import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual, Blade_GrowOutSprayDownVisual_EasterEgg } from '../../../../../assets/icons/RackIcons';
import useSwipeVertical from '../../../../../useSwipeVertical';

const Blade_CleanseTask_SprayDownOptionsStep = ({
        task,
        skipSprayDownCallback,
        confirmSprayDownCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const skipClicked = () => {
        skipSprayDownCallback()
    }

    const confirmClicked = () => {
        confirmSprayDownCallback()
    }

    const [swipeCount, SetSwipeCount] = React.useState(0)
    const swipeHandlers = useSwipeVertical ({ 
        onSwipedUp: (e) => {
            e.preventDefault()
            e.stopPropagation()
            SetSwipeCount(swipeCount + 1)
        },
    });

    const [isInEasterEgg, SetIsInEasterEgg] = React.useState(false)
    React.useLayoutEffect(() => {
        if (swipeCount >= 5) {
            SetIsInEasterEgg(true)
        }
    }, [swipeCount])

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    Spray down the grow area?
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}} {...swipeHandlers}>
                        {isInEasterEgg && <>
                            <Blade_GrowOutSprayDownVisual_EasterEgg width={291}/>
                            <div style={{textAlign:"center"}}>Thanks for helping him reach the top</div>
                        </>}
                        {!isInEasterEgg && <>
                            <Blade_GrowOutSprayDownVisual/>
                        </>}
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Skip"} status={"Neutral"} onClick={skipClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Yes"} onClick={confirmClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_SprayDownOptionsStep