import { Close } from '../assets/icons/Icons';
import './Tag.scss';


const Tag = ({content, status, fontSize, size, color, canRemove, onRemove}) => {

  const onRemoveClicked = () => {
    if (onRemove !== undefined) {
      onRemove()
    }
  }
  return (
    <div className={"Tag Tag-" + status + " Tag-" + size + " noselect"}>
      {canRemove && <>
        <div className="Tag-RemoveButton" onClick={onRemoveClicked}><Close width={6}/></div>
      </>}
      <div className="Tag-Content" style={{fontSize: fontSize}}>
        {content}
      </div>
    </div>
  )
} 

Tag.defaultProps = {
  content: "",
  status: "Neutral",
  color: false, 
  fontSize: 12,
  size: "Medium",
  canRemove: false,
  onRemove: () => {},
}


export default Tag