import './AccountConfirmVerificationCodePage.scss';
import { useDispatch, useSelector } from 'react-redux';
import PopupModal from '../../model_components/PopupModal';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../components/input/TextInput';
import Button from '../../components/Button';
import DropDownInput from '../../components/input/DropDownInput';
import { accountEmailVerification, accountFinalSetup, accountRequestNewEmailVerification, registerNewAccount } from '../../redux/entities/Account';
import { useNavigate, useSearchParams } from 'react-router-dom';






const AccountConfirmVerificationCodePage = ({pageHeaderHelper}) => {

    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const account = useSelector((state) => state.account)
    
    const cancelClicked = () => {
        navigate("/")
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const verificationInfo = searchParams.get("v")

    const [currentUserEmail, SetCurrentUserEmail] = React.useState("")

    const [currentVerificationCode, SetCurrentVerificationCode] = React.useState("")
    const verificationCodeChanged = (code) => {
        SetCurrentVerificationCode(code)
    }

    const [isUsingAccountId, SetIsUsingAccountId] = React.useState(null)
    React.useLayoutEffect(() => {
        if (verificationInfo !== undefined) {
            const verificationInfoSplit = verificationInfo.split(":")
            if (verificationInfoSplit.length == 2)  {
                const accountId = parseInt(verificationInfoSplit[0])
                const verificationCode = verificationInfoSplit[1]
                beginValidatingVerificationCode(null, accountId, verificationCode)
                SetIsUsingAccountId(accountId)
                SetCurrentVerificationCode(verificationCode)
            }
        }
    }, [verificationInfo])

 

    const [verifyingValidationCodeProcessing, SetVerifyingValidationCodeProcessing] = React.useState(false)
    const [verifyingValidationCodeSuccessful, SetVerifyingValidationCodeSuccessful] = React.useState(false)
    const validateVerificationCodeClicked = () => {
        beginValidatingVerificationCode(currentUserEmail, null, currentVerificationCode)
    }
    const beginValidatingVerificationCode = (emailAddress, accountId, verificationCode) => {
        SetVerifyingValidationCodeProcessing(true)
        dispatch(accountEmailVerification({
            email_address: emailAddress, 
            accountId: accountId,
            verificationCode: verificationCode,
            callback: (success) => {
                SetVerifyingValidationCodeProcessing(false)
                if (success)    {
                    SetVerifyingValidationCodeSuccessful(true)
                }
            }
        }))
    }

    const [requestingNewValidationCodeProcessing, SetRequestingNewValidationCodeProcessing] = React.useState(false)
    const [newValidationCodeSuccessful, SetNewValidationCodeSuccessful] = React.useState(false)
    const sendNewVerificationCodeClicked = () => {
        SetRequestingNewValidationCodeProcessing(true)
        dispatch(accountRequestNewEmailVerification({
            accountId: isUsingAccountId,
            callback: (success) => {
                SetRequestingNewValidationCodeProcessing(false)
                if (success)    {
                    SetNewValidationCodeSuccessful(true)
                }
            }
        }))
    }



    const [retrievePostAccountCreationDetails, SetRetrievePostAccountCreationDetails] = React.useState(false)
    const nextClicked = () => {
        SetRetrievePostAccountCreationDetails(true)
    }

    



    const [currentUsername, SetCurrentUsername] = React.useState("")
    const usernameChanged = (username) => {
        SetCurrentUsername(username)
    }
    const [currentPassword, SetCurrentPassword] = React.useState("")
    const passwordChanged = (password) => {
        SetCurrentPassword(password)
    }
    const [currentConfirmPassword, SetConfirmedPassword] = React.useState("")
    const confirmPasswordChanged = (password) => {
        SetConfirmedPassword(password)
    }

    const [finalAccountSetupProcessing, SetFinalAccountSetupProcessing] = React.useState(false)
    const [finalAccountSetupSuccessful, SetFinalAccountSetupSuccessful] = React.useState(false)
    const completeAccountCreationClicked = () => {
        SetFinalAccountSetupProcessing(true)
        dispatch(accountFinalSetup({
            accountId: account.id,
            verificationCode: currentVerificationCode,
            username: currentUsername,
            userkey: currentPassword,
            callback: (success) => {
                SetFinalAccountSetupProcessing(false)
                if (success)    {
                    SetFinalAccountSetupSuccessful(true)
                }
            }
        }))
    }

    const loginClicked = () => {
        navigate("/account/login")
    }

    
    const doneClicked = () => {
        //doneRegisteringCallback()
        navigate("/")
    }

    

    if (!retrievePostAccountCreationDetails)    {
        return(
            <PopupModal
                title={"Email Verification"}
                size={"full"}
                canClose={false}>
                <div className="FlexContent-50" style={{padding: isMobile ? "10px 10px" : "40px 40px"}}>
                    {!verifyingValidationCodeSuccessful && <>
                    
                        {!verifyingValidationCodeProcessing && <>
                            
                            
                            <div className="FlexContent-30">
                                {isUsingAccountId !== null && <>
                                    {!newValidationCodeSuccessful && <>
                                        <div className="FlexContent-20">
                                            <div className="FlexContent-5">
                                                <div className="Text-Medium-S14">Looks like that didn't work.</div>
                                                <TextInput value={currentVerificationCode} placeHolder={"Verification Code"} onChange={verificationCodeChanged} disabled={true}/>
                                            </div>
                                            <Button status={"Hyperlink"} content={"Send another verification code"} onClick={sendNewVerificationCodeClicked} disabled={requestingNewValidationCodeProcessing}/>
                                        </div>

                                        <div className="FlexContent" style={{gap:16}}>
                                            <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked}/>
                                        </div>    
                                    </>}
                                    {newValidationCodeSuccessful && <>
                                        <div className="FlexContent-20">
                                            <div className="FlexContent-5">
                                                <div className="Text-Medium-S20">A new validation was started, please check your email for validation</div>
                                            </div>
                                        </div>
                                        <div className="FlexContent" style={{gap:16}}>
                                            <Button content={"Done"} status={"Primary"} onClick={doneClicked} disabled={false}/>
                                        </div>
                                    </>}                                                        
                                </>}
                                {isUsingAccountId === null && <>
                                    <div className="FlexContent-20">
                                        <div className="FlexContent-5">
                                            <div className="Text-Medium-S14">Enter Verification Code</div>
                                            <TextInput value={currentVerificationCode} placeHolder={"Verification Code"} onChange={verificationCodeChanged}/>
                                        </div>
                                        <Button status={"Hyperlink"} content={"Send another verification code"} disabled={requestingNewValidationCodeProcessing}/>
                                    </div>

                                    <div className="FlexContent" style={{gap:16}}>
                                        <Button content={"Validate"} status={"Primary"} onClick={validateVerificationCodeClicked} disabled={currentVerificationCode === ""}/>
                                        <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked}/>
                                    </div>
                                </>}
                            </div>
                        </>}
                        {verifyingValidationCodeProcessing && <>
                            
                            
                            <div className="FlexContent-30">
                                <div className="FlexContent-20">
                                    <div className="FlexContent-5">
                                        <div className="Text-Medium-S14">Validating....</div>
                                    </div>
                                </div>                      
                            </div>
                        </>}
                    </>}
                    {verifyingValidationCodeSuccessful && <>
                        <div className="FlexContent-20">
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S14">Email successfully validated!</div>
                            </div>
                        </div>

                        <div className="FlexContent" style={{gap:16}}>
                            <Button content={"Next"} status={"Primary"} onClick={nextClicked}/>
                        </div>
                    </>}
                </div>
            </PopupModal>
        )
    }else {
 
 
        let isCompleteReady = true
        if (currentUsername.length < 5) {
            isCompleteReady = false
        }else if (currentPassword.length < 8) {
            isCompleteReady = false
        }else if (currentPassword !== currentConfirmPassword) {
            isCompleteReady = false
        }


        return(<>
            {!finalAccountSetupSuccessful && <>
                <PopupModal
                    title={"Final Details"}
                    size={"full"}
                    canClose={false}>
                    <div className="FlexContent-50" style={{padding: isMobile ? "10px 10px" : "40px 40px"}}>
                        <div className="FlexContent-30">
                            <div className="FlexContent-20">
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">Enter Username</div>
                                    <TextInput value={currentUsername} placeHolder={"Username"} onChange={usernameChanged}/>
                                </div>
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">Enter Password</div>
                                    <TextInput value={currentPassword} isPassword={true} placeHolder={"Password"} onChange={passwordChanged}/>
                                </div>
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">Confirm Password</div>
                                    <TextInput value={currentConfirmPassword} isPassword={true}  placeHolder={"Confirm Password"} onChange={confirmPasswordChanged}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{gap:16}}>
                                <Button content={"Complete"} status={"Primary"} onClick={completeAccountCreationClicked} disabled={!isCompleteReady || finalAccountSetupProcessing}/>
                            </div>
                        </div>
                    </div>
                </PopupModal>
            </>}
            {finalAccountSetupSuccessful && <>
                <PopupModal
                    title={"Account Finalized"}
                    size={"full"}
                    canClose={false}>
                    <div className="FlexContent-50" style={{padding: isMobile ? "10px 10px" : "40px 40px"}}>
                        <div className="FlexContent-30">
                            <div className="FlexContent-20">
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S18">Account Ready</div>
                                </div>
                            </div>
                            <div className="FlexContent" style={{gap:16}}>
                                <Button content={"Log In Now"} status={"Primary"} onClick={loginClicked}/>
                            </div>
                        </div>
                    </div>
                </PopupModal>
            </>}
        </>)
    }
}

export default AccountConfirmVerificationCodePage