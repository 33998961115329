
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import NumberInput from '../../../../components/input/NumberInput';
  

const CreateGrowPlan_SelectSeedlingSuccessRateWalkthroughStep = ({
        initialSelectedSeedlingSuccessRate,
        seedlingSuccessRateConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()

    //useSelector(state => selectAllRaftTypes(state))
    /*const haveAllRaftTypes = useSelector(state => state.raftTypes.haveAllRaftTypes)
    React.useEffect(() => {
        if (!haveAllRaftTypes)  {
            dispatch(getAllRaftTypes({}))
        }
    }, [haveAllRaftTypes])*/

    const [selectedSeedlingSuccessRate, SetSelectedSeedlingSuccessRate] = React.useState(100)
    React.useLayoutEffect(() => {
        if (initialSelectedSeedlingSuccessRate !== undefined)  {
            SetSelectedSeedlingSuccessRate(initialSelectedSeedlingSuccessRate)
        }
    }, [initialSelectedSeedlingSuccessRate])
    const seedlingSuccessRateSelected = (seedlingSuccessRate) => {
        SetSelectedSeedlingSuccessRate(100 - seedlingSuccessRate)
    }





    const backClicked = () => {
        transitionToPreviousStep(["select_seeding_method", null])
    }
    const nextClicked = () =>   {
        seedlingSuccessRateConfirmedCallback({seedlingSuccessRate: selectedSeedlingSuccessRate})
        transitionToNextStep(["plant_variant_list", null])
    }
 

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-10">
                        <div className="Text-S14">Seedling Spoilage Rate</div>
                        <NumberInput value={100 - selectedSeedlingSuccessRate} onBlur={seedlingSuccessRateSelected} suffix={"%"} min={0} max={100}/>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Finalize Variant"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectSeedlingSuccessRateWalkthroughStep
