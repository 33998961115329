import './RecipeRelationshipsPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate, useMeasure, useMeasureWithRef } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, timelineItemChanged, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import DropDownInput from '../../../components/input/DropDownInput';
import { selectAllRecipeSetpointTypes } from '../../../redux/AppInfo';
import Switch from '../../../components/Switch.js';
import { Colon } from '../../../assets/icons/Icons.js';
import { useMediaQuery } from 'react-responsive';

const RecipeRelationshipsPage = ({recipe, isEditingRecipe, selectedTimelineItem, selectTimelineItem, timelineItemSelectionOptions}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop


  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);
  
  const [timelineItems, SetTimelineItems] = React.useState([]);
  React.useEffect(() => {
    if (recipe === undefined) 
      return

    if (recipe.timeline_items != null) {
      SetTimelineItems([...recipe.timeline_items])
    }
  }, [recipe])
  const timelineItemSelected = React.useCallback((timelineItem) => {
    if (selectedTimelineItem != timelineItem && selectTimelineItem !== undefined)  {
      selectTimelineItem(timelineItem)
    }
  })
  const onTimelineItemSelectionChanged = React.useCallback((value) => {
    let foundTimelineItem = timelineItems.find(c => value === c.id)
    if (foundTimelineItem !== undefined) {
      timelineItemSelected(foundTimelineItem)
    }
  })

  const recipeSetpointTypes = useSelector(selectAllRecipeSetpointTypes)
  const [availableRelationships, SetAvailableRelationships] = React.useState([
    {
      master_setpoint_types: [{name: "light_intensity", type: null, isDefault: true}, {name: "air_temp", type: null}, {name: "air_flow", type: null}], 
      slave_setpoint_type: {name: "air_co2", type: null}, 
      function: "remap_range"
    },
    {master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "light_intensity", type: null}, function: "remap_range"},
    /*{master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "spray_rate", type: null}, function: "remap_range"},*/
    {master_setpoint_types: [{name: "air_vpd", type: null}], slave_setpoint_type: {name: "air_flow", type: null}, function: "remap_range"},
    {master_setpoint_types: [{name: "air_temp", type: null}], slave_setpoint_type: {name: "water_temp", type: null}, function: "offset"},
  ])

  React.useEffect(() => {
    let changed = false
    for (let availableRelationship of availableRelationships) {
      for (let setpointType of [...availableRelationship.master_setpoint_types, availableRelationship.slave_setpoint_type]) {
        if (setpointType.type === null) {
          let foundSetpointType = recipeSetpointTypes.find((t) => t.name == setpointType.name)
          if (foundSetpointType !== undefined)  {
            setpointType.type = foundSetpointType
            changed = true
          }
        }
      }
    }
    if (changed)  {
      SetAvailableRelationships([...availableRelationships])
    }
  }, [recipeSetpointTypes])


  const activateRelationship = (masterSetpointType, slaveSetpointType, relationshipFunction, values) =>  {
    //Check for setpoint already activated
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: [...selectedTimelineItem.item.relationships, {
              master_type_id: masterSetpointType.id,
              slave_type_id: slaveSetpointType.id,
              function: relationshipFunction,
              values: values
            }],
            setpoint_zones: [...selectedTimelineItem.item.setpoint_zones.filter((setpoint_zones) => {
              if (setpoint_zones.type_id === slaveSetpointType.id)  {
                return false
              }
              return true
            })],
            lighting_intensity_setpoint_zones: [...selectedTimelineItem.item.lighting_intensity_setpoint_zones.filter((setpoint_zone) => {
              if (slaveSetpointType.name === "light_intensity")  {
                return false
              }
              return true
            })]
          }
        }
      })]
    }}))
  }

  const deactivateRelationship = (slaveSetpointType) =>  {
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: selectedTimelineItem.item.relationships.filter((relationship) => {
              if (relationship.slave_type_id !== slaveSetpointType.id)  {
                return true
              }
              return false
            })
          }
        }
      })]
    }}))
  }

  const modifyRelationship  = (masterSetpointType, slaveSetpointType, relationshipFunction, values) =>  {
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            relationships: selectedTimelineItem.item.relationships.map((relationship) => {
              if (relationship.slave_type_id !== slaveSetpointType.id)  {
                return relationship
              }
              return {
                master_type_id: masterSetpointType.id,
                slave_type_id: slaveSetpointType.id,
                function: relationshipFunction,
                values: values
              }
            })
          }
        }
      })]
    }}))
  }



  let activeRelationships = []
  if (isEditingRecipe)  {
    activeRelationships = availableRelationships
  }else {
    if (selectedTimelineItem) {
      activeRelationships = availableRelationships.filter((availableRelationship) => {
        if (availableRelationship.slave_setpoint_type.type !== undefined && availableRelationship.slave_setpoint_type.type !== null) {
          if (selectedTimelineItem.item.relationships.find((r) => r.slave_type_id === availableRelationship.slave_setpoint_type.type.id) !== undefined) {
            return true
          }
        }
        return false
      })
    }
  }

  return (
    <>
      <div id="RecipeRelationshipsPage">
        <div className="RecipeRelationshipsPage-TimelineItemSelectionBar">
          <DropDownInput 
            uid="timeline_item_select" 
            prefix="Timeline Item: "
            options={timelineItemSelectionOptions} 
            value={(selectedTimelineItem !== undefined ? (selectedTimelineItem.id) : "")}
            onSelectionChange={(value) => {
              onTimelineItemSelectionChanged(value)
            }}/>
        </div>
        <div className="RecipeRelationshipsPage-RelationshipsList">

          {activeRelationships.map((relationshipFormat, relationshipIndex) => {

            const isMultiMaster = relationshipFormat.master_setpoint_types.length > 1

            if (relationshipFormat.slave_setpoint_type.type === null)  {
              return null
            }
            if (!isMultiMaster && relationshipFormat.master_setpoint_types[0].type === null) {
              return null
            }else if (isMultiMaster)  {
              let foundMaster = false
              for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                if (masterSetpointType.type)  {
                  foundMaster = true
                }
              }
              if (!foundMaster) {
                return null
              }
            }

            //See if the relationship is active
            let activeRelationship = null
            if (selectedTimelineItem) {
              for (let relationship of selectedTimelineItem.item.relationships) {
                if (relationship.slave_type_id === relationshipFormat.slave_setpoint_type.type.id)  {
                  activeRelationship = relationship
                }
              }
            }

            


            const masterClicked = (state) =>  {
              if (activeRelationship === null)  {
                let values = []
                if (relationshipFormat.function === "remap_range")  {
                  values = [relationshipFormat.master_setpoint_types[0].type.min, relationshipFormat.master_setpoint_types[0].type.max, relationshipFormat.slave_setpoint_type.type.min, relationshipFormat.slave_setpoint_type.type.max]
                }else if (relationshipFormat.function === "offset")  {
                  values = [0]
                }
                activateRelationship(relationshipFormat.master_setpoint_types[0].type, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
              }else {
                deactivateRelationship(relationshipFormat.slave_setpoint_type.type)
              }
            }

            const masterChanged = (masterType) =>  {
              let values = [...activeRelationship.values]
              values[0] = masterType.min
              values[1] = masterType.max
              modifyRelationship(masterType, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
            }

            const valueChanged = (index, newValue) =>  {
              let values = [...activeRelationship.values]
              values[index] = newValue
              let foundMasterSetpointType = recipeSetpointTypes.find((t) => t.id == activeRelationship.master_type_id)
              modifyRelationship(foundMasterSetpointType, relationshipFormat.slave_setpoint_type.type, relationshipFormat.function, values)
            }

            

            return (
              <div className="RecipeRelationshipsPage-Relationship-Container" key={relationshipIndex}>
                <div className={"RecipeRelationshipsPage-Relationship" + (isEditingRecipe ? " RecipeRelationshipsPage-EditingRelationship" : "")}>
                  {!isEditingRecipe && <>
                    {(() => {
                      let masterOptionName = ""
                      if (isMultiMaster)  {
                        for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                          if (masterSetpointType.type)  {
                            if (activeRelationship.master_type_id === masterSetpointType.type.id)  {
                              masterOptionName = masterSetpointType.type.display_name
                              break
                            }
                          }
                        }
                      }else {
                        masterOptionName = relationshipFormat.master_setpoint_types[0].type.display_name
                      }


                      if (isMobile) {
                        return <>
                          <div className="RecipeRelationshipsPage-Relationship-MasterSlaveOptions">
                            <div className="Text-Medium-S14">{masterOptionName}</div>
                            <div className="Text-Medium-S14">{relationshipFormat.slave_setpoint_type.type.display_name}</div>
                          </div>
                        </>
                      }else {
                        return <>
                          <div className="RecipeRelationshipsPage-Relationship-MasterOption">
                            <div className="Text-Medium-S14">{masterOptionName}</div>
                          </div>
                        </>
                      }
                    })()}
                    
                    <div className="RecipeRelationshipsPage-Relationship-Inputs">
                      {relationshipFormat.function === "remap_range" &&  <>
                        {(() => {
                          let suffix = ""
                          if (isMultiMaster)  {
                            let foundSetpointType = recipeSetpointTypes.find((t) => t.id == activeRelationship.master_type_id)
                            if (foundSetpointType !== undefined)  {
                              suffix = foundSetpointType.suffix
                            }
                              
                          }else {
                            suffix = relationshipFormat.master_setpoint_types[0].type.suffix
                          }

                          if (isMobile) {
                            return <>
                              <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Label Text-S14">Max</div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Values"><div>{parseFloat(activeRelationship.values[1])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Label Text-S14">Min</div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Values"><div>{parseFloat(activeRelationship.values[0])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                              </div>
                              <Colon/>
                              <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Label Text-S14">Max</div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Values"><div>{parseFloat(activeRelationship.values[3])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Label Text-S14">Min</div>
                                <div className="RecipeRelationshipsPage-Relationship-Input-Range-Values"><div>{parseFloat(activeRelationship.values[2])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                              </div>
                            </>
                          }else {
                            return <>
                              <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                                <div>
                                  <div className="Text-S14">Max</div>
                                  <div className="FlexContent-H-5"><div>{parseFloat(activeRelationship.values[1])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                </div>
                                <div>
                                  <div className="Text-S14">Min</div>
                                  <div className="FlexContent-H-5"><div>{parseFloat(activeRelationship.values[0])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                </div>
                              </div>
                              <Colon/>
                              <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                                <div>
                                  <div className="Text-S14">Max</div>
                                  <div className="FlexContent-H-5"><div>{parseFloat(activeRelationship.values[3])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                </div>
                                <div>
                                  <div className="Text-S14">Min</div>
                                  <div className="FlexContent-H-5"><div>{parseFloat(activeRelationship.values[2])}</div><div style={{opacity:0.4}}>{suffix}</div></div>
                                </div>
                              </div>
                            </>
                          }
                        })()}
                      </>}                      
                    </div>
                    {!isMobile && <>
                      <div className="RecipeRelationshipsPage-Relationship-Slave">
                        <div className="Text-Medium-S14">{relationshipFormat.slave_setpoint_type.type.display_name}</div>
                      </div>
                    </>}
                  </>}
                  {isEditingRecipe && <>
                  
                    {isMultiMaster && 
                      <div className="RecipeRelationshipsPage-Relationship-MasterOption">
                        {(() => {
                          //Calculate options here
                          let masterOptions = []
                          let selectedMaster = null
                          for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                            if (masterSetpointType.type)  {
                              masterOptions.push({value: masterSetpointType.type.id, label: masterSetpointType.type.display_name})
                              if (activeRelationship === null && masterSetpointType.isDefault) {
                                selectedMaster = masterSetpointType.type
                              }else if (activeRelationship !== null && activeRelationship.master_type_id === masterSetpointType.type.id)  {
                                selectedMaster = masterSetpointType.type
                              }
                            }
                          }
                          return ( 
                            <>
                              <Switch state={activeRelationship !== null} onSwitch={masterClicked}/>
                              <DropDownInput 
                                onContainerClicked={((e) => {e.stopPropagation()})}
                                uid="master_option_select"
                                options={masterOptions} 
                                value={(selectedMaster ? selectedMaster.id : "")}
                                disabled={activeRelationship === null}
                                onSelectionChange={(value) => {
                                  for (let masterSetpointType of relationshipFormat.master_setpoint_types) {
                                    if (masterSetpointType.type && masterSetpointType.type.id === value)  {
                                      masterChanged(masterSetpointType.type)
                                    }
                                  }
                                }}/>
                            </>
                          )
                        })()}
                      </div>
                    }
                    {!isMultiMaster &&
                      <div className="RecipeRelationshipsPage-Relationship-MasterOption">
                        <Switch state={activeRelationship !== null} onSwitch={masterClicked}/>
                        <div className="Text-Medium-S14">{relationshipFormat.master_setpoint_types[0].type.display_name}</div>
                      </div>
                    }
                    <div className="RecipeRelationshipsPage-Relationship-Inputs">
                      {relationshipFormat.function === "remap_range" &&  <>

                        {(() => {
                          let suffix = ""
                          let min = 0
                          let max = 0
                          let stepAmount = 1
                          if (isMultiMaster)  {
                            if (activeRelationship !== null)  {
                              let foundSetpointType = recipeSetpointTypes.find((t) => t.id == activeRelationship.master_type_id)
                              if (foundSetpointType !== undefined)  {
                                suffix = foundSetpointType.suffix
                                stepAmount = foundSetpointType.resolution
                              }
                              
                              min = parseFloat(activeRelationship.values[0])
                              max = parseFloat(activeRelationship.values[1])
                            }                                    
                          }else {
                            suffix = relationshipFormat.master_setpoint_types[0].type.suffix
                            stepAmount = relationshipFormat.master_setpoint_types[0].type.resolution
                            if (activeRelationship === null)  {
                              min = relationshipFormat.master_setpoint_types[0].type.min
                              max = relationshipFormat.master_setpoint_types[0].type.max
                          }else {
                              min = parseFloat(activeRelationship.values[0])
                              max = parseFloat(activeRelationship.values[1])
                            }
                          }

                          
                          return (
                            <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                              <div>
                                <div className="Text-S14">Max</div>
  
                                <NumberInput 
                                  value={max} 
                                  suffix={suffix} 
                                  stepAmount={stepAmount}
                                  disabled={activeRelationship === null}
                                  onBlur={(value) => {
                                    valueChanged(1, value)
                                  }}/>
                              </div>
                              <div>
                                
                                <div className="Text-S14">Min</div>
                                <NumberInput 
                                  value={min} 
                                  suffix={suffix} 
                                  stepAmount={stepAmount}
                                  disabled={activeRelationship === null}
                                  onBlur={(value) => {
                                    valueChanged(0, value)
                                  }}/>
                              </div>
                            </div>)
                        })()}

                        {!isMobile && <>
                          <Colon/>
                        </>}


                        {(() => {
                          let min = 0
                          let max = 0
                          if (activeRelationship === null)  {
                            min = relationshipFormat.slave_setpoint_type.type.min
                            max = relationshipFormat.slave_setpoint_type.type.max
                          }else {
                            min = parseFloat(activeRelationship.values[2])
                            max = parseFloat(activeRelationship.values[3])
                          }
                          return (<>
                            <div className="RecipeRelationshipsPage-Relationship-Input-Range">
                              {isMobile && <>
                                <div className="RecipeRelationshipsPage-Relationship-Slave" style={{alignSelf: "center"}}>
                                  <div className="Text-Medium-S14">{relationshipFormat.slave_setpoint_type.type.display_name}</div>
                                </div>
                              </>}
                              <div>
                                {isMobile && <div className="Text-S14">Max</div>}
                                <NumberInput 
                                  value={max} 
                                  suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                                  stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                                  disabled={activeRelationship === null}
                                  onBlur={(value) => {
                                    valueChanged(3, value)
                                  }}/>
                                {!isMobile && <div className="Text-S14">Max</div>}
                              </div>
                              <div>
                                {isMobile && <div className="Text-S14">Min</div>}
                                <NumberInput 
                                  value={min} 
                                  suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                                  stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                                  disabled={activeRelationship === null}
                                  onBlur={(value) => {
                                    valueChanged(2, value)
                                  }}/>
                                {!isMobile && <div className="Text-S14">Min</div>}
                                
                              </div>
                            </div>
                          </>)
                        })()}


                      </>}
                      {relationshipFormat.function === "offset" && <>
                        <div className="Recipe-RelationshipsManager-Relationship-Inputs-Offset">
                          <NumberInput 
                            value={0} 
                            suffix={relationshipFormat.slave_setpoint_type.type.suffix} 
                            stepAmount={relationshipFormat.slave_setpoint_type.type.resolution}
                            disabled={activeRelationship === null}
                            onBlur={(value) => {
                              valueChanged(0, value)
                            }}/>
                        </div>
                        <div className="RecipeRelationshipsPage-Relationship-Slave">
                          <div className="Text-Medium-S14">{relationshipFormat.slave_setpoint_type.type.display_name}</div>
                        </div>
                      </>}
                    </div>
                    {!isMobile && <>
                      <div className="RecipeRelationshipsPage-Relationship-Slave">
                        <div className="Text-Medium-S14">{relationshipFormat.slave_setpoint_type.type.display_name}</div>
                      </div>
                    </>}
                    
                  </>}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
} 

export default RecipeRelationshipsPage