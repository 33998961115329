import './ManageBladeWalkthrough.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import Button from '../../../components/Button';
import ControlBar from '../../../components/ControlBar';
import ScanBladeWalkthroughStep from './ManageBladeWalkthroughSteps/ScanBladeWalkthroughStep';
import ConnectingBladeWalkthroughStep from './ManageBladeWalkthroughSteps/ConnectingBladeWalkthroughStep';
import SelectBladeGroupWalkthroughStep from './ManageBladeWalkthroughSteps/SelectBladeGroupWalkthroughStep';
import { selectAllBladeGroups, selectAllBlades } from '../../../redux/entities/service/Blade';
import { useSelector } from 'react-redux';
import SelectBladeIndexWalkthroughStep from './ManageBladeWalkthroughSteps/SelectBladeIndexWalkthroughStep';
import SelectBladeNameWalkthroughStep from './ManageBladeWalkthroughSteps/SelectBladeNameWalkthroughStep';
import SelectBladeSidesWalkthroughStep from './ManageBladeWalkthroughSteps/SelectBladeSidesWalkthroughStep';
import ConfirmPowerOnWalkthroughStep from './ManageBladeWalkthroughSteps/ConfirmPowerOnWalkthroughStep';
import ManageBladeZoneChangesWalkthroughStep from './ManageBladeWalkthroughSteps/ManageBladeZoneChangesWalkthroughStep';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import ConfirmNewBladeWalkthroughStep from './ManageBladeWalkthroughSteps/ConfirmNewBladeWalkthroughStep';
import ValidateBladeWalkthroughStep from './ManageBladeWalkthroughSteps/ValidateBladeWalkthroughStep';


import { nanoid, customAlphabet  } from 'nanoid'
  

const AddRackWalkthrough = ({facilityId, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    
    const currentTheme = "white"

    const [bladeSerialNumber, SetBladeSerialNumber] = React.useState("")
    const [bladeUID, SetBladeUID] = React.useState("")
    const [codeWasScanned, SetCodeScanned] = React.useState(false)
    
    const [selectedConnectionOption, SetSelectedConnectionOption] = React.useState("provide_credentials")


    const [bladeGroupId, SetBladeGroupId] = React.useState(null)
    const [bladeIndex, SetBladeIndex] = React.useState(null)
    const [bladeName, SetBladeName] = React.useState(null)

    const [bladeSides, SetBladeSides] = React.useState({"left": "empty", "right": "empty"})
    const [zoneChanges, SetZoneChanges] = React.useState([])
    
    const allBlades = useSelector(selectAllBlades)
    const allBladeGroups = useSelector(selectAllBladeGroups)


    
    const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 8);


    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["power_on", null])

    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }

    

    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "power_on":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "get_blade_code":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "validate_blade":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
            case "connecting_to_blade":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
                
            case "select_blade_group":
                if (currentStepIndex !== 4) {
                    SetCurrentStepIndex(4)
                }
                break
                
                
            case "select_blade_index":
                if (currentStepIndex !== 5) {
                    SetCurrentStepIndex(5)
                }
                break
                
                
            case "name_blade":
                if (currentStepIndex !== 6) {
                    SetCurrentStepIndex(6)
                }
                break
                
                
            case "blade_type":
                if (currentStepIndex !== 7) {
                    SetCurrentStepIndex(7)
                }
                break
            
            case "manage_zone_changes":
                if (currentStepIndex !== 8) {
                    SetCurrentStepIndex(8)
                }
                break


            case "final_confirm":
                if (currentStepIndex !== 9) {
                    SetCurrentStepIndex(9)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true





    const processNewZoneChanges = () => {
        //Based on rack group, index, and rack sides selected, zones are going to be removed or added
        let zoneChanges = []

        let selectedBladeGroup = allBladeGroups.find((bG) => bG.id === bladeGroupId)

        let previousBlade = null
        //let currentBlade = allBlades.find((b) => b.uid === bladeUID)
        let nextBlade = null
        for (let existingBladeUID of selectedBladeGroup.blades)  {
            let foundBlade = allBlades.find((b) => b.uid === existingBladeUID)
            if (foundBlade !== undefined)   {
                if (foundBlade.index === bladeIndex - 1)   {
                    previousBlade = foundBlade
                }else if (foundBlade.index === bladeIndex + 1) {
                    nextBlade = foundBlade
                }
            }
        }

        for (const [side, sideType] of Object.entries(bladeSides)) {
            console.log(side, "-", sideType)
            if (side === "dual")   {
                switch (sideType)   {
                    case "vine":
                        
                        //Create new dual vine crop zone
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "vine",
                            for_side_type: "dual",
                            zone_name: "New Zone",
                            zone_uid: nanoid()
                        })

                    default:
                        break
                }
                break
            }else if (side === "left")    {
                switch (sideType)   { //Can be nursery, extended nursery, environment, nothing 
                    case "standard_nursery":

                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "standard_nursery",
                            zone_name: "New Nursery Zone",
                            zone_uid: nanoid(),
                        })
                        break

                    case "ext_nursery":
                        //Create new dual vine crop zone
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: "environment",
                            for_side_type: "nursery",
                            zone_name: "New Zone",
                            zone_uid: nanoid()
                        })
                        break


                    case "standard_grow_out_env":
                        //Check for conflict on previous zone
                        if (previousBlade !== null) {
                            
                            if (previousBlade.grow_side_type === "standard_grow_out" || previousBlade.grow_side_type === "berries" || previousBlade.grow_side_type === "vine") {
                                //Find the zone we are adding this blade to:
                                //TODO

                                /*zoneChanges.push({
                                    type: "activate_blade_for_zone",
                                    zone_type: previousBlade.grow_side_type,
                                    zone_name: "New Zone",
                                    zone_uid: nanoid(),
                                    grow_blade_uid: previousBlade.uid,
                                    environment_blade_uid: bladeUID
                                })*/

                            
                            
                            }
                        }
                        break
                    default:
                        break
                }
            }else if (side === "right")    {
                switch (sideType)   { //Can be grow out, berries, vine, nothing
                    case "standard_grow_out":                                
                        
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: sideType,
                            zone_name: "New Grow Out Zone",
                            zone_uid: nanoid(),
                            grow_blade_uid: bladeUID,
                            environment_blade_uid: nextBlade !== null ? nextBlade.uid : ""
                        })
                        break

                    case "berries":                                
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: sideType,
                            zone_name: "New Berries Zone",
                            zone_uid: nanoid(),
                            grow_blade_uid: bladeUID,
                            environment_blade_uid: nextBlade !== null ? nextBlade.uid : ""
                        })
                        break
                    case "vine":                                
                        zoneChanges.push({
                            type: "new_zone",
                            is_new_zone: true,
                            zone_type: sideType,
                            zone_name: "New Vine Crop Zone",
                            zone_uid: nanoid(),
                            grow_blade_uid: bladeUID,
                            environment_blade_uid: nextBlade !== null ? nextBlade.uid : ""
                        })
                        break

                    default:
                        break
                }
            }
        }
        console.log(zoneChanges)

        SetZoneChanges(zoneChanges)
        return zoneChanges.length
    }
    
    const loadWalkthroughStep = (stepKeys, isTransitioning) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "power_on":
                return (<ConfirmPowerOnWalkthroughStep transitionToNextStep={transitionToNextStep} />) 

            case "get_blade_code":
                const scanRackCallback = ({currentBladeSerialNumber, codeWasScanned}) =>  {
                    SetBladeSerialNumber(currentBladeSerialNumber)
                    SetCodeScanned(codeWasScanned)
                }
                return (<ScanBladeWalkthroughStep transitionToNextStep={transitionToNextStep} scanRackCallback={scanRackCallback} bladeUID={bladeUID}/>)  

            case "validate_blade":
                const foundBladeCallback = ({currentBladeUID}) =>  {
                    SetBladeUID(currentBladeUID)
                }
                return (<ValidateBladeWalkthroughStep 
                    currentStep={subStepKey} 
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} 
                    bladeSerialNumber={bladeSerialNumber} 
                    bladeUID={bladeUID}
                    isTransitioning={isTransitioning}
                    foundBladeCallback={foundBladeCallback}/>)  

            case "connecting_to_blade":
                const rackConnectedCallback = () =>  {

                }
                return (<ConnectingBladeWalkthroughStep 
                            bladeUID={bladeUID}
                            selectedConnectionOption={selectedConnectionOption}
                            rackConnectionTypeSelectionCallback={(type) => SetSelectedConnectionOption(type)}
                            currentStep={subStepKey} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackConnectedCallback={rackConnectedCallback}/>)        

            case "select_blade_group":
                const bladeGroupSelectedCallback = ({rackGroupId}) =>  {
                    SetBladeGroupId(rackGroupId)
                    /*let foundVerticalRackGroup = allBlades.find((vRG) => vRG.id === rackGroupId)
                    if (foundVerticalRackGroup !== undefined)   {
                        if (foundVerticalRackGroup.rack_map.racks.length !== 0)   {
                            return
                        }
                    }*/
                }
                return (<SelectBladeGroupWalkthroughStep 
                            currentStep={subStepKey}
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            rackGroupSelectedCallback={bladeGroupSelectedCallback}/>)    
            
            case "select_blade_index":
                
                const bladeIndexSelectedCallback = ({bladeIndex}) =>  {
                    SetBladeIndex(bladeIndex)
                }
                let foundBladeGroup = allBladeGroups.find((bG) => bG.id === bladeGroupId)
                let initialBladeIndex = 1
                let indexesInUse = [ ...Array(foundBladeGroup.number_of_blades).keys() ].map( i => false);

                if (bladeIndex != null)    {
                    initialBladeIndex = bladeIndex
                }else if (foundBladeGroup !== undefined)    {
                    for (let bladeUID of foundBladeGroup.blades) {
                        let foundBlade = allBlades.find((b) => b.uid === bladeUID)
                        if (foundBlade !== undefined)   {
                            indexesInUse[foundBlade.index - 1] = true
                        }
                    }
                }
                for (let i = 0; i < foundBladeGroup.number_of_blades; i++)   {
                    if (!indexesInUse[i])   {
                        initialBladeIndex = i + 1
                        break
                    }
                }
                return (<SelectBladeIndexWalkthroughStep 
                            facilityId={facilityId} 
                            bladeGroupId={bladeGroupId}
                            newBladeUID={bladeUID}
                            desiredBladeIndex={bladeIndex}
                            initialBladeIndex={initialBladeIndex}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            bladeIndexSelectedCallback={bladeIndexSelectedCallback}/>)
            
            case "name_blade":
                const bladeNameSelectedCallback = ({bladeName}) =>  {
                    SetBladeName(bladeName)
                }
                let newBlade = allBlades.find((b) => b.uid === bladeUID)
                let initialBladeName
                if (bladeName !== null) {
                    initialBladeName = bladeName
                }else if (newBlade !== undefined) {
                    initialBladeName = newBlade.display_name
                }else {
                    initialBladeName = "Blade " + bladeIndex
                }
                
                return (<SelectBladeNameWalkthroughStep 
                            bladeIndex={bladeIndex} 
                            initialBladeName={initialBladeName} 
                            facilityId={facilityId} 
                            rackGroupId={bladeGroupId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep} 
                            bladeNameSelectedCallback={bladeNameSelectedCallback}/>)
                
            case "blade_type":
                const bladeSidesSelectedCallback = ({bladeSides}) =>  {
                    SetBladeSides(bladeSides)
                    //SetCurrentStep("manage_zone_changes")
                }
                
                return (<SelectBladeSidesWalkthroughStep 
                            facilityId={facilityId} 
                            bladeGroupId={bladeGroupId} 
                            bladeSides={bladeSides}
                            currentStep={subStepKey}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            bladeSidesSelectedCallback={bladeSidesSelectedCallback}
                            processNewZoneChanges={processNewZoneChanges}/>)
            

            case "manage_zone_changes":
                const rackZonesManagedCallback = ({zoneChanges}) =>  {
                    SetZoneChanges([...zoneChanges])
                    //SetCurrentStep("confirm")
                }

                
                return (<ManageBladeZoneChangesWalkthroughStep 
                            facilityId={facilityId} 
                            zoneChanges={zoneChanges} 
                            currentZoneChangeIndex={parseInt(subStepKey)}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            rackZonesManagedCallback={rackZonesManagedCallback}/>)

            case "final_confirm":
                const bladeConfirmedCallback = () =>  {
                    //close popup
                    completeCallback()
                }

                
                return (<ConfirmNewBladeWalkthroughStep 
                            facilityId={facilityId}
                            bladeUID={bladeUID}
                            bladeName={bladeName}
                            bladeGroupId={bladeGroupId} 
                            bladeIndex={bladeIndex} 
                            bladeSides={bladeSides}
                            zoneChanges={zoneChanges} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            bladeConfirmedCallback={bladeConfirmedCallback}/>)

            default:
                return <></>
        }
    }


    return (<>
        <Walkthrough
            id={"ManageBladeWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Add Blade</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["power_on", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)

}



export default AddRackWalkthrough