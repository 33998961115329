
import './SelectRecipes.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { selectAllRecipes, getAllRecipes } from '../redux/entities/Recipes'
import { useSelector, useDispatch } from 'react-redux'
import { FormatDate } from '../helpers'
import RowGroup from '../components/RowGroup/RowGroup.js';
import { useMediaQuery } from 'react-responsive';
import RowGroupRow from '../components/RowGroup/RowGroupRow.js';


const SelectRecipes = ({selectedRecipe, onRecipeSelection}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  
  
  const allRecipes = useSelector((state) => selectAllRecipes(state))
  let haveInitialData = useSelector((state) => state.recipes.haveInitialData)
  const dispatch = useDispatch()
  React.useEffect( () =>  { 
    if (!haveInitialData) {
      dispatch(getAllRecipes({}))
    }
  }, [haveInitialData])

  const [recipesList, SetRecipesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedRecipe, SetSelectedRecipe] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedRecipe !== selectedRecipe) {
      SetSelectedRecipe(selectedRecipe)
    }
  }, [selectedRecipe])
  

  React.useEffect(() => {
    let currentRecipesList = {}

    let currentRecipes = []
    
    let availableRecipes = [...allRecipes.filter((r) => r.is_active_version)]
    if (currentSearchInput === "")  {
      currentRecipes = [...availableRecipes]
    }else {
      currentRecipes.push(...availableRecipes.filter((recipe) => {
        if (recipe.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentRecipes.indexOf(recipe) === -1)  {
          return recipe
        }
      }))
    }
    
    
    for (let recipe of currentRecipes) {
      currentRecipesList[recipe.id + "-" + recipe.version] = {
        data: recipe,
      }
    }


    SetRecipesList(currentRecipesList)
  }, [allRecipes, currentSearchInput, currentSelectedRecipe])




  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const handleRecipeSelectedStateChanged = (recipeId, recipeVersion, state) => {
    if (selectedRecipe === recipeId)    {
        if (state)  {
            SetSelectedRecipe(recipeId)
            if (onRecipeSelection !== undefined) {
              onRecipeSelection(recipeId, recipeVersion)
            }
        }else {
            SetSelectedRecipe(null)
            if (onRecipeSelection !== undefined) {
              onRecipeSelection(null)
            }
        }
    }else if (state) {
        SetSelectedRecipe(recipeId)
        if (onRecipeSelection !== undefined) {
          onRecipeSelection(recipeId, recipeVersion)
        }
    }
}


  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Varient Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
  if (!isMobile) {
    columnHeaders = {...columnHeaders, 
      duration: {label: <>Duration</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      creator: {label: <>Creator</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
    }
}

  return (
    <>
      <div id="SelectRecipes">
      <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectRecipes_RecipeRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
          }}/>

          {Object.entries(recipesList).map(([recipeKey, recipeInfo]) => {
              return (
                <SelectRecipes_RecipeRow 
                  recipeKey={recipeKey} 
                  recipeInfo={recipeInfo} 
                  isSelected={selectedRecipe === recipeInfo.data.id}
                  onSelectedChanged={(state) => {handleRecipeSelectedStateChanged(recipeInfo.data.id, recipeInfo.data.version, state)}}
                  columnSizes={columnSizes}/>
              ) 
            })}
      </RowGroup>
      </div>
    </>
  )
} 

export default SelectRecipes




const SelectRecipes_RecipeRow = ({recipeKey, recipeInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const recipe = isPlaceholder ? null : recipeInfo.data

  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "duration", content: <>
      <div className="noselect" style={{fontSize:14}}>
          <div>
              {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">25 Days, 24 hours</div>
              </> : <>
                  <div className="Text-SoftTruncate">Duration</div>
              </>
          )}</div>
      </div>
    </>
    })
    secondaryHeaderContent.push({
      key: "creator", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div>
              {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">Deryk Richardson</div>
              </> : <>
                <div className="Text-SoftTruncate">Some long name</div>
              </>
          )}</div>
        </div>
      </>
    })
  }

  let title = isPlaceholder ? <>My Amazing Recipe Nmae</> : recipe.name
  
    
  let subtitle = (isPlaceholder ? <div className="">v1000 00/00/0000</div> : <div className="">
    <div className="Text-SoftTruncate">v{recipe.version}</div>
    <div className="Text-SoftTruncate">{FormatDate(new Date(recipe.modified_on), "MM/dd/yyyy")}</div>  
  </div>)
  


  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : recipeKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="">{subtitle}</div>}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      isSelectable={true}
      selectedState={isSelected}
      onSelectedChanged={onSelectedChanged}
      isExpandable={isMobile}
      hasAdditionalFunctions={false}
      content={<>
        {(() => {
          if (isMobile && !isPlaceholder) {
            return (<>
              <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                
              </div>
            </>)
          }
      
        })()}
      </>}
    />
  </>)
}