import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'


const processAccountFromAPI = (state, account) =>  {
  console.log(account)
  if (account.id !== undefined) {
    state.id = account.id
    state.data = {...state.data, ...account}
  }
  return state
}




export const attemptAccountLogin = createAsyncThunk('account/attemptAccountLogin', async ({emailAddress, username, userkey, deviceAgent, callback}) => {
  let payload = {
    email_address: emailAddress,
    username: username,
    userkey: userkey,
    device_agent: deviceAgent
  }
  return await FetchPost(APIRepository.Account.AttemptAccountLogin, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.loginAttemptStatus === 'pending') {
      return false
    }
  },
})

export const registerNewAccount = createAsyncThunk('account/registerNewAccount', async ({emailAddress, fullName, addressDetails, callback}) => {
  let payload = {
    email_address: emailAddress,
    full_name: fullName,
    address_details: addressDetails
  }
  return await FetchPost(APIRepository.Account.RegisterNewAccount, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.registeringNewAccountStatus === 'pending') {
      return false
    }
  },
})

export const accountEmailVerification = createAsyncThunk('account/accountEmailVerification', async ({emailAddress, accountId, verificationCode, callback}) => {
  let payload = {
    email_address: emailAddress,
    account_id: accountId,
    verification_code: verificationCode,
  }
  return await FetchPost(APIRepository.Account.AccountEmailVerification, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.validatingEmailVerificationCode === 'pending') {
      return false
    }
  },
})

export const accountRequestNewEmailVerification = createAsyncThunk('account/accountRequestNewEmailVerification', async ({accountId, callback}) => {
  let payload = {
    account_id: accountId,
  }
  return await FetchPost(APIRepository.Account.AccountRequestNewEmailVerification, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.requestingNewEmailVerification === 'pending') {
      return false
    }
  },
})



export const accountFinalSetup = createAsyncThunk('account/accountFinalSetup', async ({accountId, verificationCode, username, userkey, callback}) => {
  let payload = {
    account_id: accountId,
    verification_code: verificationCode,
    username: username,
    userkey: userkey
  }
  return await FetchPost(APIRepository.Account.FinalSetup, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.performingFinalSetup === 'pending') {
      return false
    }
  },
})


export const accountLogout = createAsyncThunk('account/accountLogout', async ({}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState())
  }
  return await FetchPost(APIRepository.Account.Logout, payload)  
},
{
  condition: (args, { getState }) => {
    const { account } = getState()
    if (account.performingLogout === 'pending') {
      return false
    }
  },
})





export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    id: null,
    data: {


      privileges: {
        //"manufacturing": "all"
      },        
    },
    loggedInState: false,
    sessionToken: null,
    status: 'idle',
    loginAttemptStatus: 'idle',
    registeringNewAccountStatus: 'idle',
    validatingEmailVerificationCode: 'idle',
    requestingNewEmailVerification: 'idle',
    performingFinalSetup: 'idle',
    performingLogout: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
    setupInitialSession: (state, action) => {
      state.id = action.payload.accountId
      state.data = action.payload.accountData
      state.sessionToken = action.payload.sessionToken
      state.loggedInState = action.payload.loggedInState
    },

  },
  extraReducers: {
    [attemptAccountLogin.pending]: (state) => {
      state.loginAttemptStatus = 'pending';
    },
    [attemptAccountLogin.fulfilled]: (state, action) => {
      state.loginAttemptStatus = 'fulfilled';
      if (action.payload.success && action.payload.account !== null && action.payload.session_token !== null) {
        state = processAccountFromAPI(state, action.payload.account)
        state.sessionToken = action.payload.session_token
        state.loggedInState = true

        localStorage.setItem('account_data', JSON.stringify(state.data));
        localStorage.setItem('account_id', state.id);
        localStorage.setItem('account_logged_in', true);
        localStorage.setItem('account_session_token', action.payload.session_token);

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }  
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [attemptAccountLogin.rejected]: (state, action) => {
      state.loginAttemptStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [registerNewAccount.pending]: (state) => {
      state.registeringNewAccountStatus = 'pending';
    },
    [registerNewAccount.fulfilled]: (state, action) => {
      state.registeringNewAccountStatus = 'fulfilled';
      if (action.payload.success) {
        //DO SOME STUFF!!!
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        } 
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [registerNewAccount.rejected]: (state, action) => {
      state.registeringNewAccountStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    
    [accountEmailVerification.pending]: (state) => {
      state.validatingEmailVerificationCode = 'pending';
    },
    [accountEmailVerification.fulfilled]: (state, action) => {
      state.validatingEmailVerificationCode = 'fulfilled';
      if (action.payload.success && action.payload.account !== null) {
        state = processAccountFromAPI(state, action.payload.account)
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }  
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [accountEmailVerification.rejected]: (state, action) => {
      state.validatingEmailVerificationCode = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [accountRequestNewEmailVerification.pending]: (state) => {
      state.requestingNewEmailVerification = 'pending';
    },
    [accountRequestNewEmailVerification.fulfilled]: (state, action) => {
      state.requestingNewEmailVerification = 'fulfilled';
      if (action.payload.success) {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }  
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [accountRequestNewEmailVerification.rejected]: (state, action) => {
      state.requestingNewEmailVerification = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },
    

    [accountFinalSetup.pending]: (state) => {
      state.performingFinalSetup = 'pending';
    },
    [accountFinalSetup.fulfilled]: (state, action) => {
      state.performingFinalSetup = 'fulfilled';
      if (action.payload.success && action.payload.account !== null) {
        state = processAccountFromAPI(state, action.payload.account)
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }  
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }  
      }
    },
    [accountFinalSetup.rejected]: (state, action) => {
      state.performingFinalSetup = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [accountLogout.pending]: (state) => {
      state.performingLogout = 'pending';
    },
    [accountLogout.fulfilled]: (state, action) => {
      state.performingLogout = 'fulfilled';

      state.data = {}
      state.id = null
      state.sessionToken = ""
      state.loggedInState = false

      localStorage.clear('account_data')
      localStorage.clear('account_id');
      localStorage.clear('account_logged_in');
      localStorage.clear('account_session_token');

      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }  
    },
    [accountLogout.rejected]: (state, action) => {
      state.performingLogout = 'rejected';


      state.data = {}
      state.id = null
      state.sessionToken = ""
      state.loggedInState = false

      localStorage.clear('account_data')
      localStorage.clear('account_id');
      localStorage.clear('account_logged_in');
      localStorage.clear('account_session_token');

      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },
  }
})



// Action creators are generated for each case reducer function
export const { setupInitialSession } = accountSlice.actions

export default accountSlice.reducer

export const selectAllAccountPrivileges = (state) => {
  if (state.account.data.privileges !== undefined && state.account.data.privileges)  {
    return state.account.data.privileges
  }
  return []
}