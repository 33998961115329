import './SettingsPage.scss';
import React from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import Pages from './../../pages/PageList';
import Button from '../../components/Button.js';
import { useDispatch, useSelector } from 'react-redux';
import { IsSessionActive } from '../Account/Common.js';
import { accountLogout } from '../../redux/entities/Account.js';




const SettingsPage = ({pageHeaderHelper}) => {


  const isSessionActive = useSelector(state => IsSessionActive(state))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutClicked = () => {
    dispatch(accountLogout({callback: (success) => {
      window.location.href = window.location.origin
    }})) 
  }

  const updatePageHeader = () => {
    pageHeaderHelper.current.Reset() 
    pageHeaderHelper.current.SetTitle("Settings")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {isSessionActive && 
                <Button content={"Log Out"} onClick={logoutClicked}/>
              }
            </div>
          </div>
        </>
      )
    })
  }

  React.useLayoutEffect(() => {
    updatePageHeader()
  }, [isSessionActive])

  React.useLayoutEffect(() => {
    updatePageHeader()
  }, [])

  return (
    <div className="PageWrapper_Standard">
      <TabControl tabs={
        [new TabControlTab("Account", "account", true),
        new TabControlTab("Company", "company"),
        new TabControlTab("Users", "users"),
        new TabControlTab("Plans and Billing", "billing"),
        new TabControlTab("Farms", "farms"),
        new TabControlTab("Store", "store")]}
        content={
          <Routes>
            <Route exact path="/account" element={
              <Pages.Settings_AccountPage/>
            }/>
            <Route path="/company" element={
              <Pages.Settings_CompanyPage/>
            }/>
            <Route path="/users" element={
              <Pages.Settings_UsersPage/>
            }/>
            <Route path="/billing" element={
              <Pages.Settings_BillingPage/>
            }/>
            <Route path="/farms" element={
              <Pages.Settings_FarmsPage/>
            }/>
            <Route path="/store" element={
              <Pages.Settings_StorePage/>
            }/>

            <Route path="*" element={<Navigate to="account" replace />} />
          </Routes>
        }/>
    </div>
  )
} 

export default SettingsPage