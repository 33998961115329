import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { ScanRack } from '../../../../assets/icons/RackIcons';


  

const ScanRackWalkthroughStep = ({transitionToNextStep, scanRackCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    
    const scanRackIn = React.useCallback(() => {
        //let rackCode = "1252021"
        //SetRackCode(rackCode)
        scanRackCallback({currentBladeSerialNumber: bladeSerialNumber, codeWasScanned: true})

        transitionToNextStep(["validate_blade", "setup"])
        //transitionToNextStep(["connecting_to_blade", "connection_options"])
    })

    

    const [bladeSerialNumber, SetBladeSerialNumber] = React.useState("")
    const [codeScanned, SetCodeScanned] = React.useState(false)
    const bladeCodeManualInputChanged = React.useCallback((value) => {
        SetBladeSerialNumber(value)
    })
    
    const nextClicked = React.useCallback(() => {
        scanRackCallback({currentBladeSerialNumber: bladeSerialNumber, codeWasScanned: codeScanned})

        transitionToNextStep(["validate_blade", "setup"])
        //transitionToNextStep(["connecting_to_blade", "connection_options"])
    })


    let isRackCodeValid = bladeSerialNumber !== "" && bladeSerialNumber.length > 3
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Scan Blade or Enter Code</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-10 FlexContent-HFill">
                        
                        <Button status="Interactive-Neutral" size="Large-IconAction" content={<>
                            <div className="Button-Large-Icon"><ScanRack width={(isTablet || isDesktop) ? 168 : 168}/></div>
                            Tap to scan code
                        </>}/>
                    </div>
                    <div className="FlexContent-HFill">
                        <div className="FlexContent-20 FlexContent-Center FlexContent-HFill">
                            <div className="Walkthrough-FormItem">
                                <div className="Walkthrough-FormItem-Question">Enter blade code instead</div>
                                <div className="Walkthrough-FormItem-Answer">
                                    <TextInput value={""} placeHolder="Enter rack code" onChange={bladeCodeManualInputChanged}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Continue"} onClick={scanRackIn} width={"Flex100"} size={isMobile ? "Medium" : "Large"}  disabled={!isRackCodeValid}/>
            </div>
        </div>
    )
}


ScanRackWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default ScanRackWalkthroughStep