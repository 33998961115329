import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual } from '../../../../../assets/icons/RackIcons';

const Blade_CleanseTask_CleaningOutStep = ({
        task,
        completedSprayDownCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const finishedClicked = () => {
        completedSprayDownCallback()
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    The zone is now in drain mode spray down the grow area
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <Blade_GrowOutSprayDownVisual/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Primary" content={"Finished Spray Down"} onClick={finishedClicked} size={"Flex100"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_CleaningOutStep