import './RaftType.scss';
import React from 'react';


export const Raft = ({raftType, isSelected, onRaftTypeSelect}) => {

  const onRaftTypeSelected = (e) => {
    if (onRaftTypeSelect !== undefined) {
      onRaftTypeSelect()
    }
  }
  
  
  return (
    <div
      className={"RaftType-Raft" + (isSelected ? " RaftType-Raft-Selected" : "")} 
      onClick={onRaftTypeSelected}>
      {"x" + raftType.plant_sites}
    </div>
  )
} 

export const RaftTypes = [
  {id: 1, plant_sites: 24},
  {id: 2, plant_sites: 48}
]

export const SelectRaftType = ({selectedRaftType, onRaftTypeSelect}) => {

  

  const [currentSelectedRaftType, SetSelectedRaftType] = React.useState(selectedRaftType)
  React.useState(() => {
    if (selectedRaftType !== currentSelectedRaftType) {
      SetSelectedRaftType(selectedRaftType)
    }
  }, selectedRaftType)


  
  
  return (
    <div className="SelectRaftType-Container">
      {RaftTypes.map((raftType) => {

        const onRaftSelected = (e) =>  {
          SetSelectedRaftType(raftType)
          if (onRaftTypeSelect !== undefined) {
            onRaftTypeSelect(raftType)
          }
        }
        return (
          <Raft
            key={raftType.id}
            raftType={raftType}
            isSelected={currentSelectedRaftType !== undefined && currentSelectedRaftType.id === raftType.id}
            onRaftTypeSelect={onRaftSelected}/>
        )
      })}
    </div>
  )
} 

SelectRaftType.defaultProps = {
  
}