import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { RoundToNearest } from '../../../../helpers';

  

const ConfirmCurrentReservoirVolumeWalkthroughStep = ({NDS, reservoirInfo, transitionToPreviousStep, transitionToNextStep, initialReservoirVolumeConfirmedCallback, cancelReservoirTopUpCallback, reservoirInitialVolumeIsCorrectCallback, reservoirInitialVolumeIsIncorrectCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const [isReservoirVolumeCorrect, SetIsReservoirVolumeCorrect] = React.useState(true)

    let reservoirVolumeRemaining = 0
    if (reservoirInfo["runtime_information"]["volume_remaining"] !== undefined)  {
      reservoirVolumeRemaining = parseFloat(reservoirInfo["runtime_information"]["volume_remaining"]) / 1000;
    }


    const cancelClicked = () => {
        cancelReservoirTopUpCallback()
        transitionToNextStep(["confirm_reservoir_reinstall", null])        
    }
    const nextClicked = () =>   {
        if (isReservoirVolumeCorrect)   {
            reservoirInitialVolumeIsCorrectCallback()
            initialReservoirVolumeConfirmedCallback(RoundToNearest(reservoirVolumeRemaining, 0.1))
            transitionToNextStep(["select_topup_type", null])
        }else {
            reservoirInitialVolumeIsIncorrectCallback()
            transitionToNextStep(["update_current_reservoir_volume", null])
        }
    }


    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Current Volume</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-40 FlexContent-Center">
                        {isMobile && <>
                            <div className="FlexContent-30 FlexContent-Center">
                                <div style={{fontSize:18, fontWeight:400, textAlign:"center", margin:"0 10px"}}>Is the current volume of the reservoir:</div>
                                <div style={{fontSize:34, fontWeight:500}}>{RoundToNearest(reservoirVolumeRemaining, 0.1)}L</div>
                            </div>
                            <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
                                <Button 
                                    content={<div style={{fontSize:20}}>Yes</div>} 
                                    state={isReservoirVolumeCorrect} 
                                    status={"Primary-Toggle"}
                                    onClick={() => {SetIsReservoirVolumeCorrect(true)}}
                                    style={{height: 60}}/>
                                <Button 
                                    content={<div style={{fontSize:20}}>No</div>} 
                                    state={!isReservoirVolumeCorrect} 
                                    status={"Primary-Toggle"} 
                                    onClick={() => {SetIsReservoirVolumeCorrect(false)}}
                                    style={{height: 60}}/>
                            </div>
                        </>}
                        {!isMobile && <>
                            <div className="FlexContent-30 FlexContent-Center">
                                <div style={{fontSize:24, fontWeight:400, textAlign:"center"}}>Is the current volume of the reservoir:</div>
                                <div style={{fontSize:34, fontWeight:500}}>{RoundToNearest(reservoirVolumeRemaining, 0.1)}L</div>
                            </div>
                            <div className="FlexContent-H-10 FlexContent-HFill FlexContent-HFlex">
                                <Button 
                                    content={<div style={{fontSize:20}}>No</div>} 
                                    state={!isReservoirVolumeCorrect} 
                                    status={"Primary-Toggle"} 
                                    onClick={() => {SetIsReservoirVolumeCorrect(false)}}
                                    width={"Flex50"}
                                    style={{height: 100}}/>
                                <Button 
                                    content={<div style={{fontSize:20}}>Yes</div>} 
                                    state={isReservoirVolumeCorrect} 
                                    status={"Primary-Toggle"}
                                    onClick={() => {SetIsReservoirVolumeCorrect(true)}}
                                    width={"Flex50"}
                                    style={{height: 100}}/>
                            </div>
                        </>}
                        
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Cancel"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ConfirmCurrentReservoirVolumeWalkthroughStep