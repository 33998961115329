
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { Plus, SearchIcon, Trash } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/GrowSeeds';
  

const CreateGrowPlan_PlantVariantListWalkthroughStep = ({
        selectedPlantType,
        selectedPlantVariety,
        selectedSeed,
        selectedSeedlingRaftType,
        selectedSeedingMethod,
        selectedSeedSelector,
        selectedSeedsPerPlug,
        selectedSeedlingSuccessRate,
        selectedSeedRatios,
        changeSeedRatios,
        changePlantVariantIndex,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const cancelClicked = () => {
        //transitionToPreviousStep(["select_plant_variety", null])
    }
    const nextClicked = () =>   {

        transitionToNextStep(["select_recipe", null])
    }




    const addAnotherVariantClicked = () => {
        changePlantVariantIndex(Object.entries(selectedPlantType).length)
        //changeSeedRatios({})
        changeSeedRatios({0: 50, 1: 50})
        transitionToNextStep(["select_plant_type", null])
    }


    
    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Plant</>, setPosition: (bounds, contentBounds) => {return {left: isMobile ? bounds.left - 30 : bounds.left}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
        seeds: {label: <>Seed</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
        ratio: {label: <>Ratio</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
        functions: {label: <></>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}}  
        }
    }
    
    let plantVariantList = []
    for (let index of Object.keys(selectedPlantType))   {
        plantVariantList.push({
            data: {
                plant_type_id: selectedPlantType[index],
                plant_variety_id: selectedPlantVariety[index],
                seed_id: selectedSeed[index],
                seedling_raft_type_id: selectedSeedlingRaftType[index],
                seeding_method: selectedSeedingMethod[index],
                seed_selector_id: selectedSeedSelector[index],
                seeds_per_plug: selectedSeedsPerPlug[index],
                seedling_success_rate: selectedSeedlingSuccessRate[index],
                seeding_ratio: selectedSeedRatios[index]
            }
        })
    }
 
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-10">
                        <div className="Text-S14">Your Plant Variants</div>
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={false}>
                            
                                <CreateGrowPlant_PlantVariantList_Row isPlaceholder={true} columnSizesChanged={(columns) => {
                                    SetColumnSizes(columns)
                                }}/>
                                {plantVariantList.map((plantVariant) => {
                                    return <>
                                        <CreateGrowPlant_PlantVariantList_Row plantVariantKey={plantVariant.variant_id} plantVariantInfo={plantVariant} columnSizes={columnSizes} isReview={false}/>
                                    </>
                                })}
                                
                        </RowGroup>
                        <Button content={<>
                            <div className="FlexContent-H-5">
                                <Plus/>
                                <div>Add another plant variant</div>
                            </div>
                        </>} status={"Hyperlink"} onClick={addAnotherVariantClicked}/>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Cancel"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next: Recipe"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_PlantVariantListWalkthroughStep



export const CreateGrowPlant_PlantVariantList_Row = ({plantVariantKey, plantVariantInfo, isReview, isPlaceholder, columnSizes, columnSizesChanged}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
    
    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    const allPlantTypes = useSelector(state => selectAllPlantTypes(state))
    const allPlantVarities = useSelector(state => selectAllPlantVarieties(state))
    const allSeeds = useSelector((state) => selectAllGrowSeeds(state))

    const plantVariant = isPlaceholder ? null : plantVariantInfo.data

    let plantType = null
    let plantVariety = null
    let seed = null
    let seedlingRaftType = null
    if (plantVariant !== null)  {
        plantType = allPlantTypes.find((pT) => pT.id === plantVariant.plant_type_id)
        plantVariety = allPlantVarities.find((pV) => pV.id === plantVariant.plant_variety_id)
        seed = allSeeds.find((s) => s.id === plantVariant.seed_id)
        seedlingRaftType = allRaftTypes.find((rT) => rT.id === plantVariant.seedling_raft_type_id)
    }
    
    

  
    
    let secondaryHeaderContent = []
    if (!isMobile) {
        secondaryHeaderContent.push({
            key: "seeds", content: <>
            <div className="noselect" style={{fontSize:14}}>
            {isPlaceholder ? "Long seed name" : seed.name}
            </div>
        </>
        })
        secondaryHeaderContent.push({
            key: "ratio", content: <>
            <div className="noselect" style={{fontSize:14}}>
                {isPlaceholder ? "100%" : plantVariant["seeding_ratio"] + "%"}
            </div>
            </>
        })
        if (!isReview)    {
            secondaryHeaderContent.push({
                key: "function", style: {display:"flex", alignSelf:"center",fontSize:14}, content: <>
                <div className="noselect" style={{fontSize:14}}>
                    <Button content={<Trash fill={"#D82C0D"}/>} status={"Primary-Inverted"}/>
                </div>
                </>
            })
        }
    }
  
    let title = ""
    if (isPlaceholder) {
        title = "Placeholder"
    }else {
        if (plantType !== null) {
            title = plantType.name
        }
    }
    let subtitle = ""
    if (isPlaceholder)  {
        subtitle = "Placeholder"
    }else {
        if (plantVariety !== null)  {
            subtitle = plantVariety.name
        }
    }

    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : plantVariantKey}
        title={title}
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        subtitle={<div className="">{subtitle}</div>}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        isExpandable={true}
        hasAdditionalFunctions={false}
        content={<>
          {(() => {
            if (!isPlaceholder) {
                if (isMobile)   {
                    return (<>
                        <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                            
                        </div>
                    </>)
                }else {
                    return (<>
                        <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                            
                        </div>
                    </>)
                }
            }        
          })()}
        </>}
      />
    </>)
}