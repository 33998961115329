import './TransplantToGrowOutFromNurseryTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';

const TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep = ({
    cancelTaskCallback,
    allBladeZones,
    allBlades,
    initialSelectedGrowOutZone, 
    growOutZoneSelectionUpdated,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const cancelClicked = () => {
        transitionToPreviousStep(["transplant_list", null])
    }

    const nextClicked = () =>  {
        transitionToNextStep(["select_nursery_locations", null])
    }
    

    const [selectedBladeZoneUID, SetSelectedBladeZoneUID] = React.useState(null)
    React.useLayoutEffect(() => {
        SetSelectedBladeZoneUID(initialSelectedGrowOutZone)
    }, [initialSelectedGrowOutZone])
    const toggleBladeZoneSelected = (zoneUID) => {
        if (selectedBladeZoneUID !== zoneUID)   {
            SetSelectedBladeZoneUID(zoneUID)
            growOutZoneSelectionUpdated(zoneUID)
        }else {
            SetSelectedBladeZoneUID(null)
            growOutZoneSelectionUpdated(null)
        }
    }
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        
                        <div className="Text-SubHeader">Step 2: Select the Zone you want to transplant into</div>
                        <div>

                        {allBladeZones.filter((bZ) => bZ.zone_type === "grow_boards").map((bladeZone) => {
                            let secondaryHeaderContent = []
                            if (!isMobile)  {
                                secondaryHeaderContent.push({
                                    key: "blades", content: <>
                                    <div className="noselect" style={{fontSize:14}}>
                                        <div className="Text-AltSubHeader">Blade 1 | Blade 2</div>
                                    </div>
                                  </>
                                })
                            }
                            return (
                                <RowGroupRow
                                    key={bladeZone.id}
                                    title={<div className="Text-FormHeader">{bladeZone.display_name}</div>}
                                    titleFontSize={14}
                                    cardBackgroundColor={"transparent"}
                                    cardBorderColor={"transparent"}
                                    showRowSeperator={true}
                                    showExpandedStatusContainerStyles={false}
                                    statusContainerPadding={0}
                                    minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
                                    hasFlexCell={true}
                                    showStatusIndicator={false}
                                    isPlaceholderRow={false}
                                    isSelectable={true}
                                    selectedState={bladeZone.uid === selectedBladeZoneUID}
                                    onSelectedChanged={() => {toggleBladeZoneSelected(bladeZone.uid)}}
                                    showNeutralSelectionColor={true}
                                    secondaryContent={secondaryHeaderContent}
                                    isExpandable={false}
                                    hasAdditionalFunctions={false}
                                    content={<>
                                        {isMobile && <>
                                            <div className="noselect" style={{fontSize:14, paddingBottom: 10}}>
                                                <div className="Text-AltSubHeader">Blade 1 | Blade 2</div>
                                            </div>
                                        </>}
                                    </>}/>
                            )
                        })}

                        </div>

                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={selectedBladeZoneUID === null}/>
            </div>
        </div>
    )
    
}


export default TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep