import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardEnvironment, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewBladeGroup, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';

  

const SelectRackSidesWalkthroughStep = ({bladeSides, facilityId, rackGroupId, currentStep, transitionToPreviousStep, transitionToNextStep, bladeSidesSelectedCallback, processNewZoneChanges}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const allBlades = useSelector(selectAllBlades)


    const sideDisplayNames = {
        "standard_nursery": "Nursery",
        "ext_nursery": "Extended Nursery",
        "standard_grow_out_env": "Environment",
        "none": "None",
        "standard_grow_out": "Grow Boards",
        "berries": "Berries",
        "vine": "Vine Crops"
    }


    const [selectedLeftRackType, SetSelectedLeftRackType] = React.useState({displayName: "None", key: "empty"})
    const [selectedRightRackType, SetSelectedRightRackType] = React.useState({displayName: "None", key: "empty"})
    const [selectedDualRackType, SetSelectedDualRackType] = React.useState(null)

    React.useEffect(() => {
        SetSelectedLeftRackType(null)
        SetSelectedRightRackType(null)
        SetSelectedDualRackType(null)
        for (const [side, sideType] of Object.entries(bladeSides)) {
            if (side === "left")    {
                SetSelectedLeftRackType({displayName: sideDisplayNames[sideType], key: sideType})
            }else if (side === "right")    {
                SetSelectedRightRackType({displayName: sideDisplayNames[sideType], key: sideType})
            }else if (side === "dual")    {
                SetSelectedDualRackType({displayName: sideDisplayNames[sideType], key: sideType})
            }
        }
    }, [bladeSides])

    const leftRackTypeSelected = (type) =>  {
        SetSelectedLeftRackType(type)
        SetSelectedDualRackType(null)
        if (selectedRightRackType === null)  {
            SetSelectedRightRackType({displayName: "None", key: "empty"})
        }
    }
    const rightRackTypeSelected = (type) =>  {
        SetSelectedRightRackType(type)
        SetSelectedDualRackType(null)
        if (selectedLeftRackType === null)  {
            SetSelectedLeftRackType({displayName: "None", key: "empty"})
        }
    }
    const dualRackTypeSelected = (type) =>  {
        SetSelectedDualRackType(type)
        SetSelectedLeftRackType(null)
        SetSelectedRightRackType(null)
    }


    const backClicked = () =>   {
        transitionToPreviousStep(["name_blade", null])
    }
    const rightSideConfiguredClicked = () =>    {
        transitionToNextStep(["blade_type", "right"])
        updateBladeSides()
    }
    const backToSelectFrontRackTypeClicked = () =>  {
        transitionToPreviousStep(["blade_type", "left"])
    }


 

    const nextClicked = () =>   {
        updateBladeSides()
        let numberOfChanges = processNewZoneChanges()
        if (numberOfChanges > 0)    {
            transitionToNextStep(["manage_zone_changes", 1])
        }else {
            transitionToNextStep(["final_confirm", null])
        }
    }


    const updateBladeSides = () =>   {
        bladeSides = {}
        if (selectedLeftRackType !== null)  {
            bladeSides["left"] = selectedLeftRackType.key
        }
        if (selectedRightRackType !== null)  {
            bladeSides["right"] = selectedRightRackType.key
        }
        if (selectedDualRackType !== null)  {
            bladeSides["dual"] = selectedDualRackType.key
        }
        bladeSidesSelectedCallback({bladeSides: bladeSides})
    }


    if (true)   {

        switch (currentStep)    {
            case "left":
                return (<>
                    <div className="Walkthrough-Card Walkthrough-CardFill">
                        <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Select the front blade type</div></div>

                        <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                            <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                                <div className="FlexContent-HFill FlexContent-Center">
                                    <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                        <div className="Button-Large-Icon"><RackStandardGrowOut width={(isTablet || isDesktop) ? 168 : 140}/></div>
                                    </>}/>
                                </div>
                                <div className="FlexContent-10 FlexContent-HFill FlexContent-OverflowY">
                                    <div className="FlexContent-5 FlexContent-HFill">
                                        <span style={{fontWeight:"500"}}>Left</span>
                                        <Button 
                                            content={"None"} 
                                            alignContent="Left"
                                            status={"Primary-Toggle"} 
                                            state={selectedLeftRackType && selectedLeftRackType.key === "empty"} onClick={() => leftRackTypeSelected({displayName:"None", key:"empty"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            content={"Nursery"} 
                                            alignContent="Left"
                                            status={"Primary-Toggle"} 
                                            state={selectedLeftRackType && selectedLeftRackType.key === "standard_nursery"} onClick={() => leftRackTypeSelected({displayName:"Nursery", key:"standard_nursery"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            content={"Ext Nursery"} 
                                            alignContent="Left"
                                            status={"Primary-Toggle"} 
                                            state={selectedLeftRackType && selectedLeftRackType.key === "ext_nursery"} onClick={() => leftRackTypeSelected({displayName:"Extended Nursery", key:"ext_nursery"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            content={"Environment"} 
                                            alignContent="Left"
                                            status={"Primary-Toggle"} 
                                            state={selectedLeftRackType && selectedLeftRackType.key === "standard_grow_out_env"} onClick={() => leftRackTypeSelected({displayName:"Standard Environment", key:"standard_grow_out_env"})}
                                            width={"Flex100"}/>
                                    </div>
                                    <div className="FlexContent-5 FlexContent-HFill">
                                        <span style={{fontWeight:"500"}}>Dual</span>
                                        <Button 
                                            content={"Vine Crops"} 
                                            alignContent="Left"
                                            status={"Primary-Toggle"} 
                                            state={selectedDualRackType && selectedDualRackType.key === "vine"} onClick={() => dualRackTypeSelected({displayName:"Dual Vine Crops", key:"vine"})}
                                            width={"Flex100"}/>
                                    </div>
                                </div>
                            </div>
                        </div>                         
                        <div className="Walkthrough-Card-FooterButtons">
                            <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                            <Button content={"Next Step"} onClick={rightSideConfiguredClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                        </div>
                    </div>
                </>)
            case "right":
                return (<>
                    <div className="Walkthrough-Card Walkthrough-CardFill">
                        <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Select the back blade type</div></div>

                        <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                            <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                                <div className="FlexContent-HFill FlexContent-Center" style={{flex:0.5}}>
                                    <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                        <div className="Button-Large-Icon"><RackStandardEnvironment width={(isTablet || isDesktop) ? 168 : 140}/></div>
                                    </>}/>
                                </div>
                                <div className="FlexContent-10 FlexContent-HFill FlexContent-OverflowY" style={{flex:0.5}}>
                                    <div className="FlexContent-5 FlexContent-HFill">
                                        <span>Back</span>
                                        <Button 
                                            disabled={selectedDualRackType !== null} 
                                            alignContent="Left"
                                            content={"None"} 
                                            status={"Primary-Toggle"} 
                                            state={selectedRightRackType && selectedRightRackType.key === "empty"} onClick={() => rightRackTypeSelected({displayName:"None", key:"empty"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            disabled={selectedDualRackType !== null} 
                                            alignContent="Left"
                                            content={"Grow Boards"} 
                                            status={"Primary-Toggle"} 
                                            state={selectedRightRackType && selectedRightRackType.key === "standard_grow_out"} onClick={() => rightRackTypeSelected({displayName:"Grow Out", key:"standard_grow_out"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            disabled={selectedDualRackType !== null} 
                                            alignContent="Left"
                                            content={"Berries"} 
                                            status={"Primary-Toggle"} 
                                            state={selectedRightRackType && selectedRightRackType.key === "berries"} onClick={() => rightRackTypeSelected({displayName:"Berries", key:"berries"})}
                                            width={"Flex100"}/>
                                        <Button 
                                            disabled={selectedDualRackType !== null} 
                                            alignContent="Left"
                                            content={"Vine Crops"} 
                                            status={"Primary-Toggle"} 
                                            state={selectedRightRackType && selectedRightRackType.key === "vine"} onClick={() => rightRackTypeSelected({displayName:"Single Vine Crops", key:"vine"})}
                                            width={"Flex100"}/>
                                    </div>
                                    {selectedDualRackType !== null && 
                                        <div className="FlexContent-5 FlexContent-HFill">
                                            <span>Dual</span>
                                            <Button 
                                                content={"Vine Crops"} 
                                                alignContent="Left"
                                                status={"Primary-Toggle"} 
                                                state={selectedDualRackType && selectedDualRackType.key === "vine"} onClick={() => dualRackTypeSelected({displayName:"Dual Vine Crops", key:"vine"})}
                                                width={"Flex100"}/>
                                        </div>
                                    }                                        
                                </div>
                            </div>
                        </div>                         
                        <div className="Walkthrough-Card-FooterButtons">
                            <Button content={"Back"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={backToSelectFrontRackTypeClicked} width={"Flex50"}/>
                            <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                        </div>
                    </div>
                </>)
            default:
                return (<></>)
        }

    }
    /*else if (isMobile && isDesktop) {
        return (<>
             <PopupModal
                id={"ManageRackWalkthrough_Popup"}
                title={<>
                    
                    <span>Add Rack</span>
                    <span>Step 6/7</span>
                
                </>}
                hideMainSeparators={true}
                contentAlignment={"center"}
                closeCallback={cancelCallback}
                size={"full_screen"}>

                <div className="FlexContent-50">
                    <div className="PopupModal-StandardHeader">Configure Sides</div>
                    <div className={"PopupModal-ContentContainer PopupModal-ContentContainer-Split PopupModal-ContentContainer-30 " + (theme === "white" ? " PopupModal-ContentContainer-White" : "")}>
                        <Button status="Interactive-Neutral" disabled={true} size="Large-IconAction" content={<>
                            <div>Front</div>
                            <div className="Button-Large-Icon"><RackStandardGrowOut width={(isTablet || isDesktop) ? 168 : 140}/></div>
                            <div>{selectedLeftRackType ? selectedLeftRackType.displayName : selectedDualRackType.displayName}</div>
                        </>}/>
                        <div className="FlexContent-10 FlexContent-Fill">
                            <div className="FlexContent-H-10">
                                <div className="FlexContent-5 FlexContent-Fill">
                                    <span>Front</span>
                                    <Button content={"Nursery"} status={"Primary-Toggle"} state={selectedLeftRackType && selectedLeftRackType.key === "nursery"} onClick={() => leftRackTypeSelected({displayName:"Nursery", key:"nursery"})}/>
                                    <Button content={"Ext Nursery"} status={"Primary-Toggle"} state={selectedLeftRackType && selectedLeftRackType.key === "ext_nursery"} onClick={() => leftRackTypeSelected({displayName:"Extended Nursery", key:"ext_nursery"})}/>
                                    <Button content={"Environment"} status={"Primary-Toggle"} state={selectedLeftRackType && selectedLeftRackType.key === "standard_grow_out_env"} onClick={() => leftRackTypeSelected({displayName:"Standard Environment", key:"standard_grow_out_env"})}/>
                                    <Button content={"None"} status={"Primary-Toggle"} state={selectedLeftRackType && selectedLeftRackType.key === "empty"} onClick={() => leftRackTypeSelected({displayName:"None", key:"empty"})}/>
                                </div>
                                <div className="FlexContent-5">
                                    <span>Back</span>
                                    <Button content={"Grow Boards"} status={"Primary-Toggle"} state={selectedRightRackType && selectedRightRackType.key === "standard_grow_out"} onClick={() => rightRackTypeSelected({displayName:"Grow Boards", key:"standard_grow_out"})}/>
                                    <Button content={"Berries"} status={"Primary-Toggle"} state={selectedRightRackType && selectedRightRackType.key === "berries"} onClick={() => rightRackTypeSelected({displayName:"Berries", key:"berries"})}/>
                                    <Button content={"Vine Crops"} status={"Primary-Toggle"} state={selectedRightRackType && selectedRightRackType.key === "vine"} onClick={() => rightRackTypeSelected({displayName:"Single Vine Crops", key:"vine"})}/>
                                    <Button content={"None"} status={"Primary-Toggle"} state={selectedRightRackType && selectedRightRackType.key === "empty"} onClick={() => rightRackTypeSelected({displayName:"None", key:"empty"})}/>
                                </div>
                            </div>
                            <div className="FlexContent-5 FlexContent-Fill">
                                <span>Dual</span>
                                <Button content={"Vine Crops"} status={"Primary-Toggle"} state={selectedDualRackType && selectedDualRackType.key === "vine"} onClick={() => dualRackTypeSelected({displayName:"Dual Vine Crops", key:"vine"})}/>
                            </div>
                                
                        </div>
                        <Button status="Interactive-Neutral" disabled={true} size="Large-IconAction" content={<>
                            <div>Back</div>
                            <div className="Button-Large-Icon"><RackStandardEnvironment width={(isTablet || isDesktop) ? 168 : 140}/></div>
                            <div>{selectedRightRackType ? selectedRightRackType.displayName : selectedDualRackType.displayName}</div>
                        </>}/>
                    </div>                         
                    <div className="FlexContent-H-40">
                        <Button content={"Back"} status={"Neutral"} onClick={backCallback} width={"Flex50"}/>
                        <Button content={"Next Step"} onClick={nextClicked} width={"Flex50"}/>
                    </div>
                </div>
            </PopupModal>
        </>)
    }*/
    
}


SelectRackSidesWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default SelectRackSidesWalkthroughStep