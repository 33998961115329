import './TransplantToGrowOutFromNurseryTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'

import { calculateUnitsFromZones, getGrowById, getGrowGerminationBoardLocations, getGrowGrowoutBoardLocations, getGrowNurseryBoardLocations } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import { getRecipeById, selectAllRecipes } from '../../../../redux/entities/Recipes'
import { useMediaQuery } from 'react-responsive';
import { getRaftTypeById, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes.js';
import Walkthrough from '../../../../components/Walkthrough.js';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities.js';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, selectAllBladeZones, selectAllBlades } from '../../../../redux/entities/service/Blade.js';
import { selectAllRecipeSetpointTypes } from '../../../../redux/AppInfo.js';
import TransplantToGrowOutFromNurseryTask_TransplantList from './TransplantToGrowOutFromNurseryTask_TransplantList.js';
import TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep from './TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep.js';
import TransplantToGrowOutFromNurseryTask_SelectNurseryLocationsStep from './TransplantToGrowOutFromNurseryTask_SelectNurseryLocations.js';
import TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep from './TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep.js';
import TransplantToGrowOutFromNurseryTask_ReviewStep from './TransplantToGrowOutFromNurseryTask_ReviewStep.js';
import TransplantToGrowOutFromNurseryTask_FinalReviewStep from './TransplantToGrowOutFromNurseryTask_FinalReviewStep.js';

const TransplantToGrowOutFromNurseryTask = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }

  const taskConfirmedCompleted = () =>  {
    onCloseTask()
  }

  const allFacilities = useSelector(selectAllFacilities)
  const allRecipes = useSelector(selectAllRecipes)


  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }

    }
  }, [allFacilities])



  const allBlades = useSelector(selectAllBlades)
  const allBladeZones = useSelector(selectAllBladeZones)
  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!loadedInitialBladeGroups && loadingGroupsStatus !== "pending") {
        dispatch(getAllBladeGroupsByFacilityId({facilityIds: [task.facility_id]}))
      }
    }
  }, [loadingGroupsStatus, loadedInitialBladeGroups, allFacilities])


  React.useEffect(() => {

    let bladeZonesToLoad = []
    for (let group of allBladeGroups) {
      if (group.facility_id === task.facility_id) {
        for (let zoneUID of group.zones)  {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneUID)
          if (foundBladeZone === undefined) {
            bladeZonesToLoad.push(zoneUID)
          }
        }
      }
    }
    
    if (bladeZonesToLoad.length > 0) {
      dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
    }

  }, [allBladeZones, allBladeGroups])



  React.useEffect(() => {
    let bladeZoneRecipesToLoad = []
    for (let group of allBladeGroups) {
      if (group.facility_id === task.facility_id) {
        for (let zoneUID of group.zones)  {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneUID)
          if (foundBladeZone !== undefined) {
            if (foundBladeZone.active_zone_recipe !== null) {
              let foundRecipe = allRecipes.find((r) => r.id === foundBladeZone.active_zone_recipe.recipe_id && r.version === foundBladeZone.active_zone_recipe.recipe_version)
              if (foundRecipe === undefined)  {
                bladeZoneRecipesToLoad.push({
                  id: foundBladeZone.active_zone_recipe.recipe_id,
                  version: foundBladeZone.active_zone_recipe.recipe_version
              })
              }
            }
          }
        }
      }
    }
    if (bladeZoneRecipesToLoad.length > 0)  {
      dispatch(getRecipeById({recipes: bladeZoneRecipesToLoad}))
    }
  }, [allBladeZones, allRecipes])

  


  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])



  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow !== undefined)  {
      if (!foundGrow.loaded_growout_locations && !foundGrow.loading_growout_locations) {
        dispatch(getGrowGrowoutBoardLocations({growId: foundGrow.id}))
      }
      if (!foundGrow.loaded_nursery_locations && !foundGrow.loading_nursery_locations) {
        dispatch(getGrowNurseryBoardLocations({growId: foundGrow.id}))
      }
    }
  }, [allGrows])





  let allGrowPlans = useSelector((state) => state.growPlans.growPlans)
  const activeGrowPlan =  activeGrow !== undefined ? allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
      if (foundGrowPlan === undefined)  {
        dispatch(getGrowPlanById({growPlanId: activeGrow.grow_plan_id}))
      }
    }
  }, [activeGrow, allGrowPlans])
  
  
  const activeRecipe =  activeGrow !== undefined ? allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundRecipe = allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipes: [{id: activeGrow.recipe_id, version: activeGrow.recipe_version}]}))
      }
    }
  }, [activeGrow, allGrowPlans])
  



  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))

  const activeSeedlingRaftType = activeGrowPlan !== undefined ? allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id) : undefined
  const activeGrowOutRaftType = activeGrowPlan !== undefined ? allRaftTypes.find((rT) => rT.id === activeGrowPlan.grow_out_raft_type_id) : undefined
  React.useEffect(() => {
    if (activeGrowPlan !== undefined) {
      let foundRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id)
      if (foundRaftType === undefined)  {
        dispatch(getRaftTypeById({raftTypeId: activeGrowPlan.seedling_raft_type_id}))
      }
      let foundGrowOutRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.grow_out_raft_type_id)
      if (foundGrowOutRaftType === undefined)  {
        dispatch(getRaftTypeById({raftTypeId: activeGrowPlan.grow_out_raft_type_id}))
      }
    }
  }, [activeGrowPlan, allRaftTypes])


  const allRecipeSetpointTypes = useSelector((state) => selectAllRecipeSetpointTypes(state))


  const allBladeTypes = [
    {
        id: 1,
        display_name: "Blade Gen S",
        props: {
            "number_of_nursery_troughs": 7,
            "nursery_trough_length": 144, //in inches
            "number_of_grow_out_board_columns": 6,
            "number_of_grow_out_board_rows": 6,
            "number_of_grow_out_berry_troughs": 6,
            "grow_out_berry_trough_length": 144,       
        }
    }
  ]
  let activeBladeType = undefined
  if (activeGrow !== undefined)   {
    activeBladeType = allBladeTypes.find((bT) => bT.id === activeGrow.grow_out_blade_type_id)
  }














  const [activeNurseryLocations, SetActiveNurseryLocations] = React.useState({})
  const activeNurseryLocationsChanged = ({locations}) => {
    SetActiveNurseryLocations(locations)
  }



  const beginTransplantClicked = () => {
    
  }

  const [selectedGrowOutZone, SetSelectedGrowOutZone] = React.useState(null)
  const growOutZoneSelectionUpdated = (growOutZone) => {
    SetSelectedGrowOutZone(growOutZone)
  }
  

  const [selectedNurseryLocations, SetSelectedNurseryLocations] = React.useState({})
  const nurseryBoardSelectionUpdated = (nurseryLocations) => {
    SetSelectedNurseryLocations(nurseryLocations)
  }


  const [numberOfUnitsMoved, SetNumberOfUnitsMoved] = React.useState(0)
  const [haveDefaultUnitsMoved, SetHaveDefaultUnitsMoved] = React.useState(false)
  const numberOfUnitsMovedSelectionUpdated = (units) => {
    SetNumberOfUnitsMoved(units)
  }

  React.useLayoutEffect(() => {
    if (!haveDefaultUnitsMoved) {
      if (activeRecipe !== undefined && activeBladeType !== undefined && activeGrowOutRaftType !== undefined && activeGrowPlan !== undefined) {
        SetNumberOfUnitsMoved(calculateUnitsFromZones(activeRecipe, activeBladeType, 1, activeGrowOutRaftType, activeGrowPlan.grow_out_raft_usage))
        SetHaveDefaultUnitsMoved(true)
      }
    }
  }, [haveDefaultUnitsMoved, activeRecipe, activeBladeType, activeGrowOutRaftType, activeGrowPlan])


  const transplantWasSuccessful = () => {
    SetNumberOfUnitsMoved(0)
    SetSelectedNurseryLocations({})
    SetActiveNurseryLocations({})
    SetHaveDefaultUnitsMoved(false)
  }


  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["transplant_list", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }


  




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      switch (stepKey)    {
        case "transplant_list":
          return (<TransplantToGrowOutFromNurseryTask_TransplantList
            task={task}
            allBladeZones={allBladeZones}
            activeGrow={activeGrow}
            cancelTaskCallback={onCloseTask}
            beginTransplantClicked={beginTransplantClicked}
            locationsConfirmed={activeNurseryLocationsChanged}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep}/>)

        case "select_grow_out_zone":
          return (<TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep
            task={task}
            allBlades={allBlades}
            allBladeZones={allBladeZones}
            cancelTaskCallback={onCloseTask}
            initialSelectedGrowOutZone={selectedGrowOutZone}
            growOutZoneSelectionUpdated={growOutZoneSelectionUpdated}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep}/>)
        
        case "select_nursery_locations":
          return (<TransplantToGrowOutFromNurseryTask_SelectNurseryLocationsStep
            task={task}
            activeGrow={activeGrow}
            allBladeZones={allBladeZones}
            activeRecipe={activeRecipe}
            activeGrowPlan={activeGrowPlan}
            activeBladeType={activeBladeType}
            activeGrowOutRaftType={activeGrowOutRaftType}
            cancelTaskCallback={onCloseTask}
            initialSelectedNurseryLocations={selectedNurseryLocations}
            nurseryBoardSelectionUpdated={nurseryBoardSelectionUpdated}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep}/>)
            
        case "confirm_moved_units":
          return (<TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep
            task={task}
            activeGrow={activeGrow}
            activeRecipe={activeRecipe}
            activeGrowPlan={activeGrowPlan}
            activeBladeType={activeBladeType}
            activeGrowOutRaftType={activeGrowOutRaftType}
            cancelTaskCallback={onCloseTask}
            initialMovedUnits={numberOfUnitsMoved}
            numberOfUnitsMovedConfirmed={numberOfUnitsMovedSelectionUpdated}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
          case "review":
            return (<TransplantToGrowOutFromNurseryTask_ReviewStep
              task={task}
              allBladeZones={allBladeZones}
              activeRecipe={activeRecipe}
              activeGrow={activeGrow}
              activeGrowPlan={activeGrowPlan}
              selectedNurseryLocations={selectedNurseryLocations}
              selectedNumberOfUnitsMoved={numberOfUnitsMoved}
              selectedGrowOutZone={selectedGrowOutZone}
              nurseryLocations={activeNurseryLocations}
              transplantSuccessfulCallback={transplantWasSuccessful}
              transitionToPreviousStep={transitionToPreviousStep} 
              transitionToNextStep={transitionToNextStep} />) 
          case "final_review":
            return (<TransplantToGrowOutFromNurseryTask_FinalReviewStep
              task={task}
              allBladeZones={allBladeZones}
              activeGrow={activeGrow}
              taskCompletedCallback={taskConfirmedCompleted}
              transitionToPreviousStep={transitionToPreviousStep}
              transitionToNextStep={transitionToNextStep}/>)
        default:
            return <></>
      }
  }


  return (<>
    <Walkthrough 
      title="Transplant Crops"
      subtitle={<>#G{activeGrow !== undefined ? activeGrow.id : ""}</>}
      size="full_screen"
      closeCallback={closePopup}
      hideMainSeparators={false}
      initialWalkthroughStep={["transplant_list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default TransplantToGrowOutFromNurseryTask