import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { EmptyContainer } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { RoundToNearest } from '../../../../helpers';

  

const UpdateCurrentReservoirVolumeWalkthroughStep = ({NDS, reservoirInfo, transitionToPreviousStep, transitionToNextStep, initialReservoirVolumeConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const [updatedReservoirVolume, SetUpdatedReservoirVolume] = React.useState(() => {
        let reservoirVolumeRemaining = 0
        if (reservoirInfo["runtime_information"]["volume_remaining"] !== undefined)  {
            reservoirVolumeRemaining = RoundToNearest(parseFloat(reservoirInfo["runtime_information"]["volume_remaining"]) / 1000, 0.1);
        }
        return reservoirVolumeRemaining
    })

    const [selectedUnit, SetSelectedUnit] = React.useState("litres")
    const availableUnits = [
            {"value": "litres", "label": "Litres"}
    ]

    const backClicked = () => {
        transitionToPreviousStep(["confirm_current_reservoir_volume", null])        
    }
    const nextClicked = () =>   {
        initialReservoirVolumeConfirmedCallback(updatedReservoirVolume)
        transitionToNextStep(["select_topup_type", null])
    }

    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Current Volume</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFill FlexContent-HFlex">
                            <div style={{textAlign:"center", fontSize:18, margin: "0 10px"}}>How much of {reservoirInfo.item["display_name"]} is currently stored?</div>
                            <div className="FlexContent-10 FlexContent-Center">
                                <KeypadInput_DisplayField value={updatedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} active={true} verticalPadding={14} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                <DropDownInput value={selectedUnit} options={availableUnits}/>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={updatedReservoirVolume} 
                                    buttonVerticalPadding={5} 
                                    buttonHorizontalPadding={10}
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {SetUpdatedReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {isTablet && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much of {reservoirInfo.item["display_name"]} is currently stored?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={updatedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} verticalPadding={15} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={updatedReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {SetUpdatedReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {(!isTablet && !isMobile) && <>
                        <div className="FlexContent-H-30">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>How much of {reservoirInfo.item["display_name"]} is currently stored?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={updatedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} verticalPadding={30} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={updatedReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {SetUpdatedReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                        
                    </>}
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default UpdateCurrentReservoirVolumeWalkthroughStep