//import './PageHeader.scss';
import React from 'react'
import TextInput from '../../components/input/TextInput'
import Button from '../../components/Button'

const HomePage = ({pageHeaderHelper}) => {

  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Home")
  }, [])


  const [connected, SetConnected] = React.useState(false)
  
  const [bladeService, SetBladeService] = React.useState(null)

  return (
    <>
      
    </>
  )
} 

export default HomePage