import './BladeIssuesPopup.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import RowGroup from '../../../components/RowGroup/RowGroup';
import { useSelector } from 'react-redux';
import { selectBladeIssuesByBladeId } from '../../../redux/entities/service/Blade';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
  

const BladeIssuesPopup = ({bladeId, closeCallback}) => {
   
    const allBladeIssues = useSelector((state) => selectBladeIssuesByBladeId(state, bladeId))

    const activeBladeIssues = Object.values(allBladeIssues).filter((bI) => bI.active)

    return (<>
        <PopupModal
            id={"ManageBladeWalkthrough_Popup"}
            title={<>
                <span>Blade Issues</span>
            </>}
            hideMainSeparators={true}
            closeCallback={closeCallback}
            size={"full"}
            footer={<>
                
            </>}>
            <>
                {activeBladeIssues.length === 0 && <>
                    <div>No active issues</div>
                </>}
                {activeBladeIssues.length > 0 && <>
                    <div className="FlexContent-10" style={{alignContent:"stretch"}}>
                        {activeBladeIssues.map((bladeIssue) => {
                            console.log(bladeIssue)
                            const issueKeyMap = {
                                "primary_aero": {
                                    label: "Aeroponics",
                                    subkeyMap: {
                                        "over_pressure": {content: <>Over Pressure</>},
                                        "low_pressure": {content: <>Low Pressure</>},
                                        "low_liquid_volume": {content: <>Reservoir Severely Low</>}
                                    }
                                },
                                "canopy_air": {
                                    label: "Canopy Air",
                                    subkeyMap: {
                                        "temp_too_high": {content: <>Temperature in the canopy has risen 3C above setpoint</>}
                                    }
                                },
                                "variable_speed_compressor":    {
                                    label: "AC Compressor",
                                    subkeyMap: {
                                        "error_code": {content: <>Error Code: {bladeIssue.info["code"]}</>},
                                        "discharge_over_temp": {content: <>Discharge temperature has risen above 95C</>}
                                    }
                                },
                                "motion_distance_sensor": {
                                    label: "Motion Distance Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_4": {
                                    label: "Nursery A (Upper) Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_3": {
                                    label: "Primary Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_2": {
                                    label: "Nursery B (Lower) Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "primary_dosing": {
                                    label: "Primary Dosing",
                                    subkeyMap: {
                                        "NDS_locked_out": {content: <>
                                            <div className="FlexContent-H-10">
                                                <div>Dosing has been locked out</div>
                                                <div>Restore Now</div>
                                            </div></>
                                        },
                                        "over_pressure": {content: <>Pressure Exceeded 130PSI</>}
                                    }  
                                }
                            }
                            let issueName = bladeIssue.key
                            let issueInfo = bladeIssue.sub_key
                            let foundIssueKey = issueKeyMap[bladeIssue.key]
                            if (foundIssueKey !== undefined)    {
                                issueName = foundIssueKey.label
                                let foundIssueSubkey = foundIssueKey.subkeyMap[bladeIssue.sub_key]
                                if (foundIssueSubkey !== undefined) {
                                    issueInfo = foundIssueSubkey.content
                                }
                            }
                            let issueDate = new Date(Date.parse(bladeIssue.triggered_on))
                            return <>
                                <div key={bladeIssue.local_id} className="FlexContent-H-30" style={{justifyContent: "space-between", alignItems: "center"}}>
                                    <div className="FlexContent-H-10 FlexContent-Center">
                                        <div className="Text-S16">{issueName}</div>
                                        <div className="Text-S14">{issueInfo}</div>
                                    </div>
                                    <div className="Text-Alt12">{issueDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>

                                </div>
                            </>
                        })}
                    </div>
                </>}
            </>
        </PopupModal>
        
    </>)

}



export default BladeIssuesPopup