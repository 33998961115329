import './RecipesPage.scss';
import React from 'react';


import { selectAllChartAxisTypes, selectAllRecipeSetpointTypes  } from '../../../redux/AppInfo'
import Badge from '../../../components/Badge.js'
import { FormatDate, FormatTime, distToSegment, remapRange } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import DropDownInput from '../../../components/input/DropDownInput.js'
import GroupedOptions from '../../../components/input/GroupedOptions.js'
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import SliderInput from '../../../components/input/SliderInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, GetCLIFromPoint, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical, BiExpand} from 'react-icons/bi'
import {RiWindyLine} from 'react-icons/ri'
import {GiTreeRoots} from 'react-icons/gi'
import {MdLightMode} from 'react-icons/md'
import {HiOutlineDuplicate} from 'react-icons/hi'
import {FaLock, FaUnlock, FaTrashAlt} from 'react-icons/fa'
import {IoWaterSharp} from 'react-icons/io5'
import {BsZoomIn, BsZoomOut} from 'react-icons/bs'
import {TbWaveSine, TbWaveSquare, TbTrendingUp2} from 'react-icons/tb'
import {GrTopCorner} from 'react-icons/gr'


import { 
  lightningChart, 
  AxisTickStrategies, 
  Themes, 
  PointShape, 
  SolidLine, 
  emptyLine,
  EmptyFill, 
  FontSettings,
  emptyTick,
  SolidFill,
  ColorHEX,
  ColorRGBA,
  UIElementBuilders,
  emptyFill,
  transparentFill,
  AutoCursorModes,
  IndividualPointFill,
} from '@arction/lcjs'
import { AirSetpoint, Close, ExpandContentAlt, Functions_Instant, Functions_OverTime, Functions_SineWave, Functions_SquareWave, LightingSetpoint, WaterSetpoint } from '../../../assets/icons/Icons.js';
import { useMediaQuery } from 'react-responsive';
import Pill from '../../../components/Pill.js';
import Switch from '../../../components/Switch.js';
import Multibutton from '../../../components/MultiButton.js';
import useMeasure from '../../../useMeasure.js';
import { createLightningChart } from '../../../LC.js';

const setpointChartTheme = {...Themes.light, 
  seriesBackgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),


  seriesBackgroundStrokeStyle: emptyLine,

  backgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),

  backgroundStrokeStyle: emptyLine,
  panelBackgroundFillStyle: new SolidFill({
      color: ColorRGBA( 255, 255, 255, 255 )
  }),

  /*numericTickStrategy: Themes.light.numericTickStrategy
  
  .setMajorTickStyle((majorTicks) =>
    majorTicks
      .setLabelFont(new FontSettings({ size: 12, style: '' }))
        .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  )
  .setMinorTickStyle((minorTicks) =>
      minorTicks
          .setLabelFont(new FontSettings({ size: 12, style: '' }))
          .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: EmptyFill })),
  ),*/
}

const RecipeZonesPage = ({recipe, selectedTimelineItem, selectTimelineItem, timelineItemSelectionOptions, isEditingRecipe}) => {
  const dispatch = useDispatch() 

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop



  const [timelineItems, SetTimelineItems] = React.useState([]);
  const [currentDuration, SetCurrentDuration] = React.useState(60 * 60 * 24);
  const [lightingSpectrumRatios, SetLightingSpectrumRatios] = React.useState({});
  const [isLightingInMaxPPFDConflict, SetIsLightingInMaxPPFDConflict] = React.useState(false)
  const [lightingMaxIntensitySetpoint, SetLightingMaxIntensitySetpoint] = React.useState(0)
  const [CLI, SetCLI] = React.useState(0)
  const [maxPotentialPPFD, SetPotentialMaxPPFD] = React.useState(0)
  const [maxPPFD, SetMaxPPFD] = React.useState(0)
  const [maxIntensityPerSpectrum, SetMaxIntensityPerSpectrum] = React.useState({
    "red": 101,
    "green": 16,
    "blue": 51,
    "farred": 40,
  })
  const [maxPPFDPerSpectrum, SetMaxPPFDPerSpectrum] = React.useState({
    "red": 0,
    "green": 0,
    "blue": 0,
    "farred": 0,
  })

  const chartRef = React.useRef(undefined)
  const addSetpointButtonRef = React.useRef(undefined)
  const [addingSetpoint, SetAddingSetpoint] = React.useState(false)
  const [tempSetpointToBeAdded, SetTempSetpointToBeAdded] = React.useState(undefined)
  const [setpointChartingAreaRef, { height: setpointChartAreaHeight, width: setpointChartAreaWidth, documentTop: setpointChartAreaTop, documentLeft: setpointChartAreaLeft, documentRight: setpointChartAreaRight}] = useMeasure()
  const [tooltipBoardAreaRef, { height: tooltipBoardAreaHeight, width: tooltipBoardAreaWidth, documentTop: tooltipBoardAreaTop, documentLeft: tooltipBoardAreaLeft}] = useMeasure()
  
  const [lightingIntensityBarRef, {height: lightingIntensityBarHeight}] = useMeasure()

  const [yAxisConstantWidth, SetYAxisConstantWidth] = React.useState(isMobile ? 25 : 40)
  const [timeAxisConstantHeight, SetTimeAxisConstantHeight] = React.useState(35)
  const [chartRightPadding, SetChartRightPadding] = React.useState(isMobile ? 15 : 20)
  const [chartLeftBaselinePadding, SetChartLeftBaselinePadding] = React.useState(isMobile ? 25 : 40)
  const [chartLeftPadding, SetChartLeftPadding] = React.useState(chartLeftBaselinePadding)
  

  const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
  const chartAxisTypes = useSelector(selectAllChartAxisTypes)
  const recipeSetpointTypes = useSelector(selectAllRecipeSetpointTypes)
  
  
  const [setpointTimeInterval, SetSetpointTimeInterval] = React.useState(1000 * 60 * 15);
  const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});
  const [numberOfPointersDownOnSetpointCanvas, SetNumberOfPointersDownOnSetpointCanvas] = React.useState(0)
  const [chartingAreaPointerId, SetChartingAreaPointerId] = React.useState(null)
  const [isTouchOverSetpointChart, SetIsTouchOverSetpointChart] = React.useState(false)
  const [pointerOverSetpoint, SetPointerOverSetpoint] = React.useState(undefined)
  const [pointerDownOverSetpoint, SetPointerDownOverSetpoint] = React.useState(undefined)
  const [selectedSetpoint, SetSelectedSetpoint] = React.useState(undefined)
  const [draggingSetpointMoveType, SetDraggingSetpointMoveType] = React.useState(undefined)
  const [pointerOverSetpointChartDate, SetPointerOverSetpointChartDate] = React.useState(undefined)
  const [pointerOverSetpointChartY, SetPointerOverSetpointChartY] = React.useState(0)

  const [maxLightingIntensityAxisLimit, SetMaxLightingIntensityAxisLimit] = React.useState(600)

  const [, forceRerender] = React.useReducer(x => x + 1, 0);

  

  const timelineItemSelected = React.useCallback((timelineItem) => {
    if (selectedTimelineItem != timelineItem && selectTimelineItem !== undefined)  {
      selectTimelineItem(timelineItem)
    }
  })


  React.useEffect(() => {
    if (recipe === undefined) 
      return

    if (recipe.timeline_items != null) {
      SetTimelineItems([...recipe.timeline_items])
    }


  }, [recipe])


  const [activeChartZone, SetActiveChartZone] = React.useState("air")
  const zoneToggled = React.useCallback((key) => {
    if (activeChartZone !== key)   {
      finalizeSelectedSetpoint()
      if (pointerDownOverSetpoint === undefined)  {
        SetSelectedSetpoint(undefined)
        SetPointerOverSetpointChartDate(undefined)
      }
      SetActiveChartZone(key)
    }
  })

  React.useEffect(() => {
    //selectedTimelineItem may have changed
    if (selectedTimelineItem !== undefined && selectedTimelineItem.item !== undefined)  {
      if (selectedTimelineItem.item.duration !== undefined) {
        SetCurrentDuration(selectedTimelineItem.item.duration)
      }
      if (selectedSetpoint !== undefined) {
        //See if the selected setpoint still belongs to the selected timeline item, if not, lets unselect
        if (activeChartZone !== "lighting")  {
          if (selectedTimelineItem.item.setpoint_zones !== undefined)  {
            let foundSetpoint = undefined
            for (let setpointZone of selectedTimelineItem.item.setpoint_zones) {
              foundSetpoint = setpointZone.setpoints.find((s) => s.id === selectedSetpoint.id === undefined)
              if (foundSetpoint !== undefined) {
                break
              }
            }
            if (foundSetpoint === undefined)  {

              finalizeSelectedSetpoint()
              if (pointerDownOverSetpoint === undefined)  {
                SetSelectedSetpoint(undefined)
                SetPointerOverSetpointChartDate(undefined)
              }
            }
          }
        }else {

          if (selectedTimelineItem.item.lighting_intensity_setpoint_zones !== undefined)  {
            let foundLightingIntensitySetpoint = undefined
            for (let setpointZone of selectedTimelineItem.item.lighting_intensity_setpoint_zones) {
              foundLightingIntensitySetpoint = setpointZone.setpoints.find((s) => s.id === selectedSetpoint.id === undefined)
              if (foundLightingIntensitySetpoint !== undefined) {
                break
              }
            }
            if (foundLightingIntensitySetpoint === undefined) {
              finalizeSelectedSetpoint()
              if (pointerDownOverSetpoint === undefined)  {
                SetSelectedSetpoint(undefined)
                SetPointerOverSetpointChartDate(undefined)
              }
            }
          }
        }
      }
      if (selectedTimelineItem.item.lighting_intensity_setpoint_zones[0] !== undefined)  {
        SetLightingSpectrumRatios(selectedTimelineItem.item.lighting_intensity_setpoint_zones[0]["lighting_spectrum_ratios"])

        


      }else {
        SetLightingSpectrumRatios({})
      }
    }else {
      SetCurrentDuration(60 * 60 * 24)
    }  
    updateSetpointChart()
  }, [selectedTimelineItem])

  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.lastSetpointChartInterval.start = 0
      chartRef.current.lastSetpointChartInterval.end = currentDuration * 1000
      chartRef.current.setpointChartDateAxis.setInterval({start: 0, end: currentDuration * 1000})
    }
  }, [currentDuration])

  /*React.useEffect(() => {
    let maxCapableIntensity = //1142.8258967629044;
    foreach (RecipeLightingSpectrum spectrum in Spectrums.Where(p => p.Ratio > p.RatioAtMaxTotalLightIntensity)) { //Checking all ratios that are higher than what is capable at max
        double spectrumLimitingIntensity = Math.Round(100 / spectrum.Ratio * spectrum.MaxPPFD, 4);
        if (spectrumLimitingIntensity < maxCapableIntensity)
            maxCapableIntensity = spectrumLimitingIntensity;
    }

    if (maxCapableIntensity != maxPPFD) {
      SetMaxPPFD(maxCapableIntensity)
    }
  }, [lightingSpectrumRatios, maxLightingSpectrumRatios])*/

  React.useEffect(() => {
      //maxIntensityPerSpectrum
      //maxPPFDPerSpectrum
      if (selectedTimelineItem === undefined) {
        return
      }
      let lightBarSurfaceArea = 0
      let numberOfLightBars = 0
      if (selectedTimelineItem.type === "nursery_cycle")  {
        lightBarSurfaceArea = 8.92326
        numberOfLightBars = 24
      }else if (selectedTimelineItem.type === "grow_zone_cycle") {
        lightBarSurfaceArea = 1.11484
        numberOfLightBars = 3
      }
      
      let newMaxPPFD = {}
      let currentMaxPPFD = 0
      let maxPotentialPPFD = 0
      let maxTotalIntensity = 0

      let currentMaxTotalIntensity = 0

      //Step 1, get the max intensity under perfect ratio conditions
      for (let [key, maxIntensity] of Object.entries(maxIntensityPerSpectrum))  {
        if (lightingSpectrumRatios[key] !== 0)  {
          maxTotalIntensity += maxIntensity
        }
      }

      //Step 2, determine if any spectrum ratio is going to bring our max down
      for (let [key, ratio] of Object.entries(lightingSpectrumRatios))  {
        let contributedIntensity = (ratio / 100) * maxTotalIntensity
        if (contributedIntensity > maxIntensityPerSpectrum[key])  {
          contributedIntensity = maxIntensityPerSpectrum[key]
        }
        
        let currentTotalIntensity = contributedIntensity / (ratio / 100)
        if (currentTotalIntensity < maxTotalIntensity)  {
          maxTotalIntensity = currentTotalIntensity
        }
      }



      //Get the contributed ratio and cap it at what the max is in maxIntensityPerSpectrum
      /*for (let [key, ratio] of Object.entries(lightingSpectrumRatios))  {
        let contributedIntensity = (ratio / 100) * maxTotalIntensity
        if (contributedIntensity > maxIntensityPerSpectrum[key])  {
          contributedIntensity = maxIntensityPerSpectrum[key]
        }
        currentMaxTotalIntensity += contributedIntensity
      }*/

      //maxPotentialPPFD = maxTotalIntensity * numberOfLightBars / lightBarSurfaceArea
      currentMaxPPFD = maxTotalIntensity * numberOfLightBars / lightBarSurfaceArea

      currentMaxPPFD = Math.round(currentMaxPPFD * 10) / 10

      SetMaxPPFDPerSpectrum(newMaxPPFD)
      //SetPotentialMaxPPFD(maxPotentialPPFD)
      SetMaxPPFD(currentMaxPPFD)
  }, [lightingSpectrumRatios, selectedTimelineItem])

  let [setpointTypeToggles, SetSetpointTypeToggles] = React.useState({

    air: {
      selectedSetpointType: null, setpointTypes: {
        temp: { label: "Temp", identifier: "air_temp", color: "rgb(51,160,44)", highlightColor: "rgb(31,140,24)", selectColor: "rgb(51,160,44)", yAxis: "temp", active: false, defaultInitialValue: 20, defaultInitialRange: 2, defaultAmplitude: 5, defaultFrequency: 2 },
        rh: { label: "RH", identifier: "air_rh", color: "rgb(135,125,185)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "rh", active: false, defaultInitialValue: 50, defaultInitialRange: 10, defaultAmplitude: 15, defaultFrequency: 2 },
        cO2: { label: "CO₂", identifier: "air_co2", color: "rgb(150,155,0)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "co2", active: false, defaultInitialValue: 400, defaultInitialRange: 100, defaultAmplitude: 200, defaultFrequency: 2 },
        vpd: { label: "VPD", identifier: "air_vpd", color: "rgb(21,120,90)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "vpd", active: false, defaultInitialValue: 1.0, defaultInitialRange: 0.1, defaultAmplitude: 0.05, defaultFrequency: 2 },
        airFlow: { label: "Air Speed", identifier: "air_flow", color: "rgb(31,120,84)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "speed", active: false, defaultInitialValue: 0, defaultInitialRange: 0, defaultAmplitude: 10, defaultFrequency: 2 }
      }
    },
    root: {
      selectedSetpointType: null, setpointTypes: {
        temp: { label: "Temp", identifier: "water_temp", color: "rgb(101,120,64)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "temp", active: false, defaultInitialValue: 15, defaultInitialRange: 2, defaultAmplitude: 2, defaultFrequency: 2 },
        pH: { label: "pH", identifier: "water_ph", color: "rgb(255,0,86)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "ph", active: false, defaultInitialValue: 7, defaultInitialRange: 0.5, defaultAmplitude: 0.5, defaultFrequency: 2 },
        EC: { label: "EC", identifier: "water_ec", color: "rgb(0,0,139)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "ec", active: false, defaultInitialValue: 0, defaultInitialRange: 100, defaultAmplitude: 200, defaultFrequency: 2 },
        ORP: { label: "ORP", identifier: "water_orp", color: "rgb(1,0,103)", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", yAxis: "orp", active: false, defaultInitialValue: 0, defaultInitialRange: 0.1, defaultAmplitude: 1, defaultFrequency: 2 },
      }
    },
    lighting: {
      setpointTypes: {
        red: { label: "Red", shortKey: "R", identifier: "red", color: "rgb(216,44,13)", yAxis: "light_intensity", active: false, locked: false, defaultInitialValue: 0 },
        green: { label: "Green", shortKey: "G", identifier: "green", color: "rgb(0,128,96)", yAxis: "light_intensity", active: false, locked: false, defaultInitialValue: 0 },
        blue: { label: "Blue", shortKey: "B", identifier: "blue", color: "rgb(46,114,210)", yAxis: "light_intensity", active: false, locked: false, defaultInitialValue: 0 },
        farred: { label: "Far Red", shortKey: "FR", identifier: "farred", color: "rgb(104,20,5)", yAxis: "light_intensity", active: false, locked: false, defaultInitialValue: 0 },
      }
    },
  });

  React.useLayoutEffect(() => {
    if (chartRef.current === undefined)
      return


    if (activeChartZone === "lighting" || setpointTypeToggles[activeChartZone].selectedSetpointType) {
      SetChartLeftPadding(0)
    } else {
      SetChartLeftPadding(chartLeftBaselinePadding)
    }
    chartRef.current.setpointChart.setPadding({ top: 0, left: chartLeftPadding, right: chartRightPadding, bottom: 0 })
  }, [chartRef, setpointTypeToggles, activeChartZone, chartLeftBaselinePadding])


  React.useEffect(() => {
    if (chartRef.current === undefined)
      return


    chartRef.current.setpointChart.setPadding({ top: 0, left: chartLeftPadding, right: chartRightPadding, bottom: 0 })
  }, [chartRef, chartLeftPadding])

  React.useEffect(() => {
    if (recipe === undefined || selectedTimelineItem === undefined)
      return
    
      console.log(selectedTimelineItem.type, recipe.grow_out_type)
    
    if (selectedTimelineItem.type === "nursery_cycle")  {
      setpointTypeToggles.root.setpointTypes.ebbflowFrequency = { label: "Ebb & Flow", identifier: "ebbflow_frequency", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)", color: "rgb(158,0,142)", yAxis: "interval", active: false, defaultInitialValue: 24, defaultInitialRange: 0 }
      if (setpointTypeToggles.root.setpointTypes.sprayRate !== undefined)  {
        delete setpointTypeToggles.root.setpointTypes.sprayRate
      }
      if (setpointTypeToggles.root.setpointTypes.dripRate !== undefined)  {
        delete setpointTypeToggles.root.setpointTypes.dripRate
      }

    }else if (selectedTimelineItem.type === "grow_zone_cycle") {
      if (recipe.grow_out_type === "grow_boards") {
        setpointTypeToggles.root.setpointTypes.sprayRate = { label: "Spray Rate", identifier: "spray_rate", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)",  color: "rgb(158,0,142)", yAxis: "percent", active: false, defaultInitialValue: JSON.stringify({r: 5, f: 60}), defaultInitialRange: 1}
        if (setpointTypeToggles.root.setpointTypes.dripRate !== undefined)  {
          delete setpointTypeToggles.root.setpointTypes.dripRate
        }
        if (setpointTypeToggles.root.setpointTypes.ebbflowFrequency !== undefined)  {
          delete setpointTypeToggles.root.setpointTypes.ebbflowFrequency
        }
      }else if (recipe.grow_out_type === "berry_troughs") {
        setpointTypeToggles.root.setpointTypes.dripRate = { label: "Drip Rate", identifier: "drip_irrigation_rate", highlightColor: "rgb(51,160,44)", selectColor: "rgb(51,160,44)",  color: "rgb(158,0,142)", yAxis: "percent", active: false, defaultInitialValue: JSON.stringify({r: 5, f: 60}), defaultInitialRange: 1}
        if (setpointTypeToggles.root.setpointTypes.sprayRate !== undefined)  {
          delete setpointTypeToggles.root.setpointTypes.sprayRate
        }
        if (setpointTypeToggles.root.setpointTypes.ebbflowFrequency !== undefined)  {
          delete setpointTypeToggles.root.setpointTypes.ebbflowFrequency
        }
      }
    }

    
    SetSetpointTypeToggles({...setpointTypeToggles})
    
    
  }, [recipe, selectedTimelineItem])
 
  const activateSetpointType = (key, setpointKey, callback = undefined) =>  {
 
    const performPostActivation = () => {
    
    const setpointType = recipeSetpointTypes.find((t) => t.name == setpointTypeToggles[key].setpointTypes[setpointKey].identifier)
    let newSetpointZones = []
      if (selectedTimelineItem.item.setpoint_zones.find(sz => sz.type_id === setpointType.id && sz.zone_index === 0) === undefined) {
        newSetpointZones.push({
          id: selectedTimelineItem.item.currentSetpointTempId,
          type_id: setpointType.id,
          mode: "manual",
          zone_index: 0,
          setpoints: [{
            id: selectedTimelineItem.item.currentSetpointTempId + 1,
            index: 1,
            time: 0,
            value: setpointTypeToggles[key].setpointTypes[setpointKey].defaultInitialValue.toString(),
            range: setpointTypeToggles[key].setpointTypes[setpointKey].defaultInitialRange.toString(),
            function: "instant",
            function_params: {}
          }],
        })
      }
    

      //console.log(newSetpointZones)
      dispatch(pushRecipeChange({
        recipe: {
          ...recipe,
          timeline_items: [...recipe.timeline_items.map((timelineItem) => {
            if (timelineItem.id != selectedTimelineItem.id) {
              return timelineItem
            }
            return {
              ...timelineItem,
              item: {
                ...timelineItem.item,
                setpoint_zones: [...selectedTimelineItem.item.setpoint_zones, ...newSetpointZones],
                currentSetpointTempId: timelineItem.item.currentSetpointTempId + 2
              }
            }
          })]
        }
      }))

    }
    
    if (key == "air" && (setpointKey == "temp" || setpointKey == "rh" || setpointKey == "vpd")) {
      //Conflicts -- need to validate with user to deactivate one of the other setpoint types
      if (setpointKey == "temp" && setpointTypeToggles[key].setpointTypes["rh"].active &&  setpointTypeToggles[key].setpointTypes["vpd"].active)  {
        if (callback) {
          callback(false)
        }
      }else if (setpointKey == "rh" && setpointTypeToggles[key].setpointTypes["temp"].active &&  setpointTypeToggles[key].setpointTypes["vpd"].active)  {
        if (callback) {
          callback(false)
        }
        
      }else if (setpointKey == "vpd" && setpointTypeToggles[key].setpointTypes["temp"].active &&  setpointTypeToggles[key].setpointTypes["rh"].active)  {
        if (callback) {
          callback(false)
        }
        
      }else {
        setpointTypeToggles[key].setpointTypes[setpointKey].active = true
        SetSetpointTypeToggles({...setpointTypeToggles})
        performPostActivation()
        if (callback) {
          callback(true)
        }
      }
    }else {
      setpointTypeToggles[key].setpointTypes[setpointKey].active = true
      SetSetpointTypeToggles({...setpointTypeToggles})
      performPostActivation()
      if (callback) {
        callback(true)
      }
    }
  }

  const deactivateSetpointType = (key, setpointKey) =>  {
    setpointTypeToggles[key].selectedSetpointType = null
    setpointTypeToggles[key].setpointTypes[setpointKey].active = false
    SetSetpointTypeToggles({...setpointTypeToggles})
    
    const setpointType = recipeSetpointTypes.find((t) => t.name == setpointTypeToggles[key].setpointTypes[setpointKey].identifier)
    
    dispatch(pushRecipeChange({
      recipe: {
        ...recipe,
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              setpoint_zones: selectedTimelineItem.item.setpoint_zones.filter(function (s) {
                return s.type_id != setpointType.id
              })
            }
          }
        })]
      }
    }))
  }

  const toggleDataTypeSelected = React.useCallback((key, setpointKey) => {
    if (setpointTypeToggles[key].selectedSetpointType != setpointKey) {
      if (setpointTypeToggles[key].setpointTypes[setpointKey].active) {
        setpointTypeToggles[key].selectedSetpointType = setpointKey
        SetSetpointTypeToggles({...setpointTypeToggles})
      }else {
        activateSetpointType(key, setpointKey, (success) => {
          if (success)  {
            setpointTypeToggles[key].selectedSetpointType = setpointKey
            SetSetpointTypeToggles({...setpointTypeToggles})
          }
        })
      }

    }else {
      setpointTypeToggles[key].selectedSetpointType = null
      SetSetpointTypeToggles({...setpointTypeToggles})
    }
  })

  const toggleDataTypeActive = React.useCallback((key, setpointKey) =>  {
    if (setpointTypeToggles[key].setpointTypes[setpointKey].active) {
      setpointTypeToggles[key].setpointTypes[setpointKey].active = false
      if (setpointTypeToggles[key].selectedSetpointType == setpointKey)  {
        let foundActive = false
        for (const currentSetpointKey in setpointTypeToggles[key].setpointTypes) {
          if (setpointTypeToggles[key].setpointTypes[currentSetpointKey].active) {
            setpointTypeToggles[key].selectedSetpointType = currentSetpointKey
            foundActive = true
            break
          }
        }
        if (!foundActive) {
          setpointTypeToggles[key].selectedSetpointType = null
        }
      }
      deactivateSetpointType(key, setpointKey)
    }else {
      activateSetpointType(key, setpointKey, (success) => {
        setpointTypeToggles[key].selectedSetpointType = setpointKey
        SetSetpointTypeToggles({...setpointTypeToggles})
      })
    }
  })


  const createTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

    let minorTickInterval = 1000 * 60 * 60 * 24 //every day
    let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

    let minorTickFormat = '~HH:~MM:~SS'
    let majorTickFormat = '~HH:~MM'

    
    if (timeDelta <= 1000 * 60 * 1)  { //1 minute span -- we want ticks every 30 seconds
        minorTickInterval = 1000 * 5 //every 5 seconds
        majorTickInterval = 1000 * 60 //every minute
        minorTickFormat = '~HH:~MM:~SS'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 5)  { //5 minute span -- we want ticks every 30 seconds
        minorTickInterval = 1000 * 10 //every 10 seconds
        majorTickInterval = 1000 * 60 //every minute
        minorTickFormat = '~HH:~MM:~SS'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 10)  { //10 minute span -- we want ticks every minute
        minorTickInterval = 1000 * 30 //every 30 seconds
        majorTickInterval = 1000 * 60 * 5 //every 5 minutes
        minorTickFormat = '~HH:~MM:~SS'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 30)  { //30 minute span -- we want ticks every 5 minutes
        minorTickInterval = 1000 * 60 //every 60 seconds
        majorTickInterval = 1000 * 60 * 60 //every 10 minutes
        minorTickFormat = '~HH:~MM:~SS'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 60)  { //60 minute span -- we want ticks every 15 minutes
        minorTickInterval = 1000 * 60 * 5 //every 5 minutes
        majorTickInterval = 1000 * 60 * 60 //every 30 minutes
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 60 * 3)  { //3 hour span -- we want ticks every 30 minutes
        minorTickInterval = 1000 * 60 * 15 //every 15 minutes
        majorTickInterval = 1000 * 60 * 60 //every hour
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 60 * 6)  { //6 hour span -- we want ticks every hour
        minorTickInterval = 1000 * 60 * 60 //every hour
        majorTickInterval = 1000 * 60 * 60 * 24 //every 6 hours
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'
    }else if (timeDelta <= 1000 * 60 * 60 * 12)  { //12 hour span -- we want ticks every hour
        minorTickInterval = 1000 * 60 * 60 //every hour
        majorTickInterval = 1000 * 60 * 60 * 24 //every 6 hours
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'
    }else if (timeDelta <= 1000 * 60 * 60 * 24)  { //1 day span -- we want ticks every 6 hours
        minorTickInterval = 1000 * 60 * 60 * 3//every 3 hours
        majorTickInterval = 1000 * 60 * 60 * 24 //every 24 hours
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'

    }else if (timeDelta <= 1000 * 60 * 60 * 24 * 7)  { //7 day span -- we want ticks every day
        minorTickInterval = 1000 * 60 * 60 * 6 //every hour
        majorTickInterval = 1000 * 60 * 60 * 24 //every day
        minorTickFormat = '~HH:~MM'
        majorTickFormat = '~HH:~MM'
    }

    // Major ticks every 1000 units.
    for (let majorTickPos = start - (start % majorTickInterval); majorTickPos <= end; majorTickPos += majorTickInterval) {
        if (majorTickPos >= start) {
            const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
                .setTextFormatter(() => FormatTime(majorTickPos, majorTickFormat))
                .setValue(majorTickPos)
                .setMarker(marker => marker
                    .setTextFont(new FontSettings({ size: 14, style: '' }))
                )
                .setTickLength(8)
                .setTickLabelPadding(-4)
                .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA( 100 )))
            tickList.push(tick)
        }
    }
    // Major ticks every 100 units, but not at same interval as major ticks.
    for (let minorTickPos = start - (start % minorTickInterval); minorTickPos <= end; minorTickPos += minorTickInterval) {
      if (minorTickPos >= start && minorTickPos % majorTickInterval !== 0) {
          const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
              .setTextFormatter(() => FormatTime(minorTickPos, minorTickFormat))
              .setValue(minorTickPos)
              .setMarker(marker => marker
                  .setTextFont(new FontSettings({ size: 12, style: '' }))
              )
              .setTickLabelPadding(-4)
              .setTickLength(4)
              .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
          tickList.push(tick)
      }
    }
  }




  const updateSetpointChartAxisTicks = React.useCallback((start, end) =>    {
    if (chartRef.current === undefined)
      return //Shouldn't be possible, as this callback gets added after a ref has been made

    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()


    if (start == chartRef.current.lastSetpointChartTickRange.start && end == chartRef.current.lastSetpointChartTickRange.end)
        return

    const timeDelta = end - start
    if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastSetpointChartTickRange.end - chartRef.current.lastSetpointChartTickRange.start)) {
        //Zoom
      
        chartRef.current.setpointChartTimeTicks = chartRef.current.setpointChartTimeTicks.filter(tick => {
            tick.dispose()
            return false
        })
        createTicksInRangeX(chartRef.current.setpointChartDateAxis, chartRef.current.setpointChartTimeTicks, timeDelta, start, end)
        

    }else {
        //Pan

        //compare last vs now to see if we need to add ticks
        if (end > chartRef.current.lastSetpointChartTickRange.end) {
            createTicksInRangeX(chartRef.current.setpointChartDateAxis, chartRef.current.setpointChartTimeTicks, timeDelta, chartRef.current.lastSetpointChartTickRange.end, end)
        }
        if (start < chartRef.current.lastSetpointChartTickRange.start)    {
            createTicksInRangeX(chartRef.current.setpointChartDateAxis, chartRef.current.setpointChartTimeTicks, timeDelta, start, chartRef.current.lastSetpointChartTickRange.start)
        }

        //compare last vs now to see if we need to remove ticks
        chartRef.current.setpointChartTimeTicks = chartRef.current.setpointChartTimeTicks.filter(tick => {
            if (tick.getValue() < start || tick.getValue() > end) {
                // Tick is out of view.
                tick.dispose()
                return false
            } else {
                return true
            }
        })
    }

    chartRef.current.lastSetpointChartTickRange.start = start
    chartRef.current.lastSetpointChartTickRange.end = end

  })

  
  let processingSetpointChartInterval = false
  const checkSetpointChartInterval = React.useCallback((start, end) =>    {
    if (chartRef.current === undefined)
        return
    if ((start == chartRef.current.lastSetpointChartInterval.start && end == chartRef.current.lastSetpointChartInterval.end) || processingSetpointChartInterval)
        return
        processingSetpointChartInterval = true
    let changed = false;

    const timeDelta = end - start
    if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastSetpointChartInterval.end - chartRef.current.lastSetpointChartInterval.start)) {
       //Zoom
      if (start <= 0)    {
          start = 0
          changed = true
      }
      if (end >= currentDuration * 1000)  {
          end = currentDuration * 1000
          changed = true
      }
    }else {
      //Pan
      if (end > currentDuration * 1000)    {
        start = currentDuration * 1000 - timeDelta
        end = currentDuration * 1000
        changed = true
      }
      if (start < 0)    {
          start = 0
          end = 0 + timeDelta
          changed = true
      }
    }



    chartRef.current.lastSetpointChartInterval.start = start
    chartRef.current.lastSetpointChartInterval.end = end
    if (changed)    {
        chartRef.current.setpointChartDateAxis.setInterval({start: 0, end: end})
    }

    updateSetpointChartAxisTicks(start, end)
    
    processingSetpointChartInterval = false
  })


  const plotSquareWave = (fromTime, value, toTime, amplitude, frequency, min, max, forcePlot) => {
    let data = []
    
    if ((amplitude != 0 || forcePlot) && frequency != 0) {
        const hz = ((toTime - fromTime) / (1000 * 60 * 60)) * frequency;
        const sampleRate = Math.ceil(60 * hz);
        const timeDelta = (toTime - fromTime) / sampleRate;
        let lastPhase = 0;
        for (let n = 0; n <= sampleRate; n++) {
            const pointTime = fromTime + (timeDelta * n);
            if (fromTime + (timeDelta * n) > toTime) {
                pointTime = toTime - 1;
            }
            
            const phase = (1 * Math.sin((2 * Math.PI * n * hz) / sampleRate) >= 0) ? 1 : -1;
            if (phase != lastPhase) {
                if (lastPhase == 0) {
                    data.push({x: pointTime, y: value})
                    if (phase == 1) {
                      data.push({x: pointTime, y: value + amplitude})
                    } else {
                      data.push({x: pointTime, y: value - amplitude})
                    }
                } else {
                    if (phase == 1 && lastPhase == -1) {
                      data.push({x: pointTime, y: value - amplitude})
                      data.push({x: pointTime, y: value + amplitude})
                    } else if (phase == -1 && lastPhase == 1) {
                      data.push({x: pointTime, y: value + amplitude})
                      data.push({x: pointTime, y: value - amplitude})
                    }
                }
                lastPhase = phase;
            }


        }
    } else {
      data.push({x: fromTime, y: value});
      data.push({x: toTime, y: value});
    }

    return data
  }
  const plotSineWave = (fromTime, value, toTime, amplitude, frequency, min, max, forcePlot) => {
    let data = []
    
    if ((amplitude != 0 || forcePlot) && frequency != 0) {
        const hz = ((toTime - fromTime) / (1000 * 60 * 60)) * frequency;
        const sampleRate = Math.ceil(60 * hz);
        const timeDelta = (toTime - fromTime) / sampleRate;
        for (let n = 0; n <= sampleRate; n++) {
            const pointTime = fromTime + (timeDelta * n);
            if (fromTime + (timeDelta * n) > toTime) {
                pointTime = toTime - 1;
            }
            let pointValue = value + amplitude * Math.sin(2 * Math.PI * n * hz / sampleRate);

            if (pointValue < min)
                pointValue = min;
            else if (pointValue > max)
                pointValue = max;
                data.push({x: pointTime, y: pointValue});

        }
    } else {
      data.push({x: fromTime, y: value});
      data.push({x: toTime, y: value});
    }

    return data
  }

  const resolveConflictingLightingSetpoints = () => {
    dispatch(pushRecipeChange({
      recipe: {
        ...recipe,
        timeline_items: [...recipe.timeline_items.map((timelineItem) => {
          if (timelineItem.id != selectedTimelineItem.id) {
            return timelineItem
          }
          return {
            ...timelineItem,
            item: {
              ...timelineItem.item,
              lighting_intensity_setpoint_zones: [...selectedTimelineItem.item.lighting_intensity_setpoint_zones.map((lisz) => {
                if (lisz.zone_index === 0) {
                  return {
                    ...lisz, setpoints: lisz.setpoints.map((setpoint, setpointIndex) => {
                      if (setpoint.value > maxPPFD) {
                        return {...setpoint, value: maxPPFD}
                      }else {
                        return setpoint
                      }
                    })
                  }
                }
                return { ...lisz }
              })]
            }
          }
        })]
      }
    }))
  }

  const calculateSetpoints = React.useCallback(() =>  {
    if (!chartRef.current || (selectedTimelineItem === undefined || selectedTimelineItem === null) || !haveAppInfo || selectedTimelineItem.item === null) 
      return

    //setpointTypes
    chartRef.current.setpointTypes = {}

    let lighting_intensity_setpoints = {}

    if (selectedTimelineItem.item.setpoint_zones)  {
      for (const currentSetpointZone of selectedTimelineItem.item.setpoint_zones) {
        const setpointType = recipeSetpointTypes.find((t) => t.id == currentSetpointZone.type_id)
        if (setpointType !== undefined) {
          if (chartRef.current.setpointTypes[setpointType.name] === undefined)  {
            chartRef.current.setpointTypes[setpointType.name] = {}
          }
          if (chartRef.current.setpointTypes[setpointType.name][currentSetpointZone.zone_index] === undefined)  {
            chartRef.current.setpointTypes[setpointType.name][currentSetpointZone.zone_index] = {
              data: [],
              setpoints: []
            }
          }




          for (const currentSetpoint of currentSetpointZone.setpoints) {
            let setpoint = {...currentSetpoint, type_id: currentSetpointZone.type_id}
            
            let foundTempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => setpoint.id == tS.id)
            if (foundTempSetpointInfo !== undefined)  {
              if (foundTempSetpointInfo.time !== undefined)  {
                setpoint.time = foundTempSetpointInfo.time
              }
              if (foundTempSetpointInfo.value !== undefined)  {
                if (setpointType.name == "spray_rate")  {
                  setpoint.value = foundTempSetpointInfo.value.r
                  setpoint.fValue = foundTempSetpointInfo.value.f
                }else if (setpointType.name == "drip_irrigation_rate")  {
                  setpoint.value = foundTempSetpointInfo.value.r
                  setpoint.fValue = foundTempSetpointInfo.value.f
                }else {
                  setpoint.value = foundTempSetpointInfo.value
                }
              }

              if (foundTempSetpointInfo.function !== undefined)  {
                setpoint.function = foundTempSetpointInfo.function
              }
              if (foundTempSetpointInfo.function_params !== undefined)  {
                setpoint.function_params = foundTempSetpointInfo.function_params
              }
            }else {
              if (setpointType.name == "spray_rate")  {
                const sprayRateInfo = JSON.parse(setpoint.value)
                setpoint = {...setpoint, value: parseFloat(sprayRateInfo.r), fValue: parseFloat(sprayRateInfo.f)}
              }else if (setpointType.name == "drip_irrigation_rate")  {
                const dripRateInfo = JSON.parse(setpoint.value)
                setpoint = {...setpoint, value: parseFloat(dripRateInfo.r), fValue: parseFloat(dripRateInfo.f)}
              }else {
                setpoint = {...setpoint, value: parseFloat(setpoint.value)}
              }
              
            }

            let foundSetpoint = chartRef.current.setpointTypes[setpointType.name][currentSetpointZone.zone_index].setpoints.find((s) => s.id == setpoint.id)
            if (foundSetpoint === undefined)  {
              
              chartRef.current.setpointTypes[setpointType.name][currentSetpointZone.zone_index].setpoints.push(setpoint)
            }
  
        

            //Check for master relationship
            for (let relationship of selectedTimelineItem.item.relationships) {
              if (relationship.master_type_id === setpointType.id)  {
                const slaveSetpointType = recipeSetpointTypes.find((t) => t.id == relationship.slave_type_id)
                let slaveSetpoint = {
                  function: setpoint.function,
                  function_params: setpoint.function_params,
                  index: setpoint.index,
                  time: setpoint.time,
                  value: setpoint.value,
                  masterSetpoint: setpoint
                }
                if (slaveSetpointType.name === "spray_rate")  {
                  if (relationship.function === "remap_range")  {
                    slaveSetpoint.value = remapRange(setpoint.value, [parseFloat(relationship.values[0]), parseFloat(relationship.values[1])], [parseFloat(relationship.values[2]), parseFloat(relationship.values[3])])
                  }else if (relationship.function === "offset") {
  
                  }
                }else {
                  if (relationship.function === "remap_range")  {
                    slaveSetpoint.value = remapRange(setpoint.value, [parseFloat(relationship.values[0]), parseFloat(relationship.values[1])], [parseFloat(relationship.values[2]), parseFloat(relationship.values[3])])
                  }else if (relationship.function === "offset") {
                    slaveSetpoint.value = setpoint.value + parseFloat(relationship.values[0])
                  }
                }
                if (setpoint.slaveSetpoints === undefined)  {
                  setpoint.slaveSetpoints = []
                }
                if (slaveSetpointType.name !== "light_intensity")  {
                  if (chartRef.current.setpointTypes[slaveSetpointType.name] === undefined)  {
                    chartRef.current.setpointTypes[slaveSetpointType.name] = {}
                  }
                  if (chartRef.current.setpointTypes[slaveSetpointType.name][currentSetpointZone.zone_index] === undefined)  {
                    chartRef.current.setpointTypes[slaveSetpointType.name][currentSetpointZone.zone_index] = {
                      data: [],
                      setpoints: [slaveSetpoint]
                    }
                  }else {
                    let foundSlaveSetpoint = chartRef.current.setpointTypes[slaveSetpointType.name][currentSetpointZone.zone_index].setpoints.find((s) => s.id == setpoint.id)
                    if (foundSlaveSetpoint === undefined)  {
                      
                      chartRef.current.setpointTypes[slaveSetpointType.name][currentSetpointZone.zone_index].setpoints.push(slaveSetpoint)
                    }
                  }

                  
                }else {
                  if (lighting_intensity_setpoints[currentSetpointZone.zone_index] === undefined)  {
                    lighting_intensity_setpoints[currentSetpointZone.zone_index] = [slaveSetpoint]
                  }else {
                    let foundSlaveSetpoint = lighting_intensity_setpoints.find((s) => s.id == setpoint.id)
                    if (foundSlaveSetpoint === undefined)  {
                      
                      lighting_intensity_setpoints[currentSetpointZone.zone_index].push(slaveSetpoint)
                    }
                  }                    
                }
              }
            }
          }
        }
      }
    }

    //Add temp adding setpoint if exists
    if (tempSetpointToBeAdded !== undefined)  {
      console.log(tempSetpointToBeAdded)
      if (activeChartZone !== "lighting")  {
        const setpointType = recipeSetpointTypes.find((t) => t.id == tempSetpointToBeAdded.type_id)
        if (setpointType.name == "spray_rate")  {
          const sprayInfo = JSON.parse(tempSetpointToBeAdded.value)
          
          //if (chartRef.current.setpointTypes[setpointType.name])
          //for (let zoneIndex of activeLightingZones) {
          //TODO fix for specific zones
          chartRef.current.setpointTypes[setpointType.name][tempSetpointToBeAdded.zone_index].setpoints.push({
            ...tempSetpointToBeAdded,
            value: sprayInfo.r,
            fValue: sprayInfo.f
          })
        }else if (setpointType.name == "drip_irrigation_rate")  {
          const dripInfo = JSON.parse(tempSetpointToBeAdded.value)
          chartRef.current.setpointTypes[setpointType.name][tempSetpointToBeAdded.zone_index].setpoints.push({
            ...tempSetpointToBeAdded,
            value: dripInfo.r,
            fValue: dripInfo.f
          })
        }else {
          chartRef.current.setpointTypes[setpointType.name][tempSetpointToBeAdded.zone_index].setpoints.push(tempSetpointToBeAdded)
        }
      }

    }
    
    for (let setpointTypeName in chartRef.current.setpointTypes) {
      for (const zoneIndex in chartRef.current.setpointTypes[setpointTypeName])  {
        chartRef.current.setpointTypes[setpointTypeName][zoneIndex].setpoints.sort((a,b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0))
      }
    }


    const processSetpoint = (setpointType, lastSetpoint, setpointTime, setpointValue) => {
      let dataToPush = []
      if (lastSetpoint == null) {
        dataToPush.push({x: setpointTime * 1000, y: setpointValue})
      }else {

        let amplitude = 0
        let frequency = 1
        switch(lastSetpoint.function) {
          case "instant":
            dataToPush.push({x: setpointTime * 1000, y: lastSetpoint.value})
            dataToPush.push({x: setpointTime * 1000, y: setpointValue})
            break
          case "gradual":
            dataToPush.push({x: setpointTime * 1000, y: setpointValue})
            break
          case "square_wave":
            if (lastSetpoint.function_params.a !== undefined) {
              amplitude = lastSetpoint.function_params.a
            }
            if (lastSetpoint.function_params.f !== undefined) {
              frequency = lastSetpoint.function_params.f
            }


            dataToPush.push(...plotSquareWave(lastSetpoint.time * 1000, lastSetpoint.value, setpointTime * 1000, amplitude, frequency, setpointType.min, setpointType.max, false))
            dataToPush.push({x: setpointTime * 1000, y: setpointValue})
            break
          case "sine_wave":
            if (lastSetpoint.function_params.a !== undefined) {
              amplitude = lastSetpoint.function_params.a
            }
            if (lastSetpoint.function_params.f !== undefined) {
              frequency = lastSetpoint.function_params.f
            }
            
            dataToPush.push(...plotSineWave(lastSetpoint.time * 1000, lastSetpoint.value, setpointTime * 1000, amplitude, frequency, setpointType.min, setpointType.max, false))
            dataToPush.push({x: setpointTime * 1000, y: setpointValue})
            
            break
          default:
            break
        }
      }

      return dataToPush
    }


    for (let [name, setpointList] of Object.entries(chartRef.current.setpointTypes)) {
      for (let [zoneIndex, setpointInfo] of Object.entries(setpointList)) {
        const setpointType = recipeSetpointTypes.find((t) => t.name == name)
        
        setpointInfo.setpoints.sort((a, b) => a.time - b.time);
        
        setpointInfo.lineData = []
        setpointInfo.pointData = []
  
        
        let lastSetpoint = null
        for (const setpoint of setpointInfo.setpoints)  {
          
          
          setpointInfo.lineData.push(...processSetpoint(setpointType, lastSetpoint, setpoint.time, setpoint.value))
          setpointInfo.pointData.push({x: setpoint.time * 1000, y: setpoint.value})
        
          lastSetpoint = setpoint
        }
  
        if (lastSetpoint !== null) {
          
          setpointInfo.lineData.push(...processSetpoint(setpointType, lastSetpoint, currentDuration, lastSetpoint.value))
          
        }
      }
    }
    

    const processLightingSetpoint = (lastSetpoint, setpointTime, setpointValue, lightingSpectrumRatios) => {
      let dataToPush = {totalIntensity: []}
      //for (const [key, value] of Object.entries(lightingSpectrumRatios))  {
      //  dataToPush[key] = []
      //}
      for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
        dataToPush[key] = []
      }

      const addPointForSpectrums = (dataToPush, setpointTime, setpointValue) =>  {
        let spectrumIndex = 0;
        let lastSpectralValue = 0;
        for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
          const value = setpointValue * (lightingSpectrumRatios[key] / 100) + lastSpectralValue
          if (spectrumIndex == 0) {
            dataToPush[key].push({x: setpointTime, y: value})
          }else {
            dataToPush[key].push({position: setpointTime, high: value, low: lastSpectralValue})
          }
          lastSpectralValue = value
          spectrumIndex++
        }
        return dataToPush
      }

      if (lastSetpoint == null) {
        dataToPush.totalIntensity.push({x: setpointTime * 1000, y: setpointValue})
        dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, setpointValue)
      }else {

        switch(lastSetpoint.function) {
          case "instant":
            dataToPush.totalIntensity.push({x: setpointTime * 1000, y: lastSetpoint.value})
            dataToPush.totalIntensity.push({x: setpointTime * 1000, y: setpointValue})
            
            dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, lastSetpoint.value)
            dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, setpointValue)

            break
          case "gradual":
            dataToPush.totalIntensity.push({x: setpointTime * 1000, y: setpointValue})
            dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, setpointValue)
            break
          case "square_wave":
            if (lastSetpoint.function_params["a"] === undefined) {
              lastSetpoint.function_params["a"] = 0
            }
            if (lastSetpoint.function_params["f"] === undefined) {
              lastSetpoint.function_params["f"] = 0
            }

            let squarewaveData = plotSquareWave(lastSetpoint.time * 1000, lastSetpoint.value, setpointTime * 1000, lastSetpoint.function_params["a"], lastSetpoint.function_params["f"], 0, 1000, false)
            dataToPush.totalIntensity.push(...squarewaveData)
            for (let point of squarewaveData) {
              dataToPush = addPointForSpectrums(dataToPush, point.x, point.y)
            }
            dataToPush.totalIntensity.push({x: setpointTime * 1000, y: setpointValue})
            dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, setpointValue)
            break
          case "sine_wave":
            if (lastSetpoint.function_params["a"] === undefined) {
              lastSetpoint.function_params["a"] = 0
            }
            if (lastSetpoint.function_params["f"] === undefined) {
              lastSetpoint.function_params["f"] = 0
            }
            
            let sineWaveData = plotSineWave(lastSetpoint.time * 1000, lastSetpoint.value, setpointTime * 1000, lastSetpoint.function_params["a"], lastSetpoint.function_params["f"], 0, 1000, false)
            dataToPush.totalIntensity.push(...sineWaveData)
            for (let point of sineWaveData) {
              dataToPush = addPointForSpectrums(dataToPush, point.x, point.y)
            }
            dataToPush.totalIntensity.push({x: setpointTime * 1000, y: setpointValue})
            dataToPush = addPointForSpectrums(dataToPush, setpointTime * 1000, setpointValue)
            
            break
          default:
            break
        }
      }

      return dataToPush
    }


    chartRef.current.setpointTypes.totalIntensity = {}
    for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
      chartRef.current.setpointTypes[key] = {}
    }

    
    let isLightingPPFDConflicting = false
    let lightingMaxSetpoint = 0
    if (selectedTimelineItem.item.lighting_intensity_setpoint_zones) {
      for (const currentSetpointZone of selectedTimelineItem.item.lighting_intensity_setpoint_zones)  {
        for (const currentSetpoint of currentSetpointZone.setpoints)  {
          let setpoint = {...currentSetpoint}
          let foundTempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => setpoint.id == tS.id)
          if (foundTempSetpointInfo !== undefined)  {
            if (foundTempSetpointInfo.time !== undefined)  {
              setpoint.time = foundTempSetpointInfo.time
            }
            if (foundTempSetpointInfo.value !== undefined)  {
              setpoint.value = foundTempSetpointInfo.value
            }
            if (foundTempSetpointInfo.function !== undefined)  {
              setpoint.function = foundTempSetpointInfo.function
            }
            if (foundTempSetpointInfo.function_params !== undefined)  {
              setpoint.function_params = foundTempSetpointInfo.function_params
            }
          }

          if (lighting_intensity_setpoints[currentSetpointZone.zone_index] === undefined)  {
            lighting_intensity_setpoints[currentSetpointZone.zone_index] = []
          }
          lighting_intensity_setpoints[currentSetpointZone.zone_index].push(setpoint)
        }
      }
    }
    if (tempSetpointToBeAdded !== undefined)  {
      if (activeChartZone === "lighting")  {
        lighting_intensity_setpoints[0].push(tempSetpointToBeAdded)
      }

    }
    for (const zoneIndex in lighting_intensity_setpoints)  {
      lighting_intensity_setpoints[zoneIndex].sort((a, b) => a.time - b.time)
    }

    let currentPAR = {};
    let currentCLI = {};
    for (const zoneIndex in lighting_intensity_setpoints)  {
      let lastSetpoint = null
      for (const setpoint of lighting_intensity_setpoints[zoneIndex])  {
        let currentSpectrumRatios = lightingSpectrumRatios
        if (setpoint.lighting_spectrum_ratios)  {
          currentSpectrumRatios = setpoint.lighting_spectrum_ratios
        }
        if (setpoint.value > maxPPFD) {
          isLightingPPFDConflicting = true
        }
        if (setpoint.value > lightingMaxSetpoint) {
          lightingMaxSetpoint = setpoint.value
        }
        let lightingData = processLightingSetpoint(lastSetpoint, setpoint.time, setpoint.value, currentSpectrumRatios)
        if (chartRef.current.setpointTypes.totalIntensity[zoneIndex] === undefined)  {
          chartRef.current.setpointTypes.totalIntensity[zoneIndex] = { data: [], pointData: [] }
        }
        chartRef.current.setpointTypes.totalIntensity[zoneIndex].data.push(...lightingData.totalIntensity)
        for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
          
          if (chartRef.current.setpointTypes[key][zoneIndex] === undefined) {
            chartRef.current.setpointTypes[key][zoneIndex] = []
          }
          chartRef.current.setpointTypes[key][zoneIndex].push(...lightingData[key])
        }
        //setpointInfo.lineData.push(...processSetpoint(lastSetpoint, setpoint.time, setpoint.value))
        chartRef.current.setpointTypes.totalIntensity[zoneIndex].pointData.push({x: setpoint.time * 1000, y: setpoint.value})
  
        if (lastSetpoint) {
          const [PARtoAdd, CLItoAdd] = GetCLIFromPoint(currentSpectrumRatios, lastSetpoint, setpoint.value, lastSetpoint.time, setpoint.time);
          if (currentCLI[zoneIndex] === undefined) {
            currentCLI[zoneIndex] = 0
          }
          currentCLI[zoneIndex] += CLItoAdd
        }
  
        lastSetpoint = setpoint
      }
      

      if (lastSetpoint !== null) {
        let currentSpectrumRatios = lightingSpectrumRatios
        if (lastSetpoint.lighting_spectrum_ratios)  {
          currentSpectrumRatios = lastSetpoint.lighting_spectrum_ratios
        }
        let lightingData = processLightingSetpoint(lastSetpoint, currentDuration, lastSetpoint.value, currentSpectrumRatios)
        chartRef.current.setpointTypes.totalIntensity[zoneIndex].data.push(...lightingData.totalIntensity)
        for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
          if (chartRef.current.setpointTypes[key][zoneIndex] === undefined) {
            chartRef.current.setpointTypes[key][zoneIndex] = []
          }
          chartRef.current.setpointTypes[key][zoneIndex].push(...lightingData[key])
        }
        
  
        const [PARtoAdd, CLItoAdd] = GetCLIFromPoint(currentSpectrumRatios, lastSetpoint, lastSetpoint.value, lastSetpoint.time, currentDuration);
        
        if (currentCLI[zoneIndex] === undefined) {
          currentCLI[zoneIndex] = 0
        }
        currentCLI[zoneIndex] += CLItoAdd
      }
    }

    for (const zoneIndex of Object.keys(currentCLI))  {
      currentCLI[zoneIndex] = Math.round(currentCLI[zoneIndex] * 10) / 10
    }
    
    for (const [zoneIndex, zoneCLI] of Object.entries(currentCLI))  {
      if (CLI[zoneIndex] !== zoneCLI) {
        SetCLI({...currentCLI})
        break
      }
    }
    
    if (isLightingPPFDConflicting !== isLightingInMaxPPFDConflict || lightingMaxIntensitySetpoint !== lightingMaxSetpoint)  {
      SetIsLightingInMaxPPFDConflict(isLightingPPFDConflicting)
      SetLightingMaxIntensitySetpoint(lightingMaxSetpoint)
    }


  })


  const setpointChartConvertPositionToDate = (x) => {
    if (!chartRef.current) 
        return undefined

    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()
    const setpointChartPadding = chartRef.current.setpointChart.getPadding()

    let chartArea = getSetpointChartArea()
    const setpointChartRangeDelta = setpointChartVisibleRange.end - setpointChartVisibleRange.start

    let axisWidths = yAxisConstantWidth
    /*chartRef.current.setpointChart.forEachAxisY(axis => {
      axisWidths += 20//axis.getHeight()
    })*/

    // Figure out the x position of both gray areas
    if (x < 0)
        x = 0
    if (x > chartArea.width)
        x = chartArea.width


    return setpointChartVisibleRange.start + (x / chartArea.width) * setpointChartRangeDelta
  }
  const setpointChartConvertDateToPosition = (date) => {
    if (!chartRef.current) 
          return undefined

    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()

    let chartArea = getSetpointChartArea()
    const setpointChartRangeDelta = setpointChartVisibleRange.end - setpointChartVisibleRange.start

    

    // Figure out the x position of both gray areas
    return ((date - setpointChartVisibleRange.start) / setpointChartRangeDelta) * chartArea.width
  }


  const setpointHitTest = (x, y) => {
    let isOverSetpoint = undefined
    let overSetpointPosition = { x: 0, y: 0 }
    let closestDistanceToLine = Number.MAX_SAFE_INTEGER;
    let setpointMoveType = undefined

    let acceptableHitTestDistance = 10;
    if (isTouchOverSetpointChart) {
      acceptableHitTestDistance = 30;
    }
    const chartArea = getSetpointChartArea()



    if ((selectedTimelineItem !== undefined && selectedTimelineItem !== null) && selectedTimelineItem.item) {
      let closestPoint = null;
      let pointDistance = Number.MAX_SAFE_INTEGER;

      if (activeChartZone !== "lighting") {
        if (setpointTypeToggles[activeChartZone].selectedSetpointType) {
          const selectedSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
          if (selectedSetpointType !== undefined) {
            const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == selectedSetpointType.identifier)

            const setpointAxisVisibleRange = chartRef.current.activeYAxes[selectedSetpointType.identifier].getInterval()

            let activeZones = [0]

            let lastSetpointPos = null
            if (selectedTimelineItem.item.setpoint_zones && activeZones.length !== 0) {
              selectedTimelineItem.item.setpoint_zones.map((setpointZone) => {
                if (setpointZone.type_id === setpointTypeInfo.id && activeZones.includes(setpointZone.zone_index)) {
                  for (let currentSetpoint of setpointZone.setpoints) {
                    let setpoint = { ...currentSetpoint, type_id: setpointTypeInfo.id }
                    let foundTempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => setpoint.id == tS.id)
                    if (foundTempSetpointInfo !== undefined) {
                      if (foundTempSetpointInfo.time !== undefined) {
                        setpoint.time = foundTempSetpointInfo.time
                      }
                      if (foundTempSetpointInfo.value !== undefined) {
                        if (setpointTypeInfo.name == "spray_rate") {
                          setpoint.value = foundTempSetpointInfo.value.r
                          setpoint.fValue = foundTempSetpointInfo.value.f
                        } else if (setpointTypeInfo.name == "drip_irrigation_rate") {
                          setpoint.value = foundTempSetpointInfo.value.r
                          setpoint.fValue = foundTempSetpointInfo.value.f
                        } else {
                          setpoint.value = foundTempSetpointInfo.value
                        }
                      }
                      if (foundTempSetpointInfo.function !== undefined) {
                        setpoint.function = foundTempSetpointInfo.function
                      }
                      if (foundTempSetpointInfo.function_params !== undefined) {
                        setpoint.function_params = foundTempSetpointInfo.function_params
                      }
                    } else {
                      if (setpointTypeInfo.name == "spray_rate") {
                        const sprayInfo = JSON.parse(setpoint.value)
                        setpoint = { ...setpoint, value: sprayInfo.r, fValue: sprayInfo.f }
                      } else if (setpointTypeInfo.name == "drip_irrigation_rate") {
                        const dripInfo = JSON.parse(setpoint.value)
                        setpoint = { ...setpoint, value: dripInfo.r, fValue: dripInfo.f }
                      }

                    }



                    const setpointX = setpointChartConvertDateToPosition(setpoint.time * 1000)
                    const setpointY = chartArea.height - (setpoint.value / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height

                    const currentPointDistance = Math.sqrt(Math.pow((x - setpointX), 2) + Math.pow((y - setpointY), 2));
                    if (currentPointDistance < pointDistance) {
                      closestPoint = setpoint
                      pointDistance = currentPointDistance

                      overSetpointPosition = { x: setpointX, y: setpointY }


                    }

                    if (lastSetpointPos !== null) {
                      let currentDistanceToLine = distToSegment({ x: x, y: y }, lastSetpointPos, { x: setpointX, y: setpointY })
                      if (currentDistanceToLine < closestDistanceToLine) {
                        closestDistanceToLine = currentDistanceToLine
                      }
                    }
                    lastSetpointPos = { x: setpointX, y: setpointY }
                  }
                }
              })
              
            }
            if (lastSetpointPos !== null) {
              let currentDistanceToLine = distToSegment({ x: x, y: y }, lastSetpointPos, { x: currentDuration, y: lastSetpointPos.y })
              if (currentDistanceToLine < closestDistanceToLine) {
                closestDistanceToLine = currentDistanceToLine
              }
            }


            if (pointDistance <= acceptableHitTestDistance) {
              isOverSetpoint = closestPoint
              setpointMoveType = "xy"
            } else if (lastSetpointPos !== null && closestDistanceToLine <= acceptableHitTestDistance) {
              //isOverSetpoint = closestPoint
              //setpointMoveType = "y"
            }
          }
        }
      } else {
        const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()

        let lastSetpointPos = null
        if (selectedTimelineItem.item.lighting_intensity_setpoint_zones) {
          selectedTimelineItem.item.lighting_intensity_setpoint_zones.map((lightingSetpointZone) => {
            if (lightingSetpointZone.zone_index === 0) {
              for (let currentSetpoint of lightingSetpointZone.setpoints) {
                let setpoint = { ...currentSetpoint }
                let foundTempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => setpoint.id == tS.id)
                if (foundTempSetpointInfo !== undefined) {
                  if (foundTempSetpointInfo.time !== undefined) {
                    setpoint.time = foundTempSetpointInfo.time
                  }
                  if (foundTempSetpointInfo.value !== undefined) {
                    setpoint.value = foundTempSetpointInfo.value
                  }
                  if (foundTempSetpointInfo.function !== undefined) {
                    setpoint.function = foundTempSetpointInfo.function
                  }
                  if (foundTempSetpointInfo.function_params !== undefined) {
                    setpoint.function_params = foundTempSetpointInfo.function_params
                  }
                }

                const setpointX = setpointChartConvertDateToPosition(setpoint.time * 1000)
                const setpointY = chartArea.height - (setpoint.value / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height

                const currentPointDistance = Math.sqrt(Math.pow((x - setpointX), 2) + Math.pow((y - setpointY), 2));
                if (currentPointDistance < pointDistance) {
                  closestPoint = setpoint
                  pointDistance = currentPointDistance

                  overSetpointPosition = { x: setpointX, y: setpointY }

                  if (lastSetpointPos !== null) {
                    let currentDistanceToLine = distToSegment({ x: x, y: y }, lastSetpointPos, { x: setpointX, y: setpointY })
                    if (currentDistanceToLine < closestDistanceToLine) {
                      closestDistanceToLine = currentDistanceToLine
                    }
                  }
                  lastSetpointPos = { x: setpointX, y: setpointY }
                }
              }
            }
          })
        }


        if (lastSetpointPos !== null) {
          let currentDistanceToLine = distToSegment({ x: x, y: y }, lastSetpointPos, { x: currentDuration, y: lastSetpointPos.y })
          if (currentDistanceToLine < closestDistanceToLine) {
            closestDistanceToLine = currentDistanceToLine
          }
        }
        if (pointDistance <= acceptableHitTestDistance) {
          isOverSetpoint = closestPoint
          setpointMoveType = "xy"
        } else if (lastSetpointPos !== null && closestDistanceToLine <= acceptableHitTestDistance) {
          //isOverSetpoint = closestPoint
          //setpointMoveType = "y"
        }

      }
    }
    return [isOverSetpoint, overSetpointPosition, closestDistanceToLine, setpointMoveType]
  }


  /* HANDLE POINTER INTERACTIONS */
  const chartingAreaPointerMove = React.useCallback((e) =>  {
    if (!chartRef.current)  
          return
    const setpointChartPadding = chartRef.current.setpointChart.getPadding()
    const chartArea = getSetpointChartArea()

    let pointerPosition = {top: 0, left: 0}
        pointerPosition.top = e.clientY
        pointerPosition.left = e.clientX

    let pointerOffset = {y: pointerPosition.top - setpointChartAreaTop - setpointChartPadding.top, x: pointerPosition.left - setpointChartAreaLeft - yAxisConstantWidth }

    if (numberOfPointersDownOnSetpointCanvas <= 1)   {
      let [isOverSetpoint, overSetpointPosition] = setpointHitTest(pointerOffset.x, pointerOffset.y)


      if (e.pointerType == "touch") {
        e.preventDefault()
        e.stopPropagation()
      }
        


      //Check if we are over a point
      if (isEditingRecipe)  {
        if (selectedSetpoint !== undefined) {
          if (pointerDownOverSetpoint !== undefined)  {
            if (activeChartZone !== "lighting")  {
              const setpointTypeInfo = recipeSetpointTypes.find((t) => t.id == selectedSetpoint.type_id)
              const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()

              //Perform moving function
              
              let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) / (1 / setpointTypeInfo.resolution)
              if (desiredValue < setpointTypeInfo.min)  {
                desiredValue = setpointTypeInfo.min
              }
              if (desiredValue > setpointTypeInfo.max)  {
                desiredValue = setpointTypeInfo.max
              }

              let desiredTime = 0
              if (selectedSetpoint.index != 0 && selectedSetpoint.time != 0)  {
                desiredTime = (Math.round(setpointChartConvertPositionToDate(pointerOffset.x) / setpointTimeInterval) * setpointTimeInterval / 1000)
              }


              let foundTempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
              if (foundTempSetpointInfo === undefined)  {
                if (setpointTypeInfo.name == "spray_rate")  {
                  chartRef.current.tempSetpointInfo.push({
                    id: selectedSetpoint.id,
                    time: desiredTime,
                    value: {
                      r: desiredValue,
                      f: selectedSetpoint.fValue
                    }
                  })
                }else if (setpointTypeInfo.name == "drip_irrigation_rate")  {
                  chartRef.current.tempSetpointInfo.push({
                    id: selectedSetpoint.id,
                    time: desiredTime,
                    value: {
                      r: desiredValue,
                      f: selectedSetpoint.fValue
                    }
                  })
                }else {
                  chartRef.current.tempSetpointInfo.push({
                    id: selectedSetpoint.id,
                    time: desiredTime,
                    value: desiredValue
                  })
                }
              }else {
                foundTempSetpointInfo.time = desiredTime
                if (setpointTypeInfo.name == "spray_rate")  {
                  foundTempSetpointInfo.value = {
                    r: desiredValue,
                    f: selectedSetpoint.fValue
                  }
                }else if (setpointTypeInfo.name == "drip_irrigation_rate")  {
                  foundTempSetpointInfo.value = {
                    r: desiredValue,
                    f: selectedSetpoint.fValue
                  }
                }else {
                  foundTempSetpointInfo.value = desiredValue
                }
              }

              let desiredValueY = chartArea.height - (desiredValue / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height
              SetPointerOverSetpointChartDate(desiredTime * 1000)
              SetPointerOverSetpointChartY(desiredValueY)

            }else {
              const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == "light_intensity")
              const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()

              //Perform moving function
              
              let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) / (1 / setpointTypeInfo.resolution)
              if (desiredValue > maxPPFD) {
                desiredValue = maxPPFD
              }
              if (desiredValue < 0)  {
                desiredValue = 0
              }


              let desiredTime = 0
              if (selectedSetpoint.index != 0 && selectedSetpoint.time != 0)  {
                desiredTime = (Math.round(setpointChartConvertPositionToDate(pointerOffset.x) / setpointTimeInterval) * setpointTimeInterval / 1000)
              }


              let foundTempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
              if (foundTempSetpointInfo === undefined)  {
                chartRef.current.tempLightingSetpointInfo.push({
                  id: selectedSetpoint.id,
                  time: desiredTime,
                  value: desiredValue
                })
              }else {
                foundTempSetpointInfo.time = desiredTime
                foundTempSetpointInfo.value = desiredValue
              }

              let desiredValueY = chartArea.height - (desiredValue / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height
              SetPointerOverSetpointChartDate(desiredTime * 1000)
              SetPointerOverSetpointChartY(desiredValueY)
            }
          }
        }else {

          if (isOverSetpoint !== undefined)   {
              e.preventDefault()
              e.stopPropagation()
              if (pointerOverSetpoint != isOverSetpoint)    {
                  SetPointerOverSetpoint(isOverSetpoint)
              }
              SetPointerOverSetpointChartDate(isOverSetpoint.time * 1000)
              SetPointerOverSetpointChartY(overSetpointPosition.y)
          }else {
            SetPointerOverSetpointChartY(pointerOffset.y)
            SetPointerOverSetpointChartDate(setpointChartConvertPositionToDate(pointerOffset.x))
          }
          
          //Check if we were over a grabber but aren't anymore
          if (isOverSetpoint === undefined && pointerOverSetpoint !== undefined)   {
            SetPointerOverSetpoint(undefined)
          }
        }
      }

        
      

      
      



    }
    SetLastMousePosition({x: e.clientX, y: e.clientY})
  })


  const chartingAreaPointerDown = React.useCallback((e) =>  {
    if (e.pointerType == "touch")   {
        SetIsTouchOverSetpointChart(true)
    }else {
        SetIsTouchOverSetpointChart(false)
    }
    SetNumberOfPointersDownOnSetpointCanvas(numberOfPointersDownOnSetpointCanvas + 1)

    if (!chartRef.current) 
          return
    let requiresPointerCapture = false
    const setpointChartPadding = chartRef.current.setpointChart.getPadding()
    const chartArea = getSetpointChartArea()

    let pointerPosition = {top: 0, left: 0}
        pointerPosition.top = e.clientY
        pointerPosition.left = e.clientX

    let pointerOffset = {y: pointerPosition.top - setpointChartAreaTop - setpointChartPadding.top, x: pointerPosition.left - setpointChartAreaLeft - yAxisConstantWidth }


    if (numberOfPointersDownOnSetpointCanvas <= 1)   {
      let [isDownOverSetpoint, overSetpointPosition, distanceToSetpointLine] = setpointHitTest(pointerOffset.x, pointerOffset.y)

     

      if (e.pointerType === "touch" || e.button === 0) {
        e.preventDefault()
        e.stopPropagation()

        if (isDownOverSetpoint !== undefined)   {
          e.preventDefault()
          e.stopPropagation()
          if (selectedSetpoint !== undefined && selectedSetpoint.id != isDownOverSetpoint.id) {
            finalizeSelectedSetpoint()
          }
          SetSelectedSetpoint(isDownOverSetpoint)
          SetPointerDownOverSetpoint(isDownOverSetpoint)
          SetPointerOverSetpointChartDate(isDownOverSetpoint.time * 1000)
          SetPointerOverSetpointChartY(overSetpointPosition.y)
          requiresPointerCapture = true
          //chartRef.current.tempSetpointInfo = undefined
          //SetPointerOverSetpointChartDate(isOverSetpoint.time * 1000)
          //SetPointerOverSetpointChartY(overSetpointPosition.y)
        }else {
          //SetPointerOverSetpointChartY(pointerOffset.y)
          //SetPointerOverSetpointChartDate(setpointChartConvertPositionToDate(pointerOffset.x))
        }

        if (isDownOverSetpoint === undefined && (selectedSetpoint !== undefined || pointerDownOverSetpoint !== undefined))   {
          if (selectedSetpoint !== undefined) {
            finalizeSelectedSetpoint()
          }
          SetPointerDownOverSetpoint(undefined)
          SetSelectedSetpoint(undefined)
          //chartRef.current.tempSetpointInfo = undefined
          SetPointerOverSetpointChartY(pointerOffset.y)
          SetPointerOverSetpointChartDate(setpointChartConvertPositionToDate(pointerOffset.x))
        }

      }else if (e.pointerType !== "touch" && e.button === 2) {
        e.preventDefault()
        e.stopPropagation()
        if (distanceToSetpointLine < 100) {
          let desiredTime = (Math.round(setpointChartConvertPositionToDate(pointerOffset.x) / setpointTimeInterval) * setpointTimeInterval / 1000)
          //Check to see if there is already a point at the time
          if (activeChartZone !== "lighting")  {
            const selectedSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
            const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == selectedSetpointType.identifier)

            let foundSetpoint = undefined
            let setpointZone = selectedTimelineItem.item.setpoints_zones.find((sZ) => sZ.type_id == setpointTypeInfo.id)
            if (setpointZone !== undefined) {
              foundSetpoint = setpointZone.setpoints.find((s) => s.time == desiredTime)
            }

            if (foundSetpoint === undefined) {
              const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()
              let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) / (1 / setpointTypeInfo.resolution)
              //we want to add a new point at this time
              
              let newSetpoint
              if (setpointTypeInfo.name == "spray_rate")  {
                newSetpoint = {
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  time: desiredTime,
                  type_id: setpointTypeInfo.id,
                  value: JSON.stringify({
                    r: desiredValue,
                    f: 50
                  }),
                  function: "instant"
                } 

              }else if (setpointTypeInfo.name == "drip_rate")  {
                newSetpoint = {
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  time: desiredTime,
                  type_id: setpointTypeInfo.id,
                  value: JSON.stringify({
                    r: desiredValue,
                    f: 50
                  }),
                  function: "instant"
                } 

              }else {
                newSetpoint = {
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  time: desiredTime,
                  type_id: setpointTypeInfo.id,
                  value: desiredValue.toString(),
                  function: "instant",
                  function_params: {}
                } 
              }
              let newSetpoints = [...selectedTimelineItem.item.setpoints, newSetpoint]

              dispatch(pushRecipeChange({
                recipe: {
                  ...recipe,
                  timeline_items: [...recipe.timeline_items.map((timelineItem) => {
                    if (timelineItem.id != selectedTimelineItem.id) {
                      return timelineItem
                    }
                    return {
                      ...timelineItem,
                      item: {
                        ...timelineItem.item,
                        setpoints: newSetpoints.map((setpoint, setpointIndex) => {
                          return { ...setpoint, index: setpointIndex + 1 }
                        }),
                        currentSetpointTempId: timelineItem.item.currentSetpointTempId + 1
                      }
                    }
                  })]
                }
              }))

              SetSelectedSetpoint(newSetpoint)
              SetPointerOverSetpointChartY(pointerOffset.y)
              SetPointerOverSetpointChartDate(desiredTime * 1000)
            }


          }else {

            if (selectedTimelineItem.item.lighting_intensity_setpoints.find((s) => s.time == desiredTime) === undefined) {
              const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == "light_intensity")
              const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()
              let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) / (1 / setpointTypeInfo.resolution)
              if (desiredValue > maxPPFD) {
                desiredValue = maxPPFD
              }
              if (desiredValue < 0) {
                desiredValue = 0
              }
              //we want to add a new point at this time
              
              //we may need to intercept the index here
              let newSetpoint = {
                id: selectedTimelineItem.item.currentLightingSetpointTempId,
                time: desiredTime,
                value: desiredValue,
                function: "instant",
                function_params: {}
              } 
              let newSetpoints = [...selectedTimelineItem.item.lighting_intensity_setpoints, newSetpoint]

              dispatch(pushRecipeChange({recipe: {...recipe, 
                timeline_items: [...recipe.timeline_items.map((timelineItem) => {
                  if (timelineItem.id != selectedTimelineItem.id) {
                    return timelineItem
                  }
                  return {
                    ...timelineItem,
                    item: {
                      ...timelineItem.item,
                      lighting_intensity_setpoints: newSetpoints.map((setpoint, setpointIndex) => {
                        return {...setpoint, index: setpointIndex + 1}
                      }),
                      currentLightingSetpointTempId: timelineItem.item.currentLightingSetpointTempId + 1
                    }
                  }
                })]
              }}))


              SetSelectedSetpoint(newSetpoint)
              SetPointerOverSetpointChartY(pointerOffset.y)
              SetPointerOverSetpointChartDate(desiredTime * 1000)
            }

          }


        }

        

      }

      
        //pointerDownOverSetpoint
      


    }

    if (requiresPointerCapture) {
      if (setpointChartingAreaRef.current !== undefined && setpointChartingAreaRef.current.setPointerCapture)    {
          SetChartingAreaPointerId(e.pointerId)
          setpointChartingAreaRef.current.setPointerCapture(e.pointerId);
      }
  }

  })
  const chartingAreaPointerUp = React.useCallback((e) =>  {
      
    if (selectedSetpoint === undefined) {
      if (setpointChartingAreaRef.current !== undefined && setpointChartingAreaRef.current.releasePointerCapture && chartingAreaPointerId)    {
        setpointChartingAreaRef.current.releasePointerCapture(chartingAreaPointerId);
      }
      

    }
    SetPointerDownOverSetpoint(undefined)
    let pointerCount = numberOfPointersDownOnSetpointCanvas - 1
    pointerCount = pointerCount < 0 ? 0 : pointerCount
    SetNumberOfPointersDownOnSetpointCanvas(pointerCount)
    
    
  })
  const chartingAreaPointerLeave = React.useCallback((e) =>  {
    SetPointerOverSetpoint(undefined)
    if (selectedSetpoint === undefined)  {
      SetPointerOverSetpointChartDate(undefined)
    }
  })
  
  const finalizeSelectedSetpoint = () => {
    if (selectedSetpoint === undefined || !chartRef.current)
      return

    if (activeChartZone !== "lighting")  {
      let foundTempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
      if (foundTempSetpointInfo === undefined)
        return

      chartRef.current.tempSetpointInfo.splice(chartRef.current.tempSetpointInfo.indexOf(foundTempSetpointInfo), 1)

      const selectedSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
      const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == selectedSetpointType.identifier)


      dispatch(pushRecipeChange({
        recipe: {
          ...recipe,
          timeline_items: [...recipe.timeline_items.map((timelineItem) => {
            if (timelineItem.id != selectedTimelineItem.id) {
              return timelineItem
            }
            return {
              ...timelineItem,
              item: {
                ...timelineItem.item,
                setpoint_zones: selectedTimelineItem.item.setpoint_zones.map((setpointZone) => {
                  if (setpointZone.zone_index === 0 && setpointZone.type_id === selectedSetpoint.type_id) {
                    return {
                      ...setpointZone, setpoints: setpointZone.setpoints.map((setpoint, setpointIndex) => {
                        console.log(selectedSetpoint, setpoint)
                        if (selectedSetpoint.time != setpoint.time)
                          return setpoint

                        let updatedSetpoint = { ...setpoint }
                        if (foundTempSetpointInfo.time !== undefined) {
                          updatedSetpoint.time = foundTempSetpointInfo.time
                        }
                        if (foundTempSetpointInfo.value !== undefined) {
                          if (setpointTypeInfo.name == "spray_rate") {
                            updatedSetpoint.value = JSON.stringify(foundTempSetpointInfo.value)
                          } else if (setpointTypeInfo.name == "drip_irrigation_rate") {
                            updatedSetpoint.value = JSON.stringify(foundTempSetpointInfo.value)
                          } else {
                            updatedSetpoint.value = foundTempSetpointInfo.value.toString()
                          }
                        }
                        if (foundTempSetpointInfo.range !== undefined) {
                          updatedSetpoint.range = foundTempSetpointInfo.range
                        }
                        if (foundTempSetpointInfo.function !== undefined) {
                          updatedSetpoint.function = foundTempSetpointInfo.function
                        }
                        if (foundTempSetpointInfo.function_params !== undefined) {
                          updatedSetpoint.function_params = { ...foundTempSetpointInfo.function_params }
                        }
                        return updatedSetpoint
                      })
                    }
                  }
                  return setpointZone
                })
              }
            }
          })]
        }
      }))



    }else {

      let foundTempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
      if (foundTempSetpointInfo === undefined)
        return

      chartRef.current.tempLightingSetpointInfo.splice(chartRef.current.tempLightingSetpointInfo.indexOf(foundTempSetpointInfo), 1)

      //chartRef.current.tempLightingSetpointInfo.splice(chartRef.current.tempLightingSetpointInfo.indexOf(foundTempSetpointInfo), 1);
      dispatch(pushRecipeChange({
        recipe: {
          ...recipe,
          timeline_items: [...recipe.timeline_items.map((timelineItem) => {
            if (timelineItem.id != selectedTimelineItem.id) {
              return timelineItem
            }
            return {
              ...timelineItem,
              item: {
                ...timelineItem.item,
                lighting_intensity_setpoint_zones: [...selectedTimelineItem.item.lighting_intensity_setpoint_zones.map((lisz) => {
                  if (lisz.zone_index === 0) {
                    return {
                      ...lisz, setpoints: lisz.setpoints.map((setpoint, setpointIndex) => {
                        if (selectedSetpoint.id != setpoint.id)
                          return setpoint

                        let updatedSetpoint = { ...setpoint }
                        if (foundTempSetpointInfo.time !== undefined) {
                          updatedSetpoint.time = foundTempSetpointInfo.time
                        }
                        if (foundTempSetpointInfo.value !== undefined) {
                          updatedSetpoint.value = foundTempSetpointInfo.value
                        }
                        if (foundTempSetpointInfo.range !== undefined) {
                          updatedSetpoint.range = foundTempSetpointInfo.range
                        }
                        if (foundTempSetpointInfo.function !== undefined) {
                          updatedSetpoint.function = foundTempSetpointInfo.function
                        }
                        //updatedSetpoint.zone_index = 1
                        if (foundTempSetpointInfo.function_params !== undefined) {
                          updatedSetpoint.function_params = { ...foundTempSetpointInfo.function_params }
                        }
                        return updatedSetpoint
                      })
                    }
                  }
                  return { ...lisz }
                })]
              }
            }
          })]
        }
      }))
    }

    //chartRef.current.tempSetpointInfo = undefined
  }




  const addSetpointButtonPointerDown = (e) => {
    finalizeSelectedSetpoint()
    if (pointerDownOverSetpoint === undefined) {
      SetSelectedSetpoint(undefined)
      SetDraggingSetpointMoveType(undefined)
      SetPointerOverSetpointChartDate(undefined)
    }

    if (addSetpointButtonRef.current !== undefined && addSetpointButtonRef.current.setPointerCapture) {
      SetChartingAreaPointerId(e.pointerId)
      addSetpointButtonRef.current.setPointerCapture(e.pointerId);
    }
    SetAddingSetpoint(true)
  }
  const addSetpointButtonPointerMove = (e) => {
    if (addingSetpoint) {
      e.preventDefault()
      e.stopPropagation()

      if (!chartRef.current)
        return
      const setpointChartPadding = chartRef.current.setpointChart.getPadding()
      const chartArea = getSetpointChartArea()


      let pointerPosition = { top: 0, left: 0 }
      pointerPosition.top = e.clientY
      pointerPosition.left = e.clientX

      let pointerOffset = { y: pointerPosition.top - setpointChartAreaTop - setpointChartPadding.top, x: pointerPosition.left - setpointChartAreaLeft - yAxisConstantWidth }

      //Dragged into setpoint chart area
      if (pointerPosition.top > setpointChartAreaTop && pointerPosition.top < setpointChartAreaTop + setpointChartAreaHeight && pointerPosition.left > setpointChartAreaLeft && pointerPosition.left < setpointChartAreaLeft + setpointChartAreaWidth) {
        let desiredTime = (Math.round(setpointChartConvertPositionToDate(pointerOffset.x) / setpointTimeInterval) * setpointTimeInterval / 1000)
        if (activeChartZone !== "lighting") {
          const selectedSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
          const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == selectedSetpointType.identifier)

          let foundSetpoint = undefined
          for (let setpointZone of selectedTimelineItem.item.setpoint_zones) {
            foundSetpoint = setpointZone.setpoints.find((s) => s.time === desiredTime)
            if (foundSetpoint !== undefined) {
              break
            }
          }
          if (foundSetpoint === undefined) {
            const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()
            let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) * setpointTypeInfo.resolution
            if (desiredValue < setpointTypeInfo.min) {
              desiredValue = setpointTypeInfo.min
            }
            if (desiredValue > setpointTypeInfo.max) {
              desiredValue = setpointTypeInfo.max
            }

            if (setpointTypeInfo.name === "spray_rate") {
              if (tempSetpointToBeAdded === undefined) {
                SetTempSetpointToBeAdded({
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  index: 1,
                  time: desiredTime,
                  value: JSON.stringify({
                    r: desiredValue,
                    f: 50
                  }),
                  range: "0",
                  type_id: setpointTypeInfo.id,
                  zone_index: 0,
                  function: "instant",
                  function_params: {}
                })
              } else {
                tempSetpointToBeAdded.time = desiredTime
                tempSetpointToBeAdded.value = JSON.stringify({
                  r: desiredValue,
                  f: 50
                })
                SetTempSetpointToBeAdded({ ...tempSetpointToBeAdded })
              }
            } else if (setpointTypeInfo.name === "drip_irrigation_rate") {
              if (tempSetpointToBeAdded === undefined) {
                SetTempSetpointToBeAdded({
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  index: 1,
                  time: desiredTime,
                  value: JSON.stringify({
                    r: desiredValue,
                    f: 50
                  }),
                  range: "0",
                  zone_index: 0,
                  type_id: setpointTypeInfo.id,
                  function: "instant",
                  function_params: {}
                })
              } else {
                tempSetpointToBeAdded.time = desiredTime
                tempSetpointToBeAdded.value = JSON.stringify({
                  r: desiredValue,
                  f: 50
                })
                SetTempSetpointToBeAdded({ ...tempSetpointToBeAdded })
              }
            } else {
              if (tempSetpointToBeAdded === undefined) {
                SetTempSetpointToBeAdded({
                  id: selectedTimelineItem.item.currentSetpointTempId,
                  index: 1,
                  time: desiredTime,
                  value: desiredValue.toString(),
                  range: selectedSetpointType.defaultInitialRange.toString(),
                  zone_index: 0,
                  type_id: setpointTypeInfo.id,
                  function: "instant",
                  function_params: {}
                })
              } else {
                tempSetpointToBeAdded.time = desiredTime
                tempSetpointToBeAdded.value = desiredValue.toString()
                SetTempSetpointToBeAdded({ ...tempSetpointToBeAdded })
              }
            }

          } else {
            if (tempSetpointToBeAdded !== undefined) {
              SetTempSetpointToBeAdded(undefined)
            }
          }

        } else {

          const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == "light_intensity")

          let foundLightingSetpoint = undefined
          /*for (let lightingSetpointZone of selectedTimelineItem.item.lighting_intensity_setpoint_zones) {
            foundLightingSetpoint = lightingSetpointZone.setpoints.find((s) => s.id === selectedSetpoint.id)
            if (foundLightingSetpoint !== undefined) {
              break
            }
          }*/
          if (foundLightingSetpoint === undefined) {
            
          //if (selectedTimelineItem.item.lighting_intensity_setpoints.find((s) => s.time == desiredTime) === undefined) {
            const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()
            let desiredValue = Math.round(((chartArea.height - pointerOffset.y) / chartArea.height) * (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start) / setpointTypeInfo.resolution) / (1 / setpointTypeInfo.resolution)
            if (desiredValue < 0) {
              desiredValue = 0
            }
            if (desiredValue > maxPPFD) {
              desiredValue = maxPPFD
            }

            console.log(tempSetpointToBeAdded, selectedTimelineItem.item.currentLightingSetpointTempId)
            if (tempSetpointToBeAdded === undefined) {
              SetTempSetpointToBeAdded({
                id: selectedTimelineItem.item.currentLightingSetpointTempId,
                index: 1,
                time: desiredTime,
                value: desiredValue,
                zone_index: 0,
                function: "instant",
                function_params: {}
              })
            } else {
              tempSetpointToBeAdded.time = desiredTime
              tempSetpointToBeAdded.value = desiredValue
              SetTempSetpointToBeAdded({ ...tempSetpointToBeAdded })
            }

          } else {
            if (tempSetpointToBeAdded !== undefined) {
              SetTempSetpointToBeAdded(undefined)
            }
          }


        }

      } else {
        if (tempSetpointToBeAdded !== undefined) {
          SetTempSetpointToBeAdded(undefined)
        }
      }
    }

  }
  const addSetpointButtonPointerUp = (e) => {
    if (tempSetpointToBeAdded !== undefined) {
      if (activeChartZone !== "lighting") {
        dispatch(pushRecipeChange({
          recipe: {
            ...recipe,
            timeline_items: [...recipe.timeline_items.map((timelineItem) => {
              if (timelineItem.id != selectedTimelineItem.id) {
                return timelineItem
              }
              return {
                ...timelineItem,
                item: {
                  ...timelineItem.item,
                  setpoint_zones: selectedTimelineItem.item.setpoint_zones.map(function (sZ) {
                    if (sZ.type_id == tempSetpointToBeAdded.type_id) {
                      return { ...sZ, setpoints: [...sZ.setpoints, tempSetpointToBeAdded] }
                    } else {
                      return sZ
                    }
                  }),
                  currentSetpointTempId: timelineItem.item.currentSetpointTempId + 1
                }
              }
            })]
          }
        }))
      } else {
        dispatch(pushRecipeChange({
          recipe: {
            ...recipe,
            timeline_items: [...recipe.timeline_items.map((timelineItem) => {
              if (timelineItem.id != selectedTimelineItem.id) {
                return timelineItem
              }
              return {
                ...timelineItem,
                item: {
                  ...timelineItem.item,
                  lighting_intensity_setpoint_zones: [...selectedTimelineItem.item.lighting_intensity_setpoint_zones.map((lisz) => {
                    if (lisz.zone_index === 0) {
                      return {
                        ...lisz, 
                        setpoints: [
                          ...lisz.setpoints,
                          tempSetpointToBeAdded
                        ],
                      }
                    }
                    return { ...lisz }
                  })],
                  currentLightingSetpointTempId: timelineItem.item.currentLightingSetpointTempId + 1
                }
              }
            })]
          }
        }))

      }
      SetTempSetpointToBeAdded(undefined)
    }
    if (addSetpointButtonRef.current !== undefined && addSetpointButtonRef.current.releasePointerCapture && chartingAreaPointerId) {
      addSetpointButtonRef.current.releasePointerCapture(chartingAreaPointerId);
    }
    SetAddingSetpoint(false)
  }


  const getSetpointChartArea = () => {
    if (!chartRef.current)
      return undefined

    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()
    const setpointChartPadding = chartRef.current.setpointChart.getPadding()

    let axisWidths = yAxisConstantWidth
    chartRef.current.setpointChart.forEachAxisY(axis => {
      axisWidths += 0//axis.getHeight()
    })

    let chartArea = {
      x1: yAxisConstantWidth,
      x2: setpointChartAreaWidth - yAxisConstantWidth - chartRightPadding,
      y1: 0,
      y2: setpointChartAreaHeight - timeAxisConstantHeight,
      axisWidth: axisWidths
    }
    chartArea.width = setpointChartAreaWidth - yAxisConstantWidth - chartRightPadding
    chartArea.height = setpointChartAreaHeight - timeAxisConstantHeight


    return chartArea
  }

  
  const [selectedTooltipTab, SetSelectedTooltipTab] = React.useState("basic")
  const drawTooltip = React.useCallback(() => {
    if (!chartRef.current)
      return

    const chartArea = getSetpointChartArea()

    let tooltipSpacingFromCenter = { x: 5, y: 5 }
    if (isTouchOverSetpointChart) {
      tooltipSpacingFromCenter = { x: 15, y: 15 }
    }

    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()


    let tooltipStyleProps = {}
    let xPoint = setpointChartConvertDateToPosition(pointerOverSetpointChartDate)
    if (pointerOverSetpointChartDate < (setpointChartVisibleRange.end - setpointChartVisibleRange.start) / 2) {
      tooltipStyleProps.left = setpointChartAreaLeft + chartArea.x1 + xPoint + tooltipSpacingFromCenter.x;
    } else {
      tooltipStyleProps.right = setpointChartAreaRight + chartRightPadding + (chartArea.width - xPoint) + tooltipSpacingFromCenter.x;
    }

    if (pointerOverSetpointChartY > setpointChartAreaHeight / 2) {
      tooltipStyleProps.top = setpointChartAreaTop + pointerOverSetpointChartY - tooltipSpacingFromCenter.y;
      tooltipStyleProps.transform = "translate(0, -100%)"
    } else {
      tooltipStyleProps.top = setpointChartAreaTop + pointerOverSetpointChartY + tooltipSpacingFromCenter.y;
    }




    if (selectedSetpoint !== undefined) {

      if (activeChartZone !== "lighting") {
        let setpoint = { ...selectedSetpoint }
        const setpointTypeInfo = recipeSetpointTypes.find((t) => t.id == setpoint.type_id)
        const setpointTypeToggleInfo = Object.values(setpointTypeToggles[activeChartZone].setpointTypes).find((t) => t.identifier == setpointTypeInfo.name)



        const timeChanged = (newTime) => {
          let valid = true
          if (newTime < 0) {
            newTime = 0
            valid = false
          } else if (newTime > currentDuration) {
            newTime = currentDuration
            valid = false
          }

          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id,
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.time = newTime
          tempSetpointInfo.ignore = true

          if (!valid) {
            return newTime
          }
        }

        const finalizeTimeChanged = (newTime) => {
          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id,
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.time = newTime
          tempSetpointInfo.ignore = false

          SetPointerOverSetpointChartDate(newTime * 1000)
        }

        const valueChanged = (newValue) => {
          let valid = true
          if (newValue < setpointTypeInfo.min) {
            newValue = setpointTypeInfo.min
            valid = false
          } else if (newValue > setpointTypeInfo.max) {
            newValue = setpointTypeInfo.max
            valid = false
          }

          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            if (setpointTypeInfo.name === "spray_rate" || setpointTypeInfo.name === "drip_irrigation_rate") {
              tempSetpointInfo = {
                id: selectedSetpoint.id,
                value: { f: selectedSetpoint.fValue }
              }
            } else {
              tempSetpointInfo = {
                id: selectedSetpoint.id,
              }
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }

          if (setpointTypeInfo.name === "spray_rate" || setpointTypeInfo.name === "drip_irrigation_rate") {
            tempSetpointInfo.value.r = newValue
          } else {
            tempSetpointInfo.value = newValue
          }

          tempSetpointInfo.ignore = true

          if (!valid)
            return newValue
        }

        const finalizeValueChanged = (newValue) => {
          const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()
          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            if (setpointTypeInfo.name === "spray_rate" || setpointTypeInfo.name === "drip_irrigation_rate") {
              tempSetpointInfo = {
                id: selectedSetpoint.id,
                value: { f: selectedSetpoint.fValue }
              }
            } else {
              tempSetpointInfo = {
                id: selectedSetpoint.id,
              }
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }
          if (setpointTypeInfo.name === "spray_rate" || setpointTypeInfo.name === "drip_irrigation_rate") {
            tempSetpointInfo.value.r = newValue
          } else {
            tempSetpointInfo.value = newValue
          }
          tempSetpointInfo.ignore = false

          let desiredValueY = chartArea.height - (newValue / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height
          SetPointerOverSetpointChartY(desiredValueY)
        }

        const rangeChanged = (newRange) => {
          let valid = true
          let maxRange = 1000000000 //todo make this do something?
          if (newRange < 0) {
            newRange = 0
            valid = false
          } else if (newRange > maxRange) {
            newRange = maxRange
            valid = false
          }

          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id,
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.range = newRange
          tempSetpointInfo.ignore = true

          //let desiredValueY = chartArea.height - (newValue / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height


          forceRerender()
          if (!valid) {
            return newRange
          }
        }

        const finalizeRangeChanged = (newRange) => {
          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id,
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.range = newRange
          tempSetpointInfo.ignore = false

          forceRerender()
        }

        const setFunction = (newFunction) => {
          if (!isEditingRecipe) {
            return
          }
          if (setpoint.function !== newFunction) {
            let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
            if (tempSetpointInfo === undefined) {
              tempSetpointInfo = {
                id: selectedSetpoint.id,
              }
              chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
            }

            tempSetpointInfo.function = newFunction
            if (tempSetpointInfo.function_params === undefined) {
              tempSetpointInfo.function_params = {}
              if (setpoint.function_params.a !== undefined) {
                tempSetpointInfo.function_params.a = setpoint.function_params.a
              } else {
                tempSetpointInfo.function_params.a = setpointTypeToggleInfo.defaultAmplitude
              }

              if (setpoint.function_params.f !== undefined) {
                tempSetpointInfo.function_params.f = setpoint.function_params.f
              } else {
                tempSetpointInfo.function_params.f = setpointTypeToggleInfo.defaultFrequency
              }

            } else {
              tempSetpointInfo.function_params = {
                a: setpointTypeToggleInfo.defaultAmplitude,
                f: setpointTypeToggleInfo.defaultFrequency
              }
            }

            forceRerender()
          }
        }

        const amplitudeChanged = (newAmplitude) => {
          let valid = true
          if (newAmplitude < 0) {
            newAmplitude = 0
            valid = false
          } else if (newAmplitude > setpointTypeInfo.max) {
            newAmplitude = setpointTypeInfo.max
            valid = false
          }

          const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()
          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }

          if (tempSetpointInfo.function_params === undefined) {
            tempSetpointInfo.function_params = { f: setpointTypeToggleInfo.defaultFrequency }
          }

          tempSetpointInfo.function_params.a = newAmplitude

          forceRerender()

          if (!valid)
            return newAmplitude
        }


        const frequencyChanged = (newFrequency) => {
          let valid = true
          if (newFrequency < 0) {
            newFrequency = 0
            valid = false
          } else if (newFrequency > setpointTypeInfo.max) {
            newFrequency = setpointTypeInfo.max
            valid = false
          }

          const setpointAxisVisibleRange = chartRef.current.activeYAxes[setpointTypeInfo.name].getInterval()
          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
          }

          if (tempSetpointInfo.function_params === undefined) {
            tempSetpointInfo.function_params = { a: setpointTypeToggleInfo.defaultAmplitude }
          }

          tempSetpointInfo.function_params.f = newFrequency

          forceRerender()

          if (!valid)
            return newFrequency
        }



        let foundTempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
        if (foundTempSetpointInfo !== undefined) {
          if (foundTempSetpointInfo.time !== undefined) {
            setpoint.time = foundTempSetpointInfo.time
          }
          if (foundTempSetpointInfo.value !== undefined) {
            if (setpointTypeInfo.name == "spray_rate") {
              setpoint.value = foundTempSetpointInfo.value.r
              setpoint.fValue = foundTempSetpointInfo.value.f
            } else if (setpointTypeInfo.name == "drip_irrigation_rate") {
              setpoint.value = foundTempSetpointInfo.value.r
              setpoint.fValue = foundTempSetpointInfo.value.f
            } else {
              setpoint.value = foundTempSetpointInfo.value
            }
          }
          if (foundTempSetpointInfo.function !== undefined) {
            setpoint.function = foundTempSetpointInfo.function
          }
          if (foundTempSetpointInfo.function_params !== undefined) {
            setpoint.function_params = foundTempSetpointInfo.function_params
          }
        }

        const showFunctionInputs = (setpoint.function == "sine_wave" || setpoint.function == "square_wave") ? true : false


        const deleteSetpointPressed = (e) => {
          const selectedSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
          const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == selectedSetpointType.identifier)

          dispatch(pushRecipeChange({
            recipe: {
              ...recipe,
              timeline_items: [...recipe.timeline_items.map((timelineItem) => {
                if (timelineItem.id != selectedTimelineItem.id) {
                  return timelineItem
                }
                return {
                  ...timelineItem,
                  item: {
                    ...timelineItem.item,
                    setpoint_zones: selectedTimelineItem.item.setpoint_zones.map(function (sZ) {
                      if (sZ.type_id == setpointTypeInfo.id) {
                        return {
                          ...sZ, setpoints: sZ.setpoints.filter((setpoint, setpointIndex) => {
                            if (selectedSetpoint.id != setpoint.id)
                              return true
                            return false
                          })
                        }
                      } else {
                        return sZ
                      }
                    })
                  }
                }
              })]
            }
          }))


          SetSelectedSetpoint(undefined)
          SetDraggingSetpointMoveType(undefined)
          SetPointerOverSetpointChartDate(undefined)

        }

        let hours = Math.floor(setpoint.time / 3600)
        let minutes = Math.floor((setpoint.time % 3600) / 60)
        if (hours < 10) {
          hours = 0 + hours.toString()
        }
        if (minutes < 10) {
          minutes = 0 + minutes.toString()
        }








        if (pointerDownOverSetpoint !== undefined) {
          return (
            <div className="GrowZoneRecipeManager-Tooltip GrowZoneRecipeManager-TooltipMoving" style={tooltipStyleProps}>
              <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                <div className="GrowZoneRecipeManager-Tooltip-Inputs-Time">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay">
                    <div>Time</div>
                    {/*<div>{hours}:{minutes}</div>*/}
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay">
                    <NumberInput value={setpoint.time} type={"hours"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                    <NumberInput value={setpoint.time} type={"minutes"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                  </div>
                </div>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs-Value">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueDisplay">
                    <div>Value</div>
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueInputs">
                    <NumberInput value={parseFloat(setpoint.value)} suffix={setpointTypeInfo.suffix} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="GrowZoneRecipeManager-Tooltip GrowZoneRecipeManager-TooltipEdit" style={tooltipStyleProps}>
              <div className="GrowZoneRecipeManager-Tooltip-Header">
                <div>{setpointTypeInfo.display_name} Setpoint</div>
              </div>
              <div className="FlexContent-H-5 FlexContent-HFill">
                <Button status="Primary-Toggle" state={selectedTooltipTab === "basic"} content="Basic" onClick={() => { SetSelectedTooltipTab("basic") }} width={"Flex50"} />
                <Button status="Primary-Toggle" state={selectedTooltipTab === "advanced"} content="Advanced" onClick={() => { SetSelectedTooltipTab("advanced") }} width={"Flex50"} />
              </div>
              {selectedTooltipTab === "basic" && <>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-Time">
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay">
                      <div>Time</div>
                    </div>                
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeInputs">
                      {setpoint.time === 0 && <>
                        <NumberInput value={setpoint.time} type={"hours"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                        <NumberInput value={setpoint.time} type={"minutes"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>  
                      </>}
                      {setpoint.time !== 0 && <>
                        <NumberInput value={setpoint.time} stepper={true} type={"hours"} stepAmount={1} onChange={timeChanged} onBlur={finalizeTimeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                        <NumberInput value={setpoint.time} stepper={true} type={"minutes"} stepAmount={15} onChange={timeChanged} onBlur={finalizeTimeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </>}
                    </div>
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-Value">
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueDisplay">
                      <div>Value</div>
                    </div>
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueInputs">
                      <NumberInput value={parseFloat(setpoint.value)} suffix={setpointTypeInfo.suffix} stepper={true} stepAmount={setpointTypeInfo.resolution} onChange={valueChanged} onBlur={finalizeValueChanged} size={6} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                    </div>
                  </div>
                  {(setpointTypeInfo.name === "spray_rate" || setpointTypeInfo.name === "drip_irrigation_rate") && <>
                    {(() => {
                      const frequencyChanged = (newFrequency) => {
                        let valid = true
                        if (newFrequency < 1) {
                          newFrequency = 1
                          valid = false
                        } else if (newFrequency > 999) {
                          newFrequency = 999
                          valid = false
                        }

                        let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

                        if (tempSetpointInfo === undefined) {
                          tempSetpointInfo = {
                            id: selectedSetpoint.id,
                            value: { r: selectedSetpoint.value }
                          }
                          chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
                        }
                        tempSetpointInfo.value.f = newFrequency
                        tempSetpointInfo.ignore = true

                        if (!valid)
                          return newFrequency
                      }

                      const finalizeFrequencyChanged = (newFrequency) => {
                        let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
                        if (tempSetpointInfo === undefined) {
                          tempSetpointInfo = {
                            id: selectedSetpoint.id,
                            value: { r: selectedSetpoint.value }
                          }
                          chartRef.current.tempSetpointInfo.push(tempSetpointInfo)
                        }
                        tempSetpointInfo.value.f = newFrequency
                        tempSetpointInfo.ignore = false
                      }
                      const totalRuntime = 3600 * (setpoint.value / 100)
                      const runtime = totalRuntime / setpoint.fValue
                      const offtime = (3600 - totalRuntime) / setpoint.fValue
                      return (<>
                        <div className="GrowZoneRecipeManager-Tooltip-Inputs-Frequency">
                          <div className="GrowZoneRecipeManager-Tooltip-Inputs-FrequencyDisplay">
                            <div>Frequency</div>
                          </div>
                          <div className="GrowZoneRecipeManager-Tooltip-Inputs-FrequencyInputs">
                            <NumberInput value={setpoint.fValue} suffix={"/h"} stepper={true} onChange={frequencyChanged} onBlur={finalizeFrequencyChanged} size={4} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                            <div style={{ fontSize: 13 }}><span>Runtime: {runtime}s, Offtime: {offtime}s</span></div>
                          </div>
                        </div>
                      </>)
                    })()}
                  </>}

                </div>
              </>}
              {selectedTooltipTab === "advanced" && <>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                  {(setpointTypeInfo.name !== "spray_rate" && setpointTypeInfo.name !== "drip_irrigation_rate") && <>
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-Range">
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-RangeDisplay">
                        {(() => {
                          let setpointRange = parseFloat(setpoint.range)
                          let tempSetpointInfo = chartRef.current.tempSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
                          if (tempSetpointInfo !== undefined && tempSetpointInfo.range !== undefined) {
                            setpointRange = tempSetpointInfo.range
                          }
                          return <>
                            <div>Range</div>
                            <div>{parseFloat(setpoint.value) - parseFloat(setpointRange)}-{parseFloat(setpoint.value) + parseFloat(setpointRange)}</div>

                          </>
                        })()}
                      </div>
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-RangeInputs">
                        <NumberInput value={parseFloat(setpoint.range)} prefix={"+/-"} suffix={setpointTypeInfo.suffix} stepper={true} stepAmount={setpointTypeInfo.resolution} onChange={rangeChanged} onBlur={finalizeRangeChanged} size={6} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </div>
                    </div>
                  </>}

                  <div className="GrowZoneRecipeManager-Tooltip-AdvancedFunctions">
                    <Button status={"Primary-Toggle"} state={setpoint.function === "instant"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("instant") }} content={<Functions_Instant />} width={"Flex25"} />
                    <Button status={"Primary-Toggle"} state={setpoint.function === "gradual"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("gradual") }} content={<Functions_OverTime />} width={"Flex25"} />
                    {(setpointTypeInfo.name !== "spray_rate" && setpointTypeInfo.name !== "drip_irrigation_rate") && <>
                      <Button status={"Primary-Toggle"} state={setpoint.function === "sine_wave"}
                        contentPadding={"10px 15px"}
                        onClick={() => { setFunction("sine_wave") }} content={<Functions_SineWave />} width={"Flex25"} />
                      <Button status={"Primary-Toggle"} state={setpoint.function === "square_wave"}
                        contentPadding={"10px 15px"}
                        onClick={() => { setFunction("square_wave") }} content={<Functions_SquareWave />} width={"Flex25"} />
                    </>}
                  </div>
                  {showFunctionInputs &&
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions">
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions-Amplitude">
                        <div>Amplitude</div>
                        <NumberInput value={setpoint.function_params.a} suffix={setpointTypeInfo.suffix} stepper={true} stepAmount={setpointTypeInfo.resolution} size={4} onChange={amplitudeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </div>
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions-Frequency">
                        <div>Frequency</div>
                        <NumberInput value={setpoint.function_params.f} suffix="/hr" stepper={true} size={3} onChange={frequencyChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </div>
                    </div>
                  }                     
                </div>
              </>}
              {isEditingRecipe && <>
                {setpoint.time !== 0 &&
                  <Button content="Remove Setpoint" status="Critical" onClick={deleteSetpointPressed} width={"Flex100"}/>
                }              
              </>}
            </div>
            
          )
        }



      } else {
        let setpoint = { ...selectedSetpoint }
        const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == "light_intensity")

        const timeChanged = (newTime) => {
          let valid = true
          if (newTime < 0) {
            newTime = 0
            valid = false
          } else if (newTime > currentDuration) {
            newTime = currentDuration
            valid = false
          }

          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.time = newTime
          tempSetpointInfo.ignore = true

          if (!valid) {
            return newTime
          }
        }

        const finalizeTimeChanged = (newTime) => {
          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.time = newTime
          tempSetpointInfo.ignore = false

          SetPointerOverSetpointChartDate(newTime * 1000)

        }

        const valueChanged = (newValue) => {
          let valid = true
          if (newValue < setpointTypeInfo.min) {
            newValue = setpointTypeInfo.min
            valid = false
          } else if (newValue > maxPPFD) {
            newValue = maxPPFD
            valid = false
          }

          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.value = newValue
          tempSetpointInfo.ignore = true


          if (!valid)
            return newValue
        }

        const finalizeValueChanged = (newValue) => {

          const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()
          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }
          tempSetpointInfo.value = newValue
          tempSetpointInfo.ignore = false

          let desiredValueY = chartArea.height - (newValue / (setpointAxisVisibleRange.end - setpointAxisVisibleRange.start)) * chartArea.height
          SetPointerOverSetpointChartY(desiredValueY)
        }

        const setFunction = (newFunction) => {
          if (!isEditingRecipe) {
            return
          }
          if (setpoint.function !== newFunction) {
            let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
            if (tempSetpointInfo === undefined) {
              tempSetpointInfo = {
                id: selectedSetpoint.id
              }
              chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
            }

            tempSetpointInfo.function = newFunction
            if (tempSetpointInfo.function_params === undefined) {
              tempSetpointInfo.function_params = {}
              if (setpoint.function_params.a !== undefined) {
                tempSetpointInfo.function_params.a = setpoint.function_params.a
              } else {
                tempSetpointInfo.function_params.a = 100
              }

              if (setpoint.function_params.f !== undefined) {
                tempSetpointInfo.function_params.f = setpoint.function_params.f
              } else {
                tempSetpointInfo.function_params.f = 2
              }

            } else {
              tempSetpointInfo.function_params = {
                a: 100,
                f: 2
              }
            }

            forceRerender()
          }
        }

        const amplitudeChanged = (newAmplitude) => {
          let valid = true
          if (newAmplitude < 0) {
            newAmplitude = 0
            valid = false
          } else if (newAmplitude > setpointTypeInfo.max) {
            newAmplitude = setpointTypeInfo.max
            valid = false
          }

          const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()
          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }

          if (tempSetpointInfo.function_params === undefined) {
            tempSetpointInfo.function_params = { f: 2 }
          }

          tempSetpointInfo.function_params.a = newAmplitude

          forceRerender()

          if (!valid)
            return newAmplitude
        }


        const frequencyChanged = (newFrequency) => {
          let valid = true
          if (newFrequency < 0) {
            newFrequency = 0
            valid = false
          } else if (newFrequency > setpointTypeInfo.max) {
            newFrequency = setpointTypeInfo.max
            valid = false
          }

          const setpointAxisVisibleRange = chartRef.current.lightingYAxis.getInterval()
          let tempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)

          if (tempSetpointInfo === undefined) {
            tempSetpointInfo = {
              id: selectedSetpoint.id
            }
            chartRef.current.tempLightingSetpointInfo.push(tempSetpointInfo)
          }

          if (tempSetpointInfo.function_params === undefined) {
            tempSetpointInfo.function_params = { a: 100 }
          }

          tempSetpointInfo.function_params.f = newFrequency

          forceRerender()

          if (!valid)
            return newFrequency
        }



        let foundTempSetpointInfo = chartRef.current.tempLightingSetpointInfo.find((tS) => selectedSetpoint.id == tS.id)
        if (foundTempSetpointInfo !== undefined) {
          if (foundTempSetpointInfo.time !== undefined) {
            setpoint.time = foundTempSetpointInfo.time
          }
          if (foundTempSetpointInfo.value !== undefined) {
            setpoint.value = foundTempSetpointInfo.value
          }
          if (foundTempSetpointInfo.function !== undefined) {
            setpoint.function = foundTempSetpointInfo.function
          }
          if (foundTempSetpointInfo.function_params !== undefined) {
            setpoint.function_params = foundTempSetpointInfo.function_params
          }
        }

        const showFunctionInputs = (setpoint.function == "sine_wave" || setpoint.function == "square_wave") ? true : false



        const deleteSetpointPressed = (e) => {
          dispatch(pushRecipeChange({
            recipe: {
              ...recipe,
              timeline_items: [...recipe.timeline_items.map((timelineItem) => {
                if (timelineItem.id != selectedTimelineItem.id) {
                  return timelineItem
                }
                return {
                  ...timelineItem,
                  item: {
                    ...timelineItem.item,
                    lighting_intensity_setpoint_zones: selectedTimelineItem.item.lighting_intensity_setpoint_zones.map(function (sZ) {
                      return {
                        ...sZ, setpoints: sZ.setpoints.filter((setpoint, setpointIndex) => {
                          if (selectedSetpoint.id != setpoint.id)
                            return true
                          return false
                        })
                      }
                    })
                  }
                }
              })]
            }
          }))


          SetSelectedSetpoint(undefined)
          SetDraggingSetpointMoveType(undefined)
          SetPointerOverSetpointChartDate(undefined)

        }


        if (pointerDownOverSetpoint !== undefined) {
          return (
            <div className="GrowZoneRecipeManager-Tooltip GrowZoneRecipeManager-TooltipMoving" style={tooltipStyleProps}>
              <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                <div className="GrowZoneRecipeManager-Tooltip-Inputs-Time">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay">
                    <div>Time</div>
                    {/*<div>{hours}:{minutes}</div>*/}
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeInputs">
                    <NumberInput value={setpoint.time} type={"hours"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                    <NumberInput value={setpoint.time} type={"minutes"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                  </div>
                </div>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs-Value">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueDisplay">
                    <div>Value</div>
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueInputs">
                    <NumberInput value={parseFloat(setpoint.value)} suffix={setpointTypeInfo.suffix} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="GrowZoneRecipeManager-Tooltip GrowZoneRecipeManager-TooltipEdit" style={tooltipStyleProps}>
              <div className="GrowZoneRecipeManager-Tooltip-Header">
                <div>Lighting Intensity Setpoint</div>
              </div>
              <div className="FlexContent-H-5 FlexContent-HFill">
                <Button status="Primary-Toggle" state={selectedTooltipTab === "basic"} content="Basic" onClick={() => { SetSelectedTooltipTab("basic") }} width={"Flex50"} />
                <Button status="Primary-Toggle" state={selectedTooltipTab === "advanced"} content="Advanced" onClick={() => { SetSelectedTooltipTab("advanced") }} width={"Flex50"} />
              </div>
              {selectedTooltipTab === "basic" && <>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-Time">
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay">
                      <div>Time</div>
                    </div>                
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-TimeInputs">
                      {setpoint.time === 0 && <>
                        <NumberInput value={setpoint.time} type={"hours"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false} />
                        <NumberInput value={setpoint.time} type={"minutes"} canEdit={false} disabled={!isEditingRecipe} showDisabledVisual={false}/>  
                      </>}
                      {setpoint.time !== 0 && <>
                          <NumberInput value={setpoint.time} stepper={true} type={"hours"} stepAmount={1} onChange={timeChanged} onBlur={finalizeTimeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                          <NumberInput value={setpoint.time} stepper={true} type={"minutes"} stepAmount={15} onChange={timeChanged} onBlur={finalizeTimeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </>}
                    </div>
                  </div>
                  <div className="GrowZoneRecipeManager-Tooltip-Inputs-Value">
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueDisplay">
                      <div>Value</div>
                    </div>
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-ValueInputs">
                      <NumberInput value={parseFloat(setpoint.value)} suffix={setpointTypeInfo.suffix} stepper={true} stepAmount={setpointTypeInfo.resolution} onChange={valueChanged} onBlur={finalizeValueChanged} size={6} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                    </div>
                  </div>
                </div>
              </>}
              {selectedTooltipTab === "advanced" && <>
                <div className="GrowZoneRecipeManager-Tooltip-Inputs">
                  <div className="GrowZoneRecipeManager-Tooltip-AdvancedFunctions">
                    <Button status={"Primary-Toggle"} state={setpoint.function === "instant"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("instant") }} content={<Functions_Instant />} width={"Flex25"} />
                    <Button status={"Primary-Toggle"} state={setpoint.function === "gradual"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("gradual") }} content={<Functions_OverTime />} width={"Flex25"} />
                    <Button status={"Primary-Toggle"} state={setpoint.function === "sine_wave"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("sine_wave") }} content={<Functions_SineWave />} width={"Flex25"} />
                    <Button status={"Primary-Toggle"} state={setpoint.function === "square_wave"}
                      contentPadding={"10px 15px"}
                      onClick={() => { setFunction("square_wave") }} content={<Functions_SquareWave />} width={"Flex25"} />
                  </div>
                  {showFunctionInputs &&
                    <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions">
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions-Amplitude">
                        <div>Amplitude</div>
                        <NumberInput value={setpoint.function_params.a} suffix={setpointTypeInfo.suffix} stepper={true} stepAmount={setpointTypeInfo.resolution} size={4} onChange={amplitudeChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </div>
                      <div className="GrowZoneRecipeManager-Tooltip-Inputs-Functions-Frequency">
                        <div>Frequency</div>
                        <NumberInput value={setpoint.function_params.f} suffix="/hr" stepper={true} size={3} onChange={frequencyChanged} disabled={!isEditingRecipe} showDisabledVisual={false}/>
                      </div>
                    </div>
                  }
                </div>
                
              </>}
              {isEditingRecipe && <>
                {setpoint.time !== 0 &&
                  <Button content="Remove Setpoint" status="Critical" onClick={deleteSetpointPressed} width={"Flex100"}/>
                }
              </>}
            </div>
          )
        }
      }

    } else {
      let selectedSetpointTypes = []
      for (const setpointTypeGroupIdentifier in setpointTypeToggles) {
        const setpointTypeGroup = setpointTypeToggles[setpointTypeGroupIdentifier]
        for (const setpointType in setpointTypeGroup.setpointTypes) {
          const setpointTypeInfo = setpointTypeGroup.setpointTypes[setpointType]
          let identifier = setpointTypeInfo.identifier

          if (setpointTypeInfo.active) {
            selectedSetpointTypes.push(setpointTypeInfo)
          }
        }
      }


      const lightingSetpointTypeInfo = recipeSetpointTypes.find((t) => t.name == "light_intensity")
      let foundLightingSetpoint = null
      let isLightingSlave = false
      if (selectedTimelineItem !== null) {
        for (let relationship of selectedTimelineItem.item.relationships) {
          if (relationship.slave_type_id === lightingSetpointTypeInfo.id) {

            let foundMasterSetpoint = null
            const masterSetpointTypeInfo = recipeSetpointTypes.find((t) => t.id == relationship.master_type_id)
            for (let setpointZone of selectedTimelineItem.item.setpoint_zones.filter((sZ) => sZ.type_id === masterSetpointTypeInfo.id)) {
              for (let setpoint of setpointZone.setpoints) {
                if (setpoint.time * 1000 <= pointerOverSetpointChartDate && (foundMasterSetpoint == null || setpoint.time > foundMasterSetpoint.time)) {
                  foundMasterSetpoint = setpoint
                }
              }
            }
            
            if (foundMasterSetpoint !== null) {
              foundLightingSetpoint = {
                function: foundMasterSetpoint.function,
                function_params: foundMasterSetpoint.function_params,
                index: foundMasterSetpoint.index,
                time: foundMasterSetpoint.time,
                type_id: lightingSetpointTypeInfo.id,
                value: foundMasterSetpoint.value
              }


              if (relationship.function === "remap_range") {
                foundLightingSetpoint.value = remapRange(parseFloat(foundMasterSetpoint.value), [parseFloat(relationship.values[0]), parseFloat(relationship.values[1])], [parseFloat(relationship.values[2]), parseFloat(relationship.values[3])])
              } else if (relationship.function === "offset") {
                foundLightingSetpoint.value = parseFloat(foundMasterSetpoint.value) + parseFloat(relationship.values[0])
              }

              isLightingSlave = true
            }
            break
          }
        }

        if (!isLightingSlave) {
          if (selectedTimelineItem.item !== null && selectedTimelineItem.item.lighting_intensity_setpoint_zones !== null) {
            for (let lighting_setpoint_zone of selectedTimelineItem.item.lighting_intensity_setpoint_zones) {
              for (let setpoint of lighting_setpoint_zone.setpoints) {
                if (setpoint.time * 1000 <= pointerOverSetpointChartDate && (foundLightingSetpoint == null || setpoint.time > foundLightingSetpoint.time)) {
                  foundLightingSetpoint = setpoint
                }
              }
            }
          }
        }
        //For all setpoints find the one that is before this time but the latest time
      }
      let calculatedLightingValue = 0
      if (foundLightingSetpoint !== null) {
        calculatedLightingValue = foundLightingSetpoint.value
      }

      return (
        <div id="Recipe-ZoneManager-SetpointChart_Tooltip" style={tooltipStyleProps}>
          <table className="Recipe-ZoneManager-SetpointChart_Tooltip-Table">
            <thead><tr>
              <th> </th>
              <th> </th>

            </tr></thead>
            <tbody>
              {selectedSetpointTypes.map((setpointType) => {
                //do some math if necessary
                let value = (Math.round(0 * 100) / 100).toFixed(2)

                const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == setpointType.identifier)
                let foundSetpoint = null
                if (selectedTimelineItem.item !== null && selectedTimelineItem.item.setpoint_zones !== null) {
                  let isSlave = false
                  for (let relationship of selectedTimelineItem.item.relationships) {
                    if (relationship.slave_type_id === setpointTypeInfo.id) {
                      isSlave = true
                      let foundMasterSetpoint = null
                      const masterSetpointTypeInfo = recipeSetpointTypes.find((t) => t.id == relationship.master_type_id)
                      for (let setpointZone of selectedTimelineItem.item.setpoint_zones.filter((sZ) => sZ.type_id === masterSetpointTypeInfo.id)) {
                        for (let setpoint of setpointZone.setpoints)  {
                          if (setpoint.time * 1000 <= pointerOverSetpointChartDate && (foundMasterSetpoint == null || setpoint.time > foundMasterSetpoint.time)) {
                            foundMasterSetpoint = setpoint
                          }
                        }
                      }
                      foundSetpoint = {
                        function: foundMasterSetpoint.function,
                        function_params: foundMasterSetpoint.function_params,
                        index: foundMasterSetpoint.index,
                        time: foundMasterSetpoint.time,
                        type_id: setpointTypeInfo.id,
                        value: foundMasterSetpoint.value
                      }

                      if (setpointTypeInfo.name === "spray_rate") {
                        if (relationship.function === "remap_range") {
                          foundSetpoint.value = remapRange(parseFloat(foundMasterSetpoint.value), [parseFloat(relationship.values[0]), parseFloat(relationship.values[1])], [parseFloat(relationship.values[2]), parseFloat(relationship.values[3])])
                        } else if (relationship.function === "offset") {

                        }
                      } else {
                        if (relationship.function === "remap_range") {
                          foundSetpoint.value = remapRange(parseFloat(foundMasterSetpoint.value), [parseFloat(relationship.values[0]), parseFloat(relationship.values[1])], [parseFloat(relationship.values[2]), parseFloat(relationship.values[3])])
                        } else if (relationship.function === "offset") {
                          foundSetpoint.value = parseFloat(foundMasterSetpoint.value) + parseFloat(relationship.values[0])
                        }
                      }

                      break
                    }
                  }

                  if (!isSlave) {
                    if (selectedTimelineItem.item.setpoint_zones) {
                      for (const currentSetpointZone of selectedTimelineItem.item.setpoint_zones) {
                        for (let setpoint of currentSetpointZone.setpoints) {
                          if (currentSetpointZone.type_id === setpointTypeInfo.id) {
                            if (setpointTypeInfo.name == "spray_rate") {
                              const sprayInfo = JSON.parse(setpoint.value)
                              setpoint = { ...setpoint, value: sprayInfo.r, fValue: sprayInfo.f }
                            } else if (setpointTypeInfo.name == "drip_irrigation_rate") {
                              const dripInfo = JSON.parse(setpoint.value)
                              setpoint = { ...setpoint, value: dripInfo.r, fValue: dripInfo.f }
                            }
                            if (currentSetpointZone.type_id == setpointTypeInfo.id && setpoint.time * 1000 <= pointerOverSetpointChartDate && (foundSetpoint == null || setpoint.time > foundSetpoint.time)) {
                              foundSetpoint = setpoint
                            }
                          }
                        }
                      }
                    }
                  }
                }
                //For all setpoints find the one that is before this time but the latest time
                let calculatedValue
                if (foundSetpoint !== null) {
                  calculatedValue = foundSetpoint.value
                }
                return (
                  <tr key={setpointType.identifier}>
                    <td><div className="Recipe-ZoneManager-SetpointChart_Tooltip-ColorIndicator" style={{ backgroundColor: setpointType.color }} /></td>
                    <td>{setpointType.label}</td>
                    <td>{calculatedValue}<span className="Recipe-ZoneManager-SetpointChart_Tooltip-Unit">{setpointTypeInfo.suffix}</span></td>
                  </tr>
                )
              })}

              <tr>
                <td><div className="Recipe-ZoneManager-SetpointChart_Tooltip-ColorIndicator" style={{ backgroundColor: "#000" }} /></td>
                <td>{"PPFD"}</td>
                {lightingSetpointTypeInfo &&
                  <td>{calculatedLightingValue}<span className="Recipe-ZoneManager-SetpointChart_Tooltip-Unit">{lightingSetpointTypeInfo.suffix}</span></td>
                }
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  })



  const updateSetpointChart = React.useCallback(() =>  {
    if (!chartRef.current || (selectedTimelineItem === undefined  || selectedTimelineItem === null) || !haveAppInfo || selectedTimelineItem.item === null) 
      return

    calculateSetpoints()

    const validateSetpoinTypeToggleIsActive = (setpointType) =>  {
      if (setpointType !== undefined) {
          for (const [groupKey, setpointGroup] of Object.entries(setpointTypeToggles))  {
            for (const setpointGroupType of Object.values(setpointGroup.setpointTypes))  {
                if (setpointGroupType.identifier == setpointType.name)  {
                if (activeToggles.indexOf(setpointGroupType.identifier) === -1) {
                    activeToggles.push(setpointGroupType.identifier)
                }
                }
            }
          }
      }
    }
    
    //Activate all necessary data toggles
    let activeToggles = []
    if ((selectedTimelineItem !== undefined && selectedTimelineItem !== null))  {
      if (selectedTimelineItem.item.setpoint_zones)  {
        for (let setpointZone of selectedTimelineItem.item.setpoint_zones) {
          const setpointType = recipeSetpointTypes.find((t) => t.id == setpointZone.type_id)
          validateSetpoinTypeToggleIsActive(setpointType)

          for (let relationship of selectedTimelineItem.item.relationships) {
            if (relationship.master_type_id === setpointType.id)  {
              const slaveSetpointType = recipeSetpointTypes.find((t) => t.id == relationship.slave_type_id)
              validateSetpoinTypeToggleIsActive(slaveSetpointType)
            }
          }
        }
      }
    }
    
    let togglesChanged = false
    for (const [groupKey, setpointGroup] of Object.entries(setpointTypeToggles))  {
      for (const setpointGroupType of Object.values(setpointGroup.setpointTypes))  {
          if (activeToggles.indexOf(setpointGroupType.identifier) !== -1) {
            if (!setpointGroupType.active) {
                setpointGroupType.active = true
                togglesChanged = true
            }
            }else {
              if (setpointGroupType.active) {
                  setpointGroupType.active = false
                  togglesChanged = true
              }
          }
      }
    }


    if (togglesChanged) {
    SetSetpointTypeToggles({...setpointTypeToggles})
    }
    
    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()

    //Make sure all y axes are properly loaded in
    //selectChartAxisTypeByIdentifier
    let currentRequiredYAxes = []
    for (const [groupKey, setpointGroup] of Object.entries(setpointTypeToggles))  {
    if (groupKey !== "lighting")  {
        for (const setpointType of Object.values(setpointGroup.setpointTypes))  {
        if (setpointType.identifier !== undefined && setpointType.active && currentRequiredYAxes.indexOf(setpointType.identifier) === -1)    {
            currentRequiredYAxes.push(setpointType.identifier)
        }
        }
    }
    }


    
    for (const yAxisIdentifier of currentRequiredYAxes) {
        if (chartRef.current.activeYAxes[yAxisIdentifier] === undefined)    {
            //YAxis is missing, lets create it
            const yAxisInfo = recipeSetpointTypes.find((t) => t.name == yAxisIdentifier)
            if (yAxisInfo !== undefined)    {
                chartRef.current.activeYAxes[yAxisIdentifier] = chartRef.current.setpointChart.addAxisY()
                chartRef.current.activeYAxes[yAxisIdentifier].setInterval({start: yAxisInfo.min, end: yAxisInfo.max})
                    .setMouseInteractions(false)
                    .setTickStrategy(AxisTickStrategies.Empty)
                    .setScrollStrategy(undefined)
                    .setStrokeStyle(emptyLine)
                    .setThickness(0)
                    .onIntervalChange((axis, start, end) => {
                        if (start !== yAxisInfo.min || end !== yAxisInfo.max)  {
                            axis.setInterval({start: yAxisInfo.min, end: yAxisInfo.max})
                        }
                    })
            }
        }
    }
    for (const yAxisIdentifier in chartRef.current.activeYAxes) {
        //Check that we require all of the ones here
        if (currentRequiredYAxes.indexOf(yAxisIdentifier) === -1)   {
            //We need to remove this one
            chartRef.current.activeYAxes[yAxisIdentifier].dispose()
            delete  chartRef.current.activeYAxes[yAxisIdentifier]
        }else if (chartRef.current.activeYAxes[yAxisIdentifier] !== undefined) {
            chartRef.current.activeYAxes[yAxisIdentifier].setTickStrategy(AxisTickStrategies.Empty).setThickness(0)
        }
    }


    let activeSetpointType = null;
    if (activeChartZone !== "lighting") {
        activeSetpointType = setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType]
        if (activeSetpointType) {
            if (chartRef.current.activeYAxes[activeSetpointType.identifier] !== undefined) {
                chartRef.current.activeYAxes[activeSetpointType.identifier].setTickStrategy(AxisTickStrategies.Numeric).setThickness(yAxisConstantWidth)
            }
        }
    
        chartRef.current.lightingYAxis.setTickStrategy(AxisTickStrategies.Empty)
    }else {
        chartRef.current.lightingYAxis.setTickStrategy(AxisTickStrategies.Numeric)
    }
    
    

    
    for (const [groupKey, setpointGroup] of Object.entries(setpointTypeToggles))  {
      for (const setpointType of Object.values(setpointGroup.setpointTypes))  {
          if (groupKey !== "lighting")  {
            if (setpointType.active)    {
                if (chartRef.current.activeYAxes[setpointType.identifier] !== undefined) {
                const lineColor = setpointType.color.replace("rgb(", '').replace(")", '').split(',')
                const highlightColor = setpointType.highlightColor.replace("rgb(", '').replace(")", '').split(',')
                const selectColor = setpointType.selectColor.replace("rgb(", '').replace(")", '').split(',')
                // Validate that this series is added
                if (chartRef.current.dataSeries[setpointType.identifier] === undefined)    {
                    chartRef.current.dataSeries[setpointType.identifier] = { 
                        lineSeries: chartRef.current.setpointChart.addLineSeries({
                            dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                            yAxis: chartRef.current.activeYAxes[setpointType.identifier]
                            }).setStrokeStyle(new SolidLine({ 
                                thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor)})
                            }))
                            .setEffect(false)
                            .setMouseInteractions(false),
                        pointSeries: chartRef.current.setpointChart.addPointSeries({
                            dataPattern: {pattern: 'ProgressiveX', regularProgressiveStep: false},
                            yAxis: chartRef.current.activeYAxes[setpointType.identifier],
                            pointShape: PointShape.Circle,
                            }).setMouseInteractions(false)
                            .setAutoScrollingEnabled(false)
                            .setPointSize(10.0)
                            .setEffect(false)
                            //.setPointFillStyle(new SolidFill({color: ColorRGBA(...lineColor)})),
                            .setPointFillStyle(new IndividualPointFill({color: ColorRGBA(...lineColor, 255)})),
                        changedVersion: -1
                    }                           
                }

                if (activeSetpointType == setpointType) {
                    chartRef.current.dataSeries[setpointType.identifier].lineSeries.setStrokeStyle(new SolidLine({ 
                    thickness: 2, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 255)})
                    }))
                    //chartRef.current.dataSeries[setpointType.identifier].pointSeries.setPointSize(10).setPointFillStyle(new IndividualPointFill({color: ColorRGBA(...lineColor, 255)}))


                }else {
                    chartRef.current.dataSeries[setpointType.identifier].lineSeries.setStrokeStyle(new SolidLine({ 
                    thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 150)})
                    }))
                    //chartRef.current.dataSeries[setpointType.identifier].pointSeries.setPointSize(10).setPointFillStyle(new IndividualPointFill({color: ColorRGBA(...lineColor, 50)}))


                }

                //console.log(chartRef.current.setpointTypes)

                if (chartRef.current.setpointTypes[setpointType.identifier] !== undefined)  {
                  //Determine setpoint data
                  //activeAirZones

                  //chartRef.current.setpointTypes[setpointType.identifier]

                  //Figure out which zone to show
                  let currentSetpoints = chartRef.current.setpointTypes[setpointType.identifier][0]

                  //currentSetpoints = {lineData: [], setpoints: [], pointData: []}

                  //console.log(activeSetpointZones)
                  //console.log(currentSetpoints)


                  chartRef.current.dataSeries[setpointType.identifier].lineSeries.clear().add(currentSetpoints.lineData);
                  
                  chartRef.current.dataSeries[setpointType.identifier].pointSeries.clear().add(currentSetpoints.setpoints.map(setpoint => {
                      let setpointTime = setpoint.time
                      let setpointValue = setpoint.value
                      
                      
                      return {
                        x: setpointTime * 1000,
                        y: setpointValue,
                        color: (pointerOverSetpoint !== undefined && (pointerOverSetpoint.id === setpoint.id)) ? ColorRGBA(...highlightColor, 255) : ((activeSetpointType == setpointType) ? ColorRGBA(...lineColor, 255) : ColorRGBA(...lineColor, 50))
                      }
                  }));
                }
              }
          }else {
              if (chartRef.current.dataSeries[setpointType.identifier] !== undefined)    {
                delete chartRef.current.dataSeries[setpointType.identifier]
              }

          }
        }else {

          //Add lighting to chart 
          let currentSetpoints = []
          if (chartRef.current.setpointTypes[setpointType.identifier][0] !== undefined) {
            currentSetpoints = chartRef.current.setpointTypes[setpointType.identifier][0]
          }



          chartRef.current.lightingSeries[setpointType.identifier].clear().add(currentSetpoints);
        }
      }
    }

    //Add total light intensity to chart
    if (chartRef.current.setpointTypes.totalIntensity !== undefined)  {


      //Figure out which zone to show
      let currentIntensitySetpoints = {data: [], pointData: []}
      if (chartRef.current.setpointTypes.totalIntensity[0] !== undefined) {
        currentIntensitySetpoints = chartRef.current.setpointTypes.totalIntensity[0]
      }
      //console.log(currentIntensitySetpoints)
      chartRef.current.lightingSeries.totalIntensity.lineSeries.clear().add(currentIntensitySetpoints.data)
      chartRef.current.lightingSeries.totalIntensity.pointSeries.clear().add(currentIntensitySetpoints.pointData)

      if (activeChartZone == "lighting") {
          chartRef.current.lightingSeries.totalIntensity.pointSeries.setPointSize(10).setPointFillStyle(new SolidFill({color: ColorRGBA(0, 0, 0, 255)}))
      }else {
          chartRef.current.lightingSeries.totalIntensity.pointSeries.setPointSize(10).setPointFillStyle(new SolidFill({color: ColorRGBA(0, 0, 0, 50)}))
      }

      let spectralIndex = 0
      for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes))  {
          const lineColor = setpointType.color.replace("rgb(", '').replace(")", '').split(',')
          if (activeChartZone == "lighting") {
            if (spectralIndex == 0) {
                chartRef.current.lightingSeries[key].setStrokeStyle(new SolidLine({ 
                  thickness: 2, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 255)})
                })).setFillStyle(new SolidFill({color: ColorRGBA(...lineColor, 100)}))
                
            }else {
                chartRef.current.lightingSeries[key].setHighStrokeStyle(new SolidLine({
                  thickness: 2, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 255)})
                })).setHighFillStyle(new SolidFill({color: ColorRGBA(...lineColor, 100)}))
            } 
          }else {
            if (spectralIndex == 0) {
                chartRef.current.lightingSeries[key].setStrokeStyle(new SolidLine({ 
                  thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 40)})
                })).setFillStyle(new SolidFill({color: ColorRGBA(...lineColor, 40)}))
                
            }else {
                chartRef.current.lightingSeries[key].setHighStrokeStyle(new SolidLine({
                  thickness: 0.5, fillStyle: new SolidFill({color: ColorRGBA(...lineColor, 40)})
                })).setHighFillStyle(new SolidFill({color: ColorRGBA(...lineColor, 40)}))
            }
          }

          spectralIndex++
      }

    }

})
  
  React.useEffect(() => {
    updateSetpointChart();
  }, [chartRef, selectedTimelineItem, timelineItems, recipe, setpointTypeToggles])
  updateSetpointChart()



  const setpointChartContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (!setpointChartContainerRef.current) {
      chartRef.current = undefined
      return
    }

    const setpointChart = createLightningChart({
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
      },
    }).ChartXY({
      container: setpointChartContainerRef.current,
      theme: setpointChartTheme,
    }).setMouseInteractionRectangleZoom(false)
      .setMouseInteractionRectangleFit(false)
      .setMouseInteractionWheelZoom(true)
      .setTitle("")
      .setPadding({ top: 0, left: chartLeftPadding, right: chartRightPadding, bottom: 0 })
      .setAutoCursorMode(AutoCursorModes.disabled)
      .setBackgroundFillStyle(new SolidFill({ color: ColorRGBA(255, 255, 255, 0) }))
      .setBackgroundStrokeStyle(emptyLine)
      .setSeriesBackgroundFillStyle(new SolidFill({ color: ColorHEX("#F7F8FB") }))
      .setSeriesBackgroundStrokeStyle(new SolidLine({ thickness: 3, fillStyle: new SolidFill({ color: ColorHEX("#858585") }) }))



    setpointChart.getDefaultAxisY()
      .setMouseInteractions(false)
      .setTickStrategy(AxisTickStrategies.Empty)

    let defaultSetpointChartInterval = { start: 0, end: currentDuration * 1000 }
    const setpointChartDateAxis = setpointChart.getDefaultAxisX()
    setpointChartDateAxis
      .setTickStrategy(AxisTickStrategies.Empty)
      .setScrollStrategy(undefined)
      .setAnimationsEnabled(false)
      .setChartInteractionPanByDrag(true)
      .setChartInteractionZoomByWheel(true)
      .setChartInteractionFitByDrag(false)
      .setNibInteractionScaleByWheeling(true)
      .setDefaultInterval({ start: defaultSetpointChartInterval.start, end: defaultSetpointChartInterval.end })
      .setIntervalRestrictions((state) => ({
        startMin: defaultSetpointChartInterval.start,
        endMax: defaultSetpointChartInterval.end,
      }))
      .setThickness(timeAxisConstantHeight)

    setpointChartDateAxis.onIntervalChange((axis, start, end) => {
      checkSetpointChartInterval(start, end)
    })
    let setpointChartTimeTicks = []
    let lastSetpointChartInterval = { start: 0, end: 0 }
    let lastSetpointChartTickRange = { start: 0, end: 0 }
    updateSetpointChartAxisTicks(defaultSetpointChartInterval.start, defaultSetpointChartInterval.end)

    let lightingYAxis = setpointChart.addAxisY()
    lightingYAxis.setInterval({ start: 0, end: maxLightingIntensityAxisLimit })
      .setMouseInteractions(false)
      .setTickStrategy(AxisTickStrategies.Empty)
      .setScrollStrategy(undefined)
      .setStrokeStyle(emptyLine)
      .onIntervalChange((axis, start, end) => {
        if (start !== 0 || end !== maxLightingIntensityAxisLimit) {
          chartRef.current.lightingYAxis.setInterval({ start: 0, end: maxLightingIntensityAxisLimit })
        }
      })

    let lightingSeries = {
      totalIntensity: {
        lineSeries: setpointChart.addLineSeries({
          dataPattern: { pattern: 'ProgressiveX', regularProgressiveStep: false },
          yAxis: lightingYAxis
        }).setStrokeStyle(emptyLine)
          .setEffect(false)
          .setMouseInteractions(false),
        pointSeries: setpointChart.addPointSeries({
          dataPattern: { pattern: 'ProgressiveX', regularProgressiveStep: false },
          yAxis: lightingYAxis,
          pointShape: PointShape.Circle,
        }).setMouseInteractions(false)
          .setAutoScrollingEnabled(false)
          .setEffect(false)
          .setPointSize(10.0)
          .setPointFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 255) }))
          .setMouseInteractions(false)
      }
    }

    let spectrumIndex = 0;
    for (const [key, setpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes)) {
      const lineColor = setpointType.color.replace("rgb(", '').replace(")", '').split(',')
      if (spectrumIndex == 0) {
        lightingSeries[key] = setpointChart.addAreaSeries({
          dataPattern: { pattern: 'ProgressiveX', regularProgressiveStep: false },
          yAxis: lightingYAxis
        }).setStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorRGBA(...lineColor) }) }))
          .setFillStyle(new SolidFill({ color: ColorRGBA(...lineColor, 100) }))
          .setEffect(false)
          .setMouseInteractions(false)
      } else {
        lightingSeries[key] = setpointChart.addAreaRangeSeries({
          dataPattern: { pattern: 'ProgressiveX', regularProgressiveStep: false },
          yAxis: lightingYAxis
        }).setHighStrokeStyle(new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorRGBA(...lineColor) }) }))
          .setHighFillStyle(new SolidFill({ color: ColorRGBA(...lineColor, 100) }))
          .setLowFillStyle(emptyFill)
          .setLowStrokeStyle(emptyLine)
          .setEffect(false)
          .setMouseInteractions(false)
      }
      spectrumIndex++
    }

    //the 4 lighting series



    chartRef.current = {
      setpointChart,
      setpointChartDateAxis,
      dataSeries: {},
      selectedTimelineItem: null,
      setpointChartTimeTicks,
      lastSetpointChartInterval,
      lastSetpointChartTickRange,
      activeYAxes: {},
      setpointTypes: {},
      lightingYAxis,
      lightingSeries,
      tempSetpointInfo: [],
      tempLightingSetpointInfo: [],
      tempLightingSpectrumRatios: undefined
    }

    return () => {
      setpointChart.dispose()
      setpointChartTimeTicks.filter(tick => {
        tick.dispose()
        return false
      })
      lastSetpointChartTickRange = { start: 0, end: 0 }
      chartRef.current = undefined
    }
  }, [setpointChartTheme, setpointChartContainerRef])


  React.useEffect(() => {
    if (!chartRef.current)
      return

    chartRef.current.selectedTimelineItem = selectedTimelineItem
    const setpointChartVisibleRange = chartRef.current.setpointChartDateAxis.getInterval()
    updateSetpointChartAxisTicks(setpointChartVisibleRange.start, setpointChartVisibleRange.end)

    updateSetpointChart()

  }, [chartRef])


  React.useEffect(() => {
    if (!chartRef.current) 
        return
    chartRef.current.selectedTimelineItem = selectedTimelineItem
  }, [selectedTimelineItem])



  const onTimelineItemSelectionChanged = React.useCallback((value) => {
    let foundTimelineItem = timelineItems.find(c => value === c.id)
    if (foundTimelineItem !== undefined) {
      timelineItemSelected(foundTimelineItem)
    }
  })



  
  const [setpointLegendExpandedState, SetSetpointLegendExpandedState] = React.useState(false)
  const [setpointLegendRef, { width: setpointLegendWidth }] = useMeasure()

  const [setpointLegendExpandButtonRef, { width: setpointLegendExpandButtonWidth }] = useMeasure()

  let setpointLegendProps = { style: { height: setpointChartAreaHeight } }
  if (isMobile) {
    setpointLegendProps.style.position = "absolute"
    setpointLegendProps.style.left = 0
    setpointLegendProps.style.top = 0
    setpointLegendProps.style.bottom = 0

    setpointLegendProps.style.marginRight = setpointLegendExpandedState ? 5 : 0
    setpointLegendProps.style.width = setpointLegendExpandedState ? setpointLegendWidth : 0
  }
  let setpointChartProps = {}
  if (pointerOverSetpoint !== undefined || selectedSetpoint !== undefined || (isTouchOverSetpointChart && numberOfPointersDownOnSetpointCanvas <= 1)) {
    setpointChartProps.style = {
      pointerEvents: "none"
    }
  }



  return (
    <>
      <div className="ControlBar_Horizontal">
        <div className="ControlBar_Horizontal-Left ControlBar_Horizontal-Overflow">
          <DropDownInput 
              uid="timeline_item_select" 
              prefix="Timeline Item: "
              options={timelineItemSelectionOptions} 
              value={(selectedTimelineItem !== undefined ? (selectedTimelineItem.id) : "")}
              onSelectionChange={(value) => {
                onTimelineItemSelectionChanged(value)
              }}/>

        </div>
        <div className="ControlBar_Horizontal-Right">
          {(isEditingRecipe && (setpointTypeToggles[activeChartZone].setpointTypes[setpointTypeToggles[activeChartZone].selectedSetpointType] !== undefined || activeChartZone === "lighting")) && 
            <div className="Button Button-Neutral Button-Medium" 
              onPointerDown={addSetpointButtonPointerDown}
              onPointerMove={addSetpointButtonPointerMove}
              onPointerUp={addSetpointButtonPointerUp}
              style={{touchAction:"none"}}
              ref={addSetpointButtonRef}>
              <div className="noselect">
                Add Setpoint
              </div>
            </div>
          }
          
          <div style={{display: "flex", margin: "0 10px", gap:8}}>
            <div className="Button Button-Neutral Button-Small">
              <div>
                <BsZoomOut style={{width:18, height:18}}/>
              </div>
            </div>
            <div className="FlexContent FlexContent-Center">
              <div className="Text-Alt14">100%</div>
            </div>
            <div className="Button Button-Neutral Button-Small">
              <div>
                <BsZoomIn style={{width:18, height:18}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Recipe-ZoneManager"> 
        <div id="Recipe-ZoneManager-Zones">

          <div className="GrowZoneRecipeManager-SetpointLegend-Container" {...setpointLegendProps}>
            <div className="GrowZoneRecipeManager-SetpointLegend" ref={setpointLegendRef}>
              <div className="GrowZoneRecipeManager-SetpointLegend-ContentWrapper">
                <div className="GrowZoneRecipeManager-SetpointLegend-Header">
                  <Button
                    status="Primary-Toggle"
                    state={activeChartZone === "air"}
                    contentPadding={"8px 26px 8px 26px"}
                    content={<AirSetpoint fill={activeChartZone === "air" ? "#2E72D2" : "#9CA6B4"} />}
                    onClick={() => { SetActiveChartZone("air") }} />
                  <Button
                    status="Primary-Toggle"
                    state={activeChartZone === "root"}
                    contentPadding={"4px 26px 4px 26px"}
                    content={<WaterSetpoint fill={activeChartZone === "root" ? "#2E72D2" : "#9CA6B4"} />}
                    onClick={() => { SetActiveChartZone("root") }} />
                  <Button
                    status="Primary-Toggle"
                    state={activeChartZone === "lighting"}
                    contentPadding={"4px 26px 4px 26px"}
                    content={<LightingSetpoint fill={activeChartZone === "lighting" ? "#2E72D2" : "#9CA6B4"} />}
                    onClick={() => { SetActiveChartZone("lighting") }} />
                  {isMobile && <>
                    <Button status="Neutral" content={<Close />} onClick={() => { SetSetpointLegendExpandedState(!setpointLegendExpandedState) }} />
                  </>}
                </div>
                <div className="GrowZoneRecipeManager-SetpointLegend-Content">
                  {activeChartZone === "air" && <>
                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggles">
                      <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggles-Content">
                        {Object.entries(setpointTypeToggles.air.setpointTypes).map(([setpointKey, setpointType]) => {
                          const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == setpointType.identifier)
                          return (
                            <div key={setpointKey} className={"GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Container" + (setpointTypeToggles.air.selectedSetpointType == setpointKey ? " GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Active" : "")}>
                              <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle"
                                onClick={() => { if (setpointType.active) { toggleDataTypeSelected("air", setpointKey) } }}>
                                <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle">
                                  <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier">
                                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Indicator" style={{ backgroundColor: setpointType.color }} />
                                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Label noselect">
                                      {setpointType.label}
                                    </div>
                                    {(!isEditingRecipe && setpointType.active) && <>
                                      <Pill content={"Auto"} />
                                    </>}
                                  </div>
                                  <Switch state={setpointType.active} onSwitch={(state) => { toggleDataTypeActive("air", setpointKey) }} disabled={!isEditingRecipe} />
                                </div>
                                {(isEditingRecipe && setpointTypeToggles.air.selectedSetpointType == setpointKey) && <>
                                  Selected
                                </>}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </>}


                  {activeChartZone === "root" && <>
                    
                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggles">
                      <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggles-Content">
                        {Object.entries(setpointTypeToggles.root.setpointTypes).map(([setpointKey, setpointType]) => {
                          const setpointTypeInfo = recipeSetpointTypes.find((t) => t.name == setpointType.identifier)
                          return (
                            <div key={setpointKey} className={"GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Container" + (setpointTypeToggles.root.selectedSetpointType == setpointKey ? " GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Active" : "")}>
                              <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle"
                                onClick={() => { if (setpointType.active) { toggleDataTypeSelected("root", setpointKey) } }}>
                                <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle">
                                  <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier">
                                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Indicator" style={{ backgroundColor: setpointType.color }} />
                                    <div className="GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Label noselect">
                                      {setpointType.label}
                                    </div>
                                  </div>
                                  <Switch state={setpointType.active} onSwitch={(state) => { toggleDataTypeActive("root", setpointKey) }} disabled={!isEditingRecipe} />
                                </div>
                                {(isEditingRecipe && setpointTypeToggles.root.selectedSetpointType == setpointKey) && <>
                                  Selected
                                </>}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </>}




                  {activeChartZone === "lighting" && <>
                    <div className="GrowZoneRecipeManager-SetpointLegend-LightingZone-Content">
                      
                      <div className="GrowZoneRecipeManager-SetpointLegend-LightingZoneCompositionControl">
                        <div className="GrowZoneRecipeManager-SetpointLegend-LightingZoneCompositionControl-Items">
                          {Object.entries(setpointTypeToggles["lighting"].setpointTypes).map(([setpointKey, setpointType]) => {


                            let ratio = (Math.round(0 * 100) / 100).toFixed(1)
                            if (lightingSpectrumRatios[setpointType.identifier] !== undefined) {
                              ratio = lightingSpectrumRatios[setpointType.identifier]
                            }


                            const ratioChanged = (newRatio) => {
                              let newSpectrumRatios = { ...lightingSpectrumRatios }

                              //Calculate all other spectrums
                              let desiredAmountOfChange = newRatio - ratio

                              let availableSpectrums = []
                              let availableRatioToTake = 0
                              let availableRatioToGive = 0
                              for (const [currentSetpointKey, currentSetpointType] of Object.entries(setpointTypeToggles.lighting.setpointTypes)) {
                                if (currentSetpointKey !== setpointKey) {
                                  if (!currentSetpointType.locked) {
                                    availableSpectrums.push(currentSetpointKey)
                                    availableRatioToTake += lightingSpectrumRatios[currentSetpointType.identifier]
                                    availableRatioToGive += 100 - lightingSpectrumRatios[currentSetpointType.identifier]
                                  }
                                  //newSpectrumRatios[currentSetpointKey] = newRatio
                                }
                              }


                              if (ratio + desiredAmountOfChange < 0) {
                                desiredAmountOfChange = -ratio
                              }
                              if (desiredAmountOfChange === 0 || (desiredAmountOfChange > 0 && availableRatioToTake <= 0) || (desiredAmountOfChange < 0 && availableRatioToGive <= 0)) {
                                return ratio
                              }

                              for (const currentSetpointKey of availableSpectrums) {
                                const currentSetpointType = setpointTypeToggles.lighting.setpointTypes[currentSetpointKey]
                                if (desiredAmountOfChange >= 0) {
                                  const currentRatio = (100 / availableRatioToTake) * lightingSpectrumRatios[currentSetpointType.identifier]
                                  const amountOfChange = (currentRatio / 100) * -desiredAmountOfChange
                                  newSpectrumRatios[currentSetpointType.identifier] += amountOfChange
                                } else {
                                  let currentRatio = 100 / availableSpectrums.length
                                  if (availableRatioToTake !== 0) {
                                    currentRatio = (100 / availableRatioToTake) * lightingSpectrumRatios[currentSetpointType.identifier]
                                  }


                                  const amountOfChange = (currentRatio / 100) * -desiredAmountOfChange
                                  newSpectrumRatios[currentSetpointType.identifier] += amountOfChange
                                }
                                //newSpectrumRatios[currentSetpointType.identifier] = (Math.round(newSpectrumRatios[currentSetpointType.identifier] * 100) / 100).toFixed(1)
                              }

                              newSpectrumRatios[setpointType.identifier] += desiredAmountOfChange

                              SetLightingSpectrumRatios(newSpectrumRatios)
                              chartRef.current.tempLightingSpectrumRatios = newSpectrumRatios

                              return ratio + desiredAmountOfChange
                            }

                            const lockChanged = (state) => {
                              setpointType.locked = state
                            }

                            const valueChangedComplete = () => {
                              if (chartRef.current.tempLightingSpectrumRatios !== undefined) {
                                dispatch(pushRecipeChange({
                                  recipe: {
                                    ...recipe,
                                    timeline_items: [...recipe.timeline_items.map((timelineItem) => {
                                      if (timelineItem.id != selectedTimelineItem.id) {
                                        return timelineItem
                                      }
                                      return {
                                        ...timelineItem,
                                        item: {
                                          ...timelineItem.item,
                                          lighting_intensity_setpoint_zones: [...selectedTimelineItem.item.lighting_intensity_setpoint_zones.map((lisz) => {
                                            if (lisz.zone_index === 0) {
                                              return { ...lisz, lighting_spectrum_ratios: chartRef.current.tempLightingSpectrumRatios }
                                            }
                                            return { ...lisz }
                                          })]
                                        }
                                      }
                                    })]
                                  }
                                }))


                                delete chartRef.current.tempLightingSpectrumRatios
                              }
                            }

                            return (
                              <SliderInput
                                key={setpointType.identifier}
                                value={ratio}
                                locked={setpointType.locked}
                                fontSize={14}
                                dialDisplay={setpointType.shortKey}
                                color={setpointType.color}
                                disabled={!isEditingRecipe}
                                onValueChanged={ratioChanged}
                                onValueChangedComplete={valueChangedComplete}
                                onLockChanged={lockChanged} />
                            )

                          })}
                        </div>

                        <div className="Recipe-ZoneManager-LightingZoneCompositionDisplay-Totals">
                          <div className="FlexContent-H-5">
                            <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 5, color: "#4D5563" }}>Max PPFD</div>
                            <div style={{ fontSize: 14, fontWeight: 400 }}>{maxPPFD}umols</div>
                          </div>
                          <div className="FlexContent-H-5">
                            <div style={{ fontSize: 14, fontWeight: 500, color: "#4D5563" }}>CLI</div>
                            <div style={{ fontSize: 14, fontWeight: 400 }}>
                              {selectedTimelineItem !== undefined && selectedTimelineItem.type === "nursery_cycle" && <>
                                {CLI[0] !== undefined && <>{CLI[0]}</>}
                              </>}
                              {selectedTimelineItem !== undefined && selectedTimelineItem.type === "grow_zone_cycle" && <>
                                {CLI[0] !== undefined && <>{CLI[0]}</>}
                              </>}
                              mols
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </div>

          {isMobile &&
            <div className="GrowZoneRecipeManager-SetpointLegend-ExpandToggleContainer"
              style={{ width: !setpointLegendExpandedState ? setpointLegendExpandButtonWidth : 0 }}
              onClick={() => SetSetpointLegendExpandedState(!setpointLegendExpandedState)}>
              <div className="GrowZoneRecipeManager-SetpointLegend-ExpandToggle" ref={setpointLegendExpandButtonRef}>
                <div className="GrowZoneRecipeManager-SetpointLegend-ExpandToggle-Content">
                  <div className="GrowZoneRecipeManager-SetpointLegend-ExpandToggle-Button">
                    <ExpandContentAlt/>
                  </div>
                </div>
              </div>
            </div>
          }


          <div id="Recipe-ZoneManager-SetpointChartWrapper"
            className="noselect"
            onPointerMove={chartingAreaPointerMove}
            onPointerDown={chartingAreaPointerDown}
            onPointerUp={chartingAreaPointerUp}
            onPointerLeave={chartingAreaPointerLeave}
            onContextMenu={(e) => {e.preventDefault(); return false;}}
            ref={setpointChartingAreaRef}>
            <div id="Recipe-ZoneManager-SetpointChartContainer"
              className="noselect">
              <div ref={setpointChartContainerRef} id="Recipe-ZoneManager-SetpointChart" {...setpointChartProps}></div>
              <div className="GrowZoneRecipeManager-SetpointChart-OverlayBoard">
                <div className="GrowZoneRecipeManager-SetpointChart-OverlayBoard-Content">
                  {(() => {
                    let maxPPFDYPosition = maxPPFD / maxLightingIntensityAxisLimit
                    let maxSetpointPosition = lightingMaxIntensitySetpoint / maxLightingIntensityAxisLimit
                    
                    return (<>
                      {(isLightingInMaxPPFDConflict || activeChartZone === "lighting") && 
                        <div className="GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual"
                          style={{
                            left: yAxisConstantWidth, 
                            right: chartRightPadding, 
                            bottom: timeAxisConstantHeight + maxPPFDYPosition * (setpointChartAreaHeight - timeAxisConstantHeight)}}>
                              <div className="GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialStart"></div>
                              <div className="GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-Line"></div>
                              <div className="GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialEnd"></div>
                            </div>
                      }
                      {isLightingInMaxPPFDConflict && <>
                        <div className="GrowZoneRecipeManager-SetpointChart-LightingConflictOverlay"
                          style={{
                            top: (1 - maxSetpointPosition) * (setpointChartAreaHeight - timeAxisConstantHeight), 
                            left: yAxisConstantWidth, 
                            right: chartRightPadding, 
                            bottom: timeAxisConstantHeight + maxPPFDYPosition * (setpointChartAreaHeight - timeAxisConstantHeight)}}></div>
                        
                      </>}
                    </>)
                  })()}
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div id="Recipe-ZoneManager-CycleTimeline">
          
        </div>
        <div id="Recipe-ZoneManager-SetpointChart_TooltipBoard" ref={tooltipBoardAreaRef}>
            {pointerOverSetpointChartDate !== undefined && drawTooltip()}
        </div>
      </div>
    </>  
  )
} 

export default RecipeZonesPage