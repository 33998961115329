import Button from './Button';
import './ButtonGroup.scss';

import * as React from 'react';



const ButtonGroup = ({buttons, activeButton, onButtonClicked}) => {
  
  const buttonClicked = React.useCallback((buttonKey) => {
    if (onButtonClicked !== undefined)  {
      onButtonClicked(buttonKey)
    }
  })
  
  return (
      <>
        <div className={"ButtonGroup"}>
          <div className="ButtonGroup-Content">
            {Object.entries(buttons).map(([buttonKey, buttonInfo]) => {

              return (
                <Button 
                  key={buttonKey}
                  size={"Status"}
                  status={(activeButton === buttonKey ? "Focused" : "Neutral")}
                  onClick={() => {buttonClicked(buttonKey)}}
                  content={buttonInfo.label}
                />
              )
            })}
          </div>
        </div>
      </>
  )
} 

ButtonGroup.defaultProps = {
  buttons: {},
}

export default ButtonGroup