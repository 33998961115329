import { createSlice } from '@reduxjs/toolkit'

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    rows:  [
      { id: 1, icon: null, name: 'Some Item 1', category: "Loose Leaf", subCategory: "Lettuce", status: "No Inventory", statusMode:"Critical", unit: "plant" },
      { id: 2, icon: null, name: 'Some Item 2', category: "Loose Leaf", subCategory: "Lettuce", status: "Available", statusMode:"Success-Sub", unit: "kg", pinned: true },
      { id: 3, icon: null, name: 'Some Item 3', category: "Loose Leaf", subCategory: "Lettuce", status: "Available", statusMode:"Success-Sub", unit: "-" },
      { id: 4, icon: null, name: 'Some Item 4', category: "Loose Leaf", subCategory: "Lettuce", status: "Running Low", statusMode:"Warning", unit: "g" },
      { id: 5, icon: null, name: 'Some Item 5', category: "Consumables", subCategory: "Seeds", status: "Not Visible", statusMode:"Neutral", unit: "plant", pinned: true},
      { id: 6, icon: null, name: 'Apples', category: "Product", subCategory: "Fruit", status: "Available", statusMode:"Success-Sub", unit: "plant" },
      { id: 7, icon: null, name: 'Amazing Lettuce V2', category: "Recipe", subCategory: "Lettuce", status: "Available", statusMode:"Success-Sub", unit: "g" },
      { id: 8, icon: null, name: 'Some Item 8', category: "Product", subCategory: "Lettuce", status: "Available", statusMode:"Success-Sub", unit: "kg" },
      { id: 9, icon: null, name: 'Some Item 9', category: "Spare Part", subCategory: "Lettuce", status: "Available", statusMode:"Success-Sub", unit: "kg" },
    ]
  },
  reducers: {
    

    rowUpdate: (state, action) => {
      let hasChanged = false
      let newRows = { ...state, rows: state.rows.map((row, index) => {
        if (row.id !== action.payload.id) {
          return row
        }
    
        if (row[action.payload.prop] === undefined || row[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...row,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newRows
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { rowUpdate} = productsSlice.actions

export default productsSlice.reducer