import { CloseCell, OpenCell } from '../assets/icons/Icons';
import './ToggleCellButton.scss';
import React from 'react'

const ToggleCellButton = React.forwardRef(({content, state, status, size, onClick, hasBorder, disabled}, ref) => {
  let buttonProps = {style: {}}
  const buttonClicked = (e) => {
    if (!disabled && onClick !== undefined && onClick !== null)  {
      onClick(e)
    }
  }
  return (
    <div 
      ref={ref} 
      className={"ToggleCellButton ToggleCellButton-" + status + " ToggleCellButton-" + size + (hasBorder ? " ToggleCellButton-WithBorder" : "") + (disabled ? " ToggleCellButton-Disabled" : "")} >
      <div onClick={buttonClicked}>
        <span className="noselect">
          {content}
        </span>
        {state && 
          <CloseCell/>
        }
        {!state && 
          <OpenCell/>
        }
        </div>
    </div>
  )
})

ToggleCellButton.defaultProps = {
  content: "",
  status: "Primary",
  size: "Medium",
  hasBorder: false,
  disabled: false,
  state: false
}


export default ToggleCellButton