import './Checkbox.scss';

import * as React from 'react';



const Checkbox = ({state, canToggleOff, onChange, style, size, borderSize, disabled, label}) => {
  const [currentState, SetCurrentState] = React.useState(false)
  React.useLayoutEffect(() => {
    SetCurrentState(state)
  }, [state])
  
 
  
  const toggleClicked = React.useCallback(() => {
    if (disabled)
      return
  
    if (currentState && canToggleOff || !currentState)  {
      if (onChange !== undefined)  {
        onChange(!currentState)
      }else {
        SetCurrentState(!currentState)
      }
    }
  })
  
  let borderProps = {style:{
    height: size - (borderSize * 2),
    width: size - (borderSize * 2)
  }}

  
  let contentProps = {style:{
    height: size - (borderSize * 4),
    width: size - (borderSize * 4),
    margin: borderSize
  }}


  if (style === "radio")  {
    borderProps.style.borderRadius = size
    contentProps.style.borderRadius = size - (borderSize * 2)
  }else if (style === "check")  {
    borderProps.style.borderRadius = size / 8
    contentProps.style.borderRadius = (size / 8) - (borderSize * 2)   
  }

  return (
      <>
        <div className={"Checkbox" +  (style === "radio" ? " Checkbox-Radio" : " ") + (style === "check" ? " Checkbox-Checkbox" : "") + (currentState ? " Checkbox-On" : " Checkbox-Off") + (disabled ? " Checkbox-Disabled" : "")} onClick={toggleClicked}>
          <div className="Checkbox-Border" {...borderProps}>
            <div className="Checkbox-Content" {...contentProps}>

            </div>
          </div>
          {label && 
            <div className="CheckBox-Label">{label}</div>
          }
        </div>
      </>
  )
} 

Checkbox.defaultProps = {
  style: "radio",
  size: 20,
  borderSize: 2,
  disabled: false,
  label: false,
  canToggleOff: true,
}

export default Checkbox