import './Multibutton.scss';

import * as React from 'react';



const Multibutton = ({buttons, activeButton, onButtonClicked}) => {
  
  const buttonClicked = React.useCallback((buttonKey) => {
    if (onButtonClicked !== undefined)  {
      onButtonClicked(buttonKey)
    }
  })
  
  return (
      <>
        <div className={"Multibutton"}>
          <div className="Multibutton-Content">
            {Object.entries(buttons).map(([buttonKey, buttonInfo]) => {

              return (
                <div key={buttonInfo.key} 
                  className="Multibutton-Button-Container"
                  style={{width: 100 / buttons.length + "%"}}
                  onClick={() => {onButtonClicked(buttonInfo.key)}}>
                  <div className={"Multibutton-Button" + (buttonInfo.disabled === true ? " Multibutton-Button-Disabled" : "") + (activeButton === buttonInfo.key ? " Multibutton-Button-Selected" : "")}>
                    <div className="Multibutton-Button-Content">
                      {buttonInfo.content}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
  )
} 

Multibutton.defaultProps = {
  buttons: {},
}

export default Multibutton