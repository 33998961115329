import './GrowRacksPage.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../../redux/entities/Facilities';
import { activateTemporaryEditMode, addRackToGroup, createNewGroup, getLiveDataItem, getUniqueConfigurationByName, getBladeConfigurationMap, getBladeByServiceId, getBladeLiveDataById, getBladeStatusById, hideRackStatusContent, removeGroup, selectAllBladeConfigurationMaps, selectAllBlades, setBladeRuntimeProperty, showRackStatusContent, selectAllBladeGroups, selectAllBladeZones, getBladeGroupById, getAllBladeGroupsByFacilityId, MaintainBladeLiveData, MaintainBladeStatus, MaintainBladeUniqueConfigurationMaps } from '../../../redux/entities/service/Blade';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { RoundToNearest, convertToBool, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, Pause, Play, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import AddBladeWalkthrough from './AddBladeWalkthrough';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import BladeLightsManager from './BladeLightsManager';
import BladeIssuesPopup from './BladeIssuesPopup';






const GrowRacksPage = ({selectedFacility, setRightSideContent}) => {
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  




  const dispatch = useDispatch()

  const activeFacility = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  const allBlades = useSelector(state => state.blade.blade)
  const allGroups = useSelector(selectAllBladeGroups)
  //const allBladeZones = useSelector(state => state.blade.zones)

  const [blades, SetBlades] = React.useState([])

  

  const [bladeGroups, SetBladeGroups] = React.useState([])
  
  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  React.useEffect(() => {
    if (loadedInitialBladeGroups.indexOf(selectedFacility) === -1 && loadingGroupsStatus !== "pending") {
      dispatch(getAllBladeGroupsByFacilityId({facilityIds: [selectedFacility]}))
    }
  }, [loadedInitialBladeGroups, loadingGroupsStatus])


  
  //console.log(allGroups)
  React.useEffect(() => {
    SetBlades(allBlades.filter((vRG) => activeFacility !== undefined && activeFacility.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility, allBlades, allGroups])
  

  React.useEffect(() => {
    let activeGroupIds = []
    for (let blade of blades) {
      if (blade.group_id !== undefined && blade.group_id !== null)  {
        if (activeGroupIds.indexOf(blade.group_id) === -1)  {
          activeGroupIds.push(blade.group_id)
        }
      }
    }

    let activeGroups = []
    let groupsToLoad = []
    for (let groupId of activeGroupIds) {
      let foundGroup = allGroups.find((g) => g.id === groupId)
      if (foundGroup !== undefined) {
        activeGroups.push(foundGroup)
      }else {
        groupsToLoad.push(groupId)
      }
    }

    if (groupsToLoad.length > 0)  {
      dispatch(getBladeGroupById({bladeGroupIds: groupsToLoad}))
    }

    SetBladeGroups(activeGroups)

  }, [blades, allGroups])

  const bladeConfigurationMaps = useSelector(selectAllBladeConfigurationMaps)


  const updateHeader = React.useCallback(() =>  {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {blades.length > 0 && 
          <Button content="Add Blade" status="Primary" onClick={addNewRack}/>  
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [bladeGroups])

  
  



  React.useEffect(() => {
    //We need to make sure we have all the racks configuration maps for components/io
    let mapsToLoad = {}
    for (let blade of blades) {
      if (blade.loadedFromDatabase)  {
        let foundBladeConfigurationMap = bladeConfigurationMaps.find((cM) => cM.id === blade.configuration_id)
        if (foundBladeConfigurationMap === undefined)  {
          mapsToLoad[blade.configuration_id] = {"component_map": null}
        }
      }
    }

    if (Object.entries(mapsToLoad).length > 0)  {
      dispatch(getBladeConfigurationMap({maps: mapsToLoad}))
    }
    //console.log(blades)
  }, [blades, bladeConfigurationMaps])



  
  

  
  
  const [addingNewRack, SetAddingNewRack] = React.useState(false)
  const addNewRack = React.useCallback(() => {
    SetAddingNewRack(true)
  })

  

  const addBladeWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewRack(false)  
  })


  const [managingLightsForBladeId, SetManagingLightsForBladeId] = React.useState(null)
  const manageLightsClicked = (blade) => {
    SetManagingLightsForBladeId(blade.id)
  }
  const closeManagingLights = () => {
    SetManagingLightsForBladeId(null)
  }

  const [viewingIssuesForBlade, SetViewingIssuesForBlade] = React.useState(null)
  const issuesClicked = (blade) => {
    SetViewingIssuesForBlade(blade.id)
  }
  const closeViewBladeIssues = () => {
    SetViewingIssuesForBlade(null)
  }




  return (<>
    <MaintainBladeStatus bladeIds={allBlades.map((b) => {return b.id})}/>
    <MaintainBladeUniqueConfigurationMaps bladeIds={allBlades.map((b) => {return b.id})}/>
    <MaintainBladeLiveData bladeIds={allBlades.map((b) => {return b.id})}/>
    <div id="GrowRacksStatus">
      
        {blades.length === 0 && 
          <div className="GrowRacksStatus-NoGroupsContent">
            <span>No active blades</span>
            <Button content={"Add your first blade"} onClick={addNewRack}/>
          </div>
        }
        {bladeGroups.length > 0 &&
          <div className="GrowRacksStatus-VerticalRackGroups">
            {bladeGroups.map((bladeGroup) => {


              let otherGroupProps = {}
              if (bladeGroups.length === 1) {
                otherGroupProps.expandedState = true
              }
              return (

                <RowGroup
                  key={bladeGroup.id}
                  title={bladeGroup.display_name}
                  hasSettings={true} 
                  isExpandable={true}
                  expandCellContent={"Show " + Object.values(bladeGroup.blades).length + " Blades"}
                  collapseCellContent={"Hide Blades"}
                  settingsOptions={[
                    {key: "rename", label: "Rename Group"},
                    {key: "add", label: "Add Rack"},
                    {key: "delete", label: "Delete", status: "Critical"},
                  ]}
                  hasColumnHeaders={false}
                  {...otherGroupProps}>
                
                  {bladeGroup.blades.map((bladeUID) => {
                    let blade = blades.find((b) => b.uid === bladeUID)
                    if (blade !== undefined)  {

                      const isRackOnline = (blade.control_device !== null && blade.control_device.connected)
                      const isRackPaused = false

                      const isSerialNumberValidated = blade.linked
                      const isRackStatusInfoVisible = (blade.showStatusContent !== undefined && blade.showStatusContent)


                      let subtitle = <></>
                      if (blade.dual_side_type !== undefined && blade.dual_side_type !== null)  {
                        switch (blade.dual_side_type)  {
                          case "vine_crop":
                            subtitle = <>Dual Vine Crop</>
                            break
                            default:
                            subtitle = <></>
                            break
                        }
                      }else {
                        let leftSide = <>Nothing</>
                        let rightSide = <>Nothing</>
                        if (blade.environment_side_type !== undefined && blade.environment_side_type !== null)  {
                          switch (blade.environment_side_type)  {
                            case "standard_nursery":
                              leftSide = <>Nursery</>
                              break
                            case "standard_grow_out_env":
                              leftSide = <>Environment</>
                              break

                            default:
                              leftSide = <>Nothing</>
                              break
                          }
                        }
                        if (blade.grow_side_type !== undefined && blade.grow_side_type !== null)  {
                          switch (blade.grow_side_type)  {
                            case "standard_grow_out":
                              rightSide = <>Grow Boards</>
                              break
                            case "vine_crop":
                              rightSide = <>Vine Crop</>
                              break
                            default:
                              rightSide = <>Nothing</>
                              break
                          }
                        }

                        subtitle = <>{<span className="GrowRacksStatus-VerticalRackGroup-Rack-Side">{leftSide}</span>}<span style={{fontStyle:"normal"}}>|</span><span className="GrowRacksStatus-VerticalRackGroup-Rack-Side">{rightSide}</span></>
                      }
                      //console.log(blade)

 
                      let additionalFunctions = [
                        {key: "manage_lights", label: "Manage Lights", callback: () => {manageLightsClicked(blade)}},
                        {key: "rename", label: "Rename Rack"},
                        {key: "move", label: "Move Rack"},
                        {key: "change_sides", label: "Change Sides"},
                      ]

                      if (blade.runtime_information["primary_dose_locked_out"] !== undefined && convertToBool(blade.runtime_information["primary_dose_locked_out"])) {
                        additionalFunctions.push(...[
                          {key: "restore_primary_lockout", label: "Allow Primary Dosing", status: "Critical", callback: () => {
                            dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"primary_dose_locked_out": 0}}))
                          }},
                        ])  
                      }

                      additionalFunctions.push(...[
                        {key: "delete", label: "Delete", status: "Critical"},
                      ])
                      /*if (isMobile) {
                        additionalFunctions.splice(2, 0, 
                          {key: "pause_rack", label: "Pause Rack"}  
                        );
                      }*/

                      let secondaryHeaderContent = null
                      if (!isMobile)  {
                        secondaryHeaderContent = [
                          {key: "pause_state", content: 
                            <div className="FlexContent-H-10">
                              {isRackPaused && 
                                <div className="FlexContent-H-20 FlexContent-Center">
                                  <span className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-State">Paused 16:34</span>

                                  <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Add Time</span></div>
                                </div>
                              }
                              {/*<div className="FlexContent-H-10">
                                {isRackPaused && <>
                                  <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
                                </>}
                                {!isRackPaused &&
                                  <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
                                }
                              </div>*/}
                            </div>
                          },
                          {key: "rssi", content: <RSSI/>}
                        ]
                      }else {
                        secondaryHeaderContent = <><RSSI/></>
                      }

                      let extendedHeaderContent = null
                      if (isMobile && isRackPaused) {
                        extendedHeaderContent = {content: <>
                          <div className="FlexContent-H-10">
                            <div className="FlexContent-H-10 FlexContent-Center">
                              <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt/></div>
                            </div>
                            <div className="FlexContent-H-10">
                              <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
                            </div>
                          </div>
                        </>}
                      }else {

                      }


                      let bladeIssues = []
                      for (let issue of Object.values(blade.issues))  {
                        if (issue.active) {
                          bladeIssues.push(issue)
                        }
                      }

                      return (
                        <RowGroupRow 
                          key={blade.id}
                          title={blade.display_name}
                          hasFlexCell={true}
                          subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{subtitle}</div>}
                          showStatusIndicator={true}
                          status={isRackOnline ? "Connected" : "Not Connected"}
                          statusColor={isRackOnline ? "#4DBE3B" : "#f00"}
                          hasIssues={true}
                          numberOfIssues={Object.entries(bladeIssues).length} 
                          issuesClicked={() => {issuesClicked(blade)}}
                          customRowPadding={0}
                          isExpandable={true}
                          hasAdditionalFunctions={true}
                          secondaryContent={secondaryHeaderContent}
                          extendedHeaderContent={extendedHeaderContent}
                          additionalFunctions={additionalFunctions}
                          content={<>
                            {(() => {


                                  let rackStatusColor = (blade.control_device !== null && blade.control_device.connected) ? variables.online : variables.offline
                                  var inServiceMode = parseBool(blade.runtime_information_requested["service_mode"] ?? blade.runtime_information["service_mode"] ?? false)

                                  var compressorSpeedInOverride = parseBool(blade.runtime_information["compressor_speed_override"] ?? false)
                                  var compressorSpeedOverrideValue = blade.runtime_information_requested["compressor_speed"] ?? blade.runtime_information["compressor_speed"] ?? 0

                                  var supplyFanSpeedInOverride = parseBool(blade.runtime_information_requested["supply_fan_speed_override"] ?? blade.runtime_information["supply_fan_speed_override"] ?? false)
                                  var supplyFanSpeedOverrideValue = blade.runtime_information_requested["supply_fan_speed"] ?? blade.runtime_information["supply_fan_speed"] ?? 0

                                  var condenserFanSpeedInOverride = parseBool(blade.runtime_information_requested["condenser_fan_speed_override"] ?? blade.runtime_information["condenser_fan_speed_override"] ?? false)
                                  var condenserFanSpeedOverrideValue = blade.runtime_information_requested["condenser_fan_speed"] ?? blade.runtime_information["condenser_fan_speed"] ?? 0

                                  var bypassDamperPositionInOverride = parseBool(blade.runtime_information_requested["bypass_damper_position_override"] ?? blade.runtime_information["bypass_damper_position_override"] ?? false)
                                  var bypassDamperPositionOverrideValue = blade.runtime_information_requested["bypass_damper_position"] ?? blade.runtime_information["bypass_damper_position"] ?? 0

                                  var dehumidEEVPositionInOverride = parseBool(blade.runtime_information_requested["dehumid_eev_position_override"] ?? blade.runtime_information["dehumid_eev_position_override"] ?? false)
                                  var dehumidEEVPositionOverrideValue = blade.runtime_information_requested["dehumid_eev_position"] ?? blade.runtime_information["dehumid_eev_position"] ?? 0

                                  var precoolEEVPositionInOverride = parseBool(blade.runtime_information_requested["precool_eev_position_override"] ?? blade.runtime_information["precool_eev_position_override"] ?? false)
                                  var precoolEEVPositionOverrideValue = blade.runtime_information_requested["precool_eev_position"] ?? blade.runtime_information["precool_eev_position"] ?? 0

                                  var precoolPRVPositionInOverride = parseBool(blade.runtime_information_requested["precool_prv_position_override"] ?? blade.runtime_information["precool_prv_position_override"] ?? false)
                                  var precoolPRVPositionOverrideValue = blade.runtime_information_requested["precool_prv_position"] ?? blade.runtime_information["precool_prv_position"] ?? 0


                                  var primaryLocalRecirculationInOverride = parseBool(blade.runtime_information_requested["primary_local_recirculation_override"] ?? blade.runtime_information["primary_local_recirculation_override"] ?? false)
                                  
                                  var auxALocalRecirculationInOverride = parseBool(blade.runtime_information_requested["aux_a_local_recirculation_override"] ?? blade.runtime_information["aux_a_local_recirculation_override"] ?? false)
                                  var auxBLocalRecirculationInOverride = parseBool(blade.runtime_information_requested["aux_b_local_recirculation_override"] ?? blade.runtime_information["aux_b_local_recirculation_override"] ?? false)
                                  

                                  var allLightsOutputFactorInOverride = parseBool(blade.runtime_information_requested["all_lights_output_factor_override"] ?? blade.runtime_information["all_lights_output_factor_override"] ?? false)
                                  var allLightsOutputFactorOverrideValue = blade.runtime_information_requested["all_lights_output_factor"] ?? blade.runtime_information["all_lights_output_factor"] ?? 0


                                  var airTempSetpointInOverride = parseBool(blade.runtime_information_requested["manual_air_temp_setpoint_override"] ?? blade.runtime_information["manual_air_temp_setpoint_override"] ?? false)
                                  var airTempSetpointOverrideValue = blade.runtime_information_requested["manual_air_temp_setpoint"] ?? blade.runtime_information["manual_air_temp_setpoint"] ?? 0
                                  
                                  var airRHSetpointInOverride = parseBool(blade.runtime_information_requested["manual_air_rh_setpoint_override"] ?? blade.runtime_information["manual_air_rh_setpoint_override"] ?? false)
                                  var airRHSetpointOverrideValue = blade.runtime_information_requested["manual_air_rh_setpoint"] ?? blade.runtime_information["manual_air_rh_setpoint"] ?? 0

                                  var leafTempOffsetInOverride = parseBool(blade.runtime_information_requested["manual_leaf_temp_offset_override"] ?? blade.runtime_information["manual_leaf_temp_offset_override"] ?? false)
                                  var leafTempOffsetOverrideValue = blade.runtime_information_requested["manual_leaf_temp_offset"] ?? blade.runtime_information["manual_leaf_temp_offset"] ?? 0

                                  var isAuxAPumpingSystemActive = parseBool(getUniqueConfigurationByName(blade, "AuxALiquidSystemInstalled"));
                                  var isAuxBPumpingSystemActive = parseBool(getUniqueConfigurationByName(blade, "AuxBLiquidSystemInstalled"));


                                  let foundRackConfigurationMap = bladeConfigurationMaps.find((cM) => cM.id === blade.configuration_id)
                                  if (foundRackConfigurationMap !== undefined)  {
                                    
                                    

                                    let [leftDoorSafetySwitchComponent, [leftDoorSafetySwitchIdentifierInfo], [leftDoorSafetySwitchDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "LeftDoorSafetySwitch", ["state"])
                                    let [rightDoorSafetySwitchComponent, [rightDoorSafetySwitchIdentifierInfo], [rightDoorSafetySwitchDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "RightDoorSafetySwitch", ["state"])

                                    let [rackCurrentTranducerComponent, [rackCurrentIdentifierInfo, rackWattageIdentifierInfo, rackVoltageIdentifierInfo],  [rackCurrentDataItem, rackWattageDataItem, rackVoltageDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "RackCurrentTransducer", ["current", "w", "v"])

                                    
                                    let [boardTempAndRHComponent, 
                                      [boardTempIdentifierInfo, boardRHIdentifierInfo],  
                                      [boardTempDataItem, boardRHDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "BoardTempRHSensor", ["t", "rh"])

                                    
                                      

                                    let lightingComponent = foundRackConfigurationMap.component_map.components.find((c) => c.name === "LightingController")
                                    let lightingBlueIdentifierInfo = lightingComponent !== undefined ? lightingComponent.data_types.find((dT) => dT.identifier === "blue") : undefined
                                    let lightingBlueInfo = lightingBlueIdentifierInfo !== undefined && blade.liveData[lightingComponent.id] !== undefined && blade.liveData[lightingComponent.id][lightingBlueIdentifierInfo.identifier] !== undefined ? blade.liveData[lightingComponent.id][lightingBlueIdentifierInfo.identifier] : null
                                    
                                    let [canopyAirComponent, 
                                      [airTempIdentifierInfo, airRHIdentifierInfo, airCO2IdentifierInfo, airVPDIdentifierInfo, airDewpointIdentifierInfo, leafTempIdentifierInfo], 
                                      [airTempDataItem, airRHDataItem, airCO2DataItem, airVPDDataItem, airDewpointDataItem, leafTempDataItem]] =
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "CanopyAirSpace", ["at", "arh", "ac", "vpd", "dp", "lt"])

                                    let [airTemperaturesOneWireBusComponent, 
                                      [supplyAirTempIdentifierInfo, returnAirTempIdentifierInfo, airEnteringDehumidCoilTempIdentifierInfo, airLeavingDehumidCoilTempIdentifierInfo],  
                                      [supplyAirTempDataItem, returnAirTempDataItem, airEnteringDehumidCoilTempDataItem, airLeavingDehumidCoilTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["a-st", "a-rt", "a-edct", "a-ldct"])
                                    
                                    let [bypassDamperComponent, [bypassDamperPositionIdentifierInfo], [bypassDamperPositionDataItem]] = getLiveDataItem(blade, foundRackConfigurationMap.component_map, "BypassDamper", ["position"])
                                    let [compressorComponent, 
                                      [compressorSpeedIdentifierInfo, compressorRPMIdentifierInfo, compressorStateIdentifierInfo, compressorDischargeSuperheatIdentifierInfo], 
                                      [compressorSpeedDataItem, compressorRPMDataItem, compressorStateDataItem, compressorDischargeSuperheatDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "Compressor", ["control", "rpm", "state", "sh"])
                                    let compressorStateColor = compressorStateDataItem !== null ? (compressorStateDataItem.value ? variables.online : variables.offline) : variables.offline
                                    
                                    let [compressorSuctionPressureComponent, 
                                      [compressorSuctionPressureIdentifierInfo, evaporatorSuperheatIdentifierInfo], 
                                      [compressorSuctionPressureDataItem, evaporatorSuperheatDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "CompressorInletPressureTransducer", ["pressure", "sh"])
                                    let [condenserSubcoolPressureComponent, 
                                      [condenserSubcoolPressureIdentifierInfo, condenserSubcoolIdentifierInfo], 
                                      [condenserSubcoolPressureDataItem, condenserSubcoolDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "CondenserSubcoolPressureTransducer", ["pressure", "sc"])
                                    let [NHXSubcoolPressureComponent, 
                                      [NHXSubcoolPressureIdentifierInfo, NHXSubcoolIdentifierInfo],  
                                      [NHXSubcoolPressureDataItem, NHXSuperheatDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AccumulatorSubcoolPressureTransducer", ["pressure", "sh"])

                                    let [compressorDischargeTempComponent, [compressorDischargeTempIdentifierInfo],  [compressorDischargeTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "ACDischargeOWTB", ["t"])
                                    let [commonHighSideTempComponent, [commonHighSideIdentifierInfo],  [commonHighSideDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "ACCommonHighSideOWTB", ["t"])                    
                                    let [dehumidCoilComponent, [leavingDehumidCoilTempIdentifierInfo, dehumidCoilSuperheatIdentifierInfo],  [leavingDehumidCoilTempDataItem, dehumidCoilSuperheatDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "DehumidCoil", ["l-temp", "sh"])                    
                                    let [precoolCoilComponent, [leavingPrecoolCoilTempIdentifierInfo, precoolCoilSuperheatIdentifierInfo],  [leavingPrecoolCoilTempDataItem, precoolCoilSuperheatDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrecoolCoil", ["l-temp", "sh"])                    
                                                                      

                                      /*let [enteringNHXTempComponent, [enteringNHXTempIdentifierInfo],  [enteringNHXTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["ent-nhx"])                    
                                    let [suctionTempComponent, [suctionTempIdentifierInfo],  [suctionTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "OnewireTemperatureBus", ["suction"])*/               

                                    let [condenserFanComponent, [condenserFanSpeedIdentifierInfo, condenserFanRPMIdentifierInfo],  [condenserFanSpeedDataItem, condenserFanRPMDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "CondenserFan", ["control", "rpm"])
                                    let [supplyFanComponent, [supplyFanSpeedIdentifierInfo, supplyFanRPMIdentifierInfo],  [supplyFanSpeedDataItem, supplyFanRPMDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "SupplyFan", ["control", "rpm"])
                                    let [dehumidEEVComponent, [dehumidEEVIdentifierInfo],  [dehumidEEVDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "DehumidCoilEEV", ["output_signal"])
                                    let [precoolEEVComponent, [precoolEEVIdentifierInfo],  [precoolEEVDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrecoolCoilEEV", ["output_signal"])
                                      let [precoolPRVComponent, [precoolPRVIdentifierInfo],  [precoolPRVDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrecoolCoilPRV", ["output_signal"])
                                    
                                    let [condensateSubsystemComponent, 
                                      [condensateSubsystemPumpStateIdentifierInfo, condensateSubsystemFloatSwitchStateIdentifierInfo, condensateSubsystemTotalVolumeIdentifierInfo, condensateAverageEvapMetricIdentifierInfo, condensateAverageEvapImperialIdentifierInfo], 
                                      [condensateSubsystemPumpStateDataItem, condensateSubsystemFloatSwitchStateDataItem, condensateSubsystemTotalVolumeDataItem, condensateAverageEvapMetricDataItem, condensateAverageEvapImperialDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "CondensateSubsystem", ["p_state", "fs_state", "current_total_condensate_volume", "average_evap_metric", "average_evap_imperial"])

                                    let  [recirculationPump1Component, [recirculationPump1StateIdentifierInfo], [recirculationPump1StateDataItem]]
                                      = getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrimaryALiquidRecirculationPump", ["state"])
                                    let [recirculationPump2Component, [recirculationPump2StateIdentifierInfo], [recirculationPump2StateDataItem]]
                                        = getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrimaryBLiquidRecirculationPump", ["state"])
                                    
                                  

                                      
                
                                    let [primaryWaterReservoirComponent, 
                                      [primaryWaterLevelIdentifierInfo, primaryWaterVolumeIdentifierInfo, primaryWaterECIdentifierInfo, primaryWaterPHIdentifierInfo, primaryWaterDOIdentifierInfo, primaryWaterORPIdentifierInfo], 
                                      [primaryWaterLevelDataItem, primaryWaterVolumeDataItem, primaryWaterECDataItem, primaryWaterPHDataItem, primaryWaterDODataItem, primaryWaterORPDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrimaryWaterReservoir", ["water_level", "water_volume", "ec", "ph", "do", "orp"])
                                    
                                    let [primaryWaterReservoirTempComponent, [primaryWaterTempIdentifierInfo],  [primaryWaterTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrimaryReservoirOWTB", ["t"])
                                    let [primaryReservoirSystemPressureTransducer, [primaryReservoirSystemPressureIdentifierInfo],  [primaryReservoirSystemPressureDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "PrimaryLiquidSystemPressureTransducer", ["pressure"])
                    
                                    let reservoirStateColor = variables.online
                



                                    let  [auxARecirculationPumpComponent, [auxARecirculationPumpStateIdentifierInfo], [auxARecirculationPumpStateDataItem]]
                                      = getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxALiquidRecirculationPump", ["state"])
                                    let [auxAWaterReservoirComponent, 
                                      [auxAWaterLevelIdentifierInfo, auxAWaterVolumeIdentifierInfo, auxAWaterECIdentifierInfo, auxAWaterPHIdentifierInfo, auxAWaterDOIdentifierInfo, auxAWaterORPIdentifierInfo], 
                                      [auxAWaterLevelDataItem, auxAWaterVolumeDataItem, auxAWaterECDataItem, auxAWaterPHDataItem, auxAWaterDODataItem, auxAWaterORPDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxAWaterReservoir", ["water_level", "water_volume", "ec", "ph", "do", "orp"])
                                    
                                    let [auxAWaterReservoirTempComponent, [auxAWaterTempIdentifierInfo],  [auxAWaterTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxAReservoirOWTB", ["t"])
                                    let [auxAReservoirSystemPressureTransducer, [auxAReservoirSystemPressureIdentifierInfo],  [auxAReservoirSystemPressureDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxALiquidSystemPressureTransducer", ["pressure"])
                                    
                                    let  [auxBRecirculationPumpComponent, [auxBRecirculationPumpStateIdentifierInfo], [auxBRecirculationPumpStateDataItem]]
                                      = getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxBLiquidRecirculationPump", ["state"])
                                    let [auxBWaterReservoirComponent, 
                                      [auxBWaterLevelIdentifierInfo, auxBWaterVolumeIdentifierInfo, auxBWaterECIdentifierInfo, auxBWaterPHIdentifierInfo, auxBWaterDOIdentifierInfo, auxBWaterORPIdentifierInfo], 
                                      [auxBWaterLevelDataItem, auxBWaterVolumeDataItem, auxBWaterECDataItem, auxBWaterPHDataItem, auxBWaterDODataItem, auxBWaterORPDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxBWaterReservoir", ["water_level", "water_volume", "ec", "ph", "do", "orp"])
                                    
                                    let [auxBWaterReservoirTempComponent, [auxBWaterTempIdentifierInfo],  [auxBWaterTempDataItem]] = 
                                      getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxBReservoirOWTB", ["t"])
                                    let [auxBReservoirSystemPressureTransducer, [auxBReservoirSystemPressureIdentifierInfo],  [auxBReservoirSystemPressureDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "AuxBLiquidSystemPressureTransducer", ["pressure"])
                                      

                                    let [rackMotionComponent, 
                                      [rackMotionReverseStateIdentifierInfo, rackMotionForwardStateIdentifierInfo], 
                                      [rackMotionReverseStateDataItem, rackMotionForwardStateDataItem]] =
                                        getLiveDataItem(blade, foundRackConfigurationMap.component_map, "RackMotionController", ["reverse_switch_state", "forward_switch_state"])
                
                                        let [rackMotionDistanceSensorComponent, [rackMotionDistanceSensorDistanceIdentifierInfo], [rackMotionDistanceSensorDistanceDataItem]] =
                                            getLiveDataItem(blade, foundRackConfigurationMap.component_map, "MotionDistanceSensor", ["cd"])
                                  
                                  


                                            return (
                                              <div className="RowGroupRow-Content-Card" key={blade.id}>
                                                  
                                                    {/*<div className="GrowZones-Service-VerticalRackGroup-Rack-Header-ServiceMode">
                                                        Service Mode:
                                                        <Switch state={inServiceMode} onSwitch={(state) => {
                                                            dispatch(setVerticalRackRuntimeProperty({bladeId: blade.id, properties: {"service_mode": state}}))
                                                          }}/>
                                                        </div>*/}
                                                    <div className="GridTile-WrappingTiles">
                                                      <GridTile>
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">System Status</div>
                                                        </div>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">IP:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{blade.control_device && blade.control_device.ip_address.split(":")[0]}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Local IP:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{blade.control_device && blade.control_device.local_ip_address}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Local Port:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{blade.control_device && blade.control_device.port}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Left Service Door:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{leftDoorSafetySwitchDataItem !== null ? (leftDoorSafetySwitchDataItem.value === 1 ? "Closed": "Open") : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Right Service Door:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rightDoorSafetySwitchDataItem !== null ? (rightDoorSafetySwitchDataItem.value === 1 ? "Closed": "Open") : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Current:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackCurrentDataItem !== null ? rackCurrentDataItem.value + "A" : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Voltage:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackVoltageDataItem !== null ? rackVoltageDataItem.value + "V" : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Wattage:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackWattageDataItem !== null ? rackWattageDataItem.value + "W" : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Board Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{boardTempDataItem !== null ? boardTempDataItem.value + "°C" : "N/A"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Board RH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{boardRHDataItem !== null ? boardRHDataItem.value + "%" : "N/A"}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
          
                                                        
                                                      </GridTile>
                                                      <GridTile className="GridTile-GaugeTile">
                                                          <div className="GridTile-Header" onClick={() => {
                                                              
                                                            }}>
                                                          <div className="GridTile-Title">Air Condition Load</div>
                                                          <div className="GridTile-StatusIndicator" style={{backgroundColor:compressorStateColor}}/>
                                                        </div>
                                                        <CircularGauge value={compressorSpeedDataItem && compressorSpeedDataItem.value} auxText={compressorRPMDataItem && compressorRPMDataItem.value + "rpm"}/>
                                                        {!!inServiceMode && 
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Speed Override
                                                              <Switch state={compressorSpeedInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"compressor_speed_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(compressorSpeedOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"compressor_speed": value}}))
                                                              }}/>
                                                            </div>
                                                          </div>
                                                        }
                                                      </GridTile>
                                                      <GridTile className="GridTile-GaugeTile">
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">Air Condition Status</div>
                                                        </div>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Discharge Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{compressorDischargeTempDataItem && compressorDischargeTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Discharge SH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{compressorDischargeSuperheatDataItem && compressorDischargeSuperheatDataItem.value}°C</td>
                                                            </tr>                                                          
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Common HS Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{commonHighSideDataItem && commonHighSideDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Common SC Pres.:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condenserSubcoolPressureDataItem && condenserSubcoolPressureDataItem.value}psi</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Cond SC:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condenserSubcoolDataItem && condenserSubcoolDataItem.value}°C</td>
                                                            </tr>

                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Leaving Dehumid Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{leavingDehumidCoilTempDataItem && leavingDehumidCoilTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Dehumid SH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{dehumidCoilSuperheatDataItem && dehumidCoilSuperheatDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Leaving Precool Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{leavingPrecoolCoilTempDataItem && leavingPrecoolCoilTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Precool Pres.:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{NHXSubcoolPressureDataItem && NHXSubcoolPressureDataItem.value}psi</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Precool SH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{precoolCoilSuperheatDataItem && precoolCoilSuperheatDataItem.value}°C</td>
                                                            </tr>

                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Suction Pres.:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{compressorSuctionPressureDataItem && compressorSuctionPressureDataItem.value}psi</td>
                                                            </tr>
                                                            {/*<tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Acc Subcool:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{NHXSuperheatDataItem && NHXSuperheatDataItem.value}°C</td>
                                                            </tr>*/}
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Condenser Fan:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condenserFanSpeedDataItem && condenserFanSpeedDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Condenser RPM:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condenserFanRPMDataItem && condenserFanRPMDataItem.value}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Bypass Damper:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{bypassDamperPositionDataItem && bypassDamperPositionDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Supply Fan:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{supplyFanSpeedDataItem && supplyFanSpeedDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Supply RPM:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{supplyFanRPMDataItem && supplyFanRPMDataItem.value}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Dehumid EXV:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{dehumidEEVDataItem && dehumidEEVDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Precool EXV:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{precoolEEVDataItem && precoolEEVDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Precool PRV:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{precoolPRVDataItem && precoolPRVDataItem.value}%</td>
                                                            </tr>
          
                                                            
                                                          </tbody>
                                                        </table>
                                                      </GridTile>
                                                      {!!inServiceMode && 
                                                        <GridTile className="GridTile-GaugeTile">
                                                          <div className="GridTile-Header">
                                                            <div className="GridTile-Title">Air Condition Overrides</div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Supply Fan 
                                                              <Switch state={supplyFanSpeedInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"supply_fan_speed_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(supplyFanSpeedOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"supply_fan_speed": value}}))
                                                            }}/></div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Bypass Damper
                                                              <Switch state={bypassDamperPositionInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"bypass_damper_position_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(bypassDamperPositionOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"bypass_damper_position": value}}))
                                                            }}/></div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Condenser Fan 
                                                              <Switch state={condenserFanSpeedInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"condenser_fan_speed_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(condenserFanSpeedOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"condenser_fan_speed": value}}))
                                                            }}/></div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Dehumid EXV
                                                              <Switch state={dehumidEEVPositionInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"dehumid_eev_position_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(dehumidEEVPositionOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"dehumid_eev_position": value}}))
                                                            }}/></div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Precool EXV
                                                              <Switch state={precoolEEVPositionInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"precool_eev_position_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(precoolEEVPositionOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"precool_eev_position": value}}))
                                                            }}/></div>
                                                          </div>
                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Precool PRV
                                                              <Switch state={precoolPRVPositionInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"precool_prv_position_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(precoolPRVPositionOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"precool_prv_position": value}}))
                                                            }}/></div>
                                                          </div>
                                                        </GridTile>
                                                      }
                                                      <GridTile>
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">Canopy Air</div>
                                                        </div>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airTempDataItem && airTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">RH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airRHDataItem && airRHDataItem.value}%</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">VPD:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airVPDDataItem && airVPDDataItem.value}kPa</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">CO2:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airCO2DataItem && airCO2DataItem.value}ppm</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Leaf Temp:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{leafTempDataItem && leafTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Dewpoint:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airDewpointDataItem && airDewpointDataItem.value}°C</td>
                                                            </tr>

                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Supply Air:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{supplyAirTempDataItem && supplyAirTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Return Air:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{returnAirTempDataItem && returnAirTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Enter Dehumid Air:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airEnteringDehumidCoilTempDataItem && airEnteringDehumidCoilTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Leave Dehumid Air:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{airLeavingDehumidCoilTempDataItem && airLeavingDehumidCoilTempDataItem.value}°C</td>
                                                            </tr>

                                                          </tbody>
                                                        </table>
                                                      </GridTile>
                                                      {!!inServiceMode && 
                                                        <GridTile className="GridTile-GaugeTile">
                                                          <div className="GridTile-Header">
                                                            <div className="GridTile-Title">Overrides</div>
                                                          </div>

                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Lights
                                                              <Switch state={allLightsOutputFactorInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"all_lights_output_factor_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(allLightsOutputFactorOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"all_lights_output_factor": value}}))
                                                            }}/></div>
                                                          </div>


                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Air Temp
                                                              <Switch state={airTempSetpointInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_air_temp_setpoint_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(airTempSetpointOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_air_temp_setpoint": value}}))
                                                            }}/></div>
                                                          </div>

                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Air RH
                                                              <Switch state={airRHSetpointInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_air_rh_setpoint_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(airRHSetpointOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_air_rh_setpoint": value}}))
                                                            }}/></div>
                                                          </div>

                                                          <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Leaf Temp Offset
                                                              <Switch state={leafTempOffsetInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_leaf_temp_offset_override": state}}))
                                                              }}/>
                                                            </div>
                                                            <div className="GridTile-OverrideItem-Value">
                                                              <NumberInput 
                                                                min={0}
                                                                max={100}
                                                                value={parseFloat(leafTempOffsetOverrideValue)} 
                                                                onBlur={(value) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"manual_leaf_temp_offset": value}}))
                                                            }}/></div>
                                                          </div>
                                                          
                                                        </GridTile>
                                                      }
                                                      <GridTile>
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">Primary Reservoir Status</div>
                                                          <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                                        </div>
                                                        <CircularGauge value={primaryWaterLevelDataItem && primaryWaterLevelDataItem.value} auxText={primaryWaterVolumeDataItem && primaryWaterVolumeDataItem.value + "L"}/>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">System Pressure:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryReservoirSystemPressureDataItem && primaryReservoirSystemPressureDataItem.value}psi</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryWaterTempDataItem && primaryWaterTempDataItem.value}°C</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">EC:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryWaterECDataItem && primaryWaterECDataItem.value}S/m</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">pH:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryWaterPHDataItem && primaryWaterPHDataItem.value}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">ORP:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryWaterORPDataItem && primaryWaterORPDataItem.value}mV</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">DO:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{primaryWaterDODataItem && primaryWaterDODataItem.value}</td>
                                                            </tr>

                                                            
          
                                                            
                                                          </tbody>
                                                        </table>
                                                        <div className="GridTile-OverrideItem">
                                                            <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                              <Switch state={primaryLocalRecirculationInOverride} onSwitch={(state) => {
                                                                dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"primary_local_recirculation_override": state}}))
                                                              }}/>
                                                            </div>
                                                          </div>
                                                      </GridTile>
                                                      {isAuxAPumpingSystemActive &&
                                                        <GridTile>
                                                          <div className="GridTile-Header">
                                                            <div className="GridTile-Title">Aux A Reservoir Status</div>
                                                            <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                                          </div>
                                                          <CircularGauge value={auxAWaterLevelDataItem && auxAWaterLevelDataItem.value} auxText={auxAWaterVolumeDataItem && auxAWaterVolumeDataItem.value + "L"}/>
                                                          <table className="GridTile-DataItemTable">
                                                            <tbody>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">System Pressure:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAReservoirSystemPressureDataItem && auxAReservoirSystemPressureDataItem.value}psi</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">Temperature:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAWaterTempDataItem && auxAWaterTempDataItem.value}°C</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">EC:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAWaterECDataItem && auxAWaterECDataItem.value}S/m</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">pH:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAWaterPHDataItem && auxAWaterPHDataItem.value}</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">ORP:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAWaterORPDataItem && auxAWaterORPDataItem.value}mV</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">DO:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxAWaterDODataItem && auxAWaterDODataItem.value}</td>
                                                              </tr>
          
                                                              
                                                            </tbody>
                                                          </table>
                                                          <div className="GridTile-OverrideItem">
                                                              <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                                <Switch state={auxALocalRecirculationInOverride} onSwitch={(state) => {
                                                                  dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"aux_a_local_recirculation_override": state}}))
                                                                }}/>
                                                              </div>
                                                            </div>
                                                        </GridTile>
                                                      }
                                                      {isAuxBPumpingSystemActive &&
                                                        <GridTile>
                                                          <div className="GridTile-Header">
                                                            <div className="GridTile-Title">Aux B Reservoir Status</div>
                                                            <div className="GridTile-StatusIndicator" style={{backgroundColor:reservoirStateColor}}/>
                                                          </div>
                                                          <CircularGauge value={auxBWaterLevelDataItem && auxBWaterLevelDataItem.value} auxText={auxBWaterVolumeDataItem && auxBWaterVolumeDataItem.value + "L"}/>
                                                          <table className="GridTile-DataItemTable">
                                                            <tbody>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">Pressure:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBReservoirSystemPressureDataItem && auxBReservoirSystemPressureDataItem.value}psi</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">System Temperature:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBWaterTempDataItem && auxBWaterTempDataItem.value}°C</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">EC:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBWaterECDataItem && auxBWaterECDataItem.value}S/m</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">pH:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBWaterPHDataItem && auxBWaterPHDataItem.value}</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">ORP:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBWaterORPDataItem && auxBWaterORPDataItem.value}mV</td>
                                                              </tr>
                                                              <tr className="GridTile-DataItem-Row">
                                                                <td className="GridTile-DataItem-Row-Label">DO:</td>
                                                                <td className="GridTile-DataItem-Row-Value">{auxBWaterDODataItem && auxBWaterDODataItem.value}</td>
                                                              </tr>
          
                                                              
                                                            </tbody>
                                                          </table>
                                                          <div className="GridTile-OverrideItem">
                                                              <div className="GridTile-OverrideItem-Toggle">Local Recirculation
                                                                <Switch state={auxBLocalRecirculationInOverride} onSwitch={(state) => {
                                                                  dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"aux_b_local_recirculation_override": state}}))
                                                                }}/>
                                                              </div>
                                                            </div>
                                                        </GridTile>
                                                      }
                                                      <GridTile>
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">Condensate Subsystem</div>
                                                        </div>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Pump State:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateSubsystemPumpStateDataItem && (condensateSubsystemPumpStateDataItem.value === 1) ? "On" : "Off"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Float Switch State:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateSubsystemFloatSwitchStateDataItem && (condensateSubsystemFloatSwitchStateDataItem.value === 1) ? "On" : "Off"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Current Volume:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateSubsystemTotalVolumeDataItem && condensateSubsystemTotalVolumeDataItem.value}L</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Avg Evap Metric:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateAverageEvapMetricDataItem && condensateAverageEvapMetricDataItem.value}g/m2/s</td>
                                                            </tr>
                                                            {/*<tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Avg Evap Imperial:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateAverageEvapImperialDataItem && condensateAverageEvapImperialDataItem.value}lbs/sqft/hr</td>
                                                            </tr>*/}
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Avg Evap Imperial:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{condensateAverageEvapImperialDataItem && RoundToNearest(condensateAverageEvapImperialDataItem.value * 96, 0.01)}lbs/hr</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </GridTile>
                                                      <GridTile>
                                                        <div className="GridTile-Header">
                                                          <div className="GridTile-Title">Rack Motion</div>
                                                        </div>
                                                        <table className="GridTile-DataItemTable">
                                                          <tbody>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Reverse Switch:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackMotionReverseStateDataItem && rackMotionReverseStateDataItem.value ? "On" : "Off"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Forward Switch:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackMotionForwardStateDataItem && rackMotionForwardStateDataItem.value ? "On" : "Off"}</td>
                                                            </tr>
                                                            <tr className="GridTile-DataItem-Row">
                                                              <td className="GridTile-DataItem-Row-Label">Distance:</td>
                                                              <td className="GridTile-DataItem-Row-Value">{rackMotionDistanceSensorDistanceDataItem && rackMotionDistanceSensorDistanceDataItem.value}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </GridTile>
                                                    </div>
                                                  </div>
                                            )
                                  
                                      }else {
                                        return (
                                          <div key={blade.id}
                                            className="RowGroupRow-Content-Card">
                                              No configuration map
                                          </div>
                                        )
                                      }




                            })()}
                          </>}/>
                      )
                    }
                  })}

                </RowGroup>
              )
            
            })}
          </div>
        }
    </div>
    {addingNewRack && <AddBladeWalkthrough facilityId={selectedFacility} completeCallback={addBladeWalkthroughCloseCallback} closeCallback={addBladeWalkthroughCloseCallback}/>}
    {managingLightsForBladeId && <BladeLightsManager blade={allBlades.find((b) => b.id === managingLightsForBladeId)} closeCallback={closeManagingLights}/>}
    {viewingIssuesForBlade && <BladeIssuesPopup bladeId={viewingIssuesForBlade} closeCallback={closeViewBladeIssues}/>}
  </>)
} 

export default GrowRacksPage