import './TransplantToGrowOutFromNurseryTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { NurseryRowSelectionSupportingInfoVisual, NurseryRowSelectionVisual } from '../../../../assets/icons/RackIcons';
import { numberOfGrowOutRaftsPerZone } from '../../../../redux/entities/Grow';

const TransplantToGrowOutFromNurseryTask_SelectNurseryLocationsStep = ({
    cancelTaskCallback,
    activeGrow,
    allBladeZones,
    activeRecipe,
    activeGrowPlan,
    activeBladeType,
    activeGrowOutRaftType,
    initialSelectedNurseryLocations,
    nurseryBoardSelectionUpdated,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["select_grow_out_zone", null])
    }

    const nextClicked = () =>  {
        transitionToNextStep(["confirm_moved_units", null])
    }
    

    let nurseryRowOptions = [
        {value: 1, label: "Row A", tagLabel: "A"},
        {value: 2, label: "Row B", tagLabel: "B"},
        {value: 3, label: "Row C", tagLabel: "C"},
        {value: 4, label: "Row D", tagLabel: "D"},
        {value: 5, label: "Row E", tagLabel: "E"},
        {value: 6, label: "Row F", tagLabel: "F"},
        {value: 7, label: "Row G", tagLabel: "G"},
    ]



    const [selectedNurseryLocations, SetSelectedNurseryLocations] = React.useState({})
    React.useLayoutEffect(() => {
        SetSelectedNurseryLocations(initialSelectedNurseryLocations)
    }, [initialSelectedNurseryLocations])

    const nurseryLocationToggled = (nurseryUID, rowIndex, location) => {
        let newNurseryLocations = {...selectedNurseryLocations}

        let exists = false
        if (selectedNurseryLocations[nurseryUID] !== undefined) {
            if (selectedNurseryLocations[nurseryUID][rowIndex] !== undefined)   {
                if (selectedNurseryLocations[nurseryUID][rowIndex].indexOf(location) !== -1)    {
                    exists = true
                }
            }
        }

        if (!exists)    {
            //toggled on
            if (newNurseryLocations[nurseryUID] === undefined)  {
                newNurseryLocations[nurseryUID] = {}
            }
            if (newNurseryLocations[nurseryUID][rowIndex] === undefined)  {
                newNurseryLocations[nurseryUID][rowIndex] = []
            }
            if (newNurseryLocations[nurseryUID][rowIndex].indexOf(location) === -1)   {
                newNurseryLocations[nurseryUID][rowIndex].push(location)
            }
        }else {
            newNurseryLocations[nurseryUID][rowIndex] = newNurseryLocations[nurseryUID][rowIndex].filter((l) => l != location)
            if (newNurseryLocations[nurseryUID][rowIndex].length === 0) {
                delete newNurseryLocations[nurseryUID][rowIndex]
                if (Object.entries(newNurseryLocations[nurseryUID]).length === 0) {
                    delete newNurseryLocations[nurseryUID]
                }
            }
        }
        SetSelectedNurseryLocations(newNurseryLocations)

        nurseryBoardSelectionUpdated(newNurseryLocations)
    }
    const nurseryRowToggled = (nurseryUID, rowIndex, locations) => {
        let newNurseryLocations = {...selectedNurseryLocations}

        let exists = false
        if (selectedNurseryLocations[nurseryUID] !== undefined) {
            if (selectedNurseryLocations[nurseryUID][rowIndex] !== undefined)   {
                exists = true
            }
        }

        if (!exists)    {
            //toggled on
            if (newNurseryLocations[nurseryUID] === undefined)  {
                newNurseryLocations[nurseryUID] = {}
            }
            if (newNurseryLocations[nurseryUID][rowIndex] === undefined)  {
                newNurseryLocations[nurseryUID][rowIndex] = [...locations]
            }
        }else {
            delete newNurseryLocations[nurseryUID][rowIndex]
            if (Object.entries(newNurseryLocations[nurseryUID]).length === 0) {
                delete newNurseryLocations[nurseryUID]
            }
        }
        SetSelectedNurseryLocations(newNurseryLocations)

        nurseryBoardSelectionUpdated(newNurseryLocations)
    }




    let maxRaftsForZone = 0
    if (activeRecipe !== undefined && activeBladeType !== undefined && activeGrowOutRaftType !== undefined) {
        maxRaftsForZone = numberOfGrowOutRaftsPerZone(activeRecipe.grow_out_type, activeBladeType, activeGrowOutRaftType)
    }
    let maxNumberOfUnits = 0
    if (activeGrowPlan !== undefined)   {
        maxNumberOfUnits = maxRaftsForZone * activeGrowPlan.grow_out_raft_usage
    }


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        
                        <div className="FlexContent-H-10 FlexContent-HFill">
                            <div className="Text-SubHeader Text-Wrap">
                                Step 3: Select the nursery boards you are transplanting from. Transplant into up to {maxRaftsForZone} grow out boards ({maxNumberOfUnits} plant sites)
                            </div>
                            <div></div>
                        </div>
                        <div className={(isMobile ? "FlexContent-10" : "FlexContent-H-50 FlexContent-HFill")}>
                            <div className="FlexContent-10" style={{justifyContent: "center"}}>
                                <NurseryRowSelectionVisual/>
                                <NurseryRowSelectionSupportingInfoVisual/>
                            </div>
                            <div className={"FlexContent-10" + (isMobile ? " Linebreak-10-Dual" : " Linebreak-20-Dual")} style={{flex:1}}>
                                {activeGrow !== undefined && <>
                                    {Object.entries(activeGrow.nursery_locations).map(([nurseryUID, rowLocations]) => {
                                        let nurseryZone = allBladeZones.find((bZ) => bZ.uid === nurseryUID)
                                        return (
                                            <div className="FlexContent-15">
                                                <div className="Text-Subtext">{nurseryZone !== undefined ? nurseryZone.display_name : ""}</div>
                                                {Object.entries(rowLocations).map(([rowIndex, locations]) => {
                                                    let foundRowOption = nurseryRowOptions.find((o) => o.value === parseInt(rowIndex))
                                                    let nurseryLocationClicked = (location) => {
                                                        nurseryLocationToggled(nurseryUID, parseInt(rowIndex), location)
                                                    }
                                                    let nurserySelectAllLocationsClicked = () => {
                                                        nurseryRowToggled(nurseryUID, parseInt(rowIndex), locations)
                                                    }
                                                    return <>
                                                        <div className="FlexContent-H-15" style={{alignItems:"center"}}>
                                                            <div>{foundRowOption.tagLabel}</div>
                                                            {isMobile && <>
                                                                <div className="">
                                                                    <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                                        {locations.map((location) => {

                                                                            let isSelected = false
                                                                            if (selectedNurseryLocations[nurseryUID] !== undefined) {
                                                                                if (selectedNurseryLocations[nurseryUID][rowIndex] !== undefined)   {
                                                                                    if (selectedNurseryLocations[nurseryUID][rowIndex].indexOf(location) !== -1)    {
                                                                                        isSelected = true
                                                                                    }
                                                                                }
                                                                            }
                                                                            return (
                                                                                <Button status="Primary-Toggle" state={isSelected} content={location} onClick={() => {nurseryLocationClicked(location)}}/>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    <div><Button status="Hyperlink" content="Select All" onClick={nurserySelectAllLocationsClicked}/></div>
                                                                </div>
                                                            </>}

                                                            {!isMobile && <>
                                                                <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                                    {locations.map((location) => {
                                                                        
                                                                        let isSelected = false
                                                                        if (selectedNurseryLocations[nurseryUID] !== undefined) {
                                                                            if (selectedNurseryLocations[nurseryUID][rowIndex] !== undefined)   {
                                                                                if (selectedNurseryLocations[nurseryUID][rowIndex].indexOf(location) !== -1)    {
                                                                                    isSelected = true
                                                                                }
                                                                            }
                                                                        }
                                                                        return (
                                                                            <Button status="Primary-Toggle" state={isSelected} content={location} onClick={() => {nurseryLocationClicked(location)}}/>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div><Button status="Hyperlink" content="Select All" onClick={nurserySelectAllLocationsClicked}/></div>
                                                            </>}
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                        )
                                    })}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default TransplantToGrowOutFromNurseryTask_SelectNurseryLocationsStep