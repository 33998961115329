import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/GrowSeeds';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';


const HarvestTask_ContainerOptionsWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerOptionConfirmedCalback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["prepare_for_harvest", null])
    }
    const nextClicked = () => {
        containerOptionConfirmedCalback({containerOption: containerOption})
        if (activeGrowPlan.plant_variants.length > 1)   {
            transitionToNextStep(["container_contents", null])
        }else {
            transitionToNextStep(["container_weight", null])
        }
    }


    const [containerOption, SetContainerOption] = React.useState(null)
    

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Select Container Option
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
            <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                <Button content={<>
                    <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                            <div>
                            <svg width="58" height="43" viewBox="0 0 58 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_3293_26209" fill="white">
                                <rect x="14" width="31" height="43" rx="2"/>
                                </mask>
                                <rect x="14" width="31" height="43" rx="2" fill="white" stroke="#191C22" stroke-width="7.2" mask="url(#path-1-inside-1_3293_26209)"/>
                                <mask id="path-2-inside-2_3293_26209" fill="white">
                                <rect y="17" width="22" height="26" rx="2"/>
                                </mask>
                                <rect y="17" width="22" height="26" rx="2" fill="white" stroke="#191C22" stroke-width="7.2" mask="url(#path-2-inside-2_3293_26209)"/>
                                <mask id="path-3-inside-3_3293_26209" fill="white">
                                <rect x="33" y="11" width="25" height="32" rx="2"/>
                                </mask>
                                <rect x="33" y="11" width="25" height="32" rx="2" fill="white" stroke="#191C22" stroke-width="7.2" mask="url(#path-3-inside-3_3293_26209)"/>
                            </svg>

                            </div>
                            <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>Individual Weight Containers</div>
                            <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>Weight and track each container individually</div>
                        </div>
                    </div>
                    </>} status={"Primary-Toggle"} state={containerOption === "individual"} width={"Flex100"} onClick={() => {SetContainerOption("individual")}}/>
                <Button content={<>
                    <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                            <div>
                                <svg width="62" height="41" viewBox="0 0 62 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-inside-1_3293_26219" fill="white">
                                    <rect x="0.5" width="28" height="41" rx="2"/>
                                    </mask>
                                    <rect x="0.5" width="28" height="41" rx="2" fill="white" stroke="#191C22" stroke-width="7.2" mask="url(#path-1-inside-1_3293_26219)"/>
                                    <mask id="path-2-inside-2_3293_26219" fill="white">
                                    <rect x="33.5" width="28" height="41" rx="2"/>
                                    </mask>
                                    <rect x="33.5" width="28" height="41" rx="2" fill="white" stroke="#191C22" stroke-width="7.2" mask="url(#path-2-inside-2_3293_26219)"/>
                                </svg>

                            </div>
                            <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>Known Weight Containers</div>
                            <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>All containers have the same weight, weigh one and indicate how many you fill</div>
                        </div>
                    </div>
                    </>} status={"Primary-Toggle"} state={containerOption === "known_weight"} width={"Flex100"} onClick={() => {SetContainerOption("known_weight")}}/>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={containerOption === null}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ContainerOptionsWalkthroughStep