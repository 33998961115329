import './DateInput.scss';
import React from 'react'

import { FormatDate, useMeasure } from '../../helpers'

const DateInput = ({value, prefix, suffix, onChange, onBlur}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const [pointerId, SetPointerId] = React.useState(null)
  const containerRef = React.useRef(null);

  const [dateValue, setDateValue] = React.useState(value);

  const [yearValue, setYearValue] = React.useState(null);
  const [monthValue, setMonthValue] = React.useState(null);
  const [dayValue, setDayValue] = React.useState(null);

  const [yearValueDisplay, setYearValueDisplay] = React.useState("");
  const [monthValueDisplay, setMonthValueDisplay] = React.useState("");
  const [dayValueDisplay, setDayValueDisplay] = React.useState("");

  const dateValueRef = React.useRef(null);
  const yearValueRef = React.useRef(null);
  const yearInput = React.useRef(null);
  const monthValueRef = React.useRef(null);
  const monthInput = React.useRef(null);
  const dayValueRef = React.useRef(null);
  const dayInput = React.useRef(null);

  const actualIcon = false;
  const [inputBind, { height: inputHeight }] = useMeasure()

  const [yearInTempEmptyMode, SetYearInTempEmptyMode] = React.useState(value === null)
  const [monthInTempEmptyMode, SetMonthInTempEmptyMode] = React.useState(value === null)
  const [dayInTempEmptyMode, SetDayInTempEmptyMode] = React.useState(value === null)

  React.useEffect(() => {
    let newDate = null
    if (yearValue && monthValue && dayValue)  {
      newDate = new Date(yearValue, monthValue - 1, dayValue);
    }else {

    }
    if (onChange !== undefined) {
      let result = onChange(newDate)
      if (result !== undefined) {
        //numberInput.current.value = result
        setDateValue(result)
        return false
      }else {
        yearInput.current.value = FormatDate(newDate, "yyyy")
        monthInput.current.value = FormatDate(newDate, "MM")
        dayInput.current.value = FormatDate(newDate, "dd")
        setDateValue(newDate)
        return false
      }
    }
    setDateValue(newDate)
  }, [yearValue, monthValue, dayValue])

  const yearValueChanged = React.useCallback((e) =>  {
    e.preventDefault()
    if (e.target.value === "")  {
      SetYearInTempEmptyMode(true)
      setYearValue(null)
    }else {
      let requestedValue = parseInt(e.target.value)
      if (requestedValue == yearValue)
        return
      SetYearInTempEmptyMode(false)
      setYearValue(requestedValue)
    }
  })

  
  const monthValueChanged = React.useCallback((e) =>  {
    e.preventDefault()
    if (e.target.value === "")  {
      SetMonthInTempEmptyMode(true)
      setMonthValue(null)
    }else {
      let requestedValue = parseInt(e.target.value)
      if (requestedValue == monthValue)
        return
        
      SetMonthInTempEmptyMode(false)
      setMonthValue(e.target.value)

    }
  })

  
  const dayValueChanged = React.useCallback((e) =>  {
    e.preventDefault()
    if (e.target.value === "")  {
      SetDayInTempEmptyMode(true)
      setDayValue(null)
    }else {
      let requestedValue = parseInt(e.target.value)
      if (requestedValue == dayValue)
        return
        
      SetDayInTempEmptyMode(false)
      setDayValue(e.target.value)

    }
  })


  React.useEffect(() => {
    
    setDateValue(value)
    //TODO A) Set year/month/day values by parsing
    if (value)  {
      setYearValue(FormatDate(value, "yyyy"))
      setMonthValue(FormatDate(value, "MM"))
      setDayValue(FormatDate(value, "dd"))

    }else {
      setYearValue(null)
      setMonthValue(null)
      setDayValue(null)
    }

  }, [value])

  React.useEffect(() => {
    dateValueRef.current = dateValue
  }, [dateValue])

  
  React.useEffect(() => {
    yearValueRef.current = yearValue
  }, [yearValue])

  
  React.useEffect(() => {
    monthValueRef.current = monthValue
  }, [monthValue])

  
  React.useEffect(() => {
    dayValueRef.current = dayValue
  }, [dayValue])


  const widthRatio = 0.75

  let yearInputProps = {}
  let monthInputProps = {}
  let dayInputProps = {}
  if (yearInTempEmptyMode)  {
    yearInputProps["placeholder"] = "yyyy"
  }
  if (monthInTempEmptyMode)  {
    monthInputProps["placeholder"] = "mm"
  }
  if (dayInTempEmptyMode)  {
    dayInputProps["placeholder"] = "dd"
  }

  const handleFocus = React.useCallback((e) => {
    if (!focusState)  {
      SetFocusState(true)
      document.addEventListener('pointerdown', handleBlur);
    }
  })

  const handleBlur = React.useCallback((ev) => {
    const xCoord = ev.clientX;
    const yCoord = ev.clientY;
    if (containerRef.current) {
      const { right, x, bottom, y } = containerRef.current.getBoundingClientRect();
      if (xCoord < right && xCoord > x && yCoord < bottom && yCoord > y) {
        return;
      }
      blur()
      document.removeEventListener('pointerdown', handleBlur);
    }
  })

  const blur = React.useCallback(() => {
    SetFocusState(false)
    //SetYearInTempEmptyMode(false)
    //SetMonthInTempEmptyMode(false)
    //SetDayInTempEmptyMode(false)
    if (dateValueRef.current)  {
      if (onBlur !== undefined) {
        onBlur(dateValueRef.current)
      }
    }
  })

  const focusEnd = (e) => {
    
  }

  const calculateYearValueDisplay = (value) =>  {
    if (yearInTempEmptyMode)  {
      return ""
    }
    let yearValueDisplay = value
    return yearValueDisplay
  }
  
  const calculateMonthValueDisplay = (value) =>  {
    if (monthInTempEmptyMode)  {
      return ""
    }
    let monthValueDisplay = value
    return monthValueDisplay
  }
  
  const calculateDayValueDisplay = (value) =>  {
    if (dayInTempEmptyMode)  {
      return ""
    }
    let dayValueDisplay = value
    return dayValueDisplay
  }

  
  React.useEffect(() => {
    let yearValueDisplay = calculateYearValueDisplay(yearValue)
    //if (valueDisplay !== numberInput.current.value)  {
      yearInput.current.value = yearValueDisplay
    //}
  }, [yearValue, yearInTempEmptyMode])

  React.useEffect(() => {
    let monthValueDisplay = calculateMonthValueDisplay(monthValue)
    //if (valueDisplay !== numberInput.current.value)  {
      monthInput.current.value = monthValueDisplay
    //}
  }, [monthValue, monthInTempEmptyMode])

  React.useEffect(() => {
    let dayValueDisplay = calculateDayValueDisplay(dayValue)
    //if (valueDisplay !== numberInput.current.value)  {
      dayInput.current.value = dayValueDisplay
    //}
  }, [dayValue, dayInTempEmptyMode])

  //let defaultValue = calculateValueDisplay(numberValue)

  return (
    <div className={"DateInput" + (focusState ? " DateInput-Focused" : "")} onPointerDown={handleFocus} ref={containerRef}>
      
      <div {...inputBind}>
        <div className="DateInput_Content"
          style={{ width: 'auto' }}>
          {(prefix !== false) &&
            <div className="DateInput_Prefix noselect">
              {prefix}
            </div>
          }
          <div className="DateInput_DisplayArea">
            <input
              defaultValue={calculateDayValueDisplay(dayValue)}
              style={{ width: '2ch' }}
              onChange={dayValueChanged} 
              type="text" pattern="[0-9]*"
              ref={dayInput}
              {...dayInputProps}/>
            <span>-</span>
            <input
              defaultValue={calculateMonthValueDisplay(monthValue)}
              style={{ width: '3ch' }}
              onChange={monthValueChanged} 
              type="text" pattern="[0-9]*"
              ref={monthInput}
              {...monthInputProps}/>
            <span>-</span>
            <input
              defaultValue={calculateYearValueDisplay(yearValue)}
              style={{ width: '4ch' }}
              onChange={yearValueChanged} 
              type="text" pattern="[0-9]*"
              ref={yearInput}
              {...yearInputProps}/>
          </div>
          {(suffix !== false) &&
            <div className="DateInput_Suffix noselect">
              {suffix}
            </div>
          }
        </div>
      </div>

    </div>
  )
} 

DateInput.defaultProps = {
  value: "",
  prefix: false,
  suffix: false,
  min: false,
  max: false
}


export default DateInput