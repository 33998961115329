import './AccountLoginPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import PopupModal from '../../model_components/PopupModal';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../components/input/TextInput';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { attemptAccountEmailLogin, attemptAccountLogin } from '../../redux/entities/Account';






const AccountLoginPage = ({cancelLoginCallback}) => {

    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    let data = useSelector((state) => state.account)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeClicked = () => {
        navigate("/")
        //cancelLoginCallback()
    }


    const [currentUserInput, SetCurrentUserInput] = React.useState("")
    const userInputChanged = (user) => {
        SetCurrentUserInput(user)
    }

    const [currentUserKey, SetCurrentUserKey] = React.useState("")
    const userKeyChanged = (key) => {
        SetCurrentUserKey(key)
    }

    const registerAccountClicked = () => {
        navigate("/account/register")
    }


    const [loginAttemptProcessing, SetLoginAttemptProcessing] = React.useState(false)
    const [registerAccountSuccessful, SetRegisterAccountSuccessful] = React.useState(false)
    const attemptLoginClicked = () => {
        SetLoginAttemptProcessing(true)
        let payload = {
            userkey: currentUserKey,
            deviceAgent: window.navigator.userAgent,
        }
        if (currentUserInput.indexOf("@") !== -1)   {
            payload.emailAddress = currentUserInput
        }else {
            payload.username = currentUserInput        
        }
        dispatch(attemptAccountLogin({
            ...payload,
            callback: (success) => {
                SetLoginAttemptProcessing(false)
                if (success)    {
                    navigate("/")
                }
            }
        }))
    }

    return(
        <PopupModal
            title={"Log In To Your Account"}
            size={"full"}
            closeCallback={closeClicked}>
            <div className="FlexContent-50" style={{padding: isMobile ? "10px 10px" : "40px 40px"}}>
                <div className="FlexContent-30">
                    <div className="FlexContent-20">
                        <div className="FlexContent-5">
                            <div className="Text-Medium-S14">Email or Username</div>
                            <TextInput value={currentUserInput} placeHolder={"Enter email or username"} onChange={userInputChanged}/>
                        </div>
                        <div className="FlexContent-5">
                            <div className="FlexContent-H" style={{justifyContent:"space-between"}}>
                                <div className="Text-Medium-S14">Password</div>
                                <div className="Text-Hyperlink">Forgot Password</div>
                            </div>
                            <TextInput value={currentUserKey} isPassword={true}  placeHolder={"Enter password"} onChange={userKeyChanged}/>                            
                        </div>
                    </div>
                    <div className="FlexContent" style={{gap:16}}>
                        <Button content={"LogIn"} status={"Primary"} onClick={attemptLoginClicked} disabled={currentUserInput === "" || currentUserKey === "" || loginAttemptProcessing}/>
                    </div>
                </div>
                <div className="FlexContent-H-5">
                    <div className="Text-Medium-S14">Don't have an account?</div>
                    <div className="Text-Hyperlink" onClick={registerAccountClicked}>Register now</div>
                </div>
            </div>
        </PopupModal>
    )
}

export default AccountLoginPage