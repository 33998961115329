import './RecipeNutrientsPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate, useMeasure, useMeasureWithRef } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, recipeChanged, timelineItemChanged, pushRecipeChange } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import {FaLock, FaUnlock, FaTrashAlt} from 'react-icons/fa'
import {HiOutlineDuplicate} from 'react-icons/hi'

import SelectSubpartPopup from './SelectSubpartPopup'
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';
import DropDownInput from '../../../components/input/DropDownInput';
import { useMediaQuery } from 'react-responsive';

const RecipeNutrientsPage = ({recipe, isEditingRecipe, selectedTimelineItem, selectTimelineItem, timelineItemSelectionOptions}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop


  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);
  const [nutrientParts, SetNutrientParts] = React.useState([]);

  const [pointerPosition, SetPointerPosition] = React.useState({x: 0, y: 0});

  const [partReordering, SetPartReordering] = React.useState(undefined);
  const [partReorderingOffset, SetPartReorderingOffset] = React.useState({x: 0, y: 0});
  const partReorderingOffsetRef = React.useRef({x: 0, y: 0});
  React.useEffect(() => {partReorderingOffsetRef.current = partReorderingOffset}, [partReorderingOffset])
  const [partReorderingCompleted, SetPartReorderingCompleted] = React.useState(false);
  const reorderingPartRef = React.useRef(undefined);
  

  const [selectingSubpartForPart, SetSelectingSubpartForPart] = React.useState(undefined)
  const [selectingSubpart, SetSelectingSubpart] = React.useState(false)

  const [timelineItems, SetTimelineItems] = React.useState([]);
  React.useEffect(() => {
    if (recipe === undefined) 
      return

    if (recipe.timeline_items != null) {
      SetTimelineItems([...recipe.timeline_items])
    }
  }, [recipe])
  const timelineItemSelected = React.useCallback((timelineItem) => {
    if (selectedTimelineItem != timelineItem && selectTimelineItem !== undefined)  {
      selectTimelineItem(timelineItem)
    }
  })
  const onTimelineItemSelectionChanged = React.useCallback((value) => {
    let foundTimelineItem = timelineItems.find(c => value === c.id)
    if (foundTimelineItem !== undefined) {
      timelineItemSelected(foundTimelineItem)
    }
  })


  React.useEffect(() => {
    if (selectedTimelineItem === undefined || selectedTimelineItem.item.nutrient_recipe_zones === undefined)  {
      if (nutrientParts.length > 0) {
        SetNutrientParts([])
      }
      return
    }

    let foundZone = selectedTimelineItem.item.nutrient_recipe_zones.find((nZ) => nZ.zone_index === 0)
    if (foundZone !== undefined)  {

      SetNutrientParts(foundZone.parts)
    }else {
      SetNutrientParts([])
    }


  }, [selectedTimelineItem])

  const createPart = React.useCallback(() => {

    /*let unlockedParts = []
    let totalLockedRatio = 0
    for (let part of nutrientParts) {
      if (part.locked === undefined || !part.locked)  {
        unlockedParts.push(part.index)
      }else {
        totalLockedRatio += part.percent_of
      }
    }

    let newNutrientPartRatio = (100 - totalLockedRatio) / (unlockedParts.length + 1)

    let currentNutrientParts = nutrientParts.map((part) =>  {
      if (part.locked === undefined || !part.locked)  {
        return {...part, percent_of: ((100 - (totalLockedRatio + newNutrientPartRatio)) / 100) * part.percent_of }
      }
      return part
    })*/

    

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }

        let updatedNutrientRecipeZone = timelineItem.item.nutrient_recipe_zones
        if (updatedNutrientRecipeZone.find((nZ) => nZ.zone_index === 0) === undefined)  {
          updatedNutrientRecipeZone = [...updatedNutrientRecipeZone, {zone_index: 0, parts: []}]
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: updatedNutrientRecipeZone.map((nutrientZone) => {
              if (nutrientZone.zone_index !== 0)  {
                return nutrientZone
              }
              return {...nutrientZone, parts: [...nutrientZone.parts, {
                index: nutrientParts.length,
                percent_of: nutrientParts.length === 0 ? 100 : 0,
                sub_parts: [] 
              }]}
            })
          }
        }
      })]
    }}))

  })


  
  const openSubpartSelection = (part) => {
    SetSelectingSubpartForPart(part)
    SetSelectingSubpart(true)
  }

  const closeSubpartSelectionPopup = () =>  {
    SetSelectingSubpart(false)
  }

  const subpartSelectionCompleted = (selectedSubpart) =>  {
    console.log(selectedSubpart)

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: timelineItem.item.nutrient_recipe_zones.map((nutrientZone) => {
              if (nutrientZone.zone_index !== 0)  {
                return nutrientZone
              }
              return {...nutrientZone, 
                parts: [...nutrientParts.map((part) => {
                  if (part.index !== selectingSubpartForPart.index) {
                    return part
                  }
                  return {...part, sub_parts: [...part.sub_parts, {
                    index: part.sub_parts.length + 1,
                    percent_of: part.sub_parts.length === 0 ? 100 : 0,
                    item_type_id: selectedSubpart,
                  }]}
                })]
              }
            })
          }
        }
      })]
    }}))

    
    
    SetSelectingSubpartForPart(undefined)
    SetSelectingSubpart(false)
  }


  const updateNutrientParts = (nutrientRecipeZones) => {
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return { ...timelineItem, item: { ...timelineItem.item, nutrient_recipe_zones: nutrientRecipeZones} }
      })]
    }}))
  }

  return (
    <>
      <div id="RecipeNutrientsPage">
        <div className="RecipeNutrientsPage-TimelineItemSelectionBar">
          <DropDownInput 
            uid="timeline_item_select" 
            prefix="Timeline Item: "
            options={timelineItemSelectionOptions} 
            value={(selectedTimelineItem !== undefined ? (selectedTimelineItem.id) : "")}
            onSelectionChange={(value) => {
              onTimelineItemSelectionChanged(value)
            }}/>
        </div>
        <div className="RecipeNutrientsPage-NutrientParts">
          {nutrientParts.length > 0 && <>
            {nutrientParts.map((part) => {
              return (
              <RecipeNutrientsPage_PartItem
                recipe={recipe}
                selectedTimelineItem={selectedTimelineItem}
                key={part.index}
                nutrientParts={nutrientParts}
                part={part}
                partIndex={part.index}
                isEditing={isEditingRecipe}
                zoneIndex={0}
                updateNutrientPartsCallback={updateNutrientParts}
                openSubpartSelectionClicked={openSubpartSelection}/>
              )
            })}
          </>}
          {nutrientParts.length === 0 && <>
            <div className="FlexContent-Center Text-S16">No Nutrient Recipe</div>
          </>}
        </div>
        {isEditingRecipe && <>
          <Button content={"Add Part"} onClick={createPart}/>        
        </>}
      </div>
    
      {selectingSubpart && 
        <SelectSubpartPopup closeCallback={closeSubpartSelectionPopup} selectionCallback={subpartSelectionCompleted}/>
      }
    </>
  )
} 

export default RecipeNutrientsPage





export const RecipeNutrientsPage_PartItem = ({recipe, selectedTimelineItem, zoneIndex, nutrientParts, part, partIndex, updateNutrientPartsCallback, openSubpartSelectionClicked, isEditing}) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);




  const ratioChanged = (ratio) =>  {
    let newRatio = ratio
    
    let totalLockedRatio = 0
    let totalAvailableRatios = 0
    for (let currentPart of nutrientParts) {
      if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
        totalAvailableRatios += 1
      }else if ((currentPart.locked !== undefined && currentPart.locked) && currentPart.index != part.index)  {
        totalLockedRatio += currentPart.percent_of
      }
    }

    if (totalAvailableRatios === 0) {
      return part.percent_of
    }

    if (newRatio < 0)  {
      newRatio = 0
    }else if (newRatio > (100 - totalLockedRatio))  {
      newRatio = (100 - totalLockedRatio)
    }

    return newRatio
  }

  const ratioChangeFinalize = (newRatio) =>  {
    let ratioChange = newRatio - part.percent_of

    if (ratioChange === 0)  {
      return
    }

    let unlockedParts = []
    let totalLockedRatio = 0
    let totalUsedRatio = 0
    for (let currentPart of nutrientParts) {
      if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
        unlockedParts.push(currentPart.index)
        totalUsedRatio += parseInt(currentPart.percent_of)
      }else if (currentPart.index != part.index) {
        totalLockedRatio += parseInt(currentPart.percent_of)
      }
    }


    
    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: timelineItem.item.nutrient_recipe_zones.map((recipeZone) => {
              if (recipeZone.zone_index !== zoneIndex)  {
                return recipeZone
              }
              return {...recipeZone,
                parts: nutrientParts.map((currentPart) =>  {
                  if ((currentPart.locked === undefined || !currentPart.locked) && currentPart.index != part.index)  {
                    if (totalUsedRatio > 0) {
                      return {...currentPart, percent_of: currentPart.percent_of - ((currentPart.percent_of / (100 - (totalLockedRatio + part.percent_of))) * ratioChange)  }
                    }else {
                      return {...currentPart, percent_of: -ratioChange / unlockedParts.length  }
                    }
                  }else if (currentPart.index === part.index) {
                    return {...currentPart, percent_of: newRatio, locked: false}
                  }
                  return currentPart
                })
              }
            })
          }
        }
      })]
    }}))
  }

  const onLockToggle = () => {
    
    
    dispatch(timelineItemChanged({recipeId: recipe.id, timelineItem: selectedTimelineItem, changes: {
      nutrient_recipe_zones: selectedTimelineItem.item.nutrient_recipe_zones.map((recipeZone) => {
        if (recipeZone.zone_index !== zoneIndex)  {
          return recipeZone
        }
        return {...recipeZone,
          parts: nutrientParts.map((currentPart) =>  {
            if (currentPart.index === part.index) {
              return {...currentPart, locked: (part.locked === undefined || !part.locked) ? true : false}
            }
            return currentPart
          })
        }
      })
    }}))
  }

  const deletePart = () =>  {
    let remainingParts = nutrientParts.filter((currentPart) =>  {
      if (currentPart.index !== part.index) {
        return currentPart
      }
    }) 

    

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: timelineItem.item.nutrient_recipe_zones.map((nutrientZone) => {
              if (nutrientZone.zone_index !== zoneIndex)  {
                return nutrientZone
              }
              return {...nutrientZone, parts: 
                remainingParts.map((currentPart) =>  {
                  return {
                    ...currentPart, 
                    percent_of: currentPart.percent_of + ((currentPart.percent_of / (100 - part.percent_of)) * part.percent_of), 
                    index: (currentPart.index > part.index) ? currentPart.index - 1 : currentPart.index
                  }
                })
              }
            })
          }
        }
      })]
    }}))
  }

  const containerProps = {}

  const addSubpartClicked = () => {
    openSubpartSelectionClicked(part)
  }
  return (
    <div className={"RecipeNutrientsPage-NutrientPart" + (isEditing ? " RecipeNutrientsPage-NutrientPartEditing" : "")}>
      {isEditing && <>
        <div className="RecipeNutrientsPage-NutrientPart-Header">
          <div className="Text-Medium-S14">Part {partIndex + 1}</div>
        </div>
        <div className="RecipeNutrientsPage-NutrientPart-Configure Linebreak-10">
          <div className="GrowZoneRecipeManager-ManageNutrients-Part-PercentOfAdjustment">
            <Button status={"Primary-Toggle"} state={part.locked} onClick={onLockToggle} content={part.locked ? <><FaLock/></> : <><FaUnlock/></>}/>
            <NumberInput value={part.percent_of} suffix="%" stepAmount={0.1} maxLength={4} onChange={ratioChanged} onBlur={ratioChangeFinalize} precision={0.1}/>
          </div>
          <Button status={"Neutral"}  onClick={deletePart} content={<FaTrashAlt/>}/>
        </div>
        <div className="RecipeNutrientsPage-NutrientPart-SubpartsList">
          {part.sub_parts.map((subpart, subpartIndex) => {
            return (
              <RecipeNutrientsPage_SubPartItem
                recipe={recipe}
                selectedTimelineItem={selectedTimelineItem}
                key={subpart.index}
                nutrientParts={nutrientParts}
                part={part}
                subpart={subpart}
                subpartIndex={subpartIndex}
                isEditing={isEditing}
                zoneIndex={zoneIndex}/>
            )
          })}
          <Button content={"Add Sub Part"} onClick={addSubpartClicked}/>
        </div>
      </>}
      {!isEditing && <>
        <div className="RecipeNutrientsPage-NutrientPart-Header">
          <div className="Text-Medium-S14">Part {partIndex + 1}</div>
          <div className="Text-S14">{part.percent_of}%</div>
        </div>
        <div className="RecipeNutrientsPage-NutrientPart-SubpartsList">
          {part.sub_parts.map((subpart, subpartIndex) => {
            return (
              <RecipeNutrientsPage_SubPartItem
                recipe={recipe}
                selectedTimelineItem={selectedTimelineItem}
                key={subpart.index}
                nutrientParts={nutrientParts}
                part={part}
                subpart={subpart}
                subpartIndex={subpartIndex}
                isEditing={isEditing}
                zoneIndex={zoneIndex}/>
            )
          })}
        </div>
      </>}
    </div>
  )
}



const RecipeNutrientsPage_SubPartItem = ({recipe, selectedTimelineItem, nutrientParts, part, subpart, zoneIndex, isEditing}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop


  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);

  let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  const [inventoryItemType, SetInventoryItemType] = React.useState(undefined)
  React.useEffect(() => {
    let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === subpart.item_type_id)
    if (foundInventoryItemType === undefined) {
      dispatch(getInventoryItemTypeById({itemTypes: [subpart.item_type_id]}))
    }else {
      SetInventoryItemType(foundInventoryItemType)
    }
  }, [inventoryItemTypes])

  React.useLayoutEffect(() => {
    SetInventoryItemType(inventoryItemTypes.find((iIT) => iIT.id === subpart.item_type_id))
  }, [subpart, inventoryItemTypes])


  const ratioChanged = (ratio) =>  {
    let newRatio = ratio
    
    let totalLockedRatio = 0
    let totalAvailableRatios = 0
    for (let currentSubpart of part.sub_parts) {
      if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
        totalAvailableRatios += 1
      }else if ((currentSubpart.locked !== undefined && currentSubpart.locked) && currentSubpart.index != subpart.index)  {
        totalLockedRatio += currentSubpart.percent_of
      }
    }

    if (totalAvailableRatios === 0) {
      return subpart.percent_of
    }

    if (newRatio < 0)  {
      newRatio = 0
    }else if (newRatio > (100 - totalLockedRatio))  {
      newRatio = (100 - totalLockedRatio)
    }

    return newRatio
  }




  const ratioChangeFinalize = (newRatio) =>  {
    let ratioChange = newRatio - subpart.percent_of

    if (ratioChange === 0)  {
      return
    }

    let unlockedParts = []
    let totalLockedRatio = 0
    let totalUsedRatio = 0
    for (let currentSubpart of part.sub_parts) {
      if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
        unlockedParts.push(currentSubpart.index)
        totalUsedRatio += parseInt(currentSubpart.percent_of)
      }else if (currentSubpart.index != subpart.index) {
        totalLockedRatio += parseInt(currentSubpart.percent_of)
      }
    }


    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: timelineItem.item.nutrient_recipe_zones.map((nutrientZone) => {
              if (nutrientZone.zone_index !== zoneIndex)  {
                return nutrientZone
              }
              return {...nutrientZone, 
                parts: nutrientParts.map((currentPart) =>  {
                  if (currentPart.index === part.index) {
                    return {...currentPart, sub_parts: part.sub_parts.map((currentSubpart) => {
                      if ((currentSubpart.locked === undefined || !currentSubpart.locked) && currentSubpart.index != subpart.index)  {
                        if (totalUsedRatio > 0) {
                          return {...currentSubpart, percent_of: currentSubpart.percent_of - ((currentSubpart.percent_of / (100 - (totalLockedRatio + subpart.percent_of))) * ratioChange)  }
                        }else {
                          return {...currentSubpart, percent_of: -ratioChange / unlockedParts.length  }
                        }
                      }else if (currentSubpart.index === subpart.index) {
                        return {...currentSubpart, percent_of: newRatio, locked: false}
                      }
                      return currentSubpart
                    })}
                  }
                  return currentPart
                })
              }
            })
          }
        }
      })]
    }}))

  }

  const onLockToggle = () => {

    dispatch(timelineItemChanged({recipeId: recipe.id, timelineItem: selectedTimelineItem, changes: {
      nutrient_recipe_zones: selectedTimelineItem.item.nutrient_recipe_zones.map((recipeZone) => {
        if (recipeZone.zone_index !== zoneIndex)  {
          return recipeZone
        }
        return {...recipeZone,
          parts: nutrientParts.map((currentPart) =>  {
            if (currentPart.index === part.index) {
              return {...currentPart, sub_parts: part.sub_parts.map((currentSubpart) => {
                if (currentSubpart.index === subpart.index) {
                  return {...currentSubpart, locked: (subpart.locked === undefined || !subpart.locked) ? true : false}
                }
                return currentSubpart
              })}
            }
            return currentPart
          })
        }
      })
    }}))
  }

  const deleteSubpart = () =>  {
    let remainingSubparts = part.sub_parts.filter((currentSubpart) =>  {
      if (currentSubpart.index !== subpart.index) {
        return currentSubpart
      }
    })

    dispatch(pushRecipeChange({recipe: {...recipe, 
      timeline_items: [...recipe.timeline_items.map((timelineItem) => {
        if (timelineItem.id != selectedTimelineItem.id) {
          return timelineItem
        }
        return {
          ...timelineItem,
          item: {
            ...timelineItem.item,
            nutrient_recipe_zones: timelineItem.item.nutrient_recipe_zones.map((nutrientZone) => {
              if (nutrientZone.zone_index !== zoneIndex)  {
                return nutrientZone
              }
              return {...nutrientZone, 
                parts: nutrientParts.map((currentPart) =>  {
                  if (currentPart.index === part.index) {
                    return {...currentPart, sub_parts: remainingSubparts.map((currentSubpart) => {
                      return {
                        ...currentSubpart, 
                        percent_of: currentSubpart.percent_of + ((currentSubpart.percent_of / (100 - subpart.percent_of)) * subpart.percent_of), 
                        index: (currentSubpart.index > subpart.index) ? currentSubpart.index - 1 : currentSubpart.index
                      }
                    })}
                  }
                  return currentPart
                })
              }
            })              
          }
        }
      })]
    }}))
  }

  const containerProps = {}


  return (
    <div key={subpart.index}
      /*ref={containerRef}*/
      className={"RecipeNutrientsPage-NutrientSubPart" + (isEditing ? " RecipeNutrientsPage-NutrientSubPartEditing" : "")}
      {...containerProps}>
      {isEditing && <>
        <div className="RecipeNutrientsPage-NutrientSubPart-Content">
          {isMobile && <>
            <div className="Text-Hyperlink">
              {inventoryItemType ? inventoryItemType.name : "Subpart"}
            </div>            
          </>}
          <div className="RecipeNutrientsPage-NutrientSubPart-Content-Controls">
            <div className="RecipeNutrientsPage-NutrientSubPart-Content-Inputs">
              <Button status={"Primary-Toggle"} state={subpart.locked} onClick={onLockToggle} content={subpart.locked ? <><FaLock/></> : <><FaUnlock/></>}/>
              <NumberInput value={subpart.percent_of} stepAmount={0.1} suffix="%" maxLength={4} onChange={ratioChanged} onBlur={ratioChangeFinalize} precision={0.1}/>
              {!isMobile && <>
                <div className="Text-Hyperlink">
                  {inventoryItemType ? inventoryItemType.name : "Subpart"}
                </div>            
              </>}
            </div>
            <Button status={"Neutral"} onClick={deleteSubpart} content={<FaTrashAlt/>}/>
          </div>
        </div>
      </>}

      {!isEditing && <>
          <div className="Text-S14">{subpart.percent_of}%</div>
          <div className="Text-S14">{inventoryItemType ? inventoryItemType.name : "Subpart"}</div>
      </>}
      
    </div>
  )
}