import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'


const processRaftTypeFromAPI = (raftType) =>  {
  
  return raftType
}

export const getAllRaftTypes = createAsyncThunk('raftTypes/getAllRaftTypes', async ({}, { getState }) => {
  const state = getState()
  return await FetchPost(APIRepository.RaftTypes.GetAllRaftTypesForAccount, {
    account_id: state.account.id,
    session_token: state.account.sessionToken,
    have_raft_types: {}
  })  
})


export const getRaftTypeById = createAsyncThunk('growPlans/getRaftTypeById', async ({raftTypeId, raftTypeIds}, { getState }) => {
  const state = getState()
  let payload = {
    account_id: state.account.id,
    session_token: state.account.sessionToken,
  }
  if (raftTypeId !== undefined) {
    payload.raft_type_id = raftTypeId
  }
  if (raftTypeIds !== undefined) {
    payload.raft_type_ids = raftTypeIds
  }
  return await FetchPost(APIRepository.RaftTypes.GetRaftTypeById, payload)  
})


export const raftTypesSlice = createSlice({
  name: 'raftTypes',
  initialState: {
    raftTypes:  [
      {
        id: 1,
        name: "Seedling Board Gen 1",
        info: {
          number_of_plant_sites: 72,
        },
        width: 20,
        length: 10,
        application_type: "seedling"
      },
      {
        id: 2,
        name: "Grow Board Gen 1",
        info: {
          number_of_plant_sites: 48,
        },
        width: 25,
        length: 20,
        application_type: "grow_board"
      },
      {
          id: 3,
          name: "Bontaicor Grow Bag",
          info: {
            number_of_plant_sites: 10,
          },
          width: 25,
          length: 20,
          application_type: "grow_bag"
      },
      {
        id: 4,
        name: "Off Shelf Seedling Board",
        info: {
          number_of_plant_sites: 128,
        },
        width: 20,
        length: 10,
        application_type: "seedling"
      }
    ],
    status: 'idle',
    error: null,
    haveInitialData: false,

    haveAllRaftTypes: false,
    loadingData: false,
    loadingRaftTypesStatus: false,
    loadingRaftTypesByIds: [],
  },
  reducers: {
    

    raftTypeUpdate: (state, action) => {
      let hasChanged = false
      let newRaftTypes = { ...state, raftTypes: state.raftTypes.map((raftType, index) => {
        if (raftType.id !== action.payload.raftTypeId) {
          return raftType
        }
    
        if (raftType[action.payload.prop] === undefined || raftType[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...raftType,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newRaftTypes
      }
    }
  },
  extraReducers: {
    [getAllRaftTypes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllRaftTypes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveAllRaftTypes = true
      action.payload.raftTypes.map(function(raftType){ processRaftTypeFromAPI(raftType); return raftType });
      console.log(action.payload.raftTypes)
      state.raftTypes = action.payload.raftTypes;
    },

    [getAllRaftTypes.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getRaftTypeById.pending]: (state, action) => {
      state.loadingRaftTypesStatus = 'pending';
      if (action.meta.arg.raftTypeId !== undefined) {
        if (state.loadingRaftTypesByIds.indexOf(action.meta.arg.raftTypeId) === -1) {
          state.loadingRaftTypesByIds.push(action.meta.arg.raftTypeId)
        }
      }
      if (action.meta.arg.raftTypeIds !== undefined) {
        for (let raftTypeId of action.meta.arg.raftTypeIds) {
          if (state.loadingRaftTypesByIds.indexOf(raftTypeId) === -1) {
            state.loadingRaftTypesByIds.push(raftTypeId)
          }
        }
      }
    },

    [getRaftTypeById.fulfilled]: (state, action) => {
      state.loadingRaftTypesStatus = 'fulfilled';
      if (action.payload.raft_type !== null) {
        state.raftTypes.push(processRaftTypeFromAPI(action.payload.raft_type))
        
        const loadingRaftTypeIndex = state.loadingRaftTypesByIds.indexOf(action.payload.raft_type.id)
        if (loadingRaftTypeIndex !== -1) {
          state.loadingRaftTypesByIds.splice(loadingRaftTypeIndex, 1)
        }

      }
      if (action.payload.raft_types !== null) {
        for (let raftType of action.payload.raft_types)  {
          state.raftTypes.push(processRaftTypeFromAPI(raftType))
          const loadingRaftTypeIndex = state.loadingRaftTypesByIds.indexOf(raftType.id)
          if (loadingRaftTypeIndex !== -1) {
            state.loadingRaftTypesByIds.splice(loadingRaftTypeIndex, 1)
          }
        }
      }
    },

    [getRaftTypeById.rejected]: (state, action) => {
      state.loadingRaftTypesStatus = 'rejected';

      if (action.meta.arg.raftTypeId !== undefined) {
        const loadingRaftTypeIndex = state.loadingRaftTypesByIds.indexOf(action.meta.arg.raftTypeId)
        if (loadingRaftTypeIndex === -1) {
          state.loadingRaftTypesByIds.splice(loadingRaftTypeIndex, 1)
        }
      }
      if (action.meta.arg.raftTypeIds !== undefined) {
        for (let raftTypeId of action.meta.arg.raftTypeIds) {
          const loadingRaftTypeIndex = state.loadingRaftTypesByIds.indexOf(raftTypeId)
          if (loadingRaftTypeIndex === -1) {
            state.loadingRaftTypesByIds.splice(loadingRaftTypeIndex, 1)
          }
          }
      }
    },
  }
})



// Action creators are generated for each case reducer function
export const { raftTypeUpdate} = raftTypesSlice.actions

export default raftTypesSlice.reducer

export const selectAllRaftTypes = state => state.raftTypes.raftTypes

export const selectRaftTypeById = (state, raftTypeId) =>
  state.raftTypes.raftTypes.find(raftType => raftType.id === raftTypeId)