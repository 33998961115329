import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/GrowSeeds';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { GenericLeaf } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';


const HarvestTask_ContainerWeightSubstepWalkthroughStep = ({
        allPlantVarieties,
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerOption,
        containerContents,
        containerWeight,
        containerSubstepIndex,
        containerContentsSubstepCallback,
        containers,
        containersListConfirmedCallback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        if (containerSubstepIndex === 1)  {
            transitionToPreviousStep(["container_weight", null])
        }else {
            containerContentsSubstepCallback(containerSubstepIndex -= 1)
            transitionToPreviousStep(["container_weight_substep", null])
        }
    }
    const nextClicked = () => {
        if (containerSubstepIndex < activeGrowPlan.plant_variants.length - 1)   {
            containerContentsSubstepCallback(containerSubstepIndex += 1)
            transitionToNextStep(["container_weight_substep", null])
        }else {
            containersListConfirmedCallback({containers: [...containers, {
                weight: containerWeight,
                contents: containerContents,
                option: containerOption,
                number_of: 1
            }]})
            transitionToNextStep(["container_list"], null)
        }
    }



    let foundPlantVariety = undefined
    if (activeGrowPlan !== undefined && containerSubstepIndex >= 1) {
        foundPlantVariety = allPlantVarieties.find((pT) => pT.id === activeGrowPlan.plant_variants[containerSubstepIndex].plant_variety_id)
    }

    //let activeGrowPlanTest = {plant_variants: [1,2,3]}
    let finalContainerWeight = 0
    for (let index in activeGrowPlan.plant_variants)  {
        if (index <= containerSubstepIndex) {
            finalContainerWeight += containerWeight * (activeGrowPlan.plant_variants[index].ratio / 100)
        }
    }
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    {activeGrowPlan.plant_variants.length > 2 && <>
                        {containerSubstepIndex + 1}/{activeGrowPlan.plant_variants.length}&nbsp;-&nbsp;
                    </>}
                    Add the {foundPlantVariety !== undefined ? foundPlantVariety.name : " variant"}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{flex: 1}}>
                    <div className={(isMobile ? "FlexContent-40" : "FlexContent-60") + " FlexContent-VCenter"} style={{padding: isMobile ? "40px 10px" : "0px 40px", flex: 1}}>
                        <div className={(isMobile ? "FlexContent-20" : "FlexContent-H-30") + " FlexContent-Center"} style={{alignSelf: "flex-start"}}>
                            <GenericLeaf fill={"#262A31"} width={isMobile ? 42 : 60}/>
                            <div className="FlexContent-10">
                                <div className="Text-S16">Step 1/2</div>
                                <div className="Text-S24">Add <span>{containerWeight * (activeGrowPlan.plant_variants[containerSubstepIndex].ratio / 100)}kg</span> of {foundPlantVariety !== undefined ? foundPlantVariety.name : " variant"}</div>
                            </div>
                        </div>
                        <div className={(isMobile ? "FlexContent-20" : "FlexContent-H-30") + " FlexContent-Center"}  style={{alignSelf: "flex-start"}}>
                            <GenericLeaf fill={"#262A31"} width={isMobile ? 42 : 60}/>
                            <div className="FlexContent-10">
                                <div className="Text-S16">Step 2/2</div>
                                <div className="Text-S24">So that the container weight is <span>{finalContainerWeight}kg</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ContainerWeightSubstepWalkthroughStep