import './CreateBladeWalkthrough.scss';
import React from 'react';
import { selectAllBladeGroups, selectAllBlades } from '../../../redux/entities/service/Blade';
import { useSelector } from 'react-redux';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import SelectBladeSerialNumberWalkthroughStep from './CreateBladeWalkthroughSteps/SelectBladeSerialNumberWalkthroughStep';
import ConfirmCreateBladeWalkthroughStep from './CreateBladeWalkthroughSteps/ConfirmCreateBladeWalkthroughStep';
import SelectControlBoardWalkthroughStep from './LinkBladeToBoardWalkthroughSteps/SelectControlBoardWalkthroughStep';
import BluetoothPairToControlBoardWalkthroughStep from './LinkBladeToBoardWalkthroughSteps/BluetoothPairToControlBoardWalkthroughStep';


  

const LinkBladeToBoardWalkthrough = ({facilityId, forBlade, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }


    const [controlDeviceSerialNumber, SetControlBoardSerialNumber] = React.useState("")



    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["select_control_device", null])

    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }

    

    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "select_control_device":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            
            case "bluetooth_pairing":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true



    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "select_control_device":
                const controlDeviceSerialNumberSelectedCallback = ({controlDeviceSerialNumber}) =>  {
                    SetControlBoardSerialNumber(controlDeviceSerialNumber)
                }
                return (<SelectControlBoardWalkthroughStep
                            initialSelectedControlDeviceSerialNumber={controlDeviceSerialNumber} 
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            cancelCallback={closePopup}
                            controlDeviceSerialNumberSelectedCallback={controlDeviceSerialNumberSelectedCallback}/>)
            
            case "bluetooth_pairing":
                
                return (<BluetoothPairToControlBoardWalkthroughStep
                            connectionStep={subStepKey}
                            blade={forBlade}
                            controlDeviceSerialNumber={controlDeviceSerialNumber}
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            cancelCallback={closePopup}
                            finishedLinkingCallback={closePopup}/>)
                

            default:
                return <></>
        }
    }


    return (<>
        <Walkthrough
            id={"CreateBladeWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Link Blade to Control Board</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["select_control_device", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)

}



export default LinkBladeToBoardWalkthrough