import './CRMPage.scss';

import React from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'




const CRM_CatalogPage = ({setPageHeader}) => {

  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])

  let data = useSelector((state) => state.products)
  let products = data.rows
  const dispatch = useDispatch()

  const columns: GridColDef[] = [
    { accessor: 'icon', Header: '', width: "20", isResizable: false},
    { accessor: 'name', Header: 'Name', minWidth: "80", width: "120", isSortable: true},
    { accessor: 'category', Header: 'Category', minWidth: "80", width: "120", isSortable: true},
    { accessor: 'subCategory', Header: 'Subcategory', minWidth: "80", width: "140", isSortable: true},
    { accessor: 'status', Header: 'Status', minWidth: "80", width: "150", isSortable: true, Cell: ({ row }) => {
      let statusMode = "Neutral";
      if (row.original !== undefined) {
        statusMode = row.original.statusMode;
      }
      return(
        <div>
          <Badge status={statusMode} content={row.values.status} size="Medium"/>
        </div>
      )}, },
    { accessor: 'unit', Header: 'Unit', minWidth: "25"},  
  ];


  let navigate = useNavigate(); 
  const productClicked = (product) => {
    navigate("/crm/product/" + product.id);
  }

  return (
    <div id="CRM_CatalogPage">
        <div className="ControlBar_Horizontal">
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Customers' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'status', label: 'Status' }]} defaultValue="status"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
        </div>
        <StandardTable 
        id="CatalogTable" 
        data={products} 
        columns={columns} 
        isSelectableRows={true} 
        isResizable={true}
        onRowClicked={(row) => {productClicked(row.original)}}/>
    </div>
  )
} 

export default CRM_CatalogPage