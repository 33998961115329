//import './PageHeader.scss';
import React from 'react'


const ReportsPage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Reports")
  }, [pageHeaderHelper])
  return (
    <>
      Reports
    </>
  )
} 

export default ReportsPage