import './RevenuePage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'




const data = [
  { id: 1, name: 'Some Item 1', cost: 35, category: "Produce", revenue_date: "Mon Jul 05 2021" },
  { id: 2, name: 'Some Item 2', cost: 42, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
  { id: 3, name: 'Some Item 3', cost: 45, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
  { id: 4, name: 'Some Item 4', cost: 16, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
  { id: 5, name: 'Some Item 5', cost: null, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
  { id: 6, name: 'Some Item 6', cost: 150, category: "Recipe", revenue_date: "Mon Jul 05 2021"  },
  { id: 7, name: 'Some Item 7', cost: 44, category: "Recipe", revenue_date: "Mon Jul 05 2021"  },
  { id: 8, name: 'Some Item 8', cost: 36, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
  { id: 9, name: 'Some Item 9', cost: 65, category: "Produce", revenue_date: "Mon Jul 05 2021"  },
];



const RevenuePage = ({setPageHeader}) => {
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])

  const columns: GridColDef[] = [
    { accessor: 'name', Header: 'Name', minWidth: "120", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'cost', Header: 'Cost', type: 'number', align:"left", headerAlign:"left", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'category', Header: 'Category', minWidth: "120", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<Map>) => (
      <Badge status="Neutral" content={params.value} size="Medium"/>
    ), },
    { accessor: 'revenue_date', Header: 'Date Of Purchase', type: 'date', width: "200", headerClassName: 'DataTable_Standard-Header' },
  
  ];
  
  return (
    <div id="RevenuePage">
        <div className="ControlBar_Horizontal"> 
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'date', label: 'Date' }]} defaultValue="date" prefix="Sort By"/>
        </div>
        <StandardTable id="RevenueTable" data={data} columns={columns} multiSelect="true"/>
    </div>
  )
} 

export default RevenuePage