import {BsList, BsFillPersonFill} from 'react-icons/bs'

import React, { memo, useCallback, useState, useEffect, useLayoutEffect, useReducer } from 'react';

import { IconContext } from "react-icons";
import { useNavigate, Link, useLocation } from 'react-router-dom'

import companyLogo from '../crclr.jpg';

import { animated, useSpring } from 'react-spring'
import { useMeasure } from '../helpers'

import { useMediaQuery } from 'react-responsive'

import {SideNavigatorMainLinks, SideNavigatorBottomLinks} from "./PageLink"
import './SideNavigator.scss';
import { CRCLR_FullLogo } from '../assets/icons/Icons';
import { useSelector } from 'react-redux';
import Button from './Button';

export const DefaultActivePage = SideNavigatorMainLinks[0];





export const MobileSideNavigator = ({primaryLocation, secondaryLocation, NavigationState, mainLinks, CloseNavigation, ToggleNavigationState, LinkToProfileSettings}) => {
    const navigate = useNavigate();


    const loggedInState = useSelector((state) => state.account.loggedInState)
    const accountData = useSelector((state) => state.account.data)
    const loginClicked = () => {
        navigate("/account/login")
    }

    useLayoutEffect(() => {
        if (NavigationState)    {
            CloseNavigation()
        }
    }, [])

    const [navigationBind, { width: navigationWidth }] = useMeasure()
    const navigationAreaAnimation = useSpring({
        from: { width: 0},
        to: { width: NavigationState ? navigationWidth : 0},
        config: {duration: 100}
        
    })
    const [navigationLinkBind, { width: navigationLinkWidth }] = useMeasure()

    return (<>
        <div className={"SideNavigation-BackgroundOverlay" + (NavigationState === true ? " active" : "")} onClick={CloseNavigation}></div>
        <animated.div className={"SideNavigation SideNavigation-Mobile" + (NavigationState === false ? " collapsed" : " expanded")} style={navigationAreaAnimation}>
            <div className="SideNavigation-Container" {...navigationBind}>
                <div className="SideNavigation-Content">
                    <div className="SideNavigation-Header">
                        <div className="SideNavigation-Header-Brand">
                            <CRCLR_FullLogo/>

                        </div>
                        <div className="SideNavigation-Header-Toggle" onClick={ToggleNavigationState}>
                            <svg className={"ham hamRotate ham1" + (NavigationState === true ? " active": "")} viewBox="0 0 100 100" width="30px">
                                <path className="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                                <path className="line middle" d="m 30,50 h 40" />
                                <path className="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                            </svg>
                        </div>
                    </div>

                    <div className="SideNavigation-LinksContainers">
                        <div className="SideNavigation-Links">
                            {mainLinks.map((navigationLink) => {
                                const linkClicked = (e) =>  {
                                    //navigationLink.path
                                    navigate(navigationLink.path);
                                    CloseNavigation()
                                }
                                let isActiveLink = (primaryLocation === navigationLink.path)
                                let iconProps = {}
                                if (isActiveLink)   {
                                    iconProps.fill = "#2E72D2"
                                }
                                return (
                                    <div className={"SideNavigation-Link" + (primaryLocation === navigationLink.path ? " active": "")} onClick={linkClicked} key={navigationLink.path}>
                                        <div className="SideNavigation-Link-Icon">
                                            <navigationLink.icon {...iconProps}/>
                                        </div>
                                        <div className="SideNavigation-Link-Title">
                                            {navigationLink.path === "repo" && 
                                                <span {...navigationLinkBind}>
                                                    {navigationLink.title}
                                                </span>
                                                }
                                            {navigationLink.path !== "repo" && 
                                                <span>
                                                    {navigationLink.title}
                                                </span>                                            
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    
                    {loggedInState && <>
                        <div className={"SideNavigation-ProfileLink" + ((primaryLocation === "settings" && secondaryLocation === "account") ? " active": "")} onClick={LinkToProfileSettings}>
                            <div className="SideNavigation-ProfileLink-Icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="12.5" fill="white" stroke="#191C22"/>
                                </svg> 
                            </div>
                            <div className="SideNavigation-ProfileLink-TitleContainer">
                                <div className="SideNavigation-ProfileLink-Title" style={{width:navigationLinkWidth}}>
                                    <span>
                                        {accountData.full_name}
                                    </span>                                            
                                    <span>
                                        Owner
                                    </span>
                                </div>                                  
                            </div>
                        </div>
                    </>}
                    {!loggedInState && <>
                        <div className={"SideNavigation-ProfileLink"} onClick={loginClicked}>
                            <div className="SideNavigation-ProfileLink-TitleContainer">
                                <div className="SideNavigation-ProfileLink-Title" style={{width:navigationLinkWidth}}>
                                    <div className="Text-Hyperlink">Log In</div>
                                </div>                                  
                            </div>
                        </div>
                    </>}
                </div>
            </div>                
        </animated.div>
    </>)
}





export const TabletSideNavigator = ({primaryLocation, secondaryLocation, NavigationState, mainLinks, CloseNavigation, ToggleNavigationState, LinkToProfileSettings}) => {
    const navigate = useNavigate();

    const loggedInState = useSelector((state) => state.account.loggedInState)
    const accountData = useSelector((state) => state.account.data)
    const loginClicked = () => {
        navigate("/account/login")
    }

    useLayoutEffect(() => {
        if (NavigationState)    {
            CloseNavigation()
        }
    }, [])


    const [navigationBind, { width: navigationWidth }] = useMeasure()
    const navigationAreaAnimation = useSpring({
        from: { width: 0},
        to: { width: NavigationState ? navigationWidth : 0},
        config: {duration: 100}
        
    })

    const [headerBrandBind, { width: headerBrandWidth }] = useMeasure()

    const headerBrandAnimation = useSpring({
        from: { width: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0},
        to: { width: NavigationState ? headerBrandWidth : 0, paddingLeft: NavigationState ? 20 : 0, paddingRight: NavigationState ? 20 : 0, paddingTop: NavigationState ? 12 : 0, paddingBottom: NavigationState ? 12 : 0, },
        config: {duration: 100}
    })


    const [navigationLinkBind, { width: navigationLinkWidth }] = useMeasure()
    const navigationLinkAnimation = useSpring({
        from: { width: 0},
        to: { width: NavigationState ? navigationLinkWidth : 0},
        config: {duration: 100}
    })

    const [closedNavigationWidth, SetClosedNavigationWidth] = useState(54)
    const [isClosedNavigationWidthCalculated, SetIsClosedNavigationWidthCalculated] = useState(false)
    useLayoutEffect(() => {
        if (!NavigationState && !isClosedNavigationWidthCalculated)   {
            if (navigationWidth > 0)    {
                SetClosedNavigationWidth(navigationWidth)
                SetIsClosedNavigationWidthCalculated(true)
            }
        }
    }, [NavigationState, navigationWidth, isClosedNavigationWidthCalculated])


    return (<>
        <div className="SideNavigation-Tablet-Reserve-Space" style={{width: closedNavigationWidth}}></div>
        <div className={"SideNavigation-BackgroundOverlay" + (NavigationState === true ? " active" : "")} onClick={CloseNavigation}></div>
        <div className={"SideNavigation SideNavigation-Tablet" + (NavigationState === false ? " collapsed" : " expanded")}>
            <div className="SideNavigation-Container" {...navigationBind}>
                <div className="SideNavigation-Content">
                    <div className="SideNavigation-Header">
                        <animated.div className="SideNavigation-Header-Brand" style={headerBrandAnimation}>
                            {/*<span {...headerBrandBind}>
                                CRCLR
                            </span>*/}
                            <span {...headerBrandBind}>
                                <CRCLR_FullLogo/>
                            </span>
                        </animated.div>
                        <div className="SideNavigation-Header-Toggle" onClick={ToggleNavigationState}>
                            <svg className={"ham hamRotate ham1" + (NavigationState === true ? " active": "")} viewBox="0 0 100 100" width="30px">
                                <path className="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                                <path className="line middle" d="m 30,50 h 40" />
                                <path className="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                            </svg>
                        </div>
                    </div>

                    <div className="SideNavigation-LinksContainers">
                        <div className="SideNavigation-Links">
                            {mainLinks.map((navigationLink) => {
                                const linkClicked = (e) =>  {
                                    //navigationLink.path
                                    navigate("/" + navigationLink.path);
                                    CloseNavigation()
                                }
                                let isActiveLink = (primaryLocation === navigationLink.path)
                                let iconProps = {}
                                if (isActiveLink)   {
                                    iconProps.fill = "#2E72D2"
                                }
                                return (
                                    <div className={"SideNavigation-Link" + (primaryLocation === navigationLink.path ? " active": "")} onClick={linkClicked} key={navigationLink.path}>
                                        <div className="SideNavigation-Link-Icon">
                                            <navigationLink.icon {...iconProps}/>
                                        </div>
                                        <animated.div className="SideNavigation-Link-Title" style={navigationLinkAnimation}>
                                            {navigationLink.path === "repo" && 
                                                <span {...navigationLinkBind}>
                                                    {navigationLink.title}
                                                </span>
                                                }
                                            {navigationLink.path !== "repo" && 
                                                <span>
                                                    {navigationLink.title}
                                                </span>                                            
                                            }
                                        </animated.div>
                                        
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {loggedInState && <>
                        <div className={"SideNavigation-ProfileLink" + ((primaryLocation === "settings" && secondaryLocation === "account") ? " active": "")} onClick={LinkToProfileSettings}>
                            <div className="SideNavigation-ProfileLink-Icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="12.5" fill="white" stroke="#191C22"/>
                                </svg> 
                            </div>
                            <animated.div className="SideNavigation-ProfileLink-TitleContainer" style={navigationLinkAnimation}>
                                <div className="SideNavigation-ProfileLink-Title">
                                    <span>
                                        {accountData.full_name}
                                    </span>                                            
                                    <span>
                                        Owner
                                    </span>
                                </div>                                  
                            </animated.div>
                        </div>
                    </>}
                    {!loggedInState && <>
                        <div className={"SideNavigation-ProfileLink"} onClick={loginClicked}>
                            <animated.div className="SideNavigation-ProfileLink-TitleContainer" style={navigationLinkAnimation}>
                                <div className="SideNavigation-ProfileLink-Title">
                                    <div className="Text-Hyperlink">Log In</div>
                                </div>                                  
                            </animated.div>
                        </div>
                    </>}

                </div>
            </div>                
        </div>
    </>)
}

export const DesktopSideNavigator = ({primaryLocation, secondaryLocation, NavigationState, mainLinks, CloseNavigation, ToggleNavigationState, LinkToProfileSettings}) => {
    const navigate = useNavigate();

    const loggedInState = useSelector((state) => state.account.loggedInState)
    const accountData = useSelector((state) => state.account.data)
    const loginClicked = () => {
        navigate("/account/login")
    }

    useLayoutEffect(() => {
        if (NavigationState)    {
            CloseNavigation()
        }
    }, [])

    
    const [navigationBind, { width: navigationWidth }] = useMeasure()
    const navigationAreaAnimation = useSpring({
        from: { width: 0},
        to: { width: NavigationState ? navigationWidth : 0},
        config: {duration: 100}
        
    })

    const [headerBrandBind, { width: headerBrandWidth }] = useMeasure()

    const headerBrandAnimation = useSpring({
        from: { width: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0},
        to: { width: NavigationState ? headerBrandWidth : 0, paddingLeft: NavigationState ? 20 : 0, paddingRight: NavigationState ? 20 : 0, paddingTop: NavigationState ? 12 : 0, paddingBottom: NavigationState ? 12 : 0, },
        config: {duration: 100}
    })

    const [navigationLinkBind, { width: navigationLinkWidth }] = useMeasure()
    const navigationLinkAnimation = useSpring({
        from: { width: 0},
        to: { width: NavigationState ? navigationLinkWidth : 0},
        config: {duration: 100}
    })




    return (<>
        <div className={"SideNavigation SideNavigation-Desktop" + (NavigationState === false ? " collapsed" : " expanded")} >
            <div className="SideNavigation-Container" {...navigationBind}>
                <div className="SideNavigation-Content">
                    <div className="SideNavigation-Header">
                        <animated.div className="SideNavigation-Header-Brand" style={headerBrandAnimation}>
                            <span {...headerBrandBind}>
                                <span {...headerBrandBind}>
                                    <CRCLR_FullLogo/>
                                </span>
                            </span>
                        </animated.div>
                        <div className="SideNavigation-Header-Toggle" onClick={ToggleNavigationState}>
                            <svg className={"ham hamRotate ham1" + (NavigationState === true ? " active": "")} viewBox="0 0 100 100" width="30px">
                                <path className="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                                <path className="line middle" d="m 30,50 h 40" />
                                <path className="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                            </svg>
                        </div>
                    </div>

                    <div className="SideNavigation-LinksContainers">
                        <div className="SideNavigation-Links">
                            {mainLinks.map((navigationLink) => {
                                const linkClicked = (e) =>  {
                                    //navigationLink.path
                                    navigate("/" + navigationLink.path);
                                    //CloseNavigation()
                                }
                                let isActiveLink = (primaryLocation === navigationLink.path)
                                let iconProps = {}
                                if (isActiveLink)   {
                                    iconProps.fill = "#2E72D2"
                                }

                                return(
                                    <div className={"SideNavigation-Link" + (isActiveLink ? " active": "")} onClick={linkClicked} key={navigationLink.path}>
                                        <div className="SideNavigation-Link-Icon">
                                            <navigationLink.icon {...iconProps}/>
                                        </div>
                                        <animated.div className="SideNavigation-Link-Title" style={navigationLinkAnimation}>
                                            {navigationLink.path === "repo" && 
                                                <span {...navigationLinkBind}>
                                                    {navigationLink.title}
                                                </span>
                                                }
                                            {navigationLink.path !== "repo" && 
                                                <span>
                                                    {navigationLink.title}
                                                </span>                                            
                                            }
                                        </animated.div>
                                        
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    

                    {loggedInState && <>
                        <div className={"SideNavigation-ProfileLink" + ((primaryLocation === "settings" && secondaryLocation === "account") ? " active": "")} onClick={LinkToProfileSettings}>
                            <div className="SideNavigation-ProfileLink-Icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="12.5" fill="white" stroke="#191C22"/>
                                </svg> 
                            </div>
                            <animated.div className="SideNavigation-ProfileLink-TitleContainer" style={navigationLinkAnimation}>
                                <div className="SideNavigation-ProfileLink-Title">
                                    <span>
                                        {accountData.full_name}
                                    </span>                                            
                                    <span>
                                        Owner
                                    </span>
                                </div>                                  
                            </animated.div>
                        </div>
                    </>}
                    {!loggedInState && <>
                        <div className={"SideNavigation-ProfileLink"} onClick={loginClicked}>
                            <animated.div className="SideNavigation-ProfileLink-TitleContainer" style={navigationLinkAnimation}>
                                <div className="SideNavigation-ProfileLink-Title">
                                    <div className="Text-Hyperlink">Log In</div>
                                </div>                                  
                            </animated.div>
                        </div>
                    </>}
                </div>
            </div>                
        </div>
    </>)
}





export const SideNavigator = ({helperFunctionCallback}) => {
    const [NavigationState, SetNavigationState] = useState(false);
    
    
    
    const isDesktop = useMediaQuery({ minWidth: 1081 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1080 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    React.useLayoutEffect(() => {
        if (isDesktop)  {
            SetNavigationState(true)
        }
    }, [])

    const ToggleNavigationState = useCallback((state) => {
        SetNavigationState(!NavigationState);
        resetHelperFunctions()
    });

    const CloseNavigation = useCallback(() => {
        SetNavigationState(false);
        resetHelperFunctions()
    })


    
    const resetHelperFunctions = () =>  {
        if (helperFunctionCallback !== undefined) {
            helperFunctionCallback({
                ToggleNavigation: () =>  {
                    ToggleNavigationState()
                },
            })
        }
    }
    resetHelperFunctions()


    const navigate = useNavigate();
    const LinkToProfileSettings = useCallback(
        () => {
          navigate("/settings/account", { replace: true });
          if (!isDesktop)   {
            CloseNavigation()
          }
        },
        [navigate] 
    );



    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const primaryLocation = splitLocation[1]
    const secondaryLocation = splitLocation[2]



    const [isLoggingIn, SetIsLoggingIn] = React.useState(false)
    const [isRegistering, SetIsRegistering] = React.useState(false)
    const switchFromLoginToRegister = () => {
        SetIsLoggingIn(false)
        SetIsRegistering(true)
    }

    const mainLinks = SideNavigatorMainLinks()


    return <>
        {isMobile &&
            <MobileSideNavigator 
                primaryLocation={primaryLocation}
                secondaryLocation={secondaryLocation}
                NavigationState={NavigationState}
                mainLinks={mainLinks}
                CloseNavigation={CloseNavigation}
                ToggleNavigationState={ToggleNavigationState}
                LinkToProfileSettings={LinkToProfileSettings}
                loginCallback={() => {SetIsLoggingIn(true)}}/> 
        }
        {isTablet && 
            <TabletSideNavigator 
                primaryLocation={primaryLocation}
                secondaryLocation={secondaryLocation}
                NavigationState={NavigationState}
                mainLinks={mainLinks}
                CloseNavigation={CloseNavigation}
                ToggleNavigationState={ToggleNavigationState}
                LinkToProfileSettings={LinkToProfileSettings}
                loginCallback={() => {SetIsLoggingIn(true)}}/>  
        }
        {isDesktop &&
            <DesktopSideNavigator 
                primaryLocation={primaryLocation}
                secondaryLocation={secondaryLocation}
                NavigationState={NavigationState}
                mainLinks={mainLinks}
                CloseNavigation={CloseNavigation}
                ToggleNavigationState={ToggleNavigationState}
                LinkToProfileSettings={LinkToProfileSettings}
                loginCallback={() => {SetIsLoggingIn(true)}}/>  
        }

    </>
}