import './TransplantToGrowOutFromNurseryTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { NurseryRowSelectionSupportingInfoVisual, NurseryRowSelectionVisual } from '../../../../assets/icons/RackIcons';
import NumberInput from '../../../../components/input/NumberInput';
import { numberOfGrowOutRaftsPerZone } from '../../../../redux/entities/Grow';

const TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep = ({
    cancelTaskCallback,
    activeRecipe,
    activeGrowPlan,
    activeBladeType,
    activeGrowOutRaftType,
    initialMovedUnits,
    numberOfUnitsMovedConfirmed,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["select_nursery_locations", null])
    }

    const nextClicked = () =>  {
        transitionToNextStep(["review", null])
    }
    

    const [selectedNumberOfUnits, SetSelectedNumberOfUnits] = React.useState(0)
    React.useLayoutEffect(() => {
        SetSelectedNumberOfUnits(initialMovedUnits)
    }, [initialMovedUnits])

    const numberOfUnitsChanged = (amount) => {
        const newAmount = amount * activeGrowPlan.grow_out_raft_usage
        SetSelectedNumberOfUnits(newAmount)
        numberOfUnitsMovedConfirmed(newAmount)
    }

    let maxRaftsForZone = 0
    if (activeRecipe !== undefined && activeBladeType !== undefined && activeGrowOutRaftType !== undefined) {
        maxRaftsForZone = numberOfGrowOutRaftsPerZone(activeRecipe.grow_out_type, activeBladeType, activeGrowOutRaftType)
    }
    let maxNumberOfUnits = 0
    if (activeGrowPlan !== undefined)   {
        maxNumberOfUnits = maxRaftsForZone * activeGrowPlan.grow_out_raft_usage
    }

    let currentNumberOfGrowOutRafts = 0
    if (activeGrowPlan !== undefined)   {
        currentNumberOfGrowOutRafts = Math.floor(selectedNumberOfUnits / activeGrowPlan.grow_out_raft_usage)
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "20px 100px"}}>
                        
                        <div className="Text-SubHeader Text-Wrap">
                            Step 4: Indicate the number of grow board you used
                        </div>
                        
                        <div className="FlexContent-10 FlexContent-HFill">
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className="Text-SubHeader">Number of boards used</div>
                                <div className="Text-AuxSubtext">Max {maxRaftsForZone}</div>
                            </div>
                            <NumberInput 
                                flex={true} 
                                stepper={true} 
                                stepAmount={1} 
                                size={3}
                                value={currentNumberOfGrowOutRafts} 
                                onBlur={numberOfUnitsChanged}
                                min={0}
                                max={maxRaftsForZone}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep