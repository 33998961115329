
import '../GrowStatusList.scss';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { FormatDate } from '../../../helpers';

const GrowStatus_InfoContent = ({activeGrow, activeGrowPlan, activeRecipe}) => {
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  return (
    <div className={isMobile ? "FlexContent-30" : "FlexContent-H-10 FlexContent-HFlex"} style={{padding: "20px 40px"}}>
      <div className={isMobile ? "" : "FlexContent-HFlex"}>
        <div className="FlexContent-5">
          <div className="Text-SubHeader-Medium">Name</div>
          <div className="FlexContent-5" style={{gap:2}}>
            <div className="Text-SubHeader">{activeGrowPlan !== undefined ? activeGrowPlan.name : "Unknown Name"}</div>
            <div className="Text-FormAltLabel">#GP{activeGrowPlan !== undefined ? activeGrowPlan.uid : "?"}</div>
          </div>
        </div>
      </div>
      <div className={isMobile ? "" : "FlexContent-HFlex"}>
        <div className="FlexContent-5">
          <div className="Text-SubHeader-Medium">Recipe</div>
          <div className="FlexContent-5" style={{gap:2}}>
            <div className="Text-SubHeader">{activeRecipe !== undefined ? activeRecipe.name : "Unknown Recipe"}</div>
            <div className="Text-FormAltLabel">
            <div>
              {activeRecipe !== undefined && 
                <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                    <div>v{activeRecipe.version}</div>
                    <div>{FormatDate(new Date(activeRecipe.modified_on), "MM/dd/yyyy")}</div>
                </div>
              }
            </div>
            </div>
          </div>                  
        </div>
      </div>
      <div className={isMobile ? "" : "FlexContent-HFlex"}>
        <div className="FlexContent-5">
          <div className="Text-SubHeader-Medium">Plant Type</div>
          <div className="FlexContent-5" style={{gap:2}}>
            <div className="Text-SubHeader"></div>
            <div className="Text-FormAltLabel"></div>
          </div>                                          
        </div>
      </div>
      <div className={isMobile ? "" : "FlexContent-HFlex"}>
        <div className="FlexContent-5">
          <div className="Text-SubHeader-Medium">Location</div>
          <div className="FlexContent-5" style={{gap:2}}>
            <div className="Text-SubHeader"></div>
            <div className="Text-FormAltLabel"></div>
          </div>                      
        </div>
      </div>
    </div>
  )
}

GrowStatus_InfoContent.defaultProps = {
  
}


export default GrowStatus_InfoContent