import './StandardList.scss';
import React from 'react'
 
import StandardListRowColumn from './StandardListRowColumn';
import { useMeasure, useMeasureWithPosition, usePosition } from '../../helpers';

const StandardListRow = ({
    rowKey, row, columns, columnBounds, dynamicRowClassName,
    columnSpacing, columnPadding, rowSpacing, rowPadding, groupedColumnPadding, groupedIndent,
    onClicked, onSelected, isSelectable, isExpanded, expansionToggled, isExpandable,
    getRowDimensions, listY, rowContainerRef
  }) => {

  const dimensionsRef = React.useRef({})
  const [currentBounds, SetCurrentBounds] = React.useState(null)

  const [rowBind, forceBoundsUpdate, {}] = useMeasureWithPosition({scrollContainerRef: rowContainerRef}, (bounds) => {
    if (bounds === undefined)
      return
    SetCurrentBounds(bounds)
  })
  React.useEffect(() => {
    if (currentBounds)  {
      dimensionsRef.current.outerWidth = currentBounds.width
      dimensionsRef.current.outerHeight = currentBounds.height
      dimensionsRef.current.left = currentBounds.left
      dimensionsRef.current.top = currentBounds.top
      dimensionsRef.current.right = currentBounds.right
      dimensionsRef.current.bottom = currentBounds.bottom
      dimensionsRef.current.offsetY = currentBounds.documentTop - listY
      if (getRowDimensions !== undefined)  {
        getRowDimensions(rowKey, dimensionsRef)
      }

    }
  }, [currentBounds, listY])

  forceBoundsUpdate()

  const [rowContentBind, {}] = useMeasure((bounds) => {
    if (bounds === undefined)
      return
    
    //dimensionsRef.current = {...dimensionsRef.current, rowBounds: {width: bounds.width, height: bounds.height, left: bounds.left, top: bounds.top, right: bounds.right, bottom: bounds.bottom}}
    dimensionsRef.current.height = bounds.height
    dimensionsRef.current.width = bounds.width
    if (getRowDimensions !== undefined)  {
      getRowDimensions(rowKey, dimensionsRef)
    }
  })
  

  const selectionCheckbox = React.useRef(undefined)

  const toggleExpandedRow = React.useCallback((e) =>  {
    if (row.onExpandToggled !== undefined)  {
      row.onExpandToggled(e)
      return
    }
    if (expansionToggled !== undefined) {
      expansionToggled()
    }
  })

  const rowClicked = (e) => {
    if (onClicked !== undefined) {
      onClicked(row)
    }
  }

  const rowSelected = (e) => {
    if (onSelected !== undefined && isSelectable) {
      onSelected(row)
    }
  }

  const rowProps = {style:{padding: columnSpacing / 2}}//{style: {gap: rowSpacing}}
  if (onSelected !== undefined && isSelectable) {
    rowProps.style.cursor = "pointer"
  }

  let expandedRender = <></>
  if (row.expandedRender !== undefined) {
    expandedRender = row.expandedRender ({
      bounds: columnBounds,
      columnPadding: columnPadding,
      columnSpacing: columnSpacing,
      rowPadding: rowPadding,
      rowContainerRef: rowContainerRef
    })
  }

  let expandButtonRender = <div className="StandardList-Row-DefaultExpandButton"></div>
  if (row.expandButtonRender !== undefined) {
    expandButtonRender = row.expandButtonRender()
  }

  return (
    <div className="StandardList-RowContainer" {...rowBind}>
      <div className={"StandardList-Row" + (isSelectable ? " StandardList-Row-Selectable" : "") + (!isSelectable && row.selected ? " StandardList-Row-Selected" : "") + (dynamicRowClassName ? (" " + dynamicRowClassName(row)) : "")}
        onClick={rowClicked}>
        <div className="StandardList-Row-ContentWrapper" {...rowContentBind}>
          <div className="StandardList-Row-Content"
            {...rowProps}>
            <div className="StandardList-Row-ColumnsContent">
              {isSelectable &&
                <div className="StandardList-Row-ColumnContainer">
                  <div className="StandardList-Row-ColumnContent" style={{padding: rowPadding + "px " + columnPadding + "px"}}>
                    <div className="StandardList-Row-Column">
                      <input type="checkbox" ref={selectionCheckbox}/>
                    </div>
                  </div>
              </div>
              }
              {Object.entries(columns).map(([columnKey, column]) => {
                const rowColumnDefinition = row.columns[columnKey] !== undefined ? row.columns[columnKey] : {
                  render: (<></>)
                }

                
                if (column.isGroupColumn) {
                  return (
                    <StandardListRowColumn 
                      key={columnKey} 
                      row={row}
                      columns={columns}
                      columnKey={columnKey}
                      column={column} 
                      columnBounds={columnBounds}
                      groupedIndent={groupedIndent}
                      rowColumnDefinition={rowColumnDefinition} 
                      columnSpacing={0} 
                      columnPadding={groupedColumnPadding} 
                      rowPadding={rowPadding}/>
                  )
                }else {
                  return (
                    <StandardListRowColumn 
                      key={columnKey} 
                      row={row}
                      columns={columns}
                      columnKey={columnKey}
                      column={column} 
                      columnBounds={columnBounds} 
                      groupedIndent={groupedIndent}
                      rowColumnDefinition={rowColumnDefinition} 
                      columnSpacing={columnSpacing} 
                      columnPadding={columnPadding} 
                      rowPadding={rowPadding}/>
                  )
                }
              })}
            </div>
          </div>
        </div>
        {(row.isExpandable !== undefined && row.isExpandable) && 
          <>
            <div className="StandardList-Row-ExpandedContent" style={{height: (isExpanded ? "auto" : 0)}}>
              {expandedRender}
            </div>
          </>
        }
      </div>
      {(row.isExpandable !== undefined && row.isExpandable && (row.showExpander === undefined || row.showExpander)) && 
        <>
          <div className="StandardList-Row-ExpandButton" onClick={toggleExpandedRow}>
            {expandButtonRender}
          </div>
        </>
      }
    </div>
  )
} 

StandardListRow.defaultProps = {
  row: {},
  columns: {},
  rowSpacing: 8,
  groupedIndent: 0,
  listY: 0
}


export default StandardListRow