import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'


const processGrowSeedFromAPI = (growSeed) =>  {
  
  return growSeed
}

export const getAllGrowSeeds = createAsyncThunk('growSeeds/getAllGrowSeeds', async ({}, { getState }) => {
  return await FetchPost(APIRepository.GrowSeeds.GetAllGrowSeedsForAccount, {
    ...getAccountSessionData(getState()),
    have_grow_seeds: {}
  })  
})


export const getGrowSeedById = createAsyncThunk('growPlans/getGrowSeedById', async ({growSeedId, growSeedIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (growSeedId !== undefined) {
    payload.grow_seed_id = growSeedId
  }
  if (growSeedIds !== undefined) {
    payload.grow_seed_ids = growSeedIds
  }
  return await FetchPost(APIRepository.GrowSeeds.GetGrowSeedById, payload)  
})


export const growSeedsSlice = createSlice({
  name: 'growSeeds',
  initialState: {
    seeds:  [
      {id: 1, name: "Picante Mix", description: "Typical delicious mixture", plant_variety_id: 1},
      {id: 2, name: "Mustard Greens", description: "Tangy but delicious", plant_variety_id: 2},
      {id: 3, name: "Buttttttterhead", description: "Not margerine", plant_variety_id: 3}
    ],
    status: 'idle',
    error: null,
    haveInitialData: false,

    haveAllGrowSeeds: false,
    loadingData: false,
    loadingGrowSeedsStatus: false,
    loadingGrowSeedsByIds: [],
  },
  reducers: {
    

    growSeedUpdate: (state, action) => {
      let hasChanged = false
      let newGrowSeeds = { ...state, growSeeds: state.growSeeds.map((growSeed, index) => {
        if (growSeed.id !== action.payload.growSeedId) {
          return growSeed
        }
    
        if (growSeed[action.payload.prop] === undefined || growSeed[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...growSeed,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newGrowSeeds
      }
    }
  },
  extraReducers: {
    [getAllGrowSeeds.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllGrowSeeds.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveAllGrowSeeds = true
      action.payload.growSeeds.map(function(growSeed){ processGrowSeedFromAPI(growSeed); return growSeed });
      console.log(action.payload.growSeeds)
      state.growSeeds = action.payload.growSeeds;
    },

    [getAllGrowSeeds.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getGrowSeedById.pending]: (state, action) => {
      state.loadingGrowSeedsStatus = 'pending';
      if (action.meta.arg.growSeedId !== undefined) {
        if (state.loadingGrowSeedsByIds.indexOf(action.meta.arg.growSeedId) === -1) {
          state.loadingGrowSeedsByIds.push(action.meta.arg.growSeedId)
        }
      }
      if (action.meta.arg.growSeedIds !== undefined) {
        for (let growSeedId of action.meta.arg.growSeedIds) {
          if (state.loadingGrowSeedsByIds.indexOf(growSeedId) === -1) {
            state.loadingGrowSeedsByIds.push(growSeedId)
          }
        }
      }
    },

    [getGrowSeedById.fulfilled]: (state, action) => {
      state.loadingGrowSeedsStatus = 'fulfilled';
      if (action.payload.grow_seed !== null) {
        state.growSeeds.push(processGrowSeedFromAPI(action.payload.grow_seed))
        
        const loadingGrowSeedIndex = state.loadingGrowSeedsByIds.indexOf(action.payload.grow_seed.id)
        if (loadingGrowSeedIndex !== -1) {
          state.loadingGrowSeedsByIds.splice(loadingGrowSeedIndex, 1)
        }

      }
      if (action.payload.grow_seeds !== null) {
        for (let growSeed of action.payload.grow_seeds)  {
          state.growSeeds.push(processGrowSeedFromAPI(growSeed))
          const loadingGrowSeedIndex = state.loadingGrowSeedsByIds.indexOf(growSeed.id)
          if (loadingGrowSeedIndex !== -1) {
            state.loadingGrowSeedsByIds.splice(loadingGrowSeedIndex, 1)
          }
        }
      }
    },

    [getGrowSeedById.rejected]: (state, action) => {
      state.loadingGrowSeedsStatus = 'rejected';

      if (action.meta.arg.growSeedId !== undefined) {
        const loadingGrowSeedIndex = state.loadingGrowSeedsByIds.indexOf(action.meta.arg.growSeedId)
        if (loadingGrowSeedIndex === -1) {
          state.loadingGrowSeedsByIds.splice(loadingGrowSeedIndex, 1)
        }
      }
      if (action.meta.arg.growSeedIds !== undefined) {
        for (let growSeedId of action.meta.arg.growSeedIds) {
          const loadingGrowSeedIndex = state.loadingGrowSeedsByIds.indexOf(growSeedId)
          if (loadingGrowSeedIndex === -1) {
            state.loadingGrowSeedsByIds.splice(loadingGrowSeedIndex, 1)
          }
          }
      }
    },
  }
})



// Action creators are generated for each case reducer function
export const { growSeedUpdate} = growSeedsSlice.actions

export default growSeedsSlice.reducer

export const selectAllGrowSeeds = state => state.growSeeds.seeds

export const selectGrowSeedById = (state, growSeedId) =>
  state.growSeeds.seeds.find(growSeed => growSeed.id === growSeedId)