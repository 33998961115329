import './StandardList.scss';
import React from 'react'

import StandardListHeaderColumn from './StandardListHeaderColumn.js'
import StandardListHeaderGroupColumn from './StandardListHeaderGroupColumn.js'
import StandardListGroup from './StandardListGroup.js'
import StandardListRow from './StandardListRow.js'

import TextInput from '../input/TextInput';
import DropDownInput from '../input/DropDownInput';
import { useMeasure } from '../../helpers';

export const StandardList = ({
    showHeader, 
    columns, groups, rows, dynamicRowClassName, orderRowsBy, orderRowsByKey,
    columnSpacing, columnPadding, groupIndent, rowPadding,
    groupedColumnPadding,
    groupedBy, isolateRows,
    allowMultipleExpandedRows,
    resourcesAttached,
    isRowSelectable, isMultiSelectable, isSearchable, onSearchInput,
    isGroupFiltered, groupFilterOptions, onGroupFilterChange, filteredByGroup,
    onRowSelected, onRowClicked,
    getDimensions, getRowDimensions,
    getForceScroll
  }) => {
  const [currentColumns, SetColumns] = React.useState({})
  React.useEffect(() => {
    let updatedColumns = {...currentColumns}
    for (let [columnKey, column] of Object.entries(updatedColumns)) {
      if (columns[columnKey] === undefined)  {
        delete updatedColumns[columnKey]
      }
    }
    for (let [columnKey, column] of Object.entries(columns)) {
      if (updatedColumns[columnKey] === undefined)  {
        updatedColumns[columnKey] = column
      }else {
        if (updatedColumns[columnKey].isGroupColumn)  {
          updatedColumns[columnKey] = {...column}
        }
      }
    }
    SetColumns({...updatedColumns})
  }, [columns])
  const [columnBounds, SetColumnBounds] = React.useState({})

  const dimensionsRef = React.useRef({})
  const [listY, SetListY] = React.useState(0)
 

  const [listBind, {}] = useMeasure((bounds) => {
    if (bounds === undefined)
      return
    dimensionsRef.current.height = bounds.height
    dimensionsRef.current.y = bounds.documentTop
    SetListY(bounds.documentTop)
    if (getDimensions !== undefined)  {
      getDimensions(dimensionsRef)
    }
  })
  const [headerBind, {}] = useMeasure((bounds) => {
    if (bounds === undefined)
      return
    dimensionsRef.current.headerHeight = bounds.height
    if (getDimensions !== undefined)  {
      getDimensions(dimensionsRef)
    }
  })
  const [contentBind, {}] = useMeasure((bounds) => {
    if (bounds === undefined)
      return
    if (dimensionsRef.current.contentScrollY === undefined) {
      dimensionsRef.current.contentScrollY = 0
    }
    dimensionsRef.current.contentHeight = bounds.height
    if (isolateRows)  {
      dimensionsRef.current.contentHeight += 8
    }
    if (getDimensions !== undefined)  {
      getDimensions(dimensionsRef)
    }
  })
  const onContentScroll = (e) =>  {
    dimensionsRef.current.contentScrollY = e.target.scrollTop
    if (getDimensions !== undefined)  {
      getDimensions(dimensionsRef)
    }
  }
  
  const forceContentScroll = (offset) =>  {
    contentBind.ref.current.scrollTop += offset
  }
  if (getForceScroll !== undefined) {
    getForceScroll(forceContentScroll)
  }


  const [currentGroups, SetGroups] = React.useState({})
  const [isolatedRows, SetIsolatedRows] = React.useState({...rows})
  const [selectedRow, SetSelectedRow] = React.useState(undefined)
  const [expandedRows, SetExpandedRows] = React.useState([])

  const [currentSearchInput, SetSearchInput] = React.useState("")
  const onSearchChanged = (value) =>  {
    if (onSearchInput)  {
      const result = onSearchInput(value)
      if (result !== undefined)
        return result
    }
    return value
  }

  const [currentFlteredByGroup, SetFilteredByGroup] = React.useState(filteredByGroup)
  const onGroupFilterChanged = (value) => {
    if (onGroupFilterChange !== undefined)  {
      onGroupFilterChange(value)
    }
    SetFilteredByGroup(value)
  }

  React.useEffect(() => {
    if (Object.entries(groups).length > 0)  {
      let updatedGroups = {}
      let updatedIsolatedRows = {}

      Object.entries(groups).map(([groupKey, group]) => {
        updatedGroups[groupKey] = {...group, rows: {}}
      })

      Object.entries(rows).map(([rowKey, row]) => {
        if (row.groupKey !== undefined && updatedGroups[row.groupKey] !== undefined)  {
          updatedGroups[row.groupKey].rows[rowKey] = row
        }else {
          updatedIsolatedRows[rowKey] = row
        }
      })
      /*if (orderRowsBy !== false)  {
        updatedIsolatedRows = Object.keys(updatedIsolatedRows).map(k => ([k, updatedIsolatedRows[k]])).sort((a, b) => (b[1].data[orderRowsBy] - a[1].data[orderRowsBy]))
      }*/

      SetIsolatedRows(updatedIsolatedRows)
      SetGroups(updatedGroups)
    }else {
      //rows = Object.keys(rows).map(k => ([k, rows[k]])).sort((a, b) => (b[1].data[orderRowsBy] - a[1].data[orderRowsBy]))
      SetIsolatedRows(rows)
      SetGroups({})
    }
  }, [groups, rows])
  
  const rowSelected = (row) =>  {
    SetSelectedRow(row)
    if (onRowSelected !== undefined)  {
      onRowSelected(row)
    }
  }

  const rowExpansionToggled = (rowKey) =>  {
    let foundIndex = expandedRows.indexOf(rowKey)
    if (foundIndex === -1)  {
      if (allowMultipleExpandedRows)  {
        SetExpandedRows([...expandedRows, rowKey])
      }else {
        SetExpandedRows([rowKey])
      }
    }else {
      SetExpandedRows(...expandedRows.splice(foundIndex, 1))
    }
  }

  const rowClicked = (row) =>  {
    if (onRowClicked !== undefined)  {
      onRowClicked(row)
    }
  }

  let headerProps = {
    style: {}//{gap: columnSpacing}
  }
  if (Object.entries(currentGroups).length > 0) {
    headerProps.style.paddingLeft = groupIndent
  }
  if (!showHeader)  {
    headerProps.style.visibility = "hidden"
    headerProps.style.height = 0
  }else {
    headerProps.style.padding = columnSpacing / 2
  }

  const updateColumnBounds = React.useCallback((columnKey, bounds) => {
    columnBounds[columnKey] = bounds
    SetColumnBounds({...columnBounds})
  })


  let currentIsolatedRows = []
  if (orderRowsBy !== false) {
    currentIsolatedRows = Object.fromEntries(Object.entries(isolatedRows).sort((a, b) => {
      return a[1][orderRowsBy] - b[1][orderRowsBy]
    }))
  }else {
    currentIsolatedRows = isolatedRows
  }

  //console.log(isolatedRows, currentIsolatedRows)

  return (
    <div className="StandardList-Wrapper" {...listBind}>
      <div className="StandardList-Container">
        {(!resourcesAttached && (isSearchable || isGroupFiltered)) &&
          <div className="StandardList-DetachedResourceHeader">
            <div className="ControlBar_Horizontal">
              {isSearchable && 
                <TextInput value={currentSearchInput} placeHolder="Search" icon={<TextInput.SearchIcon/>} onChange={onSearchChanged}/>
              }
              {isGroupFiltered &&
                <DropDownInput options={groupFilterOptions} value={currentFlteredByGroup} defaultValue={"*"} onSelectionChange={onGroupFilterChanged}/>
              }
            </div>
          </div>
        }
        <div className={"StandardList" + (isolateRows ? " StandardList-IsolatedRows" : "")+ (!showHeader ? " StandardList-HiddenHeader" : "")}>
          {(resourcesAttached && (isSearchable || isGroupFiltered)) &&
            <div className="StandardList-AttachedResourceHeader">
              <div className="ControlBar_Horizontal">
                {isSearchable && 
                  <TextInput value={currentSearchInput} placeHolder="Search" icon={<TextInput.SearchIcon/>} onChange={onSearchChanged}/>
                }
                {isGroupFiltered &&
                  <DropDownInput options={groupFilterOptions} value={currentFlteredByGroup} defaultValue={"*"} onSelectionChange={onGroupFilterChanged}/>
                }
              </div>
            </div>
          }
          <div className="StandardList-HeaderWrapper" {...headerBind}>
            <div className="StandardList-Header" {...headerProps}>
              {isMultiSelectable &&
                <StandardListHeaderColumn 
                  isForMultiselect={true} 
                  onMultiselectionChange={null} 
                  multiSelectState={"off"}  
                  columnPadding={columnPadding} 
                  rowPadding={columnPadding}/>
              }
              {Object.entries(currentColumns).map(([columnKey, column]) => {
                  

                if (column.isGroupColumn) {
                  const columnSizeChanged = (bounds, uColumnKey) => {
                    //currentColumns[column.key].containerBounds = bounds
                    if (uColumnKey === undefined) {
                      updateColumnBounds(columnKey, bounds)
                    }else {
                      updateColumnBounds(uColumnKey, bounds)
                    }
                  }
                  return (
                    <StandardListHeaderGroupColumn 
                      key={columnKey} 
                        column={column} 
                        onSizeChange={columnSizeChanged} 
                        columnPadding={columnPadding} 
                        groupedColumnPadding={groupedColumnPadding} 
                        rowPadding={columnPadding}/>
                  )
                }else {
                  const columnSizeChanged = (bounds) => {
                    updateColumnBounds(columnKey, bounds)
                  }
                  return (
                    <StandardListHeaderColumn 
                      key={columnKey} 
                      column={column} 
                      onSizeChange={columnSizeChanged} 
                      columnPadding={columnPadding} 
                      rowPadding={columnPadding}/>
                  )
                }
              })}
            </div>
          </div>
          <div className="StandardList-ContentWrapper" onScroll={onContentScroll} {...contentBind}>
            {<div className="StandardList-Content">
              {Object.entries(currentGroups).length > 0 &&
                <div className="StandardList-Groups">
                  {Object.entries(currentGroups).map(([groupKey, group]) => {
                    let groupRows = {}
                    return (
                      <StandardListGroup 
                        key={groupKey} 
                        group={group}
                        dynamicRowClassName={dynamicRowClassName}
                        columns={currentColumns} 
                        columnBounds={columnBounds}
                        columnSpacing={columnSpacing} 
                        columnPadding={columnPadding} 
                        groupedColumnPadding={groupedColumnPadding}
                        rowPadding={rowPadding}
                        groupIndent={groupIndent} 
                        onRowSelected={rowSelected}
                        onRowClicked={rowClicked}
                        isRowsSelectable={isMultiSelectable || isRowSelectable}
                        getRowDimensions={getRowDimensions}
                        listY={listY}/>
                    )
                  })}
                </div>
              }
              {Object.entries(currentIsolatedRows).length > 0 &&
                <div className="StandardList-Rows">
                  {Object.entries(currentIsolatedRows).map(([rowKey, row]) => {
                    const handleExpansionToggled = () =>  {
                      rowExpansionToggled(rowKey)
                    }
                    return (
                      <StandardListRow 
                        key={rowKey} 
                        rowKey={rowKey}
                        row={row}
                        dynamicRowClassName={dynamicRowClassName}
                        columns={currentColumns} 
                        columnBounds={columnBounds}
                        columnSpacing={columnSpacing} 
                        columnPadding={columnPadding}
                        groupedColumnPadding={groupedColumnPadding}
                        rowPadding={rowPadding}
                        onClicked={rowClicked}
                        isSelectable={isMultiSelectable || isRowSelectable}
                        onSelected={rowSelected}
                        isExpanded={row.isExpanded !== undefined ? row.isExpanded : expandedRows.indexOf(rowKey) !== -1}
                        expansionToggled={handleExpansionToggled}
                        getRowDimensions={getRowDimensions}
                        listY={listY}
                        rowContainerRef={contentBind.ref}/>
                    )
                  })}
                </div>
              }
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
} 

StandardList.defaultProps = {
  columns: [],
  rows: {},
  orderRowsBy: false,
  orderRowsByKey: false,
  dynamicRowClassName: false,
  groups: {},
  showHeader: false,
  groupedBy: false,
  resourcesAttached: true,
  columnSpacing: 8,
  columnPadding: 16,
  groupedColumnPadding: 8,
  allowMultipleExpandedRows: false,
  rowPadding: 8,
  groupIndent: 16,
  isRowSelectable: false,
  isMultiSelectable: false,
  isolateRows: false,
  filteredByGroup: "*",
}





export default StandardList 