import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { Blade_LightsManager_Standard24, RackPowerOnTutorial, ScanRack } from '../../../../assets/icons/RackIcons';
import DropDownButton from '../../../../components/DropDownButton';
import { CloseDropdown, GoBack, GoForward, OpenDropdown } from '../../../../assets/icons/Icons';
import { parseBool } from '../../../../helpers';
import { setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import { useDispatch } from 'react-redux';


  

const BladeLightsManager_Overview = ({blade, transitionToNextStep, transitionToSubStep, completeCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const dispatch = useDispatch()
    
    const nextClicked = React.useCallback(() => {
        //powerOnConfirmedCallback()

        //transitionToNextStep(["get_blade_code", null])
    })



    
    const [lightbarsConfig, SetLightBarsConfig] = React.useState({})
    const [lightbarsRuntimeInformation, SetLightbarsRuntimeInformation] = React.useState({})
    
    React.useEffect(() => {
        if (blade.unique_configuration.properties !== undefined)    {
            if (blade.unique_configuration.properties["lbc"] !== undefined) {
                const existingLightbarConfig = JSON.parse(blade.unique_configuration.properties["lbc"])

                SetLightBarsConfig({...existingLightbarConfig})
            }
        }
        if (blade.runtime_information !== undefined)    {
            if (blade.runtime_information["light_bar_status"] !== undefined) {
                const existingLightbarRuntimeInformation = JSON.parse(blade.runtime_information["light_bar_status"])

                SetLightbarsRuntimeInformation({...existingLightbarRuntimeInformation})
            }
        }
        console.log(blade)
    }, [blade])


    const [selectedLightBarIndex, SetSelectedLightBarIndex] = React.useState(1)
    const [lightBarIndexOptions, SetLightBarIndexOptions] = React.useState([])
    React.useLayoutEffect(() => {
        
        let currentLightBarIndexOptions = []
        for (let i = 1; i < Object.entries(lightbarsConfig).length + 1; i++)  {
            currentLightBarIndexOptions.push({label: "Light Bar " + i.toString(), key: i})
        }
        SetLightBarIndexOptions(currentLightBarIndexOptions)
    }, [lightbarsConfig])
    const lightBarIndexOptionSelected = (option) => {
        SetSelectedLightBarIndex(option.key)   
    }


    const [lightBarSerialNumberToValidate, SetLightBarSerialNumberToValidate] = React.useState("") 

    const validateLightBarClicked = () => {

        let updatedRuntimeInformation = JSON.stringify({...lightbarsRuntimeInformation, [selectedLightBarIndex]: {...lightbarsRuntimeInformation[selectedLightBarIndex], "psn": lightBarSerialNumberToValidate}})
        dispatch(setBladeRuntimeProperty({bladeId: blade.id, properties: {"light_bar_status": updatedRuntimeInformation}}))
    }


    

    let currentLightbarConfig = lightbarsConfig[selectedLightBarIndex]
    let isLightBarCommissioned = false
    if (currentLightbarConfig !== undefined)    {
        isLightBarCommissioned = parseBool(currentLightbarConfig["c"])
    }

    let currentLightbarRuntimeInformation = lightbarsRuntimeInformation[selectedLightBarIndex]
    let isLightBarConnected = false
    let isLightBarPendingSerialNumber = false
    if (currentLightbarRuntimeInformation !== undefined)    {
        isLightBarConnected = currentLightbarRuntimeInformation["o"]
        isLightBarPendingSerialNumber = currentLightbarRuntimeInformation["psn"] !== undefined
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Lights Connected 0/{Object.entries(lightbarsConfig).length}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className={"FlexContent-HFill"} style={{flexGrow:0.5, flexShrink: 1, overflowX: "hidden"}}>
                        <Blade_LightsManager_Standard24 maxWidth={"100%"}/>
                    </div>
                    <div className="Linebreak-R-10"></div>
                    <div className="FlexContent-HFill" style={{flexGrow:0.5, flexShrink: 1}}>
                        <div className="FlexContent-30" style={{padding: "0px 10px"}}>
                            <div className="FlexContent-5">
                                <DropDownButton options={lightBarIndexOptions} onOptionSelected={lightBarIndexOptionSelected} content={<>
                                    <div className="Text-Medium-S24">Light Bar {selectedLightBarIndex}</div>
                                    <OpenDropdown/>
                                </>}/>
                                {!isLightBarCommissioned && <>
                                    <div>Not Commissioned</div>
                                </>}
                                
                            </div> 
                            {!isLightBarCommissioned && <>
                                <div className="FlexContent-10">
                                    <div className="FlexContent-H-5">
                                        <TextInput value={lightBarSerialNumberToValidate} placeHolder={"Enter Serial Number"} onChange={(value) => {SetLightBarSerialNumberToValidate(value)}}/>
                                        <Button status={"Primary-Inverted"} content={"Scan"}/>
                                    </div>
                                    <Button status={"Primary"} content={"Validate Light Bar"} onClick={validateLightBarClicked}/>
                                </div>
                            </>}
                            <div className="FlexContent-H-60" style={{justifyContent:"space-between"}}>
                                <Button status={"Hyperlink"} content={<>
                                        <div className="FlexContent-H-10 FlexContent-Center">
                                            <GoBack/>
                                            <div>Previous</div>
                                        </div>
                                    </>} disabled={selectedLightBarIndex <= 1}
                                    onClick={(() => {SetSelectedLightBarIndex(selectedLightBarIndex - 1)})}/>
                                <Button status={"Hyperlink"} content={<>
                                        <div className="FlexContent-H-10 FlexContent-Center">
                                            <div>Next</div>
                                            <GoForward/>                                    
                                        </div>
                                    </>} disabled={selectedLightBarIndex >= Object.entries(lightbarsConfig).length}
                                    onClick={(() => {SetSelectedLightBarIndex(selectedLightBarIndex + 1)})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Complete" onClick={completeCallback} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex100"/>
            </div>
        </div>
    )
}

  


export default BladeLightsManager_Overview