import './../CreateBladeWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { getAllControlDevicesByFacilityId, selectAllBladeControlDevices } from '../../../../redux/entities/service/ControlDevice';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import { RSSI } from '../../../../assets/icons/Icons';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';

  

const SelectControlBoardWalkthroughStep = ({facilityId, initialSelectedControlDeviceSerialNumber, cancelCallback, transitionToNextStep, controlDeviceSerialNumberSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const allControlDevices = useSelector(selectAllBladeControlDevices)
    
    const dispatch = useDispatch()

    const haveControlDevicesForFacility = useSelector(state => state.controlDevice.haveControlDevicesForFacility)
    const loadingControlDevicesStatus = useSelector(state => state.controlDevice.loadingControlDevicesStatus)
    React.useEffect(() => {
        if (haveControlDevicesForFacility[facilityId] !== true && loadingControlDevicesStatus !== "pending") {
            dispatch(getAllControlDevicesByFacilityId({facilityId: facilityId}))
        }
    }, [haveControlDevicesForFacility, loadingControlDevicesStatus])


    const [availableControlDevices, SetAvailableControlDevices] = React.useState([])
    React.useEffect(() => {
        SetAvailableControlDevices(allControlDevices.filter((cD) => cD.facility_id === facilityId && cD.mode === "discovery"))
    }, [facilityId, allControlDevices]) 

    const [selectedControlDeviceSerialNumber, SetSelectedControlDeviceSerialNumber] = React.useState(initialSelectedControlDeviceSerialNumber)
    const controlDeviceSelected = (serialNumber) => {
        SetSelectedControlDeviceSerialNumber(serialNumber)
    } 

    const cancelClicked = () =>   {
        cancelCallback()
    }

    
    const startClicked = async () =>   {
        controlDeviceSerialNumberSelectedCallback({controlDeviceSerialNumber: selectedControlDeviceSerialNumber})
        transitionToNextStep(["bluetooth_pairing", "setup"])
        
        

    }


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Select a control board below</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-HFill">
                    

                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false} 
                            isExpandable={false}
                            hasContainer={false}
                            hasColumnHeaders={false}>
                                {availableControlDevices.length === 0 && <>
                                    <div className="Text-S16" style={{padding:20}}>No boards available to link</div>
                                </>}
                            
                                {availableControlDevices.map((controlDevice) => {



                                    let subtitle = <>{controlDevice.serial_number}</>

                                    /*if (isMobile) {
                                        additionalFunctions.splice(2, 0, 
                                        {key: "pause_rack", label: "Pause Rack"}  
                                        );
                                    }*/

                                    let secondaryHeaderContent = null
                                    if (!isMobile)  {
                                        secondaryHeaderContent = [
                                        {key: "rssi", content: <RSSI/>}
                                        ]
                                    }else {
                                        secondaryHeaderContent = <><RSSI/></>
                                    }

                                    


                                    return (
                                        <RowGroupRow
                                            key={controlDevice.id}
                                            title={"Device #" + controlDevice.serial_number}
                                            hasFlexCell={true}
                                            showStatusIndicator={true}
                                            status={controlDevice.connected ? "Connected" : "Not Connected"}
                                            statusColor={controlDevice.connected ? "#4DBE3B" : "#f00"}
                                            hasIssues={true}
                                            numberOfIssues={0} 
                                            isSelectable={true}
                                            selectedState={selectedControlDeviceSerialNumber === controlDevice.serial_number}
                                            onSelectedChanged={() => {controlDeviceSelected(controlDevice.serial_number)}}
                                            customRowPadding={0}
                                            isExpandable={false}
                                            secondaryContent={secondaryHeaderContent}/>
                                    )
                                }
                            )}
                        </RowGroup>
                            
                        <div className="Text-S16">Make sure the device is online</div>
                        
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Start"} onClick={startClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedControlDeviceSerialNumber === ""}/>
            </div>
        </div>
    )
    
}
  


export default SelectControlBoardWalkthroughStep