import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual } from '../../../../../assets/icons/RackIcons';
import { StandardFilterHousing_Complete } from '../../../../../assets/icons/ProductIcons';

const Blade_CleanseTask_FilterChangeOptionsStep = ({
        task,
        skipFilterChangeCallback,
        confirmFilterChangingCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const skipClicked = () => {
        skipFilterChangeCallback()
    }

    const confirmClicked = () => {
        confirmFilterChangingCallback()
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    Would you like to change the blade's filters?
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <StandardFilterHousing_Complete/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Skip"} status={"Neutral"} onClick={skipClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Yes"} onClick={confirmClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_FilterChangeOptionsStep