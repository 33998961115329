//import './PageHeader.scss';
import React from 'react'

const ChatPage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Chat")
  }, [])
  return (
    <>
      Chat
    </>
  )
} 

export default ChatPage