import './ServicePage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'
import { useSearchParams } from 'react-router-dom';





const ServicePage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Service")
  }, [])


  const [searchParams, setSearchParams] = useSearchParams();
  const bladeUID = searchParams.get("blade_uid")
  
  return (
    <div id="ServicePage">
        Blade UID: {bladeUID}
    </div>
  )
} 

export default ServicePage