import './NDSsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { selectAllBladeZones, selectAllBlades, selectBladeZoneByUID,  } from '../../../redux/entities/service/Blade';
import { RoundToNearest, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { AddItem, AdditionalOptions, BadX, GoodCheckmark, HalfFullCup, Pause, Play, Plus, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import { getNDSMapVersions, getNDSMaps, getNDSStatusById, selectAllNDS } from '../../../redux/entities/service/NDS';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import { ScanNDS } from '../../../assets/icons/NDSIcons';
import DropDownButton from '../../../components/DropDownButton';
import { getInventoryItemTypeById, selectAllInventoryItemTypes } from '../../../redux/entities/Inventory';






const NDSsPage = ({selectedFacility, setRightSideContent}) => {
  


  
  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const dispatch = useDispatch()

  const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  
  const allNDSs = useSelector(selectAllNDS)

  const [NDSs, SetNDSs] = React.useState([])
  React.useEffect(() => {
    SetNDSs(allNDSs.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility, allNDSs])
  
  //const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)

  
  
 
  const updateHeader = React.useCallback(() =>  {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {NDSs.length > 0 && 
          <Button content="Add Flow" status="Primary" onClick={addNewNDS}/>  
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [NDSs])

  
  
  const [addingNewNDS, SetAddingNewNDS] = React.useState(false)
  const addNewNDS = React.useCallback(() => {
    SetAddingNewNDS(true)
  })

  

  const addNDSWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewNDS(false)  
  })



  




  ///const NDSEditing = React.useState(null);

  return (<>
    <div id="NDSStatus">
      
      {NDSs.length === 0 && 
        <div className="NDSStatus-NoGroupsContent">
          <span>No active Flows</span>
        </div>
      }
      {NDSs.length > 0 &&
        <div className="NDSStatus-NDSs">
          <RowGroup
            hasSettings={false}
            hasGroupHeader={false}
            hasColumnHeaders={false}
            verticalFill={true}
            >
              {NDSs.map((NDS) => {
                return <NDSsPage_NDSRow key={NDS.id} NDS={NDS} isDefaultExpanded={NDSs.length === 1}/>
                

                
              })}

            </RowGroup>
          </div>
            
        }
    </div>
  </>)
} 

export default NDSsPage





const NDSsPage_NDSRow = ({NDS, isDefaultExpanded}) =>  {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const [currentSubtitle, SetCurrentSubtitle] = React.useState("idle")

  const isNDSOnline = (NDS.control_device !== undefined && NDS.control_device !== null && NDS.control_device.connected)
  const isNDSPaused = false


  let additionalFunctions = [
    {key: "manage_lights", label: "Manage Lights"},
    {key: "rename", label: "Rename Rack"},
    {key: "move", label: "Move Rack"},
    {key: "change_sides", label: "Change Sides"},
    {key: "light_up", label: "Light Up Rack"},
    {key: "delete", label: "Delete", status: "Critical"},
  ]
  if (isMobile) {
    additionalFunctions.splice(2, 0, 
      {key: "pause_rack", label: "Pause Rack"}  
    );
  }

  let secondaryHeaderContent = null
  if (!isMobile)  {
    secondaryHeaderContent = [
      {key: "pause_state", content: 
        <div className="FlexContent-H-10">
          {isNDSPaused && 
            <div className="FlexContent-H-20 FlexContent-Center">
              <span className="NDSStatus-NDS-PauseInfo-State">Paused 16:34</span>

              <div className="NDSStatus-NDS-PauseInfo-AddTimeButton"><span>Add Time</span></div>
            </div>
          }
          <div className="FlexContent-H-10">
            {isNDSPaused && <>
              <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
            </>}
            {!isNDSPaused &&
              <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
            }
          </div>
        </div>
      },
      {key: "rssi", content: <RSSI/>}
    ]
  }else {
    secondaryHeaderContent = <><RSSI/></>
  }

  let extendedHeaderContent = null
  if (isMobile && isNDSPaused) {
    extendedHeaderContent = {content: <>
      <div className="FlexContent-H-10">
        <div className="FlexContent-H-10 FlexContent-Center">
          <div className="NDSStatus-NDS-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt/></div>
        </div>
        <div className="FlexContent-H-10">
          <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
        </div>
      </div>
    </>}
  }else {

  }


  return (<>
    <RowGroupRow
      key={NDS.id}
      title={NDS.display_name}
      hasFlexCell={false}
      subtitle={<div className="NDSStatus-NDS-Subtitle">{currentSubtitle}</div>}
      showStatusIndicator={true}
      status={isNDSOnline ? "Connected" : "Not Connected"}
      statusColor={isNDSOnline ? "#4DBE3B" : "#f00"}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={true}
      customRowPadding={0}
      expandedState={isDefaultExpanded}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      content={<>
        <NDSsPage_NDSContent NDS={NDS} setSubtitle={(subtitle) => {SetCurrentSubtitle(subtitle)}}/>

      </>}/>

  </>)
}




const NDSsPage_NDSContent = ({NDS, setSubtitle}) => {

  //console.log(NDS)

  const dispatch = useDispatch();
  const [currentAction, SetCurrentAction] = React.useState("idle")
  const bladeZones = useSelector(selectAllBladeZones)

  const allInventoryItemTypes = useSelector((state) => selectAllInventoryItemTypes(state))
  const loadingItemTypesStatus = useSelector((state) => state.inventory.loadingItemTypesStatus)
  /*const solutions = useSelector(selectAllSolutions)
  const solutionsStatus = useSelector((state) => state.solutions.status)
  const nutrientSolutions = useSelector(selectAllNutrientSolutions);
  const nutrientSolutionsStatus = useSelector((state) => state.nutrientSolutions.status)*/
  
  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const isNDSOnline = (NDS.control_device !== undefined && NDS.control_device !== null && NDS.control_device.connected)
  const isNDSPaused = false
  const zoneStatus = "Idle"


  

  //Make sure our maps are up to date
  const performMapValidationCheck = () => {
    dispatch(getNDSMapVersions({NDSId: NDS.id}))
  }
  React.useEffect(() => {
    if (NDS.retrievingMapVersionsStatus === "idle")  {
      performMapValidationCheck()
    }else if (NDS.retrievingMapVersionsStatus === "rejected" || NDS.retrievingMapVersionsStatus === "fulfilled")  {
      setTimeout(() => {performMapValidationCheck()}, 5000) //Every 5 seconds
    }
  }, [NDS])

  //Validate the dosing map that we have is the correct version
  const performMapRetrieval = () => {

    let mapsRequireUpdate = []
    if (NDS.maps.versions["dosing_map"] !== undefined && NDS.haveMapVersions["dosing_map"] !== undefined)  {
      if (NDS.maps.versions["dosing_map"] !== NDS.haveMapVersions["dosing_map"])  {
        mapsRequireUpdate.push("dosing_map")
      }
    }

    if (mapsRequireUpdate.length > 0) {
      dispatch(getNDSMaps({NDSId: NDS.id, mapKeys: mapsRequireUpdate}))
    }
  }
  React.useEffect(() => {
    if (NDS.retrievingMapsStatus === "idle")  {
      performMapRetrieval()
    }else if (NDS.retrievingMapsStatus === "rejected" || NDS.retrievingMapsStatus === "fulfilled")  {
      setTimeout(() => {performMapRetrieval()}, 5000) //Every 5 seconds
    }
  }, [NDS])


  //Get status updates
  const performStatusInformationRetrieval = () => {
    dispatch(getNDSStatusById({NDSId: NDS.id}))
  }
  React.useEffect(() => {
    if (NDS.retrievingStatus === "idle")  {
      performStatusInformationRetrieval()
    }else if (NDS.retrievingStatus === "rejected" || NDS.retrievingStatus === "fulfilled")  {
      setTimeout(() => {performStatusInformationRetrieval()}, 1000) //Every 1 seconds
    }
  }, [NDS])

  const [currentContentTab, SetCurrentContentTab] = React.useState("reservoirs")


  const [dosingReservoirs, SetDosingResevoirs] = React.useState({})
  React.useEffect(() => {

    let dosingReservoirs = {}
    for (let [index, info] of Object.entries(NDS.nutrientReservoirs)) {
      dosingReservoirs[index] = {...info}
      let referenceId = parseInt(info["reference_id"]);

      let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
      if (foundInventoryItemType !== undefined)  {
        dosingReservoirs[parseInt(index)]["item"] = foundInventoryItemType
      }
    }
  


    SetDosingResevoirs(dosingReservoirs)

  }, [NDS, allInventoryItemTypes])




  React.useEffect(() => {
    let inventoryItemTypesToLoad = []

    for (let [index, info] of Object.entries(dosingReservoirs)) {
      if (info.connected) {
        let referenceId = parseInt(info["reference_id"]);

        let foundInventoryItemType = allInventoryItemTypes.find((iT) => iT.id === referenceId)
        if (foundInventoryItemType === undefined)  {
          if (inventoryItemTypesToLoad.indexOf(referenceId) === -1)  {
            inventoryItemTypesToLoad.push(referenceId)
          }          
        }
      }
    }

    if (loadingItemTypesStatus === "idle" || loadingItemTypesStatus === "fulfilled")  {
      if (inventoryItemTypesToLoad.length > 0) {
        dispatch(getInventoryItemTypeById({itemTypes: inventoryItemTypesToLoad}))
      }
    }
  }, [dosingReservoirs, allInventoryItemTypes, loadingItemTypesStatus])



  React.useEffect(() => {
    if (NDS.runtime_information["control_state"] !== undefined && NDS.runtime_information["control_state"] === "rack_dose") {
      if (NDS.currentActionInfo["zone_uid"] !== undefined)  {
        let foundZone = bladeZones.find((bZ) => bZ.uid === NDS.currentActionInfo["zone_uid"])
        if (foundZone !== undefined)  {
          console.log(foundZone)
          SetCurrentAction("Dosing Zone: " + foundZone.display_name)
          return
        }else {
          SetCurrentAction("Dosing Zone: " + NDS.currentActionInfo["zone_uid"])
          return
        }
      }
      //NDS.currentActionInfo["zone_uid"]selectVerticalRackZoneByUID
      SetCurrentAction("Dosing Zone: ")
      return
    }
    SetCurrentAction(NDS.currentAction)
  }, [NDS])

  React.useEffect(() => {
    setSubtitle(currentAction)
  }, [currentAction])




  
  const [toppingUpReservoirIndex, SetToppingUpReservoirIndex] = React.useState(null)
  const [toppingUpReservoir, SetToppingUpReservoir] = React.useState(false)
  const topUpNDSReservoir = React.useCallback((reservoirIndex) => {
    SetToppingUpReservoirIndex(reservoirIndex)
    SetToppingUpReservoir(true)
  })

  const topUpNDSReservoirWalkthroughCloseCallback = React.useCallback(() => {
    SetToppingUpReservoir(false)  
  })


  
  const processReservoirAdditionalOptions = (reservoirInfo) =>  {
    let reservoirOptions = [
      {key: "change_contents", label: "Change Contents"},
      {key: "prime", label: "Prime"},
      {key: "set_volume", label: "Set Volume"},
      {key: "reinstall", label: "Reinstall"},
      {key: "deactivate", label: "Deactivate"},
    ]

    return reservoirOptions
  }
  
  const handleReservoirAdditionalOptionSelected = (reservoirIndex, option) =>  {
    switch (option) {
      case "change_contents":

        break
      case "prime":
        
        break
      case "set_volume":
      
        break
      case "reinstall":
      
        break
      default:
        break
    }
  }


  const handleReservoirStateColorAndStatus = (reservoirInfo) => {
    //console.log(reservoirInfo)
    let statusInfo = {}


    if (reservoirInfo["connected"] !== true) {
      statusInfo.activated = false
      statusInfo = {...statusInfo, color: "#3C3C3C", status: "Unused", borderColor: "#DDE3EB"}
      return statusInfo
    }
    statusInfo.activated = true

    if (reservoirInfo.runtime_information["installed"] !== 1) {
      statusInfo.installedState = false
      statusInfo = {...statusInfo, color: "#EC3C3C", status: "Not Installed", borderColor: "#EC3C3C"}
      return statusInfo
    }

    statusInfo.installedState = true

    if (reservoirInfo.runtime_information["primed"] !== 1) {
      statusInfo.primedState = false
      if (reservoirInfo.runtime_information["priming"] === 1) {
        statusInfo = {...statusInfo, color: "#E99E0B", status: "Priming", borderColor: "#E99E0B"}
        return statusInfo
      }
      statusInfo = {...statusInfo, color: "#E99E0B", status: "Not Primed", borderColor: "#E99E0B"}
      return statusInfo
    }

    statusInfo.primedState = true
    return {...statusInfo, color: "#4F5562", status: "Primed", borderColor: "#DDE3EB"}
  }

  return (<>
    <div className="RowGroupRow-Content-Card-H">
      <div className="NDSStatus-NDS-Content">
        {isMobile && <>
          <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
            <div className={"NDSStatus-NDS-OnlineStatusCard" + (isNDSOnline ? " NDSStatus-NDS-OnlineStatusCard-Active" : " NDSStatus-NDS-OnlineStatusCard-Inactive")}>
              <div className="NDSStatus-NDS-OnlineStatusCard-StatusIndicator"></div>
              <div>Status: </div>
              <div>{isNDSOnline ? "Active" : "Stopped"}</div>
            </div>
            <div className="NDSStatus-NDS-CurrentActionCard">
                <div>Current Action:</div>
                <div className="NDSStatus-NDS-CurrentActions">
                  <div>{currentAction}</div>
                  <div>{NDS.currentSubaction ? NDS.currentSubaction : <>-</>}</div>
                </div>
            </div>
            <Button 
              status={"Primary-Toggle"} 
              content="Reservoirs" 
              state={currentContentTab === "reservoirs"} 
              width={"Flex33"}
              onClick={() => {SetCurrentContentTab("reservoirs")}}/>
            <div className="FlexContent-10 FlexContent-HFill FlexContent-HFlex">
              <div className={"NDSStatus-NDS-Reservoirs-List NDSStatus-NDS-Reservoirs-ListSize1"}>
                {Object.entries(dosingReservoirs).map(([index, info]) => {
                  console.log(info)
                  let reservoirVolume = 0
                  if (info["reservoir_volume"] !== undefined)  {
                    reservoirVolume = parseFloat(info["reservoir_volume"]) / 1000;
                  }
                  let reservoirLowVolume = 0
                  if (info["reservoir_low_volume"] !== undefined)  {
                    reservoirLowVolume = parseFloat(info["reservoir_low_volume"]) / 1000;
                  }

                  let volumeRemaining = 0
                  if (info["runtime_information"]["volume_remaining"] !== undefined)  {
                    volumeRemaining = parseFloat(info["runtime_information"]["volume_remaining"]) / 1000;
                  }

                  let reservoirDisplayName = "N/A"
                  if (info["item"]["name"] !== undefined) {
                    reservoirDisplayName = info["item"]["name"]
                  }

                  let reservoirVolumeState = "Okay"
                  if (volumeRemaining == 0) {
                    reservoirVolumeState = "Empty"
                  }else if (volumeRemaining < reservoirLowVolume) {
                    reservoirVolumeState = "Low"
                  }



                  const reservoirStatusInfo = handleReservoirStateColorAndStatus(info)

                  return (
                    <div className="NDSStatus-NDS-Reservoirs-Reservoir-Container" key={index}>
                      <div className="NDSStatus-NDS-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
                        <div className="NDSStatus-NDS-Reservoirs-Reservoir-MainContent">

                          <div className="NDSStatus-NDS-Reservoirs-Reservoir-MainContent-Header">
                            <div>Reservoir {index}</div>
                            <div>
                              {reservoirStatusInfo.activated && <>
                                {RoundToNearest(volumeRemaining, 0.1)}L/{RoundToNearest(reservoirVolume, 0.1)}L
                              </>}
                              {!reservoirStatusInfo.activated && <>-</>}
                            </div>
                          </div>

                          <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Container">
                            <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Name">{reservoirDisplayName}</div>
                            <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Status" style={{color:reservoirStatusInfo.color}}>{reservoirStatusInfo.status}</div>
                          </div>

                          <div className="NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Container">
                            <div className={"NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Background NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
                            {reservoirStatusInfo.activated && <>
                              <div className={"NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Bar NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                                style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                                ></div>
                              <div className="NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                                style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
                              </>
                            }
                          </div>

                          <div className="NDSStatus-NDS-Reservoirs-Reservoir-Functions-Container">
                            {reservoirStatusInfo.activated && <>
                              <Button 
                                content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><HalfFullCup/><span>Refill</span></div>} 
                                status="Small-IconAction" 
                                width={"Flex100"}
                                onClick={() => {topUpNDSReservoir(info.index)}}/>
                            </>}
                            {!reservoirStatusInfo.activated && <>
                              <Button 
                                content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                                status="Small-IconAction" 
                                width={"Flex100"}
                                onClick={() => {topUpNDSReservoir(info.index)}}/>
                            </>}
                            <DropDownButton 
                              content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
                              options={processReservoirAdditionalOptions(info)}
                              onOptionSelected={((option) => {
                                handleReservoirAdditionalOptionSelected(index, option.key)
                              })}/>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>}

        {!isMobile && <>
          <div className="FlexContent-10 FlexContent-HFlex">
            <div className="NDSStatus-NDS-InfoSide">
              {/*<div>
                <Button status="Interactive-Neutral" style={{paddingBottom:10}} size="Large-IconAction" content={<>
                      <div className="Button-Large-Icon"><ScanNDS width={isWideDesktop ? 150 : isDesktop ? 120 : 85}/></div>
                  </>}/>
                </div>*/}
              <div className={"NDSStatus-NDS-OnlineStatusCard" + (isNDSOnline ? " NDSStatus-NDS-OnlineStatusCard-Active" : " NDSStatus-NDS-OnlineStatusCard-Inactive")}>
                <div className="NDSStatus-NDS-OnlineStatusCard-StatusIndicator"></div>
                <div>Status: </div>
                <div>{isNDSOnline ? "Active" : "Stopped"}</div>
              </div>
              <div className="NDSStatus-NDS-CurrentActionCard">
                  <div>Current Action:</div>
                  <div className="NDSStatus-NDS-CurrentActions">
                    <div>{currentAction}</div>
                    <div>-</div>
                    <div>{NDS.currentSubaction ? NDS.currentSubaction : <>-</>}</div>
                  </div>
              </div>
            </div>
            <div className="NDSStatus-NDS-ManageCard">
              <div className="NDSStatus-NDS-ManageCard-Header">
                <Button 
                  status={"Primary-Toggle"} 
                  content="Reservoirs" 
                  state={currentContentTab === "reservoirs"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("reservoirs")}}/>
                <Button 
                  status={"Primary-Toggle"} 
                  content="Dosing History" 
                  state={currentContentTab === "dosing_history"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("dosing_history")}}/>
                <Button 
                  status={"Primary-Toggle"} 
                  content="Projections" 
                  state={currentContentTab === "projections"} 
                  width={"Flex33"}
                  onClick={() => {SetCurrentContentTab("projections")}}/>
              </div>
              <div className="NDSStatus-NDS-ManageCard-Content">
                {currentContentTab === "reservoirs" && <>
                  {(() => {
                    

                    let numberOfReservoirsPerRow = 2;
                    if (isTablet) {
                      numberOfReservoirsPerRow = 3;
                    }else if (isDesktop && !isWideDesktop)  {
                      numberOfReservoirsPerRow = 4;
                    }else if (isWideDesktop) {
                      numberOfReservoirsPerRow = 4;
                    }

                    
                    return <>
                      <div className="NDSStatus-NDS-Reservoirs-List-Container">
                        <div className={"NDSStatus-NDS-Reservoirs-List NDSStatus-NDS-Reservoirs-ListSize" + numberOfReservoirsPerRow}>
                          {Object.entries(dosingReservoirs).map(([index, info]) => {
                            let reservoirVolume = 0
                            if (info["reservoir_volume"] !== undefined)  {
                              reservoirVolume = parseFloat(info["reservoir_volume"]) / 1000;
                            }
                            let reservoirLowVolume = 0
                            if (info["reservoir_low_volume"] !== undefined)  {
                              reservoirLowVolume = parseFloat(info["reservoir_low_volume"]) / 1000;
                            }

                            let volumeRemaining = 0
                            if (info["runtime_information"]["volume_remaining"] !== undefined)  {
                              volumeRemaining = parseFloat(info["runtime_information"]["volume_remaining"]) / 1000;
                            }

                            let reservoirDisplayName = "-"
                            if (info["item"]["name"] !== undefined) {
                              reservoirDisplayName = info["item"]["name"]
                            }

                            const reservoirStatusInfo = handleReservoirStateColorAndStatus(info)


                            let reservoirVolumeState = "Okay"
                            if (reservoirStatusInfo.activated) {
                              if (volumeRemaining == 0) {
                                reservoirVolumeState = "Empty"
                              }else if (volumeRemaining < reservoirLowVolume) {
                                reservoirVolumeState = "Low"
                              }
                            }

                            return (
                              <div className="NDSStatus-NDS-Reservoirs-Reservoir-Container" key={index}>
                                <div className="NDSStatus-NDS-Reservoirs-Reservoir" style={{borderColor: reservoirStatusInfo.borderColor}}>
                                  <div className="NDSStatus-NDS-Reservoirs-Reservoir-MainContent">
    
                                    <div className="NDSStatus-NDS-Reservoirs-Reservoir-MainContent-Header">
                                      <div>Reservoir {index}</div>
                                      <div>
                                        {reservoirStatusInfo.activated && <>
                                          {RoundToNearest(volumeRemaining, 0.1)}L/{RoundToNearest(reservoirVolume, 0.1)}L
                                        </>}
                                        {!reservoirStatusInfo.activated && <>-</>}
                                      </div>
                                    </div>
    
                                    <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Container">
                                      <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Name">{reservoirDisplayName}</div>
                                      <div className="NDSStatus-NDS-Reservoirs-Reservoir-SolutionInfo-Status" style={{color:reservoirStatusInfo.color}}>{reservoirStatusInfo.status}</div>
                                    </div>

                                    <div className="NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Container">
                                      <div className={"NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Background NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Background-" + reservoirVolumeState}></div>
                                      {reservoirStatusInfo.activated && <>
                                        <div className={"NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Bar NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-Bar-" + reservoirVolumeState}
                                          style={{width: ((volumeRemaining / reservoirVolume) * 100) + "%"}}
                                          ></div>
                                        <div className="NDSStatus-NDS-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator"
                                          style={{left: ((reservoirLowVolume / reservoirVolume) * 100) + "%"}}></div>
                                      </>}
                                    </div>

                                    <div className="NDSStatus-NDS-Reservoirs-Reservoir-Functions-Container">
                                      {reservoirStatusInfo.activated && <>
                                        <Button 
                                          content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><HalfFullCup/><span>Refill</span></div>} 
                                          status="Small-IconAction" 
                                          width={"Flex100"}
                                          onClick={() => {topUpNDSReservoir(info.index)}}/>
                                      </>}
                                      {!reservoirStatusInfo.activated && <>
                                        <Button 
                                          content={<div style={{display:"flex", gap: 10, alignItems: "center"}}><Plus width={14.5} fill={"#4D5563"}/><span>Activate</span></div>} 
                                          status="Small-IconAction" 
                                          width={"Flex100"}
                                          onClick={() => {topUpNDSReservoir(info.index)}}/>
                                      </>}
                                      <DropDownButton 
                                        content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} 
                                        options={processReservoirAdditionalOptions(info)}
                                        onOptionSelected={((option) => {
                                          handleReservoirAdditionalOptionSelected(index, option.key)
                                        })}/>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>
                  })()}
                </>}
              </div>
            </div>
          </div>
        </>}
      </div>
    </div>
  </>)
}