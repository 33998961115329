//import './PageHeader.scss';
import React from 'react'


const OnlineStorePage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Online Store")
  }, [pageHeaderHelper])
  return (
    <>
      Online Store
    </>
  )
} 

export default OnlineStorePage