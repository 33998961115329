import './TransplantToGrowOutFromNurseryTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'

import { calculateUnitsFromZones, getGrowById, getGrowGerminationBoardLocations, getGrowGrowoutBoardLocations, getGrowNurseryBoardLocations } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import { getRecipeById, selectAllRecipes } from '../../../../redux/entities/Recipes'
import { useMediaQuery } from 'react-responsive';
import { getRaftTypeById, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes.js';
import Walkthrough from '../../../../components/Walkthrough.js';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities.js';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, selectAllBladeZones, selectAllBlades } from '../../../../redux/entities/service/Blade.js';
import { selectAllRecipeSetpointTypes } from '../../../../redux/AppInfo.js';
import TransplantToGrowOutFromNurseryTask_TransplantList from './TransplantToGrowOutFromNurseryTask_TransplantList.js';
import TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep from './TransplantToGrowOutFromNurseryTask_SelectGrowOutZoneStep.js';
import TransplantToGrowOutFromNurseryTask_SelectNurseryLocationsStep from './TransplantToGrowOutFromNurseryTask_SelectNurseryLocations.js';
import TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep from './TransplantToGrowOutFromNurseryTask_ConfirmUnitsMovedStep.js';
import TransplantToGrowOutFromNurseryTask_ReviewStep from './TransplantToGrowOutFromNurseryTask_ReviewStep.js';
import TransplantToGrowOutFromNurseryTask_FinalReviewStep from './TransplantToGrowOutFromNurseryTask_FinalReviewStep.js';
import TransplantToGrowOutFromNurseryTaskReview_TransplantList from './TransplantToGrowOutFromNurseryTaskReview_TransplantList.js';

const TransplantToGrowOutFromNurseryTaskReview = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }


  const allFacilities = useSelector(selectAllFacilities)
  const allRecipes = useSelector(selectAllRecipes)


  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }

    }
  }, [allFacilities])



  const allBlades = useSelector(selectAllBlades)
  const allBladeZones = useSelector(selectAllBladeZones)
  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!loadedInitialBladeGroups && loadingGroupsStatus !== "pending") {
        dispatch(getAllBladeGroupsByFacilityId({facilityIds: [task.facility_id]}))
      }
    }
  }, [loadingGroupsStatus, loadedInitialBladeGroups, allFacilities])



  


  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])



  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow !== undefined)  {
      if (!foundGrow.loaded_growout_locations && !foundGrow.loading_growout_locations) {
        dispatch(getGrowGrowoutBoardLocations({growId: foundGrow.id}))
      }
      if (!foundGrow.loaded_nursery_locations && !foundGrow.loading_nursery_locations) {
        dispatch(getGrowNurseryBoardLocations({growId: foundGrow.id}))
      }
    }
  }, [allGrows])













  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["transplant_list", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }


  




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      switch (stepKey)    {
        case "transplant_list":
          return (<TransplantToGrowOutFromNurseryTaskReview_TransplantList
            task={task}
            allBladeZones={allBladeZones}
            activeGrow={activeGrow}
            cancelTaskCallback={onCloseTask}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep}/>)

        
        default:
            return <></>
      }
  }


  return (<>
    <Walkthrough 
      title="Transplant Crops"
      subHeaderContent={<div className="Text-AltSubHeader">#G{activeGrow !== undefined ? activeGrow.id : ""}</div>}
      size="full"
      closeCallback={closePopup}
      hideMainSeparators={false}
      initialWalkthroughStep={["transplant_list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default TransplantToGrowOutFromNurseryTaskReview