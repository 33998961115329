
import '../GrowStatusList.scss';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { FormatDate } from '../../../helpers';
import Button from '../../../components/Button';
import { ImageCarousel } from '../../../components/ImageCarousel';
import { Repository } from '../../../redux/api';
import DropDownInput from '../../../components/input/DropDownInput';
import { addedNewGrowPhoto, downloadGrowPhoto, downloadingGrowPhoto, downloadingGrowPhotoFailed, downloadingGrowPhotoSuccessful, getAllGrowPhotos, setPhotoUploadProgress } from '../../../redux/entities/Grow';
import { useDispatch } from 'react-redux';
import UploadPhotoPopup from '../UploadGrowPhotoPopup/UploadPhotoPopup';
import ViewGrowPhotoPopup from '../ViewGrowPhotoPopup/ViewGrowPhotoPopup';
import { RecipeTimeline } from '../../RecipesAndPlans/Recipes/RecipeTimeline';
import { GetCurrentGrowZoneTimelineItem } from '../../../redux/entities/Recipes';
import { a } from 'react-spring';

const GrowStatus_GrowProgress = ({
  activeGrow, activeGrowPlan, activeRecipe,
  allBladeZones,
  zoneOptions, selectedZoneUID, onZoneSelectionChanged,
}) => {

  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const dispatch = useDispatch()



  const [currentActivePhotosVersion, SetCurrentActivePhotosVersion] = React.useState(-1)
  React.useLayoutEffect(() => {
    SetCurrentActivePhotosVersion(-1)
  }, [selectedZoneUID])
  const [activePhotos, SetActivePhotos] = React.useState([])
  React.useLayoutEffect(() => {
    if (activeGrow !== undefined)  {
      if (!activeGrow.loaded_all_grow_photos && !activeGrow.loading_all_grow_photos) {
        SetActivePhotos([])
        dispatch(getAllGrowPhotos({growId: activeGrow.id}))
      }else {
        if (currentActivePhotosVersion !== activeGrow.grow_photos_version)  {
          let photos = activeGrow.grow_photos.filter((gP) => gP.zone_uid === selectedZoneUID)
          let localPhotoIndex = 0
          photos = photos.map((p) => {
            return {...p, elapsed_within_zone: new Date(p.photo_date).getTime() - new Date(p.zone_recipe_start_date).getTime()}
          })
          photos.sort((a, b) => a.elapsed_within_zone - b.elapsed_within_zone)
          photos = photos.map((p) => {
            return {...p, 
              local_photo_index: localPhotoIndex++, 
              timeline_item_id: null,
              elapsed_within_timeline_item: 0}
          })
          console.log(photos)
          SetActivePhotos(photos)
          SetCurrentActivePhotosVersion(activeGrow.grow_photos_version)
        }
      }
    }
  }, [activeGrow, selectedZoneUID, currentActivePhotosVersion])



  React.useEffect(() => {
    //Find the zone the photo was taken in, based on zone, based on zone, use recipe api to find timeline item and elapsed time within
    let changed = false
    let updatedPhotos = [...activePhotos]
    for (let photo of updatedPhotos)  {
      let foundZone = allBladeZones.find((bZ) => bZ.uid === photo.zone_uid)
      if (foundZone !== undefined)  {
        if (foundZone.zone_type === "nursery") {

        }else if (foundZone.zone_type === "grow_boards" || foundZone.zone_type === "berry_troughs") {
          //console.log(photo.elapsed_within_zone)
          let [timelineItem, elapsedWithinTimelineItem] = GetCurrentGrowZoneTimelineItem(activeRecipe.timeline_items, photo.elapsed_within_zone, "grow_zone_cycle")
          if (timelineItem !== null)  {
            if (photo.timeline_item_id !== timelineItem.id) {
              photo.timeline_item_id = timelineItem.id
              changed = true
            }
            if (photo.elapsed_within_timeline_item !== elapsedWithinTimelineItem) {
              photo.elapsed_within_timeline_item = elapsedWithinTimelineItem
              changed = true
            }
          }
        }
      }
    }
    if (changed)  {
      //console.log(updatedPhotos)
      SetActivePhotos(updatedPhotos)
    }
  }, [activeRecipe, allBladeZones, activePhotos])


  React.useEffect(() => {
    for (let photo of activePhotos) {
      if (!photo.downloaded && !photo.downloading && (photo.last_download_attempt_on === undefined || (new Date().getTime() - photo.last_download_attempt_on.getTime()) / 1000 > 2)) {
        dispatch(downloadingGrowPhoto({growId: activeGrow.id, storageKey: photo.storage_key}))

        let xhr = new XMLHttpRequest()
        xhr.open('get', Repository.Grows.DownloadGrowPhoto({accountId: 29, storageKey: photo.storage_key}))
        xhr.responseType = "blob";
        xhr.onload = () => {
          if (xhr.status === 200) {
            let src = window.URL.createObjectURL(xhr.response)
            dispatch(downloadingGrowPhotoSuccessful({growId: activeGrow.id, storageKey: photo.storage_key, src: src}))
          } else {
            dispatch(downloadingGrowPhotoFailed({growId: activeGrow.id, storageKey: photo.storage_key}))
          }
        }
        xhr.onprogress = (e) => {
          //console.log(e)
          //photo.progress = e.loaded / e.total
        }
        xhr.send()
      }
    }
  }, [activePhotos])



  const fileInputRef = React.useRef(null)
  const uploadPhotoClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const [photosUploading, SetPhotosUploading] = React.useState([])
  const [confirmingPhotosUploading, SetConfirmingPhotosUploading] = React.useState(false)
  const [isUploading, SetIsUploading] = React.useState(false)
  const [uploadingCompleted, SetUploadingCompleted] = React.useState(false)
  const onPhotoSelected = (e) => {
    let foundZone = allBladeZones.find((bZ) => bZ.uid === selectedZoneUID)
    if (foundZone === undefined || foundZone.active_zone_recipe === undefined || foundZone.active_zone_recipe === null)  {
      return
    }
    //console.log(e) 
    let updatedPhotosUploading = [...photosUploading]
    let photoIndex = 0
    for (let photo of activePhotos) {
      if (photo.photo_index >= photoIndex) {
        photoIndex = photo.photo_index + 1
      }
    }
    for (let photo of updatedPhotosUploading) {
      if (photo.photoIndex >= photoIndex) {
        photoIndex = photo.photoIndex + 1
      }
    }
    //console.log(e.target.files)
    /*let elapsedWithinZone = 0
    let foundZone = allBladeZones.find((bZ) => bZ.uid === selectedZoneUID)
    if (foundZone !== undefined)  {
      if (foundZone.active_zone_recipe !== undefined && foundZone.active_zone_recipe !== null)  {
        elapsedWithinZone = Math.floor((new Date().getTime() - new Date(foundZone.active_zone_recipe.started_on).getTime()) / 1000)
      }
    }*/
    for (let file of e.target.files)  {
      updatedPhotosUploading.push({
        uploaded: false,
        uploading: false,
        pending_confirmation: true,
        zone_recipe_start_date: foundZone.active_zone_recipe.started_on,
        progress: 0,
        failed: false,
        file: file,
        request: null,
        photoName: "Photo " + (photoIndex + 1).toString(),
        photoIndex: photoIndex++,
        date: new Date(),
        fileName: file.name,
        ext: file.name.split(".")[1]
      })
    }

    SetPhotosUploading(updatedPhotosUploading)

    SetConfirmingPhotosUploading(true)
  }

  const editUploadingPhoto = (photoIndex, props) => {
    SetPhotosUploading([...photosUploading.map((photo) => {
      if (photo.photoIndex !== photoIndex)  {
        return photo
      }
      return {...photo, ...props}
    })])
  }

  const deletePhotoFromUploading = (photoIndex) => {
    SetPhotosUploading([...photosUploading.filter((photo) => photo.photoIndex !== photoIndex)])
  }

  const beginPhotoUpload = () => {
    SetIsUploading(true)
    SetPhotosUploading([...photosUploading.map((photo) => {
      return {...photo, pending_confirmation: false}
    })])
  }
  React.useEffect(() => {
    if (isUploading)  {
      let stillUploading = false
      for (let photo of photosUploading)  {
        if (photo.uploading || (!photo.uploaded && !photo.failed))  {
          stillUploading = true
        }
      }
      if (!stillUploading)  {
        SetIsUploading(false)
        SetUploadingCompleted(true)
      }
    }
  }, [photosUploading, isUploading])
  React.useEffect(() => {
    let changed = false
    let updatedPhotosUploading = [...photosUploading]
    for (let photo of updatedPhotosUploading)  {
      if (!photo.uploaded && !photo.uploading && !photo.failed && !photo.pending_confirmation)  {
        const formData = new FormData();
        formData.append("photo", photo.file);
        //console.log(activeGrow)
        formData.append("grow_id", activeGrow.id)
        formData.append("zone_uid", selectedZoneUID)
        formData.append("photo_index", photo.photoIndex)
        formData.append("photo_name", photo.photoName)
        formData.append("photo_date", photo.date.toISOString())
        formData.append("zone_recipe_start_date", photo.zone_recipe_start_date)
        photo.uploading = true
        photo.progress = 0

        let xhr = new XMLHttpRequest()
        xhr.addEventListener('loadend', (request) => {
          if (xhr.status === 200) {
            photo.uploaded = true
            photo.failed = false
            photo.uploading = false
            const response = JSON.parse(request.target.response)
            let updatedPhotos = [...photosUploading.map((pU) => {
              if (pU.photoIndex !== photo.photoIndex) {
                return pU
              }
              return {...pU, 
                failed: false,
                uploaded: true,
                uploading: false,
              }
            })]
            SetPhotosUploading(updatedPhotos)
            
            dispatch(addedNewGrowPhoto({growId: activeGrow.id, entry: {
              id: response.new_photo_id,
              zone_uid: selectedZoneUID,
              photo_index: photo.photoIndex,
              elapsed_within_zone: new Date().getTime() - new Date(photo.zone_recipe_start_date).getTime(),
              zone_recipe_start_date: photo.zone_recipe_start_date,
              file_name: photo.fileName,
              photo_name: photo.photoName,
              storage_key: response.storage_key,
              ext: photo.ext,
              uploaded_on: new Date().toISOString(),
              photo_date: photo.date.toISOString(),
            }}))
          } else {
            
            let updatedPhotos = [...photosUploading.map((pU) => {
              if (pU.photoIndex !== photo.photoIndex) {
                return pU
              }
              return {...pU, 
                uploaded: false,
                uploading: false,
                failed: true
              }
            })]
            SetPhotosUploading(updatedPhotos)
          }
        })
        xhr.upload.addEventListener('progress', e => {
          dispatch(setPhotoUploadProgress({growId: activeGrow.id, photoIndex: photo.photoIndex, progress: e.loaded / e.total}))
          
          photo.progress = e.loaded / e.total
          let updatedPhotos = [...photosUploading.map((pU) => {
            if (pU.photoIndex !== photo.photoIndex) {
              return pU
            }
            return {...pU, progress: e.loaded / e.total}
          })]
          SetPhotosUploading(updatedPhotos)
        })
        xhr.open('post', Repository.Grows.UploadGrowPhoto)
        xhr.send(formData)

        changed = true
      }
    }
    if (changed)  {
      SetPhotosUploading(updatedPhotosUploading)
    }
  }, [photosUploading, confirmingPhotosUploading])


  const cancelUploadClicked = () => {
    SetPhotosUploading([])
    SetConfirmingPhotosUploading(false)
  }

  const doneUploading = () => {
    SetPhotosUploading([])
    SetConfirmingPhotosUploading(false)
    SetIsUploading(false)
    SetUploadingCompleted(false)
  }


  const [isViewingPhoto, SetIsViewingPhoto] = React.useState(null)
  const viewPhotoClicked = (photo) => {
    SetIsViewingPhoto(photo.local_photo_index)
  }
  const viewPhotosClicked = () => {
    SetIsViewingPhoto(activePhotos[0].local_photo_index)
  }
  const doneViewingPhoto = () => {
    SetIsViewingPhoto(null)
  }


  let recipeTimelinePhotoEntries = []
  for (let photo of activePhotos) {
    recipeTimelinePhotoEntries.push({
      key: photo.id,
      timeline_item_id: photo.timeline_item_id,
      elapsed: photo.elapsed_within_timeline_item
    })
  }
  const [activePhotoIndex, SetActivePhotoIndex] = React.useState(0)
  const recipeTimelinePhotoEntryClicked = (events) => {
    let foundPhoto = activePhotos.find((p) => p.id === events[0])
    if (foundPhoto) {
      SetActivePhotoIndex(foundPhoto.local_photo_index)
    }
  }
  const imageIndexChanged = (index) => {
    SetActivePhotoIndex(index)
  }
  
  let foundPhoto = activePhotos.find((p) => p.local_photo_index === activePhotoIndex)

  return (
    <>
      <div className="FlexContent-20" style={{padding: isMobile ? "20px 10px" : "20px 20px"}}>
        <div className="FlexContent-5 FlexContent-Center">
          <DropDownInput
            placeholder={"Select a zone"}
            value={selectedZoneUID}
            options={zoneOptions}
            onSelectionChange={onZoneSelectionChanged}/>
        </div>
        <div className="FlexContent-10"> 
          <ImageCarousel 
            imageIndex={activePhotoIndex}
            images={activePhotos} 
            imageHeight={isMobile ? 84 : (isTablet ? 105 : 165)} 
            aspectRatio={1.33} 
            fadeStrength={0.5} 
            onImageClicked={viewPhotoClicked}
            onIndexChanged={imageIndexChanged}/>

          <div className="GrowStatus_GrowProgress-PhotoTimeline">

            <RecipeTimeline
              recipe={activeRecipe}
              showProgress={false}
              recipeTimelineEventEntries={recipeTimelinePhotoEntries}
              onTimelineEventEntryClicked={recipeTimelinePhotoEntryClicked}
              selectedEventKey={foundPhoto !== undefined ? foundPhoto.id : 0}
              showDuration={false}
              showDayTicks={false}/>
          </div>
        </div> 

        <div className={isMobile ? "FlexContent-5" : "FlexContent-H-10 FlexContent-HFlex"}> 
          <Button content="View Photos" status="Neutral" size={"Flex100"} onClick={viewPhotosClicked} disabled={activePhotos.length === 0}/>
          <input 
            ref={fileInputRef} 
            type="file" 
            name="library-images" 
            id="library-images" 
            multiple 
            accept="image/png, image/gif, image/jpeg" 
            hidden
            onChange={onPhotoSelected}/>
          <Button content={<>Upload Photo</>} size={"Flex100"} onClick={uploadPhotoClicked}/>
        </div>
      </div>


      {confirmingPhotosUploading && <>
        <UploadPhotoPopup 
          activeGrow={activeGrow} activeGrowPlan={activeGrowPlan} 
          selectedZoneUID={selectedZoneUID}
          photos={photosUploading}
          zoneOptions={zoneOptions}
          isUploading={isUploading}
          uploadingCompleted={uploadingCompleted}
          addPhotoCallback={uploadPhotoClicked}
          deletePhotoCallback={deletePhotoFromUploading}
          editPhotoCallback={editUploadingPhoto}
          beginUploadCallback={beginPhotoUpload}
          doneUploadingCallback={doneUploading}
          onClosePopup={cancelUploadClicked}/>
      </>}

      {isViewingPhoto !== null && <>
        <ViewGrowPhotoPopup 
          activeGrow={activeGrow} 
          activeGrowPlan={activeGrowPlan} 
          activeRecipe={activeRecipe}
          selectedZoneUID={selectedZoneUID}
          photos={activePhotos}
          initialActivePhotoIndex={isViewingPhoto}
          deletePhotoCallback={deletePhotoFromUploading}
          doneViewingCallback={doneViewingPhoto}
          onClosePopup={doneViewingPhoto}/>
      </>}
    </>
  )
}

GrowStatus_GrowProgress.defaultProps = {
  
}


export default GrowStatus_GrowProgress