import './RecordsPage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'


const data = [
  { id: 1, date_created: "Mon Jul 05 2022", name: 'Purchased Seeds', category: "Expense", users:[] },
];


const RecordsPage = ({setPageHeader}) => {
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])


  const columns: GridColDef[] = [
    { accessor: 'date_created', Header: 'Date Added', type: 'date', width: "200", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'name', Header: 'Name', width: "200", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<string>) => (
      <>
        <div>
          {params.value}
        </div>
      </> 
    ), },
    { accessor: 'category', Header: 'Category', minWidth: "120", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<Map>) => (
      <Badge status="Neutral" content={params.value} size="Medium"/>
    ), },
    { accessor: 'users', Header: 'Users', minWidth: "120", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<Map>) => (
      <>{params.value}</>
    ), },
  ];


  return (
    <div id="RecordsPage" >
        <div className="ControlBar_Horizontal">
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'date', label: 'Date' }]} defaultValue="date" prefix="Sort By"/>
        </div>
        <StandardTable id="RecordsTable" data={data} columns={columns} isSelectableRows={true} isResizable={true}/>
    </div>
  )
} 

export default RecordsPage