import './SliderInput.scss';

import React from 'react'
import { useMeasure } from '../../helpers'
import {FaLock, FaUnlock} from 'react-icons/fa'
import NumberInput from './NumberInput';




const SliderInput = ({orientation, value, lockable, locked, dialDisplay, dialSize, color, fontSize, onValueChanged, onValueChangedComplete, onLockChanged, min, max, step, disabled}) =>  {
    const sliderRef = React.useRef()
    const [pointerId, SetPointerId] = React.useState(null)
    const [barHeight, SetBarHeight] = React.useState(0)
    const [barWidth, SetBarWidth] = React.useState(0)
    const [currentValue, SetCurrentValue] = React.useState(value)
    const [currentLocked, SetCurrentLocked] = React.useState(locked)
    const [sliderDragging, SetSliderDragging] = React.useState(false)
    const updateBarDimensions = React.useCallback((bounds) =>  {
        if (bounds !== undefined) {
            if (orientation === "vertical") {
                SetBarHeight(bounds.height)
            }else if (orientation === "horizontal") {
                SetBarWidth(bounds.width)
            }
        }
    })
    const [barBind, {documentTop: barTop, documentLeft: barLeft}] = useMeasure(updateBarDimensions)
    
    const onSliderPointerDown = (e) => {
        if (disabled)   {
            return
        }
        e.preventDefault()
        e.stopPropagation()
        if (currentLocked)
            onLockToggle()
        if (sliderRef.current !== undefined && sliderRef.current.setPointerCapture)    {
            SetPointerId(e.pointerId)
            sliderRef.current.setPointerCapture(e.pointerId);
        }

        SetSliderDragging(true)
    }
    const onPointerMove = (e) =>  {
        if (disabled)   {
            return
        }
        e.preventDefault()
        e.stopPropagation()
        if (sliderDragging) {
            let pointerPosition = {top: 0, left: 0}
            pointerPosition.top = e.clientY
            pointerPosition.left = e.clientX

            let pointerOffset = {y: pointerPosition.top - barTop, x: pointerPosition.left - barLeft}
            
            let currentRatio
            if (orientation === "vertical") {
                currentRatio = Math.round((max - ((pointerOffset.y / barHeight) * max)) * (1 / step)) / (1 / step);
            }else if (orientation === "horizontal") {
                currentRatio = Math.round(((pointerOffset.x / barWidth) * max) * (1 / step)) / (1 / step);
            }
            if (currentRatio > max)  {
                currentRatio = max
            }else if (currentRatio < min)    {
                currentRatio = min
            }
            if (onValueChanged) {
                const result = onValueChanged(currentRatio)
                if (result !== undefined)   {
                    SetCurrentValue(result)
                }else {
                    SetCurrentValue(currentRatio)
                }
            }else {
                SetCurrentValue(currentRatio)
            }
        }
    }
    const onPointerUp = (e) =>  {
        if (disabled)   {
            return
        }
        if (sliderRef.current !== undefined && sliderRef.current.releasePointerCapture)    {
            sliderRef.current.releasePointerCapture(pointerId);
        }
        SetSliderDragging(false)

        if (onValueChangedComplete) {
            onValueChangedComplete()
        }
    }

    const numberValueChanged = (newValue) => {
        if (disabled)   {
            return
        }
        let valid = true
        if (newValue < min)  {
          newValue = min
          valid = false
        }else if (newValue > max)  {
          newValue = max
          valid = false
        }
        if (onValueChanged) {
            const result = onValueChanged(newValue)
            if (result !== undefined)   {
                SetCurrentValue(result)
            }else {
                SetCurrentValue(newValue)
            }
        }else {
            if (!valid) {
                return newValue
            }
        }        

      }

      const finalizeNumberValueChange = (newValue) =>  {
        if (disabled)   {
            return
        }
        //SetCurrentValue(newValue)
        if (onValueChangedComplete) {
            onValueChangedComplete()
        }
      }

    const onLockToggle = () => {
        if (disabled)   {
            return
        }
        if (onLockChanged)  {
            const result = onLockChanged(!currentLocked)
            if (result !== undefined)   {
                SetCurrentLocked(result)
            }else {
                SetCurrentLocked(!currentLocked)
            }
        }else {
            SetCurrentLocked(!currentLocked)
        }
    }


    React.useEffect(() => {
        let changed = false
        if (value < min)    {
            value = min
            changed = true
        }else if (value > max)  {
            value = max
            changed = true
        }
        SetCurrentValue(value)
        if (changed && onValueChanged)    {
            onValueChanged(value)
        }
    }, [value])
    React.useEffect(() => {
        SetCurrentLocked(locked)
    }, [locked])

    let displayBarProps = {style:{backgroundColor: color}}
    let dialProps = {style: {backgroundColor: color, width: dialSize, height:dialSize, borderRadius: dialSize}}
    if (orientation === "vertical") {
        let valueY = barHeight * ((max - currentValue) / max)
        displayBarProps.style.top = valueY
        displayBarProps.style.height = barHeight - valueY
        dialProps.style.top = valueY
    }else if (orientation === "horizontal") {
        let valueX = barWidth * (currentValue / max)
        displayBarProps.style.width = valueX
        dialProps.style.left = valueX
    }



    return (
      <div className={"SliderInput" + (orientation === "vertical" ? " SliderInput-Vertical" : "") + (orientation === "horizontal" ? " SliderInput-Horizontal" : "")}>
        <NumberInput 
            stepper={false} 
            value={(Math.round(currentValue * 10) / 10)} 
            min={min} max={max} stepAmount={step}
            fontSize={fontSize}
            disabled={disabled}
            showDisabledVisual={false}
            onChange={numberValueChanged} onBlur={finalizeNumberValueChange}/>
        {/*<div className="SliderInput-Value">
          {(Math.round(currentValue * 10) / 10)}
        </div>*/}
        <div className="SliderInput-BarContainer" {...barBind}>
          <div className="SliderInput-BackBar"></div>
          <div className="SliderInput-DisplayBar" {...displayBarProps}></div>
          <div className="SliderInput-Dial noselect"
              onPointerDown={onSliderPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={onPointerUp}
              ref={sliderRef} {...dialProps}>
              {dialDisplay}
          </div>
        </div>
        {lockable && 
            <div className="SliderInput-LockingToggle" onClick={onLockToggle}>
            {currentLocked ?
                <FaLock/>
                :
                <FaUnlock/>
            }
            </div>
        }
      </div>
    )
  }


  
  SliderInput.defaultProps = {
    orientation: "vertical",
    lockable: true,
    value: 0,
    min: 0,
    max: 100,
    fontSize: 14,
    color: "#2E72D2",
    dialDisplay: "",
    dialSize: 20,
    step: 1
  }
  
  
  export default SliderInput