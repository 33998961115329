import './StandardList.scss';
import React from 'react'

import StandardListRow from './StandardListRow.js' 

const StandardListGroup = ({
    group, columns, columnBounds, dynamicRowClassName,
    columnSpacing, columnPadding, rowPadding, groupIndent, groupedColumnPadding,
    onRowSelected, isRowsSelectable, onRowClicked,
    getRowDimensions, listY
  }) => {
  const [currentColumns, SetColumns] = React.useState(columns)
 
  //collapsable 

  const rowSelected = (row) =>  {
    if (onRowSelected !== undefined)  {
      onRowSelected(row)
    }
  }

  const rowClicked = (row) =>  {
    if (onRowClicked !== undefined)  {
      onRowClicked(row)
    }
  }

  return (
    <div className={"StandardList-Group"}>
      <div className="StandardList-Group-HeaderContainer">
        {group.render}
      </div>
      {(group.rows !== undefined && Object.entries(group.rows).length > 0) &&
        <div className="StandardList-Group-Rows" style={{marginLeft: groupIndent}}>
          {Object.entries(group.rows).map(([rowKey, row]) => {
            return (
              <StandardListRow 
                key={rowKey}
                rowKey={rowKey}
                row={row} 
                dynamicRowClassName={dynamicRowClassName}
                columns={currentColumns} 
                columnBounds={columnBounds}
                columnSpacing={columnSpacing} 
                columnPadding={columnPadding}
                groupedIndent={groupIndent}
                groupedColumnPadding={groupedColumnPadding}
                rowPadding={rowPadding} 
                isSelectable={isRowsSelectable}
                onSelected={rowSelected}
                onClicked={rowClicked}
                getRowDimensions={getRowDimensions}
                listY={listY}/>
            )
          })}
        </div>
      }
    </div>
  )
} 

StandardListGroup.defaultProps = {
  rows: {},
  showHeader: false,
  collapsable: true
}


export default StandardListGroup