import React from 'react';
import './ImageCarousel.scss';
import { GoBack, GoForward } from '../assets/icons/Icons';
import Button from './Button';
import useMeasure from '../useMeasure';
import useSwipe from '../useSwipe';


export const ImageCarousel = ({
    images,
    imageIndex,
    isolateIndex,
    isolateImage,
    imageHeight, aspectRatio, imageGap,
    fadeStrength, onImageClicked,
    buttonSize,
    navigationDisabled,
    onIndexChanged,
  }) => {

  //console.log(isolateIndex)

  const [activeImageIndex, SetActiveImageIndex] = React.useState(0)
  const [numberOfVisibleImages, SetNumberOfVisibleImages] = React.useState(0)
  React.useLayoutEffect(() => {
    if (imageIndex !== null)  {
      let desiredIndex = imageIndex
      if (desiredIndex <= 0 || numberOfVisibleImages > images.length) {
        desiredIndex = 0
      }else if (desiredIndex > images.length + 2 - numberOfVisibleImages) {
        desiredIndex = images.length + 2 - numberOfVisibleImages
      }
      SetActiveImageIndex(desiredIndex)
    }else if (isolateImage && isolateIndex !== null) {
      const centerImageIndex = Math.ceil(numberOfVisibleImages / 2) - 1
      let activeIndex = isolateIndex - centerImageIndex
      if (activeIndex < 0)  {
        activeIndex = 0
      }else if (activeIndex > images.length + 2 - numberOfVisibleImages)  {
        activeIndex = images.length + 2 - numberOfVisibleImages
      }
      SetActiveImageIndex(activeIndex)
    }
  }, [imageIndex, isolateImage, isolateIndex, numberOfVisibleImages])

  const [carouselHandleRef, { width: carouselWidth }] = useMeasure()


  React.useLayoutEffect(() => {
    let numberOfImages = Math.ceil(carouselWidth / ((imageHeight * aspectRatio) + (imageGap / 2)))
    if (numberOfImages % 2 == 0)  {
      numberOfImages += 1
    }
    SetNumberOfVisibleImages(numberOfImages)
  }, [imageHeight, aspectRatio, carouselWidth, imageGap])
  
  const [canMoveBackward, SetCanMoveBackward] = React.useState(false)
  const [canMoveForward, SetCanMoveForward] = React.useState(false)
  React.useLayoutEffect(() => {
    if (navigationDisabled) {
      SetCanMoveBackward(false)
      SetCanMoveForward(false)
    }else {
      if (isolateImage) {
        const centerImageIndex = Math.ceil(numberOfVisibleImages / 2) - 1 //This is where we need to be able to navigate to.
        SetCanMoveBackward(isolateIndex > 1)
        SetCanMoveForward(isolateIndex < images.length)

      }else {
        SetCanMoveBackward(activeImageIndex > 0)
        SetCanMoveForward(images.length - activeImageIndex > numberOfVisibleImages - 2)
      }
    }

  }, [isolateImage, isolateIndex, activeImageIndex, images, numberOfVisibleImages, navigationDisabled])

  

  const [activeImages, SetActiveImages] = React.useState({})
  

  React.useLayoutEffect(() => {
    let updatedImages = {}   
    for (let i = 0; i < images.length; i++) {
      updatedImages[i] = {
        "empty": false,
        "image": images[i],
      }
    }
    for (let i = images.length; i < numberOfVisibleImages - 2; i++) {
      updatedImages[i] = {
        "empty": true,
        "image": null,
      }
    }
    SetActiveImages(updatedImages)
  }, [images])

  const previousPhotoClicked = () => {
    SetActiveImageIndex(activeImageIndex - 1)
    if (onIndexChanged !== undefined) {
      if (isolateIndex !== null)  {
        onIndexChanged(isolateIndex - 1)
      }else {
        onIndexChanged(activeImageIndex - 1)
      }
    }
  }
  const nextPhotoClicked = () => {
    SetActiveImageIndex(activeImageIndex + 1)
    if (onIndexChanged !== undefined) {
      if (isolateIndex !== null)  {
        onIndexChanged(isolateIndex + 1)
      }else {
        onIndexChanged(activeImageIndex + 1)
      }
    }
  }

  const imageWidth = imageHeight * aspectRatio
  const centerImageIndex = Math.ceil(numberOfVisibleImages / 2) - 1


  const swipeHandlers = useSwipe({ 
    onSwipedLeft: (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (canMoveForward)  {
        nextPhotoClicked()
      }
    }, 
    onSwipedRight: (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (canMoveBackward)  {
        previousPhotoClicked()
      }
    }
  });


  return (
    <div className="ImageCarousel-Container" {...swipeHandlers}>
      <Button content={<GoBack disabled={!canMoveBackward} width={buttonSize}/>} status={"Hyperlink"} contentPadding={0} disabled={!canMoveBackward} onClick={previousPhotoClicked}/>
      <div className="ImageCarousel" style={{height: imageHeight}} ref={carouselHandleRef}>

        {Object.entries(activeImages).map(([imageIndexAsString, image]) => {
          let imageIndex = parseInt(imageIndexAsString)
          let imageLocationIndex = imageIndex
          if (activeImageIndex === 0) {
            imageLocationIndex += 1
          }else if (activeImageIndex > 1) {
            imageLocationIndex -= (activeImageIndex - 1)
          }
          let carouselCenter = carouselWidth / 2
          let imagePosition = carouselCenter + ((imageLocationIndex - centerImageIndex) * (imageWidth + imageGap)) - (imageWidth / 2)

          /*let positionFromCenter = ((imagePosition + (imageWidth / 2)) > carouselCenter) ? (imagePosition + (imageWidth / 2)) - carouselCenter : carouselCenter - (imagePosition + (imageWidth / 2))
          let ratioFromCenter = positionFromCenter / (carouselCenter + (imageWidth / 2))
          let opacity = ((1 - (ratioFromCenter * fadeStrength)) * 100)*/
          
          let isImageCutoff = (imagePosition < 0) || (imagePosition + imageWidth > carouselWidth)
          
          const imageContainerProps = {style:{
            height: imageHeight,
            width: imageWidth,
            left: imagePosition,
          }}

          if (isolateImage)  {
            if (imageIndex + 1 !== isolateIndex)  {
              imageContainerProps.style.opacity = "50%"
            }
          }else if (isImageCutoff) {
            imageContainerProps.style.opacity = "50%"
          }

          const imageClicked = () => {
            if (onImageClicked !== undefined) {
              onImageClicked(image.image)
            }
          }
          return (
            <div className={"ImageCarousel-Image-Container"} onClick={imageClicked} {...imageContainerProps}>
              <div className="ImageCarousel-Image">
                {(images.length === 0 && imageLocationIndex === centerImageIndex) && 
                  <div className="ImageCarousel-Image-EmptyContent Text-AuxSubtext Text-Wrap">No Photos Available</div>
                }
                {(!image["empty"]) && <>
                  {image["image"]["downloaded"] && <>
                    <div className="ImageCarousel-Image-Content">
                      <img src={image["image"]["src"]}/>
                    </div> 
                  </>}
                  {(!image["image"]["downloaded"]) && <>
                    <div className="ImageCarousel-Image-Loading">
                      <div className="lds-ring lds-ring-size20"><div></div><div></div><div></div><div></div></div>
                    </div> 
                  </>}                                 
                </>}
              </div>
            </div>
          )
        })}

      </div>      
      <Button content={<GoForward disabled={!canMoveForward} width={buttonSize}/>} status={"Hyperlink"} contentPadding={0} disabled={!canMoveForward} onClick={nextPhotoClicked}/>
    </div>
  )
} 

ImageCarousel.defaultProps = {
  images: [],
  imageHeight: 100,
  imageIndex: null,
  isolateIndex: null,
  isolateImage: false,
  aspectRatio: 1.333,
  fadeStrength: 1.00,
  imageGap: 6,
  navigationDisabled: false,
  buttonSize: 30
}