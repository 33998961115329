
import './SelectNutrientSubParts.scss';
import React from 'react'

import {StandardList, StandardListRow} from '../components/StandardList/StandardList.js'
import { getInventoryItemsByItemTypeGroupId, getInventoryItemTypeById, getInventoryItemTypeGroupByPath, getInventoryItemTypesByGroupId, selectAllInventoryItems, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypes, selectInventoryItemTypeGroupByPath } from '../redux/entities/Inventory';
import { useDispatch, useSelector } from 'react-redux';
import RowGroupSubGroup from '../components/RowGroup/RowGroupSubGroup.js';
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../components/RowGroup/RowGroup.js';
import RowGroupRow from '../components/RowGroup/RowGroupRow.js';


const SelectNutrientSubParts = ({selectedNutrientSubPart, onNutrientSubPartSelection}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const [inventoryItemGroupPaths, SetInventoryItemGroupPaths] = React.useState([
    ["Consumables", "Nutrient Products"],
    ["Consumables", "Solutions"],
  ])

  
  let allInventoryItems = useSelector(selectAllInventoryItems)
  let allInventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  let allInventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)

  const [nutrientItemTypeGroups, SetNutrientItemTypeGroups] = React.useState([])

  const dispatch = useDispatch()
  React.useEffect(() => {
    let itemTypeGroups = []
    for (let groupPath of inventoryItemGroupPaths)  {
      let group = selectInventoryItemTypeGroupByPath(allInventoryItemTypeGroups, groupPath)
      if (group === null)  {
        dispatch(getInventoryItemTypeGroupByPath({itemTypeGroupPath: groupPath}))
      }else   {
        itemTypeGroups.push(group)
      } 
    }

    SetNutrientItemTypeGroups(itemTypeGroups)
  }, [allInventoryItemTypeGroups])


  React.useEffect(() => {
    let itemTypesByGroupsToLoad = []
    for (let group of nutrientItemTypeGroups) {
      if (!group.loadedItemTypes && !group.loadingItemTypes) {
        itemTypesByGroupsToLoad.push(group.id)
      } 
    }
    if (itemTypesByGroupsToLoad.length > 0) {
      //We have the two groups filtered, lets get all inventory items for these 2 group ids
      dispatch(getInventoryItemTypesByGroupId({itemTypeGroupIds: itemTypesByGroupsToLoad}))
    }
  }, [nutrientItemTypeGroups])
  


  const [nutrientItemTypeGroupsList, SetNutrientItemTypeGroupsList] = React.useState([])
  const [nutrientItemTypesList, SetNutrientItemTypesList] = React.useState([])
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedNutrientSubPart, SetSelectedNutrientSubPart] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedNutrientSubPart !== selectedNutrientSubPart) {
      SetSelectedNutrientSubPart(selectedNutrientSubPart)
    }
  }, [selectedNutrientSubPart])
  
  const [currentGroupByTypeFilter, SetGroupByTypeFilter] = React.useState('*')
  


  React.useEffect(() => {
    let currentInventoryItemTypeGroupsList = {}
    let currentInventoryItemTypesList = {}
    let currentInventoryItemTypes = []
    
    let allApplicableInventoryItemTypes = allInventoryItemTypes.filter((iIT) => nutrientItemTypeGroups.find((nTG) => nTG.id === iIT.type_group_id) !== undefined)
    let availableInventoryItemTypes = []


    if (currentGroupByTypeFilter === "*") {
      availableInventoryItemTypes = [...allApplicableInventoryItemTypes]
    }else {
      availableInventoryItemTypes = [availableInventoryItemTypes.find((itemType) => itemType.type_group_id === currentGroupByTypeFilter)]
    }

    currentInventoryItemTypes = availableInventoryItemTypes.filter((itemType) => {
      if ((currentSearchInput === "" || itemType.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1))  {
        return true
      }
      return false
    })

    
    
    for (let inventoryItemType of currentInventoryItemTypes) {
      currentInventoryItemTypesList[inventoryItemType.id] = {
        data: inventoryItemType,
      }

      let foundGroup = Object.keys(currentInventoryItemTypeGroupsList).find((g) => g.id === inventoryItemType.type_group_id)
      if (foundGroup === undefined) {
        currentInventoryItemTypeGroupsList[inventoryItemType.type_group_id] = {
          data: allInventoryItemTypeGroups.find((iTG) => iTG.id === inventoryItemType.type_group_id)
        }
      }
    }
    
    SetNutrientItemTypeGroupsList(currentInventoryItemTypeGroupsList)
    SetNutrientItemTypesList(currentInventoryItemTypesList)
  }, [nutrientItemTypeGroups, allInventoryItemTypes,  currentSearchInput, currentGroupByTypeFilter, currentSelectedNutrientSubPart])
 

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const onGroupFilter = (value) =>  {
    SetGroupByTypeFilter(value)
  }
  

  const nutrientSubpartSelected = (nutrientSubPartId, state) => {
    if (state)  {
      SetSelectedNutrientSubPart(nutrientSubPartId)
      if (onNutrientSubPartSelection !== undefined) {
        onNutrientSubPartSelection(nutrientSubPartId) //inventory item type id
      }
    }else {
      SetSelectedNutrientSubPart(null)
      if (onNutrientSubPartSelection !== undefined) {
        onNutrientSubPartSelection(null)
      }
    }
  }




  

  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Name</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      }
  }

  return (
    <>
      <div id="SelectNutrientSubParts">
        <RowGroup
            hasGroupHeader={false}
            hasSettings={false}
            hasContainer={false}
            itemsScroll={true}
            isExpandable={false}
            hasColumnHeaders={true}
            columnHeaders={columnHeaders}
            columnSizes={columnSizes}
            hasSubGroups={true}>

            <SelectNutrientSubPart_SubPartRow isPlaceholder={true} columnSizesChanged={(columns) => {
                SetColumnSizes(columns)
            }}/>

            {Object.entries(nutrientItemTypeGroupsList).map(([itemTypeGroupIdAsString, itemTypeGroup]) => {
              return <>
                <RowGroupSubGroup
                  title={itemTypeGroup.data.name} 
                  expandedState={true} 
                  rows={Object.entries(nutrientItemTypesList).filter(([itemTypeIdAsString, itemType]) => itemType.data.type_group_id === itemTypeGroup.data.id).map(([itemTypeIdAsString, itemType]) => {
                    let itemTypeId = parseInt(itemTypeIdAsString)
                    return (
                      <SelectNutrientSubPart_SubPartRow 
                        itemTypeKey={itemTypeId} 
                        itemTypeInfo={itemType} 
                        isSelected={currentSelectedNutrientSubPart === itemTypeId}
                        onSelectedChanged={(state) => {nutrientSubpartSelected(itemTypeId, state)}}
                        columnSizes={columnSizes}/>
                    ) 
                  })}
                  numRowsDisplay="items"/>
              </>
            })}
        </RowGroup>
      </div>
    </>
  )
} 

export default SelectNutrientSubParts






const SelectNutrientSubPart_SubPartRow = ({itemTypeKey, itemTypeInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const itemType = isPlaceholder ? null : itemTypeInfo.data

  let secondaryHeaderContent = []

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : itemType.name
  
  //let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : itemTypeKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>}/>
  </>)
}