import './SettingsPage.scss';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'



const data = [
  { id: 1, badge: null, name: 'Deryk Richardson', role: "Developer", farm_access: []},
  { id: 2, badge: null, name: 'Justin Picov', role: "Owner", farm_access: []},
  { id: 3, badge: null, name: 'Ryan Fitzgerald', role: "Developer", farm_access: []},
  
];



const Settings_UsersPage = () => {
  
  const columns: GridColDef[] = [
    { accessor: 'badge', Header: '', minWidth: "20", headerClassName: 'DataTable_Standard-Header', renderCell: (params: GridRenderCellParams<String>) => (
      <>
      </>
    ), },
    { accessor: 'name', Header: 'Name', minWidth: "120", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'email', Header: 'Email', minWidth: "200", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'role', Header: 'Role', minWidth: "120", headerClassName: 'DataTable_Standard-Header', renderCell: (params: GridRenderCellParams<String>) => (
      <Badge status="Neutral" content={params.value} size="Medium"/>
    ), },
    { accessor: 'farm_access', Header: 'Farm Access', width: "200", headerClassName: 'DataTable_Standard-Header', renderCell: (params: GridRenderCellParams<List>) => (
      <Badge status={params.value["status"]} content={params.value["name"]} size="Medium"/>
    ), },
  
  ];

  return (
    <div id="Settings_UsersPage">
        <div className="ControlBar_Horizontal">
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Roles' }]} defaultOption="All Roles"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultOption="Name" prefix="Sort By"/>
        </div>
        <StandardTable id="UsersTable" data={data} columns={columns} isSelectableRows={true} isResizable={true}/>
    </div>
  )
} 

export default Settings_UsersPage