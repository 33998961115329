import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { RoundToNearest } from '../../../../helpers';

  

const SelectResevoirTopUpTypeWalkthroughStep = ({NDS, reservoirInfo, wasReservoirVolumeInitiallyCorrect, transitionToPreviousStep, transitionToNextStep, topUpTypeSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const [topUpType, SetTopUpType] = React.useState("within_reservoir")

    let reservoirVolumeRemaining = 0
    if (reservoirInfo["runtime_information"]["volume_remaining"] !== undefined)  {
      reservoirVolumeRemaining = parseFloat(reservoirInfo["runtime_information"]["volume_remaining"]) / 1000;
    }


    const backClicked = () => {
        if (wasReservoirVolumeInitiallyCorrect) {
            transitionToPreviousStep(["confirm_current_reservoir_volume", null])
        }else {
            transitionToPreviousStep(["update_current_reservoir_volume", null])
        }
    }
    const nextClicked = () =>   {
        topUpTypeSelectedCallback(topUpType)
        if (topUpType === "within_reservoir")   {
            transitionToNextStep(["confirm_reservoir_water_topup", null])
        }else if (topUpType === "external_reservoir") {
            transitionToNextStep(["confirm_external_reservoir_water_volume", null])            
        }
    }


    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Select Refill Method</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-60 FlexContent-Center">
                        {isMobile && <>
                            <div className="FlexContent-10 FlexContent-Center">
                                <div style={{fontSize:18, fontWeight:400, textAlign:"center", margin: "0 10px"}}>How would you like to mix the nutrients</div>
                            </div>
                            <div className="FlexContent-10 FlexContent-HFill">
                                <Button 
                                    content={<div style={{fontSize:14}}>Add to reservoir*</div>} 
                                    state={topUpType === "within_reservoir"} 
                                    status={"Primary-Toggle"} 
                                    onClick={() => {SetTopUpType("within_reservoir")}}
                                    style={{height: 60}}/>
                                <Button 
                                    content={<div style={{fontSize:14}}>Add amount</div>} 
                                    state={topUpType === "external_reservoir"} 
                                    status={"Primary-Toggle"}
                                    onClick={() => {SetTopUpType("external_reservoir")}}
                                    style={{height: 60}}/>
                            </div>
                            <div className="FlexContent FlexContent-Center" style={{flex:0, textAlign:"center", whiteSpace:"normal"}}>
                                *If mixing directly in reservoir ensure you have a way of measuring volume of your reservoir
                            </div>
                        </>}
                        {!isMobile && <>
                            <div className="FlexContent-10 FlexContent-Center">
                                <div style={{fontSize:24, fontWeight:400}}>How would you like to mix the nutrients</div>
                            </div>
                            <div className="FlexContent-H-10 FlexContent-HFill FlexContent-HFlex">
                                <Button 
                                    content={<div style={{fontSize:14}}>Add to reservoir*</div>} 
                                    state={topUpType === "within_reservoir"} 
                                    status={"Primary-Toggle"} 
                                    onClick={() => {SetTopUpType("within_reservoir")}}
                                    width={"Flex50"}
                                    style={{height: 100}}/>
                                <Button 
                                    content={<div style={{fontSize:14}}>Add amount</div>} 
                                    state={topUpType === "external_reservoir"} 
                                    status={"Primary-Toggle"}
                                    onClick={() => {SetTopUpType("external_reservoir")}}
                                    width={"Flex50"}
                                    style={{height: 100}}/>
                            </div>
                            <div className="FlexContent FlexContent-Center" style={{flex:0, textAlign:"center", whiteSpace:"normal"}}>
                                *If mixing directly in reservoir ensure you have a way of measuring volume of your reservoir
                            </div>
                        </>}
                        
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default SelectResevoirTopUpTypeWalkthroughStep