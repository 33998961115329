

import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';

import RecipesPage from './Recipes/RecipesPage'
import RecipePage from './Recipes/RecipePage'

import PlansPage from './GrowPlans/GrowPlansPage'

const RecipesAndPlansPage = ({pageHeaderHelper}) => {
  
  const tabs = [
    new TabControlTab("Recipes", "recipes", true),
    new TabControlTab("Grow Plans", "growplans")
  ] 
  const tabControlTabClickedRef = React.useRef(null);
  
  const setupPageHeader = () => {
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });
  }

  React.useLayoutEffect(() => {
    setupPageHeader();
  }, [pageHeaderHelper])

  return (
    <Routes>
      <Route path="/recipe/:recipeID/edit/*" element={<RecipePage pageHeaderHelper={pageHeaderHelper} isEditingRecipe={true}/>} />
      <Route path="/recipe/:recipeID/*" element={<RecipePage pageHeaderHelper={pageHeaderHelper} />} />
      <Route path="*" element={
        <div className="PageWrapper_Standard">
       
        <TabControl tabs={tabs}
          tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
          hideTabs={true}
          activePathIndex={2}
          content={
            <Routes>
              <Route path="/recipes" element={
                <RecipesPage pageHeaderHelper={pageHeaderHelper} pageHeaderHelperSetup={setupPageHeader}/>
              }/>
              <Route path="/growplans" element={
                <PlansPage pageHeaderHelper={pageHeaderHelper} pageHeaderHelperSetup={setupPageHeader}/>
              }/>
  
              <Route path="*" element={<Navigate to="recipes" replace />} />
            </Routes>
          }/>
      </div>
        
      }/>
      
    </Routes>

    
  )
} 

export default RecipesAndPlansPage