import './GanttChart.scss';

import React from 'react'
import TimeAxis from './TimeAxis.js'
import { useMeasure, FormatDate, useMeasureWithRef } from '../helpers'
import WebFont from 'webfontloader';
import ResizeObserver from 'resize-observer-polyfill'

import variables from '../globals.scss';
  

export const GanttChart = ({
        id, axisHeight, tableHeight, 
        viewPeriod, fromTime, 
        data, content, listScrollPosition, dataStartDateKey, dataEndDateKey,
        updateRow, barMargin,
        onScroll
    }) => {

    const chartRef = React.useRef(null)
    const mainRef = React.useRef(null)

    let tilesRef = React.useRef(null)
    const [bounds, set] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
    const [currentTableHeight, SetTableHeight] = React.useState(tableHeight);
    const [fontLoaded, setFontLoaded] = React.useState(false)
    const [ro] = React.useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
    const [currentViewPeriod, SetViewPeriod] = React.useState(viewPeriod);
    const [minViewPeriod, setMinViewPeriod] = React.useState(1000 * 60 * 60 * 24)
    const [maxViewPeriod, setMaxViewPeriod] = React.useState(1000 * 60 * 60 * 24 * 365)
    const [currentFromTime, SetFromTime] = React.useState(fromTime);
    
    const [isMouseDownOnCanvas, SetIsMouseDownOnCanvas] = React.useState(false);


    const [mousePointerId, SetMousePointerId] = React.useState(null);

    const [pointersDown, SetPointersDown] = React.useState({});
    const [isPanning, SetIsPanning] = React.useState(false)


    const [currentCursor, SetCursor] = React.useState("default");
    
    const [isTwoFingerTouch, SetIsTwoFingerTouch] = React.useState(false);
    const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});
    const [lastTouchPosition, SetLastTouchPosition] = React.useState({x: 0, y: 0});
    const [last2FingerTouchDelta, SetLast2FingerTouchDelta] = React.useState(0);
    const [isTouchPanning, SetIsTouchPanning] = React.useState(false);
    const [isTouchDevice, SetIsTouchDevice] = React.useState(false)
    
    const [lastTwoFingerZoomDistance, SetLastTwoFingerZoomDistance] = React.useState(0);
    const [lastTwoFingerZoomPosition, SetLastTwoFingerZoomPosition] = React.useState({x: 0, y: 0});

    
    const [currentTempStartDate, SetCurrentTempStartDate] = React.useState(null);
    const [currentTempEndDate, SetCurrentTempEndDate] = React.useState(null);
    const [currentPendingStartDate, SetCurrentPendingStartDate] = React.useState(null);
    const [currentPendingEndDate, SetCurrentPendingEndDate] = React.useState(null);
    
    

    const dragGrabberWidth = 10
    
    const { devicePixelRatio:ratio=1 } = window
    const zoomRate = 0.005
    React.useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto']
            }
        })
        setFontLoaded(true)

        
    }, [])

    
   

    const handlePointerDown = (e) =>  {
        if (isTouchDevice)
            return

        pointersDown[e.pointerId] = {x: e.clientX, y: e.clientY}
        SetPointersDown({...pointersDown})

        if (Object.keys(pointersDown).length == 1)  {
            e.preventDefault()
            SetIsMouseDownOnCanvas(true);
            SetLastMousePosition({x: e.clientX, y: e.clientY});
        }

        //console.log(e)

        //handleCursor({x: e.clientX, y: e.clientY})
    }



    const handlePointerMove = (e) =>  {
        if (isTouchDevice)
            return


            let pointerPosition = {x: e.clientX, y: e.clientY}


        const chart = chartRef.current
        const width = chart.width/ratio
        
        //handleCursor({x: e.clientX, y: e.clientY})

        if (Object.keys(pointersDown).length === 1 && chartRef.current !== undefined)  {
            if (mainRef.current !== undefined && mainRef.current.setPointerCapture && !isPanning)    {
                SetMousePointerId(e.pointerId)
                mainRef.current.setPointerCapture(e.pointerId);
                SetIsPanning(true)
            }


            const chart = chartRef.current
            let offset = {x: pointerPosition.x - lastMousePosition.x, y: pointerPosition.y - lastMousePosition.y};
            SetLastMousePosition({x: pointerPosition.x, y: pointerPosition.y});
            let width = chart.width/ratio

            let addingTime = (currentViewPeriod * (offset.x / width))

            SetFromTime(new Date(currentFromTime.getTime() - addingTime));


        }else if (Object.keys(pointersDown).length === 2)   {
            console.log(pointersDown)
        }

        if (pointersDown[e.pointerId] !== undefined)    {
            pointersDown[e.pointerId] = pointerPosition
            SetPointersDown({...pointersDown})
        }
    }

    
    const handlePointerUp = (e) => {
        if (isTouchDevice)
            return
        
        if (pointersDown[e.pointerId] !== undefined)    {
            delete pointersDown[e.pointerId]
            SetPointersDown({...pointersDown})
        }

        if (mainRef.current !== undefined && mainRef.current.releasePointerCapture && isPanning)    {
            mainRef.current.releasePointerCapture(mousePointerId);
        }
        SetIsPanning(false)

        //handleCursor({x: e.clientX, y: e.clientY})
    };





    

    const handleTouchStart = (e) =>  {
        //SetIsTouchDevice(true)
        SetLast2FingerTouchDelta(0)

        if (e.touches.length == 1)  {
            let touchPosition = {x: e.touches[0].pageX, y: e.touches[0].pageY}

            e.preventDefault()
            SetIsMouseDownOnCanvas(true);
            SetLastTouchPosition({x: touchPosition.x, y: touchPosition.y});

        }

        console.log(e)


        //handleCursor({x: e.clientX, y: e.clientY})
    }



    const handleTouchMove = (e) =>  {

        const chart = chartRef.current
        const width = chart.width/ratio
        
        //handleCursor({x: e.clientX, y: e.clientY})

        if (e.touches.length == 1 && chartRef.current !== undefined)  {
            
            let touchPosition = {x: e.touches[0].pageX, y: e.touches[0].pageY}
            if (!isTouchPanning)    {
                SetIsTouchPanning(true)
            }else {

            

                const chart = chartRef.current
                let offset = {x: touchPosition.x - lastTouchPosition.x, y: touchPosition.y - lastTouchPosition.y};
                let width = chart.width/ratio

                let addingTime = (currentViewPeriod * (offset.x / width))

                SetFromTime(new Date(currentFromTime.getTime() - addingTime));
            }
            SetLastTouchPosition({x: touchPosition.x, y: touchPosition.y});

        }else if (e.touches.length === 2)   {
            e.stopPropagation()
            e.preventDefault()

            SetIsTouchPanning(false)

            const currentTouchDistance = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
            if (last2FingerTouchDelta !== 0)    {
                const delta = Math.abs(last2FingerTouchDelta / currentTouchDistance)
                const zoomForce = 1;
                const zoomAmount = (delta * zoomForce)
                //alert(zoomAmount)

                //const xDelta = Math.abs(e.touches[1].pageX - e.touches[0].pageX)
                let xPosition
                if (e.touches[0].pageX > e.touches[1].pageX)    {
                    xPosition = e.touches[0].pageX + ((e.touches[1].pageX - e.touches[0].pageX) / 2)
                }else {
                    xPosition = e.touches[1].pageX + ((e.touches[0].pageX - e.touches[1].pageX) / 2)
                }
                processZoom(zoomAmount * zoomForce, xPosition / width)
            }

            SetLast2FingerTouchDelta(currentTouchDistance);
        }else {
            e.stopPropagation()
            e.preventDefault()
        }

    }

    
    const handleTouchEnd = (e) => {
        
        SetIsPanning(false)
        SetIsTouchPanning(false)
        //SetLast2FingerTouchDelta(0)
        //handleCursor({x: e.clientX, y: e.clientY})
    };


    
    

    /*const handleTouchStart = (e) =>  {
        if (e.touches.length == 1)    {
            if ((isMouseDownOnCanvas && !isMouseDownOnItem && !isItemSelected) && chartRef.current !== undefined)  {
                
            }
        }else if (e.touches.length == 2)  {
            e.preventDefault()
            const chart = chartRef.current
            SetLastTwoFingerZoomDistance(Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY));
            const midX = (e.touches[0].pageX + (e.touches[1].pageX - e.touches[0].pageX) / 2) - chart.getBoundingClientRect().left;
            const midY = (e.touches[0].pageY + (e.touches[1].pageY - e.touches[0].pageY) / 2) - chart.getBoundingClientRect().top;
            SetLastTwoFingerZoomPosition({x: midX, y: midY})
            SetIsTwoFingerTouch(true)

            SetIsMouseDownOnItem(false);
            SetIsItemSelected(false);
            SetSelectedItemKey(null);
        }
    }

    const handleTouchMove = (e) =>  {
        

        const chart = chartRef.current
        const width = chart.width/ratio
        if (isTwoFingerTouch && e.touches.length == 2) {
            e.preventDefault()
            const currentTwoFingerZoomDistance = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);

            const delta = lastTwoFingerZoomDistance - currentTwoFingerZoomDistance
            const zoomAmount = (1 + (zoomRate * 1) * delta)
            
            
            if(delta > 0) {
                processZoom(zoomAmount, lastTwoFingerZoomPosition.x / width)
            }
            if(delta < 0) {
                processZoom(zoomAmount, lastTwoFingerZoomPosition.x / width)
            }

            SetLastTwoFingerZoomDistance(currentTwoFingerZoomDistance)
        }
    }

    const handleTouchEnd = (e) =>    {

    }*/



    
    const handleMouseWheel = (e) => {

        if (e.ctrlKey) {
            //e.preventDefault()
            //e.stopPropagation()

            const chart = chartRef.current
            const width = chart.width/ratio
            const zoomForce = 0.02
            const zoomAmount = (1 + zoomForce * e.deltaY)
            processZoom(zoomAmount, e.offsetX / width)
        }else {
            if (e.deltaX !== 0)   {
                const panForce = 0.001
                let addingTime = (currentViewPeriod * ((e.deltaX * panForce)))

                SetFromTime(new Date(currentFromTime.getTime() - addingTime));
            }
        }
    }

    const processZoom = (zoomAmount, atPosition) =>   {
        let newViewPeriod = Math.floor(currentViewPeriod * zoomAmount)
        if (newViewPeriod < minViewPeriod)
            newViewPeriod = minViewPeriod
        if (newViewPeriod > maxViewPeriod)
            newViewPeriod = maxViewPeriod


        const mouseAtTime = currentFromTime.getTime() + (atPosition * currentViewPeriod)
        SetFromTime(new Date(mouseAtTime - (atPosition * newViewPeriod)));
        SetViewPeriod(newViewPeriod);
    }


    React.useEffect(() => {
        
        mainRef.current.addEventListener('wheel',  handleMouseWheel, { passive: true });
        mainRef.current.addEventListener('mousewheel',  handleMouseWheel, { passive: true });

        return () => {
            ro.disconnect()
            if (mainRef.current !== undefined && mainRef.current != null) {
                mainRef.current.removeEventListener('wheel', handleMouseWheel, { passive: true });
                mainRef.current.removeEventListener('mousewheel', handleMouseWheel, { passive: true });
            }
        }
    }, [axisHeight, currentViewPeriod, currentFromTime, fontLoaded])

    /*const handleCursor = (mousePosition) =>    {
        const [mouseOverItemKey, hitPart] = [null, [null, null]]
        if (isMouseDownOnCanvas)    {
            
        }else if (isItemSelected)    {
            if (isMouseDownOnItem)  {
                SetCursor("grabbing")
            }else {
                if (mouseOverItemKey != null) {
                    if (mouseOverItemKey == selectedItemKey)  {
                        SetCursor("grab")
                    }else {
                        SetCursor("pointer")
                    }
                }else {
                    SetCursor("default")
                }
            }
        }else if (mouseOverItemKey != null) {
            if (mouseOverOnItemKey !== null && mouseOverOnItemKey != mouseOverItemKey)    {
                //mouseOverOnItem.mouseIsOver = false
            }
            SetMouseOverOnItemKey(mouseOverItemKey)
            //mouseOverItem.mouseIsOver = true
            SetCursor("pointer")
        }else {
            SetCursor("default")
        }
    }*/

    const resizeChart = chart => {
        const { width, height } = chart.getBoundingClientRect()
        //alert(width.toString() + "-" + height)
        
        
        if (chart.width !== width*ratio || chart.height !== height*ratio) {
          const context = chart.getContext('2d')
          chart.width = width*ratio
          chart.height = height*ratio
          context.scale(ratio, ratio)
          return true
        }
    
        return false
      }

    

      const draw = (canvas, ctx) => {
        let width = canvas.width/ratio
        let height = canvas.height/ratio

        ctx.fillStyle= "#F7F8FB";
        ctx.fillRect(0, 0, width, height);

        //First draw the weekend backdrops
        const dayDuration = 1000 * 60 * 60 * 24
        let currentDate = new Date(currentFromTime.getTime())
        currentDate.setHours(0, 0, 0, 0)
        while (currentDate.getTime() < currentFromTime.getTime() + currentViewPeriod)   {
            let nextDate = new Date(currentDate)
            nextDate.setDate(currentDate.getDate() + 1)
            if (currentDate.getDay() == 0 || currentDate.getDay() == 6) {
                //It's a weekend, lets fill-er in
                let x1 = Math.floor(((currentDate.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                
                let x2 = Math.floor((((nextDate.getTime()) - currentFromTime.getTime()) / currentViewPeriod) * width)

                ctx.fillStyle = "#FFF";
                ctx.fillRect(x1, 0, x2 - x1, height);
            }
           currentDate = nextDate
        }

        
        const now = new Date();
        const nowX = Math.floor(((now.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
        ctx.strokeStyle = '#2E72D2'
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(nowX, 0);
        ctx.lineTo(nowX, height);
        ctx.stroke();
        ctx.lineWidth = 1;
    }

    const handleUpdate = () =>  {
        if (chartRef.current) {
            ro.observe(chartRef.current)
            const chart = chartRef.current

            const ctx = chart.getContext('2d')
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
            ctx.clearRect(0, 0, chart.width, chart.height)
            resizeChart(chart)
            ctx.translate(0.5, 0.5);
            draw(chart, ctx)
        }
    }

    handleUpdate()

    React.useEffect(() => {
        if (currentTableHeight != tableHeight)    {
            SetTableHeight(tableHeight)
            
        }
        
    }, [tableHeight])

    const ganttAreaRef = React.useRef(null)
    const [{width: ganttAreaWidth}] = useMeasureWithRef(ganttAreaRef)

    return (
    <div className="GanttChart"
        style={{cursor: currentCursor}}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerCancel={handlePointerUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        on
        ref={mainRef}>
        <TimeAxis 
            id="GanttChartTimeAxis" 
            axisHeight={axisHeight} 
            hasExternalControl={true} 
            viewPeriod={currentViewPeriod} 
            fromTime={currentFromTime}
            tilesRef={ref => {tilesRef = ref}}/>
        <div className="GanttChartCanvas-Container">
            <canvas
                className="GanttChartCanvas noselect"
                ref={chartRef} onScroll={(e) => {console.log(e)}}/>
            <div className="GanttChartArea-Content" ref={ganttAreaRef}>
                {content.map((contentItem) => {
                    //console.log("render")
                    let renderProps = {}
                    renderProps.x1 = Math.floor(((contentItem.fromDate - currentFromTime.getTime()) / currentViewPeriod) * ganttAreaWidth)
                    renderProps.x2 = Math.floor(((contentItem.toDate - currentFromTime.getTime()) / currentViewPeriod) * ganttAreaWidth)
                    renderProps.chartWidth = ganttAreaWidth
                    renderProps.minDayWidth = 20
                    renderProps.jumpToStartTime = (date) => {
                        console.log(date)
                        SetFromTime(new Date(date - 1000 * 60 * 60 * 24))
                    }
                    renderProps.jumpToEndTime = (fromDate, duration) => {
                        SetFromTime(new Date(fromDate + 1000 * 60 * 60 * 24))
                        SetViewPeriod(duration)
                    }

                    let rowContentRender = contentItem.render !== undefined ? contentItem.render(renderProps) : (<>
                        Render Not Found
                    </>)
                    return (<div key={contentItem.key} className="GanttChartArea-Content-RowItem-Wrapper">
                        {rowContentRender}

                    </div>)
                })}
            </div>
        </div>
    </div>
  )
}


GanttChart.defaultProps = {
    axisHeight: 50,
    viewPeriod: 1000 * 60 * 60 * 24 * 5,
    fromTime: new Date(),
    content: [],
    data: [],
    dataStartDateKey: "startDate",
    barMargin: 50,
    listScrollPosition: 0
}

export default GanttChart 