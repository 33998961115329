import './PopupModal.scss';
import React from 'react';

import BadgeTimeline from '../components/BadgeTimeline.js'
import {MdClose} from 'react-icons/md'
import {Close} from '../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import useMeasure from '../useMeasure';


const PopupModal = ({
  id, title, subHeaderContent, description, closeCallback,
  size, hideMainSeparators, contentAlignment,
  children, footer,
  allowOuterClickToClosePopup,
  canClose,
  contentWrapperVerticalPadding,
  contentVerticalPadding
}) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const closePopup = () =>  {
    if (closeCallback !== undefined && allowOuterClickToClosePopup)  {
      closeCallback(false)
    }
  }
  const forceClosePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(true)
    }
  }


  

  const [popupContentHandleRef, { width: popupContentWidth }] = useMeasure()

  const [popupContainerHandleRef, { width: popupContainerWidth, height: popupContainerHeight}] = useMeasure()
  const [popupHeaderHandleRef, { width: popupHeaderWidth, height: popupHeaderHeight}] = useMeasure()
  const [popupFooterHandleRef, { width: popupFooterWidth, height: popupFooterHeight}] = useMeasure()





  const screenWrapperProps = {}
  if (id) {
    screenWrapperProps.id = id;
  }
  const popupProps = {style:{}}


  const contentWrapperProps = {style:{}}
  const contentProps = {style:{}}

  if (size === "full_screen") {


    return (
      <div className={"PopupModal_FullScreen" + (contentAlignment === "center" ? " PopupModal_CenterContent" : "") + (isMobile ? " PopupModal_Mobile" : (isTablet ? " PopupModal_Tablet" : (isDesktop ? " PopupModal_Desktop" : "") + (isWideDesktop ? " PopupModal_WideDesktop" : "" ) ) )} {...screenWrapperProps} ref={popupContentHandleRef}>
        <div className="PopupModal-Wrapper">
          <div className="PopupModal">
            <div className={"PopupModal-Header" + (hideMainSeparators ? " PopupModal-NoSeperator" : "")}>
                <div className="PopupModal-HeaderContent">
                    <div className="PopupModal-HeaderTitle">
                        {title}
                    </div>
                    {description && 
                      <div className="PopupModal-HeaderDescription">
                          {description}
                      </div>
                    }
                  {canClose && <>
                    <div className="PopupModal-CloseButton" onClick={forceClosePopup}>
                      <Close/>
                    </div>
                  </>}
                </div>
                {(subHeaderContent !== null) && 
                  <div className="PopupModal-HeaderSubContent">
                    {subHeaderContent}
                  </div>
                }
            </div>

            <div className="PopupModal-Content-Wrapper" {...contentWrapperProps}>
              <div className="PopupModal-Content" {...contentProps}>
                  {children}
              </div>
            </div>

            {footer && 
              <div className={"PopupModal-Footer" + (hideMainSeparators ? " PopupModal-NoSeperator" : "")}>
                {footer}
              </div>
            }
          </div>
        </div>
      </div>
    )

  }else {

    const containerProps = {style: {}}
    if (size === "auto")  {
      containerProps.style.alignItems = "center"
      containerProps.style.justifyItems = "center"
    }else if (size === "full")  {
      containerProps.style.alignItems = "center"
      containerProps.style.justifyContent = "center"
      //containerProps.style.maxHeight = "100%"
      containerProps.style.overflow = "hidden"
      popupProps.style.alignContent = "center"
      popupProps.style.justifyContent = "center"
      popupProps.style.alignItems = "center"
    }


    contentWrapperProps.style.margin = contentWrapperVerticalPadding + "px" + " 0"
    contentProps.style.margin = contentVerticalPadding + "px" + " 0"

    return (
      <div className={"PopupModal-ScreenWrapper"} {...screenWrapperProps}>
        <div onClick={closePopup} className="PopupModal-Container" {...containerProps}>
          <div className="PopupModal-Wrapper" style={{justifyContent:"center"}}>
            <div className="PopupModal" onClick={(e) => {e.preventDefault(); e.stopPropagation()}} {...popupProps}>
              <div className="PopupModal-Header">
                <div className="PopupModal-HeaderContent">
                  <div className="PopupModal-HeaderTitle">
                      {title}
                  </div>
                  {description && 
                    <div className="PopupModal-HeaderDescription">
                        {description}
                    </div>
                  }
                  {(subHeaderContent !== null) && 
                    <div className="PopupModal-HeaderSubContent">
                      {subHeaderContent}
                    </div>
                  }
                </div>
                {canClose && <>
                  <div className="PopupModal-CloseButton" onClick={forceClosePopup}>
                    <Close/>
                  </div>
                </>}
              </div>
                  
              <div className="PopupModal-Content-Wrapper" {...contentWrapperProps}>
                <div className="PopupModal-Content" {...contentProps}>
                  {children}
                </div>
              </div>

              {footer && 
                <div className="PopupModal-Footer">
                  {footer}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
} 

PopupModal.defaultProps = {
  id: false,
  size: "auto",
  contentAlignment: false,
  subHeaderContent: null,
  footer: false,
  timelineSteps: false,
  canClose: true,
  hideMainSeparators: false,
  allowOuterClickToClosePopup: true,
  contentWrapperVerticalPadding: 10,
  contentVerticalPadding: 10
}


export default PopupModal