import './CRMPage.scss';
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import Pages from './../../pages/PageList';

import CRM_ProductPage from './ProductPage'



const CRMPage = ({pageHeaderHelper}) => {

  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("CRM")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              
            </div>
          </div>
        </>
      )
    })
  }

  return (
    <div className="PageWrapper_Standard">
      <Routes>
        <Route path="/product/:productID" element={<CRM_ProductPage pageHeaderHelper={pageHeaderHelper}/>} />
        <Route path="*" element={
          <TabControl tabs={
            [new TabControlTab("Catalog", "catalog", true),
            new TabControlTab("Orders", "orders"),
            new TabControlTab("Customers", "customers")]}
            content={
              <Routes>
                <Route exact path="/catalog" element={
                  <Pages.CRM_CatalogPage setPageHeader={setPageHeader}/>
                }/>
                <Route path="/orders" element={
                  <Pages.CRM_OrdersPage setPageHeader={setPageHeader}/>
                }/>
                <Route path="/customers" element={
                  <Pages.CRM_CustomersPage setPageHeader={setPageHeader}/>
                }/>

                <Route path="*" element={<Navigate to="catalog" replace />} />
              </Routes>
            }/>
        } />
      </Routes>
    </div>
  )
} 

export default CRMPage