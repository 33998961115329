import './Pill.scss';


const Pill = ({content, status, fontSize, size, color}) => {

  return (
    <div className={"Pill Pill-" + status + " Pill-" + size + " noselect"}>
      <div><span style={{fontSize: fontSize}}>{content}</span></div>
    </div>
  )
} 

Pill.defaultProps = {
  content: "",
  status: "Neutral",
  color: false, 
  fontSize: 10,
  size: "Medium"
}


export default Pill