import './StandardList.scss';
import React from 'react' 
import { useMeasure } from '../../helpers'

const StandardListHeaderColumn = ({
    column, 
    columnPadding, 
    rowPadding, 
    headerRowPadding,
    onSizeChange,
    isForMultiselect,

  }) => { 

  
  
  const updateColumnBounds = (bounds) =>  {
    if (bounds === undefined)
      return
    
      if (column.containerBounds === undefined || (
        column.containerBounds.width !== bounds.width ||
        column.containerBounds.height !== bounds.height ||
        column.containerBounds.left !== bounds.documentLeft ||
        column.containerBounds.top !== bounds.documentTop
      ))  {
        column.containerBounds = bounds
        if (onSizeChange !== undefined) {
          onSizeChange(bounds)
        }
      }
  }

  
  const [usingContainerWidth, SetUsingContainerWidth] = React.useState(false)
  const [usingContainerGrow, SetUsingContainerGrow] = React.useState(false)
  const [containerWidth, SetContainerWidth] = React.useState(0)
  const [containerWidthType, SetContainerWidthType] = React.useState((column.widthType !== undefined) ? column.widthType : "ch")
  const [usingContainerMinWidth, SetUsingContainerMinWidth] = React.useState(false)
  const [containerMinWidth, SetContainerMinWidth] = React.useState(0)

  React.useLayoutEffect(() => {
    if (column.width !== undefined && column.width !== "grow") {
      SetUsingContainerWidth(true)
      SetContainerWidth(column.width)
      SetUsingContainerGrow(false)
    }else if (column.width === "grow")  {
      SetUsingContainerWidth(false)
      SetUsingContainerGrow(true)
    }else {
      SetUsingContainerWidth(false)
      SetUsingContainerGrow(false)
    }

    if (column.minWidth !== undefined) {
      SetUsingContainerMinWidth(true)
      SetContainerMinWidth(column.minWidth)
    }else {
      SetUsingContainerMinWidth(false)
    }
  }, [column])



  const [containerBind, {}] = useMeasure(updateColumnBounds)
  const selectionCheckbox = React.useRef(undefined)

  if (column.hidden)  {
    return (<></>)
  }
  
  if (!isForMultiselect)  {
    const containerProps = {...containerBind}
    const contentProps = {
      style: {
        width: usingContainerWidth ? containerWidth.toString() + containerWidthType : "auto",
        padding: headerRowPadding + "px " + columnPadding + "px"
      }
    }
    if (usingContainerMinWidth) {
      contentProps.style.minWidth = containerMinWidth.toString() + containerWidthType
    }
    if (usingContainerGrow) {
      containerProps.style = {
        flexGrow:1
      }
    }


    
    return (
      <div className="StandardList-Header-ColumnContainer"
        {...containerProps}>
        <div className="StandardList-Header-ColumnContent" {...contentProps}>
        {column.render}
        </div>
      </div>
    )

  }else {


    return (
      <div className="StandardList-Header-ColumnContainer">
        <div className="StandardList-Header-ColumnContent" style={{padding: headerRowPadding + "px " + columnPadding + "px"}}>
          <input type="checkbox" ref={selectionCheckbox}/>
        </div>
      </div>
    )
  }
} 

StandardListHeaderColumn.defaultProps = {
  column: {render: (<>No Render</>)},
  headerRowPadding: 8,
  isForMultiselect: false
}


export default StandardListHeaderColumn