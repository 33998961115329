import './TimeAxis.scss';

import React from 'react'
import { useMeasure, FormatDate, daysInMonth } from '../helpers'
import WebFont from 'webfontloader';
import ResizeObserver from 'resize-observer-polyfill'
  

export const TimeAxis = ({id, axisHeight, hasExternalControl, viewPeriod, fromTime, tilesRef}) => {
    const mainAxisCanvasRef = React.useRef(null)
    const subAxisCanvasRef = React.useRef(null)

    const [currentMainAxisHeight, SetMainAxisHeight] = React.useState(axisHeight * 0.4);
    const [currentSubAxisHeight, SetSubAxisHeight] = React.useState(axisHeight * 0.6);

    const [tiles, setTiles] = React.useState([])
    const [bounds, set] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
    const [fontLoaded, setFontLoaded] = React.useState(false)
    const [mainRO] = React.useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
    const [subRO] = React.useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
    const [currentViewPeriod, SetViewPeriod] = React.useState(viewPeriod);
    const [minViewPeriod, setMinViewPeriod] = React.useState(1000 * 60 * 60 * 24)
    const [maxViewPeriod, setMaxViewPeriod] = React.useState(1000 * 60 * 60 * 24 * 180)
    const [currentFromTime, SetFromTime] = React.useState(fromTime);
    const [lastCalculatedFromTime, SetLastCalculatedFromTime] = React.useState(0);
    const [lastCalculatedViewPeriod, SetLastCalulatedViewPeriod] = React.useState(0);
    const [lastCalculatedCanvasWidth, SetLastCalculatedCanvasWidth] = React.useState(0);
    const [isMouseDown, SetIsMouseDown] = React.useState(false);
    const [lastMousePosition, SetLastMousePosition] = React.useState({x: 0, y: 0});

    const { devicePixelRatio:ratio=1 } = window
    const zoomRate = 0.005

    React.useEffect(() => {
        WebFont.load({
            google: {
            families: ['Roboto']
            }
        })
        setFontLoaded(true)

        //if (tilesRef !== undefined) {
        //    tilesRef()
        //}
        
    }, [])
    

    const handleMouseDown = (e) =>  {
        const handleMouseUp = (upEvent) => {
            SetIsMouseDown(false);
            if (mainAxisCanvasRef.current !== undefined && mainAxisCanvasRef.current.releasePointerCapture)    {
                mainAxisCanvasRef.current.releasePointerCapture(e.pointerId);
            }
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleMouseUp);
        };

        SetIsMouseDown(true);
        SetLastMousePosition({x: (e.clientX || e.targetTouches[0].pageX), y: (e.pageY || e.targetTouches[0].pageY)});
        if (mainAxisCanvasRef.current !== undefined && mainAxisCanvasRef.current.setPointerCapture)    {
            mainAxisCanvasRef.current.setPointerCapture(e.pointerId);
        }
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleMouseUp);

        
    }
    const handleMouseMove = (e) =>  {
        if (!isMouseDown || mainAxisCanvasRef.current === undefined)
            return;


        const canvas = mainAxisCanvasRef.current
        let offset = {x: (e.clientX || e.targetTouches[0].pageX) - lastMousePosition.x, y: (e.pageY || e.targetTouches[0].pageY) - lastMousePosition.y};
        let width = canvas.width/ratio

        let addingTime = (currentViewPeriod * (offset.x / width))

        SetFromTime(new Date(currentFromTime.getTime() - addingTime));
        SetLastMousePosition({x: (e.clientX || e.targetTouches[0].pageX), y: (e.pageY || e.targetTouches[0].pageY)});
    }

    const handleMouseWheel = (e) => {
        e.preventDefault()
        
        const chart = mainAxisCanvasRef.current
        const width = chart.width/ratio
        const zoomAmount = (1 + zoomRate * e.deltaY)
        let newViewPeriod = Math.floor(currentViewPeriod * zoomAmount)
        if (newViewPeriod < minViewPeriod)
            newViewPeriod = minViewPeriod
        if (newViewPeriod > maxViewPeriod)
            newViewPeriod = maxViewPeriod


        const mouseAtTime = currentFromTime.getTime() + ((e.offsetX / width) * currentViewPeriod)
        SetViewPeriod(newViewPeriod);
        SetFromTime(new Date(mouseAtTime - ((e.offsetX / width) * newViewPeriod)));
    }

    const resizeCanvas = canvas => {
        const { width, height } = canvas.getBoundingClientRect()
        //alert(width.toString() + "-" + height)
        
        
        if (canvas.width !== width*ratio || canvas.height !== height*ratio) {
          const context = canvas.getContext('2d')
          canvas.width = width*ratio
          canvas.height = height*ratio
          context.scale(ratio, ratio)
          return true
        }
    
        return false
      }


    const calculateTiles = canvas => {

        let width = canvas.width/ratio

        const hourDuration = 1000 * 60 * 60
        const dayDuration = hourDuration * 24
        const weekDuration = dayDuration * 7
        const tiers = {
            "days": {
                fromPeriod: 0,
                calculateTiles: () => {
                    let tiles = []
                    let numberOfTiles = Math.ceil(currentViewPeriod / dayDuration) + 1
                    

                    for (let i = 0; i < numberOfTiles; i++) {
                        tiles[i] = { subTiles: [] }
                        tiles[i].startsAt = new Date(currentFromTime)
                        tiles[i].startsAt.setHours(0, 0, 0, 0)
                        if (i == 0 && tiles[i].startsAt.getTime() != currentViewPeriod)    {
                            numberOfTiles +=1;
                        }
                        let currentDate = new Date(tiles[i].startsAt)
                        currentDate.setDate(currentDate.getDate() + i)
                        tiles[i].startsAt = new Date(currentDate.getTime())
                        tiles[i].primaryLabel = FormatDate(tiles[i].startsAt, 'dd MMM');
                        tiles[i].x1 = Math.floor(((tiles[i].startsAt.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                        tiles[i].x2 = Math.floor((((tiles[i].startsAt.getTime() + dayDuration) - currentFromTime.getTime()) / currentViewPeriod) * width)

                        tiles[i].subTiles[0] = {
                            startsAt: tiles[i].startsAt,
                            endsAt: new Date(tiles[i].startsAt.getTime() + (hourDuration * 3))
                        }

                        let currentDay = tiles[i].startsAt;
                        for (let j = 0; j < 7; j++) {
                            tiles[i].subTiles[j] = {
                                startsAt: new Date(tiles[i].startsAt.getTime() + dayDuration * j)
                            }
                            tiles[i].subTiles[j].label = FormatDate(tiles[i].subTiles[j].startsAt, 'E');
                            tiles[i].subTiles[j].endsAt = new Date(tiles[i].subTiles[j].startsAt.getTime() + dayDuration)
                            tiles[i].subTiles[j].x1 = Math.floor(((tiles[i].subTiles[j].startsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)
                            tiles[i].subTiles[j].x2 = Math.floor(((tiles[i].subTiles[j].endsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)

                            if (tiles[i].subTiles[j].startsAt.getDay() == 0 || tiles[i].subTiles[j].startsAt.getDay() == 6) {
                                tiles[i].subTiles[j].isWeekendDay = true;
                            }
                        }
                        
                    }
                    return tiles
                }
            },
            "weeks": {
                fromPeriod: 1000 * 60 * 60 * 24 * 7,
                calculateTiles: () => {
                    let tiles = []
                    let numberOfTiles = Math.ceil(currentViewPeriod / weekDuration)
                    
                    for (let i = 0; i < numberOfTiles; i++) {
                        tiles[i] = { subTiles: [] }
                        tiles[i].startsAt = new Date(currentFromTime)
                        tiles[i].startsAt.setHours(0, 0, 0, 0)
                        tiles[i].startsAt = new Date(tiles[i].startsAt.setDate(tiles[i].startsAt.getDate() - (tiles[i].startsAt.getDay() || 7)));
                        if (i == 0 && tiles[i].startsAt.getTime() != currentViewPeriod)    {
                            numberOfTiles +=1;
                        }
                        tiles[i].startsAt = new Date(tiles[i].startsAt.getTime() + (weekDuration * i))
                        let endsAt = new Date(tiles[i].startsAt.getTime() + 1000 * 60 * 60 * 24 * 6)
                        if (tiles[i].startsAt.getMonth() == endsAt.getMonth())  {
                            tiles[i].primaryLabel = tiles[i].startsAt.getDate().toString() + "-" + endsAt.getDate().toString() + " " + FormatDate(tiles[i].startsAt, 'MMM');
                        }else {
                            tiles[i].primaryLabel = tiles[i].startsAt.getDate().toString() + " " + FormatDate(tiles[i].startsAt, 'MMM') + " - " + endsAt.getDate().toString() + " " + FormatDate(endsAt, 'MMM');
                        }
                        tiles[i].alternativeLabel = "W " + FormatDate(tiles[i].startsAt, 'wn');
                        tiles[i].x1 = Math.floor(((tiles[i].startsAt.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                        tiles[i].x2 = Math.floor((((tiles[i].startsAt.getTime() + weekDuration) - currentFromTime.getTime()) / currentViewPeriod) * width)

                        let currentDay = tiles[i].startsAt;
                        for (let j = 0; j < 7; j++) {
                            tiles[i].subTiles[j] = {
                                startsAt: new Date(tiles[i].startsAt.getTime() + dayDuration * j)
                            }
                            tiles[i].subTiles[j].label = FormatDate(tiles[i].subTiles[j].startsAt, 'E');
                            tiles[i].subTiles[j].endsAt = new Date(tiles[i].subTiles[j].startsAt.getTime() + dayDuration)
                            tiles[i].subTiles[j].x1 = Math.floor(((tiles[i].subTiles[j].startsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)
                            tiles[i].subTiles[j].x2 = Math.floor(((tiles[i].subTiles[j].endsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)

                            if (tiles[i].subTiles[j].startsAt.getDay() == 0 || tiles[i].subTiles[j].startsAt.getDay() == 6) {
                                tiles[i].subTiles[j].isWeekendDay = true;
                            }
                        }
                        
                    }

                    return tiles
                }
            },
            "months-a": {
                fromPeriod: 1000 * 60 * 60 * 24 * 31 * 1.2,
                calculateTiles: () => {
                    let tiles = []

                    //Figure out number of months
                    const currentEndTime = new Date(currentFromTime.getTime() + currentViewPeriod)
                    let numberOfTiles =  Math.abs( (currentFromTime.getMonth() - currentEndTime.getMonth()) + 12 * (currentFromTime.getFullYear() - currentEndTime.getFullYear()))

                    let currentDay = new Date(currentFromTime.getTime())
                    currentDay.setHours(0, 0, 0, 0)
                    currentDay.setDate(1)
                    currentDay = new Date(currentDay.setDate(currentFromTime.getDate() - (currentFromTime.getDay() || 7)))

                    let currentMonthStartsOn = new Date(currentFromTime)
                    currentMonthStartsOn.setHours(0, 0, 0, 0)
                    currentMonthStartsOn.setDate(1)
                    for (let i = 0; i < numberOfTiles; i++) {

                        tiles[i] = { subTiles: [] }
                        tiles[i].startsAt = new Date(currentMonthStartsOn)
                        if (i == 0 && tiles[i].startsAt.getTime() != currentViewPeriod)    {
                            numberOfTiles +=1;
                        }

                        const currentMonthNumberOfDays = daysInMonth(tiles[i].startsAt.getFullYear(), tiles[i].startsAt.getMonth())

                        currentMonthStartsOn.setMonth(currentMonthStartsOn.getMonth() + 1)
                        tiles[i].primaryLabel = FormatDate(tiles[i].startsAt, 'MMM yyyy');
                        tiles[i].x1 = Math.floor(((tiles[i].startsAt.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                        tiles[i].x2 = Math.floor((((currentMonthStartsOn.getTime()) - currentFromTime.getTime()) / currentViewPeriod) * width)

                        let j = 0;
                        while (currentDay.getTime() < tiles[i].startsAt.getTime() + (currentMonthNumberOfDays * dayDuration))   {
                            tiles[i].subTiles[j] = {
                                startsAt: currentDay
                            }
                            tiles[i].subTiles[j].label = FormatDate(tiles[i].subTiles[j].startsAt, 'd') + "-" + FormatDate(new Date(tiles[i].subTiles[j].startsAt.getTime() + (dayDuration * 6)), 'd');
                            tiles[i].subTiles[j].endsAt = new Date(tiles[i].subTiles[j].startsAt.getTime() + (dayDuration * 7))
                            tiles[i].subTiles[j].x1 = Math.floor(((tiles[i].subTiles[j].startsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)
                            tiles[i].subTiles[j].x2 = Math.floor(((tiles[i].subTiles[j].endsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)


                            currentDay = new Date(currentDay.getTime() + dayDuration * 7)
                            j++;
                        }
                        
                    }

                    return tiles
                }
            },
            "months-b": {
                fromPeriod: 1000 * 60 * 60 * 24 * 31 * 2.0,
                calculateTiles: () => {
                    let tiles = []

                    //Figure out number of months
                    const currentEndTime = new Date(currentFromTime.getTime() + currentViewPeriod)
                    let numberOfTiles =  Math.abs( (currentFromTime.getMonth() - currentEndTime.getMonth()) + 12 * (currentFromTime.getFullYear() - currentEndTime.getFullYear()))

                    let currentDay = new Date(currentFromTime.getTime())
                    currentDay.setHours(0, 0, 0, 0)
                    currentDay.setDate(1)
                    currentDay = new Date(currentDay.setDate(currentFromTime.getDate() - (currentFromTime.getDay() || 7)))

                    let currentMonthStartsOn = new Date(currentFromTime)
                    currentMonthStartsOn.setHours(0, 0, 0, 0)
                    currentMonthStartsOn.setDate(1)
                    for (let i = 0; i < numberOfTiles; i++) {

                        tiles[i] = { subTiles: [] }
                        tiles[i].startsAt = new Date(currentMonthStartsOn)
                        if (i == 0 && tiles[i].startsAt.getTime() != currentViewPeriod)    {
                            numberOfTiles +=1;
                        }

                        const currentMonthNumberOfDays = daysInMonth(tiles[i].startsAt.getFullYear(), tiles[i].startsAt.getMonth())
                        currentMonthStartsOn.setMonth(currentMonthStartsOn.getMonth() + 1)
                        tiles[i].primaryLabel = FormatDate(tiles[i].startsAt, 'MMM yyyy');
                        tiles[i].x1 = Math.floor(((tiles[i].startsAt.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                        tiles[i].x2 = Math.floor(((currentMonthStartsOn.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)

                        let j = 0;
                        while (currentDay.getTime() < tiles[i].startsAt.getTime() + (currentMonthNumberOfDays * dayDuration))   {
                            tiles[i].subTiles[j] = {
                                startsAt: currentDay
                            }
                            tiles[i].subTiles[j].label = FormatDate(tiles[i].subTiles[j].startsAt, 'd') + "-" + FormatDate(new Date(tiles[i].subTiles[j].startsAt.getTime() + (dayDuration * 6)), 'd');
                            tiles[i].subTiles[j].endsAt = new Date(tiles[i].subTiles[j].startsAt.getTime() + (dayDuration * 7))
                            tiles[i].subTiles[j].x1 = Math.floor(((tiles[i].subTiles[j].startsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)
                            tiles[i].subTiles[j].x2 = Math.floor(((tiles[i].subTiles[j].endsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)


                            currentDay = new Date(currentDay.getTime() + dayDuration * 7)
                            j++;
                        }
                        
                    }

                    return tiles
                }
            },
            "months-c": {
                fromPeriod: 1000 * 60 * 60 * 24 * 31 * 4,
                calculateTiles: () => {
                    let tiles = []

                    //Figure out number of months
                    const currentEndTime = new Date(currentFromTime.getTime() + currentViewPeriod)
                    let numberOfTiles =  Math.abs( (currentFromTime.getMonth() - currentEndTime.getMonth()) + 12 * (currentFromTime.getFullYear() - currentEndTime.getFullYear()))

                    let elapsedMonthDuration = 0
                    let currentDay = new Date(currentFromTime.getTime())
                    currentDay.setHours(0, 0, 0, 0)
                    currentDay.setDate(1)
                    currentDay = new Date(currentDay.setDate(currentFromTime.getDate() - (currentFromTime.getDay() || 7)))

                    let currentMonthStartsOn = new Date(currentFromTime)
                    currentMonthStartsOn.setHours(0, 0, 0, 0)
                    currentMonthStartsOn.setDate(1)
                    for (let i = 0; i < numberOfTiles; i++) {

                        tiles[i] = { subTiles: [] }
                        tiles[i].startsAt = new Date(currentMonthStartsOn)
                        if (i == 0 && tiles[i].startsAt.getTime() != currentViewPeriod)    {
                            numberOfTiles +=1;
                        }

                        const currentMonthNumberOfDays = daysInMonth(tiles[i].startsAt.getFullYear(), tiles[i].startsAt.getMonth())
                        currentMonthStartsOn.setMonth(currentMonthStartsOn.getMonth() + 1)
                        tiles[i].primaryLabel = FormatDate(tiles[i].startsAt, 'MMM yyyy');
                        tiles[i].x1 = Math.floor(((tiles[i].startsAt.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
                        tiles[i].x2 = Math.floor(((currentMonthStartsOn.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)

                        let j = 0;
                        while (currentDay.getTime() < tiles[i].startsAt.getTime() + (currentMonthNumberOfDays * dayDuration))   {
                            tiles[i].subTiles[j] = {
                                startsAt: currentDay
                            }
                            tiles[i].subTiles[j].label = FormatDate(tiles[i].subTiles[j].startsAt, 'd')
                            tiles[i].subTiles[j].endsAt = new Date(tiles[i].subTiles[j].startsAt.getTime() + (dayDuration * 7))
                            tiles[i].subTiles[j].x1 = Math.floor(((tiles[i].subTiles[j].startsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)
                            tiles[i].subTiles[j].x2 = Math.floor(((tiles[i].subTiles[j].endsAt.getTime() - fromTime.getTime()) / currentViewPeriod) * width)


                            currentDay = new Date(currentDay.getTime() + dayDuration * 7)
                            j++;
                        }
                        
                    }

                    
                    return tiles
                }
            },
            /*"quarters": {
                fromPeriod: 1000 * 60 * 60 * 24 * 31 * 10,
                calculateTiles: () => {
                    let tiles = []
                    
                    return tiles
                }
            },*/
        }

        let currentTier = "days"
        for (let tier in tiers) {
            if (tiers[tier].fromPeriod < currentViewPeriod)  {
                currentTier = tier
            }
        }
        
        //Calculate how many tiles and the labels associated with them here.
        const tiles = tiers[currentTier].calculateTiles()
        /*if (onChange !== undefined) {
            if (lastCalculatedFromTime != currentFromTime || lastCalculatedViewPeriod != currentViewPeriod || width !=lastCalculatedCanvasWidth)    {
                SetLastCalculatedCanvasWidth(width)
                SetLastCalculatedFromTime(currentFromTime)
                SetLastCalulatedViewPeriod(currentViewPeriod)
                onChange(tiles)
            }
        }*/

        if (tilesRef !== undefined) {
            tilesRef(tiles)
        }
        return tiles
    }

    
    const drawMainAxis = (canvas, ctx, tiles) => {
        let width = canvas.width/ratio
        let height = canvas.height/ratio


        for(let tile of tiles)  {


            ctx.font = '12px Roboto';
            ctx.fillStyle = '#191C22'
            ctx.textBaseline = 'middle';
            ctx.textAlign = "left";
            ctx.fillText(tile.primaryLabel, tile.x1 + 5, height / 2);


        }


        const now = new Date();
        const nowX = Math.floor(((now.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
        ctx.fillStyle = '#2E72D2'
        ctx.beginPath()
        ctx.ellipse(nowX, height / 2, 4, 4, 0, 0, Math.PI*2, true)
        ctx.closePath()
        ctx.fill()

    }

    const drawSubAxis = (canvas, ctx, tiles) => {
        let width = canvas.width/ratio
        let height = canvas.height/ratio


        for(let tile of tiles)  {


            if (tile.subTiles.length > 0)   {
                for(let subTile of tile.subTiles)  {
                    if (subTile.isWeekendDay === true)  {
                        /*ctx.fillStyle = "rgba(0,0,0,0.05)";
                        ctx.fillRect(subTile.x1, height / 2, subTile.x2 - subTile.x1, height);*/
                    }
                    //Subtile
                    /*ctx.strokeStyle = '#CBD2DE'
                    ctx.beginPath();
                    ctx.moveTo(subTile.x1, height / 2);
                    ctx.lineTo(subTile.x1, height);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.moveTo(subTile.x2, height / 2);
                    ctx.lineTo(subTile.x2, height);
                    ctx.stroke();*/


                    ctx.font = '12px Roboto';
                    ctx.fillStyle = '#191C22'
                    ctx.textBaseline = 'middle';
                    ctx.textAlign ="center";
                    ctx.fillText(subTile.label, subTile.x1 + (subTile.x2 - subTile.x1) / 2, height / 2);
                }
            }
            //ctx.beginPath()
            //ctx.arc(25, 25, 20, 0, 2*Math.PI)
            //ctx.fill()
    
        }


        const now = new Date();
        const nowX = Math.floor(((now.getTime() - currentFromTime.getTime()) / currentViewPeriod) * width)
        ctx.fillStyle = '#2E72D2'
        ctx.beginPath()
        ctx.ellipse(nowX, height / 2, 4, 4, 0, 0, Math.PI*2, true)
        ctx.closePath()
        ctx.fill()

    }

    const handleUpdate = () =>  {
        if (mainAxisCanvasRef.current) {
            mainRO.observe(mainAxisCanvasRef.current)
            const canvas = mainAxisCanvasRef.current

            const ctx = canvas.getContext('2d')
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            resizeCanvas(canvas)
            ctx.translate(0.5, 0.5);
            drawMainAxis(canvas, ctx, calculateTiles(canvas))


        }

        if (subAxisCanvasRef.current) {
            subRO.observe(subAxisCanvasRef.current)
            const canvas = subAxisCanvasRef.current

            const ctx = canvas.getContext('2d')
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            resizeCanvas(canvas)
            ctx.translate(0.5, 0.5);
            drawSubAxis(canvas, ctx, calculateTiles(canvas))  
        }
    }
    React.useEffect(() => {
        handleUpdate()
        if (!hasExternalControl)    {
            mainAxisCanvasRef.current.addEventListener('wheel',  handleMouseWheel, { passive: false });
        }
        return () => {
            mainRO.disconnect()
            subRO.disconnect()
            if (!hasExternalControl)    {
                if (mainAxisCanvasRef.current !== undefined && mainAxisCanvasRef.current != null) {
                    mainAxisCanvasRef.current.removeEventListener('wheel', handleMouseWheel, { passive: false });
                }
                if (subAxisCanvasRef.current !== undefined && subAxisCanvasRef.current != null) {
                    subAxisCanvasRef.current.removeEventListener('wheel', handleMouseWheel, { passive: false });
                }
            }
        }
    }, [currentViewPeriod, currentFromTime, fontLoaded])
    handleUpdate()

    React.useEffect(() => {
        if (currentViewPeriod != viewPeriod)    {
            SetViewPeriod(viewPeriod)
        }
        if (currentFromTime != fromTime)    {
            SetFromTime(fromTime)
        }
        if (currentMainAxisHeight != axisHeight * 0.4)    {
            SetMainAxisHeight(axisHeight * 0.4)
        }
        if (currentSubAxisHeight != axisHeight * 0.4)    {
            SetSubAxisHeight(axisHeight * 0.6)
        }
    }, [viewPeriod, fromTime, axisHeight])


    let canvasProps = {}
    if (!hasExternalControl) {
        canvasProps = {
            onMouseDown:handleMouseDown,
            onTouchStart:handleMouseDown,
            onMouseMove:handleMouseMove,
            onTouchMove:handleMouseMove
        }
    }
    let subCanvasProps = {}
    if (!hasExternalControl) {
        subCanvasProps = {
            onMouseDown:handleMouseDown,
            onTouchStart:handleMouseDown,
            onMouseMove:handleMouseMove,
            onTouchMove:handleMouseMove
        }
    }
    return (
    <div id={id} className="TimeAxis">
        <div className="TimeAxis-MainAxis">
            <canvas style={{height:currentMainAxisHeight}} ref={mainAxisCanvasRef} {...canvasProps}/>
        </div>
        <div className="TimeAxis-SubAxis">
            <canvas style={{height:currentSubAxisHeight}} ref={subAxisCanvasRef} {...subCanvasProps}/>
        </div>
    </div>
  )
}


TimeAxis.defaultProps = {
    axisHeight: 50,
    hasExternalControl: false,
    viewPeriod: 1000 * 60 * 60 * 24 * 5,
    fromTime: new Date()
}

export default TimeAxis 