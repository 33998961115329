//import './PageHeader.scss';
import React from 'react'


const GrowAnalyticsPage = ({pageHeaderHelper}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Grow Analytics")
  }, [])
  return (
    <>
      Grow Analytics
    </>
  )
} 

export default GrowAnalyticsPage