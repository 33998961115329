import './UploadPhotoPopup';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../components/Button';
import RowGroup from '../../../components/RowGroup/RowGroup';
import DropDownInput from '../../../components/input/DropDownInput';
import TextInput from '../../../components/input/TextInput';
import { RoundedDateToNearestLastDay } from '../../../helpers';
import Calendar from '../../../components/input/Calendar';

const UploadPhotoPopup_EditPhoto = ({
        photo,
        editPhotoCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    


        
    const cancelClicked = () => {
        transitionToPreviousStep(["list", null])
    }

    const doneClicked = () => {
        editPhotoCallback(photo.photoIndex, {photoName: currentEditPhotoName, date: currentEditPhotoDate})
        transitionToPreviousStep(["list", null])
    }
   

        
    const [currentEditPhotoName, SetCurrentEditPhotoName] = React.useState(photo !== undefined ? photo.photoName : "")
    const onPhotoNameChanged = (name) => {
        SetCurrentEditPhotoName(name)
    }
    const [currentEditPhotoDate, SetCurrentEditPhotoDate] = React.useState(photo !== undefined ? photo.date : new Date())
    const onCalendarDayClicked = (date) => {
        SetCurrentEditPhotoDate(date)
    }

    React.useLayoutEffect(() => {
        SetCurrentEditPhotoName(photo.photoName)
        SetCurrentEditPhotoDate(photo.date)

    }, [photo])

    //console.log(photos)
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-10" style={{flex: 1, padding: isMobile ? 0 : "0 100px"}}>
                        <div className="FlexContent" style={{gap: 2}}>
                            <div className="Text-Alt12">Name</div>
                            <TextInput placeHolder="Name" value={currentEditPhotoName} minWidth={isMobile ? "10ch" : "25ch"} onBlur={onPhotoNameChanged}/>
                        </div>
                        <div className="FlexContent" style={{gap: 2}}>
                            <div className="Text-Alt12">Date of Photo</div>
                            <Calendar
                            selectedDate={currentEditPhotoDate}
                            dateClicked={onCalendarDayClicked}
                            tileContent={(date) => {
                                let tileClassName = "ScheduleGrowPopup-Calendar-DayTileContent"
                                let additionalContent = null
                                if (date.getTime() === RoundedDateToNearestLastDay(1, currentEditPhotoDate).getTime()) {
                                    additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayStart-Visual"></div>
                                }
                                return (
                                    <div className="ScheduleGrowPopup-Calendar-DayTile">
                                        <div className={tileClassName}>
                                        
                                            {date.getDate()}
                                            {additionalContent && additionalContent}
                                        </div>
                                    </div>
                                )
                            }}/>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Done"} onClick={doneClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default UploadPhotoPopup_EditPhoto