import './CRMPage.scss';
import React from 'react'
import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'
 

const data = [
  { id: 1, date: "Mon Jul 05 2022", name: 'Some Order 1', customer: "Deryk", net_total: 126.20, category: "Loose Leaf", status: {name: "shipped", status: "Neutral"}, invoice: "No Invoice"},
  { id: 2, date: "Mon Jul 05 2022", name: 'Some Order 21', customer: "Deryk", net_total: 12.40, category: "Loose Leaf", status: {name: "attention required", status: "Warning"}, invoice: "No Invoice"},
  { id: 3, date: "Mon Jul 05 2022", name: 'Some Order 32', customer: "Deryk", net_total: 426.12, category: "Loose Leaf", status: {name: "new", status: "Success-Sub"}, invoice: "No Invoice"},
];

const CRM_OrdersPage = ({pageHeaderHelper}) => {
  const columns: GridColDef[] = [
    { accessor: 'date', Header: 'Date', type: 'date', width: "200", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'customer', Header: 'Customer', width: "200", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<string>) => (
      <>
        <div>
          {params.value}
        </div>
      </> 
    ), },
    { accessor: 'net_total', Header: 'Net Total', width: "100", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<Map>) => (
      <>
        <div>
          ${params.value}
        </div>
      </> 
    ), },
    { accessor: 'status', Header: 'Status', width: "200", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<Map>) => (
      <Badge status={params.value["status"]} content={params.value["name"]} size="Medium"/>
    ), },
    { accessor: 'invoice', Header: 'Invoice', width: "200", headerClassName: 'DataTable_Standard-Header', Cell: (params: GridRenderCellParams<string>) => (
      <>
        <div>
          {params.value}
        </div>
      </>
    ), }, 
  ];

  return (
    <div id="CRM_OrdersPage">
        <div className="ControlBar_Horizontal">
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Customers' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'status', label: 'Status' }]} defaultValue="status"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
        </div>
        <StandardTable id="OrdersTable" data={data} columns={columns} isSelectableRows={true} isResizable={true}/>
    </div>
  )
} 

export default CRM_OrdersPage