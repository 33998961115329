import './../CreateBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { DeviceTransmitting, GoodCheckmark, WiFiFull } from '../../../../assets/icons/Icons';
import Switch from '../../../../components/Switch';
import DropDownInput from '../../../../components/input/DropDownInput';
import { linkBladeToControlDevice, validateBladeSystemConnection } from '../../../../redux/entities/service/Blade';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../components/input/Checkbox';
import { ConnectToBladeBluetoothSlave, SendBladeUIDToBladeBluetoothSlave } from '../../ControlDevices/BluetoothManager';


  

const BluetoothPairToControlBoardWalkthroughStep = ({blade, controlDeviceSerialNumber, connectionStep, transitionToPreviousStep, transitionToNextStep, finishedLinkingCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()


    const [controlDeviceBluetoothService, SetControlBoardBluetoothService] = React.useState(null)

    const attemptBluetoothPair = async () => {
        const [bluetoothService, successful, err] = await ConnectToBladeBluetoothSlave(controlDeviceSerialNumber) 
        if (successful) {
            SetControlBoardBluetoothService(bluetoothService)
        }
        return successful
    }

    const [applyingBladeUIDStep, SetApplyingBladeUIDStep] = React.useState(null)
    const attemptSendBladeUIDToControlDevice = async () => {
        SetApplyingBladeUIDStep("sending_blade_uid")
        const [successful, err] = await SendBladeUIDToBladeBluetoothSlave(controlDeviceBluetoothService, blade.uid) 
        if (successful) {
            SetApplyingBladeUIDStep("updating_database")
            dispatch(linkBladeToControlDevice({
                bladeUID: blade.uid,
                controlDeviceSerialNumber: controlDeviceSerialNumber,
                callback: (success) => {
                    if (success)    {
                        SetApplyingBladeUIDStep("link_completed")
                    }else {
                        transitionToNextStep(["bluetooth_pairing", "link_blade_failed"])      
                    }
                }
            }))
        }else {
            transitionToNextStep(["bluetooth_pairing", "link_blade_failed"])            
        }
    }

    React.useEffect(() => {
        if (applyingBladeUIDStep === "link_completed")  {
            transitionToNextStep(["bluetooth_pairing", "link_blade_successful"])
        }
    }, [applyingBladeUIDStep])

    const backClicked = () => {
        transitionToPreviousStep(["select_control_device", null])
    }

    switch (connectionStep) {

        case "setup":

            const beingPairingClicked = async () => {
                transitionToNextStep(["bluetooth_pairing", "pairing"])
                const successful = await attemptBluetoothPair()
                if (!successful)    {
                    transitionToNextStep(["bluetooth_pairing", "pairing_failed"])
                }else {
                    transitionToNextStep(["bluetooth_pairing", "pairing_successful"])
                }
            }

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <div className="FlexContent-50">
                            <div className="FlexContent-30 FlexContent-Center">
                                <div className="PopupModal-StandardHeader">Begin Pairing to {controlDeviceSerialNumber}</div>
                            </div>

                            <div className="FlexContent-30">
                                <Button content={"Back"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={backClicked} width={"Flex100"}/>
                                <Button content={"Begin Pairing"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={beingPairingClicked} width={"Flex100"}/>
                            </div>
                        </div>
                    </div>
                </div>
            )

        case "pairing":

            

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pairing Bluetooth</div>
                    </div>
                </div>
            )
        
        case "pairing_failed":
            const tryBluetoothConnectingAgainClicked = () => {
                transitionToNextStep(["connecting_to_blade", "sending_credentials_over_bluetooth"])
            }

            const selectNewWiFiCredentialsClicked = () =>  {
                transitionToNextStep(["connecting_to_blade", "connection_options"])
            }
            return (

                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionFailed/>
                        <div className="PopupModal-StandardHeader">Blade Not Connected</div>
                        <Button content={"Retry"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryBluetoothConnectingAgainClicked} width={"Flex100"}/>
                        <Button content={"Try different method"} status="Primary-Inverted" onClick={selectNewWiFiCredentialsClicked}/>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
                
            )

        case "pairing_successful":
            const linkToBladeClicked = async () => {
                transitionToNextStep(["bluetooth_pairing", "linking_blade"])
                await attemptSendBladeUIDToControlDevice()
                
                
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="PopupModal-StandardHeader" style={{textAlign:"center"}}>Bluetooth Paired</div>
                        <Button content={"Link To Blade"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={linkToBladeClicked} width={"Flex100"}/>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
            )

            case "linking_blade":
    
                
    
                return (
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackLoading/>
                            <div className="PopupModal-StandardHeader">Linking Blade</div>
                        </div>
                    </div>
                )
            
            case "link_blade_failed":
                const tryLinkingBladeAgainClicked = async () => {
                    transitionToNextStep(["bluetooth_pairing", "linking_blade"])
                    await attemptSendBladeUIDToControlDevice()
                }
    
                const cancelClicked = () =>  {
                    finishedLinkingCallback()
                }
                return (
    
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                                
                            </div>
                        </div>
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackConnectionFailed/>
                            <div className="PopupModal-StandardHeader">Linking Blade Failed</div>
                            <Button content={"Retry"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryLinkingBladeAgainClicked} width={"Flex100"}/>
                            <Button content={"Cancel"} status="Primary-Inverted" onClick={cancelClicked} width={"Flex100"}/>
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                        </div>
                    </div>
                    
                )
    
            case "link_blade_successful":
                const doneClicked = async () => {
                    finishedLinkingCallback()
                }
                return (
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                                
                            </div>
                        </div>
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackConnectionSuccess/>
                            <div className="PopupModal-StandardHeader" style={{textAlign:"center"}}>Successfully linked!</div>
                            <Button content={"Finish"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={doneClicked} width={"Flex100"}/>
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                        </div>
                    </div>
                )
    


            
        /*case "sending_wifi_credentials":
            return (
                <div className="Walkthrough-Card Walkthrough-Card-VerticalCenter">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pushing WiFi Credentials</div>

                    </div>
                </div>
            )

            case "wifi_credentials_sent":
                return (
                    
    
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                                
                            </div>
                        </div>
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackConnectionSuccess/>
                            <div className="PopupModal-StandardHeader">WiFi Credentials Sent</div>
    
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                        </div>
                    </div>
                )
    

                case "blade_connected":
                    const setupRackClicked = () =>  {
                        rackConnectedCallback()
                        transitionToNextStep(["select_blade_group", "blade_group_listing"])
                    }
                    return (
                        <div className="Walkthrough-Card">
                            <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                                <RackConnectionSuccess/>
                                <div className="FlexContent-40">
                                    <div className="Walkthrough-StandardHeader Walkthrough-StandardHeader-Center">Blade Connected Successfully</div>
                                    {selectedConnectionOption === "provide_credentials" && 
                                        <div className="FlexContent-20">
                                            <div className="FlexContent FlexContent-Center">
                                                <Checkbox
                                                    style={"check"}
                                                    label="Save WiFi Credentials for future use"
                                                    state={saveWiFiCredentialsState}
                                                    onChange={saveWiFiCredentialsStateChanged}/>
                                            </div>
                                            <TextInput value={wiFiCredentialsName} onBlur={wiFiCredentialsNameChanged} placeHolder={"Enter WiFi Name"} disabled={!saveWiFiCredentialsState}/>
                                        </div>
                                    }
                                </div>                    
                            </div>
                            <div className="Walkthrough-Card-FooterButtons">
                                <Button content="Setup Rack Information" size={isMobile ? "Medium" : "Large"}  status="Primary" onClick={setupRackClicked} width={"Flex100"}/>
                            </div>
                        </div>
                    
                    )*/
                    

        default:
            return (<></>)
    }
}

  


export default BluetoothPairToControlBoardWalkthroughStep