import './GridTile.scss';


export const GridTile = (props) => {

  return (
    <div className={"GridTile" + (props.className ? " " + props.className : "") + (props.size === "full" ? " GridTile-FullWidth" : "")}>
      <div className="GridTile-Content">
        {props.children}
      </div>
    </div>
  )
} 

GridTile.defaultProps = {
  className: false,
}







export const GridWrappingTiles = (props) => {

  return (
    <div className="GridTile-WrappingTiles">
      {props.children}
    </div>
  )
} 

GridWrappingTiles.defaultProps = {
  size:"auto"
}
