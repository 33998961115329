import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewBladeGroup, selectAllBladeGroups, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';
import useMeasure from '../../../../useMeasure';
import NumberInput from '../../../../components/input/NumberInput';
import DropDownInput from '../../../../components/input/DropDownInput';

  

const SelectRackIndexWalkthroughStep = ({bladeGroupId, newBladeUID, initialBladeIndex, facilityId, transitionToPreviousStep, transitionToNextStep, bladeIndexSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const allBlades = useSelector(selectAllBlades)
    const allBladeGroups = useSelector(selectAllBladeGroups)




    let selectedBladeGroup = allBladeGroups.find((bG) => bG.id === bladeGroupId)
    let newBlade = allBlades.find((b) => b.uid === newBladeUID)
    //let initialBladeIndex = movingBladeIndex ?? selectedVerticalRackGroup.rack_map.racks.length + 1
    
    const [selectedBladeIndex, SetSelectedBladeIndex] = React.useState(null)
    React.useLayoutEffect(() => {
        SetSelectedBladeIndex(initialBladeIndex)
    }, [initialBladeIndex])
    
    const [potentialBladeIndex, SetPotentialBladeIndex] = React.useState(null)
    const [currentBladeOrder, SetCurrentBladeOrder] = React.useState([])
    React.useLayoutEffect(() => {
        //Given the blade group id, and all the available blades, assemble the initial blade order
        let bladeOrder = [ ...Array(selectedBladeGroup.number_of_blades).keys() ].map( i => null);

        let currentBladeIndex = (potentialBladeIndex ? potentialBladeIndex : selectedBladeIndex)

        let groupBlades = []
        for (let bladeUID of selectedBladeGroup.blades) {
            let foundBlade = allBlades.find((b) => b.uid === bladeUID)
            if (foundBlade !== undefined)   {
                groupBlades.push(foundBlade)
            }
        }

        let bladesShifting = false
        for (let i = 0; i < selectedBladeGroup.number_of_blades; i++)   {
            let foundBlade = groupBlades.find((b) => b.index === i + 1)
            if (foundBlade !== undefined)   {
                if (i == currentBladeIndex - 1) {
                    bladesShifting = true
                }
                bladeOrder[(bladesShifting ? i + 1 : i)] = foundBlade
            }else if (bladesShifting)   {
                bladesShifting = false
            }
        }
        bladeOrder[currentBladeIndex - 1] = {...newBlade, index: currentBladeIndex}
        console.log(currentBladeIndex, selectedBladeIndex, bladeOrder)
        SetCurrentBladeOrder(bladeOrder)
    }, [allBlades, selectedBladeIndex, potentialBladeIndex])






    const existingRackContainerRefs = React.useRef({})
    const bladeListingContainerRef = React.useRef(undefined)
    const moveRackContainerRef = React.useRef(undefined)
    const [moveRackPointerId, SetMoveRackPointerId] = React.useState(null)
    const [isPointerDownOverMovingRack, SetIsPointerDownOverMovingRack] = React.useState(false)
    const [isBladeBeingDragged, SetIsBladeBeingDragged] = React.useState(false)
    const [currentPointerPosition, SetCurrentPointerPosition] = React.useState({x: 0, y: 0})
    const [rackDraggingOffset, SetRackDraggingOffset] = React.useState({x: 0, y: 0})

    const beginMoveRackPointerDown = React.useCallback((e) =>  {
        if (bladeListingContainerRef.current !== undefined && bladeListingContainerRef.current.setPointerCapture)    {
            SetMoveRackPointerId(e.pointerId)
            bladeListingContainerRef.current.setPointerCapture(e.pointerId);
            SetIsPointerDownOverMovingRack(true)
        }
    })
    const beginMoveRackPointerMove = React.useCallback((e) =>  {
        if (isPointerDownOverMovingRack)    {
            SetIsPointerDownOverMovingRack(false)
            SetCurrentPointerPosition({x: e.clientX, y: e.clientY})
            const { offsetLeft, offsetTop } = moveRackContainerRef.current
            SetRackDraggingOffset({x: e.clientX - offsetLeft - walkthroughCardLeft, y: e.clientY - offsetTop - walkthroughCardTop})

            SetIsBladeBeingDragged(true)
            //e.preventDefault()
            //e.stopPropagation()
        }

        let mouseOffsetY = e.clientY - walkthroughCardTop

        if (isBladeBeingDragged) {
            //e.preventDefault()
            //e.stopPropagation()
            SetCurrentPointerPosition({x: e.clientX , y: e.clientY})

            //Check current position against all existing racks
            let closestBefore = null;
            let closestAfter = null;
            for (let [rackIndex, rackRef] of Object.entries(existingRackContainerRefs.current))  {
                if (rackRef)    {
                    if (rackRef.offsetTop + (rackRef.clientHeight / 2) <= mouseOffsetY)  {
                        if (closestBefore === null || closestBefore.ref.offsetTop < rackRef.offsetTop)  {
                            closestBefore = {index: parseInt(rackIndex), ref: rackRef}
                        }
                    }else {
                        if (closestAfter === null || closestAfter.ref.offsetTop > rackRef.offsetTop)  {
                            closestAfter = {index: parseInt(rackIndex), ref: rackRef}
                        }
                    }
                }
            }
            if (closestBefore !== null && closestAfter !== null)    {
                SetPotentialBladeIndex(closestBefore.index)       
            }else if (closestBefore === null)  {
                SetPotentialBladeIndex(1)
            }else if (closestAfter === null) {
                SetPotentialBladeIndex(selectedBladeGroup.blades.length)
            }
        }
    })
    const beginMoveRackPointerUp = React.useCallback((e) =>  {
        SetIsPointerDownOverMovingRack(false)
        SetIsBladeBeingDragged(false)
        if (potentialBladeIndex)    {
            SetSelectedBladeIndex(potentialBladeIndex)
            SetPotentialBladeIndex(null)
        }
        if (bladeListingContainerRef.current !== undefined && bladeListingContainerRef.current.releasePointerCapture && moveRackPointerId)    {
            bladeListingContainerRef.current.releasePointerCapture(moveRackPointerId);
        }

        //SetIsRackDoneBeingDragged(true)
    })
    

    const [walkthroughCardHandleRef, { documentTop: walkthroughCardTop, documentLeft: walkthroughCardLeft }] = useMeasure()



    const backClicked = () =>   {
        transitionToPreviousStep(["select_blade_group", "blade_group_listing"])
    }
    const nextClicked = () =>   {
        bladeIndexSelectedCallback({bladeIndex: selectedBladeIndex})
        transitionToNextStep(["name_blade", null])
    }

    let offsetPosition = false
    let positionOptions = []
    if (selectedBladeGroup !== undefined)   {
        for (let i = 0; i < selectedBladeGroup.number_of_blades; i++)   {
            positionOptions.push({"label": "Position " + (i + 1).toString(), "value": i + 1})
        }
    }
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill" ref={walkthroughCardHandleRef}>
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">{selectedBladeGroup.display_name}</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    {!isMobile && 
                        <div>
                            <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                <div className="Button-Large-Icon"><RackStandardGroup width={(isTablet || isDesktop) ? 168 : 140}/></div>
                            </>}/>
                        </div>
                    }
                    <div className="FlexContent-HFill FlexContent-Center">
                        <div className="FlexContent-20 FlexContent-HFill">
                            <div className={(isMobile ? "FlexContent-5" : "FlexContent-H-20") + " FlexContent-Center"}>
                                <div className="Text-Medium-S14">Move Blade into installed position</div>
                                <div className="FlexContent-H-10">
                                <div className="Text-Medium-S14">Total:</div>

                                </div>
                            </div>
                            <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks FlexContent-HFill"
                                onPointerMove={beginMoveRackPointerMove}
                                onPointerUp={beginMoveRackPointerUp}
                                ref={bladeListingContainerRef}>
                                {currentBladeOrder.map((blade, index) => {
                                    if (blade !== null)   {
                                        return (<>
                                            {blade.uid === newBladeUID && (() => {
                                                const bladeProps = {style:{}}
                                                if (isBladeBeingDragged) {
                                                    bladeProps.style.left = currentPointerPosition.x - rackDraggingOffset.x
                                                    bladeProps.style.top = currentPointerPosition.y - rackDraggingOffset.y
                                                }else {
                                                    offsetPosition = true
                                                }
                                                return (<>
                                                    {/*isRackBeingDragged &&
                                                        <div className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-DropIndicator"}></div>
                                                    */}
                                                    <div className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack" + (!isBladeBeingDragged ? " ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Still" : " ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Moving")}
                                                        key={newBlade.index}
                                                        ref={moveRackContainerRef}
                                                        {...bladeProps}>
                                                        <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name noselect">
                                                            <div onPointerDown={beginMoveRackPointerDown}
                                                                    onContextMenu={(e) => {e.preventDefault(); return false;}}>
                                                                <ReorderItem/>
                                                            </div>
                                                            {blade["display_name"]}
                                                            <div className="FlexContent-H-10 FlexContent-Center">
                                                                <DropDownInput value={selectedBladeIndex} options={positionOptions} onSelectionChange={(index) => {console.log(index);SetSelectedBladeIndex(parseInt(index))}}/>
                                                            </div>
                                                        </div>
                                                        {/*<div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position noselect">Position {rack["index"]}</div>*/}
                                                    </div> 
                                                </>)
                                            
                                            })()}
                                            {(isBladeBeingDragged && blade.uid === newBladeUID) && (() => {
                                                offsetPosition = true
                                                return (
                                                    <div key={blade.index + "-dragging"} className={"ManageRackWalkthrough_Popup-RackPlacement-Racks-DropIndicator"}></div>
                                                    
                                                )
                                            })()}                          

                                            {blade.uid !== newBladeUID && <>
                                                <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack ManageRackWalkthrough_Popup-RackPlacement-ExistingRack noselect"
                                                    ref={el => existingRackContainerRefs.current[blade.index] = el}
                                                    key={blade.index}>
                                                    <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name">{blade["display_name"]}</div>
                                                    <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position">Position {blade.index}</div>
                                                </div>
                                            </>}
                                        </>)
                                    }else {
                                        return (
                                            <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack ManageRackWalkthrough_Popup-RackPlacement-PlaceholderRack noselect"
                                                ref={el => existingRackContainerRefs.current[index + 1] = el}
                                                key={index + 1}>                                                
                                                <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name">-</div>
                                                <div className="ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position">Position {index + 1}</div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>                       
        </div>
    )
    
}


SelectRackIndexWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default SelectRackIndexWalkthroughStep