
import './SelectPlantTypes.scss';
import React from 'react'
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupSubGroup from '../components/RowGroup/RowGroupSubGroup';
import { useMediaQuery } from 'react-responsive';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import { selectAllPlantTypeGroups, selectAllPlantTypes } from '../redux/entities/PlantTypes';
import { useSelector } from 'react-redux';


const SelectPlantTypes = ({selectedPlantType, onPlantTypeSelection}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const allPlantGroups = useSelector(state => selectAllPlantTypeGroups(state))
  const allPlantTypes = useSelector(state => selectAllPlantTypes(state))

  const [plantTypesList, SetPlantTypesList] = React.useState({})
  const [plantGroupsList, SetPlantGroupsList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedPlantType, SetSelectedPlantType] = React.useState(undefined)
  React.useEffect(() => {
    if (selectedPlantType !== currentSelectedPlantType) {
      SetSelectedPlantType(selectedPlantType)
    }
  }, [selectedPlantType])
  
  const [currentGroupByTypeFilter, SetGroupByTypeFilter] = React.useState('*')
  


  React.useEffect(() => {
    let currentPlantTypesList = {}
    let currentPlantGroupsList = {}

    let currentPlantTypes = []
    let currentPlantGroups = []
    
    let availablePlantGroups = []

    if (currentGroupByTypeFilter === "*") {
      availablePlantGroups = [...allPlantGroups]
    }else {
      availablePlantGroups = [allPlantGroups.find((plantGroup) => plantGroup.id === currentGroupByTypeFilter)]
    }

    if (currentSearchInput === "")  {
      currentPlantGroups = [...availablePlantGroups]
    }else {
      currentPlantGroups.push(...availablePlantGroups.filter((plantGroup) => {
        if (plantGroup.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1)  {
          currentPlantTypes.push(...allPlantTypes.filter((plantType) => {
            if (plantType.group_id === plantGroup.id) {
              return plantType
            }
          }))
          return plantGroup
        }
      }))
    }
    currentPlantTypes.push(...allPlantTypes.filter((plantType) => {

      if ((currentSearchInput === "" || plantType.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1) && currentPlantTypes.indexOf(plantType) === -1)  {
        const foundPlantGroup = availablePlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          if (currentPlantGroups.indexOf(foundPlantGroup) === -1) {
            currentPlantGroups.push(foundPlantGroup)
          }
          return plantType
        }
      }
    }))
    
    for (let plantType of currentPlantTypes) {
      if (currentPlantGroupsList[plantType.group_id] === undefined) {
        let foundPlantGroup = currentPlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          currentPlantGroupsList[plantType.group_id] = {
            data: foundPlantGroup,
          }
        }
      }
      currentPlantTypesList[plantType.id] = {
        data: plantType,
        groupKey: plantType.group_id
      }
    }
    SetPlantTypesList(currentPlantTypesList)
    SetPlantGroupsList(currentPlantGroupsList)
  }, [allPlantGroups, allPlantTypes, currentSearchInput, currentGroupByTypeFilter])



  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const onGroupFilter = (value) =>  {
    SetGroupByTypeFilter(value)
  }
  

  const handlePlantTypeSelectedStateChanged = (plantTypeId, state) => {
    if (selectedPlantType === plantTypeId)    {
        if (state)  {
            SetSelectedPlantType(plantTypeId)
            if (onPlantTypeSelection !== undefined) {
              onPlantTypeSelection(plantTypeId)
            }
        }else {
            SetSelectedPlantType(null)
            if (onPlantTypeSelection !== undefined) {
              onPlantTypeSelection(null)
            }
        }
    }else if (state) {
        SetSelectedPlantType(plantTypeId)
        if (onPlantTypeSelection !== undefined) {
          onPlantTypeSelection(plantTypeId)
        }
    }
}



  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Plant</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      }
  }

  return (
    <>
      <div id="SelectPlantTypes">
        <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectPlantTypes_PlantTypeRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
          }}/>

          {Object.entries(plantGroupsList).map(([plantGroupIdAsString, plantGroup]) => {
            return <>
              <RowGroupSubGroup 
                title={plantGroup.data.name} 
                expandedState={true} 
                rows={Object.entries(plantTypesList).filter(([plantTypeIdAsString, plantTypeInfo]) => plantTypeInfo.data.group_id === plantGroup.data.id).map(([plantTypeIdAsString, plantTypeInfo]) => {
                  let plantTypeId = parseInt(plantTypeIdAsString)
                  return (
                    <SelectPlantTypes_PlantTypeRow 
                      plantTypeKey={plantTypeId} 
                      plantTypeInfo={plantTypeInfo} 
                      isSelected={selectedPlantType === plantTypeId}
                      onSelectedChanged={(state) => {handlePlantTypeSelectedStateChanged(plantTypeId, state)}}
                      columnSizes={columnSizes}/>
                  ) 
                })}
                numRowsDisplay="types" 
                emptyRowsDisplay="no plant types found"/>
            </>
          })}
      </RowGroup>
      </div>
    </>
  )
} 

export default SelectPlantTypes


const SelectPlantTypes_PlantTypeRow = ({plantTypeKey, plantTypeInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const plantType = isPlaceholder ? null : plantTypeInfo.data

  let secondaryHeaderContent = []

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : plantType.name
  
  //let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : plantTypeKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>}/>
  </>)
}