import './../CreateBladeWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';

  

const SelectBladeSerialNumberWalkthroughStep = ({initialBladeSerialNumber, cancelCallback, transitionToNextStep, bladeSerialNumberSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });



    const [selectedBladeSerialNumber, SetSelectedBladeSerialNumber] = React.useState(initialBladeSerialNumber)
    const bladeSerialNumberInputChanged = (value) => {
        SetSelectedBladeSerialNumber(value)
    }

    const cancelClicked = () =>   {
        cancelCallback()
    }
    const nextClicked = () =>   {
        bladeSerialNumberSelectedCallback({bladeSerialNumber: selectedBladeSerialNumber})
        transitionToNextStep(["final_confirm", null])
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Provide new blade serial number</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-HFill">
                        <div className="FlexContent-30 FlexContent-Center FlexContent-HFill">
                            <div className="Walkthrough-FormItem">
                                <div className="Walkthrough-FormItem-Question">Enter Serial Number</div>
                                <div className="Walkthrough-FormItem-Answer">
                                    <TextInput value={selectedBladeSerialNumber} placeHolder="Blade Name" onChange={bladeSerialNumberInputChanged}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next Step"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedBladeSerialNumber === ""}/>
            </div>
        </div>
    )
    
}
  


export default SelectBladeSerialNumberWalkthroughStep