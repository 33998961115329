import './UploadPhotoPopup';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../components/Button';
import RowGroup from '../../../components/RowGroup/RowGroup';
import DropDownInput from '../../../components/input/DropDownInput';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { Trash } from '../../../assets/icons/Icons';
import { FormatDate, RoundToNearest } from '../../../helpers';
import ProgressBar from '../../../components/ProgressBar';


const UploadPhotoPopup_Listing = ({
        selectedZoneUID,
        zoneOptions,
        photos, isUploading, uploadingCompleted,
        addPhotoCallback,
        cancelUploadCallback, beginUploadCallback,
        beginEditPhotoCallback,
        doneUploadingCallback, deletePhotoCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


        
    const cancelClicked = () => {
        cancelUploadCallback()
    }

    const addPhotoClicked = () => {
        addPhotoCallback()
    }
    const uploadClicked = () =>  {
        beginUploadCallback()
    }

    const editPhotoClicked = (photoIndex) => {
        beginEditPhotoCallback(photoIndex)
        transitionToNextStep(["edit_photo", null])
    }

    const doneClicked = () => {
        doneUploadingCallback()
    }
    const [activeZoneUID, SetActiveZoneUID] = React.useState(selectedZoneUID)
    const onZoneSelectionChanged = (zoneUID) => {
        //SetActiveZoneUID(zoneUID)
    }
    React.useLayoutEffect(() => {

    }, [activeZoneUID])



    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
            date: {label: <>Date</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
            action: {label: <></>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        }
    }
 
    
    //console.log(photos)
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-15">
                        <div className="FlexContent" style={{gap: 2}}>
                            <div className="Text-FormAltLabel-Small">Selected Zone</div>
                            <DropDownInput
                                value={activeZoneUID}
                                options={zoneOptions}
                                onSelectionChange={onZoneSelectionChanged}/>
                        </div>
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            hasContainer={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}>

                            <UploadPhotoEntryRow isPlaceholder={true} columnSizesChanged={(columns) => {
                                console.log(columns)
                                SetColumnSizes(columns)
                            }}/>
                            {photos.map((photo, index) => {
                                const editPhotoCallback = () => {
                                    editPhotoClicked(photo.photoIndex)
                                }
                                return <>
                                    <UploadPhotoEntryRow 
                                        photo={photo}
                                        index={index}
                                        isUploading={isUploading}
                                        editPhotoCallback={editPhotoCallback}
                                        deletePhotoCallback={deletePhotoCallback}
                                        columnSizes={columnSizes}/>
                                </>
                            })}
                            {photos.length === 0 && <>
                                <div className="Text-Alt14" style={{padding: "20px 0"}}>
                                    No photos selected
                                </div>
                            </>}
                        </RowGroup>
                        <Button status={"Hyperlink"} disabled={uploadingCompleted || isUploading} content={photos.length > 0 ? "Upload Another Photo" : "Upload a Photo"} onClick={addPhotoClicked}/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelClicked} size={"Flex50"}/>
                {photos.length > 0 && <>
                    {!uploadingCompleted && <>
                        <Button status="Primary" content={"Upload " + photos.length.toString() + (photos.length > 1 ? " Photos" : " Photo")} onClick={uploadClicked} size={"Flex50"} disabled={isUploading}/>
                    </>}
                    {uploadingCompleted && <>
                        <Button status="Primary" content={"Done"} onClick={doneClicked} size={"Flex50"}/>
                    </>}
                </>}
                {photos.length === 0 && <>
                    <Button status="Primary" content={"Select New Photo"} onClick={addPhotoClicked} size={"Flex50"}/>
                </>}
            </div>
        </div>
    )
    
}


export default UploadPhotoPopup_Listing





export const UploadPhotoEntryRow = ({photo, index, isPlaceholder, isUploading, editPhotoCallback, deletePhotoCallback, columnSizes, columnSizesChanged}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
    const deletePhotoClicked = () => {
        deletePhotoCallback(photo.photoIndex)
    }
    //console.log(photo)
    let secondaryHeaderContent = []
    if (!isMobile) {
      secondaryHeaderContent.push({
        key: "date", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    <div>{FormatDate(photo.date, "MM/dd/yy")}</div>
                </> : <>
                    <div>00/00/00</div>
                </>
            )}</div>
        </div>
      </>
      })
      secondaryHeaderContent.push({
        key: "action", content: <>
          <div className="noselect" style={{fontSize:14}}>
            {isPlaceholder && <>
                <div className="FlexContent-H-5" style={{marginLeft:80}}>
                    <Button content={<div style={{padding:"0 20px"}}>Edit</div>} status={"Primary-Inverted"}/>     
                    <Button content={<Trash fill={"#D82C0D"}/>} status={"Primary-Inverted"}/>
                </div>
            
            </>}
            {!isPlaceholder && <>
                {(!photo.uploaded && !photo.uploading) && 
                    <div className="FlexContent-H-5" style={{marginLeft:80}}>
                        <Button content={<div style={{padding:"0 20px"}}>Edit</div>} status={"Primary-Inverted"} onClick={editPhotoCallback}/>     
                        <Button content={<Trash fill={"#D82C0D"}/>} status={"Primary-Inverted"} onClick={deletePhotoClicked}/>
                    </div>
                }
                {(photo.uploading || photo.uploaded) && <>
                    <div className="FlexContent-5">
                        <div className="FlexContent-H">
                            <div className="Text-FormLabel-Small" style={{flex: 1}}>
                                {(photo.uploading && !photo.uploaded) && <>Uploading</>}
                                {(!photo.uploading && photo.uploaded) && <>Uploaded</>}
                            </div>
                            <div className="Text-FormLabel-Small">
                                {RoundToNearest(photo.progress * 100, 1)}%
                            </div>
                        </div>
                        <div>
                            <ProgressBar progress={photo.progress * 100}/>
                        </div>
                    </div>
                </>}
            </>}
          </div>
        </>
      })
      
    }
    

    //transplantEntry.zone_uid
    let title = isPlaceholder ? <>Unnamed long photo name</> : photo.photoName
    
    const [imageSRC, SetImageSRC] = React.useState(null)
    React.useLayoutEffect(() => {
        if (!isPlaceholder, imageSRC === null && photo !== undefined && photo.file !== undefined)  {
            SetImageSRC(window.URL.createObjectURL(photo.file))
        }
    }, [isPlaceholder, imageSRC, photo])
   
    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : index}
        title={
            <div className="FlexContent-H-10 FlexContent-Center">
                {isPlaceholder && <>
                    <div className="UploadPhotoPopup-PhotoThumb"></div>
                </>}
                {!isPlaceholder && <>
                    <img className="UploadPhotoPopup-PhotoThumb" src={imageSRC}/>
                </>}
                <div className="Text-SubHeader">
                    {title}
                </div>
            </div>
        }
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showRowSeperator={true}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        hasIssues={false}
        numberOfIssues={0}
        isSelectable={false}
        isExpandable={isMobile}
        hasAdditionalFunctions={false}
        content={<>
          {(() => {
            if (isMobile && !isPlaceholder) {
              return (<>
                <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                    <Button content={"Edit"} status={"Primary-Inverted"}/>    
                </div>
              </>)
            }
        
          })()}
        </>}
      />
    </>)
  }