import './StandardList.scss';
import React from 'react'
 

const StandardListRowColumn = ({
    row, columns, 
    columnKey, column, columnBounds, rowColumnDefinition, 
    columnSpacing, columnPadding, rowPadding, groupedIndent
  }) => {

  //const [columnWidth, SetColumnWidth] = React.useState("auto")
  
  if (column.hidden)  {
    return (<></>)
  }

  let columnWidth = columnBounds[columnKey] !== undefined ? columnBounds[columnKey].width  : "auto"
  if (column.width === "grow")  {
    columnWidth -= columnSpacing
  }
  if (isNaN(columnWidth) || columnWidth < 0)
    columnWidth = 0

  let containterProps = {
    style: {
      width: columnWidth
    }
  }
  if (column.width === "grow")  {
    containterProps.style.flexGrow = 1
  }
  const contentProps = {
    style: {
      padding: rowPadding + "px " + columnPadding + "px"
    }
  }
  
  if (rowColumnDefinition.buildRender !== undefined)  {
    let bounds = {width: 0, height: 0, innerWidth: 0, innerHeight: 0}
    if (columnBounds[columnKey] !== undefined)  {
      bounds = {
        width: columnBounds[columnKey].width, 
        innerWidth: columnBounds[columnKey].width - (columnPadding * 2),
        height: columnBounds[columnKey].height, 
        innerHeight: columnBounds[columnKey].height - (rowPadding * 2)
      }
    }
    let props = {
      row: row,
      column: column,
      bounds: bounds,
      columns: columns,
      columnBounds: columnBounds,
    }
    if (column.isGroupColumn) {
      props.renderContent = Object.entries(column.groupedColumns).map(([subColumnKey, subColumn]) => {
        const rowSubColumnDefinition = row.columns[subColumnKey] !== undefined ? row.columns[subColumnKey] : {
          render: (<></>)
        }
        return (
          <StandardListRowColumn 
            key={subColumnKey}
            columnKey={subColumnKey}
            column={subColumn} 
            columnBounds={columnBounds} 
            rowColumnDefinition={rowSubColumnDefinition} 
            columnSpacing={columnSpacing} 
            columnPadding={columnPadding} 
            rowPadding={rowPadding}/>
        )
      })
      
    }
    rowColumnDefinition.render = rowColumnDefinition.buildRender(props)
  }

  return (
    <div className="StandardList-Row-ColumnContainer"
      {...containterProps}>
      <div className="StandardList-Row-ColumnContent" {...contentProps}>
        {rowColumnDefinition.render}
      </div>
    </div>
  )
} 

StandardListRowColumn.defaultProps = {
  columns: {}
}


export default StandardListRowColumn