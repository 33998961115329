import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'


const processPlantTypeFromAPI = (plantType) =>  {
  
  return plantType
}

export const getAllPlantTypes = createAsyncThunk('plantTypes/getAllPlantTypes', async ({}, { getState }) => {
  return await FetchPost(APIRepository.PlantTypes.GetAllPlantTypesForAccount, {
    ...getAccountSessionData(getState()),
    have_plant_types: {}
  })  
})

export const plantTypesSlice = createSlice({
  name: 'plantTypes',
  initialState: {
    plantTypes:  [
      {id: 1, name: "Blackberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
      {id: 2, name: "Strawberries", group_id: 1, description: "Disgusting horribly delicious fruit"},
      {id: 3, name: "Butterhead", group_id: 2, description: "The only plant we can grow"}
    ],
    plantTypeGroups: [
      {id: 1, name: "Berries"}, 
      {id: 2, name: "Lettuce"}
    ],
    plantVarieties: [
      {id: 1, name: "Crimson Queen", description: "Disgusting horribly delicious fruit", plant_type_id: 1},
      {id: 2, name: "Bitter Blue", description: "Disgusting horribly delicious fruit", plant_type_id: 2},
      {id: 3, name: "Blah blah yuck", description: "The only plant we can grow", plant_type_id: 3}
    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    

    plantTypeUpdate: (state, action) => {
      let hasChanged = false
      let newPlantTypes = { ...state, plantTypes: state.plantTypes.map((plantType, index) => {
        if (plantType.id !== action.payload.plantTypeId) {
          return plantType
        }
    
        if (plantType[action.payload.prop] === undefined || plantType[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...plantType,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newPlantTypes
      }
    }
  },
  extraReducers: {
    [getAllPlantTypes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllPlantTypes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.plantTypes.map(function(plantType){ processPlantTypeFromAPI(plantType); return plantType });
      console.log(action.payload.plantTypes)
      state.plantTypes = action.payload.plantTypes;
    },

    [getAllPlantTypes.rejected]: (state) => {
      state.status = 'rejected';
    }
  }
})



// Action creators are generated for each case reducer function
export const { plantTypeUpdate} = plantTypesSlice.actions

export default plantTypesSlice.reducer

export const selectAllPlantTypes = state => state.plantTypes.plantTypes
export const selectAllPlantTypeGroups = state => state.plantTypes.plantTypeGroups
export const selectAllPlantVarieties = state => state.plantTypes.plantVarieties

export const selectPlantTypeById = (state, plantTypeId) =>
  state.plantTypes.plantTypes.find(plantType => plantType.id === plantTypeId)