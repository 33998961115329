import './FlowZonePortsManager.scss';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import Walkthrough from '../../../components/Walkthrough';
import FlowZonePortsManager_Overview from './FlowZonePortsManager/FlowZonePortsManager_Overview';
  

const FlowZonePortsManager = ({facilityId, flow, closeCallback}) => {
   

    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["overview", null])

    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }



    const loadWalkthroughStep = (stepKeys, isTransitioning) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "overview":
                return (<FlowZonePortsManager_Overview facilityId={facilityId} flow={flow} transitionToNextStep={transitionToNextStep} completeCallback={closeCallback}/>) 

            default:
                return <></>
        }
    }


    return (<>
        <Walkthrough
            id={"ManageFlowWalkthrough_Popup"}
            title={<>
                <span>Ports Manager</span>
            </>}
            hideMainSeparators={true}
            closeCallback={closeCallback}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["overview", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)

}



export default FlowZonePortsManager