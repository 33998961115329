import './PlantSeedsForGerminationTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import { useDispatch } from 'react-redux';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';

const PlantSeedsForGermination_LocationTrackingOptionsStep = ({
        activeGrowPlan,
        seedingPlantVariantId,
        seedingPlantVariantIndex,
        trackingOptionConfirmedCallback,
        setVariantIndexCallback,
        seedingCompletedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()


    const backClicked = () => {
        transitionToPreviousStep(["seeding", null])
    }

    const nextClicked = () =>  {
        trackingOptionConfirmedCallback({variantId: seedingPlantVariantId, trackingOption: selectedTrackingOption})
        if (selectedTrackingOption === "tracked")   {
            transitionToNextStep(["location_tracking", null])
        }else if (selectedTrackingOption === "pending" || selectedTrackingOption === "skipped") {
            if (seedingPlantVariantIndex < activeGrowPlan.plant_variants.length - 1)  {
                setVariantIndexCallback(seedingPlantVariantIndex + 1)
                transitionToNextStep(["seeding", null])
            }else {
                seedingCompletedCallback()
                transitionToNextStep(["seeding_list", null])
            }
        }
    }

    const [selectedTrackingOption, SetSelectedTrackingOption] = React.useState(null)
    const trackingOptionSelected = (option) => {
        SetSelectedTrackingOption(option)
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Location Tracking
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className="Text-Medium-S24">Track Now</div>
                                <div className="Text-S18 Text-Wrap" style={{textAlign:"left"}}>Track the location of the boards now</div>
                            </div>
                        </div>
                        </>} status={"Primary-Toggle"} state={selectedTrackingOption === "tracked"} width={"Flex100"} onClick={() => {trackingOptionSelected("tracked")}}/>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className="Text-Medium-S24">Track Later</div>
                                <div className="Text-S18 Text-Wrap" style={{textAlign:"left"}}>Track the location of the boards later</div>
                            </div>
                        </div>
                        </>} status={"Primary-Toggle"} state={selectedTrackingOption === "pending"} width={"Flex100"} onClick={() => {trackingOptionSelected("pending")}}/>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className="Text-Medium-S24">Skip Tracking</div>
                                <div className="Text-S18 Text-Wrap" style={{textAlign:"left"}}>Do not track location of boards</div>
                            </div>
                        </div>
                        </>} status={"Primary-Toggle"} state={selectedTrackingOption === "skipped"} width={"Flex100"} onClick={() => {trackingOptionSelected("skipped")}}/>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={selectedTrackingOption === null}/>
            </div>
        </div>
    )
    
}


export default PlantSeedsForGermination_LocationTrackingOptionsStep