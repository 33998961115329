import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual, Blade_GrowOutSprayDownVisual_EasterEgg, RackStandardEmpty } from '../../../../../assets/icons/RackIcons';
import useSwipeVertical from '../../../../../useSwipeVertical';
import DropDownButton from '../../../../../components/DropDownButton';
import DropDownInput from '../../../../../components/input/DropDownInput';

const Blade_CleanseTask_CleanseBathOptionsStep = ({
        task,
        skipBathCallback,
        confirmBathCleanseCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const skipClicked = () => {
        skipBathCallback()
    }

    
    const [selectedBathDuration, SetSelectedBathDuration] = React.useState(6 * 60 * 60) //in seconds
    const selectedBathOptionChanged = (option) =>   {
        SetSelectedBathDuration(option)
    }

    let bathOptions = [
        {value: 1 * 60 * 60, label: "1 Hour"},
        {value: 2 * 60 * 60, label: "2 Hours"},
        {value: 3 * 60 * 60, label: "3 Hours"},
        {value: 4 * 60 * 60, label: "4 Hours"},
        {value: 5 * 60 * 60, label: "5 Hours"},
        {value: 6 * 60 * 60, label: "6 Hours"},
        {value: 8 * 60 * 60, label: "8 Hours"},
        {value: 12 * 60 * 60, label: "12 Hours"},
    ]


    const confirmClicked = () => {
        confirmBathCleanseCallback(selectedBathDuration)
    }
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    Would you like to run a full bath cycle?
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")} style={{gap: isMobile ? 60 : 70}}>
                    <div className="FlexContent-20">
                        <RackStandardEmpty width={291}/>
                    </div>
                    <div className="FlexContent" style={{justifyContent:"center"}}>
                        <div className="FlexContent-40">
                            <div className="FlexContent-5">
                                <div className="Text-S18">Current Cleaner</div>
                                <div className="Text-S16">H2O2</div>
                            </div>
                            <div className="FlexContent-5" style={{alignItems:"flex-start"}}>
                                <div className="Text-S18">Bath Duration</div>
                                <DropDownInput value={selectedBathDuration} options={bathOptions} onSelectionChange={selectedBathOptionChanged}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Skip and Complete"} status={"Neutral"} onClick={skipClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Yes"} onClick={confirmClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_CleanseBathOptionsStep