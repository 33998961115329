import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual } from '../../../../../assets/icons/RackIcons';

const Blade_CleanseTask_FullCleanseStep = ({
        task,
        blade, subTaskInfo,
        stopBathEarlyCallback,
        finishedCleanseCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [requestingStopBathEarly, SetRequestingStopBathEarly] = React.useState(false)
    const requestStopBathEarlyClicked = () => {
        SetRequestingStopBathEarly(true)
    }
    const cancelStopBathEarlyClicked = () => {
        SetRequestingStopBathEarly(false)
    }
    const stopBathEarlyClicked = () => {
        stopBathEarlyCallback()
    }
    const finishedClicked = () => {
        finishedCleanseCallback()
    }
    

    const determineCleanseStage = () => {
        if (blade !== undefined && blade !== null && subTaskInfo !== null)    {
            let cleanStateKey = subTaskInfo["for_reservoir"] + "_reservoir_clean_state"
            let controlStateKey = subTaskInfo["for_reservoir"] + "_reservoir_control_state"
            let subcontrolStateKey = subTaskInfo["for_reservoir"] + "_reservoir_subcontrol_state"
            if (blade.runtime_information_requested !== null && blade.runtime_information_requested[cleanStateKey] !== undefined)   {
                if (blade.runtime_information_requested[cleanStateKey] === "full_cleanse")  {
                    //Happens when the zone is requested for full cleanse but is currently busy doing another operation
                    return "awaiting_cleanse_to_start"
                }
            }
            if (blade.runtime_information !== null) {
                if(blade.runtime_information[cleanStateKey] !== undefined && blade.runtime_information[cleanStateKey + "_step"] !== undefined) {
                    if (blade.runtime_information[cleanStateKey] === "pending_full_cleanse")   {
                        //Happens when the blade is beginning the cleanse
                        return "preparing_for_cleanse"
                    }

                    if (blade.runtime_information[cleanStateKey] === "full_cleaning")   {

                        if (blade.runtime_information[controlStateKey] !== undefined && blade.runtime_information[subcontrolStateKey] !== undefined)    {
                            if (blade.runtime_information[controlStateKey] === "full_clean") {
                                if (blade.runtime_information[subcontrolStateKey] === "setup")  {
                                    //Happens when the cleanse is just starting, and the blade is checking if the reservoir is empty
                                    return "preparing_for_cleanse"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "draining")  {
                                    //Happens when the reservoir is draining in preperation for the cleanse
                                    return "initial_draining_reservoir"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "wait_for_NDS_for_cleanse")  {
                                    //Happens when the zone is prepared for cleanse and is waiting for the NDS to perform it
                                    return "waiting_for_NDS"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "NDS_initialized_and_injecting" || blade.runtime_information[subcontrolStateKey] === "wait_for_NDS_injection")  {
                                    //Happens when the zone is getting injected with cleaning solution and water
                                    return "receiving_cleaning_solution"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "recirculating_through_NDS" || blade.runtime_information[subcontrolStateKey] === "cleansing_agent_dose_completed")   {
                                    //Happens when the zone is mixing
                                    return "mixing_cleaning_solution"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "post_bath_draining")   {
                                    //Happens when the draining after the bath
                                    return "post_bath_draining_reservoir"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "wait_for_NDS_for_flush" || blade.runtime_information[subcontrolStateKey] === "NDS_RO_flush" || blade.runtime_information[subcontrolStateKey] === "NDS_RO_flush_completed")   {
                                    //Happens when the NDS flushes reservoir with RO water
                                    return "post_bath_reservoir_flush"
                                }
                                if (blade.runtime_information[subcontrolStateKey] === "final_drain")   {
                                    //Happens when the reservoir is draining for the last time
                                    return "final_reservoir_drain"
                                }
                            }
                        }
                        if (blade.runtime_information[cleanStateKey + "_step"] === "bath_time")   {
                            //Happens when the cleanse is now in bath time
                            return "bath_time"
                        }
                    }

                    if (blade.runtime_information[cleanStateKey] === "clean")   {
                        //Happens when the blade has completed the cleanse
                        return "cleanse_finished"
                    }
                }

            }
        }

        return null
    }

    
    const cleanseStage = determineCleanseStage()
    


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    Full Cleanse in Progress
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <div className="FlexContent-40">
                            <div className="FlexContent FlexContent-Center Text-S50 Linebreak-20" style={{textAlign:"center"}}>
                                {cleanseStage === "awaiting_cleanse_to_start" && <>
                                    Waiting for blade to begin the cleanse...
                                </>}
                                {cleanseStage === "preparing_for_cleanse" && <>
                                    Preparing for cleanse...
                                </>}
                                {cleanseStage === "initial_draining_reservoir" && <>
                                    Completely draining dirty reservoir...
                                </>}
                                {cleanseStage === "waiting_for_NDS" && <>
                                    Waiting for Flow to begin the cleanse...
                                </>}
                                {cleanseStage === "receiving_cleaning_solution" && <>
                                    Receiving cleaning solution...
                                </>}
                                {cleanseStage === "mixing_cleaning_solution" && <>
                                    Mixing cleaning solution...
                                </>}
                                {cleanseStage === "bath_time" && <>
                                    {(() => {
                                        let timeRemaining = 0
                                        let lastDosedOnKey = subTaskInfo["for_reservoir"] + "_reservoir_last_dosed_on"
                                        let doseDurationKey = subTaskInfo["for_reservoir"] + "_reservoir_bath_duration"
                                        if (blade !== undefined && blade !== null && blade.runtime_information !== null && blade.runtime_information[lastDosedOnKey] !== undefined && blade.runtime_information[doseDurationKey] !== undefined)    {
                                            let bathStartedOn = new Date((parseInt(blade.runtime_information[lastDosedOnKey]) * 1000) + (parseInt(blade.runtime_information[doseDurationKey]) * 1000))
                                            timeRemaining = (bathStartedOn.getTime() - new Date().getTime())
                                            if (timeRemaining < 0)  {
                                                timeRemaining = 0
                                            }
                                        }

                                        var milliseconds = Math.floor((timeRemaining % 1000) / 100),
                                            seconds = Math.floor((timeRemaining / 1000) % 60),
                                            minutes = Math.floor((timeRemaining / (1000 * 60)) % 60),
                                            hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);

                                        hours = (hours < 10) ? "0" + hours : hours;
                                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                                        seconds = (seconds < 10) ? "0" + seconds : seconds;
                                        return (<>
                                        
                                            {hours}:{minutes}:{seconds} remaining
                                        </>)
                                    })()}
                                </>}         
                                {cleanseStage === "post_bath_draining_reservoir" && <>
                                    Bath completed, completely draining reservoir...
                                </>}     
                                {cleanseStage === "post_bath_reservoir_flush" && <>
                                    Flushing reservoir with clean water...
                                </>}   
                                {cleanseStage === "final_reservoir_drain" && <>
                                    Final reservoir drain...
                                </>} 
                                {cleanseStage === "cleanse_finished" && <>
                                    Full cleanse completed.
                                </>}
                                
                            </div>
                            {cleanseStage === "bath_time" && <>
                                {!requestingStopBathEarly && <>
                                    <Button status={"hyperlink"} content={<div style={{color:"#EC3C3C"}} onClick={requestStopBathEarlyClicked}>Stop Bath Early</div>}/>
                                </>}
                                {requestingStopBathEarly && <>
                                    <div className="FlexContent FlexContent-HFlex Text-S18" style={{textAlign:"center"}}>Are you sure you would like to cancel</div>
                                    <div className={isMobile ? "FlexContent-30" : "FlexContent-H-5 FlexContent-HFlex"}>
                                        <Button status={"Hyperlink"} content={"No, resume bath"} onClick={cancelStopBathEarlyClicked} size={"Flex100"}/>
                                        <Button status={"Hyperlink"} content={<div style={{color:"#EC3C3C"}}>Yes, cancel bath</div>} onClick={stopBathEarlyClicked} size={"Flex100"}/>
                                    </div>
                                </>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Primary" content={"Done"} onClick={finishedClicked} size={"Flex100"} disabled={cleanseStage !== "cleanse_finished"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_FullCleanseStep