import './TopUpReservoirWalkthrough.scss';
import React from 'react'; 
import { useSelector } from 'react-redux';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import { selectAllNDS } from '../../../redux/entities/service/NDS';
import ConfirmReservoirRemovalWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirRemovalWalkthroughStep';
import ConfirmCurrentReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmCurrentReservoirVolumeWalkthroughStep';
import ConfirmReservoirReinstallWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirReinstallWalkthroughStep';
import SelectResevoirTopUpTypeWalkthroughStep from './TopUpReservoirWalkthroughSteps/SelectResevoirTopUpTypeWalkthroughStep';
import UpdateCurrentReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/UpdateCurrentReservoirVolumeWalkthroughStep';
import ConfirmReservoirWaterTopUpWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmReservoirWaterTopUpWalkthroughStep';
import TopUpInternalReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/TopUpInternalReservoirVolumeWalkthroughStep';
import ConfirmExternalReservoirWaterVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmExternalReservoirWaterVolumeWalkthroughStep';
import ConfirmExternalReservoirMixWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmExternalReservoirMixWalkthroughStep';
import ConfirmInternalReservoirMixWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmInternalReservoirMixWalkthroughStep';
import ConfirmFinalReservoirVolumeWalkthroughStep from './TopUpReservoirWalkthroughSteps/ConfirmFinalReservoirVolumeWalkthroughStep';


  

const TopUpReservoirWalkthrough = ({facilityId, NDS, reservoirInfo, completeCallback, closeCallback}) => {

    const selectedFacility = useSelector(state => state.facilities.facilities.find((f) => f.id === facilityId))
    

    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)


    const [wasReservoirVolumeInitiallyCorrect, SetWasReservoirVolumeInitiallyCorrect] = React.useState(true)
    const [initialReservoirVolume, SetInitialReservoirVolume] = React.useState(0)

    const [waterBeingMixed, SetWaterBeingMixed] = React.useState(0)

    const [topUpType, SetTopUpType] = React.useState(null)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    
    const currentTheme = "white"
    
    const NDSs = useSelector(selectAllNDS)
    




    


    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["confirm_reservoir_removal", null])
    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }


    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "confirm_reservoir_removal":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "confirm_current_reservoir_volume":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
            
            case "update_current_reservoir_volume":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
            
            case "select_topup_type":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 3) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 4)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 3 : 4)
                }
                break
    
            case "confirm_reservoir_water_topup":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 4) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 5)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 4 : 5)
                }
                break
            
            case "top_up_internal_reservoir":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 5) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 6)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 5 : 6)
                }
                break
            
            case "confirm_external_reservoir_water_volume":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 4) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 5)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 4 : 5)
                }
                break


            case "confirm_internal_mix":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 6) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 7)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 6 : 7)
                }
                break

            case "confirm_external_mix":
                if ((wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 5) || (!wasReservoirVolumeInitiallyCorrect && currentStepIndex !== 6)) {
                    SetCurrentStepIndex(wasReservoirVolumeInitiallyCorrect ? 5 : 6)
                }
                break

            case "confirm_final_volume":
                if (currentStepIndex !== numberOfSteps - 1) {
                    SetCurrentStepIndex(numberOfSteps - 1)
                }
                break

            case "confirm_reservoir_reinstall":
                if (currentStepIndex !== numberOfSteps) {
                    SetCurrentStepIndex(numberOfSteps)
                }
                break
            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true


    
    const reservoirRemovalConfirmed = () => {
        //Mark reservoir as removed and no longer primed

    }
    const currentReservoirVolumeConfirmed = () =>   {
        //Keep track of current reservoir volume for later calculation

    }

    const reservoirInitialVolumeIsIncorrect = () => {
        SetWasReservoirVolumeInitiallyCorrect(false)        
    }
    const reservoirInitialVolumeIsCorrect = () => {
        SetWasReservoirVolumeInitiallyCorrect(true)   
    }
    
    const initialReservoirVolumeConfirmed = (reservoirVolume) => {
        SetInitialReservoirVolume(reservoirVolume)
    }

    const topUpTypeSelected = (topUpType) => {
        SetTopUpType(topUpType)
        if (topUpType === "")   {

        }else {

        }
    }

    React.useEffect(() => {
        let addedSteps = 0
        if (!wasReservoirVolumeInitiallyCorrect)    {
            addedSteps += 1
        }
        if (topUpType === "within_reservoir")    {
            addedSteps += 1
        }
        SetNumberOfSteps(7 + addedSteps)
    }, [wasReservoirVolumeInitiallyCorrect, topUpType])


    const toppedUpInternalReservoirVolumeConfirmed = (waterAdded) => {
        SetWaterBeingMixed(waterAdded)
    }

    const externalReservoirWaterVolumeConfirmed = (waterVolume) => {
        SetWaterBeingMixed(waterVolume)
    }

    const finalReservoirVolumeConfirmed = (finalReservoirVolume) => {
        
    }

    const cancelReservoirTopup = () => {
        //Do nothing
    }

    const attachReservoirLater = () => {
        //Just close out, we aren't reinstalling right now
        closePopup()
    }
    
    const reservoirReinstallConfirmed = () => {
        //Mark reservoir as installed, will automatically prime now
        closePopup()
    }
    
    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "confirm_reservoir_removal":
                return (<ConfirmReservoirRemovalWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} reservoirRemovalConfirmedCallback={reservoirRemovalConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            case "confirm_current_reservoir_volume":
                return (<ConfirmCurrentReservoirVolumeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} initialReservoirVolumeConfirmedCallback={initialReservoirVolumeConfirmed} cancelReservoirTopUpCallback={cancelReservoirTopup} reservoirInitialVolumeIsIncorrectCallback={reservoirInitialVolumeIsIncorrect} reservoirInitialVolumeIsCorrectCallback={reservoirInitialVolumeIsCorrect} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
            
            case "update_current_reservoir_volume":
                return (<UpdateCurrentReservoirVolumeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} initialReservoirVolumeConfirmedCallback={initialReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
    
            case "select_topup_type":
                return (<SelectResevoirTopUpTypeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} wasReservoirVolumeInitiallyCorrect={wasReservoirVolumeInitiallyCorrect} topUpTypeSelectedCallback={topUpTypeSelected} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            case "confirm_reservoir_water_topup":
                return (<ConfirmReservoirWaterTopUpWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

                    
            case "top_up_internal_reservoir":
                return (<TopUpInternalReservoirVolumeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} originalVolume={initialReservoirVolume} toppedUpInternalReservoirVolumeConfirmedCallback={toppedUpInternalReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            case "confirm_internal_mix":
                return (<ConfirmInternalReservoirMixWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} waterBeingMixed={waterBeingMixed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 


            case "confirm_external_reservoir_water_volume":
                return (<ConfirmExternalReservoirWaterVolumeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} externalReservoirWaterVolumeConfirmedCallback={externalReservoirWaterVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 
                        
            case "confirm_external_mix":
                return (<ConfirmExternalReservoirMixWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} waterBeingMixed={waterBeingMixed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

                
            case "confirm_final_volume":
                return (<ConfirmFinalReservoirVolumeWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} topUpType={topUpType} finalReservoirVolumeCallback={finalReservoirVolumeConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

                
            case "confirm_reservoir_reinstall":
                return (<ConfirmReservoirReinstallWalkthroughStep NDS={NDS} reservoirInfo={reservoirInfo} attachReservoirLaterCallback={attachReservoirLater} reservoirReinstallConfirmedCallback={reservoirReinstallConfirmed} transitionToPreviousStep={transitionToPreviousStep} transitionToNextStep={transitionToNextStep} />) 

            default:
                return <></>
        }
    }




    return (<>

        <Walkthrough
            id={"TopUpReservoirWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Reservoir {reservoirInfo["index"]} Top Up</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["confirm_reservoir_removal", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)
}



export default TopUpReservoirWalkthrough