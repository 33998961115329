import './TasksPage.scss';
import React from 'react';

import StandardTable from '../../components/StandardTable.js'
import GanttChart from '../../components/GanttChart.js'
import Badge from '../../components/Badge.js'
 
import { useMeasureWithRef } from '../../helpers'


import { useSelector, useDispatch } from 'react-redux'
import { selectAllTasks, getAllTasks, setTaskLayoutBounds, taskUpdate} from '../../redux/entities/Tasks'
import StandardList from '../../components/StandardList/StandardList';

const TasksTimelinePage = ({setPageHeader}) => {
  React.useLayoutEffect( () =>  { 
    //fetchTasks
    setPageHeader()
  }, [])


  const dispatch = useDispatch()
  let tasks = useSelector(selectAllTasks)
  let haveInitialData = useSelector((state) => state.tasks.haveInitialData)
  React.useEffect( () =>  { 
    //fetchTasks
    if (!haveInitialData) {
      dispatch(getAllTasks())
    }
  }, [])

  const handleTaskUpdate = (task, prop, value) => {
    dispatch(taskUpdate({taskId: task.id, prop: prop, value: value}))
  }

  //const taskRepoDimensions = React.useRef(null)
  const [taskRepoDimensions, SetTaskRepoDimensions] = React.useState({})
  const handleTaskRepoDimensions = React.useCallback((ref) => {
    SetTaskRepoDimensions({...ref.current})
  }, [])
  const taskRepoRowDimensions = React.useRef({})

  const [tasksList, SetTasksList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  
  
  
  React.useEffect(() => {
    let currentTasksList = {}

    let currentTasks = []
    

    if (currentSearchInput === "")  {
      currentTasks = [...tasks]
    }else {
      currentTasks.push(...tasks.filter((task) => {
        if (task.name.indexOf(currentSearchInput) !== -1 && currentTasks.indexOf(task) === -1)  {
          return task
        }
      }))
    }
    
    
    for (let task of currentTasks) {
      let durationDisplay = ""
      console.log(task)
      if (task.duration === 0)  {
        
      }else {
        const hourDuration = 60 * 60
        const dayDuration = hourDuration * 8

        const totalDays = Math.floor(task.duration / dayDuration)
        const remainingHours = (task.duration - (totalDays * dayDuration)) / hourDuration

        
        if (totalDays !== 0) {
          durationDisplay += totalDays.toString() + "d"
          if (remainingHours !== 0)  {
            durationDisplay += " " + remainingHours.toString() + "h"
          }
        }else {
          durationDisplay += remainingHours.toString() + "h"
        }
      }

      let statusMode = "Neutral";

      
      currentTasksList[task.id] = {
        data: task,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{task.name}</div>
            </div>),
          },
          duration: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{durationDisplay}</div>
            </div>),
          },
          status: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary"><Badge status={statusMode} content={task.status} size="Medium"/></div>
            </div>),
          },
          assigned_to: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary"></div>
            </div>),
          }
        }
      }
    }

    SetTasksList(currentTasksList)
  }, [tasks, currentSearchInput])

  

  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 15, resizable: true, sort_by: false},
    duration: {render: (<div className="StandardList-Header-Column">Duration</div>), width: 10, resizable: true, sort_by: false},
    status: {render: (<div className="StandardList-Header-Column">Status</div>), width: 12, resizable: true, sort_by: false},
    assignedTo: {render: (<div className="StandardList-Header-Column">Assignee</div>), width: 12, resizable: true, sort_by: false},
  }


  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const AddTaskButton = ({rowGroup}) =>   {

    return (
      <div>
        Add Task
      </div>
    )
  }


    for (let [taskId, task] of Object.entries(tasksList)) {
      task.startDate = new Date(task.data.scheduled_for).getTime()
      task.duration = task.data.duration
      task.name = task.data.name
      task.id = task.data.id
      if (taskRepoRowDimensions.current[taskId] !== undefined)  {
        task.bounds = taskRepoRowDimensions.current[taskId]
      }
    }
    

  
  return (
    <div id="TasksTimelinePage">
        <StandardList 
          showHeader={true}
          columns={columns} 
          rows={tasksList}
          columnSpacing={8}
          columnPadding={0}
          rowPadding={4}
          onSearchInput={onSearchInput}
          getDimensions={handleTaskRepoDimensions}
          getRowDimensions={(rowKey, ref) => taskRepoRowDimensions.current[rowKey] = ref.current}/>
        
        <div id="TasksTimelineContent">
          <GanttChart 
            id="TasksTimelineGanttChart" 
            axisHeight={taskRepoDimensions ? taskRepoDimensions.headerHeight : 0} 
            tableHeight={taskRepoDimensions ? taskRepoDimensions.contentHeight : 0}
            listScrollPosition={taskRepoDimensions ? taskRepoDimensions.contentScrollY : 0}
            data={tasksList}
            dataBounds={taskRepoRowDimensions.current}
            barMargin={40}
            dataStartDateKey="startDate"
            updateRow={handleTaskUpdate}/>
        </div>
    </div>
  )
}   

export default TasksTimelinePage