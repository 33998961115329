import './TransplantToNurseryFromGerminationTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'

import { getGrowById, getGrowGerminationBoardLocations } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import { getRecipeById, selectAllRecipes } from '../../../../redux/entities/Recipes'
import PopupModal from '../../../../model_components/PopupModal';
import { useMediaQuery } from 'react-responsive';
import { getRaftTypeById, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes.js';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities.js';
import { getGerminationChamberByServiceId, selectAllGerminationChamber } from '../../../../redux/entities/service/GerminationChamber.js';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, selectAllBladeZones } from '../../../../redux/entities/service/Blade.js';
import { selectAllRecipeSetpointTypes } from '../../../../redux/AppInfo.js';
  

const TransplantToNurseryFromGerminationTaskReview = ({task, onClose}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onClose()
  }


  const allFacilities = useSelector(selectAllFacilities)
  const allGerminationChambers = useSelector(selectAllGerminationChamber)
  const allRecipes = useSelector(selectAllRecipes)


  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }else {
        let germinationChambersToLoad = []
        for (let service of foundFacility.services.filter((s) => s.service_type === "germination_chamber")) {
          let foundGerminationChamberService = allGerminationChambers.find((gC) => gC.service_id === service.id)
          if (foundGerminationChamberService === undefined)  {
            germinationChambersToLoad.push(service.id)
          }
        }
        if (germinationChambersToLoad.length > 0) {
          dispatch(getGerminationChamberByServiceId({GerminationChamberServiceIds: germinationChambersToLoad}))
        }
      }

    }
  }, [allFacilities, allGerminationChambers])



  const allBladeZones = useSelector(selectAllBladeZones)
  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === task.facility_id)
    if (foundFacility !== undefined)  {
      if (!loadedInitialBladeGroups && loadingGroupsStatus !== "pending") {
        dispatch(getAllBladeGroupsByFacilityId({facilityIds: [task.facility_id]}))
      }
    }
  }, [loadingGroupsStatus, loadedInitialBladeGroups, allFacilities])


  React.useEffect(() => {

    let bladeZonesToLoad = []
    for (let group of allBladeGroups) {
      if (group.facility_id === task.facility_id) {
        for (let zoneUID of group.zones)  {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneUID)
          if (foundBladeZone === undefined) {
            bladeZonesToLoad.push(zoneUID)
          }
        }
      }
    }
    
    if (bladeZonesToLoad.length > 0) {
      dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
    }

  }, [allBladeZones, allBladeGroups])



  React.useEffect(() => {
    let bladeZoneRecipesToLoad = []
    for (let group of allBladeGroups) {
      if (group.facility_id === task.facility_id) {
        for (let zoneUID of group.zones)  {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneUID)
          if (foundBladeZone !== undefined) {
            if (foundBladeZone.active_zone_recipe !== null) {
              let foundRecipe = allRecipes.find((r) => r.id === foundBladeZone.active_zone_recipe.recipe_id && r.version === foundBladeZone.active_zone_recipe.recipe_version)
              if (foundRecipe === undefined)  {
                bladeZoneRecipesToLoad.push({
                  id: foundBladeZone.active_zone_recipe.recipe_id,
                  version: foundBladeZone.active_zone_recipe.recipe_version
              })
              }
            }
          }
        }
      }
    }
    if (bladeZoneRecipesToLoad.length > 0)  {
      dispatch(getRecipeById({recipes: bladeZoneRecipesToLoad}))
    }
  }, [allBladeZones, allRecipes])

  


  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])



  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow !== undefined)  {
      if (!foundGrow.loaded_germination_locations && !foundGrow.loading_germination_locations) {
        console.log("YEA")
        dispatch(getGrowGerminationBoardLocations({growId: foundGrow.id}))
      }else {
        console.log(foundGrow)
      }
    }
  }, [allGrows])





  let allGrowPlans = useSelector((state) => state.growPlans.growPlans)
  const activeGrowPlan =  activeGrow !== undefined ? allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
      if (foundGrowPlan === undefined)  {
        dispatch(getGrowPlanById({growPlanId: activeGrow.grow_plan_id}))
      }
    }
  }, [activeGrow, allGrowPlans])
  
  
  const activeRecipe =  activeGrow !== undefined ? allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundRecipe = allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipes: [{id: activeGrow.recipe_id, version: activeGrow.recipe_version}]}))
      }
    }
  }, [activeGrow, allGrowPlans])
  



  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))

  const activeSeedlingRaftType =  activeGrowPlan !== undefined ? allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id) : undefined
  React.useEffect(() => {
    if (activeGrowPlan !== undefined) {
      let foundRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.seedling_raft_type_id)
      if (foundRaftType === undefined)  {
        dispatch(getRaftTypeById({raftTypeId: activeGrowPlan.seedling_raft_type_id}))
      }
    }
  }, [activeGrowPlan, allRaftTypes])


  const allRecipeSetpointTypes = useSelector((state) => selectAllRecipeSetpointTypes(state))


  const [activeNurseryLocations, SetActiveNurseryLocations] = React.useState({})
  const activeNurseryLocationsChanged = ({locations}) => {
    SetActiveNurseryLocations(locations)
  }



  return (<>
    <PopupModal 
      title="Move Crops"
      subHeaderContent={<div className="Text-AltSubHeader">#G{activeGrow !== undefined ? activeGrow.id : ""}</div>}
      size="full"
      closeCallback={closeTask}
      hideMainSeparators={false}
    >

      Test      
    </PopupModal>
  </>)
} 

export default TransplantToNurseryFromGerminationTaskReview