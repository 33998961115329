
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { Plus, SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import NumberInput from '../../../../components/input/NumberInput';
import { getInventoryItemTypeGroupByPath, getInventoryItemTypesByGroupId, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypes, selectInventoryItemTypeGroupByPath } from '../../../../redux/entities/Inventory';
  

const CreateGrowPlan_SelectFinalProductWalkthroughStep = ({
        initialSelectedProduct,
        finalProductConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

   
    const [selectedProduct, SetSelectedProduct] = React.useState(null)
    const [searchingProductName, SetSearchingProductName] = React.useState("")
    const productsSearched = (name) => {
        SetSearchingProductName(name)
    }

    React.useLayoutEffect(() => {
        if (initialSelectedProduct !== undefined)  {
            SetSelectedProduct(initialSelectedProduct)
        }
    }, [initialSelectedProduct])
    const productUpdated = (product) => {
        SetSelectedProduct(product)
    }

    const backClicked = () => {
        transitionToPreviousStep(["select_grow_plan_name", null])
    }
    const nextClicked = () =>   {
        finalProductConfirmedCallback({
            product: selectedProduct
        })
        transitionToNextStep(["final_review", null])
    }
 



    const [inventoryItemGroupPaths, SetInventoryItemGroupPaths] = React.useState([
        ["Products", "Produce"],
    ])
    let allInventoryItemTypes = useSelector(selectAllInventoryItemTypes)
    let allInventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)

    const [activeItemTypeGroups, SetActiveItemTypeGroups] = React.useState([])
    const dispatch = useDispatch()
    React.useEffect(() => {
        let itemTypeGroups = []
        for (let groupPath of inventoryItemGroupPaths)  {
            let group = selectInventoryItemTypeGroupByPath(allInventoryItemTypeGroups, groupPath)
            if (group === null)  {
                dispatch(getInventoryItemTypeGroupByPath({itemTypeGroupPath: groupPath}))
            }else   {
                itemTypeGroups.push(group)
            } 
        }

        SetActiveItemTypeGroups(itemTypeGroups)
    }, [allInventoryItemTypeGroups])

    
  React.useEffect(() => {
    let itemTypesByGroupsToLoad = []
    for (let group of activeItemTypeGroups) {
      if (!group.loadedItemTypes && !group.loadingItemTypes) {
        itemTypesByGroupsToLoad.push(group.id)
      } 
    }
    if (itemTypesByGroupsToLoad.length > 0) {
      //We have the two groups filtered, lets get all inventory items for these 2 group ids
      dispatch(getInventoryItemTypesByGroupId({itemTypeGroupIds: itemTypesByGroupsToLoad}))
    }
  }, [activeItemTypeGroups])
  


    const [currentInventoryItemTypeOptions, SetCurrentInventoryItemTypeOptions] = React.useState([])
    React.useEffect(() => {
        let currentInventoryItemTypesList = []

        let allAvailableItemTypes = [...allInventoryItemTypes]
        let activeItemTypes = []
        if (searchingProductName === "")    {
            activeItemTypes = allAvailableItemTypes
        }else {
            activeItemTypes.push(...allAvailableItemTypes.filter((itemType) => {
                if (itemType.name.toLowerCase().indexOf(searchingProductName.toLowerCase()) !== -1 && activeItemTypes.indexOf(itemType) === -1)  {
                    return itemType
                }
            }))
        }
        for (let itemType of activeItemTypes)  {
            currentInventoryItemTypesList.push({
                value: itemType.id,
                label: itemType.name,
                icon: <></>
            })
        }
        console.log(currentInventoryItemTypesList)
        SetCurrentInventoryItemTypeOptions(currentInventoryItemTypesList)
    }, [allInventoryItemTypes, selectedProduct, searchingProductName])

    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "FlexContent-20" : "FlexContent-30"} style={{padding: isMobile ? "40px 20px" : "40px 40px"}}>
                        <div className="FlexContent-10">
                            <div className="Text-S14">Produced Item</div>
                            <div>
                                <DropDownInput     
                                    hasIcon={true}
                                    options={currentInventoryItemTypeOptions}
                                    finalOption={<>
                                        <div className="FlexContent-H-10 FlexContent-VCenter Linebreak-Top-10" style={{width: "100%"}}>
                                            <Plus/>
                                            <div className="Text-Hyperlink">Add New Item</div>
                                        </div>
                                    </>}
                                    canSearch={true}
                                    displayWidth={"30ch"}
                                    placeholder={"Select Item"}
                                    onSelectionChange={productUpdated}
                                    onSearch={productsSearched}
                                />
                            </div>
                        </div>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedProduct === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectFinalProductWalkthroughStep
