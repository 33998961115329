import './ScheduleGrowPopup.scss';

import React from 'react';
import Button from '../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { FormatDate, RoundToNearest, RoundedDateToNearestLastDay } from '../../../helpers';
import Checkbox from '../../../components/input/Checkbox';
import NumberInput from '../../../components/input/NumberInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllGrowPlans } from '../../../redux/entities/GrowPlans';
import { SimpleGrowBag, StandardBerryTroughZone, StandardGrowOutBoard, StandardGrowOutZone, StandardSeedlingBoard } from '../../../assets/icons/ProductIcons';
import { selectAllRecipes } from '../../../redux/entities/Recipes';
import { selectAllRaftTypes } from '../../../redux/entities/RaftTypes';
import { GenericLeaf } from '../../../assets/icons/Icons';
import Calendar from '../../../components/input/Calendar';
import { scheduleNewGrow } from '../../../redux/entities/Grow';
import { GetActiveAccountId } from '../../Account/Common';

  

const ScheduleGrow_ReviewNewGrowWalkthroughStep = ({
        selectedFacility,
        selectedGrowPlan, 
        selectedBladeTypeId,
        selectedNumberOfUnits,
        selectedTimingType,
        selectedCalendarDate,
        createGrowConfirmedCallback,
        transitionToPreviousStep, completeWalkthrough}) => {

    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const allGrowPlans = useSelector((state) => selectAllGrowPlans(state))
    const activeGrowPlan = allGrowPlans.find((gP) => gP.id === selectedGrowPlan)
    const allRecipes = useSelector((state) => selectAllRecipes(state))
    const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))

    let selectedRecipe = undefined
    if (activeGrowPlan !== undefined)   {
        selectedRecipe = allRecipes.find((r) => r.id === activeGrowPlan.recipe_id && r.version === activeGrowPlan.recipe_version)
    }

    
    let selectedSeedlingRaftTypes = []
    let selectedGrowOutRaftType = undefined
    if (activeGrowPlan !== undefined)   {
        for (let variant of activeGrowPlan.plant_variants)  {
            let foundRaftType = allRaftTypes.find((rT) => rT.id === variant.seedling_raft_type_id)
            if (foundRaftType !== undefined)    {
                selectedSeedlingRaftTypes.push(foundRaftType)
            }
        }
        selectedGrowOutRaftType = allRaftTypes.find((rT) => rT.id === activeGrowPlan.grow_out_raft_type_id)
    }



    const allBladeTypes = [
        {
            id: 1,
            display_name: "Blade Gen S",
            props: {
                "number_of_nursery_troughs": 7,
                "nursery_trough_length": 144, //in inches
                "number_of_grow_out_board_columns": 6,
                "number_of_grow_out_board_rows": 6,
                "number_of_grow_out_berry_troughs": 6,
                "grow_out_berry_trough_length": 144,       
            }
        }
    ]
    const selectedBladeType = allBladeTypes.find((bT) => bT.id === selectedBladeTypeId)
    const bladeProps = selectedBladeType !== undefined ? selectedBladeType.props : {}



    const calculateZonesFromUnits = (units) => {
        if (selectedRecipe !== undefined && selectedSeedlingRaftTypes.length > 0 && selectedGrowOutRaftType !== undefined)   {
            //Based on recipe type
            switch (selectedRecipe.grow_out_type) {
                case "grow_boards":
                    //Make sure we have the grow out board information for the selected blade type
                    if (bladeProps["number_of_grow_out_board_columns"] !== undefined && bladeProps["number_of_grow_out_board_rows"] !== undefined)   {
                        let numberOfGrowBoardsPerZone = parseFloat(bladeProps["number_of_grow_out_board_columns"]) * parseFloat(bladeProps["number_of_grow_out_board_rows"])
                        return units / (parseFloat(activeGrowPlan.grow_out_raft_usage) * numberOfGrowBoardsPerZone)
                    }else {
                        return 0
                    }
                    break
                case "berry_troughs":
                    if (bladeProps["number_of_grow_out_berry_troughs"] !== undefined && bladeProps["grow_out_berry_trough_length"] !== undefined && selectedGrowOutRaftType.info["length"] !== undefined)   {
                        let numberOfGrowBagsPerTrough = Math.floor(parseFloat(bladeProps["grow_out_berry_trough_length"]) / parseFloat(selectedGrowOutRaftType.info.length))
                        let numberOfUnitsPerTrough = parseFloat(activeGrowPlan.grow_out_raft_usage) * numberOfGrowBagsPerTrough
                        let numberOfUnitsForZone = parseFloat(bladeProps["number_of_grow_out_berry_troughs"]) * numberOfUnitsPerTrough
                        return units / numberOfUnitsForZone
                    }else {
                        return 0
                    }
                default:
                    return 0
            }
        }else {
            return 0
        }
    }




    let currentDateRange = null
    if (selectedCalendarDate !== null && selectedRecipe !== undefined)  {
        if (selectedTimingType === "start_date")    {
            let currentDay = RoundedDateToNearestLastDay(1, selectedCalendarDate)
            let finalDay = new Date(currentDay.getTime() + selectedRecipe.expected_duration * 1000)
            currentDateRange = {start: currentDay, end: finalDay}
        }else if (selectedTimingType === "harvest_on")  {
            let currentDay = RoundedDateToNearestLastDay(1, selectedCalendarDate)
            let firstDay = new Date(currentDay.getTime() - selectedRecipe.expected_duration * 1000)
            currentDateRange = {start: firstDay, end: currentDay}
        }
    }



    

    const backClicked = () => {
        transitionToPreviousStep(["select_grow_timing", null])
    }
    
    const [isCreatingGrow, SetIsCreatingGrow] = React.useState(false)
    const dispatch = useDispatch()
    const activeAccountId = GetActiveAccountId()
    const finishedClicked = () =>   {
        SetIsCreatingGrow(true)

        let newGrow = {
            id: -1, 
            facility_id: selectedFacility,
            scheduled_by_account_id: activeAccountId,
            grow_plan_id: activeGrowPlan.id,
            recipe_id: activeGrowPlan.recipe_id,
            recipe_version: activeGrowPlan.recipe_version,
            grow_out_blade_type_id: selectedBladeTypeId,
            number_of_units: selectedNumberOfUnits,
            stage: "pending",
            scheduled_type: selectedTimingType,
            scheduled_for: selectedCalendarDate.toISOString(),
        }
        
        dispatch(scheduleNewGrow({grow: newGrow, callback: (success) => {
            SetIsCreatingGrow(false)
            if (success)    {
                completeWalkthrough()
            }else {

            }
        }}))
    }


    let numberOfZones = calculateZonesFromUnits(selectedNumberOfUnits)
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-15 FlexContent-HFlex">
                        <div className="Text-HeaderMedium">Summary</div>
                        <div style={{height:1, width:"100%", backgroundColor: "#DDE3EB"}}></div>
                        <div className={isMobile ? "FlexContent-30" : "FlexContent-H-50"}>
                            <div className={"FlexContent-30" + (isMobile ? "" : " FlexContent-HFlex")}>
                                <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                    <div>Grow Plan</div>
                                    <div>{activeGrowPlan.name}</div>
                                </div>
                                <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                    <div>Blade Type</div>
                                    <div>{selectedBladeType !== undefined ? selectedBladeType.display_name : " "}</div>
                                </div>
                                <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                    <div>Plant Sites</div>
                                    <div className="ScheduleGrowPopup-Review-DetailsItem">
                                        <div>{numberOfZones} Zone{numberOfZones > 1 && <>s</>}</div>
                                        <div className="ScheduleGrowPopup-Review-DetailsItem-AuxInfo">
                                            {selectedNumberOfUnits} Plant Site{selectedNumberOfUnits > 1 && <>s</>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"FlexContent-30" + (isMobile ? "" : " FlexContent-HFlex")}>
                                {selectedTimingType !== "next_available" && <>
                                    <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                        <div>Start Date</div>
                                        <div>{currentDateRange && FormatDate(currentDateRange["start"], "MMMM dth yyyy")}</div>
                                    </div>
                                    <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                        <div>Harvest Date</div>
                                        <div>{currentDateRange && FormatDate(currentDateRange["end"], "MMMM dth yyyy")}</div>
                                    </div>
                                </>}
                                {selectedTimingType === "next_available" && <>
                                    <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                        <div>Start Date</div>
                                        <div>Next Available</div>
                                    </div>
                                </>}
                                <div>
                                    <div className="FlexContent-5 ScheduleGrowPopup-Review-DetailsItem">
                                        <div>Seed Type</div>
                                        <div>{selectedRecipe ? selectedRecipe.starter_product_id : ""}</div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Finish"} onClick={finishedClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={isCreatingGrow}/>
            </div>
        </div>
    )
    
}


export default ScheduleGrow_ReviewNewGrowWalkthroughStep