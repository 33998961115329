import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/GrowSeeds';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { AdditionalOptions, GenericLeaf } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownButton from '../../../../components/DropDownButton';
import NumberInput from '../../../../components/input/NumberInput';


const HarvestTask_ContainerListWalkthroughStep = ({
        allPlantVarieties,
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerOption,
        containerContents,
        containerWeight,
        containers,
        containersListConfirmedCallback,
        resetForNewContainerCallback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const addContainerClicked = () => {
        resetForNewContainerCallback()
        transitionToNextStep(["container_options", null])
    }
    const numberOfContainersChanged = (index, numberOf) => {
        let updatedContainers = [...containers]
        updatedContainers[index].number_of = numberOf
        containersListConfirmedCallback({containers: updatedContainers})
    }
    const completeClicked = () => {
        transitionToNextStep(["clean_options", null])   
    }

    let totalWeight = 0
    for (let container of containers)   {
        if (container.option === "known_weight")    {
            totalWeight += (container.weight * container.number_of)
        }else {
            totalWeight += container.weight
        }
    }

    

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Harvest Containers
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className="FlexContent-10" style={{flex:1, overflowY: "hidden"}}>
                    <div className={isMobile ? "FlexContent-5" : "FlexContent-10"} style={{flex: 1, alignItems: "flex-start", overflowY: "hidden"}}>
                        <div className={isMobile ? "FlexContent-5" : "FlexContent-10"} style={{alignSelf: "stretch", overflowY: "auto"}}>
                            {containers.map((container, containerIndex) => {
                                let contentsName = ""
                                if (container.contents === "mix")   {
                                    contentsName = "Mix"
                                }else {
                                    let foundVariety = allPlantVarieties.find((pV) => pV.id === container.contents)
                                    contentsName = foundVariety.name
                                }

                                let dropDownOptions = []
                                if (containers.length > 1)  {
                                    dropDownOptions.push({key: "delete", label: "Remove"})
                                }
                                const dropDownOptionClicked = (option) => {
                                    switch (option.key) {
                                        case "delete":
                                            let updatedContainers = [...containers]
                                            updatedContainers.splice(containerIndex, 1)
                                            containersListConfirmedCallback({containers: updatedContainers})
                                            break
                                        default:
                                            break
                                    }
                                }
                                return <>
                                    <div className={(isMobile ? "FlexContent-10" : "FlexContent-H-30") + " HarvestTask-ContainerList-Item"}>
                                        <div className="FlexContent-10" style={{flex: 1, alignSelf: "stretch"}}>
                                            <div className={"FlexContent" + (container.contents === "mix" ? " Linebreak-10" : "")} style={{flex: 1}}>
                                                <div className="FlexContent-H" style={{justifyContent:"space-between"}}>
                                                    <div className="Text-S14">Bucket {containerIndex + 1} - {contentsName}</div>
                                                    <div className="FlexContent-H-20">
                                                        <div className="Text-S14">{container.weight}kg</div>
                                                        <DropDownButton content={<AdditionalOptions/>} status={"Neutral"} options={dropDownOptions} onOptionSelected={dropDownOptionClicked}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {container.contents === "mix" && <>
                                                <div className="FlexContent-10">
                                                    {activeGrowPlan.plant_variants.map((plantVariant, plantVariantIndex) => {
                                                        let foundPlantVariety = allPlantVarieties.find((pV) => pV.id === plantVariant.plant_variety_id)
                                                        return <>
                                                            <div className="FlexContent-H" style={{justifyContent:"space-between"}}>
                                                                <div className="FlexContent-H-10">
                                                                    <div className="Text-S12">{plantVariant.ratio}%</div>
                                                                    <div className="Text-S12">{foundPlantVariety !== undefined ? foundPlantVariety.name : ("Plant Variant " + plantVariantIndex.toString())}</div>
                                                                </div>
                                                                <div className="Text-S14">{container.weight * (plantVariant.ratio / 100)}kg</div>
                                                            </div>
                                                        </>
                                                    })}
                                                </div>
                                            </>}
                                        </div>
                                        {container.option === "known_weight" && <>
                                            <div className={"FlexContent-10" + (container.option === "known_weight" ? (isMobile ? " Linebreak-Top-10" : " Linebreak-L-20") : "")} style={{alignSelf: "stretch", justifyContent: "flex-start"}}>
                                                <div className="Text-S14">Number of containers</div>
                                                <NumberInput value={container.number_of} onChange={(value) => {
                                                    if (parseInt(value) < 1) {
                                                        return 1
                                                    }
                                                }} onBlur={(value) => {numberOfContainersChanged(containerIndex, value)}}
                                                flex={true}/>
                                            </div>
                                        </>}
                                    </div>
                                </>
                            })}
                        </div>
                        <Button status={"Hyperlink"} content={"Add a different container"} onClick={addContainerClicked}/>
                    </div>
                    <div className="FlexContent-H" style={{justifyContent:"space-between"}}>
                        <div className="Text-Medium-S24">Total Harvest</div>
                        <div className="Text-Medium-S24">Total: {totalWeight}kg</div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Primary" content={"Complete Harvest"} onClick={completeClicked} size={"Flex100"}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ContainerListWalkthroughStep