import './../CreateControlDeviceWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { DeviceTransmitting, GoodCheckmark, WiFiFull } from '../../../../assets/icons/Icons';
import Switch from '../../../../components/Switch';
import DropDownInput from '../../../../components/input/DropDownInput';
import { linkBladeToControlDevice, validateBladeSystemConnection } from '../../../../redux/entities/service/Blade';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../components/input/Checkbox';
import { ConnectToBladeBluetoothSlave, SendBladeUIDToBladeBluetoothSlave, SendWiFiCredentialsToBladeBluetoothSlave } from '../../ControlDevices/BluetoothManager';


  

const BluetoothPairToControlBoardWalkthroughStep = ({controlDevice, WiFiSSID, WiFiPassword, connectionStep, transitionToPreviousStep, transitionToNextStep, wiFiConfirmedSentCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()


    const [controlDeviceBluetoothService, SetControlBoardBluetoothService] = React.useState(null)

    const attemptBluetoothPair = async () => {
        const [bluetoothService, successful, err] = await ConnectToBladeBluetoothSlave(controlDevice.serial_number) 
        if (successful) {
            SetControlBoardBluetoothService(bluetoothService)
        }
        return successful
    }

    const attemptSendWiFiToControlDevice = async () => {
        const [successful, err] = await SendWiFiCredentialsToBladeBluetoothSlave(controlDeviceBluetoothService, WiFiSSID, WiFiPassword) 
        if (successful) {
            transitionToNextStep(["bluetooth_pairing", "wifi_credentials_send_success"])            
        }else {
            transitionToNextStep(["bluetooth_pairing", "wifi_credentials_send_failed"])            
        }
    }

    const backClicked = () => {
        transitionToPreviousStep(["wifi_credentials", null])
    }

    switch (connectionStep) {

        case "setup":

            const beingPairingClicked = async () => {
                transitionToNextStep(["bluetooth_pairing", "pairing"])
                const successful = await attemptBluetoothPair()
                if (!successful)    {
                    transitionToNextStep(["bluetooth_pairing", "pairing_failed"])
                }else {
                    transitionToNextStep(["bluetooth_pairing", "pairing_successful"])
                }
            }

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <div className="FlexContent-50">
                            <div className="FlexContent-30 FlexContent-Center">
                                <div className="PopupModal-StandardHeader">Begin Pairing to {controlDevice.serial_number}</div>
                            </div>

                            <div className="FlexContent-30">
                                <Button content={"Back"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={backClicked} width={"Flex100"}/>
                                <Button content={"Begin Pairing"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={beingPairingClicked} width={"Flex100"}/>
                            </div>
                        </div>
                    </div>
                </div>
            )

        case "pairing":

            

            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pairing Bluetooth</div>
                    </div>
                </div>
            )
        
        case "pairing_failed":
            const tryBluetoothConnectingAgainClicked = () => {
                transitionToNextStep(["bluetooth_pairing", "pairing"])
            }

            return (

                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionFailed/>
                        <div className="PopupModal-StandardHeader">Pairing Failed</div>
                        <Button content={"Retry"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryBluetoothConnectingAgainClicked} width={"Flex100"}/>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
                
            )

        case "pairing_successful":
            const linkToBladeClicked = async () => {
                transitionToNextStep(["bluetooth_pairing", "sending_wifi_credentials"])
                await attemptSendWiFiToControlDevice()
                
                
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="PopupModal-StandardHeader" style={{textAlign:"center"}}>Bluetooth Paired</div>
                        <Button content={"Link To Blade"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={linkToBladeClicked} width={"Flex100"}/>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
            )

        case "sending_wifi_credentials":
            return (
                <div className="Walkthrough-Card Walkthrough-Card-VerticalCenter">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pushing WiFi Credentials</div>

                    </div>
                </div>
            )

        case "wifi_credentials_send_success":
            const doneClicked = () => {
                wiFiConfirmedSentCallback()
            }
            return (
                
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="PopupModal-StandardHeader">WiFi Credentials Succesfully Sent</div>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        <Button content={"Done"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={doneClicked} width={"Flex100"}/>
                    </div>
                </div>
            )

        case "wifi_credentials_send_failed":
            const tryDifferentMethodClicked = () => {
                transitionToPreviousStep(["connecting_to_blade", "connection_options"])
            }
            return (
                

                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Header">
                        <div className="Walkthrough-Card-HeaderContent">
                            
                        </div>
                    </div>
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="PopupModal-StandardHeader">WiFi Credentials Failed To Send</div>
                        <Button content={"Try Different Method"} status={"Netral"} size={isMobile ? "Medium" : "Large"} onClick={tryDifferentMethodClicked} width={"Flex100"}/>                        
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                    </div>
                </div>
            )

    


            
        /*case "sending_wifi_credentials":
            return (
                <div className="Walkthrough-Card Walkthrough-Card-VerticalCenter">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Pushing WiFi Credentials</div>

                    </div>
                </div>
            )

            case "wifi_credentials_sent":
                return (
                    
    
                    <div className="Walkthrough-Card">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                                
                            </div>
                        </div>
                        <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                            <RackConnectionSuccess/>
                            <div className="PopupModal-StandardHeader">WiFi Credentials Sent</div>
    
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                        </div>
                    </div>
                )
    

                case "blade_connected":
                    const setupRackClicked = () =>  {
                        rackConnectedCallback()
                        transitionToNextStep(["select_blade_group", "blade_group_listing"])
                    }
                    return (
                        <div className="Walkthrough-Card">
                            <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                                <RackConnectionSuccess/>
                                <div className="FlexContent-40">
                                    <div className="Walkthrough-StandardHeader Walkthrough-StandardHeader-Center">Blade Connected Successfully</div>
                                    {selectedConnectionOption === "provide_credentials" && 
                                        <div className="FlexContent-20">
                                            <div className="FlexContent FlexContent-Center">
                                                <Checkbox
                                                    style={"check"}
                                                    label="Save WiFi Credentials for future use"
                                                    state={saveWiFiCredentialsState}
                                                    onChange={saveWiFiCredentialsStateChanged}/>
                                            </div>
                                            <TextInput value={wiFiCredentialsName} onBlur={wiFiCredentialsNameChanged} placeHolder={"Enter WiFi Name"} disabled={!saveWiFiCredentialsState}/>
                                        </div>
                                    }
                                </div>                    
                            </div>
                            <div className="Walkthrough-Card-FooterButtons">
                                <Button content="Setup Rack Information" size={isMobile ? "Medium" : "Large"}  status="Primary" onClick={setupRackClicked} width={"Flex100"}/>
                            </div>
                        </div>
                    
                    )*/
                    

        default:
            return (<></>)
    }
}

  


export default BluetoothPairToControlBoardWalkthroughStep