import { useDispatch, useSelector } from "react-redux"
import { setupInitialSession } from "../../redux/entities/Account";
import React from "react";


export const CheckForActiveSession = () => {
    //Check for existance of session in local storage
   
    //localStorage.setItem('account_logged_in', JSON.stringify(true));
}

export const IsSessionActive = (state) => {
    let loggedInState = state.account.loggedInState
    return loggedInState
}



export const getAccountSessionData = (state) => {
    const accountId = state.account.id
    const accountSessionToken = state.account.sessionToken
    return {account_id: accountId, session_token: accountSessionToken}
}



export const GetSessionToken = () => {
    let accountData = useSelector((state) => state.account)
    console.log(accountData)
}

export const GetActiveAccountId = () => {
    let loggedInState = useSelector((state) => state.account.loggedInState)
    let accountId = useSelector((state) => state.account.id)
    if (loggedInState)  {
        return accountId
    }else {
        return null
    }
}

export const AccountManager = ({}) => {
    const dispatch = useDispatch()


    const CheckForActiveSession = () => {
        const accountLoggedIn = localStorage.getItem('account_logged_in');
        if (accountLoggedIn !== null && accountLoggedIn)    {
            const accountId = localStorage.getItem('account_id');
            const accountData = localStorage.getItem('account_data');
            const accountSessionToken = localStorage.getItem('account_session_token');
    
            dispatch(setupInitialSession({accountId: parseInt(accountId), accountData: JSON.parse(accountData), sessionToken: accountSessionToken, loggedInState: true }))
        }
    }

    React.useLayoutEffect(() => {
        CheckForActiveSession()
    }, [])

    return <>
    
    </>
}