import './SettingsPage.scss';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'


const Settings_FarmsPage = () => {
  return (
    <div id="Settings_FarmsPage">
        <div className="ControlBar_Horizontal">
          <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Roles' }]} defaultOption="All Roles"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultOption="Name" prefix="Sort By"/>
        </div>
    </div>
  )
} 

export default Settings_FarmsPage