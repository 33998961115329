import './../CreateControlDeviceWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, RackStandardGroup, ScanRack } from '../../../../assets/icons/RackIcons';
import { useDispatch, useSelector } from 'react-redux';
import { createNewControlDevice, pairControlDevice, selectAllControlDeviceGroups, selectAllControlDevices } from '../../../../redux/entities/service/ControlDevice';
import { createNewUID, generateUID, useMeasure } from '../../../../helpers';


  

const SelectWiFiCredentialsWalkthroughStep = ({initialWiFiSSID, initialWiFiPassword, transitionToNextStep, cancelCallback, setWiFiCredentialsCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const cancelClicked = React.useCallback(() => {
        cancelCallback()
    })

    const sendCredentialsClicked = () => {
        setWiFiCredentialsCallback({ssid: WiFiSSID, password: WiFiPassword})
        transitionToNextStep(["bluetooth_pairing", "setup"])
    }

    const [WiFiSSID, SetWiFiSSID] = React.useState("")
    const [WiFiPassword, SetWiFiPassword] = React.useState("")
    React.useLayoutEffect(() => {
        SetWiFiSSID(initialWiFiSSID)
        SetWiFiPassword(initialWiFiPassword)
    }, [initialWiFiSSID, initialWiFiPassword])
    const WiFiSSIDChanged = (name) =>   {
        SetWiFiSSID(name)
    }
    const WiFiPasswordChanged = (password) =>   {
        SetWiFiPassword(password)
    }



    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    WiFi Credentials
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    
                    <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                        <div className="FlexContent-40 FlexContent-Center FlexContent-OverflowY">
                            <div className="FlexContent-10 FlexContent-HFill">
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">WiFi SSID</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={WiFiSSID} placeHolder="Enter WiFi Name" onChange={WiFiSSIDChanged}/>
                                    </div>
                                </div>
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">WiFi Password</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={WiFiPassword} placeHolder="Enter WiFi Password" onChange={WiFiPasswordChanged}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Cancel" onClick={cancelClicked} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
                <Button content="Send Credentials" disabled={WiFiSSID == "" || WiFiPassword == ""} onClick={sendCredentialsClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
            </div>
        </div>
    )
}

  


export default SelectWiFiCredentialsWalkthroughStep