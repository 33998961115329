import './RecipesPage.scss';
import React from 'react';

import { useSelector, useDispatch } from 'react-redux'
import TextInput from '../../../components/input/TextInput';
import Button from '../../../components/Button';
import { pushRecipeChange } from '../../../redux/entities/Recipes';


const RecipeConfigPage = ({
    recipe, isEditingRecipe
  }) => {
  const dispatch = useDispatch()
  const [, forceRerender] = React.useReducer(x => x + 1, 0);

  const scrollContainerRef = React.useRef(null);



  const [recipeName, SetRecipeName] = React.useState("")
  const onRecipeNameChanged = (value) =>  {
    SetRecipeName(value)
    dispatch(pushRecipeChange({recipe: {...recipe,  name: value }}))
  }


  const [growOutType, SetGrowOutType] = React.useState("")
  const onGrowOutTypeSelected = (value) =>  {
    SetGrowOutType(value)

    dispatch(pushRecipeChange({recipe: {...recipe,  grow_out_type: value }}))
  }


  React.useEffect(() => {
    if (recipe === undefined) 
      return
    
      SetRecipeName(recipe.name)
      SetGrowOutType(recipe.grow_out_type)
  }, [recipe])




  

  return (
    
    <div id="Recipe-ConfigManager" ref={scrollContainerRef}>
        <div className="Recipe-ConfigManager-ConfigContent">
          <div className="FlexContent-20 FlexContent-Center">
            <div className="FormContent-FormItem">
              <div className="FormContent-FormItem-Label">
                  {!isEditingRecipe && <>Recipe Name</>}
                  {isEditingRecipe && <>Name your recipe</>}
              </div>
              <div className="FormContent-FormItem-Value-TextInput">
                  <TextInput value={recipeName} placeHolder="" onBlur={onRecipeNameChanged} inactive={!isEditingRecipe}/>
              </div>
            </div>
            <div className="FormContent-FormItem">
              <div className="FormContent-FormItem-Label">
                  {!isEditingRecipe && <>Grow Out Type</>}
                  {isEditingRecipe && <>Select grow out type</>}
              </div>
              {!isEditingRecipe && <div className="FormContent-FormItem-Value-Display">
                {growOutType === "grow_boards" && <>Grow Boards</>}
                {growOutType === "berry_troughs" && <>Berry Troughs</>}
              </div>}
              {isEditingRecipe && <div className="FormContent-FormItem-Value-ButtonsInput">
                <Button 
                  status={"Primary-Toggle"} 
                  content={"Grow Boards"} 
                  width={"flex50"} 
                  state={growOutType === "grow_boards"}
                  onClick={() => {onGrowOutTypeSelected("grow_boards")}}/>
                <Button 
                  status={"Primary-Toggle"} 
                  content={"Berry Troughs"} 
                  width={"flex50"} 
                  state={growOutType === "berry_troughs"}
                  onClick={() => {onGrowOutTypeSelected("berry_troughs")}}/>
              </div>}
            </div>
          </div>
        </div>
    </div>
  )
} 













export default RecipeConfigPage