import './RecipesPage.scss';
import React from 'react';

import { useMeasure, useMeasureWithRef } from '../../../helpers'
import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import {TabControl, TabControlTab} from '../../../components/TabControl.js';
import DropDownInput from '../../../components/input/DropDownInput.js'
import Button from '../../../components/Button.js';
import TextInput from '../../../components/input/TextInput';
import NumberInput from '../../../components/input/NumberInput';
import DropDownButton from '../../../components/DropDownButton.js';
import Checkbox from '@mui/material/Checkbox';
 
import { useParams, useNavigate, Route, Navigate, Routes, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { selectRecipeById, selectAllRecipes, getRecipeById, getWorkingRecipeById, setRecipeRevisionIndex, saveRecipe, discardRecipeChanges } from '../../../redux/entities/Recipes'

import {BiGridVertical} from 'react-icons/bi'
import {FaTrashAlt} from 'react-icons/fa'
import {HiOutlineDuplicate} from 'react-icons/hi'
import {FiEdit} from 'react-icons/fi'
import {MdUndo, MdRedo} from 'react-icons/md'


import RecipeTimelinePage from './RecipeTimelinePage.js'
import RecipeZonesPage from './RecipeZonesPage.js'
import RecipeNutrientsPage from './RecipeNutrientsPage.js'
import { RecipeTimeline } from './RecipeTimeline';

import DiscardRecipeChangesPopup from './DiscardRecipeChangesPopup';
import RecipeRelationshipsPage from './RecipeRelationshipsPage';
import RecipeConfigPage from './RecipeConfigPage';
import { useMediaQuery } from 'react-responsive';
import { AdditionalOptions } from '../../../assets/icons/Icons';

const RecipePage = ({pageHeaderHelper, isEditingRecipe}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const { recipeID } = useParams();
  let recipes = useSelector(selectAllRecipes)
  let pushingChangeStatus = useSelector((state) => state.recipes.pushingChangeStatus)
  const [recipe, SetRecipe] = React.useState(undefined)
  
  const location = useLocation();
  const [currentTab, SetCurrentTab] = React.useState("")
  React.useLayoutEffect(() => {
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    SetCurrentTab(splitLocation.length > 0 ? splitLocation[splitLocation.length - 1] : "")
  }, [location])
  let cycleTimelineSelectionActive = currentTab == "timeline" ? false : true
  
  //let recipe = recipes.find(e => e.id.toString() === recipeID.toString())
  //let recipe = useSelector(selectRecipeById(recipeID))
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    let foundRecipe = undefined
    if (isEditingRecipe)  {
      foundRecipe = recipes.find(r => r.is_working_version && r.id === parseInt(recipeID))
      if (foundRecipe === undefined) {
        dispatch(getWorkingRecipeById({recipeId: parseInt(recipeID)}))
      }
    }else {
      foundRecipe = recipes.find(r => r.is_active_version && r.id === parseInt(recipeID))
      if (foundRecipe === undefined) {
        dispatch(getRecipeById({recipeId: parseInt(recipeID)}))
      }
    }
    console.log(foundRecipe)
    SetRecipe(foundRecipe)
  }, [recipeID, navigate, recipes, isEditingRecipe]);


  


  const lastCheckForUpdateOn = React.useRef(new Date().getTime())
  React.useEffect(() => {
    const checkForRecipeUpdate = () =>  {
      dispatch(getWorkingRecipeById({recipeId: recipe.id, haveStackIndex: recipe.stack_index}))
    }
    if (isEditingRecipe && recipe !== undefined)  {
      const requestInterval = setInterval(() => {
        if (lastCheckForUpdateOn.current + 500 < new Date().getTime()) {
          if (pushingChangeStatus !== "pending")  {
            checkForRecipeUpdate()
            lastCheckForUpdateOn.current = new Date().getTime()
          }
        }
      }, 10);
      

      return () => {
          clearInterval(requestInterval)
      };
    }
  }, [recipe, isEditingRecipe])




  const undoRecipe = React.useCallback(() =>  {
    if (recipe.canUndo) {
      dispatch(setRecipeRevisionIndex({recipeId: recipe.id, stackIndex: recipe.stack_index - 1}))
    }
  })
  const redoRecipe = React.useCallback(() =>  {
    if (recipe.canRedo) {
      dispatch(setRecipeRevisionIndex({recipeId: recipe.id, stackIndex: recipe.stack_index + 1}))
    }
  })

  const [savingRecipe, SetSavingRecipe] = React.useState(false)
  const saveRecipeClicked = React.useCallback(() => {
    if (recipe.canSave) {
      dispatch(saveRecipe({recipeId: recipe.id}))
      navigate("/repo/recipe/" + recipeID + (currentTab !== "" ? "/" + currentTab : ""));
      //SetSavingRecipe(true)
    }
  })
  const closeSaveRecipePopup = () =>  {
    SetSavingRecipe(false)
  }
  const savingRecipeFinalized = (recipe) =>  {

      //dispatch(saveRecipe({recipe: recipe}))
  }


  
  const [discardingRecipe, SetDiscardingRecipe] = React.useState(false)
  const discardChangesClicked = React.useCallback(() => {
    SetDiscardingRecipe(true)
  })
  const closeDiscardRecipePopup = () => {
    SetDiscardingRecipe(false)
  }
  const confirmedDiscardRecipe = () =>  {
    dispatch(discardRecipeChanges({recipeId: recipe.id}))
    navigate("/repo/recipe/" + recipeID + (currentTab !== "" ? "/" + currentTab : ""));
    SetDiscardingRecipe(false)
}


  const headerRef = React.useRef({
    canUndo: false,
    canRedo: false,
    canSave: false,
    onUndo: null,
    onRedo: null,
    onSave: null,
    onDiscard: null
  })
  
  const updateHeader = React.useCallback(() =>  {
    pageHeaderHelper.current.Reset()
    if (recipe !== undefined) {
      pageHeaderHelper.current.SetTitle(recipe.name)
      let recipeDate = new Date()
      pageHeaderHelper.current.SetSubtitle(() => {
        return (
          <>
            <span style={{color: "#666"}}>{"v" + recipe.version + ", " + FormatDate(recipeDate, "MM/d/yyyy")}</span>
            {isEditingRecipe && <>
              &nbsp;-&nbsp;
              <span style={{color: "#916A00"}}>Unsaved Changes</span>
            </>}
          </>
        )
      })
    }
    //pageHeaderHelper.current.SetNavigateBackActive(true)
    //pageHeaderHelper.current.SetNavigateBackLocation("/repo/recipes")
    pageHeaderHelper.current.SetFunctionsContainerCanScroll(false)
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {!isEditingRecipe && <>
                {isMobile && <>
                  
                  {(() => {
                    let additionalFunctions = [
                      {key: "view_revisions", label: "View Revisions"},
                      {key: "manage_access", label: "Manage Access"},
                    ]
                    let optionSelected = (option) => {

                    }
                    return (
                      <DropDownButton content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} options={additionalFunctions} onOptionSelected={optionSelected}/>
                    )
                  })()}
                
                </>}
                {!isMobile && <>
                  <Button content="View Revisions" status="Neutral"/>
                  <Button content="Manage Access" status="Neutral"/>
                </>}
                <Button content="Edit" onClick={setToEditing}/>
              </>}
              {isEditingRecipe && <>
                {isMobile && <>
                  
                  {(() => {
                    let additionalFunctions = [
                      {key: "manage_access", label: "Manage Access"},
                      {key: "undo", label: "Undo Change", disabled: !headerRef.current.canUndo},
                      {key: "redo", label: "Redo Change", disabled: !headerRef.current.canRedo},
                      {key: "discard_changes", label: "Discard Changes"},
                    ]
                    let optionSelected = (option) => {
                      switch(option.key)  {
                        case "undo":
                          headerRef.current.onUndo()
                          break
                        case "redo":
                          headerRef.current.onRedo()
                          break
                        case "discard_changes":
                          headerRef.current.onDiscard()
                          break
                        default:
                          break
                      }
                    }
                    return (
                      <DropDownButton content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} options={additionalFunctions} onOptionSelected={optionSelected}/>
                    )
                  })()}

                </>}
                {!isMobile && <>

                  <Button content="Manage Access" status="Neutral"/>
                  <div style={{display: "flex", margin: "0 10px", gap:8}}>
                    <Button 
                      content={<MdUndo />} 
                      status="Neutral"
                      disabled={!headerRef.current.canUndo}
                      onClick={headerRef.current.onUndo}/>
                    <Button 
                      content={<MdRedo />} 
                      status="Neutral"
                      disabled={!headerRef.current.canRedo}
                      onClick={headerRef.current.onRedo}/>
                    
                  </div>
                  <Button content="Discard Changes" onClick={headerRef.current.onDiscard} status={"Neutral"}/>
                </>}
                <Button content="Save" onClick={headerRef.current.onSave} disabled={!headerRef.current.canSave}/>
              </>}

            </div>
          </div>
        </>
      )
    })
    pageHeaderHelper.current.SetCondensed(false)
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])

  React.useLayoutEffect(() => {
    updateHeader()
  }, [currentTab])

  React.useEffect(() => {
    if (recipe === undefined) {
      headerRef.current.canUndo = false
      headerRef.current.canRedo = false
      updateHeader()
      return
    }
    headerRef.current.canUndo = recipe.canUndo
    headerRef.current.canRedo = recipe.canRedo
    headerRef.current.canSave = recipe.canSave
    headerRef.current.onUndo = undoRecipe
    headerRef.current.onRedo = redoRecipe
    headerRef.current.onSave = saveRecipeClicked
    headerRef.current.onDiscard = discardChangesClicked
    updateHeader()

    
  }, [recipe])


  
  const setToEditing = React.useCallback(() =>  {
    navigate("/repo/recipe/" + recipeID + "/edit" + (currentTab !== "" ? "/" + currentTab : ""));
  })
 
  




  const [recipeDuration, SetRecipeDuration] = React.useState(0);
  const [selectedTimelineItem, SetSelectedTimelineItem] = React.useState(undefined);
  const [timelineItemSelectionOptions, SetTimelineItemSelectionOptions] = React.useState([]);
  

  const timelineItemSelected = React.useCallback((cycle) => {
    if (selectedTimelineItem != cycle)  {
      SetSelectedTimelineItem(cycle)
    }
  })

  const SelectManageTimelineItems = React.useCallback(
    () => {
      navigate("timeline", { replace: true });
    },
    [navigate]
  );




  React.useEffect(() => {
    if (recipe === undefined) 
      return


    let currentTimelineItemSelectionOptions = []
    let foundFirstCycle = null
    let foundSelectedCycle = (selectedTimelineItem === undefined)
    if (recipe.timeline_items != null) {
      console.log(recipe)
      for (let timelineItem of recipe.timeline_items) {
        if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle")  {
          if (foundFirstCycle === null) {
            foundFirstCycle = timelineItem
          }
          currentTimelineItemSelectionOptions.push({
            value: timelineItem.id,
            label: timelineItem.item.name
          })

          if (!foundSelectedCycle && selectedTimelineItem.id === timelineItem.id)  {
            foundSelectedCycle = true
          }
        }
      }

      if (selectedTimelineItem === undefined || !foundSelectedCycle)  {
        if (foundFirstCycle)  {
          SetSelectedTimelineItem(foundFirstCycle)
        }
      }else {
        const foundTimelineItem = recipe.timeline_items.find((t) => t.id == selectedTimelineItem.id)
        if (foundTimelineItem) {
          SetSelectedTimelineItem(foundTimelineItem)
        }
      }
    }

    SetTimelineItemSelectionOptions(currentTimelineItemSelectionOptions)
  }, [recipe, navigate])





  


  return (
    <div className="PageWrapper_Standard">
      <TabControl tabs={
        [new TabControlTab("Config", "config", true),
        new TabControlTab("Timeline", "timeline"),
        new TabControlTab("Setpoints", "setpoints"),
        new TabControlTab("Relationships", "relationships"),
        new TabControlTab("Nutrient Composition", "nutrients")]}
        activePathIndex={isEditingRecipe ? 5 : 4}
        tabLocation={"center"}
        content={
          <div id="RecipePage">
            
            <div id="RecipePage_TabContent">
              {recipe && 
                <>
                <Routes>
                  <Route path="/config" element={
                    <RecipeConfigPage
                      recipe={recipe}
                      isEditingRecipe={isEditingRecipe}/>
                  }/>
                  <Route path="/timeline" element={
                    <RecipeTimelinePage 
                      recipe={recipe}
                      isEditingRecipe={isEditingRecipe}/>
                  }/>
                  <Route path="/setpoints" element={
                    <RecipeZonesPage 
                      recipe={recipe} 
                      selectedTimelineItem={selectedTimelineItem} 
                      selectTimelineItem={timelineItemSelected} 
                      timelineItemSelectionOptions={timelineItemSelectionOptions}
                      isEditingRecipe={isEditingRecipe}/>
                  }/>
                  <Route path="/relationships" element={
                    <RecipeRelationshipsPage
                      recipe={recipe} 
                      selectedTimelineItem={selectedTimelineItem} 
                      selectTimelineItem={timelineItemSelected} 
                      timelineItemSelectionOptions={timelineItemSelectionOptions}
                      isEditingRecipe={isEditingRecipe}/>
                  }/>
                  <Route path="/nutrients" element={
                    <RecipeNutrientsPage 
                      recipe={recipe} 
                      selectedTimelineItem={selectedTimelineItem} 
                      selectTimelineItem={timelineItemSelected} 
                      timelineItemSelectionOptions={timelineItemSelectionOptions}
                      isEditingRecipe={isEditingRecipe}/>
                  }/>

                  <Route path="*" element={<Navigate to="config" replace />} />
                </Routes>

                </>
              }
              <div className="RecipePage_TimelineContainer">
                <RecipeTimeline
                  recipe={recipe}
                  selectedTimelineItem={selectedTimelineItem}
                  timelineItemSelected={timelineItemSelected}
                  timelineSelectable={cycleTimelineSelectionActive}
                  showDayTicks={false}
                  manageTimelineSelected={SelectManageTimelineItems}/>
              </div>
              
            </div>
            {/*savingRecipe && 
              <SavingRecipePopup closeCallback={closeSaveRecipePopup} completeCallback={savingRecipeFinalized}/>
            */}
            {discardingRecipe && 
              <DiscardRecipeChangesPopup closeCallback={closeDiscardRecipePopup} completeCallback={confirmedDiscardRecipe}/>
            }
          </div>
        }/>
    </div>
  )
}


RecipePage.defaultProps = {
  isEditingRecipe: false,
}

export default RecipePage