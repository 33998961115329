import './StandardTable.scss';

import React from 'react'

import { useTable, useBlockLayout, useResizeColumns, useSortBy, useRowSelect} from 'react-table'

import {AiOutlineLoading} from "react-icons/ai";
import {TbArrowsSort, TbArrowNarrowDown, TbArrowNarrowUp} from 'react-icons/tb'
import {MdKeyboardArrowRight, MdKeyboardArrowDown} from 'react-icons/md'

import { animated, useSpring } from 'react-spring'
import { useMeasure } from '../helpers'


const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
    }, 
  },
]




export const StandardTable = React.memo(({id, 
  data, columns, initialState, isSelectableRows, isResizable, 
  isFullWidth, GroupFooter, isDefaultOpen, headerRef, 
  tableBodyRef, isCondensed, setRowLayoutBounds, onRowClicked, initialLoadingState}) => {



  const tableRootRef = React.useRef()
  

  const groupedColumns = React.useMemo(() => (
    columns.filter(function (e) {
      return e.isGrouped === true;
    })
  ), []);
  
  const [storedGroupedRows, SetGroupedRows] = React.useState([]);
  const setGroupExpansion = function(rowGroup, state) {
    rowGroup.state = (state ? "expanded" : "collapsed")
    SetGroupedRows(storedGroupedRows => [...storedGroupedRows]);
  };

  /*React.useEffect(()=>{
    return ()=>{
      for (let rowGroup of storedGroupedRows) {
        console.log(storedGroupedRows)
        if (rowGroup.content !== undefined) {
          if (rowGroup.state != (rowGroup.content.isExpanded ? "expanded" : "collapsed"))  {
            console.log("updating")
            setGroupExpansion(rowGroup, rowGroup.content.isExpanded);
          }
        }
      }
    }
  })*/

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <label className="DataTable_TableCellSelect">
          <input type="checkbox" ref={resolvedRef} {...rest}/>
        </label>
      )
    }
  )

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 300, // maxWidth is only used as a limit for resizing
      wrapStyle: "truncate",
      canGroupBy: false
    }),
    []
  )


  const StandardTableRow = React.memo(({row}) => {
    prepareRow(row)
    const tableRowRef = React.useRef()

    React.useEffect(() => {
      if (tableRowRef.current != null && tableBodyRef !== undefined && tableBodyRef.current != null && row.dataEntry != null && row.groupContentRef != null)  {
        const layoutY = tableRowRef.current.getBoundingClientRect().y - tableBodyRef.current.getBoundingClientRect().y
        //row.dataEntry.y = tableRowRef.current.getBoundingClientRect().y - tableBodyRef.current.getBoundingClientRect().y
        
        let rowBounds = tableRowRef.current.getBoundingClientRect(), rowTop = rowBounds.top, rowBottom = rowBounds.bottom
        const rowParentBounds = row.groupContentRef.current.getBoundingClientRect()

        if (rowBottom > rowParentBounds.bottom)  {
          rowBottom = rowParentBounds.bottom
          if (rowTop > rowBottom) {
            rowBottom = rowTop
          }
        }
        const layoutHeight = rowBottom - rowTop ;
        //row.dataEntry.height = rowBottom - rowTop ;

        if (setRowLayoutBounds !== undefined) {
          setRowLayoutBounds(row, {x: 0, y: layoutY, width: 0, height: layoutHeight})
        }
      }
    }, [row])


    const cellClicked = (cell) =>  {
      if (onRowClicked !== undefined) {
        onRowClicked(row)
      }
    }

    return (
        <div
          {...row.getRowProps({style: {width: "auto"}})}
          className="DataTable_TableRow"
          key={row.original.id}
          ref={tableRowRef} 
        >
          
          {row.cells.map(cell => {
                if (cell.column.canGroupBy !== true)  {
                  const uniqueCellProps = {
                    style: {
                        whiteSpace: (cell.wrapStyle == "break" ? "normal" : "nowrap"),
                        textOverflow: (cell.wrapStyle == "break" ? "break" : "ellipsis")
                    },
                  }


                  switch(cell.wrapStyle) {
                    case "break":
                      cell.whiteSpace = "normal";
                    case "truncate":
                      cell.whiteSpace = "nowrap";
                    break;
                  default:
                } 
                  return (
                    <div  {...cell.getCellProps({...cellProps, })} className="DataTable_TableCell" onClick={() => {cellClicked(cell)}}>
                      <div className="DataTable_TableCellContent" {...uniqueCellProps}>
                        {cell.render('Cell')}
                      </div>
                    </div>
                  )
                }
              })}
        </div>
    );
  })




  //Needs to use memo
  //Upon sort/filter, group needs to be memoized to prevent rerender, then
  //State needs to be updated to push row changes (rows and subgroups now need to be part of state)
  const StandardTableGroup = React.memo(({rowGroup, offset}) => {
    const [ isExpanded, setExpanded ] = React.useState((rowGroup.state == "expanded" ? true : false));
    const [ updateCounter, setUpdateCount ] = React.useState(0);
    
    const tableGroupContentRef = React.useRef()

    function handleExpansionToggle() {
      setExpanded(!isExpanded);
      //rowGroup.state = (isExpanded ? "expanded" : "collapsed")
      //setGroupExpansion(rowGroup, !isExpanded);
    }
    rowGroup.values = {}
    prepareRow(rowGroup)

    rowGroup.contentRef = tableGroupContentRef


    rowGroup.renderCallback = function() {
      setUpdateCount(updateCounter + 1)
    }

    const [subGroupsContainerBind, { height: subGroupsContainerHeight }] = useMeasure()
    const [pinnedRowsContainerBind, { height: pinnedRowsContainerHeight }] = useMeasure()
    const [unpinnedRowsContainerBind, { height: unpinnedRowsContainerHeight }] = useMeasure()
    const [groupFooterContainerBind, { height: groupFooterContainerHeight }] = useMeasure()
    const totalContentHeight = subGroupsContainerHeight + pinnedRowsContainerHeight + unpinnedRowsContainerHeight + groupFooterContainerHeight
    const growItemHeightAnimation = useSpring({
      from: {height: 0},
      to: { height: !isExpanded ? 0 : totalContentHeight}
    })

    const margin = offset * 15;
    
    if (rowGroup.subGroups !== undefined)  {
      for (let group of rowGroup.subGroups)  {
        group.content = <StandardTableGroup rowGroup={group} offset={offset + 1} key={group.key}/>
      }
    }
    if (rowGroup.rows !== undefined)  {
      for (let row of rowGroup.rows)  {
        row.groupContentRef = tableGroupContentRef
        row.content = <StandardTableRow row={row} key={row.original.id}/>;
      }
    }
    return (
      <div className={"DataTable_TableGroup" + (isFullWidth ? " DataTable_TableGroupFullWidth" : "")} key={rowGroup.key}>
        <div className="DataTable_TableGroupHeader" {...rowGroup.getRowProps({style: {width: "auto"}})}> 
          {isSelectableRows && 
            <div className="DataTable_TableGroupCell">
              
            </div>
          }
          
          <div className="DataTable_TableGroupCell DataTable_TableGroupHeaderToggle noselect" style={{marginLeft: margin}} onClick={handleExpansionToggle}>
            {(!isExpanded ?  <MdKeyboardArrowRight/> : <MdKeyboardArrowDown/>)}
            {rowGroup.key}
          </div>
        </div>
        <animated.div className="DataTable_TableGroupContent" style={growItemHeightAnimation} ref={tableGroupContentRef}>
          {rowGroup.subGroups !== undefined &&
            <div {...subGroupsContainerBind}>
              {(() => {rowGroup.subGroups.sort(function(a, b){return a.key > b.key});})()}
              {rowGroup.subGroups.map(subRowGroup => {
                //return renderRowGroup(subRowGroup, offset + 1);
                return (
                  <subRowGroup.content rowGroup={subRowGroup} offset={offset + 1}/>
                  
                )
                //<StandardTableGroup rowGroup={subRowGroup} offset={offset + 1} />
              })}
            </div>
          }
          {(() => {
            if (rowGroup.rows !== undefined) {
              let pinnedRows = rowGroup.rows.filter(function (e) {
                return (e.original !== undefined && e.original.pinned === true);
              });
              let unpinnedRows = rowGroup.rows.filter(function (e) {
                return (e.original === undefined || e.original.pinned !== true);
              });


              return (
                <div className="DataTable_TableGroupRows"  >
                  {pinnedRows.length > 0 &&
                    <div className={"DataTable_PinnedRows" + (unpinnedRows.length > 0 ? " DataTable_PinnedRowsIndicator" : "")} {...pinnedRowsContainerBind}>
                      {pinnedRows.map(row => {
                        return row.content;
                      })}
                    </div>
                  }
                  <div className="DataTable_UnpinnedRows" {...unpinnedRowsContainerBind}>
                    {unpinnedRows.map(row => {
                      return row.content;
                    })}
                  </div>
                </div>
              )

            }
          })()}

          {GroupFooter !== undefined &&
            <div className="DataTable_TableGroupFooter" {...groupFooterContainerBind}>
              <GroupFooter rowGroup={rowGroup}/>
            </div>
          }
        </animated.div>
      </div>
    )
  })


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    } = useTable(
    {
      columns,
      data,
      initialState,
      defaultColumn,
      
    },
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    hooks => {
      if (isSelectableRows === true) {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            width:"auto",
            disableResizing: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox  {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
           
          },
          ...columns,
        ])
      }
    }
  )


  
  let groupedRows = [...storedGroupedRows];
  const isCurrentlyGrouped = (groupedColumns.length > 0 ? true : false);

    if (isCurrentlyGrouped) {
      let clearRows = function(currentGroupedRows)  {
        for (let currentGroup of currentGroupedRows) {
          currentGroup.rows = []
        }
      }
      clearRows(groupedRows)
      let currentGroupedRows = groupedRows;
      

      let groupKeys = [];
      {groupedColumns.map(column => {
        groupKeys.push(column.accessor);
        //create group for every row category that is new
      })}

      
      for (let row of rows) {
        let dataEntry = data.find(e => e.id.toString() === row.original.id.toString())
        if (dataEntry !== undefined)  {
          row.dataEntry = dataEntry
        }
        let currentGroupKeyIndex = 0;
        currentGroupedRows = groupedRows;
        while (currentGroupKeyIndex < groupKeys.length) {
          let currentGroupKey = groupKeys[currentGroupKeyIndex];
          let currentGroup = currentGroupedRows.find(e => e.key === row.values[currentGroupKey])
          
          currentGroupKeyIndex += 1;
          if (currentGroup === undefined) {
            currentGroup = {key: row.values[currentGroupKey], state: (isDefaultOpen ? "expanded" : "collapsed"), initialized: false, updateCount: 0}
            //[currentGroup.ExpandedState, currentGroup.SetExpandedState] = useState(false);
            if (currentGroupKeyIndex < groupKeys.length)  {
              currentGroup.subGroups = [];
            }else {
              currentGroup.rows = [];
              currentGroup.rows.push(row);
            }
            currentGroupedRows.push(currentGroup)
          }else {
            if (currentGroupKeyIndex == groupKeys.length)  {
              if (!currentGroup.rows.includes(row))  {
                currentGroup.rows.push(row);
              }
            }
          }
          currentGroupedRows = currentGroup.subGroups;
        }

      }
    }else {
      for (let row of rows)  {
        row.content = <StandardTableRow row={row} key={row.original.id}/>;
      }
    }


  
  for (let group of groupedRows)  {
    if (group.rows !== undefined)  {
      for (let row of group.rows)  {
        row.content =<StandardTableRow row={row} key={row.original.id}/>;
      }
    }
    if (group.initialized === false)  {
      group.content = <StandardTableGroup rowGroup={group} offset={0} key={group.key}/>
      group.initialized = true
      SetGroupedRows(storedGroupedRows => [...storedGroupedRows, group]);
    }
  }

  React.useEffect(() => {
    for (let group of groupedRows)  {
        if (group.initialized !== false)  {
          if (group.renderCallback != null) {
            group.renderCallback();
          }
        }
    }

    
  }, [rows])
  
  

  

  return (
    <div className={"DataTable_Standard" + (isCondensed ? " DataTable_Condensed" : "")} id={id} ref={tableRootRef}>
      {initialLoadingState &&
        <div className="DataTable_Standard-LoadingView">
          <AiOutlineLoading/>
        </div>
      }
      <div {...getTableProps()} className="DataTable_Table">
        <div className="DataTable_TableHeader noselect" ref={headerRef}>
          {headerGroups.map(headerGroup => {
            if (headerGroup.headers.length > 0) {
              headerGroup.headers[headerGroup.headers.length - 1].canResize = false;
            }

            let lastColumn = null;
            return (
              <div {...headerGroup.getHeaderGroupProps({style: {width: "auto"}})} className="DataTable_TableHeaderRow noselect">
                

                {headerGroup.headers.map(column => {
                  if (column.canGroupBy !== true)  {
                    column.colRef = React.createRef();
                    
                    let lastColumn = null;
                    for (const c of headerGroup.headers) {
                      if (c == column)  {
                        break;
                      }
                      if (c.isGrouped !== true) {
                        lastColumn = c;
                      }
                    }
                    

                    return (
                      <div 
                        colSpan="1"
                        ref={column.colRef}
                        role="columnheader" 
                        onClick={(event)=> event.stopPropagation()}
                        {... column.getHeaderProps(headerProps)}
                        className="DataTable_TableHeaderCell noselect">
                        <div
                          className={"DataTable_TableHeaderCellContent noselect" + (column.id === "selection" ? " DataTable_TableHeaderSelectionCellContent" : "")}
                          {... (lastColumn != null && lastColumn.canResize === true && isResizable && lastColumn.isResizable !== false ? {...lastColumn.getResizerProps()} : {})}>
                          {column.render('Header')}
                        </div>
                        {column.isSortable === true && 
                          <div className="DataTable_TableHeaderCellSort noselect"
                            {...column.getSortByToggleProps()}>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <>
                                  <TbArrowNarrowDown/>
                                  </>
                                : <>
                                  <TbArrowNarrowUp/>
                                  </>
                              : <>
                                  <TbArrowsSort/>
                                </>
                              }
                          </div>
                        }
                      </div>
                    )
                  }
                })}
              </div>
            )
          })}
        </div>
        <div {...getTableBodyProps()} className="DataTable_TableBody" ref={tableBodyRef}>
          {/* Check for grouping */}
          {(() => {
            
            if (isCurrentlyGrouped) {
              
              storedGroupedRows.sort(function(a, b){return a.key > b.key});
              return (
                <div className="DataTable_TableGroups">
                  {storedGroupedRows.map(rowGroup => {
                    //rowGroup.content = React.memo(StandardTableGroup(rowGroup, 0));
                    //return renderRowGroup(rowGroup);
                    //return (
                    //  <StandardTableGroup rowGroup={rowGroup} offset={0} key={rowGroup.key}/>
                    //)
                    return rowGroup.content
                  })}
                </div>
              )
            }else {


              let pinnedRows = rows.filter(function (e) {
                return (e.original !== undefined && e.original.pinned === true);
              });
              let unpinnedRows = rows.filter(function (e) {
                return (e.original === undefined || e.original.pinned !== true);
              });

              return (
                <div className="DataTable_TableRows">
                  {pinnedRows.length > 0 &&
                    <div className={"DataTable_PinnedRows" + (unpinnedRows.length > 0 ? " DataTable_PinnedRowsIndicator" : "")}>
                      {pinnedRows.map(row => {
                        return row.content;
                      })}
                    </div>
                  }
                  <div className="DataTable_UnpinnedRows">
                    {unpinnedRows.map(row => {
                      return row.content;
                    })}
                  </div>
                </div>
              )
            }
          })()}
          
        </div>
      </div>
    </div>

  )
})


/*import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export const StandardTable = ({rows, columns, multiSelect}) => {


  var conditionalProps = {
    
  };
  
  if (multiSelect) {
    conditionalProps.checkboxSelection = true
  }
  return (
    <div className="DataTable_Standard">
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        pageSize={10}
        rowsPerPageOptions={[10]}
        {...conditionalProps}
      />
    </div>
  )
} */

StandardTable.defaultProps = {
  isFullWidth: false,
  isCondensed: false
}

export default StandardTable 