import './CreateControlDeviceWalkthrough.scss';
import React from 'react';
import Walkthrough from '../../../components/Walkthrough';
import SelectWiFiCredentialsWalkthroughStep from './SetWiFiCredentialsForControlDeviceWalkthroughSteps/SelectWiFiCredentialsWalkthroughStep';
import BluetoothPairToControlBoardWalkthroughStep from './SetWiFiCredentialsForControlDeviceWalkthroughSteps/BluetoothPairToControlBoardWalkthroughStep';


  

const SetWiFiCredentialsForControlDeviceWalkthrough = ({controlDevice, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }


    const [WiFiSSID, SetWiFiSSID] = React.useState("")
    const [WiFiPassword, SetWiFiPassword] = React.useState("")

    const setWiFiCredentials = ({ssid, password}) => {
        SetWiFiSSID(ssid)
        SetWiFiPassword(password)
    }
    


    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["wifi_credentials", null])

    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }

    

    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "wifi_credentials":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            
            case "bluetooth_pairing":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true



    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "wifi_credentials":
                return (<SelectWiFiCredentialsWalkthroughStep
                            initialWiFiSSID={WiFiSSID}
                            initialWiFiPassword={WiFiPassword} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            cancelCallback={closePopup}
                            setWiFiCredentialsCallback={setWiFiCredentials}/>)
                

            case "bluetooth_pairing":
                const wiFiConfirmedSentCallback = () =>  {
                    completeCallback()   
                }

                
                return (<BluetoothPairToControlBoardWalkthroughStep
                            controlDevice={controlDevice}
                            connectionStep={subStepKey}
                            WiFiSSID={WiFiSSID}
                            WiFiPassword={WiFiPassword}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            wiFiConfirmedSentCallback={wiFiConfirmedSentCallback}/>)

            default:
                return <></>
        }
    }


    return (<>
        <Walkthrough
            id={"SetWiFiCredentialsPopup_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Set WiFi For Device</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["wifi_credentials", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)

}



export default SetWiFiCredentialsForControlDeviceWalkthrough