import { useSpring, animated } from 'react-spring';
import './RowGroup.scss';

import * as React from 'react';
import { Gear } from '../../assets/icons/Icons';
import Button from '../Button';
import DropDownButton from '../DropDownButton';
import { useMediaQuery } from 'react-responsive';
import useMeasure from '../../useMeasure';



const RowGroup = ({
                  children,
                  title,
                  titleFontSize,
                  hasGroupHeader,
                  verticalFill,
                  isExpandable,
                  expandedState,
                  expandCellContent,
                  collapseCellContent,
                  hasSubGroups,
                  hasSettings,
                  hasContainer,
                  containerHasFill,
                  containerHasBorder,
                  settingsOptions,
                  columnHeaders,
                  customContentPadding,
                  itemsScroll,
                  itemContentBackgroundColor,
                  itemContentPadding,
                  hasColumnHeaders, columnSizes
                }) => {


  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const [rowGroupItemsHandleRef, {height: rowGroupItemsHeight}] = useMeasure()
  const [rowGroupItemsContainerHandleRef, {width: rowGroupItemsContainerWidth}] = useMeasure()
  
  const [colummHeadersRef, {}] = useMeasure()

  const [columnHeaderContentHeight, SetColumnHeaderContentHeight] = React.useState(0)



  const [isExpanded, SetIsExpanded] = React.useState(false)
  const [isExpandedTransitionState, SetIsExpandedTransitionState] = React.useState("idle")
  React.useLayoutEffect(() => {
    SetIsExpanded(expandedState)
  }, [expandedState])
  const toggleExpandedState = (state) =>  {
    SetIsExpandedTransitionState("setup")
    SetIsExpanded(state)
  }
  let expandedItemsStyle = {height: 0}
  if (isExpandable) {
    if (isExpandedTransitionState === "idle") {
      expandedItemsStyle.height = (isExpanded ? "auto" : 0)
      expandedItemsStyle.visibility = (isExpanded ? "visible" : "hidden")
    }else if (isExpandedTransitionState === "setup")  {
      expandedItemsStyle.height = rowGroupItemsHeight
    }else if (isExpandedTransitionState === "transitioning")  {
      expandedItemsStyle.height = (isExpanded ? rowGroupItemsHeight : 0)
    }
  }

  React.useLayoutEffect(() => {
    if (isExpandedTransitionState === "setup")  {
      SetIsExpandedTransitionState("transitioning")
    }
  }, [isExpandedTransitionState])

  const expandedStateTransitionCompleted = () =>  {
    SetIsExpandedTransitionState("idle")
  }
  

  const contentPadding = customContentPadding ? customContentPadding : (isMobile ? 5 : 9)

  /*const [nextCardTransitionToCurrentCardAnimation, nextCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContentWidth },
  }))*/

  let footerShouldShow = false
  if (isExpandable) {
    footerShouldShow = true
  }


  const rowGroupContainerProps = {style:{}}
  if (itemsScroll)  {
    rowGroupContainerProps.style.overflowY = "hidden"
  }
  if (verticalFill) {
    rowGroupContainerProps.style.flex = 1
  }

  const rowGroupProps = {style:{}}
  if (verticalFill) {
    rowGroupProps.style.flex = 1
  }

  const rowGroupItemsProps = {style:{}}
  if (hasSubGroups) {
    rowGroupItemsProps.style.gap = 16
    if (columnSizes["container_width"] !== undefined) {
      //if (columnSizes["container_width"] < rowGroupItemsContainerWidth) {
        //rowGroupItemsProps.style.minWidth = columnSizes["container_width"]
      //}else {

      //}
    }
  }
  const rowGroupItemsContainerStyle = {}
  if (containerHasBorder) {
    rowGroupItemsContainerStyle.border = "1px solid #DDE3EB";
  }

  return (<>
    <div className={"RowGroup-Container" + (!hasContainer ? " RowGroup-NoContainer" : "")} {...rowGroupContainerProps}>
      <div className={"RowGroup"} {...rowGroupProps}>
        {hasGroupHeader && 
          <div className={"RowGroup-Header"}>
            <div className="RowGroup-Header-Title">
              <div className="RowGroup-Header-Title-Content noselect" style={{fontSize: titleFontSize}}>
                {title}
              </div>
            </div>
            {hasSettings &&
              <div className="RowGroup-Header-Settings">
                <div className="RowGroup-Header-Settings-Button">
                  <DropDownButton content={<div style={{display:"flex", padding:"0px 0px"}}><Gear/></div>} options={settingsOptions}/>

                </div>
              </div>
            }
          </div>
        }
        <div className="RowGroup-Content">
          {(hasColumnHeaders && columnHeaders !== undefined) &&
            <div className="RowGroup-ColumnHeaders-Container" style={{paddingLeft: contentPadding, paddingRight: contentPadding, height: columnHeaderContentHeight ?? 0}}>
              <div className="RowGroup-ColumnHeaders" ref={colummHeadersRef}>
                {Object.entries(columnHeaders).map(([columnKey, props]) => {
                  return <RowGroupHeaderColumn key={columnKey} columnKey={columnKey} columnSizes={columnSizes} props={props} columnHeaderContentHeight={columnHeaderContentHeight} columnHeightSizeChanged={(height) => {
                    if (height > columnHeaderContentHeight)  {
                      SetColumnHeaderContentHeight(height)
                    }
                  }}/>
                })}
              </div>
            </div>
          }
          {isExpandable && 
            <div className="RowGroup-Items-Container RowGroup-Items-Container-Expandable"
              style={{padding: "3px " + contentPadding + "px", ...rowGroupItemsContainerStyle, ...expandedItemsStyle}}
              onTransitionEnd={expandedStateTransitionCompleted}
              ref={rowGroupItemsContainerHandleRef}>
              <div className="RowGroup-Items" ref={rowGroupItemsHandleRef} {...rowGroupItemsProps}>
                {hasColumnHeaders && <>
                  {children}
                </>}
                {!hasColumnHeaders &&
                  <div className="RowGroup-Items-Content">
                    {children}
                  </div>              
                }
              </div>
            </div>
          }
          {!isExpandable &&
            <div className="RowGroup-Items-Container" style={{padding: "3px " + contentPadding + "px", ...rowGroupItemsContainerStyle}} ref={rowGroupItemsContainerHandleRef}>
              <div className="RowGroup-Items" ref={rowGroupItemsHandleRef} {...rowGroupItemsProps}>
                {hasColumnHeaders && <>
                  {children}
                </>}
                {!hasColumnHeaders &&
                  <div className="RowGroup-Items-Content">
                    {children}
                  </div>              
                }
              </div>
            </div>
          }
        </div>

        {footerShouldShow && 
          <div className="RowGroup-Footer">
            <div className="RowGroup-Footer-Content">
              
              {isExpandable && 
                <div className="RowGroup-ExpandedStateToggle" onClick={() => toggleExpandedState(!isExpanded)}>
                  <div className="RowGroup-ExpandedStateToggle-Label noselect">
                    {isExpanded && collapseCellContent}
                    {!isExpanded && expandCellContent}
                  </div>
                </div>
              }

            </div>
          </div>
        }
      </div>
    </div>
  </>)
} 

RowGroup.defaultProps = {
  title: "",
  titleFontSize: 24,
  hasGroupHeader: true,
  verticalFill: false,
  isExpandable: false,
  expandedState: false,
  hasSubGroups: false,
  hasSettings: false,
  hasContainer: true,
  containerHasFill: true,
  containerHasBorder: true,
  settingsOptions: [],
  itemsScroll: false,
  customContentPadding: false,
  itemContentBackgroundColor: "",
  itemContentPadding: 0,
  expandCellContent: <>show items</>,
  collapseCellContent: <>hide items</>,
  hasColumnHeaders: false,

}

export default RowGroup




const RowGroupHeaderColumn = ({columnKey, columnSizes, props, columnHeaderContentHeight, columnHeightSizeChanged}) => {
  const containerProps = {style:{left:0}}
  const [contentHandleRef, {width: columnContentWidth, height: columnContentHeight}] = useMeasure()
  React.useLayoutEffect(() => {
    if (columnContentHeight > columnHeaderContentHeight)  {
      columnHeightSizeChanged(columnContentHeight)
    }
  }, [columnContentHeight, columnHeaderContentHeight])

  if (props.setPosition !== undefined && columnSizes[columnKey] !== undefined)  {
    containerProps.style = props.setPosition(columnSizes[columnKey], columnContentWidth)
  }
  return (<div className="RowGroup-ColumnHeader-Container" {...containerProps}>
    <div className="RowGroup-ColumnHeader" ref={contentHandleRef}>
      <div className="RowGroup-ColumnHeader-Content">
        {props.label}
      </div>
    </div>
  </div>)
} 
