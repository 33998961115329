import './SettingsPage.scss';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'


const Settings_BillingPage = () => {
  return (
    <div id="Settings_BillingPage">
        <div className="ControlBar_Horizontal">
        <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultOption="All Categories"/>
          <DropDownInput options={[{ value: 'status', label: 'Status' }]} defaultOption="Status"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultOption="Name" prefix="Sort By"/>
        </div>
    </div>
  )
} 

export default Settings_BillingPage