import './GrowZonesPage.scss';
import React from 'react'

import _ from 'underscore';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../redux/entities/Facilities';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, getBladeZoneConfigurationMap, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZoneConfigurationMaps, setBladeRuntimeProperty, setBladeZoneRuntimeProperty, MaintainBladeZoneStatus, MaintainBladeLiveData, selectBladeZoneDisplayNameByUID, selectAllBladeUIDs, getBladeByUID, MaintainBladeStatus } from '../../redux/entities/service/Blade';
import ControlBar from '../../components/ControlBar';
import GrowZoneDataAnalytics from './DataAnalytics';
import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import Button from '../../components/Button';
import RowGroup from '../../components/RowGroup/RowGroup';
import { AirSetpoint, LightingSetpoint, LowerWaterSetpoint, Pause, Play, PlusAlt, RSSI, UpperWaterSetpoint, WaterSetpoint } from '../../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../components/input/DropDownInput';
import TextInput from '../../components/input/TextInput';
import { FormatDate, useRenderingTrace } from '../../helpers';
import TaskPopup from '../Tasks/TaskPopup';
import GrowZonesPage_Zone from './GrowZonesPage_Zone';




const GrowZonesPage = ({selectedFacility}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  /* React useEffects */
  const dispatch = useDispatch()
  const navigate = useNavigate()


  /* Data */   
  const allFacilities = useSelector(selectAllFacilities)

  const allBladeZoneConfigurationMaps = useSelector(state => state.blade.zoneConfigurationMaps)

  const loadedBladeUIDs = useSelector((state) => selectAllBladeUIDs(state), _.isEqual)
  const loadedBladeZoneUIDs = useSelector(state => state.blade.loadedZoneUIDs, _.isEqual)

  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  



  /* State */
  const currentFacility = allFacilities.find((f) => f.id === selectedFacility)
  const [activeBladeUIDs, SetActiveBladeUIDs] = React.useState([])
  const [activeBladeZoneUIDs, SetActiveBladeZoneUIDs] = React.useState([])
  const [bladeGroups, SetBladeGroups] = React.useState([])




  /* Loading of data */
  React.useEffect(() => {
    let foundFacility = allFacilities.find((f) => f.id === selectedFacility)
    if (foundFacility !== undefined)  {
      if (!foundFacility.initialServicesLoaded)  {
        dispatch(getAllServicesForFacility({facilityId: foundFacility.id}))
      }
      //console.log(foundFacility.services)
    }
  }, [allFacilities, selectedFacility])

  React.useEffect(() => {
    if (currentFacility !== undefined && loadedInitialBladeGroups.indexOf(selectedFacility) === -1 && loadingGroupsStatus !== "pending") {
      dispatch(getAllBladeGroupsByFacilityId({facilityIds: [selectedFacility]}))
    }
  }, [loadingGroupsStatus, loadedInitialBladeGroups, currentFacility, selectedFacility])


  
  
  React.useEffect(() => {
    
    let bladesToLoad = []
    let currentActiveAndLoadedBladeUIDs = []

    let bladeZonesToLoad = []
    let currentActiveAndLoadedBladeZoneUIDs = []
    for (let group of allBladeGroups) {
      if (group.facility_id === selectedFacility) {
        for (let bladeUID of group.blades)  {
          if (loadedBladeUIDs.find((bUID) => bUID === bladeUID) === undefined) {
            bladesToLoad.push(bladeUID)
          }else {
            currentActiveAndLoadedBladeUIDs.push(bladeUID)
          }
        }
        for (let zoneUID of group.zones)  {
          if (loadedBladeZoneUIDs.find((zUID) => zUID === zoneUID) === undefined) {
            bladeZonesToLoad.push(zoneUID)
          }else {
            currentActiveAndLoadedBladeZoneUIDs.push(zoneUID)
          }
        }
      }
    }
    if (bladesToLoad.length > 0) {
      dispatch(getBladeByUID({bladeUIDs: bladesToLoad}))
    }
    SetActiveBladeUIDs(currentActiveAndLoadedBladeUIDs)

    
    if (bladeZonesToLoad.length > 0) {
      dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
    }
    SetActiveBladeZoneUIDs(currentActiveAndLoadedBladeZoneUIDs)

  }, [loadedBladeZoneUIDs, loadedBladeUIDs, allBladeGroups, selectedFacility])

  React.useEffect(() => {
    let activeBladeGroups = []
    for (let group of allBladeGroups) {
      if (group.facility_id === selectedFacility) {
        activeBladeGroups.push(group)
      }
    }
    SetBladeGroups(activeBladeGroups)
  }, [selectedFacility, allBladeGroups])
 
  

  /* Interaction */
  const [viewingTask, SetViewingTask] = React.useState(null)
  const onViewingTask = (taskId) => {
    SetViewingTask(taskId)
  }
  const closeViewingTask = () => {
    SetViewingTask(null)
  }

  

  
  //useRenderingTrace("ZonesPage", [currentFacility, bladeGroups, allFacilities, activeBladeUIDs, activeBladeZoneUIDs, loadedBladeZoneUIDs, allBladeGroups])
  //console.log("Rerender")


  return (<>
    <MaintainBladeStatus bladeUIDs={activeBladeUIDs}/>
    <MaintainBladeLiveData bladeUIDs={activeBladeUIDs}/>
    <MaintainBladeZoneStatus bladeZoneUIDs={activeBladeZoneUIDs}/>
    <div id="GrowZonesStatus">
      
      {bladeGroups.length === 0 && 
        <div className="GrowZonesStatus-NoGroupsContent">
          <span>No active zones</span>
        </div>
      }
      {bladeGroups.length > 0 &&
        <div className="GrowZonesStatus-VerticalRackGroups">
          {bladeGroups.map((bladeGroup) => {


            let otherGroupProps = {}
            if (bladeGroups.length === 1) {
              otherGroupProps.expandedState = true
            }
            return (

              <RowGroup
                key={bladeGroup.id}
                title={bladeGroup.display_name}
                hasSettings={false}
                isExpandable={true}
                expandCellContent={"Show " + Object.values(bladeGroup.zones).length + " Zones"}
                collapseCellContent={"Hide Zones"}
                hasColumnHeaders={false}
                {...otherGroupProps}>
               
                  {bladeGroup.zones.map((zoneUID) => {
                    if (loadedBladeZoneUIDs.indexOf(zoneUID) !== -1)  {

                      return <GrowZonesPage_Zone
                                key={zoneUID}
                                selectedFacility={selectedFacility} 
                                zoneUID={zoneUID} 
                                onViewingTask={onViewingTask}/>
                    }
                  })}

                </RowGroup>
              )
            
            })}
          </div>
        }
    </div>
    {viewingTask && <>
      <TaskPopup taskId={viewingTask} onCloseTask={closeViewingTask}/>
    </>}
  </>)
} 


export default GrowZonesPage









