import './BadgeTimeline.scss';


import Badge from './Badge.js'

const BadgeTimeline = ({steps, currentStep, onStepChanged}) => {


  return (
    <div className="BadgeTimeline">
      {Object.entries(steps).map(([key, step]) => {
        return (
          <BadgeTimelineItem key={key} stepKey={key} step={step} currentStep={currentStep}/>
        )
      })}
    </div>
  )
} 

BadgeTimeline.defaultProps = {
  steps: {},
  currentStep: undefined
}




const BadgeTimelineItem = ({stepKey, step, currentStep}) => {
    
      
  const stepClicked = (e) =>  {
    
  }

  return (
    <>
      <div className="BadgeTimeline-Badge">
        <Badge content={step.render} status={currentStep === stepKey ? "Active" : "Neutral"} onClick={stepClicked}/>
      </div>
    </>
  )
}


export default BadgeTimeline