import './GrowManagerPage.scss';
import variables from '../../globals.scss';
  
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';

import Badge from '../../components/Badge.js'

import GrowManagerChartsPage from './GrowManagerChartsPage';

import {useLocation} from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import GrowStatusList from './GrowStatusList';
import GrowSchedulingPage from './GrowSchedulingPage';
import GrowZonesPage from '../GrowZones/GrowZonesPage';
import { getAllFacilities, selectAllFacilities, selectAllFarmFacilities } from '../../redux/entities/Facilities';
import DropDownInput from '../../components/input/DropDownInput';
import { IsSessionActive } from '../Account/Common.js';





const GrowManagerPage = ({pageHeaderHelper}) => { 



  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const selectedTab = splitLocation.length >= 3 ? splitLocation[3] : null

  const { farmID } = useParams();
  const [selectedFacility, SetSelectedFacility] = React.useState("all");
  React.useLayoutEffect(() => {
    if (farmID !== undefined) {
      SetSelectedFacility(farmID === "all" ? farmID : parseInt(farmID))
    }
  }, [farmID])

  const facilities = useSelector(selectAllFarmFacilities)
  let haveInitialFacilitiesData = useSelector((state) => state.facilities.haveInitialData)

  const isSessionActive = useSelector(state => IsSessionActive(state))

  React.useEffect( () =>  { 
    if (isSessionActive && !haveInitialFacilitiesData) {
      dispatch(getAllFacilities({}))
    }
  }, [isSessionActive, facilities, haveInitialFacilitiesData])


  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    if (splitLocation.length <= 2 || Number.isNaN(splitLocation[2]))  {
      navigate("/growmanager/all/status")
    }else if (splitLocation[2] !== "all" && Number.isNaN(parseInt(splitLocation[2]))) {
      if (splitLocation.length <= 3) {
        navigate("/growmanager/all/status")
      }else {
        navigate("/growmanager/all/" + splitLocation[3])
      }
    }else {
      SetSelectedFacility(splitLocation[2] === "all" ? splitLocation[2] : parseInt(splitLocation[2]))
    }
  }
  React.useEffect(() => {
    validateLocation()
  }, [location])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])


  









  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  const selectedFacilityChanged = (value) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[2] = value
    navigate(splitLocation.join("/"))
    //SetIsAllSelected(value === "all")
    //SetSelectedFacility(value)
  }
  
  React.useEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = [{value: "all", label: "All Farms"}]
    for (let facility of facilities)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }
   

    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [facilities, location])




  



  const managerTabs = [new TabControlTab("Status", "status", true),
    new TabControlTab("Planning", "planning"),
    new TabControlTab("KPI", "kpi"),
    new TabControlTab("Charts", "charts"),
    /*new TabControlTab("Grow Zones", "zones")*/];
  const managerTabControlTabClickedRef = React.useRef(null);
  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Grow Manager")
    
    pageHeaderHelper.current.SetTabControlTabs(managerTabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (managerTabControlTabClickedRef.current) {
        managerTabControlTabClickedRef.current(tab);
      }
    });
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <DropDownInput placeholder={"Select Facility"} options={facilitySelectionOptions} value={selectedFacility} onSelectionChange={selectedFacilityChanged}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
  }
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])
  React.useEffect(() => {
    setPageHeader()
  }, [facilitySelectionOptions])




  return (
    <div className="PageWrapper_Standard">
      <TabControl 
        activePathIndex={3}
        tabs={managerTabs}
        tabClickedRef={(ref) => {managerTabControlTabClickedRef.current=ref;}}
        hideTabs={true}
        content={
          <div id="GrowManager">
            


            <div id="GrowManager_Content">
          


              <div id="GrowManager_TabContent">
                <Routes>
                  <Route path="/status" element={<GrowStatusList selectedFacility={selectedFacility}/>}/>
                  <Route exact path="/planning" element={ <GrowSchedulingPage/> }/>
                  <Route path="/kpi" element={
                    <div>
                      KPI
                    </div>
                  }/>
                  <Route path="/charts" element={<GrowManagerChartsPage pageHeaderHelper={pageHeaderHelper}/>}/>
                  {/*<Route path="/zones" element={ <GrowZonesPage pageHeaderHelper={pageHeaderHelper} selectedFacility={selectedFacility}/> }/>*/}

                  <Route path="*" element={<Navigate to="status" replace />} />
                </Routes>
              </div>
            </div>
          </div>
        }/>
    </div>
  )
}

export default GrowManagerPage 