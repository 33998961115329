
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
  

const CreateGrowPlan_SelectSeedlingRaftTypeWalkthroughStep = ({
        initialSelectedSeedlingRaftType,
        seedlingRaftTypeConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()

    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    /*const haveAllRaftTypes = useSelector(state => state.raftTypes.haveAllRaftTypes)
    React.useEffect(() => {
        if (!haveAllRaftTypes)  {
            dispatch(getAllRaftTypes({}))
        }
    }, [haveAllRaftTypes])*/

    const [selectedSeedlingRaftType, SetSelectedSeedlingRaftType] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedSeedlingRaftType !== undefined)  {
            SetSelectedSeedlingRaftType(initialSelectedSeedlingRaftType)
        }
    }, [initialSelectedSeedlingRaftType])
    const seedlingRaftTypeSelected = (seedlingRaftType) => {
        SetSelectedSeedlingRaftType(seedlingRaftType)
    }





    const backClicked = () => {
        transitionToPreviousStep(["select_seed", null])
    }
    const nextClicked = () =>   {
        seedlingRaftTypeConfirmedCallback({seedlingRaftType: selectedSeedlingRaftType})
        transitionToNextStep(["select_seeding_method", null])
    }
 

    let seedlingRaftOptions = []
    for (let raft of allRaftTypes.filter((rT) => rT.application_type === "seedling"))   {
        seedlingRaftOptions.push({value: raft.id, label: raft.name})
    }
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-10">
                        <div className="Text-S14">Seedling Raft Type</div>
                        <DropDownInput placeholder={"Select Raft Type"} options={seedlingRaftOptions} value={selectedSeedlingRaftType} onSelectionChange={seedlingRaftTypeSelected}/>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedSeedlingRaftType === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectSeedlingRaftTypeWalkthroughStep
