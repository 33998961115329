import './HVACsPage.scss';
import variables from '../../../globals.scss';
import React from 'react'


import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ControlBar from '../../../components/ControlBar';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import TextInput from '../../../components/input/TextInput';
import ToggleCellButton from '../../../components/ToggleCellButton';
import { AddItem, BadX, GoodCheckmark, Pause, Play, PlusAlt, RSSI, ReorderItem, SoftWarning, Trash } from '../../../assets/icons/Icons';
import BasicAlert from '../../../components/BasicAlert';
import { selectAllHVAC } from '../../../redux/entities/service/HVAC';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';






const HVACsPage = ({selectedFacility, setRightSideContent}) => {
  


  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  const facilityInfo = useSelector(state => state.facilities.facilities.find((f) => f.id === selectedFacility))
  
  const allHVACs = useSelector(selectAllHVAC)

  const [HVACs, SetHVACs] = React.useState([])
  React.useEffect(() => {
    SetHVACs(allHVACs.filter((vRG) => facilityInfo !== undefined && facilityInfo.services.find((s) => s.id === vRG.service_id) !== undefined))
  }, [selectedFacility, allHVACs])
  
  //const verticalRackConfigurationMaps = useSelector(selectAllVerticalRackConfigurationMaps)



  
  const updateHeader = React.useCallback(() =>  {
    if (setRightSideContent === undefined)
      return
    setRightSideContent(
      <>
        {HVACs.length > 0 && 
          <Button content="Add HVAC" status="Primary" onClick={addNewHVAC}/>  
        }
      </>
    )
  })

  React.useLayoutEffect(() => {
    updateHeader()
  }, [])
  React.useLayoutEffect(() => {
    updateHeader()
  }, [HVACs])


  
  
  
  const [addingNewHVAC, SetAddingNewHVAC] = React.useState(false)
  const addNewHVAC = React.useCallback(() => {
    SetAddingNewHVAC(true)
  })

  

  const addHVACWalkthroughCloseCallback = React.useCallback(() => {
    SetAddingNewHVAC(false)  
  })



  




  ///const HVACEditing = React.useState(null);

  return (<>
    <div id="HVACStatus">
      
      {HVACs.length === 0 && 
        <div className="HVACStatus-NoGroupsContent">
          <span>No active HVAC</span>
        </div>
      }
      {HVACs.length > 0 &&
        <div className="HVACStatus-HVACs">
          <RowGroup
            hasSettings={false}
            hasGroupHeader={false}
            hasColumnHeaders={false}
            verticalFill={true}
            >
            {HVACs.map((HVAC) => {

              const isHVACOnline = (HVAC.control_device !== undefined && HVAC.control_device !== null && HVAC.control_device.connected)
              const isHVACPaused = false
              const zoneStatus = "Idle"

              let subtitle = <>{zoneStatus}</>

              let additionalFunctions = [
                {key: "manage_lights", label: "Manage Lights"},
                {key: "rename", label: "Rename Rack"},
                {key: "move", label: "Move Rack"},
                {key: "change_sides", label: "Change Sides"},
                {key: "light_up", label: "Light Up Rack"},
                {key: "delete", label: "Delete", status: "Critical"},
              ]
              if (isMobile) {
                additionalFunctions.splice(2, 0, 
                  {key: "pause_rack", label: "Pause Rack"}  
                );
              }

              let secondaryHeaderContent = null
              if (!isMobile)  {
                secondaryHeaderContent = [
                  {key: "pause_state", content: 
                    <div className="FlexContent-H-10">
                      {isHVACPaused && 
                        <div className="FlexContent-H-20 FlexContent-Center">
                          <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Paused 16:34</span>

                          <div className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-AddTimeButton"><span>Add Time</span></div>
                        </div>
                      }
                      <div className="FlexContent-H-10">
                        {isHVACPaused && <>
                          <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
                        </>}
                        {!isHVACPaused &&
                          <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
                        }
                      </div>
                    </div>
                  },
                  {key: "rssi", content: <RSSI/>}
                ]
              }else {
                secondaryHeaderContent = <><RSSI/></>
              }

              let extendedHeaderContent = null
              if (isMobile && isHVACPaused) {
                extendedHeaderContent = {content: <>
                  <div className="FlexContent-H-10">
                    <div className="FlexContent-H-10 FlexContent-Center">
                      <div className="HVACStatus-VerticalRackGroup-Zone-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt/></div>
                    </div>
                    <div className="FlexContent-H-10">
                      <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
                    </div>
                  </div>
                </>}
              }else {

              }


              return (<>
                <RowGroupRow
                  key={HVAC.id}
                  title={HVAC.display_name}
                  hasFlexCell={false}
                  subtitle={<div className="HVACStatus-HVACs">{subtitle}</div>}
                  showStatusIndicator={true}
                  status={isHVACOnline ? "Connected" : "Not Connected"}
                  statusColor={isHVACOnline ? "#4DBE3B" : "#f00"}
                  hasIssues={true}
                  numberOfIssues={0}
                  isExpandable={true}
                  expandedState={HVACs.length === 1}
                  hasAdditionalFunctions={true}
                  secondaryContent={secondaryHeaderContent}
                  extendedHeaderContent={extendedHeaderContent}
                  additionalFunctions={additionalFunctions}
                  content={<>
                    MORE STUFF
                  </>}/>
                </>)
              })}

            </RowGroup>
          </div>
            
        }
    </div>
    {/*addingNewHVAC && <AddHVACWalkthrough facilityId={selectedFacility} closeCallback={addHVACWalkthroughCloseCallback}/>*/}
  </>)
} 

export default HVACsPage