import React from 'react'
import './RackIcons.scss';

export function ScanRack({width}) {
  
  return (
   <svg className="ScanRackIcon" width={width} viewBox="0 0 221 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 2400" clip-path="url(#clip0_164_29613)">
    <g id="scan_rack_graphic">
    <g id="Group 1468">
    <path id="Vector 228" d="M32.9031 119.267V10.0947L193.296 0L200.774 3.73877V112.163L40.0067 122.632L32.9031 119.267Z" fill="white"/>
    <g id="Group 1438">
    <g id="Group 1437">
    <circle id="Ellipse 575" cx="41.9955" cy="113.022" r="0.519627" fill="black"/>
    <circle id="Ellipse 576" cx="41.9955" cy="83.922" r="0.519627" fill="black"/>
    <g id="Group 1395">
    <path id="Vector 218" d="M197.105 14.291L64.0803 22.8649V115.358" stroke="#2E72D2"/>
    <path id="Vector 216" d="M32.9036 10.1347L40.4382 13.5123M32.9036 10.1347L193.468 0.00195312L201.003 3.37953M32.9036 10.1347V20.5272M40.4382 13.5123L201.003 3.37953M40.4382 13.5123L40.4122 24.4244M40.1783 122.634L32.9036 119.256V113.54M40.1783 122.634L40.192 116.918M40.1783 122.634L201.003 112.241V106.785M32.9036 113.54L40.192 116.918M32.9036 113.54V20.5272M40.192 116.918L64.0812 115.413M40.192 116.918L40.2786 80.5441M201.003 3.37953V14.0319M201.003 106.785V14.0319M201.003 106.785L197.106 107.031M64.0812 115.413L197.106 107.031M64.0812 115.413V114.06M32.9036 20.5272L40.4122 24.4244M40.4122 24.4244L64.0812 22.8927M40.4122 24.4244L40.2786 80.5441M201.003 14.0319L197.106 14.2841M64.0812 22.8927L85.1261 21.5308M64.0812 22.8927V38.1945M40.2786 80.5441L64.0812 78.9853M64.0812 78.9853V68.3329M64.0812 78.9853V83.6619M197.106 14.2841V107.031M197.106 14.2841L195.547 14.385M197.106 107.031L195.287 106.006L195.331 90.4171M195.547 14.385L173.593 15.8057M195.547 14.385L195.503 29.8805M194.508 105.746L173.073 107.112M64.0812 114.06L85.1261 112.719M64.0812 114.06V98.7311M85.1261 112.719V21.5308M85.1261 112.719L107.21 111.311M85.1261 21.5308L107.21 20.1016M107.21 20.1016V111.311M107.21 20.1016L129.294 18.6725M107.21 111.311L129.294 109.903M129.294 109.903V18.6725M129.294 109.903L151.638 108.479M129.294 18.6725L151.638 17.2265M151.638 17.2265V108.479M151.638 17.2265L173.593 15.8057M151.638 108.479L173.073 107.112M173.073 107.112L173.593 15.8057M195.503 29.8805L64.0812 38.1945M195.503 29.8805L195.46 44.9497M64.0812 38.1945V53.2637M64.0812 53.2637L195.46 44.9497M64.0812 53.2637V68.3329M195.46 44.9497L195.417 60.0189M195.417 60.0189L64.0812 68.3329M195.417 60.0189L195.374 75.3479M195.374 75.3479L64.0812 83.6619M195.374 75.3479L195.331 90.4171M64.0812 83.6619V98.7311M195.331 90.4171L64.0812 98.7311" stroke="#2E72D2" stroke-width="0.5"/>
    <path id="highlight_indicator" d="M32.9036 10.1347V119.256L40.1783 122.634L201.003 112.241V3.37953L193.468 0.00195312L32.9036 10.1347Z" stroke="#2E72D2" stroke-width="1.5"/>
    </g>
    </g>
    </g>
    </g>
    <path id="Rectangle 1994" d="M34.8235 43.8945L38.6643 45.3478V51.5762L34.8235 50.1229V43.8945Z" fill="#2E72D2"/>
    <path id="Rectangle 2019" d="M34.8235 51.0273L38.6643 52.4056V52.9478L34.8235 51.5695V51.0273Z" fill="#DDE3EB"/>
    <path id="Vector 228_2" opacity="0.4" d="M33.7128 44.5156L22.0068 54.4327L35.7553 52.1165C37.1942 51.8741 38.4323 50.9625 39.091 49.6604L39.4165 49.017C40.209 47.4503 39.7936 45.5424 38.4215 44.4469C37.0371 43.3418 35.0644 43.3706 33.7128 44.5156Z" fill="#2E72D2"/>
    <path id="phone_scanning" d="M22.6756 44.7263L27.6756 47.2488C28.1812 47.5039 28.5 48.0218 28.5 48.588V63.5673C28.5 64.6847 27.322 65.4098 26.3244 64.9065L21.3244 62.384C20.8188 62.1289 20.5 61.611 20.5 61.0448V46.0655C20.5 44.9481 21.678 44.223 22.6756 44.7263Z" fill="white" stroke="#2E72D2"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_164_29613">
    <rect width="221" height="123" fill="white"/>
    </clipPath>
    </defs>
   </svg>
   
  )
}
ScanRack.defaultProps = {
  width: "auto"
}


export function RackLoading({width})  {
  return(
    <svg className="RackLoadingIcon" width={width} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1534">
      <g id="Group 1532">
      <path id="Vector 228" d="M1.09727 160.5V14.5L215.597 1L225.597 6V151L10.5973 165L1.09727 160.5Z" fill="white"/>
      <g id="Group 1438">
      <g id="Group 1437">
      <circle id="Ellipse 575" cx="13.2572" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="13.2572" cy="113.228" r="0.694916" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M220.691 20.1094L42.7924 31.5755V155.271" stroke="#2E72D2"/>
      <path id="Vector 216" d="M1.09727 14.5509L11.1736 19.0678M1.09727 14.5509L215.826 1L225.903 5.51696M1.09727 14.5509V28.4492M11.1736 19.0678L225.903 5.51696M11.1736 19.0678L11.1388 33.6611M10.8261 165L1.09727 160.483V152.839M10.8261 165L10.8443 157.356M10.8261 165L225.903 151.102V143.805M1.09727 152.839L10.8443 157.356M1.09727 152.839V28.4492M10.8443 157.356L42.7923 155.343M10.8443 157.356L10.9601 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7923 155.343L220.691 144.134M42.7923 155.343V153.534M1.09727 28.4492L11.1388 33.6611M11.1388 33.6611L42.7923 31.6126M11.1388 33.6611L10.9601 108.712M225.903 19.7627L220.691 20.1M42.7923 31.6126L70.9364 29.7913M42.7923 31.6126V52.0764M10.9601 108.712L42.7923 106.627M42.7923 106.627V92.3815M42.7923 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.547 40.9577M217.216 142.415L188.551 144.243M42.7923 153.534L70.9364 151.74M42.7923 153.534V133.034M70.9364 151.74V29.7913M70.9364 151.74L100.47 149.857M70.9364 29.7913L100.47 27.88M100.47 27.88V149.857M100.47 27.88L130.004 25.9688M100.47 149.857L130.004 147.975M130.004 147.975V25.9688M130.004 147.975L159.886 146.07M130.004 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.547 40.9577L42.7923 52.0764M218.547 40.9577L218.49 61.1103M42.7923 52.0764V72.2289M42.7923 72.2289L218.49 61.1103M42.7923 72.2289V92.3815M218.49 61.1103L218.433 81.2628M218.433 81.2628L42.7923 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7923 112.882M218.375 101.763L218.318 121.915M42.7923 112.882V133.034M218.318 121.915L42.7923 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M1.09727 14.5509V160.483L10.8261 165L225.903 151.102V5.51696L215.826 1L1.09727 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      </g>
      </g>
      <g id="loading_dots">
      <circle id="dot_1" cx="84.0973" cy="88" r="10" fill="#2E72D2"/>
      <circle id="dot_2" cx="124.097" cy="88" r="10" fill="#2E72D2"/>
      <circle id="dot_3" cx="164.097" cy="88" r="10" fill="#2E72D2"/>
      </g>
      </g>
    </svg>
  )
}
RackLoading.defaultProps = {
  width: 227
}


export function RackConnectionFailed({width})  {
  return(
    <svg className="RackConnectionFailed" width={width} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1536">
      <g id="Group 1535">
      <path id="Vector 228" d="M1.09729 160.5V14.5L215.597 1L225.597 6V151L10.5973 165L1.09729 160.5Z" fill="white"/>
      <g id="Group 1438">
      <g id="Group 1437">
      <circle id="Ellipse 575" cx="13.2572" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="13.2572" cy="113.228" r="0.694916" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M220.691 20.1094L42.7924 31.5755V155.271" stroke="#2E72D2"/>
      <path id="Vector 216" d="M1.09729 14.5509L11.1736 19.0678M1.09729 14.5509L215.826 1L225.903 5.51696M1.09729 14.5509V28.4492M11.1736 19.0678L225.903 5.51696M11.1736 19.0678L11.1388 33.6611M10.8261 165L1.09729 160.483V152.839M10.8261 165L10.8443 157.356M10.8261 165L225.903 151.102V143.805M1.09729 152.839L10.8443 157.356M1.09729 152.839V28.4492M10.8443 157.356L42.7923 155.343M10.8443 157.356L10.9601 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7923 155.343L220.691 144.134M42.7923 155.343V153.534M1.09729 28.4492L11.1388 33.6611M11.1388 33.6611L42.7923 31.6126M11.1388 33.6611L10.9601 108.712M225.903 19.7627L220.691 20.1M42.7923 31.6126L70.9364 29.7913M42.7923 31.6126V52.0764M10.9601 108.712L42.7923 106.627M42.7923 106.627V92.3815M42.7923 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.547 40.9577M217.216 142.415L188.551 144.243M42.7923 153.534L70.9364 151.74M42.7923 153.534V133.034M70.9364 151.74V29.7913M70.9364 151.74L100.47 149.857M70.9364 29.7913L100.47 27.88M100.47 27.88V149.857M100.47 27.88L130.004 25.9688M100.47 149.857L130.004 147.975M130.004 147.975V25.9688M130.004 147.975L159.886 146.07M130.004 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.547 40.9577L42.7923 52.0764M218.547 40.9577L218.49 61.1103M42.7923 52.0764V72.2289M42.7923 72.2289L218.49 61.1103M42.7923 72.2289V92.3815M218.49 61.1103L218.433 81.2628M218.433 81.2628L42.7923 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7923 112.882M218.375 101.763L218.318 121.915M42.7923 112.882V133.034M218.318 121.915L42.7923 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M1.09729 14.5509V160.483L10.8261 165L225.903 151.102V5.51696L215.826 1L1.09729 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      </g>
      </g>
      <g id="Red X">
      <circle id="Ellipse 577" cx="126.597" cy="86.5" r="39.5" fill="#EB3223"/>
      <path id="Vector 251" d="M108.097 68L145.097 105M108.097 105L145.097 68" stroke="white" stroke-width="8"/>
      </g>
      </g>
    </svg>
  )
}
RackConnectionFailed.defaultProps = {
  width: 227
}

 

export function RackConnectionSuccess({width, maxWidth})  {
  return(
    <svg className="RackConnectionSuccess" width={width} maxWidth={maxWidth} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 160.5V14.5L215.5 1L225.5 6V151L10.5 165L1 160.5Z" fill="white"/>
      <circle cx="13.1598" cy="152.144" r="0.694916" fill="#2E72D2"/>
      <circle cx="13.1598" cy="113.229" r="0.694916" fill="#2E72D2"/>
      <path d="M220.594 20.1104L42.6953 31.5765V155.272" stroke="#2E72D2"/>
      <path d="M1 14.5509L11.0763 19.0678M1 14.5509L215.729 1L225.805 5.51696M1 14.5509V28.4492M11.0763 19.0678L225.805 5.51696M11.0763 19.0678L11.0415 33.6611M10.7288 165L1 160.483V152.839M10.7288 165L10.747 157.356M10.7288 165L225.805 151.102V143.805M1 152.839L10.747 157.356M1 152.839V28.4492M10.747 157.356L42.695 155.343M10.747 157.356L10.8628 108.712M225.805 5.51696V19.7627M225.805 143.805V19.7627M225.805 143.805L220.594 144.134M42.695 155.343L220.594 144.134M42.695 155.343V153.534M1 28.4492L11.0415 33.6611M11.0415 33.6611L42.695 31.6126M11.0415 33.6611L10.8628 108.712M225.805 19.7627L220.594 20.1M42.695 31.6126L70.8391 29.7913M42.695 31.6126V52.0764M10.8628 108.712L42.695 106.627M42.695 106.627V92.3815M42.695 106.627V112.882M220.594 20.1V144.134M220.594 20.1L218.509 20.2349M220.594 144.134L218.161 142.763L218.22 121.915M218.509 20.2349L189.149 22.135M218.509 20.2349L218.45 40.9577M217.119 142.415L188.454 144.243M42.695 153.534L70.8391 151.74M42.695 153.534V133.034M70.8391 151.74V29.7913M70.8391 151.74L100.373 149.857M70.8391 29.7913L100.373 27.88M100.373 27.88V149.857M100.373 27.88L129.907 25.9688M100.373 149.857L129.907 147.975M129.907 147.975V25.9688M129.907 147.975L159.788 146.07M129.907 25.9688L159.788 24.035M159.788 24.035V146.07M159.788 24.035L189.149 22.135M159.788 146.07L188.454 144.243M188.454 144.243L189.149 22.135M218.45 40.9577L42.695 52.0764M218.45 40.9577L218.393 61.1103M42.695 52.0764V72.2289M42.695 72.2289L218.393 61.1103M42.695 72.2289V92.3815M218.393 61.1103L218.336 81.2628M218.336 81.2628L42.695 92.3815M218.336 81.2628L218.278 101.763M218.278 101.763L42.695 112.882M218.278 101.763L218.22 121.915M42.695 112.882V133.034M218.22 121.915L42.695 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path d="M1 14.5509V160.483L10.7288 165L225.805 151.102V5.51696L215.729 1L1 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
      <circle cx="125.5" cy="82.5" r="39.5" fill="#2ED25C"/>
      <path d="M107.742 84.2726L117.662 96.6331L143.256 64.7432" stroke="white" stroke-width="8"/>
    </svg> 
  )
}
RackConnectionSuccess.defaultProps = {
  width: 227,
  maxWidth: 227,
}


export function RackStandardGrowOut({width, maxWidth})  {
  return (
    <svg id="RackStandardGrowOut" width={width} style={{maxWidth:maxWidth}} viewBox="0 0 227 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.09766 160.5V14.5L215.598 1L225.598 6V151L10.5977 165L1.09766 160.5Z" fill="white" stroke="#2E72D2"/>
      <circle cx="13.2574" cy="152.144" r="0.5" fill="black" stroke="#2E72D2" stroke-width="0.389833"/>
      <circle cx="13.2574" cy="113.229" r="0.5" fill="black" stroke="#2E72D2" stroke-width="0.389833"/>
      <path d="M220.692 20.1104L42.793 31.5765V155.272" stroke="#2E72D2"/>
      <path d="M1.09766 14.5509L11.1739 19.0678M1.09766 14.5509L215.827 1L225.903 5.51696M1.09766 14.5509V28.4492M11.1739 19.0678L225.903 5.51696M11.1739 19.0678L11.1392 33.6611M10.8265 165L1.09766 160.483V152.839M10.8265 165L10.8447 157.356M10.8265 165L225.903 151.102V143.805M1.09766 152.839L10.8447 157.356M1.09766 152.839V28.4492M10.8447 157.356L42.7926 155.343M10.8447 157.356L10.9605 108.712M225.903 5.51696V19.7627M225.903 143.805V19.7627M225.903 143.805L220.691 144.134M42.7926 155.343L220.691 144.134M42.7926 155.343V153.534M1.09766 28.4492L11.1392 33.6611M11.1392 33.6611L42.7926 31.6126M11.1392 33.6611L10.9605 108.712M225.903 19.7627L220.691 20.1M42.7926 31.6126L70.9368 29.7913M42.7926 31.6126V52.0764M10.9605 108.712L42.7926 106.627M42.7926 106.627V92.3815M42.7926 106.627V112.882M220.691 20.1V144.134M220.691 20.1L218.606 20.2349M220.691 144.134L218.259 142.763L218.318 121.915M218.606 20.2349L189.246 22.135M218.606 20.2349L218.548 40.9577M217.217 142.415L188.551 144.243M42.7926 153.534L70.9368 151.74M42.7926 153.534V133.034M70.9368 151.74V29.7913M70.9368 151.74L100.471 149.857M70.9368 29.7913L100.471 27.88M100.471 27.88V149.857M100.471 27.88L130.005 25.9688M100.471 149.857L130.005 147.975M130.005 147.975V25.9688M130.005 147.975L159.886 146.07M130.005 25.9688L159.886 24.035M159.886 24.035V146.07M159.886 24.035L189.246 22.135M159.886 146.07L188.551 144.243M188.551 144.243L189.246 22.135M218.548 40.9577L42.7926 52.0764M218.548 40.9577L218.491 61.1103M42.7926 52.0764V72.2289M42.7926 72.2289L218.491 61.1103M42.7926 72.2289V92.3815M218.491 61.1103L218.433 81.2628M218.433 81.2628L42.7926 92.3815M218.433 81.2628L218.375 101.763M218.375 101.763L42.7926 112.882M218.375 101.763L218.318 121.915M42.7926 112.882V133.034M218.318 121.915L42.7926 133.034" stroke="#2E72D2" stroke-width="0.5"/>
      <path d="M1.09766 14.5509V160.483L10.8265 165L225.903 151.102V5.51696L215.827 1L1.09766 14.5509Z" stroke="#2E72D2" stroke-width="1.5"/>
    </svg> 
  )
}
RackStandardGrowOut.defaultProps = {
  width: 227,
  maxWidth: 227,
}


export function RackStandardEnvironment({width, maxWidth})  {
  return (
    <svg id="RackStandardEnvironment" maxWidth={maxWidth} width={width} viewBox="0 0 199 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1509">
      <path id="Vector 228" d="M196.539 139.924V12.7585L9.70966 1L0.999664 5.355V131.65L188.265 143.844L196.539 139.924Z" fill="white"/>
      <g id="Group 1468">
      <circle id="Ellipse 575" cx="0.607419" cy="0.607419" r="0.607419" transform="matrix(-1 0 0 1 187.479 132.506)" fill="#2E72D2"/>
      <circle id="Ellipse 576" cx="0.607419" cy="0.607419" r="0.607419" transform="matrix(-1 0 0 1 187.479 98.4902)" fill="#2E72D2"/>
      <g id="Group 1395">
      <path id="Vector 218" d="M5.55547 17.7031L161.055 27.7255V135.846" stroke="#2E72D2"/>
      <path id="Vector 216" d="M197.5 12.8447L188.692 16.7929M197.5 12.8447L9.80759 1L1 4.94822M197.5 12.8447V24.993M188.692 16.7929L1 4.94822M188.692 16.7929L188.723 29.5487M188.996 144.351L197.5 140.403V133.721M188.996 144.351L188.98 137.669M188.996 144.351L1 132.202V125.825M197.5 133.721L188.98 137.669M197.5 133.721V24.993M188.98 137.669L161.055 135.91M188.98 137.669L188.879 95.1499M1 4.94822V17.4003M1 125.825V17.4003M1 125.825L5.55565 126.112M161.055 135.91L5.55565 126.112M161.055 135.91V134.328M197.5 24.993L188.723 29.5487M188.723 29.5487L161.055 27.7582M188.723 29.5487L188.879 95.1499M1 17.4003L5.55565 17.6951M161.055 27.7582L136.454 26.1662L110.639 24.4955L84.8239 22.8249L58.7048 21.1346L33.0414 19.4738L7.3779 17.8131M161.055 27.7582V45.6453V63.2604V80.8756V93.3277M188.879 95.1499L161.055 93.3277M161.055 93.3277V98.7944V116.41V134.328M5.55565 17.6951V126.112M5.55565 17.6951L7.3779 17.8131M5.55565 126.112L7.68161 124.913L7.62996 106.691L7.58 89.0757L7.52919 71.1569L7.47923 53.5417L7.42928 35.9266L7.3779 17.8131M8.59274 124.61L33.6488 126.207L58.7048 127.804L84.8239 129.469L110.639 131.115L136.454 132.76L161.055 134.328" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 217" d="M197.5 12.8447V140.403L188.996 144.351L1 132.202V4.94822L9.80759 1L197.5 12.8447Z" stroke="#2E72D2" stroke-width="1.5"/>
      </g>
      </g>
      <path id="Rectangle 2033" d="M58 28L57 28.3784V30L58 29.6216V28Z" fill="#2E72D2"/>
      <path id="Rectangle 2034" d="M109 32L108 32.3784V34L109 33.6216V32Z" fill="#2E72D2"/>
      <path id="Rectangle 2035" d="M160 36L159 36.3784V38L160 37.6216V36Z" fill="#2E72D2"/>
      <path id="Rectangle 2036" d="M58 42L57 42.3784V44L58 43.6216V42Z" fill="#2E72D2"/>
      <path id="Rectangle 2037" d="M109 46L108 46.3784V48L109 47.6216V46Z" fill="#2E72D2"/>
      <path id="Rectangle 2038" d="M160 50L159 50.3784V52L160 51.6216V50Z" fill="#2E72D2"/>
      <path id="Rectangle 2039" d="M58 58L57 58.3784V60L58 59.6216V58Z" fill="#2E72D2"/>
      <path id="Rectangle 2040" d="M109 62L108 62.3784V64L109 63.6216V62Z" fill="#2E72D2"/>
      <path id="Rectangle 2041" d="M160 66L159 66.3784V68L160 67.6216V66Z" fill="#2E72D2"/>
      <path id="Rectangle 2042" d="M58 72L57 72.3784V74L58 73.6216V72Z" fill="#2E72D2"/>
      <path id="Rectangle 2043" d="M109 76L108 76.3784V78L109 77.6216V76Z" fill="#2E72D2"/>
      <path id="Rectangle 2044" d="M160 80L159 80.3784V82L160 81.6216V80Z" fill="#2E72D2"/>
      <path id="Rectangle 2045" d="M58 86L57 86.3784V88L58 87.6216V86Z" fill="#2E72D2"/>
      <path id="Rectangle 2046" d="M109 90L108 90.3784V92L109 91.6216V90Z" fill="#2E72D2"/>
      <path id="Rectangle 2047" d="M160 94L159 94.3784V96L160 95.6216V94Z" fill="#2E72D2"/>
      <path id="Rectangle 2048" d="M58 102L57 102.378V104L58 103.622V102Z" fill="#2E72D2"/>
      <path id="Rectangle 2049" d="M109 106L108 106.378V108L109 107.622V106Z" fill="#2E72D2"/>
      <path id="Rectangle 2050" d="M160 110L159 110.378V112L160 111.622V110Z" fill="#2E72D2"/>
      <g id="Group 1469">
      <path id="Vector 222" d="M10 24.5L56 27.5M10 24.5V29L56 32M10 24.5L14.5 24L59 27M56 27.5V32M56 27.5L59 27M56 32L59 30V27" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223" d="M54.5 28.5L11 25.5V28L54.5 31V28.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1470">
      <path id="Vector 222_2" d="M10 68.5L56 71.5M10 68.5V73L56 76M10 68.5L14.5 68L59 71M56 71.5V76M56 71.5L59 71M56 76L59 74V71" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_2" d="M54.5 72.5L11 69.5V72L54.5 75V72.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1471">
      <path id="Vector 222_3" d="M10 38.5L56 41.5M10 38.5V43L56 46M10 38.5L14.5 38L59 41M56 41.5V46M56 41.5L59 41M56 46L59 44V41" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_3" d="M54.5 42.5L11 39.5V42L54.5 45V42.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1472">
      <path id="Vector 222_4" d="M10 82.5L56 85.5M10 82.5V87L56 90M10 82.5L14.5 82L59 85M56 85.5V90M56 85.5L59 85M56 90L59 88V85" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_4" d="M54.5 86.5L11 83.5V86L54.5 89V86.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1473">
      <path id="Vector 222_5" d="M10 54.5L56 57.5M10 54.5V59L56 62M10 54.5L14.5 54L59 57M56 57.5V62M56 57.5L59 57M56 62L59 60V57" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_5" d="M54.5 58.5L11 55.5V58L54.5 61V58.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1474">
      <path id="Vector 222_6" d="M10 98.5L56 101.5M10 98.5V103L56 106M10 98.5L14.5 98L59 101M56 101.5V106M56 101.5L59 101M56 106L59 104V101" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_6" d="M54.5 102.5L11 99.5V102L54.5 105V102.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1475">
      <path id="Vector 222_7" d="M61 28.5L107 31.5M61 28.5V33L107 36M61 28.5L65.5 28L110 31M107 31.5V36M107 31.5L110 31M107 36L110 34V31" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_7" d="M105.5 32.5L62 29.5V32L105.5 35V32.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1476">
      <path id="Vector 222_8" d="M61 72.5L107 75.5M61 72.5V77L107 80M61 72.5L65.5 72L110 75M107 75.5V80M107 75.5L110 75M107 80L110 78V75" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_8" d="M105.5 76.5L62 73.5V76L105.5 79V76.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1477">
      <path id="Vector 222_9" d="M61 42.5L107 45.5M61 42.5V47L107 50M61 42.5L65.5 42L110 45M107 45.5V50M107 45.5L110 45M107 50L110 48V45" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_9" d="M105.5 46.5L62 43.5V46L105.5 49V46.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1478">
      <path id="Vector 222_10" d="M61 86.5L107 89.5M61 86.5V91L107 94M61 86.5L65.5 86L110 89M107 89.5V94M107 89.5L110 89M107 94L110 92V89" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_10" d="M105.5 90.5L62 87.5V90L105.5 93V90.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1479">
      <path id="Vector 222_11" d="M61 58.5L107 61.5M61 58.5V63L107 66M61 58.5L65.5 58L110 61M107 61.5V66M107 61.5L110 61M107 66L110 64V61" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_11" d="M105.5 62.5L62 59.5V62L105.5 65V62.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1480">
      <path id="Vector 222_12" d="M61 102.5L107 105.5M61 102.5V107L107 110M61 102.5L65.5 102L110 105M107 105.5V110M107 105.5L110 105M107 110L110 108V105" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_12" d="M105.5 106.5L62 103.5V106L105.5 109V106.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1481">
      <path id="Vector 222_13" d="M112 32.5L158 35.5M112 32.5V37L158 40M112 32.5L116.5 32L161 35M158 35.5V40M158 35.5L161 35M158 40L161 38V35" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_13" d="M156.5 36.5L113 33.5V36L156.5 39V36.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1482">
      <path id="Vector 222_14" d="M112 76.5L158 79.5M112 76.5V81L158 84M112 76.5L116.5 76L161 79M158 79.5V84M158 79.5L161 79M158 84L161 82V79" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_14" d="M156.5 80.5L113 77.5V80L156.5 83V80.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1483">
      <path id="Vector 222_15" d="M112 46.5L158 49.5M112 46.5V51L158 54M112 46.5L116.5 46L161 49M158 49.5V54M158 49.5L161 49M158 54L161 52V49" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_15" d="M156.5 50.5L113 47.5V50L156.5 53V50.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1484">
      <path id="Vector 222_16" d="M112 90.5L158 93.5M112 90.5V95L158 98M112 90.5L116.5 90L161 93M158 93.5V98M158 93.5L161 93M158 98L161 96V93" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_16" d="M156.5 94.5L113 91.5V94L156.5 97V94.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1485">
      <path id="Vector 222_17" d="M112 62.5L158 65.5M112 62.5V67L158 70M112 62.5L116.5 62L161 65M158 65.5V70M158 65.5L161 65M158 70L161 68V65" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_17" d="M156.5 66.5L113 63.5V66L156.5 69V66.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      <g id="Group 1486">
      <path id="Vector 222_18" d="M112 106.5L158 109.5M112 106.5V111L158 114M112 106.5L116.5 106L161 109M158 109.5V114M158 109.5L161 109M158 114L161 112V109" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 223_18" d="M156.5 110.5L113 107.5V110L156.5 113V110.5Z" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      </g>
    </svg>
  )
}
RackStandardEnvironment.defaultProps = {
  width: 199
}



export function RackStandardGroup({width, maxWidth})  {
  return (
    <svg id="RackStandardGroup" width={width} maxWidth={maxWidth} viewBox="0 0 244 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1543">
      <g id="Group 1542">
      <path id="Vector 287" d="M33 1H6.5L1 12.6383V151H28L31 145V151H58L61 145V151H88L91 145V151H118L121.5 145V151H148L151.5 145V151H178.5L181.5 145V151H208.5L211 146V151H238L243 140.5V1H217L213.5 8V1H187L183.291 8.7927V1H157L153.183 9V1H127L123.228 8.7315V1H97L93 9V1H67L63 9V1H37L33 8.5V1Z" fill="white"/>
      </g>
      <g id="Group 1541">
      <path id="Vector 237" d="M213.474 8.01559V1H187.082L181.402 12.6927V151H208.462L211.135 145.655" stroke="#2E72D2"/>
      <path id="Vector 238" d="M183.403 8.01559V1H157.011L151.332 12.6927V151H178.392L181.065 145.655" stroke="#2E72D2"/>
      <path id="Vector 240" d="M153.337 8.01559V1H126.945L121.266 12.6927V151H148.326L150.998 145.655" stroke="#2E72D2"/>
      <path id="Vector 242" d="M123.27 8.01559V1H96.8785L91.1992 12.6927V151H118.259L120.932 145.655" stroke="#2E72D2"/>
      <path id="Vector 244" d="M93.2041 8.01559V1H66.8121L61.1328 12.6927V151H88.1929L90.8656 145.655" stroke="#2E72D2"/>
      <path id="Vector 246" d="M63.1377 8.01559V1H36.7457L31.0664 12.6927V151H58.1265L60.7991 145.655" stroke="#2E72D2"/>
      <path id="Vector 248" d="M33.0713 8.01559V1H6.67929L1 12.6927V151H28.0601L30.7327 145.655" stroke="#2E72D2"/>
      <path id="Vector 235" d="M211.133 12.6926V151H238.193L243.204 140.31V1H216.812L211.133 12.6926Z" stroke="#2E72D2"/>
      <path id="Vector 234" d="M238.194 24.7197H211.134M208.461 24.7197H181.401M178.394 24.7197H151.334M148.327 24.7197H121.267M118.26 24.7197H91.2003M88.1937 24.7197H61.1335M57.7928 24.7197H30.7326M28.06 24.7197H0.999885M178.394 143.317H151.334M148.327 143.317H121.267M118.26 143.317H91.2003M88.1937 143.317H61.1335M57.7928 143.317H30.7326M208.461 143.317H181.401M28.0015 143.317H0.941406" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 233" d="M243.206 15.3652L238.195 24.7194" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 231" d="M238.193 151H211.133V12.6927M238.193 151V12.6927M238.193 151L243.204 140.31V1M238.193 12.6927H211.133M238.193 12.6927L243.204 1M211.133 12.6927L216.812 1H243.204M238.861 142.314L242.536 134.964V16.6079L238.861 23.7171V142.314Z" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 236" d="M208.462 151H181.402V12.6927M208.462 151V12.6927M208.462 151L211.135 145.655M208.462 12.6927H181.402M208.462 12.6927L213.474 1H187.082L181.402 12.6927M211.135 138.973L209.131 142.314V23.7171L211.268 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 239" d="M178.392 151H151.332V12.6927M178.392 151V12.6927M178.392 151L181.065 145.655M178.392 12.6927H151.332M178.392 12.6927L183.403 1H157.011L151.332 12.6927M181.065 138.973L179.06 142.314V23.7171L181.198 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 241" d="M148.326 151H121.266V12.6927M148.326 151V12.6927M148.326 151L150.998 145.655M148.326 12.6927H121.266M148.326 12.6927L153.337 1H126.945L121.266 12.6927M150.998 138.973L148.994 142.314V23.7171L151.131 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 243" d="M118.259 151H91.1992V12.6927M118.259 151V12.6927M118.259 151L120.932 145.655M118.259 12.6927H91.1992M118.259 12.6927L123.27 1H96.8785L91.1992 12.6927M120.932 138.973L118.928 142.314V23.7171L121.065 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 245" d="M88.1929 151H61.1328V12.6927M88.1929 151V12.6927M88.1929 151L90.8656 145.655M88.1929 12.6927H61.1328M88.1929 12.6927L93.2041 1H66.8121L61.1328 12.6927M90.8656 138.973L88.8611 142.314V23.7171L90.9984 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 247" d="M58.1265 151H31.0664V12.6927M58.1265 151V12.6927M58.1265 151L60.7991 145.655M58.1265 12.6927H31.0664M58.1265 12.6927L63.1377 1H36.7457L31.0664 12.6927M60.7991 138.973L58.7947 142.314V23.7171L60.9319 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 249" d="M28.0601 151H1V12.6927M28.0601 151V12.6927M28.0601 151L30.7327 145.655M28.0601 12.6927H1M28.0601 12.6927L33.0713 1H6.67929L1 12.6927M30.7327 138.973L28.7283 142.314V23.7171L30.8655 19.5824" stroke="#2E72D2" stroke-width="0.5"/>
      <path id="Vector 232" d="M242.536 134.965H238.861M211.133 143.651H238.193" stroke="#2E72D2" stroke-width="0.5"/>
      </g>
      </g>
    </svg>

  )
}
RackStandardGroup.defaultProps = {
  width: "100%",
  maxWidth: 244,
}


export function RackStandardEmpty({width, maxWidth})  {
  return (
    <svg width={width} viewBox="0 0 320 233" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.75 225.612V20.0611L303.741 1.05469L317.82 8.09411V212.237L15.1249 231.948L1.75 225.612Z" fill="white" stroke="black" stroke-width="1.6164"/>
      <circle cx="18.867" cy="213.846" r="0.8082" fill="#1D65F0" stroke="black" stroke-width="0.340323"/>
      <circle cx="18.867" cy="159.058" r="0.8082" fill="#1D65F0" stroke="black" stroke-width="0.340323"/>
      <path d="M310.913 27.9551L60.4521 44.098V218.246" stroke="black" stroke-width="1.28659"/>
      <path d="M1.75 20.1288L15.9362 26.4882M1.75 20.1288L304.064 1.05078L318.25 7.41013M1.75 20.1288V39.6961M15.9362 26.4882L318.25 7.41013M15.9362 26.4882L15.8873 47.0338M15.4471 231.944L1.75 225.585V214.823M15.4471 231.944L15.4727 221.182M15.4471 231.944L318.25 212.377V202.104M1.75 214.823L15.4727 221.182M1.75 214.823V39.6961M15.4727 221.182L60.4517 218.348M15.4727 221.182L15.6357 152.697M318.25 7.41013V27.4665M318.25 202.104V27.4665M318.25 202.104L310.912 202.566M60.4517 218.348L310.912 202.566M60.4517 218.348V214.823M1.75 39.6961L15.8873 47.0338M15.8873 47.0338L60.4517 44.1498M15.8873 47.0338L15.6357 152.697M318.25 27.4665L310.912 27.9414M60.4517 44.1498L100.075 41.5856L141.656 38.8948L183.236 36.2039L225.306 33.4814L266.641 30.8064L307.977 28.1313L310.912 27.9414M60.4517 44.1498V72.9604V101.333V129.705V149.762M15.6357 152.697L60.4517 149.762M60.4517 149.762V158.567V186.94V214.823M310.912 27.9414V202.566M310.912 202.566L302.958 199.486M302.958 199.486L60.4517 214.823M302.958 199.486V28.4561" stroke="black" stroke-width="0.857724"/>
      <path d="M1.75 20.1288V225.585L15.4471 231.944L318.25 212.377V7.41013L304.064 1.05078L1.75 20.1288Z" stroke="black" stroke-width="1.71545"/>
      <path d="M61.0561 218.032L60.75 214.032L302.944 199.051L311.25 202.551L61.0561 218.032Z" fill="#C7D7F8" stroke="black" stroke-width="0.5"/>
    </svg>

  )
}
RackStandardEmpty.defaultProps = {
  width: 320,
  maxWidth: 227,
}




export function RackPowerOnTutorial({width})  {
  return (
    <svg id={RackPowerOnTutorial} width={width} viewBox="0 0 254 196"fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 2801" clip-path="url(#clip0_388_43141)">
      <g id="Group 1541">
      <path id="Power button" fill-rule="evenodd" clip-rule="evenodd" d="M101.351 99.6355C96.0248 114.605 81.7304 125.323 64.9328 125.323C48.1353 125.323 33.8409 114.605 28.5147 99.6355H101.351ZM101.351 73.7223H28.5147C33.8409 58.7526 48.1353 48.0352 64.9328 48.0352C81.7304 48.0352 96.0248 58.7526 101.351 73.7223ZM111.577 86.6789C111.577 112.44 90.6935 133.323 64.9328 133.323C39.1722 133.323 18.2891 112.44 18.2891 86.6789C18.2891 60.9183 39.1722 40.0352 64.9328 40.0352C90.6935 40.0352 111.577 60.9183 111.577 86.6789Z" fill="#2E72D2"/>
      <path id="Power button_2" fill-rule="evenodd" clip-rule="evenodd" d="M204.864 50.2584C219.834 55.5846 230.551 69.879 230.551 86.6765C230.551 103.474 219.834 117.769 204.864 123.095V50.2584ZM178.951 50.2584V123.095C163.981 117.769 153.264 103.474 153.264 86.6765C153.264 69.879 163.981 55.5846 178.951 50.2584ZM191.907 40.0328C217.668 40.0328 238.551 60.9159 238.551 86.6765C238.551 112.437 217.668 133.32 191.907 133.32C166.147 133.32 145.264 112.437 145.264 86.6765C145.264 60.9159 166.147 40.0328 191.907 40.0328Z" fill="#2E72D2"/>
      <circle id="Ellipse 605" cx="59.5508" cy="172.059" r="18" fill="#EC3C3C"/>
      <circle id="Ellipse 606" cx="191.551" cy="172.059" r="18" fill="#5AE044"/>
      <path id="Arrow 4" d="M112.238 29.3303L110.375 30.9975L112.238 29.3303ZM120.507 41.2222C121.886 41.2987 123.065 40.2432 123.142 38.8646L124.388 16.3991C124.465 15.0205 123.409 13.8409 122.03 13.7645C120.652 13.688 119.472 14.7436 119.396 16.1221L118.288 36.0914L98.3187 34.9836C96.9401 34.9071 95.7605 35.9626 95.684 37.3412C95.6075 38.7198 96.6631 39.8994 98.0417 39.9759L120.507 41.2222ZM110.375 30.9975L118.783 40.3932L122.509 37.059L114.101 27.6632L110.375 30.9975ZM11.6856 40.0277C33.2313 4.68633 82.7729 0.15314 110.375 30.9975L114.101 27.6632C84.2627 -5.67993 30.7075 -0.779486 7.41642 37.4251L11.6856 40.0277Z" fill="#191C22"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_388_43141">
      <rect width="254" height="196" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  )
}
RackPowerOnTutorial.defaultProps = {
  width: 254
}






export function RackNurseryZonesSelection({width, selectedLightingFill, unselectedLightingFill, selectedLightingOverWaterFill, unselectedLightingOverWaterFill, selectedLightingZones, 
                                                  selectedWaterFill, unselectedWaterFill, selectedWaterStroke, unselectedWaterStroke, selectedWaterZones})  {
  
  const waterZoneActive1 = selectedWaterZones.find((wz) => wz === 1) !== undefined
  const waterZoneActive2 = selectedWaterZones.find((wz) => wz === 2) !== undefined

  const lightingZoneActive1 = selectedLightingZones.find((lz) => lz === 1) !== undefined
  const lightingZoneActive2 = selectedLightingZones.find((lz) => lz === 2) !== undefined
  const lightingZoneActive3 = selectedLightingZones.find((lz) => lz === 3) !== undefined
  const lightingZoneActive4 = selectedLightingZones.find((lz) => lz === 4) !== undefined
  const lightingZoneActive5 = selectedLightingZones.find((lz) => lz === 5) !== undefined
  const lightingZoneActive6 = selectedLightingZones.find((lz) => lz === 6) !== undefined
  const lightingZoneActive7 = selectedLightingZones.find((lz) => lz === 7) !== undefined

  return (
    <svg width={width} viewBox="0 0 215 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect id="WZone1" x="0.5" y="-0.5" width="214" height="81" rx="1" transform="matrix(-1 0 0 1 215 1)" 
        fill={waterZoneActive1 ? selectedWaterFill : unselectedWaterFill}
        stroke={waterZoneActive1 ? selectedWaterStroke : unselectedWaterStroke}/>
      <rect id="WZone2" x="0.5" y="-0.5" width="214" height="62" rx="1" transform="matrix(-1 0 0 1 215 90)"
        fill={waterZoneActive2 ? selectedWaterFill : unselectedWaterFill}
        stroke={waterZoneActive2 ? selectedWaterStroke : unselectedWaterStroke}/>
      
      <path id="Lights1" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 13.5C193.856 13.2239 193.632 13 193.356 13H141.745C141.469 13 141.245 13.2239 141.245 13.5V14H133.239V13.5C133.239 13.2239 133.015 13 132.739 13H82.2714C81.9953 13 81.7715 13.2239 81.7715 13.5V14H73.7657V13.5C73.7657 13.2239 73.5418 13 73.2657 13H21.6546C21.3785 13 21.1546 13.2239 21.1546 13.5V14.0714C21.0608 14.1395 20.9998 14.2501 20.9998 14.375C20.9998 14.4999 21.0608 14.6105 21.1546 14.6786V15.5C21.1546 15.7761 21.3785 16 21.6546 16H73.2657C73.5418 16 73.7657 15.7761 73.7657 15.5V14.75H81.7715V15.5C81.7715 15.7761 81.9953 16 82.2714 16H132.739C133.015 16 133.239 15.7761 133.239 15.5V14.75H141.245V15.5C141.245 15.7761 141.469 16 141.745 16H193.356C193.632 16 193.856 15.7761 193.856 15.5V13.5Z"
        fill={waterZoneActive1 ? (lightingZoneActive1 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive1 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights2" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 31.5C193.856 31.2239 193.632 31 193.356 31H141.745C141.469 31 141.245 31.2239 141.245 31.5V32.125H133.239V31.5C133.239 31.2239 133.015 31 132.739 31H82.2714C81.9953 31 81.7715 31.2239 81.7715 31.5V32.125H73.7657V31.5C73.7657 31.2239 73.5418 31 73.2657 31H21.6546C21.3785 31 21.1546 31.2239 21.1546 31.5V32.4924C21.1546 32.4949 21.1546 32.4974 21.1546 32.5C21.1546 32.5026 21.1546 32.5051 21.1546 32.5076V33.5C21.1546 33.7761 21.3785 34 21.6546 34H73.2657C73.5418 34 73.7657 33.7761 73.7657 33.5V32.875H81.7715V33.5C81.7715 33.7761 81.9953 34 82.2714 34H132.739C133.015 34 133.239 33.7761 133.239 33.5V32.875H141.245V33.5C141.245 33.7761 141.469 34 141.745 34H193.356C193.632 34 193.856 33.7761 193.856 33.5V32.5V31.5Z"
        fill={waterZoneActive1 ? (lightingZoneActive2 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive2 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights3" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 49.5C193.856 49.2239 193.632 49 193.356 49H141.745C141.469 49 141.245 49.2239 141.245 49.5V50.125H133.239V49.5C133.239 49.2239 133.015 49 132.739 49H82.2714C81.9953 49 81.7715 49.2239 81.7715 49.5V50.125H73.7657V49.5C73.7657 49.2239 73.5418 49 73.2657 49H21.6546C21.3785 49 21.1546 49.2239 21.1546 49.5V50.4924C21.1546 50.4949 21.1546 50.4974 21.1546 50.5C21.1546 50.5026 21.1546 50.5051 21.1546 50.5076V51.5C21.1546 51.7761 21.3785 52 21.6546 52H73.2657C73.5418 52 73.7657 51.7761 73.7657 51.5V50.875H81.7715V51.5C81.7715 51.7761 81.9953 52 82.2714 52H132.739C133.015 52 133.239 51.7761 133.239 51.5V50.875H141.245V51.5C141.245 51.7761 141.469 52 141.745 52H193.356C193.632 52 193.856 51.7761 193.856 51.5V50.5V49.5Z"
        fill={waterZoneActive1 ? (lightingZoneActive3 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive3 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights4" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 67.5C193.856 67.2239 193.632 67 193.356 67H141.745C141.469 67 141.245 67.2239 141.245 67.5V68.125H133.239V67.5C133.239 67.2239 133.015 67 132.739 67H82.2714C81.9953 67 81.7715 67.2239 81.7715 67.5V68.125H73.7657V67.5C73.7657 67.2239 73.5418 67 73.2657 67H21.6546C21.3785 67 21.1546 67.2239 21.1546 67.5V68.4924C21.1546 68.4949 21.1546 68.4974 21.1546 68.5C21.1546 68.5026 21.1546 68.5051 21.1546 68.5076V69.5C21.1546 69.7761 21.3785 70 21.6546 70H73.2657C73.5418 70 73.7657 69.7761 73.7657 69.5V68.875H81.7715V69.5C81.7715 69.7761 81.9953 70 82.2714 70H132.739C133.015 70 133.239 69.7761 133.239 69.5V68.875H141.245V69.5C141.245 69.7761 141.469 70 141.745 70H193.356C193.632 70 193.856 69.7761 193.856 69.5V68.5V67.5Z"
        fill={waterZoneActive1 ? (lightingZoneActive4 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive4 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights5" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 101.5C193.856 101.224 193.632 101 193.356 101H141.745C141.469 101 141.245 101.224 141.245 101.5V102.125H133.239V101.5C133.239 101.224 133.015 101 132.739 101H82.2714C81.9953 101 81.7715 101.224 81.7715 101.5V102.125H73.7657V101.5C73.7657 101.224 73.5418 101 73.2657 101H21.6546C21.3785 101 21.1546 101.224 21.1546 101.5V102.492C21.1546 102.495 21.1546 102.497 21.1546 102.5C21.1546 102.503 21.1546 102.505 21.1546 102.508V103.5C21.1546 103.776 21.3785 104 21.6546 104H73.2657C73.5418 104 73.7657 103.776 73.7657 103.5V102.875H81.7715V103.5C81.7715 103.776 81.9953 104 82.2714 104H132.739C133.015 104 133.239 103.776 133.239 103.5V102.875H141.245V103.5C141.245 103.776 141.469 104 141.745 104H193.356C193.632 104 193.856 103.776 193.856 103.5V102.5V101.5Z"
        fill={waterZoneActive2 ? (lightingZoneActive5 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive5 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights6" fill-rule="evenodd" clip-rule="evenodd" d="M193.856 119.5C193.856 119.224 193.632 119 193.356 119H141.745C141.469 119 141.245 119.224 141.245 119.5V120.125H133.239V119.5C133.239 119.224 133.015 119 132.739 119H82.2714C81.9953 119 81.7715 119.224 81.7715 119.5V120.125H73.7657V119.5C73.7657 119.224 73.5418 119 73.2657 119H21.6546C21.3785 119 21.1546 119.224 21.1546 119.5V120.492C21.1546 120.495 21.1546 120.497 21.1546 120.5C21.1546 120.503 21.1546 120.505 21.1546 120.508V121.5C21.1546 121.776 21.3785 122 21.6546 122H73.2657C73.5418 122 73.7657 121.776 73.7657 121.5V120.875H81.7715V121.5C81.7715 121.776 81.9953 122 82.2714 122H132.739C133.015 122 133.239 121.776 133.239 121.5V120.875H141.245V121.5C141.245 121.776 141.469 122 141.745 122H193.356C193.632 122 193.856 121.776 193.856 121.5V120.5V119.5Z"
        fill={waterZoneActive2 ? (lightingZoneActive6 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive6 ? selectedLightingFill : unselectedLightingFill)}/>
      <path id="Lights7" fill-rule="evenodd" clip-rule="evenodd" d="M194.095 137.5C194.095 137.224 193.871 137 193.595 137H141.984C141.708 137 141.484 137.224 141.484 137.5V138.125H133.478V137.5C133.478 137.224 133.254 137 132.978 137H82.5104C82.2343 137 82.0104 137.224 82.0104 137.5V138.125H74.0047V137.5C74.0047 137.224 73.7808 137 73.5047 137H21.8936C21.6175 137 21.3936 137.224 21.3936 137.5V138.485C21.3934 138.49 21.3933 138.495 21.3933 138.5C21.3933 138.505 21.3934 138.51 21.3936 138.515V139.5C21.3936 139.776 21.6175 140 21.8936 140H73.5047C73.7808 140 74.0047 139.776 74.0047 139.5V138.875H82.0104V139.5C82.0104 139.776 82.2343 140 82.5104 140H132.978C133.254 140 133.478 139.776 133.478 139.5V138.875H141.484V139.5C141.484 139.776 141.708 140 141.984 140H193.595C193.871 140 194.095 139.776 194.095 139.5V138.5V137.5Z" 
        fill={waterZoneActive2 ? (lightingZoneActive7 ? selectedLightingOverWaterFill : unselectedLightingOverWaterFill) : (lightingZoneActive7 ? selectedLightingFill : unselectedLightingFill)}/>
    </svg> 

  )
}
RackNurseryZonesSelection.defaultProps = {
  width: 215,
  selectedLightingFill: "#2E72D2",
  unselectedLightingFill: "#D0D7EB",
  selectedLightingOverWaterFill: "#2E72D2",
  unselectedLightingOverWaterFill: "#BBCDE5",
  selectedWaterFill: "#DCE6F8",
  unselectedWaterFill: "#F7F8FB",
  selectedWaterStroke: "#B3CDF1",
  unselectedWaterStroke: "#DDE3EB",
  selectedLightingZones: [],
  selectedWaterZones: []
}







export function NurseryRowSelectionVisual({width})  {
  return (
    <svg width={width} viewBox="0 0 172 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.67041" y="4.71582" width="149.454" height="101.815" rx="1.86817" fill="#F8F8F8"/>
      <rect x="6.53857" y="17.793" width="144.783" height="2.80225" fill="#1D65F0"/>
      <rect x="6.53857" y="30.8701" width="144.783" height="2.80225" fill="#1D65F0"/>
      <rect x="6.53857" y="43.9463" width="144.783" height="2.80225" fill="#1D65F0"/>
      <rect x="6.53857" y="57.0234" width="144.783" height="2.80225" fill="#D0D7EB"/>
      <rect x="6.53857" y="70.1006" width="144.783" height="2.80225" fill="#D0D7EB"/>
      <rect x="6.53857" y="83.1782" width="144.783" height="2.80225" fill="#D0D7EB"/>
      <rect x="6.53857" y="96.2554" width="144.783" height="2.80225" fill="#D0D7EB"/>
      <mask id="path-9-inside-1_2761_65052" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M170.937 1.91309C170.937 0.88133 170.101 0.0449219 169.069 0.0449219H2.80225C1.77049 0.0449219 0.934082 0.881332 0.934082 1.91309V108.399C0.934082 109.43 1.77048 110.267 2.80224 110.267H169.069C170.101 110.267 170.937 109.43 170.937 108.399V1.91309ZM150.516 7.63663C150.686 7.63663 150.824 7.77418 150.824 7.94387V104.474C150.824 104.644 150.686 104.781 150.516 104.781H7.28193C7.11224 104.781 6.97469 104.644 6.97469 104.474V7.94387C6.97469 7.77418 7.11223 7.63663 7.28192 7.63663H150.516Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M170.937 1.91309C170.937 0.88133 170.101 0.0449219 169.069 0.0449219H2.80225C1.77049 0.0449219 0.934082 0.881332 0.934082 1.91309V108.399C0.934082 109.43 1.77048 110.267 2.80224 110.267H169.069C170.101 110.267 170.937 109.43 170.937 108.399V1.91309ZM150.516 7.63663C150.686 7.63663 150.824 7.77418 150.824 7.94387V104.474C150.824 104.644 150.686 104.781 150.516 104.781H7.28193C7.11224 104.781 6.97469 104.644 6.97469 104.474V7.94387C6.97469 7.77418 7.11223 7.63663 7.28192 7.63663H150.516Z" fill="white"/>
      <path d="M2.80225 0.535802H169.069V-0.445958H2.80225V0.535802ZM1.42496 108.399V1.91309H0.443202V108.399H1.42496ZM169.069 109.776H2.80224V110.758H169.069V109.776ZM170.447 1.91309V108.399H171.428V1.91309H170.447ZM151.315 7.94387C151.315 7.50309 150.957 7.14575 150.516 7.14575V8.12751C150.415 8.12751 150.333 8.04528 150.333 7.94387H151.315ZM151.315 104.474V7.94387H150.333V104.474H151.315ZM150.516 105.272C150.957 105.272 151.315 104.915 151.315 104.474H150.333C150.333 104.373 150.415 104.291 150.516 104.291V105.272ZM7.28193 105.272H150.516V104.291H7.28193V105.272ZM6.48381 104.474C6.48381 104.915 6.84114 105.272 7.28193 105.272V104.291C7.38335 104.291 7.46557 104.373 7.46557 104.474H6.48381ZM6.48381 7.94387V104.474H7.46557V7.94387H6.48381ZM7.28192 7.14575C6.84111 7.14575 6.48381 7.5031 6.48381 7.94387H7.46557C7.46557 8.04527 7.38336 8.12751 7.28192 8.12751V7.14575ZM150.516 7.14575H7.28192V8.12751H150.516V7.14575ZM169.069 110.758C170.372 110.758 171.428 109.702 171.428 108.399H170.447C170.447 109.159 169.83 109.776 169.069 109.776V110.758ZM0.443202 108.399C0.443202 109.702 1.49937 110.758 2.80224 110.758V109.776C2.04159 109.776 1.42496 109.159 1.42496 108.399H0.443202ZM169.069 0.535802C169.83 0.535802 170.447 1.15243 170.447 1.91309H171.428C171.428 0.610228 170.372 -0.445958 169.069 -0.445958V0.535802ZM2.80225 -0.445958C1.49938 -0.445958 0.443202 0.610228 0.443202 1.91309H1.42496C1.42496 1.15244 2.0416 0.535802 2.80225 0.535802V-0.445958Z" fill="#DDE3EB" mask="url(#path-9-inside-1_2761_65052)"/>
      <mask id="path-11-inside-2_2761_65052" fill="white">
        <path d="M0 1.91309C0 0.881333 0.836408 0.0449219 1.86817 0.0449219H169.94C170.972 0.0449219 171.808 0.88133 171.808 1.91309V109.451C171.808 110.483 170.972 111.32 169.94 111.32H1.86816C0.836403 111.32 0 110.483 0 109.451V1.91309Z"/>
      </mask>
      <path d="M1.86817 1.24553H169.94V-1.15569H1.86817V1.24553ZM170.607 1.91309V109.451H173.009V1.91309H170.607ZM169.94 110.119H1.86816V112.52H169.94V110.119ZM1.20061 109.451V1.91309H-1.20061V109.451H1.20061ZM1.86816 110.119C1.49949 110.119 1.20061 109.82 1.20061 109.451H-1.20061C-1.20061 111.146 0.17332 112.52 1.86816 112.52V110.119ZM170.607 109.451C170.607 109.82 170.309 110.119 169.94 110.119V112.52C171.635 112.52 173.009 111.146 173.009 109.451H170.607ZM169.94 1.24553C170.309 1.24553 170.607 1.54441 170.607 1.91309H173.009C173.009 0.218254 171.635 -1.15569 169.94 -1.15569V1.24553ZM1.86817 -1.15569C0.173326 -1.15569 -1.20061 0.218256 -1.20061 1.91309H1.20061C1.20061 1.54441 1.49949 1.24553 1.86817 1.24553V-1.15569Z" fill="#DDE3EB" mask="url(#path-11-inside-2_2761_65052)"/>
    </svg>


  )
}
NurseryRowSelectionVisual.defaultProps = {
  width: 172
}





export function NurseryRowSelectionSupportingInfoVisual({width})  {
  return (
    <svg width={width} viewBox="0 0 211 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.646447 3.6567C0.451184 3.85196 0.451184 4.16855 0.646447 4.36381L3.82843 7.54579C4.02369 7.74105 4.34027 7.74105 4.53553 7.54579C4.7308 7.35053 4.7308 7.03394 4.53553 6.83868L1.70711 4.01025L4.53553 1.18183C4.7308 0.986565 4.7308 0.669982 4.53553 0.47472C4.34027 0.279458 4.02369 0.279458 3.82843 0.47472L0.646447 3.6567ZM194.354 4.36381C194.549 4.16855 194.549 3.85196 194.354 3.6567L191.172 0.47472C190.976 0.279458 190.66 0.279458 190.464 0.47472C190.269 0.669982 190.269 0.986565 190.464 1.18183L193.293 4.01025L190.464 6.83868C190.269 7.03394 190.269 7.35053 190.464 7.54579C190.66 7.74105 190.976 7.74105 191.172 7.54579L194.354 4.36381ZM1 4.51025H194V3.51025H1V4.51025Z" fill="#1D65F0"/>
      <path d="M16.716 24.0103V16.7383L15.36 17.0383V16.3423L17.052 15.6103H17.736V24.0103H16.716Z" fill="#191C22"/>
      <path d="M37.9225 24.0103V23.3023C38.4905 22.8543 39.0265 22.4023 39.5305 21.9463C40.0425 21.4823 40.4945 21.0263 40.8865 20.5783C41.2865 20.1303 41.5985 19.6903 41.8225 19.2583C42.0545 18.8263 42.1705 18.4103 42.1705 18.0103C42.1705 17.7143 42.1185 17.4383 42.0145 17.1823C41.9185 16.9263 41.7585 16.7223 41.5345 16.5703C41.3105 16.4103 41.0065 16.3303 40.6225 16.3303C40.2545 16.3303 39.9465 16.4143 39.6985 16.5823C39.4505 16.7423 39.2625 16.9583 39.1345 17.2303C39.0145 17.5023 38.9545 17.7943 38.9545 18.1063H37.9825C37.9825 17.5543 38.0985 17.0823 38.3305 16.6903C38.5625 16.2903 38.8785 15.9863 39.2785 15.7783C39.6785 15.5703 40.1305 15.4663 40.6345 15.4663C41.3785 15.4663 41.9865 15.6783 42.4585 16.1023C42.9385 16.5183 43.1785 17.1423 43.1785 17.9743C43.1785 18.4703 43.0545 18.9623 42.8065 19.4503C42.5585 19.9303 42.2425 20.3983 41.8585 20.8543C41.4745 21.3023 41.0625 21.7223 40.6225 22.1143C40.1905 22.5063 39.7865 22.8583 39.4105 23.1703H43.4665V24.0103H37.9225Z" fill="#191C22"/>
      <path d="M65.8375 24.1543C65.3015 24.1543 64.8175 24.0583 64.3855 23.8663C63.9535 23.6663 63.6055 23.3703 63.3415 22.9783C63.0855 22.5863 62.9495 22.0983 62.9335 21.5143H63.9535C63.9615 21.9943 64.1295 22.4103 64.4575 22.7623C64.7855 23.1063 65.2455 23.2783 65.8375 23.2783C66.4295 23.2783 66.8735 23.1143 67.1695 22.7863C67.4735 22.4583 67.6255 22.0623 67.6255 21.5983C67.6255 21.2063 67.5295 20.8863 67.3375 20.6383C67.1535 20.3903 66.9015 20.2063 66.5815 20.0863C66.2695 19.9663 65.9255 19.9063 65.5495 19.9063H64.9255V19.0543H65.5495C66.0935 19.0543 66.5175 18.9303 66.8215 18.6823C67.1335 18.4343 67.2895 18.0863 67.2895 17.6383C67.2895 17.2623 67.1655 16.9543 66.9175 16.7143C66.6775 16.4663 66.3175 16.3423 65.8375 16.3423C65.3735 16.3423 65.0015 16.4823 64.7215 16.7623C64.4415 17.0343 64.2855 17.3783 64.2535 17.7943H63.2335C63.2575 17.3383 63.3775 16.9343 63.5935 16.5823C63.8175 16.2303 64.1215 15.9583 64.5055 15.7663C64.8895 15.5663 65.3335 15.4663 65.8375 15.4663C66.3815 15.4663 66.8335 15.5623 67.1935 15.7543C67.5615 15.9463 67.8375 16.2023 68.0215 16.5223C68.2135 16.8423 68.3095 17.1943 68.3095 17.5783C68.3095 18.0023 68.1935 18.3903 67.9615 18.7423C67.7295 19.0863 67.3815 19.3183 66.9175 19.4383C67.4135 19.5423 67.8255 19.7783 68.1535 20.1463C68.4815 20.5143 68.6455 20.9983 68.6455 21.5983C68.6455 22.0623 68.5375 22.4903 68.3215 22.8823C68.1135 23.2663 67.8015 23.5743 67.3855 23.8063C66.9695 24.0383 66.4535 24.1543 65.8375 24.1543Z" fill="#191C22"/>
      <path d="M93.192 24.0103V22.2103H88.932V21.4303L93.012 15.6103H94.188V21.3223H95.436V22.2103H94.188V24.0103H93.192ZM90.048 21.3223H93.24V16.7263L90.048 21.3223Z" fill="#191C22"/>
      <path d="M118.411 24.1543C117.835 24.1543 117.335 24.0503 116.911 23.8423C116.487 23.6343 116.147 23.3503 115.891 22.9903C115.643 22.6223 115.491 22.2063 115.435 21.7423H116.419C116.515 22.2063 116.739 22.5823 117.091 22.8703C117.443 23.1503 117.887 23.2903 118.423 23.2903C118.823 23.2903 119.167 23.1983 119.455 23.0143C119.743 22.8223 119.963 22.5663 120.115 22.2463C120.275 21.9263 120.355 21.5703 120.355 21.1783C120.355 20.5543 120.179 20.0503 119.827 19.6663C119.475 19.2823 119.019 19.0903 118.459 19.0903C117.995 19.0903 117.599 19.1943 117.271 19.4023C116.943 19.6103 116.703 19.8823 116.551 20.2183H115.591L116.311 15.6103H120.715V16.4863H117.091L116.599 19.0423C116.791 18.8103 117.055 18.6183 117.391 18.4663C117.735 18.3143 118.127 18.2383 118.567 18.2383C119.119 18.2383 119.599 18.3663 120.007 18.6223C120.423 18.8783 120.747 19.2263 120.979 19.6663C121.211 20.1063 121.327 20.6063 121.327 21.1663C121.327 21.7023 121.211 22.1983 120.979 22.6543C120.755 23.1103 120.423 23.4743 119.983 23.7463C119.551 24.0183 119.027 24.1543 118.411 24.1543Z" fill="#191C22"/>
      <path d="M144.745 24.1543C144.137 24.1543 143.625 24.0423 143.209 23.8183C142.793 23.5863 142.457 23.2783 142.201 22.8943C141.953 22.5103 141.769 22.0823 141.649 21.6103C141.537 21.1303 141.481 20.6423 141.481 20.1463C141.481 19.2183 141.609 18.4063 141.865 17.7103C142.121 17.0063 142.489 16.4583 142.969 16.0663C143.457 15.6663 144.045 15.4663 144.733 15.4663C145.261 15.4663 145.713 15.5703 146.089 15.7783C146.465 15.9863 146.761 16.2663 146.977 16.6183C147.201 16.9623 147.341 17.3463 147.397 17.7703H146.461C146.373 17.3143 146.177 16.9623 145.873 16.7143C145.569 16.4663 145.185 16.3423 144.721 16.3423C144.089 16.3423 143.557 16.6423 143.125 17.2423C142.701 17.8343 142.473 18.7623 142.441 20.0263C142.633 19.6503 142.937 19.3263 143.353 19.0543C143.777 18.7743 144.285 18.6343 144.877 18.6343C145.357 18.6343 145.805 18.7463 146.221 18.9703C146.645 19.1943 146.989 19.5103 147.253 19.9183C147.517 20.3183 147.649 20.7903 147.649 21.3343C147.649 21.8063 147.533 22.2583 147.301 22.6903C147.069 23.1223 146.733 23.4743 146.293 23.7463C145.861 24.0183 145.345 24.1543 144.745 24.1543ZM144.673 23.2663C145.049 23.2663 145.385 23.1863 145.681 23.0263C145.977 22.8663 146.209 22.6463 146.377 22.3663C146.545 22.0783 146.629 21.7503 146.629 21.3823C146.629 20.8143 146.441 20.3623 146.065 20.0263C145.697 19.6823 145.233 19.5103 144.673 19.5103C144.297 19.5103 143.961 19.5983 143.665 19.7743C143.369 19.9423 143.133 20.1703 142.957 20.4583C142.789 20.7463 142.705 21.0583 142.705 21.3943C142.705 21.7543 142.789 22.0783 142.957 22.3663C143.133 22.6463 143.369 22.8663 143.665 23.0263C143.961 23.1863 144.297 23.2663 144.673 23.2663Z" fill="#191C22"/>
      <path d="M168.584 24.0103L171.92 16.4743H167.312V15.6103H172.952V16.3663L169.652 24.0103H168.584ZM173.838 20.9503V20.0983H176.13V17.9143H177.03V20.0983H179.322V20.9503H177.03V23.1463H176.13V20.9503H173.838Z" fill="#191C22"/>
    </svg>
  )
}
NurseryRowSelectionSupportingInfoVisual.defaultProps = {
  width: 171
}



export function Blade_GrowOutSprayDownVisual({width}) {
  return (
    <svg width={width} viewBox="0 0 291 233" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.15356 196.19V17.5226L263.648 1.00195L275.885 7.1207V184.564L12.7792 201.697L1.15356 196.19Z" fill="white" stroke="#191C22" stroke-width="1.40499"/>
      <circle cx="16.032" cy="185.964" r="0.702497" fill="#1D65F0" stroke="#191C22" stroke-width="0.295812"/>
      <circle cx="16.032" cy="138.341" r="0.702497" fill="#1D65F0" stroke="#191C22" stroke-width="0.295812"/>
      <path d="M269.881 24.3848L52.178 38.4164V189.788" stroke="#191C22" stroke-width="1.11831"/>
      <path d="M1.15356 17.5829L13.4844 23.1105M1.15356 17.5829L263.928 1L276.259 6.52762M1.15356 17.5829V34.5909M13.4844 23.1105L276.259 6.52762M13.4844 23.1105L13.4419 40.9689M13.0592 201.695L1.15356 196.167V186.813M13.0592 201.695L13.0815 192.341M13.0592 201.695L276.259 184.687V175.758M1.15356 186.813L13.0815 192.341M1.15356 186.813V34.5909M13.0815 192.341L52.1777 189.877M13.0815 192.341L13.2232 132.812M276.259 6.52762V23.9609M276.259 175.758V23.9609M276.259 175.758L269.881 176.16M52.1777 189.877L269.881 176.16M52.1777 189.877V186.813M1.15356 34.5909L13.4419 40.9689M13.4419 40.9689L52.1777 38.4622M13.4419 40.9689L13.2232 132.812M276.259 23.9609L269.881 24.3736M52.1777 38.4622L86.6191 36.2333L122.761 33.8944L158.903 31.5555L195.471 29.1891L231.4 26.8639L267.33 24.5387L269.881 24.3736M52.1777 38.4622V63.5046V88.1663V112.828V130.261M13.2232 132.812L52.1777 130.261M52.1777 130.261V137.915V162.577V186.813M269.881 24.3736V176.16M269.881 176.16L262.967 173.482M262.967 173.482L52.1777 186.813M262.967 173.482V24.821" stroke="#191C22" stroke-width="0.745543"/>
      <path d="M1.15356 17.5829V196.167L13.0592 201.695L276.259 184.687V6.52762L263.928 1L1.15356 17.5829Z" stroke="#191C22" stroke-width="1.49109"/>
      <path d="M262.967 25.4219L53.0835 39.2908V107.604C54.423 98.4341 68.998 97.5473 94.3465 112.9C110.11 122.447 122.633 53.8605 147.361 84.7713C172.09 115.682 163.435 102.39 194.964 73.6434C226.493 44.8964 233.602 103.936 248.439 112.9C260.309 120.071 263.07 97.1355 262.967 84.7713V25.4219Z" fill="#C7D7F8" stroke="#4282FB" stroke-width="0.618214"/>
      <path d="M192.547 109.69C185.467 91.7179 166.896 57.3263 149.251 63.5348" stroke="#C7D7F8" stroke-width="5.71833"/>
      <path d="M190.096 110.507C183.016 92.5349 166.896 62.2274 149.251 68.4359" stroke="#4282FB" stroke-width="0.816904"/>
      <path d="M194.589 107.647C188.054 90.0826 164.772 52.0974 148.026 49.2383" stroke="#4282FB" stroke-width="0.816904"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M195.259 113.781C196.096 113.107 196.631 112.074 196.631 110.916C196.631 108.886 194.986 107.24 192.955 107.24C190.925 107.24 189.279 108.886 189.279 110.916C189.279 112.633 190.456 114.075 192.048 114.479L192.547 120.719H195.814L195.259 113.781Z" fill="#D9D9D9"/>
      <path d="M194.181 121.127C195.134 141.413 190.26 192.279 218.688 192.606C254.223 193.014 317.533 192.606 274.237 204.451C235.434 210.986 240.989 221.851 269.744 231.001" stroke="#8A8A8A" stroke-width="2.45071"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M223.508 117.805C229.262 115.036 233.233 109.151 233.233 102.339C233.233 92.8641 225.552 85.1836 216.078 85.1836C206.603 85.1836 198.923 92.8641 198.923 102.339C198.923 109.151 202.894 115.036 208.647 117.805C208.023 118.532 207.425 119.347 206.857 120.243L199.9 116.226C197.555 114.873 194.558 115.676 193.204 118.02C191.851 120.364 192.654 123.362 194.998 124.716L202.923 129.291C202.291 131.437 201.771 133.749 201.382 136.188L190.754 154.596C189.4 156.941 190.203 159.938 192.548 161.292C194.892 162.645 197.89 161.842 199.243 159.498L201.296 155.942C201.71 158.143 202.289 160.005 203.007 161.575V199.551C203.007 202.258 205.202 204.452 207.909 204.452C210.616 204.452 212.81 202.258 212.81 199.551V169.087C213.864 169.253 214.957 169.324 216.078 169.324C217.491 169.324 218.861 169.21 220.162 168.938V199.551C220.162 202.258 222.357 204.452 225.064 204.452C227.771 204.452 229.965 202.258 229.965 199.551V159.477C231.011 156.301 231.599 152.176 231.599 146.859C231.599 134.325 228.329 123.419 223.508 117.805Z" fill="black"/>
    </svg>

  )
}
Blade_GrowOutSprayDownVisual.defaultProps = {
  width: 291
}




export function Blade_GrowOutSprayDownVisual_EasterEgg({width}) {
  return (
    <svg width={width} viewBox="0 0 371 277" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 263.808V24.1611L353.084 2.00195L369.499 10.2091V248.215L16.5935 271.195L1 263.808Z" fill="white" stroke="#191C22" stroke-width="1.88452"/>
      <circle cx="20.9566" cy="250.092" r="0.942262" fill="#1D65F0" stroke="#191C22" stroke-width="0.396775"/>
      <circle cx="20.9566" cy="186.217" r="0.942262" fill="#1D65F0" stroke="#191C22" stroke-width="0.396775"/>
      <path d="M361.445 33.3672L69.4392 52.1879V255.223" stroke="#191C22" stroke-width="1.5"/>
      <path d="M1 24.2427L17.5394 31.6569M1 24.2427L353.461 2L370 9.41422M1 24.2427V47.0556M17.5394 31.6569L370 9.41422M17.5394 31.6569L17.4824 55.6105M16.9691 271.193L1 263.779V251.232M16.9691 271.193L16.999 258.646M16.9691 271.193L370 248.38V236.403M1 251.232L16.999 258.646M1 251.232V47.0556M16.999 258.646L69.4389 255.342M16.999 258.646L17.1891 178.801M370 9.41422V32.7975M370 236.403V32.7975M370 236.403L361.445 236.942M69.4389 255.342L361.445 236.942M69.4389 255.342V251.232M1 47.0556L17.4824 55.6105M17.4824 55.6105L69.4389 52.2482M17.4824 55.6105L17.1891 178.801M370 32.7975L361.445 33.3512M69.4389 52.2482L115.635 49.2586L164.113 46.1214L212.59 42.9842L261.638 39.8101L309.831 36.6914L358.023 33.5726L361.445 33.3512M69.4389 52.2482V85.8377V118.917V151.995V175.379M17.1891 178.801L69.4389 175.379M69.4389 175.379V185.645V218.723V251.232M361.445 33.3512V236.942M361.445 236.942L352.172 233.351M352.172 233.351L69.4389 251.232M352.172 233.351V33.9513" stroke="#191C22"/>
      <path d="M1 24.2427V263.779L16.9691 271.193L370 248.38V9.41422L353.461 2L1 24.2427Z" stroke="#191C22" stroke-width="2"/>
      <path d="M352.172 34.7578L70.6538 53.3603V144.989C72.4504 132.689 92 131.5 126 152.093C147.144 164.899 163.94 72.9027 197.109 114.363C230.277 155.824 218.668 137.996 260.958 99.4376C303.248 60.8791 312.784 140.069 332.685 152.093C348.606 161.711 352.31 130.948 352.172 114.363V34.7578Z" fill="#C7D7F8" stroke="#4282FB" stroke-width="0.829214"/>
      <path d="M247 103C240.296 89.5867 222.709 63.9185 206 68.5522" stroke="#C7D7F8" stroke-width="7"/>
      <path d="M245 104C238.24 90.174 222.848 66.8581 206 71.6344" stroke="#4282FB"/>
      <path d="M249 102C242.825 88.7687 220.825 60.1539 205 58" stroke="#4282FB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M252.321 107.007C253.345 106.182 254 104.917 254 103.5C254 101.015 251.985 99 249.5 99C247.015 99 245 101.015 245 103.5C245 105.602 246.441 107.367 248.389 107.862L249 115.5H253L252.321 107.007Z" fill="#D9D9D9"/>
      <path d="M251 116C252.167 140.833 246.2 203.1 281 203.5C324.5 204 402 203.5 349 218C301.5 226 308.3 239.3 343.5 250.5" stroke="#8A8A8A" stroke-width="3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M286.9 110.933C293.944 107.544 298.805 100.34 298.805 92C298.805 80.402 289.403 71 277.805 71C266.207 71 256.805 80.402 256.805 92C256.805 100.34 261.666 107.544 268.709 110.933C267.946 111.823 267.214 112.821 266.518 113.917L258.001 109C255.131 107.343 251.462 108.326 249.805 111.196C248.148 114.066 249.131 117.735 252.001 119.392L261.702 124.993C260.928 127.62 260.292 130.45 259.815 133.437L246.805 155.971C245.148 158.841 246.131 162.51 249.001 164.167C251.871 165.824 255.54 164.841 257.197 161.971L259.564 157.872C260.077 161.618 260.838 165.14 261.805 168.351V211C261.805 214.314 264.491 217 267.805 217C271.119 217 273.805 214.314 273.805 211V186.101C275.094 186.69 276.433 187 277.805 187C279.535 187 281.212 186.507 282.805 185.583V211C282.805 214.314 285.491 217 288.805 217C292.119 217 294.805 214.314 294.805 211V164.607C296.085 159.158 296.805 153.008 296.805 146.5C296.805 131.156 292.802 117.805 286.9 110.933Z" fill="url(#paint0_linear_3063_31236)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M286.9 110.933C293.944 107.544 298.805 100.34 298.805 92C298.805 80.402 289.403 71 277.805 71C266.207 71 256.805 80.402 256.805 92C256.805 100.34 261.666 107.544 268.709 110.933C267.946 111.823 267.214 112.821 266.518 113.917L258.001 109C255.131 107.343 251.462 108.326 249.805 111.196C248.148 114.066 249.131 117.735 252.001 119.392L261.702 124.993C260.928 127.62 260.292 130.45 259.815 133.437L246.805 155.971C245.148 158.841 246.131 162.51 249.001 164.167C251.871 165.824 255.54 164.841 257.197 161.971L259.564 157.872C260.077 161.618 260.838 165.14 261.805 168.351V211C261.805 214.314 264.491 217 267.805 217C271.119 217 273.805 214.314 273.805 211V186.101C275.094 186.69 276.433 187 277.805 187C279.535 187 281.212 186.507 282.805 185.583V211C282.805 214.314 285.491 217 288.805 217C292.119 217 294.805 214.314 294.805 211V164.607C296.085 159.158 296.805 153.008 296.805 146.5C296.805 131.156 292.802 117.805 286.9 110.933Z" fill="url(#paint1_linear_3063_31236)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M319.805 152C319.805 160.34 314.944 167.544 307.9 170.933C313.802 177.805 317.805 191.156 317.805 206.5C317.805 213.008 317.085 219.158 315.805 224.607V271C315.805 274.314 313.119 277 309.805 277C306.491 277 303.805 274.314 303.805 271V245.583C302.212 246.507 300.535 247 298.805 247C297.433 247 296.094 246.69 294.805 246.101V271C294.805 274.314 292.119 277 288.805 277C285.491 277 282.805 274.314 282.805 271V228.351C281.838 225.14 281.077 221.618 280.564 217.872L278.197 221.971C276.54 224.841 272.871 225.824 270.001 224.167C267.131 222.51 266.148 218.841 267.805 215.971L280.815 193.437C282.358 183.777 285.566 175.758 289.709 170.933C282.666 167.544 277.805 160.34 277.805 152C277.805 140.402 287.207 131 298.805 131C310.403 131 319.805 140.402 319.805 152Z" fill="url(#paint2_linear_3063_31236)"/>
      <defs>
      <linearGradient id="paint0_linear_3063_31236" x1="272.402" y1="71" x2="272.402" y2="217" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="0.5" stop-color="#916A00"/>
      <stop offset="1"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3063_31236" x1="272.402" y1="71" x2="272.402" y2="217" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="0.5" stop-color="#916A00"/>
      <stop offset="1"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3063_31236" x1="293.402" y1="131" x2="293.402" y2="277" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="0.5" stop-color="#916A00"/>
      <stop offset="1"/>
      </linearGradient>
      </defs>
    </svg>

  )
}


Blade_GrowOutSprayDownVisual_EasterEgg.defaultProps = {
  width: 371
}


export function Blade_LightsManager_Standard24({width, maxWidth}) {
  let extraProps = {style:{}}
  if (maxWidth !== undefined) {
    extraProps.style.maxWidth = maxWidth
  }

  return (
    <svg width={width} viewBox="0 0 470 306" fill="none" xmlns="http://www.w3.org/2000/svg" {...extraProps}>
      <rect x="10.3184" y="13.1809" width="413.196" height="281.49" rx="5.16495" fill="#F8F8F8"/>
      <rect x="159.778" y="41.3501" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="41.3501" width="115.423" height="7.82526" fill="#1D65F0"/>
      <rect x="288.895" y="41.3501" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="72.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="103.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="72.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="72.6337" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="103.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="103.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="134.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="134.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="134.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="165.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="165.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="165.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="196.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="196.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="196.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="227.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="227.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="227.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="159.778" y="258.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="30.6611" y="258.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <rect x="288.895" y="258.634" width="115.423" height="7.82526" fill="#D0D7EB"/>
      <mask id="path-26-inside-1_3393_37977" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M470.005 5.79886C470.005 2.94634 467.693 0.633911 464.84 0.633911H5.15958C2.30706 0.633911 -0.00537109 2.94633 -0.00537109 5.79885V300.201C-0.00537109 303.054 2.30706 305.366 5.15958 305.366H464.84C467.693 305.366 470.005 303.054 470.005 300.201V5.79886ZM413.541 21.2567C414.011 21.2567 414.391 21.637 414.391 22.1062V288.985C414.391 289.454 414.011 289.834 413.541 289.834H17.539C17.0699 289.834 16.6896 289.454 16.6896 288.985V22.1062C16.6896 21.637 17.0699 21.2567 17.539 21.2567H413.541Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M470.005 5.79886C470.005 2.94634 467.693 0.633911 464.84 0.633911H5.15958C2.30706 0.633911 -0.00537109 2.94633 -0.00537109 5.79885V300.201C-0.00537109 303.054 2.30706 305.366 5.15958 305.366H464.84C467.693 305.366 470.005 303.054 470.005 300.201V5.79886ZM413.541 21.2567C414.011 21.2567 414.391 21.637 414.391 22.1062V288.985C414.391 289.454 414.011 289.834 413.541 289.834H17.539C17.0699 289.834 16.6896 289.454 16.6896 288.985V22.1062C16.6896 21.637 17.0699 21.2567 17.539 21.2567H413.541Z" fill="white"/>
      <path d="M5.15958 3.63391H464.84V-2.36609H5.15958V3.63391ZM2.99463 300.201V5.79885H-3.00537V300.201H2.99463ZM464.84 302.366H5.15958V308.366H464.84V302.366ZM467.005 5.79886V300.201H473.005V5.79886H467.005ZM417.391 22.1062C417.391 19.9801 415.667 18.2567 413.541 18.2567V24.2567C412.354 24.2567 411.391 23.2939 411.391 22.1062H417.391ZM417.391 288.985V22.1062H411.391V288.985H417.391ZM413.541 292.834C415.667 292.834 417.391 291.111 417.391 288.985H411.391C411.391 287.797 412.354 286.834 413.541 286.834V292.834ZM17.539 292.834H413.541V286.834H17.539V292.834ZM13.6896 288.985C13.6896 291.111 15.4131 292.834 17.539 292.834V286.834C18.7267 286.834 19.6896 287.797 19.6896 288.985H13.6896ZM13.6896 22.1062V288.985H19.6896V22.1062H13.6896ZM17.539 18.2567C15.4129 18.2567 13.6896 19.9803 13.6896 22.1062H19.6896C19.6896 23.2938 18.7268 24.2567 17.539 24.2567V18.2567ZM413.541 18.2567H17.539V24.2567H413.541V18.2567ZM464.84 308.366C469.35 308.366 473.005 304.71 473.005 300.201H467.005C467.005 301.397 466.036 302.366 464.84 302.366V308.366ZM-3.00537 300.201C-3.00537 304.71 0.650202 308.366 5.15958 308.366V302.366C3.96391 302.366 2.99463 301.397 2.99463 300.201H-3.00537ZM464.84 3.63391C466.036 3.63391 467.005 4.60319 467.005 5.79886H473.005C473.005 1.28948 469.35 -2.36609 464.84 -2.36609V3.63391ZM5.15958 -2.36609C0.650211 -2.36609 -3.00537 1.28946 -3.00537 5.79885H2.99463C2.99463 4.60319 3.9639 3.63391 5.15958 3.63391V-2.36609Z" fill="#DDE3EB" mask="url(#path-26-inside-1_3393_37977)"/>
    </svg>


  )
}


Blade_LightsManager_Standard24.defaultProps = {
  width: 470
}

