import './AccountRegisterPage.scss';

import { useDispatch, useSelector } from 'react-redux';
import PopupModal from '../../model_components/PopupModal';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../components/input/TextInput';
import Button from '../../components/Button';
import DropDownInput from '../../components/input/DropDownInput';
import { registerNewAccount } from '../../redux/entities/Account';
import { useNavigate } from 'react-router-dom';






const AccountRegisterPage = ({}) => {

    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const account = useSelector((state) => state.account)

    const closeClicked = () => {
        navigate("/account/login")
    }


    const [currentUserEmail, SetCurrentUserEmail] = React.useState("")
    const userEmailChanged = (email) => {
        SetCurrentUserEmail(email)
    }

    const [currentUserFullName, SetCurrentUserFullName] = React.useState("")
    const userFullNameChanged = (fullName) => {
        SetCurrentUserFullName(fullName)
    }

    const [currentUserCountry, SetCurrentUserCountry] = React.useState("ca")
    const userCountryChanged = (country) => {
        SetCurrentUserCountry(country)
    }

    const [currentUserStreetAddress, SetCurrentUserStreetAddress] = React.useState("")
    const userStreetAddressChanged = (streetAddress) => {
        SetCurrentUserStreetAddress(streetAddress)
    }

    const [currentUserCity, SetCurrentUserCity] = React.useState("")
    const userCityChanged = (city) => {
        SetCurrentUserCity(city)
    }

    const [currentUserProvince, SetCurrentUserProvince] = React.useState("")
    const userProvinceChanged = (province) => {
        SetCurrentUserProvince(province)
    }

    const [currentUserPostalCode, SetCurrentUserPostalCode] = React.useState("")
    const userPostalCodeChanged = (postalCode) => {
        SetCurrentUserPostalCode(postalCode)
    }


    const [registerAccountProcessing, SetRegisterAccountProcessing] = React.useState(false)
    const [registerAccountSuccessful, SetRegisterAccountSuccessful] = React.useState(false)
    const createAccountClicked = () => {
        SetRegisterAccountProcessing(true)
        dispatch(registerNewAccount({
            emailAddress: currentUserEmail, 
            fullName: currentUserFullName, 
            addressDetails: {
                country_code: currentUserCountry,
                province: currentUserProvince,
                city: currentUserCity,
                street_address: currentUserStreetAddress,
                postal_code: currentUserPostalCode
            },
            callback: (success) => {
                SetRegisterAccountProcessing(false)
                if (success)    {
                    SetRegisterAccountSuccessful(true)
                }
            }
        }))
    }

    const doneClicked = () => {
        //doneRegisteringCallback()
        navigate("/")
    }



    let isRegisterReady = true
    if (currentUserEmail === "")    {
        isRegisterReady = false
    }else if (currentUserFullName === "")    {
        isRegisterReady = false
    }else if (currentUserCountry === "")    {
        isRegisterReady = false
    }else if (currentUserStreetAddress === "")    {
        isRegisterReady = false
    }else if (currentUserProvince === "")    {
        isRegisterReady = false
    }else if (currentUserCity === "")    {
        isRegisterReady = false
    }else if (currentUserPostalCode === "")    {
        isRegisterReady = false
    }


    return(
        <PopupModal
            title={"Create A New Account"}
            size={"full"}
            closeCallback={closeClicked}>
            <div className="FlexContent-50" style={{padding: isMobile ? "10px 10px" : "40px 40px"}}>
                {!registerAccountSuccessful && <>
                    <div className="FlexContent-30">
                        <div className="FlexContent-20">
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S14">Enter email</div>
                                <TextInput value={currentUserEmail} placeHolder={"Email"} onChange={userEmailChanged}/>
                            </div>
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S14">Enter full name</div>
                                <TextInput value={currentUserFullName} placeHolder={"Full name"} onChange={userFullNameChanged}/>
                            </div>
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S14">Country</div>
                                <DropDownInput options={[{"value": "ca", "label": "Canada"}]} value={currentUserCountry} onChange={userCountryChanged}/>
                            </div>
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S14">Street Address</div>
                                <TextInput value={currentUserStreetAddress} placeHolder={"Address"} onChange={userStreetAddressChanged}/>
                            </div>
                            <div className={isMobile ? "FlexContent-5" : "FlexContent-H-5"}>
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">City</div>
                                    <TextInput value={currentUserCity} placeHolder={"City"} onChange={userCityChanged}/>
                                </div>
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">Province</div>
                                    <TextInput value={currentUserProvince} placeHolder={"Province"} onChange={userProvinceChanged}/>
                                </div>
                                <div className="FlexContent-5">
                                    <div className="Text-Medium-S14">Postal Code</div>
                                    <TextInput value={currentUserPostalCode} placeHolder={"Postal Code"} onChange={userPostalCodeChanged}/>
                                </div>
                            </div>
                        </div>
                        <div className="FlexContent" style={{gap:16}}>
                            <Button content={"Create Account"} status={"Primary"} onClick={createAccountClicked} disabled={!isRegisterReady || registerAccountProcessing}/>
                        </div>
                    </div>
                </>} 
                {registerAccountSuccessful && <>
                    <div className="FlexContent-30">
                        <div className="FlexContent-20">
                            <div className="FlexContent-5">
                                <div className="Text-Medium-S20">Account created, please check your email for validation</div>
                            </div>
                        </div>
                        <div className="FlexContent" style={{gap:16}}>
                            <Button content={"Done"} status={"Primary"} onClick={doneClicked} disabled={false}/>
                        </div>
                    </div>
                </>}
            </div>
        </PopupModal>
    )
}

export default AccountRegisterPage