import './CRMPage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'


const data = [
  { id: 1, name: 'Jason Mamosa', email: "mamosasarethebest@gmail.com", last_order: "Mon Jul 05 2022" },
];
 

const CRM_CustomersPage = ({pageHeaderHelper}) => {
  const columns: GridColDef[] = [
    { accessor: 'name', Header: 'Name', minWidth: "120", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'email', Header: 'Email', minWidth: "200", headerClassName: 'DataTable_Standard-Header' },
    { accessor: 'last_order', Header: 'Last Order', type: 'date', minWidth: "200", headerClassName: 'DataTable_Standard-Header' },
  ];


  return (
    <div id="CRM_CustomersPage">
        <div className="ControlBar_Horizontal">
        <TextInput placeHolder="Search" icon={<TextInput.SearchIcon/>}/>
          <DropDownInput options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
          <DropDownInput options={[{ value: 'status', label: 'Status' }]} defaultValue="status"/>
          <DropDownInput options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
        </div>
        <StandardTable id="CustomersTable" data={data} columns={columns} isSelectableRows={true} isResizable={true}/>
    </div>
  )
} 

export default CRM_CustomersPage