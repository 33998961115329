import './Switch.scss';

import * as React from 'react';



const Switch = ({state, offLabel, onLabel, onSwitch, isThumbStyle, scale, hasBorder, disabled, stopEventPropagation}) => {
  const [currentState, SetCurrentState] = React.useState(false)
  React.useLayoutEffect(() => {
    SetCurrentState(state)
  }, [state])
  const offClicked = React.useCallback(() => {
    if (currentState) {
      if (onSwitch !== undefined)  {
        onSwitch(false)
      }else {
        SetCurrentState(false)
      }
    }
  })
  const onClicked = React.useCallback(() => {
    if (!currentState) {
      if (onSwitch !== undefined)  {
        onSwitch(true)
      }else {
        SetCurrentState(true)
      }
    }
  })

  
  const toggleClicked = React.useCallback((e) => {
    if (disabled)
      return
    
    if (stopEventPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }
     if (onSwitch !== undefined)  {
      onSwitch(!currentState)
    }else {
      SetCurrentState(!currentState)
    }
  })
  
  let contentProps = {style:{
    borderRadius: 18 * scale,
    height: 18 * scale,
    width: 36 * scale
  }}

  let thumbProps = {style:{
    borderRadius: 14 * scale,
    height: 14 * scale,
    width: 14 * scale
  }}

  if (currentState) {
    thumbProps.style.left = 18 * scale
  }else {
    thumbProps.style.left = 0
  }

  let showBorder = hasBorder && isThumbStyle && (offLabel !== null || onLabel !== null)
  
  
  return (
      <>
        <div className={"Switch" + (disabled ? " Switch-Disabled" : "") + (currentState ? " Switch-On" : " Switch-Off") + (showBorder ? " Switch-WithBorder" : "")} onClick={toggleClicked}>
          {currentState && <div className="Switch-Label Switch-Label-TurnOff noselect">{offLabel}</div>}
          {!currentState && <div className="Switch-Label Switch-Label-TurnOn noselect">{onLabel}</div>}
          <div className="Switch-Content" {...contentProps}>
            {isThumbStyle && <>
              <div className="Switch-Thumb" {...thumbProps}> </div>
            </>}
            {!isThumbStyle && <>
              <div className="Switch-Button-Main Switch-Button-Off"
                  onClick={() => {offClicked()}}>
                <span className="Switch-Button-Display noselect">
                  {offLabel}
                </span>
              </div>
              <div className="Switch-Button-Main Switch-Button-On"
                  onClick={() => {onClicked()}}>
                <span className="Switch-Button-Display noselect">
                  {onLabel}
                </span>
              </div>
            </>}
          </div>
        </div>
      </>
  )
} 

Switch.defaultProps = {
  offLabel: null,
  onLabel: null,
  isThumbStyle: true,
  scale: 1.0,
  hasBorder: true,
  disabled: false,
  stopEventPropagation: true,
}

export default Switch