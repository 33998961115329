import './TransplantToGrowOutFromNurseryTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import { update } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import { SoftWarning } from '../../../../assets/icons/Icons';
import { createTransplantToGrowOutFromNurseryEntry } from '../../../../redux/entities/Grow';

const TransplantToGrowOutFromNurseryTask_ReviewStep = ({
    task,
    allBladeZones,
    activeRecipe,
    activeGrow,
    activeGrowPlan,
    selectedNurseryLocations,
    selectedNumberOfUnitsMoved,
    selectedGrowOutZone,
    transplantSuccessfulCallback,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    
    const dispatch = useDispatch()
    

    
    
    const backClicked = () =>   {
        transitionToPreviousStep(["confirm_moved_units", null])
    }
    

    let selectedBladeZone = allBladeZones.find((bZ) => bZ.uid === selectedGrowOutZone)
    let currentNumberOfGrowOutRafts = 0
    if (activeGrowPlan !== undefined)   {
        currentNumberOfGrowOutRafts = Math.floor(selectedNumberOfUnitsMoved / activeGrowPlan.grow_out_raft_usage)
    }

    const [performingTransplantToGrowOut, SetPerformingTransplantToGrowOut] = React.useState(false)
    const confirmTransplantClicked = () => {
        SetPerformingTransplantToGrowOut(true)
        dispatch(createTransplantToGrowOutFromNurseryEntry({
            taskId: task.id, 
            growId: activeGrow.id, 
            usedNurseryLocations: selectedNurseryLocations, 
            toGrowOutZoneUID: selectedGrowOutZone.toString(), 
            numberOfUnits: selectedNumberOfUnitsMoved,
            numberOfGrowOutRafts: currentNumberOfGrowOutRafts,
            growOutType: selectedBladeZone.zone_type,
            callback: (success) => {
                if (success)    {
                    transitionToNextStep(["transplant_list", null])
                }
                transplantSuccessfulCallback()
                SetPerformingTransplantToGrowOut(false)
            }
        }))
    }
    
    


    


    


    let nurseryRowOptions = [
        {value: 1, label: "Row A", tagLabel: "A"},
        {value: 2, label: "Row B", tagLabel: "B"},
        {value: 3, label: "Row C", tagLabel: "C"},
        {value: 4, label: "Row D", tagLabel: "D"},
        {value: 5, label: "Row E", tagLabel: "E"},
        {value: 6, label: "Row F", tagLabel: "F"},
        {value: 7, label: "Row G", tagLabel: "G"},
    ]

    let irrigationType = activeRecipe !== undefined ? (activeRecipe.grow_out_type === "grow_boards" ? "aeroponics" : "drip irrigation") : "aeroponics/drip irrigation"
    


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-30" style={{padding: isMobile ? "0 10px" : "20px 100px"}}>
                        <div className="FlexContent-20">
                            <div className="Text-SubHeader">Confirm that these new locations are accurate</div>
                            <div className="FlexContent-H-10 FlexContent-Center">
                                <div style={{flex:0}}><SoftWarning fill={"#EC3C3C"}/></div>
                                <div className="Text-Subtext">
                                    Confirming will immediately activate the grow out zone and {irrigationType} will begin along with dosing
                                </div>
                            </div>
                        </div>
                        <div className="FlexContent-H-10">
                            <div className="Text-SubHeader">Boards Used:</div>
                            <div className="Text-AuxSubtext" style={{color:"#4D5563"}}>{currentNumberOfGrowOutRafts}</div>
                        </div>
                        <div className={isMobile ? "FlexContent-30" : "FlexContent-H-20"}>
                            <div className={"FlexContent-10" + (isMobile ? " Linebreak-20" : " Linebreak-R-20")} style={{flexBasis:"50%"}}>
                                <div className="FlexContent-10" style={{alignSelf:"flex-start"}}>
                                    <div className="Text-SubHeader">Nursery Board Locations</div>
                                    {Object.entries(selectedNurseryLocations).map(([nurseryUID, nurseryRowLocations]) => {
                                        let nurseryZone = allBladeZones.find((bZ) => bZ.uid === nurseryUID)
                                        return (
                                            <div className="FlexContent-10">
                                                <div className="Text-Subtext">{nurseryZone !== undefined ? nurseryZone.display_name : ""}</div>
                                                <div className="FlexContent-10" style={{flexGrow: 0}}>
                                                    {Object.entries(nurseryRowLocations).map(([rowIndex, locations]) => {
                                                        let foundRowOption = nurseryRowOptions.find((o) => o.value === parseInt(rowIndex))
                                                        
                                                        return <>
                                                            <div className="FlexContent-H-15" style={{alignItems:"center"}}>
                                                                <div className="Text-Subtext">{foundRowOption.tagLabel}</div>
                                                            
                                                                <div className="FlexWrap FlexWrap-Size5" style={{flexGrow: 0}}>
                                                                    {locations.map((location) => {
                                                                        return (
                                                                            <Tag content={location} status={"Neutral"}/>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                            </div>
                            <div className="FlexContent-20" style={{flexBasis:"50%"}}>
                                <div className="Text-SubHeader">New Growout Zone</div>
                                <div className="Text-AltSubtext">{selectedBladeZone !== undefined ? selectedBladeZone.display_name : "Unnamed Zone"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Confirm"} onClick={confirmTransplantClicked} size={"Flex50"} disabled={performingTransplantToGrowOut}/>
            </div>
        </div>
    )
    
}


export default TransplantToGrowOutFromNurseryTask_ReviewStep