import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'
import { getAccountSessionData } from '../../../pages/Account/Common'


const processHVACFromAPI = (HVAC) =>  {
  


  return HVAC
}

const processHVACPropertyFromAPI = (HVAC, key, value) =>  {
  console.log(key, value)
  switch (key)  {
    default:
      break
  }
}

export const getAllHVAC = createAsyncThunk('HVAC/getAllHVAC', async ({facilityId}, { getState }) => {
  return await FetchPost(APIRepository.HVAC.GetAllHVACForFacility, {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    have_grow_plans: {}
  })  
})

export const getHVACById = createAsyncThunk('HVAC/getHVACById', async ({HVACId, HVACIds}, { getState }) => {
  console.log(HVACId)
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (HVACId !== undefined) {
    payload.hvac_id = HVACId
  }
  if (HVACIds !== undefined) {
    payload.hvac_ids = HVACIds
  }
  return await FetchPost(APIRepository.HVAC.GetHVACById, payload)  
},
{
  condition: (args, { getState }) => {
    const { HVAC } = getState()
    if (HVAC.status === 'pending') {
      return false
    }
  },
})


export const getHVACByServiceId = createAsyncThunk('HVAC/getHVACByServiceId', async ({HVACServiceId, HVACServiceIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (HVACServiceId !== undefined) {
    payload.service_id = HVACServiceId
  }
  if (HVACServiceIds !== undefined) {
    payload.service_ids = HVACServiceIds
  }
  return await FetchPost(APIRepository.HVAC.GetHVACByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { HVAC } = getState()
    if (HVAC.status === 'pending') {
      return false
    }
  },
})


export const getHVACProperty = createAsyncThunk('HVAC/getHVACProperty', async ({HVACId, key, keys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    hvac_id: HVACId
  }  
  if (key !== undefined) {
    payload.key = key
  }
  if (keys !== undefined) {
    payload.keys = keys
  }
  return await FetchPost(APIRepository.HVAC.GetHVACProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { HVAC } = getState()
    if (HVAC.status === 'pending') {
      return false
    }
  },
})

export const setHVACProperty = createAsyncThunk('HVAC/setHVACProperty', async ({HVACId, key, value, values}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    hvac_id: HVACId
  }  
  if (key !== undefined && value !== undefined) {
    payload.key = key
    payload.value = value
  }
  if (values !== undefined) {
    payload.values = values
  }
  return await FetchPost(APIRepository.HVAC.SetHVACProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { HVAC } = getState()
    if (HVAC.status === 'pending') {
      return false
    }
  },
})

export const HVACsSlice = createSlice({
  name: 'HVAC',
  initialState: {
    HVAC:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllHVAC.pending]: (state) => {
      state.status = 'pending';
    },
    [getAllHVAC.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.HVAC.map(function(HVAC){ processHVACFromAPI(HVAC); return HVAC });
      state.HVAC = action.payload.ndss;
    },
    [getAllHVAC.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getHVACById.pending]: (state) => {
      state.status = 'pending';
    },

    [getHVACById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.nds !== null) {
        state.HVAC.push(processHVACFromAPI(action.payload.nds))
      }*/
      if (action.payload.ndss !== null) {
        for (let HVAC of action.payload.ndss)  {
          if (HVAC)  {
            state.HVAC.push(processHVACFromAPI(HVAC))
          }
        }
      }
    },

    [getHVACById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getHVACByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getHVACByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';

      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.hvacs !== undefined && action.payload.hvacs !== null) {
        for (let HVAC of action.payload.hvacs)  {
          //console.log(HVAC)
          if (HVAC)  {
            let exists = false
            for (let existingHVAC of state.HVAC) {
              if (existingHVAC.id === HVAC.id)  {
                existingHVAC = processHVACFromAPI({...existingHVAC, ...HVAC})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.HVAC.push(processHVACFromAPI(HVAC))              
            }
          }
        }
      }
    },

    [getHVACByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getHVACProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [getHVACProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      
      const HVAC = state.HVAC.find((n) => { return n.id === action.meta.arg.HVACId; })
      if (HVAC !== undefined)  {
        //Do something with the resulting property
        if (action.payload.value !== null) {
          processHVACPropertyFromAPI(HVAC, action.meta.arg.key, action.payload.value)
        }
        if (action.payload.values !== null) {
          for (let [key, value] of Object.entries(action.payload.values))  {
            processHVACPropertyFromAPI(HVAC, key, value)
          }
        }
      }
    },

    [getHVACProperty.rejected]: (state) => {
      state.status = 'rejected';
    },

    [setHVACProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [setHVACProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //Do something with the resulting property
    },

    [setHVACProperty.rejected]: (state) => {
      state.status = 'rejected';
    },
    
  }
})



// Action creators are generated for each case reducer function
export const { } = HVACsSlice.actions

export default HVACsSlice.reducer

export const selectAllHVAC = state => state.HVAC.HVAC
