import './CRMPage.scss';
import React from 'react';


import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
 
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

const CRM_ProductPage = ({pageHeaderHelper}) => {
  let data = useSelector((state) => state.products)
  let products = data.rows


  const { productID } = useParams();
  let product = products.find(e => e.id.toString() === productID.toString())


  const navigate = useNavigate();
  React.useEffect(() => {
    if (product === undefined || (!/\d+/.test(productID))) {
      navigate(-1);
    }
  }, [productID, navigate]);
 

  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    if (product !== undefined) {
      pageHeaderHelper.current.SetTitle(product.name)
    }
    pageHeaderHelper.current.SetSubtitle("Edit Product")
    pageHeaderHelper.current.SetNavigateBackActive(true)
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              
            </div>
          </div>
        </>
      )
    })
    pageHeaderHelper.current.SetCondensed(false)
  }, [pageHeaderHelper])


  return (
    <div className="PageWrapper_Standard" id="Product_Page">
      <div>
        {productID}
      </div>
    </div>
  )
} 

export default CRM_ProductPage