import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Close } from '../../assets/icons/Icons';
import useMeasure from '../../useMeasure';
import './FullScreenPhotoViewer.scss';
import React from 'react'


const FullScreenPhotoViewer = ({src, onClose}) => {


  const [photoViewerRef, {width: photoViewerWidth, height: photoViewerHeight}] = useMeasure()

  const [closeButtonContainerRef, {width: closeButtonContainerWidth}] = useMeasure()

  const [photoDimensions, SetPhotoDimensions] = React.useState(null)
  React.useLayoutEffect(() => {
    if (src !== null) {
      const image = new Image();
      image.onload = () => {
        SetPhotoDimensions({width: image.width, height: image.height})
      };
      image.src = src;
    }else {
      SetPhotoDimensions(null)
    }
  }, [src])


  const [activePhotoSize, SetActivePhotoSize] = React.useState({width: 0, height: 0})
  React.useLayoutEffect(() => {
    if (photoDimensions)  {
      let currentWidth = photoDimensions.width
      let currentHeight = photoDimensions.height

      let widthRatio = currentWidth / photoViewerWidth
      let heightRatio = currentHeight / photoViewerHeight

      let maxWidth = photoViewerWidth
      let maxHeight = photoViewerHeight

      if (widthRatio < heightRatio) {
        maxWidth -= (closeButtonContainerWidth * 2)
      }else {
        maxHeight -= (closeButtonContainerWidth * 2)
      }


      if (currentWidth > maxWidth) {
        let ratio = maxWidth / currentWidth
        currentWidth = maxWidth
        currentHeight = currentHeight * ratio
      }

      if (currentHeight > maxHeight) {
        let ratio = maxHeight / currentHeight
        currentHeight = maxHeight
        currentWidth = currentWidth * ratio
      }

      SetActivePhotoSize({width: currentWidth, height: currentHeight})
    }else {
      SetActivePhotoSize({width: 0, height: 0})
    }
  }, [photoDimensions, closeButtonContainerWidth, photoViewerWidth, photoViewerHeight])


  let photoContainerProps = {style:{
    left: (photoViewerWidth - activePhotoSize.width) / 2,
    top: (photoViewerHeight - activePhotoSize.height) / 2,
    width:activePhotoSize.width,
    height:activePhotoSize.height
  }}




  const closeClicked = () => {
    if (onClose !== undefined)  {
      onClose()
    }
  }

  return (
    <div className={"FullScreenPhotoViewer"} ref={photoViewerRef}>
        <div className="FullScreenPhotoViewer-CloseButton" ref={closeButtonContainerRef} onClick={closeClicked}>
          <Close fill={"#fff"} width={20}/>
        </div>
        <div className="FullScreenPhotoViewer-ImageContainer" {...photoContainerProps}>
          <TransformWrapper>
            <TransformComponent>
              <img className={"FullScreenPhotoViewer-Image"} src={src}/>
            </TransformComponent>
          </TransformWrapper>
        </div>
    </div>
  )
} 

FullScreenPhotoViewer.defaultProps = {
  
}


export default FullScreenPhotoViewer