import './ExpensesPage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardTable from '../../components/StandardTable.js'

import Badge from '../../components/Badge.js'
import StandardList from '../../components/StandardList/StandardList';
import { FormatDate } from '../../helpers';




const expenses = [
  { id: 1, name: 'Some Item 1', cost: 35, category: "Consumables", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021" },
  { id: 2, name: 'Some Item 2', cost: 42, category: "Consumables", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 3, name: 'Some Item 3', cost: 45, category: "Spare Part", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 4, name: 'Some Item 4', cost: 16, category: "Consumables", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 5, name: 'Some Item 5', cost: null, category: "Consumables", usage_breakdown: {name: "attention required", status: "Warning"}, last_updated: "Mon Jul 05 2021"  },
  { id: 6, name: 'Some Item 6', cost: 150, category: "Product", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 7, name: 'Some Item 7', cost: 44, category: "Consumables", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 8, name: 'Some Item 8', cost: 36, category: "Product", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
  { id: 9, name: 'Some Item 9', cost: 65, category: "Spare Part", usage_breakdown: {name: "available", status: "Neutral"}, last_updated: "Mon Jul 05 2021"  },
];



const ExpensesPage = ({setPageHeader}) => {
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])

   const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 15, resizable: true, sort_by: false},
    cost: {render: (<div className="StandardList-Header-Column">Cost</div>), width: 6, resizable: true, sort_by: false},
    category: {render: (<div className="StandardList-Header-Column">Category</div>), width: 15, resizable: true, sort_by: false},
    usage: {render: (<div className="StandardList-Header-Column">Usage</div>), width: 20, resizable: true, sort_by: false},
    lastUpdated: {render: (<div className="StandardList-Header-Column">Last Updated</div>), minWidth: 20, resizable: true, sort_by: false}
  }

  const [expensesList, SetExpensesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")  
  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  React.useEffect(() => {
    let currentExpensesList = {}

    let currentExpenses = []
    

    if (currentSearchInput === "")  {
      currentExpenses = [...expenses]
    }else {
      currentExpenses.push(...expenses.filter((expense) => {
        if (expense.name.indexOf(currentSearchInput) !== -1 && currentExpenses.indexOf(expense) === -1)  {
          return expense
        }
      }))
    }
    
    
    for (let expense of currentExpenses) {
      currentExpensesList[expense.id] = {
        data: expense,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{expense.name}</div>
            </div>),
          },
          cost: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">${(expense.cost / 100).toFixed(2)}</div>
            </div>),
          },
          category: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary"><Badge status="Neutral" content={expense.category} size="Medium"/></div>
            </div>),
          },
          usage: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary"><Badge status={expense.usage_breakdown.status} content={expense.usage_breakdown.name} size="Medium"/></div>
            </div>),
          },
          lastUpdated: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{FormatDate(new Date(expense.last_updated), "MM/d/yyyy")}</div>
            </div>),
          },
        }
      }
    }

    SetExpensesList(currentExpensesList)
  }, [expenses, currentSearchInput])

  const expenseClicked = (expense) => {
    //navigate("/farms/expense/" + expense.data.id);
  }
  
  return (
    <div id="ExpensesPage">
        <StandardList 
          columns={columns} 
          showHeader={true}
          rows={expensesList}
          isSearchable={true}
          isMultiSelectable={true}
          resourcesAttached={false}
          onSearchInput={onSearchInput}
          onRowClicked={expenseClicked}
          loadingData={false}/>
    </div>
  )
} 

export default ExpensesPage