import './CreateControlDeviceWalkthrough.scss';
import React from 'react';
import { selectAllControlDeviceGroups, selectAllControlDevices } from '../../../redux/entities/service/ControlDevice';
import { useSelector } from 'react-redux';
import Walkthrough from '../../../components/Walkthrough';
import ProgressBar from '../../../components/ProgressBar';
import SelectControlDeviceSerialNumberWalkthroughStep from './CreateControlDeviceWalkthroughSteps/SelectControlDeviceSerialNumberWalkthroughStep';
import ConfirmCreateControlDeviceWalkthroughStep from './CreateControlDeviceWalkthroughSteps/ConfirmCreateControlDeviceWalkthroughStep';


  

const CreateControlDeviceWalkthrough = ({facilityId, completeCallback, closeCallback}) => {
   
    const [numberOfSteps, SetNumberOfSteps] = React.useState(7)
    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)

    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }
    const confirmedCancelClicked = (e) =>  {
        

        if (completeCallback !== undefined) {
            completeCallback()
        }
    }


    const [controlDeviceSerialNumber, SetControlDeviceSerialNumber] = React.useState("")


    


    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["control_device_serial_number", null])

    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }

    

    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "control_device_serial_number":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            
            case "final_confirm":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break


            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    let showsPopupHeader = true



    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]

        switch (stepKey)    {
            case "control_device_serial_number":
                const controlDeviceSerialNumberSelectedCallback = ({controlDeviceSerialNumber}) =>  {
                    SetControlDeviceSerialNumber(controlDeviceSerialNumber)
                }
                return (<SelectControlDeviceSerialNumberWalkthroughStep
                            initialControlDeviceSerialNumber={controlDeviceSerialNumber} 
                            facilityId={facilityId} 
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            cancelCallback={closePopup}
                            controlDeviceSerialNumberSelectedCallback={controlDeviceSerialNumberSelectedCallback}/>)
                

            case "final_confirm":
                const controlDeviceConfirmedCallback = () =>  {
                    completeCallback()   
                }

                
                return (<ConfirmCreateControlDeviceWalkthroughStep
                            facilityId={facilityId}
                            controlDeviceType={"blade"}
                            controlDeviceSerialNumber={controlDeviceSerialNumber}
                            transitionToPreviousStep={transitionToPreviousStep} 
                            transitionToNextStep={transitionToNextStep}
                            controlDeviceConfirmedCallback={controlDeviceConfirmedCallback}/>)

            default:
                return <></>
        }
    }


    return (<>
        <Walkthrough
            id={"CreateControlDeviceWalkthrough_Popup"}
            title={<>
                {showsPopupHeader && <>
                    <span>Create Control Device</span>
                    {/*<span>Step {currentStepIndex}/7</span>*/}
                </>}
            </>}
            subHeaderContent={<>
                <div className="FlexContent-HFill" style={{flex:1}}>
                    <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStepIndex} completedBarFill={"#ABC7ED"}/>
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closePopup}
            size={"full_screen"}
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["control_device_serial_number", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    </>)

}



export default CreateControlDeviceWalkthrough