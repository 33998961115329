import './../TasksPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../../components/Button';
import { markTaskAsCompleted, markTaskAsStarted } from '../../../redux/entities/Tasks';
import { getGrowById } from '../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../redux/entities/GrowPlans';
import { getRecipeById } from '../../../redux/entities/Recipes'
import ControlBar from '../../../components/ControlBar';
import PopupModal from '../../../model_components/PopupModal';
import NumberInput from '../../../components/input/NumberInput';
import { Raft } from '../../../model_components/RaftType';
  

const TaskPopup_PlantRootBaseInGrowOut = ({task, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  let growsList = useSelector((state) => state.grows.grows)
  let growPlansList = useSelector((state) => state.growPlans.growPlans)
  let recipesList = useSelector((state) => state.recipes.recipes)
 
  const closePopup = () =>  {
    onClosePopup()
  }

  const [grow, SetGrow] = React.useState(null)
  const [growZones, SetGrowZones] = React.useState({
    
    3: {
      name: "Zone A",
      uid: "31020"
    },
    4: {
      name: "Zone B",
      uid: "31021"
    }
  })
  const [growPlans, SetGrowPlans] = React.useState([])
  const [recipe, SetRecipe] = React.useState(null)

  const [currentStep, SetStep] = React.useState("pending")
  
  
  React.useEffect(() => {
    let foundGrow = growsList.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }else {
      SetGrow({...foundGrow})
    }
  }, [task, growsList])

  React.useEffect(() => {
    
    if (grow) {
      
      
    }
    
    //set defaults
    console.log(growZones)
    SetDesiredDestination(3)
  }, [grow])

  React.useEffect(() => {
    if (grow) {
      let foundRecipe = recipesList.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
      console.log(grow, recipesList, foundRecipe)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipeId: grow.recipe_id, recipeVersion: grow.recipe_version}))
      }else {
        SetRecipe({...foundRecipe})
      }
    }
  }, [grow, recipesList])

  const [desiredDestination, SetDesiredDestination] = React.useState(null)


  

  

  const startTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsStarted({taskId: task.id}))
    //SetSelectedNumberOfPlugs(desiredNumberOfPlugs)
  })

  const completeTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsCompleted({taskId: task.id, params: {"zone_uid": growZones[desiredDestination].uid}}))
    onClosePopup()
  })



  let footerContent = <></>
  if (!task.started) {
    footerContent = <>
       <ControlBar controls={(<>
          <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
        </>)} secondaryControls={(<>
            {<Button status="Primary" content="Start" onClick={startTaskClicked}/>}
        </>)}/>
    </>
  }else {
    footerContent = <>
      <ControlBar secondaryControls={(<>
         {<Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>}
      </>)}/>
    </>
  }


  return (
    <PopupModal 
      title={task.name + (grow ? " #G" + grow.id : "")}
      closeCallback={closePopup}
      footer={footerContent}>

      {currentStep === "pending" && <>
        {(grow && recipe) && <>
          <div className="PopupModal-FormItem">
            <div className="PopupModal-FormItem-Question">
              <span>Place root bases into <span style={{fontWeight:"bold"}}>{growZones[desiredDestination].name}</span></span>
              <div className="PopupModal-FormItem-Question-Help">
                <span>Use <span style={{fontWeight:"bold"}}>28</span> of the following root bases</span>
              </div>
            </div>
            
          </div>
        </>}
      </>}


    </PopupModal>

  )
} 

export default TaskPopup_PlantRootBaseInGrowOut