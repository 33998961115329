import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { HalfFullCup, LowContainer, MixContainer, MixCup, UnmixedContainer, UnmixedCup } from '../../../../assets/icons/Icons';
import { RoundToNearest } from '../../../../helpers';

  

const ConfirmExternalReservoirMixWalkthroughStep = ({NDS, reservoirInfo, waterBeingMixed, transitionToPreviousStep, transitionToNextStep}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    let mixType = reservoirInfo["mix_type"]
    let mixRatio = reservoirInfo["mix_ratio"]

    let mixAmount = 0
    if (mixType === "solids")   {
        mixAmount = RoundToNearest(waterBeingMixed * mixRatio, 0.1)
    }else if (mixType === "liquid") {
        mixAmount = RoundToNearest(waterBeingMixed / (mixRatio - 1), 0.001)
    }

    const backClicked = () => {
        transitionToPreviousStep(["confirm_external_reservoir_water_volume", null])
    }

    const nextClicked = () =>   {
        transitionToNextStep(["confirm_final_volume", null])
    }



    let mixDisplayContent = <>{mixAmount}{mixType === "solids" && "g"}{mixType === "liquid" && "L"}</>
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Mix Container</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {isMobile && <>
                        <div className="FlexContent-40">
                            {waterBeingMixed !== 0 && 
                                <div className="FlexContent-10">
                                    <div className="FlexContent-10 FlexContent-Center">
                                        <div style={{fontWeight:500}}>Step 1/3</div>
                                    </div>                                
                                    <div className="FlexContent-H-5 FlexContent-Center">Fill container with <div style={{color:"#2E72D2"}}>{waterBeingMixed}L</div> of water</div>
                                </div>
                            }
                            <div className="FlexContent-10">
                                <div className="FlexContent-10 FlexContent-Center">
                                     <div style={{fontWeight:500}}>{waterBeingMixed !== 0 ? "Step 2/3": "Step 1/2"}</div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Add <div style={{color:"#2E72D2"}}>{mixDisplayContent}</div> of {reservoirInfo.item["display_name"]}</div>
                            </div> 
                            <div className="FlexContent-10">
                                <div className="FlexContent-10 FlexContent-Center">
                                     <div style={{fontWeight:500}}>{waterBeingMixed !== 0 ? "Step 3/3": "Step 2/2"}</div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Stir mixture for at least <div style={{color:"#2E72D2"}}>15 seconds</div></div>
                            </div> 
                        </div>
                    </>}
                    {!isMobile && <>
                        <div className="FlexContent-30" style={{margin:"10px 30px"}}>
                            {waterBeingMixed !== 0 && 
                                <div className="FlexContent-H-30">
                                    <div className="FlexContent-H-30 FlexContent-Center">
                                        <div style={{fontWeight:500}}>Step 1/3</div>
                                        <div><HalfFullCup width={47}/></div>
                                    </div>                                
                                    <div className="FlexContent-H-5 FlexContent-Center">Fill container with <div style={{color:"#2E72D2"}}>{waterBeingMixed}L</div> of water</div>
                                </div>
                            }
                            <div className="FlexContent-H-30">
                                <div className="FlexContent-H-30 FlexContent-Center">
                                    <div style={{fontWeight:500}}>{waterBeingMixed !== 0 ? "Step 2/3": "Step 1/2"}</div>
                                    <div><UnmixedCup width={47}/></div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Add <div style={{color:"#2E72D2"}}>{mixDisplayContent}</div> of {reservoirInfo.item["display_name"]}</div>
                            </div> 
                            <div className="FlexContent-H-30">
                                <div className="FlexContent-H-30 FlexContent-Center">
                                    <div style={{fontWeight:500}}>{waterBeingMixed !== 0 ? "Step 3/3": "Step 2/2"}</div>
                                    <div><MixCup width={47}/></div>
                                </div>                                
                                <div className="FlexContent-H-5 FlexContent-Center">Stir mixture for at least <div style={{color:"#2E72D2"}}>15 seconds</div></div>
                            </div> 
                        </div>
                    </>}
                </div>        
            </div>               
            <div className="Walkthrough-Card-FooterButtons">
                <Button status={"Neutral"} content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ConfirmExternalReservoirMixWalkthroughStep