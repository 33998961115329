import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, ScanRack } from '../../../../assets/icons/RackIcons';
import { useDispatch, useSelector } from 'react-redux';
import { getBladeBySerialNumber, selectBladeBySerialNumber } from '../../../../redux/entities/service/Blade';


  

const ValidateBladeWalkthroughStep = ({bladeSerialNumber, bladeUID, currentStep, transitionToPreviousStep, transitionToNextStep, isTransitioning, foundBladeCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()

    const foundBlade = useSelector((state) => selectBladeBySerialNumber(state, bladeSerialNumber))

    const loadingBladeStatus = useSelector((state) => state.blade.loadingBladeStatus)
    const [foundBladeResult, SetFoundBladeResult] = React.useState(null)
    React.useEffect(() => {
        if (isTransitioning)    {
            return
        }
        if (currentStep === "setup") {
            if (foundBlade !== undefined)   {
                SetFoundBladeResult(true)
                foundBladeCallback({currentBladeUID: foundBlade.uid})
            }else if (loadingBladeStatus !== "pending") {
                dispatch(getBladeBySerialNumber({bladeSerialNumber: bladeSerialNumber, callback: (success, blade) => {
                    if (success)    {
                        SetFoundBladeResult(true)
                        foundBladeCallback({currentBladeUID: blade.uid})
                    }else {
                        SetFoundBladeResult(false)
                    }
                }}))
            }
        }
    }, [isTransitioning, currentStep, foundBlade, loadingBladeStatus])

    React.useLayoutEffect(() => {
        if (foundBladeResult === true)  {
            transitionToNextStep(["validate_blade", "found_blade"])
        }else if (foundBladeResult === false)   {
            transitionToNextStep(["validate_blade", "not_found"])
        }
    }, [foundBladeResult])

    


    switch (currentStep) {
        case "setup":
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackLoading/>
                        <div className="PopupModal-StandardHeader">Finding Blade</div>
                    </div>
                </div>
            )
        
        case "not_found":
            const tryAnotherCodeClicked = () => {
                transitionToPreviousStep(["get_blade_code", null])
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <div className="FlexContent-50">
                            <div className="FlexContent-30 FlexContent-Center">
                                <RackConnectionFailed width={isMobile ? 180 : 224}/>
                                <div className="PopupModal-StandardHeader">Blade Not Found</div>
                            </div>

                            <div className="FlexContent-30">
                                <div className="FlexContent-HFill">
                                    <Button content={"Try Another Code"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={tryAnotherCodeClicked} width={"Flex100"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )

            
        case "found_blade":
            const setupBladeClicked = () =>  {
                //rackConnectedCallback()
                transitionToNextStep(["select_blade_group", "blade_group_listing"])
            }

            const connectionSetupClicked = () => {
                transitionToNextStep(["connecting_to_blade", "connection_options"])
            }

            let isBladeOnline = false
            if (foundBlade !== undefined && foundBlade.control_device !== null) {
                if (foundBlade.control_device.connected)    {
                    isBladeOnline = true
                }
            }
            return (
                <div className="Walkthrough-Card">
                    <div className="Walkthrough-Card-Content Walkthrough-Card-Content-Center">
                        <RackConnectionSuccess/>
                        <div className="FlexContent-40 FlexContent-Center">
                            <div className="Walkthrough-StandardHeader Walkthrough-StandardHeader-Center">{foundBlade !== undefined && foundBlade.display_name} Found</div>
                            {isBladeOnline && <>
                                <div className="Text-S14">Blade already online!</div>
                            </>}
                            {!isBladeOnline && <>
                                <div className="Text-S14">Blade needs to be connected to the internet</div>
                            </>}                            
                        </div>                    
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        {isBladeOnline && <>
                            <Button content="Setup Blade" size={isMobile ? "Medium" : "Large"}  status="Primary" onClick={setupBladeClicked} width={"Flex100"}/>
                        </>}
                        {!isBladeOnline && <>
                            <Button content="Connect Blade" size={isMobile ? "Medium" : "Large"}  status="Primary" onClick={connectionSetupClicked} width={"Flex100"}/>
                        </>}
                    </div>
                </div>
            
            )
                    

        default:
            return (<></>)
    }
}
  


export default ValidateBladeWalkthroughStep