import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { RoundToNearest } from '../../../../helpers';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { useDispatch } from 'react-redux';
import { setNDSProperty } from '../../../../redux/entities/service/NDS';

  

const ConfirmFinalReservoirVolumeWalkthroughStep = ({NDS, reservoirInfo, topUpType, expectedReservoirVolume, transitionToPreviousStep, transitionToNextStep, finalReservoirVolumeCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()

    const [finalReservoirVolume, setFinalReservoirVolume] = React.useState(expectedReservoirVolume)

    


    
    const [selectedUnit, SetSelectedUnit] = React.useState("litres")
    const availableUnits = [
            {"value": "litres", "label": "Litres"}
    ]

    const backClicked = () => {
        if (topUpType === "within_reservoir")   {
            transitionToPreviousStep(["confirm_internal_mix", null])        
        }else if (topUpType === "external_reservoir") {
            transitionToPreviousStep(["confirm_external_mix", null])        
        }
    }
    const [isApplyingFinalVolume, SetIsApplyingFinalVolume] = React.useState(false)
    const nextClicked = () =>   {
        if (!isApplyingFinalVolume) {
            SetIsApplyingFinalVolume(true)


            dispatch(setNDSProperty({NDSId: NDS.id, values: {
                "nutrient_reservoir_new_volume": finalReservoirVolume * 1000,
                "nutrient_reservoir_index": reservoirInfo.index,
            }, callback: (success) => {
                SetIsApplyingFinalVolume(false)
                if (success)    {
                    finalReservoirVolumeCallback(finalReservoirVolume)
                    transitionToNextStep(["confirm_reservoir_reinstall", null])
                }else {
                    //something went wrong
                }
            }}))
        }
    }





    
    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Final Volume</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    
                    {isMobile && <>
                        <div className="FlexContent-20 FlexContent-HFill FlexContent-HFlex">
                            <div style={{textAlign:"center", fontSize:18, margin: "0 10px"}}>What is the new volume of the reservoir?</div>
                            <div className="FlexContent-10 FlexContent-Center">
                                <KeypadInput_DisplayField value={expectedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} active={true} verticalPadding={14} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                <DropDownInput value={selectedUnit} options={availableUnits}/>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={expectedReservoirVolume} 
                                    buttonVerticalPadding={5} 
                                    buttonHorizontalPadding={10}
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {setFinalReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {isTablet && <>
                        <div className="FlexContent-20 FlexContent-Center">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>What is the new volume of the reservoir?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={expectedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} verticalPadding={15} active={true} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={expectedReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {setFinalReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                    </>}
                    {(!isTablet && !isMobile) && <>
                        <div className="FlexContent-H-40">
                            <div className="FlexContent-40" style={{margin:"20px 0"}}>
                                <div style={{textAlign:"center", fontSize:24}}>What is the new volume of the reservoir?</div>
                                <div className="FlexContent-10 FlexContent-Center">
                                    <KeypadInput_DisplayField value={expectedReservoirVolume} resolution={0.1} maxLength={8} suffix={"L"} active={true} verticalPadding={30} inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                                    <DropDownInput value={selectedUnit} options={availableUnits}/>
                                </div>
                            </div>
                            <div className="FlexContent" style={{flexShrink:0}}>
                                <KeypadInput_Keypad 
                                    value={expectedReservoirVolume} 
                                    buttonVerticalPadding={15} 
                                    inputFieldRef={keypadInputFieldRef.current}
                                    onChange={(value) => {setFinalReservoirVolume(parseFloat(value))}}/>
                            </div>
                        </div>
                            
                    </>}


                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={isApplyingFinalVolume ? "Applying..." : "Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={isApplyingFinalVolume}/>
            </div>
        </div>
    )
    
}


export default ConfirmFinalReservoirVolumeWalkthroughStep