import './Blade_CleanseTask';

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../../components/Button';
import { Blade_GrowOutSprayDownVisual } from '../../../../../assets/icons/RackIcons';
import { WalkthroughCarousel } from '../../../../../components/WalkthroughCarousel';
import { StandardFilterHousing_CleanFilterVisual, StandardFilterHousing_Complete, StandardFilterHousing_FilterContainerWithFilter, StandardFilterHousing_InstallFilterIntoHousingVisual, StandardFilterHousing_InstallHousingVisual, StandardFilterHousing_RemoveFilterFromHousingVisual, StandardFilterHousing_RemoveHousingVisual } from '../../../../../assets/icons/ProductIcons';

const Blade_CleanseTask_ChangeFilterStep = ({
        task,
        confirmFilterChangedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const finishedClicked = () => {
        confirmFilterChangedCallback()
    }

    const carouselHeight = isMobile ? 150 : 200
    const carouselWidth = 350

    const slides = [
        {content: <>
            <div className="FlexContent-H-10">
                <StandardFilterHousing_RemoveHousingVisual height={carouselHeight}/>
            </div>
        </>, description: "Unscrew the container from the blade"},
        
        {content: <>
            <div className="FlexContent-H-10">
                <StandardFilterHousing_RemoveFilterFromHousingVisual height={carouselHeight}/>
            </div>
        </>, description: "Remove filter from the container"},

        {content: <>
            <div className="FlexContent-H-10">
                <StandardFilterHousing_CleanFilterVisual height={carouselHeight}/>
            </div>
        </>, description: "Thoroughly rinse the filter of any debris, or swap out to a new filter"},

        {content: <>
            <div className="FlexContent-H-10">
                <StandardFilterHousing_InstallFilterIntoHousingVisual height={carouselHeight}/>
            </div>
        </>, description: "Place the filter back in the container with the 2 seals in place"},

        {content: <>
            <div className="FlexContent-H-10">
                <StandardFilterHousing_InstallHousingVisual height={carouselHeight}/>
            </div>
        </>, description: "Screw the container back on to the blade"},
    ]

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={(isMobile ? "Walkthrough-Card-MobileHeader" : "Walkthrough-Card-Header") + " Walkthrough-Card-HeaderWithSeperator"}>
                <div className="Walkthrough-Card-HeaderContent">
                    Change filters
                </div>
            </div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <WalkthroughCarousel slides={slides} slideHeight={carouselHeight} slideWidth={carouselWidth}/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Primary" content={"Done"} onClick={finishedClicked} size={"Flex100"}/>
            </div>
        </div>
    )
    
}


export default Blade_CleanseTask_ChangeFilterStep