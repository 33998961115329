import './GroupedOptions.scss';

import * as React from 'react';
import Button from '../Button';

import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {MdKeyboardArrowDown} from 'react-icons/md'
import Checkbox from '@mui/material/Checkbox';



const GroupedOptions = ({uid, label, options, groupActive, prefix, suffix, prefixComponent, suffixComponent, onGroupToggle, onOptionChange}) => {
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const optionsRef = React.useRef(options)

  const prevOpen = React.useRef(open);

  const groupedToggled = () =>    {
    if (onGroupToggle !== undefined)    {
        onGroupToggle()
    }
}

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false)
  }

  const optionToggle = React.useCallback((option) =>  {
    option.selected = !option.selected

    if (onOptionChange !== undefined) {
      onOptionChange(option, option.selected)
    }
  })

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  
  return (
      <>
        <div className={"GroupedOptions" + (groupActive ? " GroupedOptions-Selected" : "")}>
          <div className="GroupedOptions-Content">
            <div
              className="GroupedOptions-Main"
              ref={anchorRef}
              onClick={groupedToggled}>
              <span className="GroupedOptions-Display noselect">
                {label}
              </span>
            </div>
            <div className="GroupedOptions-DropDown noselect" onClick={handleToggle}>
              <MdKeyboardArrowDown/>
            </div>
            
            {/*suffixComponent &&
              <div className="GroupedOptions-Suffix">
                {suffixComponent}
              </div>
            */}
          </div>
        </div>
        <Popper 
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper>
                  <div className="GroupedOptions-DropDownContent">
                    {optionsRef.current.map((option) => {

                      let optionProps = {}

                      if (option.selected)  {
                        optionProps.checked = true
                      }

                      return (
                        <div
                          key={option.label}
                          className="GroupedOptions-DropDownContent-Item noselect" 
                          onClick={() => {optionToggle(option)}}>
                          <div className="GroupedOptions-DropDownContent-ItemCheckbox noselect">
                            <Checkbox
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                              checked={option.selected}/>
                          </div>
                          {option.prefixContent && 
                            <div className="noselect">
                              {option.prefixContent}
                            </div>
                          }
                          <div className="GroupedOptions-DropDownContent-ItemDisplay noselect">{option.label}</div>
                        </div>
                      )}
                    )}
                  </div>
                </Paper>
              </ClickAwayListener>
            </Box>
            </Fade>
          )}
        </Popper>
      </>
  )
} 

GroupedOptions.defaultProps = {
  options: {},
  displayName: "",
  prefix: null, 
  suffix: null,
  prefixComponent: null,
  suffixComponent: undefined
}

export default GroupedOptions