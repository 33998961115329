import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'


const processInventoryItemFromAPI = (inventoryItem) =>  {
  
  //inventoryItem.lastModifiedOn = new Date(inventoryItem.last_modified)
  
  inventoryItem.availableValue = inventoryItem.units
  if (inventoryItem.resources)  {
    inventoryItem.availableValue = inventoryItem.resources.length
  
  }else if (inventoryItem.entries)  {

    //console.log(inventoryItem.entries)

  }

  return inventoryItem
}


const processInventoryItemTypeFromAPI = (itemType) =>  {
  return itemType
}


const processInventoryItemTypeGroupFromAPI = (itemTypeGroup) =>  {
  if (itemTypeGroup.loadedSubgroups === undefined)  {
    itemTypeGroup.loadedSubgroups = false
  }
  if (itemTypeGroup.loadedItemTypes === undefined)  {
    itemTypeGroup.loadedItemTypes = false
  }
  return itemTypeGroup
}

export const getAllInventoryItems = createAsyncThunk('inventory/getAllInventoryItems', async ({facilityId, facilityIds}, { getState }) => {
  var payload = {
    ...getAccountSessionData(getState()),
  }
  if (facilityId !== undefined) {
    payload.facility_id = facilityId
  }
  if (facilityIds !== undefined) {
    payload.facility_ids = facilityIds
  }
  //payload.have_inventory_items = {}
  return await FetchPost(APIRepository.Inventory.GetAllInventoryItems, payload)  
})


export const createNewInventoryItem = createAsyncThunk('inventory/createNewInventoryItem', async ({itemInfo}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: 1,
    new_item_info: itemInfo,
  }
  return await FetchPost(APIRepository.Inventory.CreateNewInventoryItem, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.newItemCreateStatus === 'pending') {
      return false
    }
  },
})



export const getInventoryItemsByItemTypeGroupId = createAsyncThunk('inventory/getInventoryItemsByItemTypeGroupId', async ({itemTypeGroupId, itemTypeGroupIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: 1,
  }
  if (itemTypeGroupId !== undefined) {
    payload.item_type_group_id = itemTypeGroupId
  }
  if (itemTypeGroupIds !== undefined) {
    payload.item_type_groups = itemTypeGroupIds
  }
  return await FetchPost(APIRepository.Inventory.GetInventoryItemsByItemTypeGroupId, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.status === 'pending') {
      return false
    }
  },
})







export const getAllInventoryItemTypes = createAsyncThunk('inventory/getAllInventoryItemTypes', async ({}, { getState }) => {
  var payload = {
    ...getAccountSessionData(getState()),
  }
  //payload.have_inventory_items = {}
  return await FetchPost(APIRepository.Inventory.GetAllInventoryItemTypes, payload)  
})


export const getInventoryItemTypesByGroupId = createAsyncThunk('inventory/getInventoryItemTypesByGroupId', async ({itemTypeGroupId, itemTypeGroupIds}, { getState }) => {
  var payload = {
    ...getAccountSessionData(getState()),
  }
  if (itemTypeGroupId !== undefined) {
    payload.item_type_group_id = itemTypeGroupId
  }
  if (itemTypeGroupIds !== undefined) {
    payload.item_type_group_ids = itemTypeGroupIds
  }
  //payload.have_inventory_items = {}
  return await FetchPost(APIRepository.Inventory.GetInventoryItemTypesByGroupId, payload)  
})

export const getInventoryItemTypeById = createAsyncThunk('inventory/getInventoryItemTypeById', async ({itemTypeId, itemTypes}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (itemTypeId !== undefined) {
    payload.item_type_id = itemTypeId
  }
  if (itemTypes !== undefined) {
    payload.item_types = itemTypes
  }
  return await FetchPost(APIRepository.Inventory.GetInventoryItemTypeById, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.loadingItemTypesStatus === 'pending') {
      return false
    }
  },
})


export const createNewInventoryItemType = createAsyncThunk('inventory/createNewInventoryItemType', async ({itemTypeInfo}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    new_item_type_info: itemTypeInfo,
  }
  return await FetchPost(APIRepository.Inventory.CreateNewInventoryItemType, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.newItemTypeCreateStatus === 'pending') {
      return false
    }
  },
})





export const getAllInventoryItemTypePrimaryGroups = createAsyncThunk('inventory/getAllInventoryItemTypePrimaryGroups', async ({}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  return await FetchPost(APIRepository.Inventory.GetAllInventoryItemTypePrimaryGroups, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.status === 'pending') {
      return false
    }
  },
})


export const getInventoryItemTypeGroupById = createAsyncThunk('inventory/getInventoryItemTypeGroupById', async ({itemTypeGroupId, itemTypeGroups}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (itemTypeGroupId !== undefined) {
    payload.item_type_group_id = itemTypeGroupId
  }
  if (itemTypeGroups !== undefined) {
    payload.item_type_groups = itemTypeGroups
  }
  return await FetchPost(APIRepository.Inventory.GetInventoryItemTypeGroupById, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.status === 'pending') {
      return false
    }
  },
})

export const getInventoryItemTypeGroupsByParentGroupId = createAsyncThunk('inventory/getInventoryItemTypeGroupsByParentGroupId', async ({itemTypeParentGroupId}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    item_type_parent_group_id: itemTypeParentGroupId
  }
  return await FetchPost(APIRepository.Inventory.GetInventoryItemTypeGroupsByParentGroupId, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.status === 'pending') {
      return false
    }
  },
})



export const getInventoryItemTypeGroupByPath = createAsyncThunk('inventory/getInventoryItemTypeGroupByPath', async ({itemTypeGroupPath}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    item_type_group_path: itemTypeGroupPath
  }
  return await FetchPost(APIRepository.Inventory.GetInventoryItemTypeGroupByPath, payload)  
},
{
  condition: (args, { getState }) => {
    const { inventory } = getState()
    if (inventory.status === 'pending') {
      return false
    }
  },
})



export const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    inventoryItems:  [

    ],
    itemTypes: [

    ],
    haveAllItemTypes: false,
    itemTypeGroups: [

    ],
    haveItemTypePrimaryGroups: false,
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingItemTypesStatus: 'idle',
    loadingData: false,
    newItemCreateStatus: 'idle',
    newItemTypeCreateStatus: 'idle',
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllInventoryItems.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllInventoryItems.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      //console.log(action.payload.inventory_items)
      if (action.payload.inventory_items) {
        for (let [facilityId, inventoryItems] of Object.entries(action.payload.inventory_items)) {
          inventoryItems.map(function(inventoryItem){ processInventoryItemFromAPI(inventoryItem); return inventoryItem });
          state.inventoryItems[facilityId] = inventoryItems;
        }
      }
      
    },

    [getAllInventoryItems.rejected]: (state) => {
      state.status = 'rejected';
    },


    
    [createNewInventoryItem.pending]: (state) => {
      state.newItemCreateStatus = 'pending'
    },

    [createNewInventoryItem.fulfilled]: (state, action) => {
      state.newItemCreateStatus = 'fulfilled'
      //console.log(action.payload.inventory_item)
      if (action.payload.inventory_item !== null) {
        state.inventoryItems.push(processInventoryItemFromAPI(action.payload.inventory_item))
      }
    },

    [createNewInventoryItem.rejected]: (state) => {
      state.newItemCreateStatus = 'rejected'
    },

    [getInventoryItemsByItemTypeGroupId.pending]: (state) => {
      state.status = 'pending';
    },

    [getInventoryItemsByItemTypeGroupId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.inventory_items)
      for (let item of action.payload.inventory_items)  {
        let foundInventoryItem = state.inventoryItems.find((iI) => iI.id === item.id)
        if (foundInventoryItem === undefined) {
          state.inventoryItems.push(processInventoryItemFromAPI(item))
        }else {
          //update
        }
      }
    },

    [getInventoryItemsByItemTypeGroupId.rejected]: (state) => {
      state.status = 'rejected';
    },



    [getAllInventoryItemTypes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllInventoryItemTypes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveAllItemTypes = true
      //console.log(action.payload.inventory_items)
      if (action.payload.item_types !== null) {
        for (let itemType of action.payload.item_types)  {
          state.itemTypes.push(processInventoryItemTypeFromAPI(itemType))
        }
      }
      if (action.payload.item_type_groups !== null) {
        for (let itemTypeGroup of action.payload.item_type_groups)  {
          state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(itemTypeGroup))
        }
      }
    },

    [getAllInventoryItemTypes.rejected]: (state) => {
      state.status = 'rejected';
    },
    

    [getInventoryItemTypesByGroupId.pending]: (state, action) => {
      state.itemTypeGroups = [...state.itemTypeGroups.map((itemTypeGroup) => {
        if (action.meta.arg.itemTypeGroupId !== undefined && action.meta.arg.itemTypeGroupId === itemTypeGroup.id)  {
          return {...itemTypeGroup, loadingItemTypes: true, loadedItemTypes: false}
        }
        if (action.meta.arg.itemTypeGroupIds !== undefined && action.meta.arg.itemTypeGroupIds.indexOf(itemTypeGroup.id) !== -1)  {
          return {...itemTypeGroup, loadingItemTypes: true, loadedItemTypes: false}
        }
        return itemTypeGroup
      })]
    },

    [getInventoryItemTypesByGroupId.fulfilled]: (state, action) => {

      //console.log(action.payload.inventory_items)
      if (action.payload.item_type !== null) {
        let foundItemType = state.itemTypes.find((iT) => iT.id === action.payload.item_type.id)
        if (foundItemType === undefined)  {
          state.itemTypes.push(processInventoryItemTypeFromAPI(action.payload.item_type))
        }
      }
      if (action.payload.item_types !== null) {
        for (let itemType of action.payload.item_types)  {
          let foundItemType = state.itemTypes.find((iT) => iT.id === itemType.id)
          if (foundItemType === undefined)  {
            state.itemTypes.push(processInventoryItemTypeFromAPI(itemType))
          }
        }
      }
      state.itemTypeGroups = [...state.itemTypeGroups.map((itemTypeGroup) => {
        if (action.meta.arg.itemTypeGroupId !== undefined && action.meta.arg.itemTypeGroupId === itemTypeGroup.id)  {
          return {...itemTypeGroup, loadedItemTypes: true}
        }
        if (action.meta.arg.itemTypeGroupIds !== undefined && action.meta.arg.itemTypeGroupIds.indexOf(itemTypeGroup.id) !== -1)  {
          return {...itemTypeGroup, loadedItemTypes: true}
        }
        return itemTypeGroup
      })]
    },

    [getInventoryItemTypesByGroupId.rejected]: (state, action) => {
      state.itemTypeGroups = [...state.itemTypeGroups.map((itemTypeGroup) => {
        if (action.meta.arg.itemTypeGroupId !== undefined && action.meta.arg.itemTypeGroupId === itemTypeGroup.id)  {
          return {...itemTypeGroup, loadingItemTypes: false, loadedItemTypes: false}
        }
        if (action.meta.arg.itemTypeGroupIds !== undefined && action.meta.arg.itemTypeGroupIds.indexOf(itemTypeGroup.id) !== -1)  {
          return {...itemTypeGroup, loadingItemTypes: false, loadedItemTypes: false}
        }
        return itemTypeGroup
      })]
    },
    

    


    [getInventoryItemTypeById.pending]: (state) => {
      state.loadingItemTypesStatus = 'pending';
    },

    [getInventoryItemTypeById.fulfilled]: (state, action) => {
      state.loadingItemTypesStatus = 'fulfilled';
      if (action.payload.item_type !== null) {
        let foundItemType = state.itemTypes.find((iT) => iT.id === action.payload.item_type.id)
        if (foundItemType === undefined)  {
          state.itemTypes.push(processInventoryItemTypeFromAPI(action.payload.item_type))
        }
      }
      if (action.payload.item_types !== null) {
        for (let itemType of action.payload.item_types)  {
          let foundItemType = state.itemTypes.find((iT) => iT.id === itemType.id)
          if (foundItemType === undefined)  {
            state.itemTypes.push(processInventoryItemTypeFromAPI(itemType))
          }
        }
      }
    },

    [getInventoryItemTypeById.rejected]: (state) => {
      state.loadingItemTypesStatus = 'rejected';
    },


    [createNewInventoryItemType.pending]: (state) => {
      state.newItemTypeCreateStatus = 'pending'
    },

    [createNewInventoryItemType.fulfilled]: (state, action) => {
      state.newItemTypeCreateStatus = 'fulfilled'
      //console.log(action.payload.new_item_type)
      if (action.payload.new_item_type !== null) {
        state.itemTypes.push(processInventoryItemTypeFromAPI(action.payload.new_item_type))
      }
    },

    [createNewInventoryItemType.rejected]: (state) => {
      state.newItemTypeCreateStatus = 'rejected'
    },

    


    
    [getAllInventoryItemTypePrimaryGroups.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllInventoryItemTypePrimaryGroups.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.item_type_groups !== null) {
        //console.log(action.payload.item_type_groups)
        for (let itemTypeGroup of action.payload.item_type_groups)  {
          let foundItemTypeGroup = state.itemTypeGroups.find((iTG) => iTG.id === itemTypeGroup.id)
          if (foundItemTypeGroup === undefined) {
            state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(itemTypeGroup))
          }else {
            //update
          }
        }
        state.haveItemTypePrimaryGroups = true
      }
    },

    [getAllInventoryItemTypePrimaryGroups.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getInventoryItemTypeGroupById.pending]: (state) => {
      state.status = 'pending';
    },

    [getInventoryItemTypeGroupById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.item_type_group !== null) {
        state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(action.payload.item_type_group))
      }
      if (action.payload.item_type_groups !== null) {
        for (let itemTypeGroup of action.payload.item_type_groups)  {
          let foundItemTypeGroup = state.itemTypeGroups.find((iTG) => iTG.id === itemTypeGroup.id)
          if (foundItemTypeGroup === undefined) {
            state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(itemTypeGroup))
          }else {
            //update
          }
        }
      }
    },

    [getInventoryItemTypeGroupById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getInventoryItemTypeGroupsByParentGroupId.pending]: (state) => {
      state.status = 'pending';
    },

    [getInventoryItemTypeGroupsByParentGroupId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.item_type_groups !== null) {
        for (let itemTypeGroup of action.payload.item_type_groups)  {
          let foundItemTypeGroup = state.itemTypeGroups.find((iTG) => iTG.id === itemTypeGroup.id)
          if (foundItemTypeGroup === undefined) {
            state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(itemTypeGroup))
          }else {
            //update
          }
        }
      }
    },

    [getInventoryItemTypeGroupsByParentGroupId.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getInventoryItemTypeGroupByPath.pending]: (state) => {
      state.status = 'pending';
    },

    [getInventoryItemTypeGroupByPath.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.item_type_groups)
      if (action.payload.item_type_groups !== null) {
        for (let itemTypeGroup of action.payload.item_type_groups)  {
          let foundItemTypeGroup = state.itemTypeGroups.find((iTG) => iTG.id === itemTypeGroup.id)
          if (foundItemTypeGroup === undefined) {
            state.itemTypeGroups.push(processInventoryItemTypeGroupFromAPI(itemTypeGroup))
          }else {
            //update
          }
        }
      }
    },

    [getInventoryItemTypeGroupByPath.rejected]: (state) => {
      state.status = 'rejected';
    },
    
    
  }
})



// Action creators are generated for each case reducer function
export const { } = inventorySlice.actions

export default inventorySlice.reducer

export const selectAllInventoryItems = state => state.inventory.inventoryItems
export const selectAllInventoryItemTypes = state => state.inventory.itemTypes
export const selectAllInventoryItemTypeGroups = state => state.inventory.itemTypeGroups
export const selectAllInventoryItemTypePrimaryGroups = state => state.inventory.itemTypeGroups.filter((iTG) => iTG.type_group_id === null)

export const selectInventoryItemTypeGroupByPath = (itemTypeGroups, path) => {
  let currentItemTypeGroup = null
  for (let currentPathIndex = 0; currentPathIndex < path.length; currentPathIndex++)  {
    let foundGroup = false
    for (let itemTypeGroup of itemTypeGroups) {
      if (currentItemTypeGroup === null) {
        //Primary group
        if (itemTypeGroup.name === path[currentPathIndex] && itemTypeGroup.type_group_id === null)  {
          currentItemTypeGroup = itemTypeGroup
          foundGroup = true
          break
        }
      }else {
        if (itemTypeGroup.name === path[currentPathIndex] && itemTypeGroup.type_group_id !== null)  {
          currentItemTypeGroup = itemTypeGroup
          foundGroup = true
          break
        }
      }
    }
    if (!foundGroup) {
      return null
    }
  }
  return currentItemTypeGroup
}