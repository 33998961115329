import './Calendar.scss';
import React from 'react'
import {BsChevronLeft, BsChevronRight, BsSearch} from 'react-icons/bs'
import { daysInMonth, FormatDate } from '../../helpers';
import { GoBack, GoForward } from '../../assets/icons/Icons';




const Calendar = ({tileContent, selectedDate, dateClicked, disabled}) => {


  const [activeCalendarStartDate, SetActiveCalendarStartDate] = React.useState(new Date()) //todo make this flexible
  /*React.useLayoutEffect(() => {
    SetActiveCalendarStartDate(selectedDate)
  }, [selectedDate])*/
  React.useLayoutEffect(() => SetActiveCalendarStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),[])

  const [currentDateMode, SetCurrentDateMode] = React.useState("month") //todo make this flexible
  const [numberOfColumns, SetNumberOfColumns] = React.useState(7)
  const [numberOfRows, SetNumberOfRows] = React.useState(5)

  React.useEffect(() => {
    //Figure out number of columns and rows based on this

  }, [activeCalendarStartDate])
  
  const onDateBackwardsPressed = (e) =>  {
    switch(currentDateMode) {
      case "month":
        SetActiveCalendarStartDate(new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth() - 1, 1))
        break
      default:
        break
    }
  }
  const onDateForwardsPressed = (e) => {
    switch(currentDateMode) {
      case "month":
        SetActiveCalendarStartDate(new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth() + 1, 1))
        break
      default:
        break
    }
  }
  const onDateClicked = (e, date) =>  {
    if (dateClicked !== undefined && !disabled)  {
      dateClicked(date, e)
    }
  }


  


  
  let currentDateCounter = 0
  return (
    <div className={"Calendar" + (disabled ? " Calendar-Disabled" : "")}>
      <div className="Calendar-Header">
        <div className="Calendar-Header-DateBackwardsButton" onClick={onDateBackwardsPressed}>
          <GoBack/>
        </div>
        <div className="Calendar-Header-DateDisplay">
          {FormatDate(activeCalendarStartDate, "MMMM yyyy")}
        </div>
        <div className="Calendar-Header-DateForwardsButton" onClick={onDateForwardsPressed}>
          <GoForward/>
        </div>
      </div>

      <div className="Calendar-Content">
        <div className="Calendar-HeaderTiles">
          {Array(numberOfColumns).fill().map(function (_, column) {
            const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            let tileProps = {style: {flexBasis: (100 / numberOfColumns).toString() + "%"}}
            return (
              <div className="Calendar-HeaderTiles-Tile" {...tileProps} key={column}>
                {daysOfTheWeek[column]}
              </div>
            )
          })}
        </div>
        <div className="Calendar-Tiles">
          {Array(numberOfRows).fill().map(function (_, row) {
            return (
              <div className="Calendar-Tiles-Row" key={row}>
                {Array(numberOfColumns).fill().map(function (_, column) {
                  let tileProps = {style: {flexBasis: (100 / numberOfColumns).toString() + "%"}}
                  let tileClassNames = "Calendar-Tiles-Tile"
                  let currentDate
                  let currentTileContent = <></>
                  switch(currentDateMode) {
                    case "month":
                      let currentMonthStartDayOfTheWeek = (new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth())).getDay();
                      if (row === 0 && column < currentMonthStartDayOfTheWeek) {
                        currentDate = new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth() - 1, daysInMonth(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth() - 1) - (activeCalendarStartDate.getDay() - column))
                        tileClassNames += " Calendar-Tiles-Tile-PreviousTerm"
                      }else {
                        let daysInCurrentMonth = daysInMonth(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth())
                        currentDateCounter++
                        if (daysInCurrentMonth >= currentDateCounter) {
                          currentDate = new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth(), activeCalendarStartDate.getDate() + (currentDateCounter - 1))
                        }else {
                          currentDate = new Date(activeCalendarStartDate.getFullYear(), activeCalendarStartDate.getMonth() + 1, (currentDateCounter - daysInCurrentMonth))
                          tileClassNames += " Calendar-Tiles-Tile-NextTerm"
                        }
                      }

                      if (currentDate !== undefined)  {
                        if (tileContent === undefined)  {
                          currentTileContent = <div className="Calendar-Tiles-Tile-Basic">
                            {currentDate.getDate()}
                          </div>
                        }else {
                          currentTileContent = tileContent(currentDate)
                        }
                      }

                      
                      //currentDate
                      break
                    default:
                      currentDate = new Date()
                      break
                  }

                  //let currentTileContent
                  //if {tileContent !== undefined }

                  return (
                    <div className={tileClassNames} {...tileProps} onClick={(e) => {onDateClicked(e, currentDate)}} key={column}>
                      {currentTileContent}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>      
      </div>
    </div>
  )
} 

Calendar.defaultProps = {
  tileContent: undefined,
  selectedDate: new Date(),
  disabled: false
}


export default Calendar