import HomePage from './Home/HomePage';
import TasksPage from './Tasks/TasksPage';
import GrowManagerPage from './GrowManager/GrowManagerPage';
import FarmsPage from './Farms/FarmsPage';
import RecipesAndPlansPage from './RecipesAndPlans/RecipesAndPlansPage';
import GrowAnalyticsPage from './GrowAnalytics/GrowAnalyticsPage';
import InventoryPage from './Inventory/InventoryPage';
import ExpensesPage from './Expenses/ExpensesPage';
import RecordsPage from './Records/RecordsPage';
import ReportsPage from './Reports/ReportsPage';
import OnlineStorePage from './OnlineStore/OnlineStorePage';

import CRMPage from './CRM/CRMPage';
import CRM_CatalogPage from './CRM/CatalogPage';
import CRM_OrdersPage from './CRM/OrdersPage';
import CRM_CustomersPage from './CRM/CustomersPage';
 

import ChatPage from './Chat/ChatPage';
import SettingsPage from './Settings/SettingsPage';
import Settings_AccountPage from './Settings/AccountPage';
import Settings_CompanyPage from './Settings/CompanyPage';
import Settings_UsersPage from './Settings/UsersPage';
import Settings_BillingPage from './Settings/BillingPage';
import Settings_FarmsPage from './Settings/FarmsPage';
import Settings_StorePage from './Settings/StorePage';
import BusinessPage from './Business/BusinessPage';
import GrowZoneRecipeManagerPage from './GrowZones/GrowZoneRecipeManagerPage';
import ServicePage from './Service/ServicePage';


import ManufacturingPage from './Manufacturing/ManufacturingPage';


const Pages = {
  HomePage: HomePage,
  TasksPage: TasksPage,
  GrowManagerPage: GrowManagerPage,
  GrowZoneRecipeManagerPage: GrowZoneRecipeManagerPage,
  FarmsPage: FarmsPage,
  BusinessPage: BusinessPage,
  RecipesAndPlansPage: RecipesAndPlansPage,
  GrowAnalyticsPage: GrowAnalyticsPage,
  InventoryPage: InventoryPage,
  ExpensesPage: ExpensesPage,
  RecordsPage: RecordsPage,
  ReportsPage: ReportsPage,
  OnlineStorePage: OnlineStorePage,
  ServicePage: ServicePage,

  CRMPage: CRMPage,
  CRM_CatalogPage: CRM_CatalogPage,
  CRM_OrdersPage: CRM_OrdersPage,
  CRM_CustomersPage: CRM_CustomersPage,
  

  ChatPage: ChatPage,
  SettingsPage: SettingsPage,
  Settings_AccountPage: Settings_AccountPage,
  Settings_CompanyPage: Settings_CompanyPage,
  Settings_UsersPage: Settings_UsersPage,
  Settings_BillingPage: Settings_BillingPage,
  Settings_FarmsPage: Settings_FarmsPage,
  Settings_StorePage: Settings_StorePage,


  ManufacturingPage: ManufacturingPage,
}

export default Pages; 