import { CloseCell, OpenCell, RadioChecked, RadioUnchecked } from '../assets/icons/Icons';
import './RadioButton.scss';
import React from 'react'

const RadioButton = React.forwardRef(({content, state, status, size, onStateChanged, hasBorder, disabled}, ref) => {
  let buttonProps = {style: {}}
  const buttonClicked = (e) => {
    if (!disabled && onStateChanged !== undefined && onStateChanged !== null)  {
      onStateChanged(e)
    }
  }
  return (
    <div 
      ref={ref} 
      className={"RadioButton RadioButton-" + status + " RadioButton-" + size + (hasBorder ? " RadioButton-WithBorder" : "") + (disabled ? " RadioButton-Disabled" : "")} >
      <div onClick={buttonClicked}>
        <div className="RadioButton-Dot">
          {state && 
            <RadioChecked/>
          }
          {!state && 
            <RadioUnchecked/>
          }
        </div>
        <div className="RadioButton-Content noselect">
          {content}
        </div>
      </div>
    </div>
  )
})

RadioButton.defaultProps = {
  content: "",
  status: "Neutral",
  size: "Medium",
  hasBorder: false,
  disabled: false,
  state: false
}


export default RadioButton