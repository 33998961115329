import React from 'react';
import './WalkthroughCarousel.scss';
import {  LeftChevron, RightChevron } from '../assets/icons/Icons';
import Button from './Button';
import useMeasure from '../useMeasure';
import useSwipe from '../useSwipe';


export const WalkthroughCarousel = ({
    slides,
    slideIndex,
    slideHeight, slideWidth,
    slideGap,
    buttonSize,
    contentGap,
    navigationDisabled,
    onIndexChanged,
  }) => {

 

  const [activeSlideIndex, SetActiveSlideIndex] = React.useState(0)

  const previousSlideClicked = () => {
    if (activeSlideIndex > 0) {
      SetActiveSlideIndex(activeSlideIndex - 1)
    }
  }
  const nextSlideClicked = () => {
    if (activeSlideIndex < slides.length - 1) {
      SetActiveSlideIndex(activeSlideIndex + 1)
    }
  }
  
  const [canMoveBackward, SetCanMoveBackward] = React.useState(false)
  const [canMoveForward, SetCanMoveForward] = React.useState(false)
  React.useLayoutEffect(() => {
    SetCanMoveBackward(activeSlideIndex > 0)
    SetCanMoveForward(slides.length - activeSlideIndex > 1)
  }, [slides, activeSlideIndex])
  const swipeHandlers = useSwipe({ 
    onSwipedLeft: (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (canMoveForward)  {
        nextSlideClicked()
      }
    }, 
    onSwipedRight: (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (canMoveBackward)  {
        previousSlideClicked()
      }
    }
  });


  const [carouselHandleRef, { width: carouselWidth }] = useMeasure()
  const [carouselDescriptionRef, { height: carouselDescriptionHeight}] = useMeasure()

  console.log(carouselDescriptionHeight)


  return (
    <div className="WalkthroughCarousel-Container" {...swipeHandlers}>
      <div className="WalkthroughCarousel-NavigationButton" style={{height: slideHeight}}>
        <Button content={<LeftChevron disabled={!canMoveBackward} width={buttonSize}/>} status={"Hyperlink"} contentPadding={0} disabled={!canMoveBackward} onClick={previousSlideClicked}/>
      </div>
      <div className="WalkthroughCarousel" style={{height: slideHeight + contentGap + carouselDescriptionHeight, width: slideWidth}} ref={carouselHandleRef}>

        {slides.map((slide, slideIndex) => {
          const slideIndexOffset = slideIndex - activeSlideIndex
          let slidePosition =  slideWidth * slideIndexOffset

          const slideContainerProps = {style:{
            width: slideWidth,
            left: slidePosition,
            gap: contentGap
          }}

          const slideDescriptionProps = {}
          if (slideIndex === activeSlideIndex)  {
            slideDescriptionProps.ref = carouselDescriptionRef
          }
          
          return (
            <div className={"WalkthroughCarousel-Slide-Container"} {...slideContainerProps}>
              <div className={"WalkthroughCarousel-Slide-Content"} style={{height: slideHeight}}>
                {slide.content && slide.content}
              </div>
              <div className={"WalkthroughCarousel-Slide-Description"} {...slideDescriptionProps}>
                {slide.description && slide.description}
              </div>
            </div>
          )
        })}

      </div>
      <div className="WalkthroughCarousel-NavigationButton" style={{height: slideHeight}}>
        <Button content={<RightChevron disabled={!canMoveForward} width={buttonSize}/>} status={"Hyperlink"} contentPadding={0} disabled={!canMoveForward} onClick={nextSlideClicked}/>
      </div> 
    </div>
  )
} 

WalkthroughCarousel.defaultProps = {
  images: [],
  slideHeight: 100,
  slideWidth: 200,
  navigationDisabled: false,
  buttonSize: 16,
  contentGap: 40
}