import './UploadPhotoPopup.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import Walkthrough from '../../../components/Walkthrough';
import UploadPhotoPopup_Listing from './UploadPhotoPopup_Listing';
import UploadPhotoPopup_EditPhoto from './UploadPhotoPopup_EditPhoto';


const UploadPhotoPopup = ({
    activeGrow, activeGrowPlan,
    photos,
    selectedZoneUID, zoneOptions, 
    addPhotoCallback, editPhotoCallback, deletePhotoCallback,
    beginUploadCallback, isUploading, uploadingCompleted,
    doneUploadingCallback, 
    onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closePopup = () =>  {
    onClosePopup()
  }

  const beginUploading = () => {
    beginUploadCallback()
  }

  const doneUploading = () => {
    doneUploadingCallback()
  }

  const [editingPhotoIndex, SetEditingPhotoIndex] = React.useState(null)
  const beginEditingPhoto = (photoIndex) => {
    SetEditingPhotoIndex(photoIndex)
  }

  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["list", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }


  //console.log(photos)

  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      //console.log(stepKeys)
      switch (stepKey)    {
        case "list":
          return (<UploadPhotoPopup_Listing
            selectedZoneUID={selectedZoneUID}
            zoneOptions={zoneOptions}
            photos={photos}
            isUploading={isUploading}
            uploadingCompleted={uploadingCompleted}
            cancelUploadCallback={closePopup}
            addPhotoCallback={addPhotoCallback}
            deletePhotoCallback={deletePhotoCallback}
            beginEditPhotoCallback={beginEditingPhoto}
            beginUploadCallback={beginUploading}
            doneUploadingCallback={doneUploading}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep} />) 
        
        case "edit_photo":
          return (<UploadPhotoPopup_EditPhoto
            photo={photos.find((p) => p.photoIndex === editingPhotoIndex)}
            editPhotoCallback={editPhotoCallback}
            transitionToPreviousStep={transitionToPreviousStep}
            transitionToNextStep={transitionToNextStep} />) 
                
        /*case "edit_photo":
          return (<UploadPhotoPopup_EditPhoto            
            
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) */

        default:
            return <></>
      }
  }


  return (<>
    <Walkthrough
      title={activeGrowPlan.name}
      subHeaderContent={<div className="Text-AuxSubtext">Upload Photo</div>}
      size="full"
      closeCallback={closePopup}
      allowOuterClickToClosePopup={false}
      hideMainSeparators={false}
      initialWalkthroughStep={["list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default UploadPhotoPopup