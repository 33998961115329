import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'


const processFacilityFromAPI = (facility) =>  {
  if (facility.loadedInitialBladeGroups === undefined)  {
    facility.loadedInitialBladeGroups = false
  }
  facility.initialInventoryLoaded = false
  facility.initialServicesLoaded = false
  facility.services = []
  //facility.verticalRackGroups = {}
  //facility.nds = {}
  //facility.hvac = {} 

  return facility
}

export const getAllFacilities = createAsyncThunk('facilities/getAllFacilities', async ({}, {getState}) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  return await FetchPost(APIRepository.Facility.GetAllFacilitiesForAccount, payload)  
},
{
  condition: (args, { getState }) => {
    const { facilities } = getState()
    if (facilities.status === 'pending') {
      return false
    }
  },
})



export const getAllServicesForFacility = createAsyncThunk('facilities/getAllServicesForFacility', async ({facilityId}, { getState}) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId
  }
  return await FetchPost(APIRepository.Facility.GetAllServicesForFacility, payload)  
},
{
  condition: (args, { getState }) => {
    const { facilities } = getState()
    if (facilities.loadingServicesStatus === 'pending') {
      return false
    }
  },
})




export const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState: {
    facilities:  [

    ],
    status: 'idle',
    loadingServicesStatus: 'idle',
    error: null,
    haveInitialData: false
  },
  reducers: {
  },
  extraReducers: {
    [getAllFacilities.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllFacilities.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      if (action.payload.facilities !== null) {
        for (let facility of action.payload.facilities)  {
          state.facilities.push(processFacilityFromAPI(facility))
        }
        state.haveInitialData = true
      }
    },

    [getAllFacilities.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getAllServicesForFacility.pending]: (state) => {
      state.loadingServicesStatus = 'pending';
    },

    [getAllServicesForFacility.fulfilled]: (state, action) => {
      state.loadingServicesStatus = 'fulfilled';
      console.log(action.payload)
      if (action.payload.services !== null) {
        let foundFacility = state.facilities.find((f) => f.id === action.meta.arg.facilityId)
        foundFacility.services = action.payload.services
        foundFacility.initialServicesLoaded = true
      }
    },

    [getAllServicesForFacility.rejected]: (state) => {
      state.loadingServicesStatus = 'rejected';
    },
    
  }
})



// Action creators are generated for each case reducer function
export const {} = facilitiesSlice.actions

export default facilitiesSlice.reducer

export const selectAllFacilities = state => state.facilities.facilities

export const selectAllFarmFacilities = createSelector(
  (state) => state.facilities,
  (facilities) => facilities.facilities.filter((f) => f.type === "farm"),
)