import './BasicAlert.scss';
import React from 'react'

const BasicAlert = React.forwardRef(({content, icon, status}, ref) => {
  let buttonProps = {style: {}}
  return (
    <div 
      ref={ref} 
      className={"BasicAlert BasicAlert-" + status} >
        <div>
          {icon !== false && <>
            {icon}
            </>}
          <span className="noselect">
            {content}
          </span>
        </div>
    </div>
  )
})

BasicAlert.defaultProps = {
  content: "",
  icon: false,
  status: "Okay"
}


export default BasicAlert