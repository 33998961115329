import './InventoryPage.scss';
import React from 'react';


import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../components/Button';
import { getInventoryItemTypeById, getInventoryItemTypeGroupById, selectAllInventoryItems, selectAllInventoryItemTypeGroups, selectAllInventoryItemTypes } from '../../redux/entities/Inventory';
import PopupModal from '../../model_components/PopupModal';

const InventoryItemPopup = () => {
  let inventoryItems = useSelector(selectAllInventoryItems)
  let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  let inventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)


  const { inventoryItemId } = useParams();
  let inventoryItem = inventoryItems.find(iI => iI.id.toString() === inventoryItemId.toString())


  const navigate = useNavigate();
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (inventoryItem === undefined || (!/\d+/.test(inventoryItemId))) {
      navigate(-1);
    }
  }, [inventoryItemId, navigate, inventoryItem]);

  React.useEffect(() => {
    if (inventoryItem)  {
      let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === inventoryItem.item_type_id)
      if (foundInventoryItemType === undefined)  {
        dispatch(getInventoryItemTypeById({itemTypes: [inventoryItem.item_type_id]}))
      }else {
        if (inventoryItemTypeGroups.find((iIG) => iIG.id === foundInventoryItemType.type_group_id) === undefined)  {
          dispatch(getInventoryItemTypeGroupById({itemTypeGroups: [foundInventoryItemType.type_group_id]}))
        }
      }      
    }

  }, [inventoryItem, inventoryItemTypes, inventoryItemTypeGroups])
 
  const closePopup = () =>  {
    navigate(-1);
  }

  

  
  let foundInventoryItemType = inventoryItemTypes.find((iIT) => iIT.id === inventoryItem.item_type_id)
  let foundInventoryItemGroup = undefined
  if (foundInventoryItemType !== undefined) {
    foundInventoryItemGroup = inventoryItemTypeGroups.find((iIG) => iIG.id === foundInventoryItemType.type_group_id)
  }

  if (inventoryItem === undefined || foundInventoryItemType === undefined || foundInventoryItemGroup === undefined) {
    return (<></>) //Change to loading in popup view
  }else {
    

    return (
      <PopupModal
          title={"Inventory Item - " + foundInventoryItemType.name}
          description={<Badge content={foundInventoryItemGroup.name} status={"Neutral"}/>}
          closeCallback={closePopup}
          footer={<></>}>
            <div>
              <div className="PopupModal-FormItem">
                <div className="PopupModal-FormItem-Question">
                    Properties
                </div>
                <div className="ViewInventoryItem-PropertiesList">
                  {Object.entries(foundInventoryItemType.properties).map(([propertyKey, propertyValue]) => {
                      console.log(propertyKey, propertyValue)
                      return (
                        <div className="ViewInventoryItem-Property">
                          <div>{propertyKey}:</div>
                          <div>{propertyValue}</div>
                        </div>
                      )
                  })}
                </div>
                
            </div>
          </div>
      </PopupModal>

    )
  }
} 

export default InventoryItemPopup