
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectPlantVarieties from '../../../../model_components/SelectPlantVarieties';
  

const CreateGrowPlan_SelectPlantVarietyWalkthroughStep = ({
        selectedPlantType,
        initialSelectedPlantVariety,
        plantVarietyConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop



    const [selectedPlantVariety, SetSelectedPlantVariety] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedPlantVariety !== undefined)  {
            SetSelectedPlantVariety(initialSelectedPlantVariety)
        }
    }, [initialSelectedPlantVariety])
    const plantVarietySelected = (plantVariety) => {
        SetSelectedPlantVariety(plantVariety)
    }




    const backClicked = () => {
        transitionToPreviousStep(["select_plant_type", null])
    }
    const nextClicked = () =>   {
        plantVarietyConfirmedCallback({plantVariety: selectedPlantVariety})
        transitionToNextStep(["select_seed", null])
    }
 
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>Select a plant variety</div>
                    <SelectPlantVarieties selectedPlantType={selectedPlantType} selectedPlantVariety={selectedPlantVariety} onPlantVarietySelection={plantVarietySelected}/>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedPlantVariety === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectPlantVarietyWalkthroughStep
