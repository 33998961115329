import './../ManageNDSWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { ScanNDS } from '../../../../assets/icons/NDSIcons';


  

const ScanNDSWalkthroughStep = ({transitionToNextStep, scanNDSCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    
    const scanNDSIn = React.useCallback(() => {
        //let rackCode = "1252021"
        //SetNDSCode(rackCode)
        scanNDSCallback({currentNDSCode: NDSCode, codeWasScanned: true})

        transitionToNextStep(["connecting_to_NDS", "connection_options"])
    })

    

    const [NDSCode, SetNDSCode] = React.useState("")
    const [codeScanned, SetCodeScanned] = React.useState(false)
    const NDSCodeManualInputChanged = React.useCallback((value) => {
        SetNDSCode(value)
    })
    
    const nextClicked = React.useCallback(() => {
        scanNDSCallback({currentNDSCode: NDSCode, codeWasScanned: codeScanned})

        transitionToNextStep(["connecting_to_NDS", "connection_options"])
    })


    let isNDSCodeValid = NDSCode !== "" && NDSCode.length > 3
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Scan NDS or Enter Code</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    {!isMobile && 
                        <div className="FlexContent-H-50">
                            <div className="FlexContent-10 FlexContent-HFill">
                                
                                <Button status="Interactive-Neutral" style={{paddingBottom:10}} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><ScanNDS width={isShortDisplay ? 70 : ((isTablet || isDesktop) ? 120 : 85)}/></div>
                                    Tap to scan code
                                </>}/>
                            </div>
                            <div className="FlexContent-20 FlexContent-Center FlexContent-HFill">
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">Enter NDS code instead</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={""} placeHolder="Enter NDS code" onChange={NDSCodeManualInputChanged}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {isMobile && <>
                        <div className="FlexContent-10 FlexContent-HFill">
                            
                            <Button status="Interactive-Neutral" style={{paddingBottom:10}} size="Large-IconAction" content={<>
                                <div className="Button-Large-Icon"><ScanNDS width={isShortDisplay ? 70 : ((isTablet || isDesktop) ? 168 : 85)}/></div>
                                Tap to scan code
                            </>}/>
                        </div>
                        <div className="FlexContent-HFill">
                            <div className="FlexContent-20 FlexContent-Center FlexContent-HFill">
                                <div className="Walkthrough-FormItem">
                                    <div className="Walkthrough-FormItem-Question">Enter NDS code instead</div>
                                    <div className="Walkthrough-FormItem-Answer">
                                        <TextInput value={""} placeHolder="Enter NDS code" onChange={NDSCodeManualInputChanged}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Continue"} onClick={scanNDSIn} width={"Flex100"} size={isMobile ? "Medium" : "Large"}  disabled={!isNDSCodeValid}/>
            </div>
        </div>
    )
}


export default ScanNDSWalkthroughStep