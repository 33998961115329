import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'
import { getAccountSessionData } from '../../../pages/Account/Common'


const processGerminationChamberFromAPI = (GerminationChamber) =>  {
  


  return GerminationChamber
}

const processGerminationChamberPropertyFromAPI = (GerminationChamber, key, value) =>  {
  console.log(key, value)
  switch (key)  {
    default:
      break
  }
}

export const getAllGerminationChamber = createAsyncThunk('GerminationChamber/getAllGerminationChamber', async ({facilityId}, { getState }) => {
  return await FetchPost(APIRepository.GerminationChamber.GetAllGerminationChamberForFacility, {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    have_grow_plans: {}
  })  
})

export const getGerminationChamberById = createAsyncThunk('GerminationChamber/getGerminationChamberById', async ({GerminationChamberId, GerminationChamberIds}, { getState }) => {
  console.log(GerminationChamberId)
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (GerminationChamberId !== undefined) {
    payload.germination_chamber_id = GerminationChamberId
  }
  if (GerminationChamberIds !== undefined) {
    payload.germination_chamber_ids = GerminationChamberIds
  }
  return await FetchPost(APIRepository.GerminationChamber.GetGerminationChamberById, payload)  
},
{
  condition: (args, { getState }) => {
    const { GerminationChamber } = getState()
    if (GerminationChamber.status === 'pending') {
      return false
    }
  },
})


export const getGerminationChamberByServiceId = createAsyncThunk('GerminationChamber/getGerminationChamberByServiceId', async ({GerminationChamberServiceId, GerminationChamberServiceIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (GerminationChamberServiceId !== undefined) {
    payload.service_id = GerminationChamberServiceId
  }
  if (GerminationChamberServiceIds !== undefined) {
    payload.service_ids = GerminationChamberServiceIds
  }
  return await FetchPost(APIRepository.GerminationChamber.GetGerminationChamberByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { germinationChamber } = getState()
    if (germinationChamber.status === 'pending') {
      return false
    }
  },
})


export const getGerminationChamberProperty = createAsyncThunk('GerminationChamber/getGerminationChamberProperty', async ({GerminationChamberId, key, keys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_chamber_id: GerminationChamberId
  }  
  if (key !== undefined) {
    payload.key = key
  }
  if (keys !== undefined) {
    payload.keys = keys
  }
  return await FetchPost(APIRepository.GerminationChamber.GetGerminationChamberProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { GerminationChamber } = getState()
    if (GerminationChamber.status === 'pending') {
      return false
    }
  },
})

export const setGerminationChamberProperty = createAsyncThunk('GerminationChamber/setGerminationChamberProperty', async ({GerminationChamberId, key, value, values}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    germination_chamber_id: GerminationChamberId
  }  
  if (key !== undefined && value !== undefined) {
    payload.key = key
    payload.value = value
  }
  if (values !== undefined) {
    payload.values = values
  }
  return await FetchPost(APIRepository.GerminationChamber.SetGerminationChamberProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { GerminationChamber } = getState()
    if (GerminationChamber.status === 'pending') {
      return false
    }
  },
})

export const GerminationChambersSlice = createSlice({
  name: 'GerminationChamber',
  initialState: {
    germinationChambers:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllGerminationChamber.pending]: (state) => {
      state.status = 'pending';
    },
    [getAllGerminationChamber.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      action.payload.germination_chambers.map(function(germinationChamber){ processGerminationChamberFromAPI(germinationChamber); return germinationChamber });
      state.germinationChambers = action.payload.germination_chambers;
    },
    [getAllGerminationChamber.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getGerminationChamberById.pending]: (state) => {
      state.status = 'pending';
    },

    [getGerminationChamberById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.nds !== null) {
        state.germinationChamber.push(processGerminationChamberFromAPI(action.payload.nds))
      }*/
      if (action.payload.germination_chambers !== null) {
        for (let germinationChamber of action.payload.germination_chambers)  {
          if (germinationChamber)  {
            state.germinationChambers.push(processGerminationChamberFromAPI(germinationChamber))
          }
        }
      }
    },

    [getGerminationChamberById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getGerminationChamberByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getGerminationChamberByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';

      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.germination_chambers !== undefined && action.payload.germination_chambers !== null) {
        for (let germinationChamber of action.payload.germination_chambers)  {
          //console.log(GerminationChamber)
          if (germinationChamber)  {
            let exists = false
            for (let existingGerminationChamber of state.germinationChambers) {
              if (existingGerminationChamber.id === germinationChamber.id)  {
                existingGerminationChamber = processGerminationChamberFromAPI({...existingGerminationChamber, ...germinationChamber})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.germinationChambers.push(processGerminationChamberFromAPI(germinationChamber))              
            }
          }
        }
      }
    },

    [getGerminationChamberByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getGerminationChamberProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [getGerminationChamberProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      
      const GerminationChamber = state.germinationChamber.find((n) => { return n.id === action.meta.arg.GerminationChamberId; })
      if (GerminationChamber !== undefined)  {
        //Do something with the resulting property
        if (action.payload.value !== null) {
          processGerminationChamberPropertyFromAPI(GerminationChamber, action.meta.arg.key, action.payload.value)
        }
        if (action.payload.values !== null) {
          for (let [key, value] of Object.entries(action.payload.values))  {
            processGerminationChamberPropertyFromAPI(GerminationChamber, key, value)
          }
        }
      }
    },

    [getGerminationChamberProperty.rejected]: (state) => {
      state.status = 'rejected';
    },

    [setGerminationChamberProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [setGerminationChamberProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //Do something with the resulting property
    },

    [setGerminationChamberProperty.rejected]: (state) => {
      state.status = 'rejected';
    },
    
  }
})



// Action creators are generated for each case reducer function
export const { } = GerminationChambersSlice.actions

export default GerminationChambersSlice.reducer

export const selectAllGerminationChamber = state => state.germinationChamber.germinationChambers
