import './TransplantToGrowOutFromNurseryTask.scss';


import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { TransplantToGrowoutEntryRow } from './TransplantToGrowOutFromNurseryTask_TransplantList';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';
import { useDispatch } from 'react-redux';
 
const TransplantToGrowOutFromNurseryTask_FinalReviewStep = ({
    task,
    allBladeZones,
    activeGrow,
    taskCompletedCallback,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["transplant_list", null])
    }


    
    
    
    const dispatch = useDispatch()
    
    const [performingTransplantToGrowoutCompleted, SetPerformingTransplantToGrowOutCompleted] = React.useState(false)
    const finishedTransplantingClicked = () => {
        SetPerformingTransplantToGrowOutCompleted(true)
        dispatch(markTaskAsCompleted({taskId: task.id, params: {}, callback: (success) => {
            if (success)    {
                taskCompletedCallback()
            }
            SetPerformingTransplantToGrowOutCompleted(false)
        }}))
    }
    
    



    let transplantEntries = []
    if (activeGrow !== undefined)   {
        transplantEntries = activeGrow.growout_location_entries
    }


    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Zone Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
        units: {label: <>Units</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        action: {label: <></>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        }
    }
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "" : "FlexContent-15"}>
                        <div className="Text-SubHeader Text-Wrap">
                            Confirm that these new locations are accurate
                        </div>
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            hasContainer={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={true}>

                            <TransplantToGrowoutEntryRow isPlaceholder={true} columnSizesChanged={(columns) => {
                                SetColumnSizes(columns)
                            }}/>
                            {transplantEntries.map((transplantEntry, index) => {
                                return <>
                                    <TransplantToGrowoutEntryRow
                                        transplantEntry={transplantEntry}
                                        index={index} 
                                        allBladeZones={allBladeZones}
                                        columnSizes={columnSizes}/>
                                </>
                            })}
                        </RowGroup>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Finished Transplanting"} onClick={finishedTransplantingClicked} size={"Flex50"} disabled={performingTransplantToGrowoutCompleted}/>
            </div>
        </div>
    )
    
}


export default TransplantToGrowOutFromNurseryTask_FinalReviewStep