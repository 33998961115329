import './ViewGrowPhotoPopup.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import PopupModal from '../../../model_components/PopupModal';
import { ImageCarousel } from '../../../components/ImageCarousel';
import { AdditionalOptions } from '../../../assets/icons/Icons';
import Button from '../../../components/Button';
import DropDownButton from '../../../components/DropDownButton';
import { FormatDate, RoundedDateToNearestLastDay } from '../../../helpers';
import { GetCurrentGrowZoneTimelineItem } from '../../../redux/entities/Recipes';
import TextInput from '../../../components/input/TextInput';
import Calendar from '../../../components/input/Calendar';
import FullScreenPhotoViewer from '../../../components/input/FullScreenPhotoViewer';
import { deleteGrowPhoto, editGrowPhoto } from '../../../redux/entities/Grow';


const ViewGrowPhotoPopup = ({
    activeGrow, activeGrowPlan, activeRecipe,
    photos,
    initialActivePhotoIndex,
    selectedZoneUID, zoneOptions, 
    addPhotoCallback, deletePhotoCallback,
    beginUploadCallback, isUploading, uploadingCompleted,
    doneUploadingCallback, 
    onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closePopup = () =>  {
    onClosePopup()
  }


  const [isEditMode, SetIsEditMode] = React.useState(false)

  const [activePhotoIndex, SetActivePhotoIndex] = React.useState(0)
  React.useLayoutEffect(() => {
    SetActivePhotoIndex(initialActivePhotoIndex)
  }, [initialActivePhotoIndex])
  const [activePhoto, SetActivePhoto] = React.useState(null)
  React.useLayoutEffect(() => {
    let photo = photos.find((p) => p.local_photo_index === activePhotoIndex)
    if (photo !== undefined)  {
      SetActivePhoto(photo)
    }else {
      SetActivePhoto(null)
    }
  }, [activePhotoIndex, photos])


  const [photoSRC, SetPhotoSRC] = React.useState(null)
  React.useLayoutEffect(() => {
    if (activePhoto !== null) {
      SetPhotoSRC(activePhoto.src)
    }
  }, [activePhoto])

  const [photoDimensions, SetPhotoDimensions] = React.useState(null)
  React.useLayoutEffect(() => {
    if (activePhoto !== null) {
      const image = new Image();
      image.onload = () => {
        SetPhotoDimensions({width: image.width, height: image.height})
      };
      image.src = activePhoto.src;
    }else {
      SetPhotoDimensions(null)
    }
  }, [activePhoto])

  const [activePhotoContainerSize, SetActivePhotoContainerSize] = React.useState({width: 0, height: 0})
  React.useLayoutEffect(() => {
    let maxWidth, maxHeight
    if (isWideDesktop)  {
      maxWidth = 600
      maxHeight = 500
    }else if (isDesktop)  {
      maxWidth = 400
      maxHeight = 310
    }else if (isTablet) {
      maxWidth = 350
      maxHeight = 200
    }else if (isMobile) {
      maxWidth = 250
      maxHeight = 160
    }
    SetActivePhotoContainerSize({width: maxWidth, height: maxHeight})

  }, [isMobile, isTablet, isDesktop, isWideDesktop])
  const [activePhotoSize, SetActivePhotoSize] = React.useState({width: 0, height: 0})
  React.useLayoutEffect(() => {
    if (photoDimensions)  {
      let currentWidth = photoDimensions.width
      let currentHeight = photoDimensions.height

      

      if (currentWidth > activePhotoContainerSize.width) {
        let ratio = activePhotoContainerSize.width / currentWidth
        currentWidth = activePhotoContainerSize.width
        currentHeight = currentHeight * ratio
      }

      if (currentHeight > activePhotoContainerSize.height) {
        let ratio = activePhotoContainerSize.height / currentHeight
        currentHeight = activePhotoContainerSize.height
        currentWidth = currentWidth * ratio
      }

      SetActivePhotoSize({width: currentWidth, height: currentHeight})
    }else {
      SetActivePhotoSize({width: 0, height: 0})
    }
  }, [photoDimensions, activePhotoContainerSize])


  const imageCarouselPhotoClicked = (image) => {
    SetActivePhotoIndex(image.local_photo_index)
  }
  const imageCarouselIndexChanged = (index) => {
    SetActivePhotoIndex(index - 1)
  }

  const dropDownOptions = [
    {label: "Full Screen", value: "full_screen"},
    {label: "Edit", value: "edit"},
    {label: "Download", value: "download"},
    {label: "Delete", value: "delete", status: "Critical"},
  ]

  const onDropDownButtonOptionClicked = (option) => {
    switch (option.value) {
      case "full_screen":
        viewFullScreenPhotoClicked(activePhoto.photo_index)
        break
      case "edit":
        SetCurrentEditPhotoName(activePhoto.photo_name)
        SetCurrentEditPhotoDate(new Date(activePhoto.photo_date))
        SetIsEditMode(true)
      case "crop":
        break
      case "download":
        const link = document.createElement('a')
        link.href = activePhoto.src
        link.download = activePhoto.photo_name + "." + activePhoto.ext
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        break
      case "delete":
        deletePhotoClicked()
        break

      default:
        break
    }
  }

  const deletePhotoClicked = () => {
    dispatch(deleteGrowPhoto({growId: activeGrow.id, photoId: activePhoto.id, callback: (success) => {
      if (success)  {
        
      }
    }}))
  }

  const editCancelledClicked = () => {
    SetIsEditMode(false)
  }


  const [currentEditPhotoName, SetCurrentEditPhotoName] = React.useState(null)
  const onPhotoNameChanged = (name) => {
    SetCurrentEditPhotoName(name)
  }
  const [currentEditPhotoDate, SetCurrentEditPhotoDate] = React.useState(null)
  const onCalendarDayClicked = (date) => {
    SetCurrentEditPhotoDate(date)
  }

  const [canSavePhotoChanges, SetCanSavePhotoChanges] = React.useState(false)
  React.useLayoutEffect(() => {
    if (activePhoto !== null && isEditMode)  {
      if (activePhoto.photo_name !== currentEditPhotoName)  {
        SetCanSavePhotoChanges(true)
      }else if (new Date(activePhoto.photo_date).getTime() !== currentEditPhotoDate.getTime())  {
        SetCanSavePhotoChanges(true)
      }
    }else {
      SetCanSavePhotoChanges(false)
    }
  }, [activePhoto, isEditMode, currentEditPhotoName, currentEditPhotoDate])


  const [isSavingPhotoChanges, SetIsSavingPhotoChanges] = React.useState(false)
  const savePhotoChangesClicked = () => {
    SetIsSavingPhotoChanges(true)
    dispatch(editGrowPhoto({growId: activeGrow.id, photoId: activePhoto.id, props: {
      "photo_name": currentEditPhotoName,
      "photo_date": currentEditPhotoDate.toISOString()
    }, callback: (success) => {
      SetIsSavingPhotoChanges(false)
      if (success)  {
        SetIsEditMode(false)
      }
    }}))
  }



  const [isViewingFullScreenPhoto, SetIsViewingFullScreenPhoto] = React.useState(null)
  const mainPhotoClicked = () => {
    SetIsViewingFullScreenPhoto(activePhoto.photo_index)
  }
  const viewFullScreenPhotoClicked = (photoIndex) => {
    SetIsViewingFullScreenPhoto(photoIndex)
  }
  const fullScreenPhotoClosed = () => {
    SetIsViewingFullScreenPhoto(null)
  }


  let dateOfPhoto = activePhoto ? new Date(activePhoto.photo_date) : null
  let daysSinceStart = 0
  let activeTimelineItem = null
  if (dateOfPhoto && activeGrow !== undefined)  {
    daysSinceStart = Math.round((dateOfPhoto.getTime() - new Date(activeGrow.started_on).getTime()) / 1000 / 60 / 60 / 24)
  }

  if (activeRecipe !== undefined && activePhoto && activePhoto.timeline_item_id !== null) {
    let foundTimelineItem = activeRecipe.timeline_items.find((tI) => tI.id === activePhoto.timeline_item_id)
    if (foundTimelineItem !== undefined)  {
      activeTimelineItem = foundTimelineItem
    }
  }

  let mainPhotoAreaPadding = 0
  if (!isMobile && isEditMode)  {
    mainPhotoAreaPadding = 24
  }

  let activePhotoDate = null
  
  if (activePhoto)  {
    activePhotoDate = new Date(activePhoto.photo_date)
  }
  return (<>
    <PopupModal
      title={activeGrowPlan.name}
      subHeaderContent={<div className="Text-AuxSubtext">#G{activeGrow.id}</div>}
      size="full"
      closeCallback={closePopup}
      hideMainSeparators={false}
      contentVerticalPadding={0}
      allowOuterClickToClosePopup={!isEditMode}
      contentWrapperVerticalPadding={0}
      footer={<>
        <div className={isMobile ? "FlexContent-5 FlexContent-HFlex" : "FlexContent-H-10 FlexContent-HFlex"}>
          {!isMobile && 
            <Button content="Cancel" status="Neutral" onClick={closePopup} size={"Flex100"} disabled={isEditMode}/>
          }
          <Button content="Done" onClick={closePopup} size={"Flex100"} disabled={isEditMode}/>
        </div>
      </>}
    >
      <div className={isMobile ? "FlexContent-10" : "FlexContent-H"}>

        <div className={"FlexContent-10" + (isMobile ? "" : " Linebreak-R-20")} /*style={{paddingTop: mainPhotoAreaPadding, paddingBottom: mainPhotoAreaPadding}}*/>
          <div className="ViewGrowPhoto-MainPhoto" style={{width: activePhotoContainerSize.width, height: activePhotoContainerSize.height}} onClick={mainPhotoClicked}>
            <img src={photoSRC} style={{width: activePhotoSize.width, height: activePhotoSize.height}}/>
          </div>
          <ImageCarousel 
            images={photos} 
            isolateIndex={activePhoto && activePhoto.local_photo_index + 1}
            isolateImage={true}
            imageHeight={isMobile ? 38 : (isTablet ? 50 : 70)} 
            imageGap={isMobile ? 2 : 6} 
            buttonSize={25}
            aspectRatio={1.33} 
            fadeStrength={0.5}
            navigationDisabled={isEditMode}
            onImageClicked={imageCarouselPhotoClicked}
            onIndexChanged={imageCarouselIndexChanged}/>
        </div>

        <div className="FlexContent-15" style={{padding: "16px 20px 0px 20px"}}>
          {!isEditMode && <>
            <div className="FlexContent-H-10">
              <div className="Text-H24" style={{flex: 1, minWidth: isMobile ? "8ch" : "15ch"}}>{activePhoto && activePhoto.photo_name}</div>
              <DropDownButton content={<AdditionalOptions/>} status="Hyperlink" options={dropDownOptions} onOptionSelected={onDropDownButtonOptionClicked}/>
            </div>
            <div className="FlexContent-10">
              <div className="FlexContent-5">
                <div className="Text-Aux14">Date of Photo</div>
                <div className="Text-S14">{FormatDate(activePhoto && new Date(activePhoto.photo_date), "MM/dd/yy")}</div>
              </div>
              <div className="FlexContent-5">
                <div className="Text-Aux14">Timeline</div>
                <div className="Text-S14">{activeTimelineItem ? activeTimelineItem.item.name : "N/A"}</div>
              </div>
              <div className="FlexContent-5">
                <div className="Text-Aux14">Days since start</div>
                <div className="Text-S14">{daysSinceStart}</div>
              </div>
            </div>
          </>}
          {isEditMode && <>
            <div className="FlexContent-10" style={{flex: 1}}>
              <div className="FlexContent" style={{gap: 2}}>
                <div className="Text-Alt12">Name</div>
                <TextInput placeHolder="Name" value={currentEditPhotoName} minWidth={isMobile ? "10ch" : "25ch"} onBlur={onPhotoNameChanged}/>
              </div>
              <div className="FlexContent" style={{gap: 2}}>
                <div className="Text-Alt12">Date of Photo</div>
                <Calendar
                  selectedDate={currentEditPhotoDate}
                  dateClicked={onCalendarDayClicked}
                  tileContent={(date) => {
                    let tileClassName = "ScheduleGrowPopup-Calendar-DayTileContent"
                    let additionalContent = null
                    if (date.getTime() === RoundedDateToNearestLastDay(1, currentEditPhotoDate).getTime()) {

                      additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayStart-Visual"></div>
                    }
                    return (
                        <div className="ScheduleGrowPopup-Calendar-DayTile">
                            <div className={tileClassName}>
                              
                                {date.getDate()}
                                {additionalContent && additionalContent}
                            </div>
                        </div>
                    )
                }}/>
                
              </div>
            </div>
            <div className="FlexContent-H-5">
              <Button status="Neutral" content="Cancel" width={"Flex50"} onClick={editCancelledClicked}/>
              <Button content="Save" width={"Flex50"} disabled={!canSavePhotoChanges || isSavingPhotoChanges} onClick={savePhotoChangesClicked}/>
            </div>
          </>}
          
        </div>

      </div>
    </PopupModal>
    {(isViewingFullScreenPhoto !== null) && <>
      {(() => {
        let viewingFullScreenPhoto = photos.find((p) => p.photo_index === isViewingFullScreenPhoto)
        if (viewingFullScreenPhoto !== undefined) {
          return (
            <FullScreenPhotoViewer src={viewingFullScreenPhoto.src} onClose={fullScreenPhotoClosed}/>
          )
        }
      })()}
    </>}
  </>)
} 

export default ViewGrowPhotoPopup