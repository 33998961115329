import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus, ReorderItem } from '../../../../assets/icons/Icons';
import { createNewBladeGroup, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';

  

const ManageRackZoneChangesWalkthroughStep = ({rackIndex, facilityId, zoneChanges, rackGroupId, currentZoneChangeIndex, transitionToPreviousStep, transitionToNextStep, rackZonesManagedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const verticalRackGroups = useSelector(selectAllBlades)

    const [currentZoneChange, UpdateZoneChange] = React.useState({})
    React.useLayoutEffect(() => {
        UpdateZoneChange(zoneChanges[currentZoneChangeIndex - 1])
    }, [zoneChanges, currentZoneChangeIndex])


    const backClicked = () =>  {
        if (currentZoneChangeIndex > 1)  {
            transitionToPreviousStep(["manage_zone_changes", currentZoneChangeIndex - 1])
        }else {
            transitionToPreviousStep(["blade_type", "right"])
        }
 
    }

    const nextClicked = () =>   {
        let updatedZoneChanges = zoneChanges
        updatedZoneChanges[currentZoneChangeIndex - 1] = currentZoneChange

        rackZonesManagedCallback({zoneChanges: updatedZoneChanges})

        if (zoneChanges.length > currentZoneChangeIndex)  {
            transitionToNextStep(["manage_zone_changes", currentZoneChangeIndex + 1])
        }else {
            
            transitionToNextStep(["final_confirm", null])
        }
    }


    const finalZoneToManageCompletedClicked = () =>   {
        let rackZoneChanges = []
    }

    if (currentZoneChange !== undefined) {
        switch (currentZoneChange.type) {
            case "new_zone":
                
                let newZoneText = <></>
                switch (currentZoneChange["for_side_type"]) {
                    case "nursery":
                        newZoneText = <>Name the new nursery zone</>
                        break

                    default: 
                        break 
                }
                
                const zoneNameChanged = (name) =>   {
                    UpdateZoneChange({...currentZoneChange, "zone_name": name})
                }

                return (<>
                    <div className="Walkthrough-Card Walkthrough-CardFill">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                            Manage Zones {currentZoneChangeIndex}/{zoneChanges.length}
                            </div>
                        </div>
                        <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                            <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                                <div style={{flexGrow:0.5, flexShrink: 1}}>
                                    <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                        <div className="Button-Large-Icon"><RackStandardGroup maxWidth={(isTablet || isDesktop) ? 300 : 140} width={"100%"}/></div>
                                    </>}/>
                                </div>
                                <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                                    <div className="FlexContent-20 FlexContent-Center">
                                        <div className="PopupModal-StandardHeader">New Zone</div>

                                        <div className="FlexContent-30 FlexContent-Center">
                                            <div className="Walkthrough-FormItem">
                                                <div className="Walkthrough-FormItem-Question">{newZoneText}</div>
                                                <div className="Walkthrough-FormItem-Answer">
                                                    <TextInput value={currentZoneChange["zone_name"]} placeHolder="Zone Name" onChange={zoneNameChanged}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Walkthrough-Card-FooterButtons">
                            <Button content={"Back"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={backClicked} width={"Flex50"}/>
                            <Button content={"Next"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={nextClicked} width={"Flex50"}/>
                        </div>
                    </div>
                </>)

            case "decommission_zone":
                return (<>
                    <div className="Walkthrough-Card Walkthrough-CardFill">
                        <div className="Walkthrough-Card-Header">
                            <div className="Walkthrough-Card-HeaderContent">
                            Manage Zones {currentZoneChangeIndex}/{zoneChanges.length}
                            </div>
                        </div>
                        <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                            <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                                <div style={{flexGrow:0.5, flexShrink: 1}}>
                                    <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                        <div className="Button-Large-Icon"><RackStandardGroup maxWidth={(isTablet || isDesktop) ? 300 : 140} width={"100%"}/></div>
                                    </>}/>
                                </div>
                                <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                                    <div className="FlexContent-20 FlexContent-Center">
                                        <div className="PopupModal-StandardHeader">Decommission Zone</div>
                                        <span style={{textAlign:"center"}}>The zone Placeholder Zone will be decommissioned between <br/><span style={{fontWeight:500}}>Rack X and Rack X</span>.</span>
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Walkthrough-Card-FooterButtons-Container">
                            <div className="Walkthrough-Card-FooterButtons">
                                <Button content={"Back"} status={"Neutral"} size={isMobile ? "Medium" : "Large"} onClick={backClicked} width={"Flex50"}/>
                                <Button content={"Next"} status={"Primary"} size={isMobile ? "Medium" : "Large"} onClick={nextClicked} width={"Flex50"}/>
                            </div>
                            <div className="Walkthrough-Card-FooterButtons-Aux">
                                <Button content={"Decommission Later"} status={"Primary-Inverted"}/>
                            </div>
                        </div>
                    </div>
                </>)
            default:
                return <></>
        }
    }
}


ManageRackZoneChangesWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default ManageRackZoneChangesWalkthroughStep