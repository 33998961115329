import './RecipesPage.scss';
import React from 'react';


import Badge from '../../../components/Badge.js'
import { FormatDate } from '../../../helpers'
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import SelectNutrientSubParts from '../../../model_components/SelectNutrientSubParts';
import PopupModal from '../../../model_components/PopupModal';
import ControlBar from '../../../components/ControlBar';
import Button from '../../../components/Button';
  

const SelectSubpartPopup = ({selectionCallback, closeCallback}) => {

  const [selectedNutrientSubpart, SetSelectedNutrientSubpart] = React.useState(undefined)
 
 
  const closePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback()
    }
  }

  const nutrientSubpartSelected = (nutrientSubpart) => {
    SetSelectedNutrientSubpart(nutrientSubpart)
  }

  const confirmNutrientSubpartSelection = (e) =>  {
    if (selectionCallback !== undefined)  {
      selectionCallback(selectedNutrientSubpart)
    }
  }

  return (
    <PopupModal 
      title="Add a subpart"
      size="full"
      closeCallback={closePopup}
      footer={(
        <ControlBar controls={(<>
          <Button content={"Cancel"} status={"Neutral"} onClick={closePopup}/>
        </>)} secondaryControls={(<>
            {selectedNutrientSubpart && <Button content={"Confirm"} onClick={confirmNutrientSubpartSelection}/>}
        </>)}/>
      )}>
      <SelectNutrientSubParts selectedNutrientSubpart={selectedNutrientSubpart} onNutrientSubPartSelection={nutrientSubpartSelected}/>
    </PopupModal>
  )
} 

export default SelectSubpartPopup