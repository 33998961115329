import './../TopUpReservoirWalkthrough.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';

import { EmptyCup, FullContainer } from '../../../../assets/icons/Icons';
import { useDispatch } from 'react-redux';
import { setNDSProperty } from '../../../../redux/entities/service/NDS';

  

const ConfirmReservoirReinstallWalkthroughStep = ({NDS, reservoirInfo, transitionToPreviousStep, transitionToNextStep, attachReservoirLaterCallback, reservoirReinstallConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const dispatch = useDispatch()

    

    const attachLaterClicked = () => {
        attachReservoirLaterCallback()
    }


    

    const [isApplyingReservoirReinstall, SetIsApplyingReservoirReinstall] = React.useState(false)
    const doneClicked = () =>   {
        if (!isApplyingReservoirReinstall) {
            SetIsApplyingReservoirReinstall(true)


            dispatch(setNDSProperty({NDSId: NDS.id, values: {
                "nutrient_reservoir_installed_state": 1,
                "nutrient_reservoir_index": reservoirInfo.index,
            }, callback: (success) => {
                SetIsApplyingReservoirReinstall(false)
                if (success)    {
                    reservoirReinstallConfirmedCallback()
                }else {
                    //something went wrong
                }
            }}))
        }
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator"><div className="Walkthrough-Card-HeaderContent">Reconnect Reservoir {reservoirInfo.index}</div></div>

            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20 FlexContent-Center" style={{padding: isMobile ? "0 20px" : "0 150px"}}>
                        <FullContainer width={100}/>
                        <div style={{fontSize:18,textAlign:"center",fontWeight:300}}>Confirm the reservoir has been reconnected. The reservoir will auto-prime when able.</div>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <div className="FlexContent-10 FlexContent-HFlex FlexContent-Center">
                    <Button content={isApplyingReservoirReinstall ? "Applying..." : "Confirm and Complete"} onClick={doneClicked} size={isMobile ? "Medium" : "Large"} width={"Flex100"} disabled={isApplyingReservoirReinstall}/>
                    <Button status="Primary-Inverted" content={"Reattach reservoir later"} onClick={attachLaterClicked}/>
                </div>
            </div>
        </div>
    )
    
}


export default ConfirmReservoirReinstallWalkthroughStep