import useMeasure from '../useMeasure';
import './ProgressBar.scss';
import React from 'react'

const ProgressBar = ({progress, label, barHeight, borderColor, barFill, backgroundFill}) => {
  
  const [barContainerRef, {width: barWidth}] = useMeasure()
  const barContainerProps = {style:{borderColor:borderColor, backgroundColor: backgroundFill, height: barHeight}}
  
  const barProps = {style: {backgroundColor: barFill, width: barWidth * (progress / 100)}}
  return (
    <div className="ProgressBar-Container" ref={barContainerRef} {...barContainerProps}>
      <div className="ProgressBar" {...barProps}>

      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  progess: 0,
  barHeight: 10,
  borderColor: "transparent",
  backgroundFill: "#DDE3EB",
  barFill: "#1D65F0"
}


export default ProgressBar