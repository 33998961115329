import './InventoryPage.scss';
import React from 'react'

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import StandardList from '../../components/StandardList/StandardList';

import Badge from '../../components/Badge.js'


import { selectAllInventoryItems, getAllInventoryItems, selectAllInventoryItemTypes, selectAllInventoryItemTypeGroups, getInventoryItemTypeById, getInventoryItemTypeGroupById} from '../../redux/entities/Inventory'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatDate } from '../../helpers';
import ControlBar from '../../components/ControlBar';
import Button from '../../components/Button';
import AddToInventoryPopup from './AddToInventoryPopup';
import InventoryItemPopup from './InventoryItemPopup';
import { selectAllFacilities } from '../../redux/entities/Facilities';






const InventoryPage = ({setPageHeader, selectedFacility}) => {
 
  
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [setPageHeader])


  const location = useLocation()
  /*const { pathname } = location;
  const splitLocation = pathname.split("/");
  const facilityID = splitLocation.length >= 2 ? splitLocation[1] : null*/

  let navigate = useNavigate(); 
  const dispatch = useDispatch()
  const facilities = useSelector(selectAllFacilities)
  const haveInitialFacilityData = useSelector((state) => state.facilities.haveInitialData)
  const [activeFacilities, SetActiveFacilities] = React.useState([])
  let inventoryItems = useSelector(selectAllInventoryItems)
  let inventoryItemTypes = useSelector(selectAllInventoryItemTypes)
  let inventoryItemTypeGroups = useSelector(selectAllInventoryItemTypeGroups)


  React.useEffect(() => {
    if (selectedFacility === "all") {
      SetActiveFacilities([...facilities])
    }else {
      let found = false
      for (let facility of facilities)  {
        if (facility.id === selectedFacility) {
          found = true
          SetActiveFacilities([facility])
          break
        }
      }
      if (!found) {
        SetActiveFacilities([])
      }
    }
  }, [facilities, selectedFacility])

  React.useEffect( () =>  { 
    //fetchTasks
    for (let facility of activeFacilities)  {
      if (!facility.initialInventoryLoaded) {
        dispatch(getAllInventoryItems({facilityId: facility.id}))
      }
    }
  }, [activeFacilities])

  

  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 20, resizable: true, sort_by: false},
    amount: {render: (<div className="StandardList-Header-Column">Amount</div>), width: 10, resizable: true, sort_by: false},
    category: {render: (<div className="StandardList-Header-Column">Category</div>), width: 15, resizable: true, sort_by: false},
    usage_breakdown: {render: (<div className="StandardList-Header-Column">Usage Breakdown</div>), width: 12, resizable: true, sort_by: false},
    last_updated: {render: (<div className="StandardList-Header-Column">Last Updated</div>), width: "grow", resizable: true, sort_by: false},
    /*deleteFunction: {render: (<div className="StandardList-Header-Column"> </div>), widthType: "px", width: 20, resizable: false},*/
  }

  const [inventoryItemList, SetInventoryItemList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")




  const buildRow = (inventoryItem) => {
    let row = {data: inventoryItem}

    //See if the item type exists
    let foundItemType = inventoryItemTypes.find((iT) => iT.id === inventoryItem.item_type_id)
    let foundItemTypeGroup = undefined
    
    let unit = ""
    let category = "N/A"
    let availableValue = inventoryItem.availableValue
    if (foundItemType !== undefined)  {
      //console.log(foundItemType)
      switch (foundItemType.unit) {
        case "kilograms":
          unit = "kg"
          break
        case "units":
          unit = " Units"
          break
        case "litres":
          unit = "L"
          availableValue = inventoryItem.availableValue / 1000
          break
        default:
          break
      }

      foundItemTypeGroup = inventoryItemTypeGroups.find((iTG) => iTG.id === foundItemType.type_group_id)
      if (foundItemTypeGroup !== undefined) {
        category = foundItemTypeGroup.name
      }
    }

    row.columns = {
      name: {
        render: (<div className="StandardList-Row-Column">
          <div className="StandardList-Row-Column-Primary">{foundItemType ? foundItemType.name : "Loading"}</div>
        </div>),
      },
      amount: {
        render: (<div className="StandardList-Row-Column">
          {availableValue.toString() + unit}
        </div>),
      },
      category: {
        render: (<div className="StandardList-Row-Column">
          <Badge status="Neutral" content={category} size="Medium"/>
        </div>),
      },
      last_updated: {
        render: (<div className="StandardList-Row-Column">
          {FormatDate(new Date(inventoryItem.last_modified), "MM/dd/yyyy")}
        </div>),
      },
    }

    return row
  }
  


  React.useEffect(() => {
    let currentInventoryItemList = {}

    let currentInventoryItems = []

    let availableInventoryItems = []
    for (let facility of activeFacilities)  {
      if (facility.id in inventoryItems)  {
        availableInventoryItems.push(...inventoryItems[facility.id])
      }
    }
    
    if (currentSearchInput === "")  {
      currentInventoryItems = [...availableInventoryItems]
    }else {
      currentInventoryItems.push(...availableInventoryItems.filter((inventoryItem) => {
        let foundItemType = inventoryItemTypes.find((iT) => iT.id === inventoryItem.item_type_id)
        if (foundItemType !== undefined)  {
          if (foundItemType.name.indexOf(currentSearchInput) !== -1 && currentInventoryItems.indexOf(inventoryItem) === -1)  {
            return true
          }
        }
        return false
      }))
    }
    
    let itemTypesToLoad = []
    let itemTypeGroupsToLoad = []
    for (let inventoryItem of currentInventoryItems) {
      //See if the item type exists
      let foundItemType = inventoryItemTypes.find((iT) => iT.id === inventoryItem.item_type_id)
      if (foundItemType === undefined)  {
        itemTypesToLoad.push(inventoryItem.item_type_id)
      }else {
        if (inventoryItemTypeGroups.find((iTG) => iTG.id === foundItemType.type_group_id) === undefined)  {
          itemTypeGroupsToLoad.push(foundItemType.type_group_id)
        }
      }

      currentInventoryItemList[inventoryItem.id] = buildRow(inventoryItem)
    }

    if (itemTypesToLoad.length > 0) {
      dispatch(getInventoryItemTypeById({itemTypes: itemTypesToLoad}))
    }
    if (itemTypeGroupsToLoad.length > 0)  {
      dispatch(getInventoryItemTypeGroupById({itemTypeGroups: itemTypeGroupsToLoad}))
    }
    
    SetInventoryItemList({...currentInventoryItemList})
  }, [activeFacilities, inventoryItems, inventoryItemTypes, inventoryItemTypeGroups, currentSearchInput])

  const inventoryItemClicked = (inventoryItem) => {
    navigate(location.pathname + "/" + inventoryItem.data.id);
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const [addingInventoryItem, SetAddingToInventoryItem] = React.useState(false)
  const addToInventoryClicked = (e) =>  {
    SetAddingToInventoryItem(true)
  }
  const closeAddToInventoryPopup = (e) =>  {
    SetAddingToInventoryItem(false)
  }
  const addToInventoryFinalized = (e) =>  {
    SetAddingToInventoryItem(false)
  }

  return (<>
    <div id="InventoryPage">
        <ControlBar controls={<>
            <TextInput value={currentSearchInput} placeHolder="Search" icon={<TextInput.SearchIcon/>} onChange={onSearchInput}/>
            <DropDownInput uid="category_select" options={[{ value: 'all', label: 'All Categories' }, { value: 'consumables', label: 'Consumables' }, { value: 'products', label: 'Products' }]} defaultValue="all"/>
            <DropDownInput uid="sort_select" options={[{ value: 'date', label: 'Date' }]} defaultValue="date" prefix="Sort By"/>
          </>}
          secondaryControls={<>
            <Button content={"Add To Inventory"} onClick={addToInventoryClicked}/>
          </>}
        />
        <StandardList
          id="InventoryTable" 
          columns={columns} 
          showHeader={true}
          rows={inventoryItemList}
          isSearchable={false}
          isMultiSelectable={true}
          resourcesAttached={false}
          onRowClicked={inventoryItemClicked}
          loadingData={false}/>
          <Routes>
            <Route path="/:inventoryItemId" element={
              <InventoryItemPopup/>
            } />
            
          </Routes>
    </div>
    {addingInventoryItem && 
      <AddToInventoryPopup closeCallback={closeAddToInventoryPopup} completeCallback={addToInventoryFinalized}/>
    }
  </>)
} 

export default InventoryPage