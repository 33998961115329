
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import NumberInput from '../../../../components/input/NumberInput';
  

const CreateGrowPlan_SelectGrowPlanNameWalkthroughStep = ({
        selectedRecipeId,
        selectedRecipeVersion,    
        initialSelectedGrowPlanName,
        growPlanNameConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const allRecipes = useSelector(state => selectAllRecipes(state))
   
    const [selectedGrowPlanName, SetSelectedGrowPlanName] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedGrowPlanName !== undefined)  {
            SetSelectedGrowPlanName(initialSelectedGrowPlanName)
        }
    }, [initialSelectedGrowPlanName])
    const growPlanNameUpdated = (growPlanName) => {
        SetSelectedGrowPlanName(growPlanName)
    }

    const backClicked = () => {
        transitionToPreviousStep(["select_grow_out_raft_type", null])
    }
    const nextClicked = () =>   {
        growPlanNameConfirmedCallback({
            growPlanName: selectedGrowPlanName
        })
        transitionToNextStep(["select_product", null])
    }
 



    React.useLayoutEffect(() => {
        let activeRecipe = allRecipes.find((r) => r.id === selectedRecipeId && r.version === selectedRecipeVersion)
        
        if (selectedGrowPlanName === null && activeRecipe !== undefined)    {
            SetSelectedGrowPlanName(activeRecipe.name + " - Plan")
        }
    }, [selectedRecipeId, selectedRecipeVersion, selectedGrowPlanName])

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "" : ""}}>
                        <div className="FlexContent-10">
                            <div className="Text-S14">Name of Grow Plan</div>
                            <TextInput placeHolder={"Enter Name"} value={selectedGrowPlanName} onBlur={growPlanNameUpdated}/>
                        </div>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedGrowPlanName === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectGrowPlanNameWalkthroughStep
