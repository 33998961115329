import './../CreateControlDeviceWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, RackStandardGroup, ScanRack } from '../../../../assets/icons/RackIcons';
import { useDispatch, useSelector } from 'react-redux';
import { createNewControlDevice, pairControlDevice, selectAllControlDeviceGroups, selectAllControlDevices } from '../../../../redux/entities/service/ControlDevice';
import { createNewUID, generateUID, useMeasure } from '../../../../helpers';


  

const ConfirmCreateControlDeviceWalkthroughStep = ({facilityId, controlDeviceType, controlDeviceSerialNumber, transitionToPreviousStep, controlDeviceConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = React.useCallback(() => {
        transitionToPreviousStep(["control_device_serial_number", null])
    })

    const [applyingCreateControlDevice, SetApplyingCreateControlDevice] = React.useState(false)
    const dispatch = useDispatch()
    const confirmChangesClicked = React.useCallback(() => {
        SetApplyingCreateControlDevice(true)
        dispatch(createNewControlDevice({
            accountId: 29,
            facilityId: facilityId,
            controlDeviceInfo: {
                control_device_type: controlDeviceType,
                control_device_serial_number: controlDeviceSerialNumber,
            },
            callback: (success) => {
                SetApplyingCreateControlDevice(false)
                if (success)    {
                    controlDeviceConfirmedCallback()
                }
            }
        }))
    })

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    Review Control Device Info
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    
                    <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                        <div className="FlexContent-40 FlexContent-Center FlexContent-OverflowY">
                            <div className="FlexContent-10 FlexContent-Left">
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500}}>Serial Number</span><span>{controlDeviceSerialNumber}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Back" disabled={applyingCreateControlDevice} onClick={backClicked} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
                <Button content="Finalize" disabled={applyingCreateControlDevice} onClick={confirmChangesClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
            </div>
        </div>
    )
}

  


export default ConfirmCreateControlDeviceWalkthroughStep