import './Badge.scss';


const Badge = ({content, status, fontSize, size, color}) => {

  return (
    <div className={"Badge Badge-" + status + " Badge-" + size + " noselect"}>
      <div><span style={{fontSize: fontSize}}>{content}</span></div>
    </div>
  )
} 

Badge.defaultProps = {
  content: "",
  status: "Neutral",
  color: false, 
  fontSize: 12,
  size: "Medium"
}


export default Badge