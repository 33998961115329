import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackConnectionFailed, RackConnectionSuccess, RackLoading, RackStandardGroup, RackStandardGrowOut, ScanRack } from '../../../../assets/icons/RackIcons';
import RadioButton from '../../../../components/RadioButton';
import { Plus } from '../../../../assets/icons/Icons';
import { createNewBladeGroup, selectAllBladeGroups, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useDispatch, useSelector } from 'react-redux';


import { nanoid, customAlphabet  } from 'nanoid'
import LoadingBar from '../../../../components/LoadingBar';
import { selectAllFacilities } from '../../../../redux/entities/Facilities';

  

const SelectRackGroupWalkthroughStep = ({currentStep, facilityId, transitionToPreviousStep, transitionToNextStep, rackGroupSelectedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const facilities = useSelector(selectAllFacilities)
    const allBlades = useSelector(selectAllBlades)
    const allBladeGroups = useSelector(selectAllBladeGroups)

    const [newGroupName, SetNewGroupName] = React.useState("")
    const [newGroupUID, SetNewGroupUID] = React.useState("")
    const [selectedBladeGroupId, SetSelectedBladeGroupId] = React.useState(null)


    const dispatch = useDispatch()
    const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 8);

    const isCreateNewGroupLoading = useSelector((state) => state.blade.creatingNewBladeGroup)
    const [creatingNewGroupLoadingStep, SetCreatingNewGroupLoadingStep] = React.useState("idle")
    const [creatingNewGroupResult, SetCreatingNewGroupResult] = React.useState(null)
    React.useEffect(() => {
        if (isCreateNewGroupLoading === "pending")  {
            SetCreatingNewGroupLoadingStep("creating_group")
        }else if (isCreateNewGroupLoading === "fulfilled")  {
            SetCreatingNewGroupLoadingStep("group_created-stage1")
        }else if (isCreateNewGroupLoading === "rejected")  {
            SetCreatingNewGroupLoadingStep("failed")
        }
    }, [isCreateNewGroupLoading])


    
    React.useEffect(() => {
        if (creatingNewGroupLoadingStep === "finished") {
            let foundNewRackGroup = allBladeGroups.find((bG) => bG.UID === newGroupUID)
            if (foundNewRackGroup !== undefined)  {
                SetSelectedBladeGroupId(foundNewRackGroup.id)
            }
        }
    }, [creatingNewGroupLoadingStep, allBladeGroups, newGroupUID])

    React.useEffect(() => {
        if (creatingNewGroupLoadingStep === "group_created-stage1")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("group_created-stage2")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "group_created-stage2")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("group_created-stage3")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "group_created-stage3")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("finished")
            }, 1000)
            return () => clearTimeout(timeout)            
        }else if (creatingNewGroupLoadingStep === "finished")  {
            const timeout = setTimeout(() => {
                SetCreatingNewGroupLoadingStep("idle")
                transitionToNextStep(["select_blade_group", "blade_group_listing"])
            }, 2000)
            return () => clearTimeout(timeout)            
        }
    }, [creatingNewGroupLoadingStep])


    switch (currentStep) {
        case "blade_group_listing":
            const addNewGroupSelected = () =>   {
                transitionToNextStep(["select_blade_group", "create_new_rack_group"])
            }
            const nextStepClicked = () =>   {
                rackGroupSelectedCallback({rackGroupId: selectedBladeGroupId})
                transitionToNextStep(["select_blade_index", null])
            }
            return (
                <div className="Walkthrough-Card Walkthrough-CardFill">
                    <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Select Blade Group</div></div>


                    <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                        <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                            <div className="FlexContent-HFill" style={{flexGrow:0.5, flexShrink: 1}}>
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><RackStandardGrowOut maxWidth={(isTablet || isDesktop) ? 200 : 140} width={"100%"}/></div>
                                </>}/>
                            </div>
                            <div className="FlexContent-HFill" style={{flexGrow:0.5, flexShrink: 1}}>
                                <div className="FlexContent-20 FlexContent-HFill FlexContent-Center">
                                    {/*<div className="FlexContent-Center">
                                        <div className="PopupModal-StandardHeader">Select Rack Group</div>
                                    </div>*/}
                                    <div className="FlexContent-10 FlexContent-HFill FlexContent-OverflowY">
                                        {allBladeGroups.filter((bG) => bG.facility_id === facilityId).map((bladeGroup) => {
                                            const groupSelected = () =>    {
                                                if (bladeGroup.id === selectedBladeGroupId)   {
                                                    SetSelectedBladeGroupId(null)
                                                }else {
                                                    SetSelectedBladeGroupId(bladeGroup.id)
                                                }
                                            }
                                            return (<> 
                                                <Button key={bladeGroup.id} status={"Primary-Toggle"} state={bladeGroup.id === selectedBladeGroupId} width={"Flex100"} content={bladeGroup["display_name"]} onClick={groupSelected}/>                                        
                                            </>)
                                        })}
                                    </div>
                                    <div className="FlexContent-Center">
                                        <Button content={<><Plus/>Add New Group</>} status="Primary-Inverted" onClick={addNewGroupSelected}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        <Button content={"Continue"} onClick={nextStepClicked} size={isMobile ? "Medium" : "Large"}  width={"Flex100"} disabled={selectedBladeGroupId === null}/>
                    </div>
                </div>
            )
        
        case "create_new_rack_group":
            const cancelCreateRackGroupClicked = () =>  {
                transitionToPreviousStep(["select_blade_group", "blade_group_listing"])
            }
            const rackGroupNameChanged = (value) =>  {
                SetNewGroupName(value)
            }

            const createNewRackGroupClicked = () =>    {
                transitionToNextStep(["select_blade_group", "confirm_new_group"])
            }

            let isNewGroupNameValid = newGroupName.length > 3
            return(
            

                <div className="Walkthrough-Card Walkthrough-CardFill">
                    <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Create New Rack Group</div></div>


                    <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                        <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                            <div className="FlexContent-HFill" style={{flexGrow:0.5, flexShrink: 1}}>
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><RackStandardGrowOut maxWidth={(isTablet || isDesktop) ? 250 : 140} width={"100%"}/></div>
                                </>}/>
                            </div>
                            <div className="FlexContent-HFill" style={{flexGrow:0.5, flexShrink: 1}}>
                                <div className="FlexContent-5 FlexContent-Center FlexContent-HFill">
                                    <div className="Walkthrough-FormItem">
                                        <div className="Walkthrough-FormItem-Question">Group Name</div>
                                        <div className="Walkthrough-FormItem-Answer">
                                            <TextInput value={newGroupName} placeHolder="Enter Group Name" onChange={rackGroupNameChanged}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        <Button content={"Cancel"} status="Neutral" onClick={cancelCreateRackGroupClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                        <Button content={"Create Group"} onClick={createNewRackGroupClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={!isNewGroupNameValid}/>
                    </div>
                </div>
            )

            
        case "confirm_new_group":
            const confirmNewRackGroupClicked = () => {
                const groupUID = nanoid()
                SetNewGroupUID(groupUID)
                SetCreatingNewGroupResult(null)
                dispatch(createNewBladeGroup({facilityId: facilityId, groupName: newGroupName, uid: groupUID, callback: (success, bladeGroup) => {
                    console.log(success, bladeGroup)
                    if (success)    {
                        SetSelectedBladeGroupId(bladeGroup.id)
                        SetCreatingNewGroupResult(true)
                    }else {
                        SetCreatingNewGroupResult(false)
                    }
                }}))
            }

            const changeNameClicked = () => {
                transitionToPreviousStep(["select_blade_group", "create_new_rack_group"])
            }
            

            let loadingProgress = 0;
            let loadingStatus = "";
            switch (creatingNewGroupLoadingStep) {
                case "creating_group":
                    loadingProgress = 5
                    loadingStatus = "Creating Group"
                    break
                case "group_created-stage1":
                    loadingProgress = 25
                    loadingStatus = "Adding to farm"
                    break
                case "group_created-stage2":
                    loadingProgress = 50
                    loadingStatus = "Giving permissions"
                    break
                case "group_created-stage3":
                    loadingProgress = 75
                    loadingStatus = "Doing the thing"
                    break
                case "finished":
                    loadingProgress = 100
                    loadingStatus = "Done!"
                    break
                
                case "failed":
                    loadingProgress = 100
                    loadingStatus = "Failed!"
                    break
    
                default:
                    break
            }

            return(
                <div className="Walkthrough-Card Walkthrough-CardFill">
                    <div className="Walkthrough-Card-Header"><div className="Walkthrough-Card-HeaderContent">Create New Rack Group</div></div>


                    <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                        <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                            <div style={{flexGrow:0.5, flexShrink: 1}}>
                                <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                                    <div className="Button-Large-Icon"><RackStandardGroup maxWidth={(isTablet || isDesktop) ? 300 : 140} width={"100%"}/></div>
                                </>}/>
                            </div>
                            <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                                <div className="FlexContent-10 FlexContent-HFill FlexContent-Center">
                                    <div className="FlexContent-10 FlexContent-HFill">
                                        <span>Group Name</span>
                                        <span style={{fontSize:24 }}>{newGroupName}</span>
                                    </div>

                                    {creatingNewGroupLoadingStep === "idle" && 
                                        <div className="FlexContent-10 FlexContent-Center FlexContent-HFill">
                                            <Button content={"Confirm"} status={"Primary"} onClick={confirmNewRackGroupClicked} size={isMobile ? "Medium" : "Large"}  width="Flex100"/>
                                            <Button content={"Change Name"} status={"Primary-Inverted"} onClick={changeNameClicked}/>
                                        </div>
                                    }
                                    {(isMobile && creatingNewGroupLoadingStep !== "idle") && 
                                        <div className="FlexContent-10 FlexContent-Center FlexContent-HFill">
                                            <LoadingBar progress={loadingProgress} subContent={<>
                                                {creatingNewGroupLoadingStep === "failed" && <>
                                                    <div className="FlexContent-H-30" style={{alignItems:"center"}}>
                                                        <span>Failed</span>
                                                        <Button content={"Try Again"} status={"Primary-Inverted"} onClick={confirmNewRackGroupClicked}/>
                                                    </div>
                                                </>}
                                                {creatingNewGroupLoadingStep !== "failed" &&
                                                    loadingStatus
                                                }
                                            </>}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Walkthrough-Card-FooterButtons">
                        {(!isMobile && creatingNewGroupLoadingStep !== "idle") && 
                            <div className="FlexContent-10 FlexContent-HFill" style={{flex:1}}>
                                <LoadingBar progress={loadingProgress} subContent={<>
                                    {creatingNewGroupLoadingStep === "failed" && <>
                                        <div className="FlexContent-H-30" style={{alignItems:"center"}}>
                                            <span>Unable to create rack group</span>
                                            <Button content={"Try Again"} status={"Primary-Inverted"} onClick={confirmNewRackGroupClicked}/>
                                        </div>
                                    </>}
                                    {creatingNewGroupLoadingStep !== "failed" &&
                                        loadingStatus
                                    }
                                </>}/>
                            </div>
                        }
                    </div>
                </div>
            )
    
            
        
        default:
            return (<></>)
    }
}


SelectRackGroupWalkthroughStep.defaultProps = {
    theme: "normal",
  }
  


export default SelectRackGroupWalkthroughStep