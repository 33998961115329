import './StandardList.scss';
import React from 'react' 
import { useMeasure } from '../../helpers'
import StandardListHeaderColumn from './StandardListHeaderColumn.js'

const StandardListHeaderGroupColumn = ({
    column,
    columnPadding, 
    groupedColumnPadding,
    rowPadding, 
    onSizeChange,
    isForMultiselect,

  }) => {  
    
    
    const columnGroupSizeChanged = () => {
      if (onSizeChange !== undefined) {
        onSizeChange(column)
      }
    }

    const updateColumnBounds = (bounds) =>  {
      if (bounds === undefined)
        return
      
        if (column.containerBounds === undefined || (
          column.containerBounds.width !== bounds.width ||
          column.containerBounds.height !== bounds.height ||
          column.containerBounds.left !== bounds.documentLeft ||
          column.containerBounds.top !== bounds.documentTop
        ))  {
          column.containerBounds = bounds
          if (onSizeChange !== undefined) {
            onSizeChange(bounds)
          }
        }
    }

    const [containerBind, {}] = useMeasure(updateColumnBounds)
    const containerProps = {...containerBind}
    const contentProps = {
      style: {
        padding: "0px " + groupedColumnPadding + "px"
      }
    }

    return (
      <div className="StandardList-Header-GroupedColumnContainer"
        {...containerProps}>
        <div className="StandardList-Header-GroupedColumnContent" {...contentProps}>
          {Object.entries(column.groupedColumns).map(([columnKey, currentColumn]) => {
            
            const columnSizeChanged = (bounds) => {
              column.groupedColumns[columnKey].containerBounds = bounds
              if (onSizeChange !== undefined) {
                onSizeChange(bounds, columnKey)
              }
            }
            return (
              <StandardListHeaderColumn key={columnKey} column={currentColumn} onSizeChange={columnSizeChanged} columnPadding={columnPadding} rowPadding={columnPadding}/>
            )
          })}
        </div>
      </div>
    )
} 

StandardListHeaderGroupColumn.defaultProps = {
  columns: [],
  isForMultiselect: false
}


export default StandardListHeaderGroupColumn