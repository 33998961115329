import './LoadingBar.scss';
import React from 'react'

const LoadingBar = React.forwardRef(({content, subContent, fill, border, barFill, barBorder, progress, size, barHeight}, ref) => {
  
  const barContainerProps = {style:{borderColor:border, backgroundColor: fill, minHeight: barHeight}}
  const barProps = {style:{borderColor:barBorder, backgroundColor: barFill, width: progress + "%"}}
  
  return (
    <div 
      ref={ref} 
      className={"LoadingBar LoadingBar-" + size}>
      <div className="LoadingBar-BarContainer" {...barContainerProps}>
        <div className="LoadingBar-Bar" {...barProps}>
          <div className="LoadingBar-Content noselect">
            {content}
          </div>
        </div>
      </div>
      <div className="LoadingBar-SubContent noselect">
        {subContent}
      </div>
    </div>
  )
})

LoadingBar.defaultProps = {
  content: "",
  subContent: "",
  fill: "#E4EAF5",
  border: "#DDE3EB",
  barFill: "#2E72D2",
  barBorder: "transparent",
  progress: 0,
  barHeight: 20,
  size: "Medium",
}


export default LoadingBar