import './../ManageBladeWalkthrough.scss';

import React from 'react';
import PopupModal from '../../../../model_components/PopupModal';
import Button from '../../../../components/Button';
import ControlBar from '../../../../components/ControlBar';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../../../components/input/TextInput';
import { RackPowerOnTutorial, RackStandardGroup, ScanRack } from '../../../../assets/icons/RackIcons';
import { useDispatch, useSelector } from 'react-redux';
import { addBladeToFarm, selectAllBladeGroups, selectAllBlades } from '../../../../redux/entities/service/Blade';
import { useMeasure } from '../../../../helpers';


  

const ConfirmNewRackWalkthroughStep = ({facilityId, bladeUID, bladeName, bladeGroupId, bladeIndex, bladeSides, zoneChanges, transitionToPreviousStep, bladeConfirmedCallback}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const allBladeGroups = useSelector(selectAllBladeGroups)
    let selectedBladeGroup = allBladeGroups.find((bG) => bG.id === bladeGroupId)


    const backClicked = React.useCallback(() => {
        if (zoneChanges.length > 0) {
            transitionToPreviousStep(["manage_zone_changes", zoneChanges.length])
        }else {
            transitionToPreviousStep(["blade_type", "right"])
        }
    })

    const [applyingLinkBlade, SetApplyingLinkBlade] = React.useState(false)
    const dispatch = useDispatch()
    const confirmChangesClicked = React.useCallback(() => {
        SetApplyingLinkBlade(true)
        dispatch(addBladeToFarm({
            facilityId: facilityId,
            bladeUID: bladeUID,
            bladeName: bladeName,
            bladeGroupId: bladeGroupId,
            bladeIndex: bladeIndex,
            bladeSides: bladeSides,
            zoneChanges: zoneChanges,
            callback: (success) => {
                SetApplyingLinkBlade(false)
                if (success)    {
                    bladeConfirmedCallback()
                }
            }
        }))
        //bladeConfirmedCallback()
        //transitionToNextStep(["", null])
        //Do the stuff
    })





    const [primaryInfoColumnBind, {width: primaryInfoColumnWidth}] = useMeasure()

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header">
                <div className="Walkthrough-Card-HeaderContent">
                    Review Blade Info
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-Center" style={{flexGrow:0.5, flexShrink: 1}}>
                        <Button status="Interactive-Button-Neutral" disabled={true} size="Large-IconAction" content={<>
                            <div className="Button-Large-Icon"><RackStandardGroup maxWidth={(isTablet || isDesktop) ? 300 : 140} width={"100%"}/></div>
                        </>}/>
                    </div>
                    <div className="FlexContent-5 FlexContent-HFill" style={{flexGrow:0.5, flexShrink:0}}>
                        <div className="FlexContent-40 FlexContent-Center FlexContent-OverflowY">
                            <div className="FlexContent-10 FlexContent-Left">
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>UID</span><span>{bladeUID}</span>
                                </div>
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>Name</span><span>{bladeName}</span>
                                </div>
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>Group</span><span>{selectedBladeGroup.display_name}</span>
                                </div>
                                <div className="FlexContent-H-40">
                                    <span style={{fontWeight: 500}}  {...primaryInfoColumnBind}>Location</span><span>Position {bladeIndex}</span>
                                </div>
                                {Object.entries(bladeSides).map(([side, sideKey]) => {
                                    if (side === "left")    {
                                        switch (sideKey)    {
                                            case "empty":
                                                return (
                                                    <div className="FlexContent-H-40">
                                                        <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>Front</span><span>Nothing</span>
                                                    </div>
                                                )
                                            case "nursery":
                                                return (
                                                    <div className="FlexContent-H-40">
                                                        <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>Front</span><span>Nursery</span>
                                                    </div>
                                                )
                                            default:
                                                return <></>
                                        }
                                    }else if (side === "right") {
                                        switch (sideKey)    {
                                            case "empty":
                                                return (
                                                    <div className="FlexContent-H-40">
                                                        <span style={{fontWeight: 500, width:primaryInfoColumnWidth}}>Back</span><span>Nothing</span>
                                                    </div>
                                                )
                                            default:
                                                return <></>
                                        }
                                    }else if (side === "dual")  {
                                        
                                    }
                                })}
                            </div>
                            <div className="FlexContent-10 FlexContent-Left">
                                {zoneChanges.map((zoneChange) => {
                                    switch (zoneChange.type) {
                                        case "new_zone":
                                            return (<>
                                                <div className="FlexContent-H-40">
                                                    <span style={{fontWeight: 500}}>{zoneChange["zone_name"]}</span><span>Create</span>
                                                </div>
                                            </>)
                                        case "decommission_zone":
                                            return (<>
                                                <div className="FlexContent-H-40">
                                                    <span style={{fontWeight: 500}}>Placeholder Name</span><span>Decommission</span>
                                                </div>
                                            </>)
                                        default:
                                            return <></>
                                    }
                                    return (<>

                                    </>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content="Back" disabled={applyingLinkBlade} onClick={backClicked} status={"Neutral"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
                <Button content="Finalize" onClick={confirmChangesClicked} status={"Primary"} size={isMobile ? "Medium" : "Large"} width="Flex50"/>
            </div>
        </div>
    )
}

  


export default ConfirmNewRackWalkthroughStep