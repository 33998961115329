import {TouchEvent, useState} from "react";

const useSwipe = ({onSwipedLeft, onSwipedRight}) => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0); 

    const minSwipeDistance = 50;

    const calculateSwipe = (start, end) => {
        if (!start || !end) return;
        const distance = start - end;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe && onSwipedLeft !== undefined) {
            return "left"
        }
        if (isRightSwipe && onSwipedRight !== undefined) {
            return "right"
        }
        return false
    }

    const onTouchStart = (e) => {
        setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }
    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
        let isSwipe = calculateSwipe(touchStart, e.targetTouches[0].clientX)
        if (isSwipe !== false)  {
            e.preventDefault()
            e.stopPropagation()
            return true
        }
    }
    const onTouchEnd = (e) => {
        let isSwipe = calculateSwipe(touchStart, touchEnd)
        if (isSwipe === "left" && onSwipedLeft !== undefined) {
            onSwipedLeft(e);
        }else if (isSwipe === "right" && onSwipedRight !== undefined) {
            onSwipedRight(e);
        }
    }

    
    const onWheel = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!touchStart)    {
            setTouchStart(e.clientX)
        }else {
            let isSwipe = calculateSwipe(touchStart, e.clientX)
            if (isSwipe === "left" && onSwipedLeft !== undefined) {
                onSwipedLeft(e);
                setTouchStart(0)
            }else if (isSwipe === "right" && onSwipedRight !== undefined) {
                onSwipedRight(e);
                setTouchStart(0)
            }
        }
    }
    return {
        onWheel,
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}

export default useSwipe