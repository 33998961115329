import {TouchEvent, useState} from "react";

const useSwipeVertical = ({onSwipedUp, onSwipedDown}) => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0); 

    const minSwipeDistance = 50;

    const calculateSwipe = (start, end) => {
        if (!start || !end) return;
        const distance = start - end;
        const isUpSwipe = distance > minSwipeDistance;
        const isDownSwipe = distance < -minSwipeDistance;
        if (isUpSwipe && onSwipedUp !== undefined) {
            return "up"
        }
        if (isDownSwipe && onSwipedDown !== undefined) {
            return "down"
        }
        return false
    }

    const onTouchStart = (e) => {
        setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY);
    }
    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientY)
        let isSwipe = calculateSwipe(touchStart, e.targetTouches[0].clientY)
        if (isSwipe !== false)  {
            e.preventDefault()
            e.stopPropagation()
            return true
        }
    }
    const onTouchEnd = (e) => {
        let isSwipe = calculateSwipe(touchStart, touchEnd)
        if (isSwipe === "up" && onSwipedUp !== undefined) {
            onSwipedUp(e);
        }else if (isSwipe === "down" && onSwipedDown !== undefined) {
            onSwipedDown(e);
        }
    }

    
    const onWheel = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!touchStart)    {
            setTouchStart(e.clientY)
        }else {
            let isSwipe = calculateSwipe(touchStart, e.clientY)
            if (isSwipe === "up" && onSwipedUp !== undefined) {
                onSwipedUp(e);
                setTouchStart(0)
            }else if (isSwipe === "down" && onSwipedDown !== undefined) {
                onSwipedDown(e);
                setTouchStart(0)
            }
        }
    }
    return {
        onWheel,
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}

export default useSwipeVertical