import { createSlice } from '@reduxjs/toolkit'

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    pageStack: {
      
    }
  },
  reducers: {
    
    navigateToPage: (state, action) =>  {
      
    },

  },
})

// Action creators are generated for each case reducer function
export const { navigateToPage} = navigationSlice.actions

export default navigationSlice.reducer